import { useNavigate, useParams } from "react-router-dom";
import Footer from "../CommonComponents/Footer";
import MintHero from "./MintHero";
import UserNFTs from "../Cards/UserNFTs";
import { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { PactContext } from "../pact/PactContextProvider";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import { useDisclosure } from "@chakra-ui/react";
import MyNftPopUpModal from "../DialogueModals/MyNftPopUpModal";
import { Box } from "@chakra-ui/react";
import { CrossChainContext } from "../CrossChainOperations/CrossChainContextProvider";
import { buildDocumentQuery, firestoreGetDocument } from "../Firestore/FirestoreService";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import { checkIfNullOrUndefined } from "../utils/utils";
import { useCollectionProvider } from "../CollectionProviders/CollectionProvider";
import { toast } from "react-toastify";
import useFirestore from "../Firestore/useFirestore";
import { useGetAccountWhitelistData } from "../hooks/useGetAccountWhitelistData";
import { KADCARS_PROJECT_NAME_KEY, LOCAL_ACCOUNT_KEY, MARMALADE_TYPE, STACKED_TYPE } from "../utils/Constants";
import { UserContext } from "../UserProfilePage/UserContextProvider";

export async function getTotalPriceForAmount(mintProvider, accountWhitelistInfo, nftAmount) {
    let total = 0;

    for (let i = 1; i <= nftAmount; i++) {
        total += await mintProvider?.getNextPrice(i, accountWhitelistInfo);
    }

    return total;
}


const Mint = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const pactContext = useContext(PactContext);
    const isokoContext = useContext(IsokoDialogContext);
    const projectName = projectId.split("-")[0];
    const collectionName = projectId.split("-")[1];
    const crossChainContext = useContext(CrossChainContext);
    const isokoCollectionsContext = useContext(IsokoCollectionsContext);
    const collectionProvider = useCollectionProvider(projectName, collectionName);
    const [collectionConfig, setCollectionConfig] = useState(null);
    const [mintProvider, setMintProvider] = useState(null);
    const [userNfts, setUserNfts] = useState(null);
    const [userNftData, setUserNftData] = useState(null);
    const [accountWhitelistInfo, setAccountWhitelistInfo] = useState(null);
    const [selectedNftData, setSelectedNftData] = useState(null);
    const { isOpen: isOpenNftDataPopup, onOpen: onOpenNftDataPopup, onClose: onCloseNftDataPopup } = useDisclosure();
    const userNftsQuery = useFirestore(
        buildDocumentQuery(`/users/${pactContext.account?.account}`),
        async (data) => {
            let mintCollection = null;
            if (
                checkIfNullOrUndefined(pactContext.account?.account) ||
                checkIfNullOrUndefined(collectionConfig)
            ) {
                return;
            }
            if (!checkIfNullOrUndefined(data) &&
                !checkIfNullOrUndefined(data[projectName]) &&
                !checkIfNullOrUndefined(data[projectName][collectionName])
            ) {
                mintCollection = data[projectName][collectionName];
            }

            if (checkIfNullOrUndefined(mintCollection)) {
                mintCollection = await mintProvider.getNftsForUser();
            }
            setUserNfts(mintCollection);
            return mintCollection;
        },
        "doc",
        pactContext.account?.account
    )

    useEffect(() => {
        if (!checkIfNullOrUndefined(collectionProvider)) {
            setMintProvider(collectionProvider.getMintProvider());
            setCollectionConfig(collectionProvider.getConfig());
        }
        if (!checkIfNullOrUndefined(pactContext.account)) {
            if (checkIfNullOrUndefined(userNftData) && !checkIfNullOrUndefined(userNfts)) {
                getNftsAndUserWLInfo(collectionConfig)
            }
        } else {
            handleUserLogout();
        }
    }, [pactContext.account, projectId, userNfts]);


    async function getNftsAndUserWLInfo() {
        let nfts = null;
        let newNftId = "";
        let token = collectionConfig["nft-id-separator-token"];
        let nftDataObj = {}
        let nftsData = [];
        let mintedNftIds = !checkIfNullOrUndefined(userNftData) ? userNftData.map((nft) => { return nft["token-id"] }) : [];

        if (!checkIfNullOrUndefined(userNfts)) {
            for (const nft of userNfts) {
                let collectionName = collectionProvider.getCollectionName();
                let nftId = nft;
                let id = nft;

                if (collectionConfig["policy-info"]["standard"] === STACKED_TYPE) {
                    collectionName = mintProvider.getSubCollectionName(collectionConfig, nft.split(token)[0]);
                    nftId = nft.split(token)[1];
                } else if (collectionConfig["policy-info"]["collection-name"] === KADCARS_PROJECT_NAME_KEY) {
                    nftId = await mintProvider.getNftId(nft);
                } else {
                    nftId = nft.split(token)[1];
                    id = nft.split(token)[1];
                }

                let image = await mintProvider.getNftImage(id);
                let obj = {
                    "token-id": nft,
                    "nft-id": nftId,
                    "nft-id-on-chain": nft,
                    "nft-uri": image,
                    "standard": collectionProvider.getCollectionStandard(),
                    "collectionName": collectionName
                }
                // console.log(obj)

                if (!checkIfNullOrUndefined(mintedNftIds) && !mintedNftIds.includes(id)) {
                    newNftId = nftId;
                    nftDataObj = obj
                }

                nftsData.push(obj);
            }
        }
        let accountWlInfo = await mintProvider.getAccountWhitelistData(pactContext.account.account);
        setAccountWhitelistInfo(accountWlInfo);
        setUserNftData(nftsData);

        return nftDataObj;
    }

    async function onMintTransactionComplete(data) {
        console.log(data)
        if (data?.result?.status === "success") {
            let nftId = await getNftsAndUserWLInfo();
            let collectionConfig = isokoCollectionsContext.getCollectionConfig(projectName, collectionName);
            let nftData = await getNftData(nftId);

            // userContext.addNft(nftData, collectionConfig);
            isokoContext.setActiveModalUrl(`/userProfile/${pactContext.account.account}`);
            // isokoContext.onOpenSuccessMintModal();
        } else {
            crossChainContext.setQuickBuyErrorMessage("Not Enough Funds Across All Chains!");
            crossChainContext.onOpenFailedQuickBuyModal();
        }
    }

    async function onNftCardClickCallback(nftDataLocal) {
        let nftData = await getNftData(nftDataLocal);

        console.log(nftData)
        setSelectedNftData(nftData);
        onOpenNftDataPopup();
    }

    async function getNftData(nftDataLocal) {
        console.log(nftDataLocal)
        let nftPrefix = null;
        let nftNumber = null;

        if (collectionName === KADCARS_PROJECT_NAME_KEY) {
            nftNumber = nftDataLocal["token-id"];
        } else {
            nftPrefix = nftDataLocal["nft-id-on-chain"].split(collectionConfig["nft-id-separator-token"])[0];
            nftNumber = nftDataLocal["nft-id-on-chain"].split(collectionConfig["nft-id-separator-token"])[1];
        }

        let collectionData = await collectionProvider.getMarketplaceCollectionDetails(nftNumber, nftPrefix);
        let nftData = await collectionProvider.getNftListingData(nftNumber, nftPrefix);

        return {
            ...nftData,
            ...collectionData
        }
    }

    async function initiateMint(nftAmount) {
        let nftSupply = collectionProvider.getTotalSupply();
        if (nftSupply < nftAmount) {
            toast.error(
                `Supply exceeded! Only ${nftSupply} NFTs remaining in this collection`
            );
            return;
        }
        if (nftAmount > 0) {
            let totalPrice = await getTotalPriceForAmount(mintProvider, accountWhitelistInfo, nftAmount);//getnextprice - on mint

            if (pactContext.account?.accountGuard) {
                mintProvider.mint(nftAmount, totalPrice, onMintTransactionComplete);
            } else {
                onMintTransactionComplete(null);
            }

        }
    }

    function handleConnectClick() {
        if (!pactContext.account) {
            isokoContext.onOpenConnectModal();
        }
    }

    function handleBackClick() {
        navigate("/Launchpad");
    }

    function handleManageNftClick(nftData) {
        let nftId = nftData["name"].split("#")[1];
        if (nftData["standard"] === STACKED_TYPE) {
            nftId = nftData["nft-id-on-chain"]
        }
        if (collectionConfig["standard"] === STACKED_TYPE) {
            let prefix = nftId.split(collectionConfig["nft-id-separator-token"])[0];
            let num = nftId.split(collectionConfig["nft-id-separator-token"])[1];
            navigate(
                `/listNft/${projectId.split("-")[0]}-${projectId.split("-")[1]}/${prefix}/${num}`, {
                state: {
                    config: collectionConfig,
                    nftId: nftId
                }
            });
        } else if (collectionConfig["standard"] === MARMALADE_TYPE) {
            navigate(
                `/listNft/${projectId.split("-")[0]}-${projectId.split("-")[1]}/${nftId}`, {
                state: {
                    config: collectionConfig,
                    nftId: nftId
                }
            });
        } else {
            navigate(`/listNft/${projectId.split("-")[0]}-${projectId.split("-")[1]}/${nftData["token-id"]}`);
        }
    }

    function handleUserLogout() {
        setAccountWhitelistInfo(null);
        setUserNftData(null);
    }

    return (
        <div className="launchPad latestDesign">
            {
                selectedNftData &&
                <MyNftPopUpModal
                    isOpen={isOpenNftDataPopup}
                    onOpen={onOpenNftDataPopup}
                    onClose={onCloseNftDataPopup}
                    nftData={selectedNftData}
                    handleManageNftClick={handleManageNftClick}
                />
            }

            <MintHero
                initiateMint={initiateMint}
                account={pactContext.account}
                userNftList={userNftData}
                accountWhitelistInfo={accountWhitelistInfo}
                mintProvider={mintProvider}
                collectionProvider={collectionProvider}
                projectConfig={collectionConfig}
                onMintCompleteCallback={onMintTransactionComplete}
                handleBackClick={handleBackClick}
                handleConnectClick={handleConnectClick}
            />

            <Box mt="48px">
                <UserNFTs
                    header={"Your NFTs"}
                    onCardClickCallback={onNftCardClickCallback}
                    userNftList={userNftData}
                    collectionName={isokoCollectionsContext?.collections && collectionProvider?.getCollectionName()}
                />
            </Box>
        </div>
    );
};

export default Mint;

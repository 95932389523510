const kishu_ken_metadata = {
    "1": {
        "name": "Kishu Ken #1",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/1.png",
        "dna": "a01119d06771655c2525a6b021bc23e62c6d2d8d",
        "edition": 1,
        "date": 1662951959788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "1",
        "rarity_score": 49.63480330415433,
        "rarity_level": "COMMON"
    },
    "2": {
        "name": "Kishu Ken #2",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/2.png",
        "dna": "baacf6580a409bd4dd4774e3e1d8bdee785526de",
        "edition": 2,
        "date": 1662951958391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "2",
        "rarity_score": 59.991223739783884,
        "rarity_level": "COMMON"
    },
    "3": {
        "name": "Kishu Ken #3",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/3.png",
        "dna": "e373262d83d842127c151a0f6e7b55a6961edaba",
        "edition": 3,
        "date": 1662952253077,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "3",
        "rarity_score": 58.107416950502056,
        "rarity_level": "COMMON"
    },
    "4": {
        "name": "Kishu Ken #4",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/4.png",
        "dna": "aa941afcb87a1867440d4a38c4bc219cfbb14fc7",
        "edition": 4,
        "date": 1662952005746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "4",
        "rarity_score": 39.8434179987578,
        "rarity_level": "COMMON"
    },
    "5": {
        "name": "Kishu Ken #5",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/5.png",
        "dna": "a4090399b05e435871815caa09cb51fe582ac09b",
        "edition": 5,
        "date": 1662951911055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "5",
        "rarity_score": 46.32236984857643,
        "rarity_level": "COMMON"
    },
    "6": {
        "name": "Kishu Ken #6",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/6.png",
        "dna": "c6aa79ef94013c7a6bc9942a57a978c0eddecb3c",
        "edition": 6,
        "date": 1662952512102,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "6",
        "rarity_score": 45.51898607140944,
        "rarity_level": "COMMON"
    },
    "7": {
        "name": "Kishu Ken #7",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/7.png",
        "dna": "27d8d82111511ec89f762f8fd922b9e92c5f8d85",
        "edition": 7,
        "date": 1662951898320,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "7",
        "rarity_score": 62.625389777381905,
        "rarity_level": "COMMON"
    },
    "8": {
        "name": "Kishu Ken #8",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/8.png",
        "dna": "f9bb4f0685ad38e9121804a290fb4074980b4536",
        "edition": 8,
        "date": 1662951841012,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "8",
        "rarity_score": 49.641957585227836,
        "rarity_level": "COMMON"
    },
    "9": {
        "name": "Kishu Ken #9",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/9.png",
        "dna": "de1ddf7b04c88ae171f1bad1632b7c88c9fec036",
        "edition": 9,
        "date": 1662952455267,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "9",
        "rarity_score": 64.03508771929825,
        "rarity_level": "COMMON"
    },
    "10": {
        "name": "Kishu Ken #10",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/10.png",
        "dna": "5e8672ef72bf300d16a6da9e0c77b72693c9ba31",
        "edition": 10,
        "date": 1662951927091,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.25,
        "id": "10",
        "rarity_score": 36.43808348472809,
        "rarity_level": "COMMON"
    },
    "11": {
        "name": "Kishu Ken #11",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/11.png",
        "dna": "f3a288277361c3daac5bd04cc135b7d69610b566",
        "edition": 11,
        "date": 1662952078200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "11",
        "rarity_score": 41.4132721361637,
        "rarity_level": "COMMON"
    },
    "12": {
        "name": "Kishu Ken #12",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/12.png",
        "dna": "28d0fd0e64c372666c0b699033abbd7f2034258e",
        "edition": 12,
        "date": 1662952144534,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "12",
        "rarity_score": 100.67912772585669,
        "rarity_level": "RARE"
    },
    "13": {
        "name": "Kishu Ken #13",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/13.png",
        "dna": "a9509e013a243d7c4db3ca0a2364065e2331303c",
        "edition": 13,
        "date": 1662952198201,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "13",
        "rarity_score": 63.30121209475583,
        "rarity_level": "COMMON"
    },
    "14": {
        "name": "Kishu Ken #14",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/14.png",
        "dna": "3593335187ac85faee1fd8f05086fca861603111",
        "edition": 14,
        "date": 1662952544544,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "14",
        "rarity_score": 87.9621907144843,
        "rarity_level": "COMMON"
    },
    "15": {
        "name": "Kishu Ken #15",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/15.png",
        "dna": "2408f9e03ca73d9fd944e0754fde7c669004d4eb",
        "edition": 15,
        "date": 1662952299436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "15",
        "rarity_score": 41.8788753540605,
        "rarity_level": "COMMON"
    },
    "16": {
        "name": "Kishu Ken #16",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/16.png",
        "dna": "c8a95669e8b3ff2994ed7fc2774b25d1b9f6a03e",
        "edition": 16,
        "date": 1662951929110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "16",
        "rarity_score": 55.11124845646168,
        "rarity_level": "COMMON"
    },
    "17": {
        "name": "Kishu Ken #17",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/17.png",
        "dna": "53c1bb1fe852ec2a7d782fee8549c2596dc8649f",
        "edition": 17,
        "date": 1662952534190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "17",
        "rarity_score": 74.98922252211726,
        "rarity_level": "COMMON"
    },
    "18": {
        "name": "Kishu Ken #18",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/18.png",
        "dna": "8f023e15982dcc77b69e24609f457d49f31b8056",
        "edition": 18,
        "date": 1662952388258,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "18",
        "rarity_score": 61.09677178019676,
        "rarity_level": "COMMON"
    },
    "19": {
        "name": "Kishu Ken #19",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/19.png",
        "dna": "20e00aafbbd1ab60375175ae503f71ceaceb0ef7",
        "edition": 19,
        "date": 1662952366969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "19",
        "rarity_score": 69.41767021858556,
        "rarity_level": "COMMON"
    },
    "20": {
        "name": "Kishu Ken #20",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/20.png",
        "dna": "2bee84192ac5aef90871ca5fc5b1870f55fdff96",
        "edition": 20,
        "date": 1662952356185,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "20",
        "rarity_score": 62.30953341401103,
        "rarity_level": "COMMON"
    },
    "21": {
        "name": "Kishu Ken #21",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/21.png",
        "dna": "d3cbd25562b6f6b2e9a20d9950474df8154205e6",
        "edition": 21,
        "date": 1662952393270,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "21",
        "rarity_score": 62.0906042908358,
        "rarity_level": "COMMON"
    },
    "22": {
        "name": "Kishu Ken #22",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/22.png",
        "dna": "d22848b368e7aea524229be237f0013af5d7c5a9",
        "edition": 22,
        "date": 1662952024962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "22",
        "rarity_score": 39.578867734207535,
        "rarity_level": "COMMON"
    },
    "23": {
        "name": "Kishu Ken #23",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/23.png",
        "dna": "606cd7ca08b3ef81163a25cdeef9415eb54eb6b1",
        "edition": 23,
        "date": 1662952035689,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "23",
        "rarity_score": 48.393963583837,
        "rarity_level": "COMMON"
    },
    "24": {
        "name": "Kishu Ken #24",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/24.png",
        "dna": "9ad0b1cf934f8d0ff9c4c8577de2563c2a17fc4d",
        "edition": 24,
        "date": 1662952254495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "24",
        "rarity_score": 55.765345183888925,
        "rarity_level": "COMMON"
    },
    "25": {
        "name": "Kishu Ken #25",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/25.png",
        "dna": "aae5d83bedd1efb974cbfb5da413741a7682d5df",
        "edition": 25,
        "date": 1662951889104,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "25",
        "rarity_score": 62.03068267063198,
        "rarity_level": "COMMON"
    },
    "26": {
        "name": "Kishu Ken #26",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/26.png",
        "dna": "3066a22b24af805face0c86c64c01cc510131faa",
        "edition": 26,
        "date": 1662952447710,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "26",
        "rarity_score": 69.26158643375413,
        "rarity_level": "COMMON"
    },
    "27": {
        "name": "Kishu Ken #27",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/27.png",
        "dna": "5d42d1fb374cf277d8f18a6fc0483db8738046a1",
        "edition": 27,
        "date": 1662951813507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "27",
        "rarity_score": 59.9923751822486,
        "rarity_level": "COMMON"
    },
    "28": {
        "name": "Kishu Ken #28",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/28.png",
        "dna": "72312945884ecdd3b98bd6a9593a8afc58bc7969",
        "edition": 28,
        "date": 1662952331614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "28",
        "rarity_score": 81.25230493651546,
        "rarity_level": "COMMON"
    },
    "29": {
        "name": "Kishu Ken #29",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/29.png",
        "dna": "15c9b03db7c0b829bedf13e7db3874c150659685",
        "edition": 29,
        "date": 1662952057806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16.333333333333332,
        "id": "29",
        "rarity_score": 24.21588836709495,
        "rarity_level": "COMMON"
    },
    "30": {
        "name": "Kishu Ken #30",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/30.png",
        "dna": "e10356166ce428be987dd4d706c28793329e2c52",
        "edition": 30,
        "date": 1662952469179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "30",
        "rarity_score": 66.60941405735198,
        "rarity_level": "COMMON"
    },
    "31": {
        "name": "Kishu Ken #31",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/31.png",
        "dna": "37339ec112b758a6e8b7034cc33b4a6c0b21283f",
        "edition": 31,
        "date": 1662952537235,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "31",
        "rarity_score": 134.06126037704985,
        "rarity_level": "EPIC"
    },
    "32": {
        "name": "Kishu Ken #32",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/32.png",
        "dna": "d923d629ddebe357a9cb7678b59ea92dad082128",
        "edition": 32,
        "date": 1662952030098,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "32",
        "rarity_score": 32.11382802659301,
        "rarity_level": "COMMON"
    },
    "33": {
        "name": "Kishu Ken #33",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/33.png",
        "dna": "9a79606aa39a7ff831a4ac0f5ff4cae9b6879fdc",
        "edition": 33,
        "date": 1662952055878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "33",
        "rarity_score": 33.02439285176203,
        "rarity_level": "COMMON"
    },
    "34": {
        "name": "Kishu Ken #34",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/34.png",
        "dna": "065437eff6e3fafddc2b0cf84d2fdbe9dd0124a8",
        "edition": 34,
        "date": 1662952413544,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "34",
        "rarity_score": 95.51786381407872,
        "rarity_level": "RARE"
    },
    "35": {
        "name": "Kishu Ken #35",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/35.png",
        "dna": "4f808acdca7f6837eaa0592fe0b905cebfeea549",
        "edition": 35,
        "date": 1662951965643,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "35",
        "rarity_score": 77.91385829360513,
        "rarity_level": "COMMON"
    },
    "36": {
        "name": "Kishu Ken #36",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/36.png",
        "dna": "5720aee0722d90e44cd5d10bb288a79c1999e870",
        "edition": 36,
        "date": 1662951871921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "36",
        "rarity_score": 43.98204421864661,
        "rarity_level": "COMMON"
    },
    "37": {
        "name": "Kishu Ken #37",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/37.png",
        "dna": "5372f4fc59b827388ae0ec7db71a3a18594f10e5",
        "edition": 37,
        "date": 1662952249990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15,
        "id": "37",
        "rarity_score": 37.29336915014034,
        "rarity_level": "COMMON"
    },
    "38": {
        "name": "Kishu Ken #38",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/38.png",
        "dna": "19db43743c014cffefaffcba5be62586fa286887",
        "edition": 38,
        "date": 1662952389921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "38",
        "rarity_score": 93.88111888111888,
        "rarity_level": "RARE"
    },
    "39": {
        "name": "Kishu Ken #39",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/39.png",
        "dna": "186d4b3385f79c3709f2c1783ec2e686da2e55c7",
        "edition": 39,
        "date": 1662952225348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "39",
        "rarity_score": 61.333022780968534,
        "rarity_level": "COMMON"
    },
    "40": {
        "name": "Kishu Ken #40",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/40.png",
        "dna": "96171b9b51c043384395817e9d4d52ff8c4016eb",
        "edition": 40,
        "date": 1662952419386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "40",
        "rarity_score": 75.90885816692268,
        "rarity_level": "COMMON"
    },
    "41": {
        "name": "Kishu Ken #41",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/41.png",
        "dna": "48d4ae34fe773d659a2491d966b754251bdd72c9",
        "edition": 41,
        "date": 1662951789361,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "41",
        "rarity_score": 42.35234060881035,
        "rarity_level": "COMMON"
    },
    "42": {
        "name": "Kishu Ken #42",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/42.png",
        "dna": "f37fc21566471e42e82e3beb3fcd8d0046a8e195",
        "edition": 42,
        "date": 1662951861069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "42",
        "rarity_score": 47.08160595983733,
        "rarity_level": "COMMON"
    },
    "43": {
        "name": "Kishu Ken #43",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/43.png",
        "dna": "e183c3ab1ca2f2c385c88a7984a977727c3aa785",
        "edition": 43,
        "date": 1662951998665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.333333333333333,
        "id": "43",
        "rarity_score": 62.59874332163489,
        "rarity_level": "COMMON"
    },
    "44": {
        "name": "Kishu Ken #44",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/44.png",
        "dna": "50fc7435fc83dfa8b1ad187944d00fc604d5448a",
        "edition": 44,
        "date": 1662952250726,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "44",
        "rarity_score": 70.81495397869126,
        "rarity_level": "COMMON"
    },
    "45": {
        "name": "Kishu Ken #45",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/45.png",
        "dna": "07d93e82608cfdc0dbe7bbe936357bf47abdc85a",
        "edition": 45,
        "date": 1662952514514,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "45",
        "rarity_score": 77.68488827810862,
        "rarity_level": "COMMON"
    },
    "46": {
        "name": "Kishu Ken #46",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/46.png",
        "dna": "58057371d8f2efb335fce6ea976e1ab7cf3cf5ac",
        "edition": 46,
        "date": 1662952392292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "46",
        "rarity_score": 67.22274497503855,
        "rarity_level": "COMMON"
    },
    "47": {
        "name": "Kishu Ken #47",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/47.png",
        "dna": "2d0c371caecb0fad35427347d898c233ebc57b73",
        "edition": 47,
        "date": 1662951941852,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "47",
        "rarity_score": 42.198704778367535,
        "rarity_level": "COMMON"
    },
    "48": {
        "name": "Kishu Ken #48",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/48.png",
        "dna": "dfdbf673d1028878da005ae574e202bef1bc75c9",
        "edition": 48,
        "date": 1662952072962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "48",
        "rarity_score": 35.58463243655626,
        "rarity_level": "COMMON"
    },
    "49": {
        "name": "Kishu Ken #49",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/49.png",
        "dna": "ec791ad5a7088a3f78a900a2eb5328521faec088",
        "edition": 49,
        "date": 1662952540755,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "49",
        "rarity_score": 51.62056774960001,
        "rarity_level": "COMMON"
    },
    "50": {
        "name": "Kishu Ken #50",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/50.png",
        "dna": "a16af3c0b5dd193752ca97c57f33d542d8d385af",
        "edition": 50,
        "date": 1662951827231,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "50",
        "rarity_score": 54.616422558589555,
        "rarity_level": "COMMON"
    },
    "51": {
        "name": "Kishu Ken #51",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/51.png",
        "dna": "e1ff488cd1f3f5e221a322ec57f86f46c3c2c249",
        "edition": 51,
        "date": 1662952208689,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "51",
        "rarity_score": 124.26794258373205,
        "rarity_level": "EPIC"
    },
    "52": {
        "name": "Kishu Ken #52",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/52.png",
        "dna": "f181ffa950de1e90f5b24a20e29a94e2f9354be9",
        "edition": 52,
        "date": 1662952080329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "52",
        "rarity_score": 42.7929079827814,
        "rarity_level": "COMMON"
    },
    "53": {
        "name": "Kishu Ken #53",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/53.png",
        "dna": "d0a8c35be5e9e709c2c0f43b1432f1d7adea4304",
        "edition": 53,
        "date": 1662952107076,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "53",
        "rarity_score": 31.23924268502582,
        "rarity_level": "COMMON"
    },
    "54": {
        "name": "Kishu Ken #54",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/54.png",
        "dna": "181b32cc8d75e379d4a2c9fdc51cfef747af4417",
        "edition": 54,
        "date": 1662951957585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "54",
        "rarity_score": 105.725632877625,
        "rarity_level": "RARE"
    },
    "55": {
        "name": "Kishu Ken #55",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/55.png",
        "dna": "c79814996237f23a88faa94f4557da9e8b45bb86",
        "edition": 55,
        "date": 1662952121183,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "55",
        "rarity_score": 44.83207555496712,
        "rarity_level": "COMMON"
    },
    "56": {
        "name": "Kishu Ken #56",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/56.png",
        "dna": "a3dae08411ee49032b3ae06da2d560e2cd7eea99",
        "edition": 56,
        "date": 1662951964262,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16,
        "id": "56",
        "rarity_score": 33.39020029370045,
        "rarity_level": "COMMON"
    },
    "57": {
        "name": "Kishu Ken #57",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/57.png",
        "dna": "28e281bd2784cf5c6b1d799f43a7fe9f9e56cce9",
        "edition": 57,
        "date": 1662951849640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "57",
        "rarity_score": 92.90288256084108,
        "rarity_level": "RARE"
    },
    "58": {
        "name": "Kishu Ken #58",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/58.png",
        "dna": "a887b45aaa21d1603fd74c1090380b8a16f0068a",
        "edition": 58,
        "date": 1662951858370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.5,
        "id": "58",
        "rarity_score": 51.8534396524622,
        "rarity_level": "COMMON"
    },
    "59": {
        "name": "Kishu Ken #59",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/59.png",
        "dna": "6384babafa7f6c03e0093b495c37026af0813d48",
        "edition": 59,
        "date": 1662952517586,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "59",
        "rarity_score": 85.58006991131086,
        "rarity_level": "COMMON"
    },
    "60": {
        "name": "Kishu Ken #60",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/60.png",
        "dna": "923926fea70b2b8c8c36f3ce4f6c22d00af00c97",
        "edition": 60,
        "date": 1662952052773,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "60",
        "rarity_score": 35.517535754138144,
        "rarity_level": "COMMON"
    },
    "61": {
        "name": "Kishu Ken #61",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/61.png",
        "dna": "9c0361238ede6964dab944cd7d67fa5133480b3f",
        "edition": 61,
        "date": 1662952313171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "61",
        "rarity_score": 48.93959850447192,
        "rarity_level": "COMMON"
    },
    "62": {
        "name": "Kishu Ken #62",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/62.png",
        "dna": "36714aba1aa6bfa2a65081adfb57bc1d282cc06d",
        "edition": 62,
        "date": 1662952013623,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "62",
        "rarity_score": 41.32478851576519,
        "rarity_level": "COMMON"
    },
    "63": {
        "name": "Kishu Ken #63",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/63.png",
        "dna": "1e013a0ae7aa4731bae339c4475c7c0bd6b4ea7e",
        "edition": 63,
        "date": 1662952352161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "63",
        "rarity_score": 93.79762537657274,
        "rarity_level": "RARE"
    },
    "64": {
        "name": "Kishu Ken #64",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/64.png",
        "dna": "23000f94eca9f2bb97ce77b461594590916aeedd",
        "edition": 64,
        "date": 1662951885245,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "64",
        "rarity_score": 63.655327806534395,
        "rarity_level": "COMMON"
    },
    "65": {
        "name": "Kishu Ken #65",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/65.png",
        "dna": "bd4c59bececbdd836b44355e2d39174617490bd8",
        "edition": 65,
        "date": 1662951818840,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "65",
        "rarity_score": 50.348920769510286,
        "rarity_level": "COMMON"
    },
    "66": {
        "name": "Kishu Ken #66",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/66.png",
        "dna": "cded021c74a560b1a014882474fd9fd30b7e2563",
        "edition": 66,
        "date": 1662952040677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "66",
        "rarity_score": 39.338367493707295,
        "rarity_level": "COMMON"
    },
    "67": {
        "name": "Kishu Ken #67",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/67.png",
        "dna": "e0ae56be39fb2febb97657bf17dc88af2a166808",
        "edition": 67,
        "date": 1662952093762,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "67",
        "rarity_score": 38.97542402215299,
        "rarity_level": "COMMON"
    },
    "68": {
        "name": "Kishu Ken #68",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/68.png",
        "dna": "c8590006f33c77ef95c7ac6da2b37e8ad64756f1",
        "edition": 68,
        "date": 1662952422714,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "68",
        "rarity_score": 46.16444095312909,
        "rarity_level": "COMMON"
    },
    "69": {
        "name": "Kishu Ken #69",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/69.png",
        "dna": "2ec28e6ab7e76b36de1ad52f70d0424eb26e4f70",
        "edition": 69,
        "date": 1662952528470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "69",
        "rarity_score": 46.89920920104814,
        "rarity_level": "COMMON"
    },
    "70": {
        "name": "Kishu Ken #70",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/70.png",
        "dna": "4fce53024fbdf2747f3c0286e1ece30f9d33d663",
        "edition": 70,
        "date": 1662951909318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "70",
        "rarity_score": 47.63105650928788,
        "rarity_level": "COMMON"
    },
    "71": {
        "name": "Kishu Ken #71",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/71.png",
        "dna": "5f99e93dbf4967fa89f7604bcb70cad473679699",
        "edition": 71,
        "date": 1662952442602,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "71",
        "rarity_score": 51.715601010737586,
        "rarity_level": "COMMON"
    },
    "72": {
        "name": "Kishu Ken #72",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/72.png",
        "dna": "70454fcaa08e2a488c5b1d5e4296710804f2cb44",
        "edition": 72,
        "date": 1662952341168,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "72",
        "rarity_score": 59.61913365835423,
        "rarity_level": "COMMON"
    },
    "73": {
        "name": "Kishu Ken #73",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/73.png",
        "dna": "7584e2befdbe66b6d49ed0953e88a40bce7302d0",
        "edition": 73,
        "date": 1662952127169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "73",
        "rarity_score": 99.82198486871384,
        "rarity_level": "RARE"
    },
    "74": {
        "name": "Kishu Ken #74",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/74.png",
        "dna": "2fe2ad95cc98b1a63f8b5747f0665ba0b0c71fb4",
        "edition": 74,
        "date": 1662952105779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "74",
        "rarity_score": 44.62937278471259,
        "rarity_level": "COMMON"
    },
    "75": {
        "name": "Kishu Ken #75",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/75.png",
        "dna": "189d078462369d346d48e4e38f9edbdf298cb0a4",
        "edition": 75,
        "date": 1662951983445,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "75",
        "rarity_score": 38.12819075161566,
        "rarity_level": "COMMON"
    },
    "76": {
        "name": "Kishu Ken #76",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/76.png",
        "dna": "0cafd6b8ffdfc550754a66ca09047d66019638dd",
        "edition": 76,
        "date": 1662952558331,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "76",
        "rarity_score": 54.832539774706774,
        "rarity_level": "COMMON"
    },
    "77": {
        "name": "Kishu Ken #77",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/77.png",
        "dna": "f7bdd8e4c478fc67d89176a7772c1b4b394b0645",
        "edition": 77,
        "date": 1662952145177,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "77",
        "rarity_score": 51.202731925623496,
        "rarity_level": "COMMON"
    },
    "78": {
        "name": "Kishu Ken #78",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/78.png",
        "dna": "a436f27bacdcf229e310d4f69a6e510f153e0c15",
        "edition": 78,
        "date": 1662951835180,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "78",
        "rarity_score": 46.72352566195923,
        "rarity_level": "COMMON"
    },
    "79": {
        "name": "Kishu Ken #79",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/79.png",
        "dna": "eab8fef084fc7f58254ce0e5147af1455e094025",
        "edition": 79,
        "date": 1662952222955,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "79",
        "rarity_score": 70.86962744857482,
        "rarity_level": "COMMON"
    },
    "80": {
        "name": "Kishu Ken #80",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/80.png",
        "dna": "59f00f72aca549dd5d23a5e6c3c478e8e69ec468",
        "edition": 80,
        "date": 1662952315235,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.25,
        "id": "80",
        "rarity_score": 108.03186626616169,
        "rarity_level": "RARE"
    },
    "81": {
        "name": "Kishu Ken #81",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/81.png",
        "dna": "e275cdf7c72171ae4a8117cc2870b5ad5fd9e39e",
        "edition": 81,
        "date": 1662952498993,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "81",
        "rarity_score": 98.6936233863363,
        "rarity_level": "RARE"
    },
    "82": {
        "name": "Kishu Ken #82",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/82.png",
        "dna": "74f26373a1876d3bbcf807fa08ef02c337c5d04f",
        "edition": 82,
        "date": 1662952327916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "82",
        "rarity_score": 51.88964244206581,
        "rarity_level": "COMMON"
    },
    "83": {
        "name": "Kishu Ken #83",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/83.png",
        "dna": "3f6d3a3ddcd20395909f50cd42b3ce6cce19cb3b",
        "edition": 83,
        "date": 1662951825837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "83",
        "rarity_score": 46.08070395558766,
        "rarity_level": "COMMON"
    },
    "84": {
        "name": "Kishu Ken #84",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/84.png",
        "dna": "70614fa0af23242599bad6f28d5ea0756c5901e8",
        "edition": 84,
        "date": 1662952378117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "84",
        "rarity_score": 85.67558739783999,
        "rarity_level": "COMMON"
    },
    "85": {
        "name": "Kishu Ken #85",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/85.png",
        "dna": "45778d623cf33d0eaefaf18636a8bbece112e65a",
        "edition": 85,
        "date": 1662952079040,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "85",
        "rarity_score": 46.29925348912691,
        "rarity_level": "COMMON"
    },
    "86": {
        "name": "Kishu Ken #86",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/86.png",
        "dna": "81956914706c84151e43e38981e76f471ac36f7c",
        "edition": 86,
        "date": 1662952315947,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "86",
        "rarity_score": 85.87459723713937,
        "rarity_level": "COMMON"
    },
    "87": {
        "name": "Kishu Ken #87",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/87.png",
        "dna": "a4d50a000b132728df37ee77b79ad39200fbbaea",
        "edition": 87,
        "date": 1662952183395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.25,
        "id": "87",
        "rarity_score": 87.96540428151619,
        "rarity_level": "COMMON"
    },
    "88": {
        "name": "Kishu Ken #88",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/88.png",
        "dna": "042e271e902cd977900165358476cbd6c0cdc4fe",
        "edition": 88,
        "date": 1662952484990,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "88",
        "rarity_score": 86.18228954483168,
        "rarity_level": "COMMON"
    },
    "89": {
        "name": "Kishu Ken #89",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/89.png",
        "dna": "cf86bee144398e36ac68a1c7ea5b8c9c39d2ca80",
        "edition": 89,
        "date": 1662952420038,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "89",
        "rarity_score": 53.00531530715424,
        "rarity_level": "COMMON"
    },
    "90": {
        "name": "Kishu Ken #90",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/90.png",
        "dna": "71876d5b22487c858a16576c1d7cb39d93b1b2c3",
        "edition": 90,
        "date": 1662952433264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "90",
        "rarity_score": 93.1234425915277,
        "rarity_level": "RARE"
    },
    "91": {
        "name": "Kishu Ken #91",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/91.png",
        "dna": "a0674485f27ae9c8425ee0473ba9f73264d3b626",
        "edition": 91,
        "date": 1662952560404,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "91",
        "rarity_score": 45.927467137207906,
        "rarity_level": "COMMON"
    },
    "92": {
        "name": "Kishu Ken #92",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/92.png",
        "dna": "6c3c08b001744c98e6302ea1deb8978448db7f3a",
        "edition": 92,
        "date": 1662951802605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "92",
        "rarity_score": 69.20286605478988,
        "rarity_level": "COMMON"
    },
    "93": {
        "name": "Kishu Ken #93",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/93.png",
        "dna": "b1f5fa58cabaa4a5dee70d8d39aaef6d63175cf7",
        "edition": 93,
        "date": 1662951970613,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "93",
        "rarity_score": 55.30910692918417,
        "rarity_level": "COMMON"
    },
    "94": {
        "name": "Kishu Ken #94",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/94.png",
        "dna": "d73976f251a953afe83088492950ee520bf9fc8d",
        "edition": 94,
        "date": 1662951831222,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "94",
        "rarity_score": 57.00028247546762,
        "rarity_level": "COMMON"
    },
    "95": {
        "name": "Kishu Ken #95",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/95.png",
        "dna": "4baa40df444193c70b29635624b3f1287562be3a",
        "edition": 95,
        "date": 1662952247211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "95",
        "rarity_score": 50.98004868802832,
        "rarity_level": "COMMON"
    },
    "96": {
        "name": "Kishu Ken #96",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/96.png",
        "dna": "ee3bd9cc3c94dfb328e7129c9329fa612101cb85",
        "edition": 96,
        "date": 1662952283618,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "96",
        "rarity_score": 69.25752434374358,
        "rarity_level": "COMMON"
    },
    "97": {
        "name": "Kishu Ken #97",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/97.png",
        "dna": "b98a68c79a11bf45834014594dccb7df21634464",
        "edition": 97,
        "date": 1662952508413,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "97",
        "rarity_score": 64.44429760803489,
        "rarity_level": "COMMON"
    },
    "98": {
        "name": "Kishu Ken #98",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/98.png",
        "dna": "39ae8102ec496f017331d036564495c01574d1d0",
        "edition": 98,
        "date": 1662951919682,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "98",
        "rarity_score": 39.87168177518193,
        "rarity_level": "COMMON"
    },
    "99": {
        "name": "Kishu Ken #99",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/99.png",
        "dna": "6024a995553fb1fc49f73939e998ab3c8d0ebac3",
        "edition": 99,
        "date": 1662951890800,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "99",
        "rarity_score": 64.67524283058265,
        "rarity_level": "COMMON"
    },
    "100": {
        "name": "Kishu Ken #100",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/100.png",
        "dna": "c93906d94d6ab3f03ad332314e1f67f6a629d9cf",
        "edition": 100,
        "date": 1662952438287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "100",
        "rarity_score": 120.05049736488228,
        "rarity_level": "RARE"
    },
    "101": {
        "name": "Kishu Ken #101",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/101.png",
        "dna": "e2ef7953c6f940c305ed3da206a21639f8ebdb82",
        "edition": 101,
        "date": 1662951831995,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "101",
        "rarity_score": 85.63838127833058,
        "rarity_level": "COMMON"
    },
    "102": {
        "name": "Kishu Ken #102",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/102.png",
        "dna": "c5ebdd6ffcf877ce202efaf146bf8721de3e02f7",
        "edition": 102,
        "date": 1662952133359,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "102",
        "rarity_score": 38.59530950807449,
        "rarity_level": "COMMON"
    },
    "103": {
        "name": "Kishu Ken #103",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/103.png",
        "dna": "d5a890b5e3ecf8fe954e07a68544780535db0244",
        "edition": 103,
        "date": 1662952473764,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "103",
        "rarity_score": 56.654164016205144,
        "rarity_level": "COMMON"
    },
    "104": {
        "name": "Kishu Ken #104",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/104.png",
        "dna": "8dc4ec609d9a7f05bcedb44acf69a658b227d8d2",
        "edition": 104,
        "date": 1662952297284,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "104",
        "rarity_score": 52.315912999337975,
        "rarity_level": "COMMON"
    },
    "105": {
        "name": "Kishu Ken #105",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/105.png",
        "dna": "4cec019e05f8a4764ec77f2a4595d4ddb8964e7c",
        "edition": 105,
        "date": 1662952418678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "105",
        "rarity_score": 90.33534903100121,
        "rarity_level": "COMMON"
    },
    "106": {
        "name": "Kishu Ken #106",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/106.png",
        "dna": "ed899e70df504a6c7a9ffe69349d62300ec43a0d",
        "edition": 106,
        "date": 1662951938853,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "106",
        "rarity_score": 63.27405202261217,
        "rarity_level": "COMMON"
    },
    "107": {
        "name": "Kishu Ken #107",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/107.png",
        "dna": "ffe6e2281b0be93c63a466019bfad6d36d9460a0",
        "edition": 107,
        "date": 1662952325121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "107",
        "rarity_score": 62.82215395118621,
        "rarity_level": "COMMON"
    },
    "108": {
        "name": "Kishu Ken #108",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/108.png",
        "dna": "f2a44f9337c2466484dc53f3bdf6bfc66fee2204",
        "edition": 108,
        "date": 1662952473036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "108",
        "rarity_score": 58.067942399183345,
        "rarity_level": "COMMON"
    },
    "109": {
        "name": "Kishu Ken #109",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/109.png",
        "dna": "015c8770c2e5ce7648306a2c16c4f32bc6188733",
        "edition": 109,
        "date": 1662952464134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "109",
        "rarity_score": 103.2999164578112,
        "rarity_level": "RARE"
    },
    "110": {
        "name": "Kishu Ken #110",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/110.png",
        "dna": "b078772f65ae09d15f8971eaa9123bb87f37ea39",
        "edition": 110,
        "date": 1662952187765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "110",
        "rarity_score": 44.71259098923631,
        "rarity_level": "COMMON"
    },
    "111": {
        "name": "Kishu Ken #111",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/111.png",
        "dna": "f81ef6eec351731533b8a77790498421f14412e2",
        "edition": 111,
        "date": 1662952545167,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "111",
        "rarity_score": 66.22285779620606,
        "rarity_level": "COMMON"
    },
    "112": {
        "name": "Kishu Ken #112",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/112.png",
        "dna": "814a3a899526572a08ea557bfb4d6b5366190901",
        "edition": 112,
        "date": 1662952101405,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "112",
        "rarity_score": 39.93096008629989,
        "rarity_level": "COMMON"
    },
    "113": {
        "name": "Kishu Ken #113",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/113.png",
        "dna": "6353490b622dce2ce77a35eabc57f4fc0ef3432e",
        "edition": 113,
        "date": 1662951865223,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "113",
        "rarity_score": 41.880732545791105,
        "rarity_level": "COMMON"
    },
    "114": {
        "name": "Kishu Ken #114",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/114.png",
        "dna": "d164ead41f1a621fdafcee7ca7803aec389a971a",
        "edition": 114,
        "date": 1662951882366,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "114",
        "rarity_score": 117.99333825394122,
        "rarity_level": "RARE"
    },
    "115": {
        "name": "Kishu Ken #115",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/115.png",
        "dna": "35af86dfc067f1420054920ab6c42f6859d9594d",
        "edition": 115,
        "date": 1662952149330,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "115",
        "rarity_score": 61.71694082141843,
        "rarity_level": "COMMON"
    },
    "116": {
        "name": "Kishu Ken #116",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/116.png",
        "dna": "55d51cb5f968eb1af100561b45ba8cc22a017ee4",
        "edition": 116,
        "date": 1662952465660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "116",
        "rarity_score": 83.4115732068403,
        "rarity_level": "COMMON"
    },
    "117": {
        "name": "Kishu Ken #117",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/117.png",
        "dna": "7cb308b3d370410901d395a299ebd33358c74780",
        "edition": 117,
        "date": 1662952257988,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "117",
        "rarity_score": 62.609093656126134,
        "rarity_level": "COMMON"
    },
    "118": {
        "name": "Kishu Ken #118",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/118.png",
        "dna": "a98514442879545f1d534ebbd2297c6a5859faaa",
        "edition": 118,
        "date": 1662952259660,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "118",
        "rarity_score": 67.3108504398827,
        "rarity_level": "COMMON"
    },
    "119": {
        "name": "Kishu Ken #119",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/119.png",
        "dna": "62bc7fe95a55fcbd2f5c7627c83db5f3a4413e22",
        "edition": 119,
        "date": 1662952059674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "119",
        "rarity_score": 42.39422744095641,
        "rarity_level": "COMMON"
    },
    "120": {
        "name": "Kishu Ken #120",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/120.png",
        "dna": "30bc7c36fb58482d347d3e5fba098735dd9fdfdd",
        "edition": 120,
        "date": 1662952425578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "120",
        "rarity_score": 64.47921447921448,
        "rarity_level": "COMMON"
    },
    "121": {
        "name": "Kishu Ken #121",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/121.png",
        "dna": "c2055d1361007faad1dcf195533e61a558422566",
        "edition": 121,
        "date": 1662952491138,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "121",
        "rarity_score": 66.8805168805169,
        "rarity_level": "COMMON"
    },
    "122": {
        "name": "Kishu Ken #122",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/122.png",
        "dna": "a93d335329bdc97abf84a509af1cc63cca43f0b3",
        "edition": 122,
        "date": 1662951948150,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "122",
        "rarity_score": 48.60562726499933,
        "rarity_level": "COMMON"
    },
    "123": {
        "name": "Kishu Ken #123",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/123.png",
        "dna": "b14a7e74bd8404949efea1407d1cefa98e91ef72",
        "edition": 123,
        "date": 1662951977719,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "123",
        "rarity_score": 44.78933804256942,
        "rarity_level": "COMMON"
    },
    "124": {
        "name": "Kishu Ken #124",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/124.png",
        "dna": "b108021738ef810d3b7d91977211725e8b3403c5",
        "edition": 124,
        "date": 1662952119232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "124",
        "rarity_score": 31.503792949576084,
        "rarity_level": "COMMON"
    },
    "125": {
        "name": "Kishu Ken #125",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/125.png",
        "dna": "712c6d296583453f1a3bd5e561ef88833031a65a",
        "edition": 125,
        "date": 1662952470178,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5,
        "id": "125",
        "rarity_score": 149.00930291831273,
        "rarity_level": "EPIC"
    },
    "126": {
        "name": "Kishu Ken #126",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/126.png",
        "dna": "30dff852e63cc8a0b6a14b5f87af1f6987b05102",
        "edition": 126,
        "date": 1662951837953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15,
        "id": "126",
        "rarity_score": 36.987534034178644,
        "rarity_level": "COMMON"
    },
    "127": {
        "name": "Kishu Ken #127",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/127.png",
        "dna": "c40d5a90e257c948aab993fbfc52308ffca208fe",
        "edition": 127,
        "date": 1662951859225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "127",
        "rarity_score": 99.52968457641354,
        "rarity_level": "RARE"
    },
    "128": {
        "name": "Kishu Ken #128",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/128.png",
        "dna": "294acb772dc81768b24f1637be6de5628713629c",
        "edition": 128,
        "date": 1662952058405,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "128",
        "rarity_score": 42.30077361967929,
        "rarity_level": "COMMON"
    },
    "129": {
        "name": "Kishu Ken #129",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/129.png",
        "dna": "e627630f72a13326c7d5ddf3636814cbe12e66b3",
        "edition": 129,
        "date": 1662951915134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "129",
        "rarity_score": 84.04811909484806,
        "rarity_level": "COMMON"
    },
    "130": {
        "name": "Kishu Ken #130",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/130.png",
        "dna": "cef323030874f54d5e2fc6c4f4763836706724c8",
        "edition": 130,
        "date": 1662951873415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "130",
        "rarity_score": 96.0916311383601,
        "rarity_level": "RARE"
    },
    "131": {
        "name": "Kishu Ken #131",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/131.png",
        "dna": "3a8e427050c30fd07269995469569442ddcd8726",
        "edition": 131,
        "date": 1662952060919,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "131",
        "rarity_score": 38.82996920971604,
        "rarity_level": "COMMON"
    },
    "132": {
        "name": "Kishu Ken #132",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/132.png",
        "dna": "36da049c19137a8c13e601f4012b6ea9f79fe238",
        "edition": 132,
        "date": 1662951826528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "132",
        "rarity_score": 90.8966560865295,
        "rarity_level": "RARE"
    },
    "133": {
        "name": "Kishu Ken #133",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/133.png",
        "dna": "f216d1c9d47760b8fee39de556ec5da88b41bbf1",
        "edition": 133,
        "date": 1662952382315,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.25,
        "id": "133",
        "rarity_score": 99.9428125687924,
        "rarity_level": "RARE"
    },
    "134": {
        "name": "Kishu Ken #134",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/134.png",
        "dna": "412ec62d30b27267801702b7525eacdc848da347",
        "edition": 134,
        "date": 1662952127715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "134",
        "rarity_score": 42.06611391803774,
        "rarity_level": "COMMON"
    },
    "135": {
        "name": "Kishu Ken #135",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/135.png",
        "dna": "52fb1998f498448f53cc0d22040a173e5b982fb0",
        "edition": 135,
        "date": 1662951810668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "135",
        "rarity_score": 47.39424605930462,
        "rarity_level": "COMMON"
    },
    "136": {
        "name": "Kishu Ken #136",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/136.png",
        "dna": "63eea8f97d209fba5c1b5be7d76cbea67bf5f390",
        "edition": 136,
        "date": 1662951797053,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "136",
        "rarity_score": 76.95833840412155,
        "rarity_level": "COMMON"
    },
    "137": {
        "name": "Kishu Ken #137",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/137.png",
        "dna": "36f70da7aac0417fd0c03ae17c8a79f9d2da230b",
        "edition": 137,
        "date": 1662952029435,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "137",
        "rarity_score": 29.146879383481775,
        "rarity_level": "COMMON"
    },
    "138": {
        "name": "Kishu Ken #138",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/138.png",
        "dna": "55751ba6ef26d832dee55c0688d1e577c290a62f",
        "edition": 138,
        "date": 1662952270822,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "138",
        "rarity_score": 107.5060706639654,
        "rarity_level": "RARE"
    },
    "139": {
        "name": "Kishu Ken #139",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/139.png",
        "dna": "110046c0986d9f5e078bdfcff99b4f135fc32233",
        "edition": 139,
        "date": 1662952075142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "139",
        "rarity_score": 39.4804745272035,
        "rarity_level": "COMMON"
    },
    "140": {
        "name": "Kishu Ken #140",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/140.png",
        "dna": "94989ea437247791dd80ef63cedd98069911a91a",
        "edition": 140,
        "date": 1662952471607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "140",
        "rarity_score": 52.82250557479915,
        "rarity_level": "COMMON"
    },
    "141": {
        "name": "Kishu Ken #141",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/141.png",
        "dna": "485021b31853f4a8e2f9d521a9c1f130171d9689",
        "edition": 141,
        "date": 1662951791821,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "141",
        "rarity_score": 58.61182003354901,
        "rarity_level": "COMMON"
    },
    "142": {
        "name": "Kishu Ken #142",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/142.png",
        "dna": "b78f3ee645dfd823444765d7e35b153e630f68d8",
        "edition": 142,
        "date": 1662952307872,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "142",
        "rarity_score": 78.43752923844457,
        "rarity_level": "COMMON"
    },
    "143": {
        "name": "Kishu Ken #143",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/143.png",
        "dna": "6c5d9019b81d881a2d0096c9c0c61d39e1c16ff8",
        "edition": 143,
        "date": 1662952151210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "143",
        "rarity_score": 58.47676419965577,
        "rarity_level": "COMMON"
    },
    "144": {
        "name": "Kishu Ken #144",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/144.png",
        "dna": "3b14d5a5584782830e8f94bdd20a04f29ce02a5e",
        "edition": 144,
        "date": 1662951811369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "144",
        "rarity_score": 42.098936393287424,
        "rarity_level": "COMMON"
    },
    "145": {
        "name": "Kishu Ken #145",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/145.png",
        "dna": "c8731ad355ded64451071128aa43864970188976",
        "edition": 145,
        "date": 1662952004232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "145",
        "rarity_score": 31.06031332013074,
        "rarity_level": "COMMON"
    },
    "146": {
        "name": "Kishu Ken #146",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/146.png",
        "dna": "743ebb93d8e4d3dcbfbbaf543e5b03774ecf8b67",
        "edition": 146,
        "date": 1662952395931,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "146",
        "rarity_score": 75.61786600496278,
        "rarity_level": "COMMON"
    },
    "147": {
        "name": "Kishu Ken #147",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/147.png",
        "dna": "4bc7ab579b238b304057bb144265f9ef49ee683d",
        "edition": 147,
        "date": 1662951835957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "147",
        "rarity_score": 62.07483922683136,
        "rarity_level": "COMMON"
    },
    "148": {
        "name": "Kishu Ken #148",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/148.png",
        "dna": "beb9acf3a055ed57c0757881ffa677b4b0ab5a3b",
        "edition": 148,
        "date": 1662951900384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "148",
        "rarity_score": 47.053682504158886,
        "rarity_level": "COMMON"
    },
    "149": {
        "name": "Kishu Ken #149",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/149.png",
        "dna": "f11f18cc95f70cfefbf8e50c48297a51c4bf60bb",
        "edition": 149,
        "date": 1662952251436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "149",
        "rarity_score": 57.25284158408253,
        "rarity_level": "COMMON"
    },
    "150": {
        "name": "Kishu Ken #150",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/150.png",
        "dna": "3bb0f0999aac2f29224d2abe888e7bff54e79703",
        "edition": 150,
        "date": 1662951799556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "150",
        "rarity_score": 41.449941194368115,
        "rarity_level": "COMMON"
    },
    "151": {
        "name": "Kishu Ken #151",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/151.png",
        "dna": "c2655176f6f720ff07f5becddf14f5757b7281e2",
        "edition": 151,
        "date": 1662951902678,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.75,
        "id": "151",
        "rarity_score": 43.012082048747686,
        "rarity_level": "COMMON"
    },
    "152": {
        "name": "Kishu Ken #152",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/152.png",
        "dna": "f30772a43480d255924cc2bacde593df803bfd57",
        "edition": 152,
        "date": 1662952280484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "152",
        "rarity_score": 89.60352367123657,
        "rarity_level": "COMMON"
    },
    "153": {
        "name": "Kishu Ken #153",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/153.png",
        "dna": "8d3a79f951508eb4ec3f3a3bccfdc261f7cae013",
        "edition": 153,
        "date": 1662952333279,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "153",
        "rarity_score": 72.92401572493105,
        "rarity_level": "COMMON"
    },
    "154": {
        "name": "Kishu Ken #154",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/154.png",
        "dna": "ae11d3a6853a8d161e2e9539a4e35e7473f8792d",
        "edition": 154,
        "date": 1662952429822,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "154",
        "rarity_score": 70.18038316133925,
        "rarity_level": "COMMON"
    },
    "155": {
        "name": "Kishu Ken #155",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/155.png",
        "dna": "17f4a1a70785f61f7b9ea97122a846311b507846",
        "edition": 155,
        "date": 1662952344794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "155",
        "rarity_score": 63.57300399503647,
        "rarity_level": "COMMON"
    },
    "156": {
        "name": "Kishu Ken #156",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/156.png",
        "dna": "66e400bc9a1f360a7603b0386ff0cf2d4738f617",
        "edition": 156,
        "date": 1662952016570,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "156",
        "rarity_score": 43.34976350510331,
        "rarity_level": "COMMON"
    },
    "157": {
        "name": "Kishu Ken #157",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/157.png",
        "dna": "672dc4fea368a0ce63111df0e67b2115d161d40b",
        "edition": 157,
        "date": 1662952232357,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "157",
        "rarity_score": 47.543658227083206,
        "rarity_level": "COMMON"
    },
    "158": {
        "name": "Kishu Ken #158",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/158.png",
        "dna": "782b7049bcefc0696206bf801e9ec3230a211785",
        "edition": 158,
        "date": 1662952084231,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "158",
        "rarity_score": 38.62239013720422,
        "rarity_level": "COMMON"
    },
    "159": {
        "name": "Kishu Ken #159",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/159.png",
        "dna": "ca3fe7c4760a530add4f729046009223974ae0eb",
        "edition": 159,
        "date": 1662952147807,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "159",
        "rarity_score": 42.46777319066476,
        "rarity_level": "COMMON"
    },
    "160": {
        "name": "Kishu Ken #160",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/160.png",
        "dna": "cda7f4fd5d7477efa688c1c3ea55344e266625d8",
        "edition": 160,
        "date": 1662952261298,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "160",
        "rarity_score": 54.74840085287846,
        "rarity_level": "COMMON"
    },
    "161": {
        "name": "Kishu Ken #161",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/161.png",
        "dna": "5bcc09023f9a764f855d3783c9ee38e54ebf6309",
        "edition": 161,
        "date": 1662952085724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "161",
        "rarity_score": 72.04420709093607,
        "rarity_level": "COMMON"
    },
    "162": {
        "name": "Kishu Ken #162",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/162.png",
        "dna": "b46e90a364935d1b29c72bcc81e5779aa4051d2a",
        "edition": 162,
        "date": 1662951905302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "162",
        "rarity_score": 46.39839086984258,
        "rarity_level": "COMMON"
    },
    "163": {
        "name": "Kishu Ken #163",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/163.png",
        "dna": "a3a390eb7f8cd353659d6656ae3c4a5a32f2b54d",
        "edition": 163,
        "date": 1662952128346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "163",
        "rarity_score": 144.19104991394147,
        "rarity_level": "EPIC"
    },
    "164": {
        "name": "Kishu Ken #164",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/164.png",
        "dna": "2290d7f69e7a0c3652992141022e81ab1de8499b",
        "edition": 164,
        "date": 1662952534906,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "164",
        "rarity_score": 55.73625848855207,
        "rarity_level": "COMMON"
    },
    "165": {
        "name": "Kishu Ken #165",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/165.png",
        "dna": "16a934cf78d394953531dc6f2ef775e3dfd54aac",
        "edition": 165,
        "date": 1662952092473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "165",
        "rarity_score": 46.968827691719255,
        "rarity_level": "COMMON"
    },
    "166": {
        "name": "Kishu Ken #166",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/166.png",
        "dna": "b3c9b709337d4e4ce0ede858a092b072b19fb2a6",
        "edition": 166,
        "date": 1662952061560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.333333333333334,
        "id": "166",
        "rarity_score": 27.26377155812259,
        "rarity_level": "COMMON"
    },
    "167": {
        "name": "Kishu Ken #167",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/167.png",
        "dna": "8c936b143b394d25d0ae5002b2e788fe98dc2ff0",
        "edition": 167,
        "date": 1662952020743,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "167",
        "rarity_score": 144.74050046339204,
        "rarity_level": "EPIC"
    },
    "168": {
        "name": "Kishu Ken #168",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/168.png",
        "dna": "fb9b2a1043477b35346e7c5c804fe5c60cf31293",
        "edition": 168,
        "date": 1662952311740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "168",
        "rarity_score": 60.4025887503569,
        "rarity_level": "COMMON"
    },
    "169": {
        "name": "Kishu Ken #169",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/169.png",
        "dna": "91c7c26a787cd9686fc493b1283962a6e67922d0",
        "edition": 169,
        "date": 1662951996826,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "169",
        "rarity_score": 42.39422744095641,
        "rarity_level": "COMMON"
    },
    "170": {
        "name": "Kishu Ken #170",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/170.png",
        "dna": "3a9835fcffcfa81b9a4476cd9ac4305547087d36",
        "edition": 170,
        "date": 1662952450960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "170",
        "rarity_score": 81.76340653875955,
        "rarity_level": "COMMON"
    },
    "171": {
        "name": "Kishu Ken #171",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/171.png",
        "dna": "0f0adb7184f2dd7ef377d1a24c787b74d3323667",
        "edition": 171,
        "date": 1662952055294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "171",
        "rarity_score": 38.71087375760273,
        "rarity_level": "COMMON"
    },
    "172": {
        "name": "Kishu Ken #172",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/172.png",
        "dna": "ad46de7ff987e96d226836eabab5fee70ad0d140",
        "edition": 172,
        "date": 1662952053344,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "172",
        "rarity_score": 49.95195018703688,
        "rarity_level": "COMMON"
    },
    "173": {
        "name": "Kishu Ken #173",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/173.png",
        "dna": "10a84601c55a1247d70137c8c84a456ab95e8ebe",
        "edition": 173,
        "date": 1662952498272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.5,
        "id": "173",
        "rarity_score": 53.86530770874378,
        "rarity_level": "COMMON"
    },
    "174": {
        "name": "Kishu Ken #174",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/174.png",
        "dna": "8a9d0e9fc1b2ca38c69a4c5139ca54315a48ee2d",
        "edition": 174,
        "date": 1662951803986,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "174",
        "rarity_score": 59.73629719065931,
        "rarity_level": "COMMON"
    },
    "175": {
        "name": "Kishu Ken #175",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/175.png",
        "dna": "2d78f17ab54bcab46d75e58476951359da1c680a",
        "edition": 175,
        "date": 1662952308814,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "175",
        "rarity_score": 133.86390217969165,
        "rarity_level": "EPIC"
    },
    "176": {
        "name": "Kishu Ken #176",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/176.png",
        "dna": "59e64005dd6faa818e7ca210db8d10f9074e1bab",
        "edition": 176,
        "date": 1662951991183,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "176",
        "rarity_score": 89.13730929264909,
        "rarity_level": "COMMON"
    },
    "177": {
        "name": "Kishu Ken #177",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/177.png",
        "dna": "0813c50002f11311c2246de2d0ec2ca5b181728b",
        "edition": 177,
        "date": 1662952148603,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "177",
        "rarity_score": 29.4492383274697,
        "rarity_level": "COMMON"
    },
    "178": {
        "name": "Kishu Ken #178",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/178.png",
        "dna": "a45c74dfe6af716336d75a1945b8779f5ef06297",
        "edition": 178,
        "date": 1662951928443,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "178",
        "rarity_score": 57.90847719284926,
        "rarity_level": "COMMON"
    },
    "179": {
        "name": "Kishu Ken #179",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/179.png",
        "dna": "d0a5ac65dc4cc3c292a4b9b5765d2e6dcfa1c517",
        "edition": 179,
        "date": 1662952307227,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "179",
        "rarity_score": 98.72677693462423,
        "rarity_level": "RARE"
    },
    "180": {
        "name": "Kishu Ken #180",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/180.png",
        "dna": "9449fb81c767ea857a5d8c54127a5f3de4443405",
        "edition": 180,
        "date": 1662952496235,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "180",
        "rarity_score": 60.77098465651025,
        "rarity_level": "COMMON"
    },
    "181": {
        "name": "Kishu Ken #181",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/181.png",
        "dna": "4aeef2de7b746844df1f06d5a914bdd347ce786b",
        "edition": 181,
        "date": 1662951891509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "181",
        "rarity_score": 39.93928157409899,
        "rarity_level": "COMMON"
    },
    "182": {
        "name": "Kishu Ken #182",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/182.png",
        "dna": "ccef55e783b5399d32c4c24c35bde8bf3696ddf5",
        "edition": 182,
        "date": 1662951924687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "182",
        "rarity_score": 47.27114701557394,
        "rarity_level": "COMMON"
    },
    "183": {
        "name": "Kishu Ken #183",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/183.png",
        "dna": "7d8619de6859cdc7efbc4a4bdd20d5e166be2375",
        "edition": 183,
        "date": 1662951828734,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "183",
        "rarity_score": 42.54328674565593,
        "rarity_level": "COMMON"
    },
    "184": {
        "name": "Kishu Ken #184",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/184.png",
        "dna": "ee02ab3256e662ad9ff8dc343b5d649e7a515985",
        "edition": 184,
        "date": 1662952190171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.75,
        "id": "184",
        "rarity_score": 137.9120879120879,
        "rarity_level": "EPIC"
    },
    "185": {
        "name": "Kishu Ken #185",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/185.png",
        "dna": "6a3337f820772180849f478aa05a552e3db68e88",
        "edition": 185,
        "date": 1662951993278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "185",
        "rarity_score": 29.411429648032037,
        "rarity_level": "COMMON"
    },
    "186": {
        "name": "Kishu Ken #186",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/186.png",
        "dna": "a1973e3f460905b2cea6c58f901d914a5317cda0",
        "edition": 186,
        "date": 1662951901716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "186",
        "rarity_score": 50.56825250088484,
        "rarity_level": "COMMON"
    },
    "187": {
        "name": "Kishu Ken #187",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/187.png",
        "dna": "fe98a4b9ed52ff3bd810252924358ac92b6c6c20",
        "edition": 187,
        "date": 1662951832767,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "187",
        "rarity_score": 48.64219887880127,
        "rarity_level": "COMMON"
    },
    "188": {
        "name": "Kishu Ken #188",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/188.png",
        "dna": "c435d861acddfee4ee4b601a12c746771c65aa5b",
        "edition": 188,
        "date": 1662952138980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "188",
        "rarity_score": 60.25928841462822,
        "rarity_level": "COMMON"
    },
    "189": {
        "name": "Kishu Ken #189",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/189.png",
        "dna": "6dbd99e310b98ba96ec0d9a54b1edaf1359e923f",
        "edition": 189,
        "date": 1662952426323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.75,
        "id": "189",
        "rarity_score": 110.59492672395899,
        "rarity_level": "RARE"
    },
    "190": {
        "name": "Kishu Ken #190",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/190.png",
        "dna": "f85370b91ac440541d391ff7dfa3750f9312b8d6",
        "edition": 190,
        "date": 1662952014503,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.333333333333334,
        "id": "190",
        "rarity_score": 33.83777012214219,
        "rarity_level": "COMMON"
    },
    "191": {
        "name": "Kishu Ken #191",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/191.png",
        "dna": "3809d763b278f93f7bb088ccef5d5455760b83d2",
        "edition": 191,
        "date": 1662951838824,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "191",
        "rarity_score": 65.34311612620988,
        "rarity_level": "COMMON"
    },
    "192": {
        "name": "Kishu Ken #192",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/192.png",
        "dna": "369ce58893a4377a1143653fbab42759138facef",
        "edition": 192,
        "date": 1662952555992,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.25,
        "id": "192",
        "rarity_score": 92.85437489305593,
        "rarity_level": "RARE"
    },
    "193": {
        "name": "Kishu Ken #193",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/193.png",
        "dna": "9e7eec0b1fbf6d381055a84579617512a9529550",
        "edition": 193,
        "date": 1662951941110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "193",
        "rarity_score": 57.805085960425764,
        "rarity_level": "COMMON"
    },
    "194": {
        "name": "Kishu Ken #194",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/194.png",
        "dna": "3844ab2317f40f816382e6328d2e169ccb725e4e",
        "edition": 194,
        "date": 1662952128988,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "194",
        "rarity_score": 35.86953272145655,
        "rarity_level": "COMMON"
    },
    "195": {
        "name": "Kishu Ken #195",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/195.png",
        "dna": "613d09ce34e8bf85ee7f00090f0cd2a7a509190e",
        "edition": 195,
        "date": 1662952093112,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15,
        "id": "195",
        "rarity_score": 27.20318703055621,
        "rarity_level": "COMMON"
    },
    "196": {
        "name": "Kishu Ken #196",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/196.png",
        "dna": "73f6dba3d20c5008c9eedd107df015ce9f958a33",
        "edition": 196,
        "date": 1662952387546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "196",
        "rarity_score": 60.34605328822029,
        "rarity_level": "COMMON"
    },
    "197": {
        "name": "Kishu Ken #197",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/197.png",
        "dna": "2d07cf58902c2343e765f540aa7e27873730040a",
        "edition": 197,
        "date": 1662951814523,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "197",
        "rarity_score": 63.24840603149979,
        "rarity_level": "COMMON"
    },
    "198": {
        "name": "Kishu Ken #198",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/198.png",
        "dna": "40070423ed27950ee7a35da823497aef94aa56a8",
        "edition": 198,
        "date": 1662952334742,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "198",
        "rarity_score": 61.4138727764149,
        "rarity_level": "COMMON"
    },
    "199": {
        "name": "Kishu Ken #199",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/199.png",
        "dna": "f4ee95264b83c4610912aa6498637b5c83d9dc05",
        "edition": 199,
        "date": 1662952523744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "199",
        "rarity_score": 61.185470385154815,
        "rarity_level": "COMMON"
    },
    "200": {
        "name": "Kishu Ken #200",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/200.png",
        "dna": "6aa77813f0d91a91afa3277670871b36f973f81b",
        "edition": 200,
        "date": 1662952223684,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "200",
        "rarity_score": 131.36574074074073,
        "rarity_level": "EPIC"
    },
    "201": {
        "name": "Kishu Ken #201",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/201.png",
        "dna": "e958a547ffb4751beb5acd8d6ad9f6f321f82dda",
        "edition": 201,
        "date": 1662952239530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "201",
        "rarity_score": 93.55954762296804,
        "rarity_level": "RARE"
    },
    "202": {
        "name": "Kishu Ken #202",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/202.png",
        "dna": "1c8c92d7b8bbf5dbd0eeb27334425b89663a695c",
        "edition": 202,
        "date": 1662952252366,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "202",
        "rarity_score": 99.58040027605244,
        "rarity_level": "RARE"
    },
    "203": {
        "name": "Kishu Ken #203",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/203.png",
        "dna": "beba732bc548ba32b35f5f9c2652a32787f11ac3",
        "edition": 203,
        "date": 1662952367729,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "203",
        "rarity_score": 53.720482824960435,
        "rarity_level": "COMMON"
    },
    "204": {
        "name": "Kishu Ken #204",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/204.png",
        "dna": "07b459a4d844e5c04e9b651614437bcdd2e865ec",
        "edition": 204,
        "date": 1662952235415,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "204",
        "rarity_score": 44.293507772985386,
        "rarity_level": "COMMON"
    },
    "205": {
        "name": "Kishu Ken #205",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/205.png",
        "dna": "4130f16a6d6075a3b3f9f5dd8d615a947d86f146",
        "edition": 205,
        "date": 1662951946712,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "205",
        "rarity_score": 62.41287656408314,
        "rarity_level": "COMMON"
    },
    "206": {
        "name": "Kishu Ken #206",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/206.png",
        "dna": "90cfe6c2592101b15f3751c04f9277cabe61fc6b",
        "edition": 206,
        "date": 1662952347598,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "206",
        "rarity_score": 65.02866502866503,
        "rarity_level": "COMMON"
    },
    "207": {
        "name": "Kishu Ken #207",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/207.png",
        "dna": "4c10d88b162bd21ed69e054f662e827df718508c",
        "edition": 207,
        "date": 1662952129569,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.333333333333334,
        "id": "207",
        "rarity_score": 39.351283635655705,
        "rarity_level": "COMMON"
    },
    "208": {
        "name": "Kishu Ken #208",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/208.png",
        "dna": "18517c57afd4deff3b301009e78787f6af1728ef",
        "edition": 208,
        "date": 1662952559672,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "208",
        "rarity_score": 106.53108100651141,
        "rarity_level": "RARE"
    },
    "209": {
        "name": "Kishu Ken #209",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/209.png",
        "dna": "bdb088992060f0f978a85a81834ab61106685232",
        "edition": 209,
        "date": 1662952109407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "209",
        "rarity_score": 38.219934132699116,
        "rarity_level": "COMMON"
    },
    "210": {
        "name": "Kishu Ken #210",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/210.png",
        "dna": "d3ba5e4dde472a253b60f4bcba79da53cb7b37b2",
        "edition": 210,
        "date": 1662951840292,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "210",
        "rarity_score": 54.04853442425291,
        "rarity_level": "COMMON"
    },
    "211": {
        "name": "Kishu Ken #211",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/211.png",
        "dna": "ec42391903cba357326c8771fe7c82094526a111",
        "edition": 211,
        "date": 1662952461967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "211",
        "rarity_score": 72.26563096128314,
        "rarity_level": "COMMON"
    },
    "212": {
        "name": "Kishu Ken #212",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/212.png",
        "dna": "17b4587c6e85434c17dc6394d6ffbe0386acce8d",
        "edition": 212,
        "date": 1662952050249,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "212",
        "rarity_score": 33.16713434289557,
        "rarity_level": "COMMON"
    },
    "213": {
        "name": "Kishu Ken #213",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/213.png",
        "dna": "fc9c448ba166f2453ce868ea9c3cb79b3b87bce2",
        "edition": 213,
        "date": 1662952440189,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "213",
        "rarity_score": 83.04659787475259,
        "rarity_level": "COMMON"
    },
    "214": {
        "name": "Kishu Ken #214",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/214.png",
        "dna": "2aa4525f9936ff8d2601722470459d267d2e03b2",
        "edition": 214,
        "date": 1662952142228,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "214",
        "rarity_score": 37.60989905568219,
        "rarity_level": "COMMON"
    },
    "215": {
        "name": "Kishu Ken #215",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/215.png",
        "dna": "0dc853fe3485f3ffac7cdb17fab16d931eeffd9f",
        "edition": 215,
        "date": 1662952011456,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15,
        "id": "215",
        "rarity_score": 26.918286745655926,
        "rarity_level": "COMMON"
    },
    "216": {
        "name": "Kishu Ken #216",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/216.png",
        "dna": "6894e9ada9622008cdbea34a7d99457dc5eab9a2",
        "edition": 216,
        "date": 1662952461242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "216",
        "rarity_score": 97.37762237762237,
        "rarity_level": "RARE"
    },
    "217": {
        "name": "Kishu Ken #217",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/217.png",
        "dna": "625ccda02cc5f16734fe7109b45282b2a74da19f",
        "edition": 217,
        "date": 1662952025887,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "217",
        "rarity_score": 43.385500575373996,
        "rarity_level": "COMMON"
    },
    "218": {
        "name": "Kishu Ken #218",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/218.png",
        "dna": "03b6a22914a035f89d6bd66a965c21e3f1240144",
        "edition": 218,
        "date": 1662952341874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "218",
        "rarity_score": 81.54639133736595,
        "rarity_level": "COMMON"
    },
    "219": {
        "name": "Kishu Ken #219",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/219.png",
        "dna": "c6ffa0bcf8ed3242be5ad1e211c261b1981b61fb",
        "edition": 219,
        "date": 1662952046072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "219",
        "rarity_score": 31.78869323447637,
        "rarity_level": "COMMON"
    },
    "220": {
        "name": "Kishu Ken #220",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/220.png",
        "dna": "d76a2a397ae9082bc79fe5bb7635bfa2b5074625",
        "edition": 220,
        "date": 1662952334012,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "220",
        "rarity_score": 44.0086074880851,
        "rarity_level": "COMMON"
    },
    "221": {
        "name": "Kishu Ken #221",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/221.png",
        "dna": "80d02f82e4cb73b3170fc26f6e054fe6b94d5803",
        "edition": 221,
        "date": 1662952099874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "221",
        "rarity_score": 68.31385336058233,
        "rarity_level": "COMMON"
    },
    "222": {
        "name": "Kishu Ken #222",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/222.png",
        "dna": "cf7d99472ee8abe581df2e7b9e4bec54604d5274",
        "edition": 222,
        "date": 1662952212062,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "222",
        "rarity_score": 68.16644174538911,
        "rarity_level": "COMMON"
    },
    "223": {
        "name": "Kishu Ken #223",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/223.png",
        "dna": "666414a9d2f6c663cbaa0fac6a117e2a00e9d98c",
        "edition": 223,
        "date": 1662952038482,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "223",
        "rarity_score": 37.76524728006136,
        "rarity_level": "COMMON"
    },
    "224": {
        "name": "Kishu Ken #224",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/224.png",
        "dna": "0c8f9406634b97cf80e64228af43c85eea4e7f2c",
        "edition": 224,
        "date": 1662951976276,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "224",
        "rarity_score": 62.33067864679055,
        "rarity_level": "COMMON"
    },
    "225": {
        "name": "Kishu Ken #225",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/225.png",
        "dna": "56d4776cad3bf07ef92c514f975ad52044968030",
        "edition": 225,
        "date": 1662951844687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "225",
        "rarity_score": 107.40410696898039,
        "rarity_level": "RARE"
    },
    "226": {
        "name": "Kishu Ken #226",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/226.png",
        "dna": "af73fc6b15686b0ed2b99cc2cd7b1bafc3e409e2",
        "edition": 226,
        "date": 1662952337540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "226",
        "rarity_score": 95.82247492695255,
        "rarity_level": "RARE"
    },
    "227": {
        "name": "Kishu Ken #227",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/227.png",
        "dna": "f27849809e0b4b479e868cf6a6ee172c428c5b2a",
        "edition": 227,
        "date": 1662952526015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "227",
        "rarity_score": 53.55837695172708,
        "rarity_level": "COMMON"
    },
    "228": {
        "name": "Kishu Ken #228",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/228.png",
        "dna": "e640a7d8472e8abe941abb66f1844c59a17f5212",
        "edition": 228,
        "date": 1662951960496,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "228",
        "rarity_score": 51.13703461221976,
        "rarity_level": "COMMON"
    },
    "229": {
        "name": "Kishu Ken #229",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/229.png",
        "dna": "6dd614cd358b6f030c4cb37b93ccdd6561e17950",
        "edition": 229,
        "date": 1662951801196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "229",
        "rarity_score": 57.94164298837196,
        "rarity_level": "COMMON"
    },
    "230": {
        "name": "Kishu Ken #230",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/230.png",
        "dna": "15365ec0c2a06c11a107f1134453c20a304a6558",
        "edition": 230,
        "date": 1662952110912,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "230",
        "rarity_score": 28.536844306464847,
        "rarity_level": "COMMON"
    },
    "231": {
        "name": "Kishu Ken #231",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/231.png",
        "dna": "35779ad4332abe42025d61f2fb02e72f1cf323a3",
        "edition": 231,
        "date": 1662951792755,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "231",
        "rarity_score": 51.32715477293791,
        "rarity_level": "COMMON"
    },
    "232": {
        "name": "Kishu Ken #232",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/232.png",
        "dna": "50dba53e84b321937b5c32e15567fcba6d9fa0da",
        "edition": 232,
        "date": 1662952227676,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "232",
        "rarity_score": 45.56973384637916,
        "rarity_level": "COMMON"
    },
    "233": {
        "name": "Kishu Ken #233",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/233.png",
        "dna": "9319332ad81c08be6466ea67405701aa109e3ecf",
        "edition": 233,
        "date": 1662951868376,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "233",
        "rarity_score": 50.86031534532533,
        "rarity_level": "COMMON"
    },
    "234": {
        "name": "Kishu Ken #234",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/234.png",
        "dna": "7ed169a3d15867000b1e8240f6beca690deac8da",
        "edition": 234,
        "date": 1662952137116,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.666666666666667,
        "id": "234",
        "rarity_score": 62.59874332163489,
        "rarity_level": "COMMON"
    },
    "235": {
        "name": "Kishu Ken #235",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/235.png",
        "dna": "2f8911cd47dd7c9261e33dbba336dd99a627878a",
        "edition": 235,
        "date": 1662952432607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "235",
        "rarity_score": 75.28248587570621,
        "rarity_level": "COMMON"
    },
    "236": {
        "name": "Kishu Ken #236",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/236.png",
        "dna": "0be9310c832b7446f6c8cd85f0cd9efcf17c2687",
        "edition": 236,
        "date": 1662952042202,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "236",
        "rarity_score": 36.78726010616578,
        "rarity_level": "COMMON"
    },
    "237": {
        "name": "Kishu Ken #237",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/237.png",
        "dna": "52bca8e1d4d7ac444187f5a24c82368d78336b8f",
        "edition": 237,
        "date": 1662952057153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "237",
        "rarity_score": 43.845844080930775,
        "rarity_level": "COMMON"
    },
    "238": {
        "name": "Kishu Ken #238",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/238.png",
        "dna": "f840883f587f740785bf02efeb442dec8074e4eb",
        "edition": 238,
        "date": 1662952537921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "238",
        "rarity_score": 91.9983202061675,
        "rarity_level": "RARE"
    },
    "239": {
        "name": "Kishu Ken #239",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/239.png",
        "dna": "c5719dade104d664f7512815e23890bfd29f4872",
        "edition": 239,
        "date": 1662952118587,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "239",
        "rarity_score": 36.17722502914886,
        "rarity_level": "COMMON"
    },
    "240": {
        "name": "Kishu Ken #240",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/240.png",
        "dna": "badc1436d794ffc12770bf957b872c66117891a2",
        "edition": 240,
        "date": 1662952276508,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "240",
        "rarity_score": 78.46109167083245,
        "rarity_level": "COMMON"
    },
    "241": {
        "name": "Kishu Ken #241",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/241.png",
        "dna": "39a9a45da59540a78372625d15e7720a4ea858c0",
        "edition": 241,
        "date": 1662952512929,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "241",
        "rarity_score": 56.81924651694447,
        "rarity_level": "COMMON"
    },
    "242": {
        "name": "Kishu Ken #242",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/242.png",
        "dna": "e69451754556ee53b15e54aec815807169a21f3d",
        "edition": 242,
        "date": 1662952237464,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "242",
        "rarity_score": 95.76023391812865,
        "rarity_level": "RARE"
    },
    "243": {
        "name": "Kishu Ken #243",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/243.png",
        "dna": "69d0313ce3fb814209c4a1987483455f10ffa6bb",
        "edition": 243,
        "date": 1662951815817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "243",
        "rarity_score": 63.05505324492666,
        "rarity_level": "COMMON"
    },
    "244": {
        "name": "Kishu Ken #244",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/244.png",
        "dna": "74ca6df1e0fed9880d92a52b192524834327814d",
        "edition": 244,
        "date": 1662952373334,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "244",
        "rarity_score": 45.92535262305058,
        "rarity_level": "COMMON"
    },
    "245": {
        "name": "Kishu Ken #245",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/245.png",
        "dna": "c862283575af1b3b38ad34095f2d95dad2cb9e1f",
        "edition": 245,
        "date": 1662952022936,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "245",
        "rarity_score": 30.366965712178935,
        "rarity_level": "COMMON"
    },
    "246": {
        "name": "Kishu Ken #246",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/246.png",
        "dna": "1d3b2d6a6f9e1e370b7615b4c67eb2ac9f3ff552",
        "edition": 246,
        "date": 1662951915911,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "246",
        "rarity_score": 43.69714393374632,
        "rarity_level": "COMMON"
    },
    "247": {
        "name": "Kishu Ken #247",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/247.png",
        "dna": "fc8e8dcbf8fb0754d3f9cfa61c5307964ce9dae2",
        "edition": 247,
        "date": 1662951979190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "247",
        "rarity_score": 79.12711417384315,
        "rarity_level": "COMMON"
    },
    "248": {
        "name": "Kishu Ken #248",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/248.png",
        "dna": "f0069dc22cb13c272adbcae66223872e1d248c43",
        "edition": 248,
        "date": 1662952449063,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "248",
        "rarity_score": 43.65298871141369,
        "rarity_level": "COMMON"
    },
    "249": {
        "name": "Kishu Ken #249",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/249.png",
        "dna": "282415bb64b092ffac24b5f7a87fa14a386e0715",
        "edition": 249,
        "date": 1662952462687,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "249",
        "rarity_score": 70.67528658547491,
        "rarity_level": "COMMON"
    },
    "250": {
        "name": "Kishu Ken #250",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/250.png",
        "dna": "a62afd78d8dac176cb423b6169ba4dd33f53935a",
        "edition": 250,
        "date": 1662952189477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "250",
        "rarity_score": 57.42885742885743,
        "rarity_level": "COMMON"
    },
    "251": {
        "name": "Kishu Ken #251",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/251.png",
        "dna": "f6e6b49350cbff193a42dfbfe91bc3c6804a875e",
        "edition": 251,
        "date": 1662952247938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "251",
        "rarity_score": 51.335667464699725,
        "rarity_level": "COMMON"
    },
    "252": {
        "name": "Kishu Ken #252",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/252.png",
        "dna": "2c63ebe463c9f77115648283bb9c6139e3071156",
        "edition": 252,
        "date": 1662952284352,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "252",
        "rarity_score": 77.86849907823985,
        "rarity_level": "COMMON"
    },
    "253": {
        "name": "Kishu Ken #253",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/253.png",
        "dna": "bbd21476d84c6f54c1634e8758bc76dd7c4bf0aa",
        "edition": 253,
        "date": 1662952504744,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "253",
        "rarity_score": 59.94121793200668,
        "rarity_level": "COMMON"
    },
    "254": {
        "name": "Kishu Ken #254",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/254.png",
        "dna": "1c2698c2b068c2afcd5160fc9c7752820c07e72e",
        "edition": 254,
        "date": 1662951903478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "254",
        "rarity_score": 45.84894032039203,
        "rarity_level": "COMMON"
    },
    "255": {
        "name": "Kishu Ken #255",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/255.png",
        "dna": "ac4f9f096ec0f8779cc33e6f01b8fe0ef3cd48bd",
        "edition": 255,
        "date": 1662951893804,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "255",
        "rarity_score": 47.16794834370957,
        "rarity_level": "COMMON"
    },
    "256": {
        "name": "Kishu Ken #256",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/256.png",
        "dna": "3d92d9222bd55c9779fcd82d26555dd3c689c316",
        "edition": 256,
        "date": 1662952291934,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "256",
        "rarity_score": 81.40336366142819,
        "rarity_level": "COMMON"
    },
    "257": {
        "name": "Kishu Ken #257",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/257.png",
        "dna": "8d6e622bca46e7ceaa89b5a2cdee901a8f2119d6",
        "edition": 257,
        "date": 1662952390640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "257",
        "rarity_score": 53.00531530715424,
        "rarity_level": "COMMON"
    },
    "258": {
        "name": "Kishu Ken #258",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/258.png",
        "dna": "98da93e987331d8c41a787a48ea8e781d14823fb",
        "edition": 258,
        "date": 1662952376338,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "258",
        "rarity_score": 109.60908460908462,
        "rarity_level": "RARE"
    },
    "259": {
        "name": "Kishu Ken #259",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/259.png",
        "dna": "597be3f7e403e99a3ad8b55a00ce2d0525f006f4",
        "edition": 259,
        "date": 1662952211072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "259",
        "rarity_score": 65.52816552816553,
        "rarity_level": "COMMON"
    },
    "260": {
        "name": "Kishu Ken #260",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/260.png",
        "dna": "2eba0c063abd1e68671f7142bcbcde328155bf57",
        "edition": 260,
        "date": 1662952312440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "260",
        "rarity_score": 74.27922954238744,
        "rarity_level": "COMMON"
    },
    "261": {
        "name": "Kishu Ken #261",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/261.png",
        "dna": "a9e42a85d576b4faa2b87ae7ef52fa87861c4187",
        "edition": 261,
        "date": 1662952050902,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "261",
        "rarity_score": 38.39274101616593,
        "rarity_level": "COMMON"
    },
    "262": {
        "name": "Kishu Ken #262",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/262.png",
        "dna": "fac41bacda9040cbb887a17adecaf542abc505d1",
        "edition": 262,
        "date": 1662951967065,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "262",
        "rarity_score": 58.80963696497677,
        "rarity_level": "COMMON"
    },
    "263": {
        "name": "Kishu Ken #263",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/263.png",
        "dna": "f12f4eda8f6ba172b844e0ead1e7729e6e0b455d",
        "edition": 263,
        "date": 1662952446179,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "263",
        "rarity_score": 47.89391294804546,
        "rarity_level": "COMMON"
    },
    "264": {
        "name": "Kishu Ken #264",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/264.png",
        "dna": "fcf747d61b05fbd7955b79ba5b9294c0f3e172f0",
        "edition": 264,
        "date": 1662952317384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "264",
        "rarity_score": 76.92417692417692,
        "rarity_level": "COMMON"
    },
    "265": {
        "name": "Kishu Ken #265",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/265.png",
        "dna": "cb54577826d0c6e5843b69d6ac0eb8473d217a1d",
        "edition": 265,
        "date": 1662951886244,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "265",
        "rarity_score": 49.23479147139386,
        "rarity_level": "COMMON"
    },
    "266": {
        "name": "Kishu Ken #266",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/266.png",
        "dna": "022c85bf33d7c1acde93ef4ffe4550d9457dd5b3",
        "edition": 266,
        "date": 1662952228425,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "266",
        "rarity_score": 47.2446940135148,
        "rarity_level": "COMMON"
    },
    "267": {
        "name": "Kishu Ken #267",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/267.png",
        "dna": "22c26b6fdacbdc6fd7700fc24f6add739f643a20",
        "edition": 267,
        "date": 1662952141560,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "267",
        "rarity_score": 36.255854601067824,
        "rarity_level": "COMMON"
    },
    "268": {
        "name": "Kishu Ken #268",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/268.png",
        "dna": "5ae527365c9f45b996302bfe31ae9c1c467086f9",
        "edition": 268,
        "date": 1662952132796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "268",
        "rarity_score": 88.46667351340248,
        "rarity_level": "COMMON"
    },
    "269": {
        "name": "Kishu Ken #269",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/269.png",
        "dna": "73e2d576d3118855a90a125e79e219f8855131ee",
        "edition": 269,
        "date": 1662951968478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "269",
        "rarity_score": 103.63940927437355,
        "rarity_level": "RARE"
    },
    "270": {
        "name": "Kishu Ken #270",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/270.png",
        "dna": "cd332c01e9e943d5c7f45cad4c4073ebd6206f11",
        "edition": 270,
        "date": 1662952027293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "270",
        "rarity_score": 35.93011724902293,
        "rarity_level": "COMMON"
    },
    "271": {
        "name": "Kishu Ken #271",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/271.png",
        "dna": "46b93eea2b7d0bfc63a178ffb6290ec2a6f1cc3a",
        "edition": 271,
        "date": 1662952318095,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "271",
        "rarity_score": 90.1420217209691,
        "rarity_level": "COMMON"
    },
    "272": {
        "name": "Kishu Ken #272",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/272.png",
        "dna": "2a80747c9655a2eb0738709a44b030c59fa77521",
        "edition": 272,
        "date": 1662951865936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "272",
        "rarity_score": 61.36044640717538,
        "rarity_level": "COMMON"
    },
    "273": {
        "name": "Kishu Ken #273",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/273.png",
        "dna": "499dcd31b4230ee9061bae24c7ff906e34dd9e7b",
        "edition": 273,
        "date": 1662952468437,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "273",
        "rarity_score": 42.07270712683964,
        "rarity_level": "COMMON"
    },
    "274": {
        "name": "Kishu Ken #274",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/274.png",
        "dna": "617ffac4047d492fdb195e6188028b114e11121d",
        "edition": 274,
        "date": 1662952542136,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "274",
        "rarity_score": 67.17211673699015,
        "rarity_level": "COMMON"
    },
    "275": {
        "name": "Kishu Ken #275",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/275.png",
        "dna": "6d5359d8e24d1b0380487a3104404feae3718b23",
        "edition": 275,
        "date": 1662951990343,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "275",
        "rarity_score": 31.849277762042746,
        "rarity_level": "COMMON"
    },
    "276": {
        "name": "Kishu Ken #276",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/276.png",
        "dna": "2047f8e9207c5bbacd6f2a69a582c1e9b577c150",
        "edition": 276,
        "date": 1662951801901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "276",
        "rarity_score": 46.99309797659251,
        "rarity_level": "COMMON"
    },
    "277": {
        "name": "Kishu Ken #277",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/277.png",
        "dna": "86d3922ebd284bf18788b6be2557005d46c7ba46",
        "edition": 277,
        "date": 1662952446862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "277",
        "rarity_score": 57.86707305694648,
        "rarity_level": "COMMON"
    },
    "278": {
        "name": "Kishu Ken #278",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/278.png",
        "dna": "c2e63ecd763b026f015315383ac78797cc29d24b",
        "edition": 278,
        "date": 1662951984570,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "278",
        "rarity_score": 30.684937944755365,
        "rarity_level": "COMMON"
    },
    "279": {
        "name": "Kishu Ken #279",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/279.png",
        "dna": "787367f1dc8bfa09cf095f9de30b9bc50fa45fb2",
        "edition": 279,
        "date": 1662951976999,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "279",
        "rarity_score": 112.8986685336328,
        "rarity_level": "RARE"
    },
    "280": {
        "name": "Kishu Ken #280",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/280.png",
        "dna": "e4f9f9bdb561feed973e694c6c6140d038bb523f",
        "edition": 280,
        "date": 1662952404428,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "280",
        "rarity_score": 84.02200884669327,
        "rarity_level": "COMMON"
    },
    "281": {
        "name": "Kishu Ken #281",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/281.png",
        "dna": "1514cbf0c87eaf323e3eb409e28eacd40ab36d0d",
        "edition": 281,
        "date": 1662952532707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "281",
        "rarity_score": 46.990277713169284,
        "rarity_level": "COMMON"
    },
    "282": {
        "name": "Kishu Ken #282",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/282.png",
        "dna": "793bb0e7a4208bdba9a1c20bfb65ad1542442788",
        "edition": 282,
        "date": 1662952277159,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "282",
        "rarity_score": 62.40857652341223,
        "rarity_level": "COMMON"
    },
    "283": {
        "name": "Kishu Ken #283",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/283.png",
        "dna": "9e6435106fdc6a9adf031e445e77e9d8c6f53a63",
        "edition": 283,
        "date": 1662952199145,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "283",
        "rarity_score": 53.41509816739175,
        "rarity_level": "COMMON"
    },
    "284": {
        "name": "Kishu Ken #284",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/284.png",
        "dna": "880cedbadd0c655becbff40245211429df0be1db",
        "edition": 284,
        "date": 1662951906665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "284",
        "rarity_score": 54.501213379444756,
        "rarity_level": "COMMON"
    },
    "285": {
        "name": "Kishu Ken #285",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/285.png",
        "dna": "4b97bb32d8c41adf4e73752a94c02c6345cfb23e",
        "edition": 285,
        "date": 1662951948785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "285",
        "rarity_score": 63.23749528003021,
        "rarity_level": "COMMON"
    },
    "286": {
        "name": "Kishu Ken #286",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/286.png",
        "dna": "cf37156e03b32220ad152ea182eef7db944f2767",
        "edition": 286,
        "date": 1662951972280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "286",
        "rarity_score": 41.28813995319852,
        "rarity_level": "COMMON"
    },
    "287": {
        "name": "Kishu Ken #287",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/287.png",
        "dna": "9e18af9c05e86988889e43e91f7bdff38919c042",
        "edition": 287,
        "date": 1662952054000,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "287",
        "rarity_score": 145.35053554040897,
        "rarity_level": "EPIC"
    },
    "288": {
        "name": "Kishu Ken #288",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/288.png",
        "dna": "ccb181ad6d26c7cae22c8b6e57f7f4f340cb5a37",
        "edition": 288,
        "date": 1662951930878,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "288",
        "rarity_score": 57.635477790817596,
        "rarity_level": "COMMON"
    },
    "289": {
        "name": "Kishu Ken #289",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/289.png",
        "dna": "0505bf3ebf267cad41087c5fc032e43ca1773216",
        "edition": 289,
        "date": 1662952210369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4,
        "id": "289",
        "rarity_score": 201.29258688580722,
        "rarity_level": "LEGENDARY"
    },
    "290": {
        "name": "Kishu Ken #290",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/290.png",
        "dna": "0ade50450f5a0bc7605e6fe3f687f75f1f4df9cf",
        "edition": 290,
        "date": 1662952207006,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "290",
        "rarity_score": 70.49425207319945,
        "rarity_level": "COMMON"
    },
    "291": {
        "name": "Kishu Ken #291",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/291.png",
        "dna": "b9856ff0698c92bab12fab205c81cf1d03427cd8",
        "edition": 291,
        "date": 1662952350067,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "291",
        "rarity_score": 51.083247359892674,
        "rarity_level": "COMMON"
    },
    "292": {
        "name": "Kishu Ken #292",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/292.png",
        "dna": "24683aa16156cf56f3f5fc25264baee82f481310",
        "edition": 292,
        "date": 1662952044855,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.333333333333334,
        "id": "292",
        "rarity_score": 26.65373648110566,
        "rarity_level": "COMMON"
    },
    "293": {
        "name": "Kishu Ken #293",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/293.png",
        "dna": "94542e3a04a8f966c4d150173895e74138078a78",
        "edition": 293,
        "date": 1662952430530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "293",
        "rarity_score": 75.8563074352548,
        "rarity_level": "COMMON"
    },
    "294": {
        "name": "Kishu Ken #294",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/294.png",
        "dna": "394cf75e20727934579d72bd2aed7a7a7f369c60",
        "edition": 294,
        "date": 1662952246481,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "294",
        "rarity_score": 72.45344471793342,
        "rarity_level": "COMMON"
    },
    "295": {
        "name": "Kishu Ken #295",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/295.png",
        "dna": "20dc6ff2c3bfdd9f9aece7a298de3bf8ee6b9eb9",
        "edition": 295,
        "date": 1662952125896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "295",
        "rarity_score": 32.11382802659301,
        "rarity_level": "COMMON"
    },
    "296": {
        "name": "Kishu Ken #296",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/296.png",
        "dna": "ea9e8e6861b33a82996a58edc96922dd04154e59",
        "edition": 296,
        "date": 1662952108829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "296",
        "rarity_score": 50.327325562412256,
        "rarity_level": "COMMON"
    },
    "297": {
        "name": "Kishu Ken #297",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/297.png",
        "dna": "f225971d1c07a65a8f6881af666a47ecf37d4d3d",
        "edition": 297,
        "date": 1662952000500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "297",
        "rarity_score": 28.80139457101511,
        "rarity_level": "COMMON"
    },
    "298": {
        "name": "Kishu Ken #298",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/298.png",
        "dna": "eba9da4f6b58af19a4b4f69f8a773bdd39e1069c",
        "edition": 298,
        "date": 1662952248566,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "298",
        "rarity_score": 52.35012781040102,
        "rarity_level": "COMMON"
    },
    "299": {
        "name": "Kishu Ken #299",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/299.png",
        "dna": "e8352cccd755221966ee89a275d9437b6b565673",
        "edition": 299,
        "date": 1662952546923,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "299",
        "rarity_score": 43.3084067441253,
        "rarity_level": "COMMON"
    },
    "300": {
        "name": "Kishu Ken #300",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/300.png",
        "dna": "d6332efcd69db9891da3000d04ac76554b89287f",
        "edition": 300,
        "date": 1662952354530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "300",
        "rarity_score": 54.18533176230977,
        "rarity_level": "COMMON"
    },
    "301": {
        "name": "Kishu Ken #301",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/301.png",
        "dna": "a43dbae3442b54c6ac4cc4f21e798f799304a3e4",
        "edition": 301,
        "date": 1662951904494,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "301",
        "rarity_score": 46.34313970598058,
        "rarity_level": "COMMON"
    },
    "302": {
        "name": "Kishu Ken #302",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/302.png",
        "dna": "560b53159cbd29c435621db40f0671f1a844aac4",
        "edition": 302,
        "date": 1662951953921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "302",
        "rarity_score": 60.9215077734316,
        "rarity_level": "COMMON"
    },
    "303": {
        "name": "Kishu Ken #303",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/303.png",
        "dna": "3945993c6c2ccfc4c5486fef4ce34188805e044d",
        "edition": 303,
        "date": 1662952444046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "303",
        "rarity_score": 96.89134433928227,
        "rarity_level": "RARE"
    },
    "304": {
        "name": "Kishu Ken #304",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/304.png",
        "dna": "42e8d41b2001e6dae22d3bcc11b8c1ffee70a3e9",
        "edition": 304,
        "date": 1662952431814,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "304",
        "rarity_score": 86.04065195084027,
        "rarity_level": "COMMON"
    },
    "305": {
        "name": "Kishu Ken #305",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/305.png",
        "dna": "d2d09d3c2bd6315551e79031cb8790fee6fda3f5",
        "edition": 305,
        "date": 1662952310271,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "305",
        "rarity_score": 61.428285896370994,
        "rarity_level": "COMMON"
    },
    "306": {
        "name": "Kishu Ken #306",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/306.png",
        "dna": "0d884819e6e9d4469ecfccc45743a1fe49db874f",
        "edition": 306,
        "date": 1662951819556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.5,
        "id": "306",
        "rarity_score": 51.56853936756192,
        "rarity_level": "COMMON"
    },
    "307": {
        "name": "Kishu Ken #307",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/307.png",
        "dna": "55bb4c314de83aa0b775c7b95e0f898452505547",
        "edition": 307,
        "date": 1662951864266,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "307",
        "rarity_score": 45.351536955754355,
        "rarity_level": "COMMON"
    },
    "308": {
        "name": "Kishu Ken #308",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/308.png",
        "dna": "626c829db0f16ca518f8aa2dce2e52763a272625",
        "edition": 308,
        "date": 1662952488728,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.25,
        "id": "308",
        "rarity_score": 79.44446993682988,
        "rarity_level": "COMMON"
    },
    "309": {
        "name": "Kishu Ken #309",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/309.png",
        "dna": "f8517349aac09f82a139146223bd5bcef1207bfc",
        "edition": 309,
        "date": 1662952383984,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "309",
        "rarity_score": 77.2685774411381,
        "rarity_level": "COMMON"
    },
    "310": {
        "name": "Kishu Ken #310",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/310.png",
        "dna": "e2680dda15a5ac8945f16838df1d0400f9bca5e0",
        "edition": 310,
        "date": 1662952454485,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.25,
        "id": "310",
        "rarity_score": 158.8235294117647,
        "rarity_level": "EPIC"
    },
    "311": {
        "name": "Kishu Ken #311",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/311.png",
        "dna": "2aff1c000fcd4de99f7aaa93d59a9326ca82c7cd",
        "edition": 311,
        "date": 1662952289634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "311",
        "rarity_score": 55.66553999678094,
        "rarity_level": "COMMON"
    },
    "312": {
        "name": "Kishu Ken #312",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/312.png",
        "dna": "b5502f30d99f5b60f42c17103c5128ece5e368f7",
        "edition": 312,
        "date": 1662952399775,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "312",
        "rarity_score": 72.39915506924493,
        "rarity_level": "COMMON"
    },
    "313": {
        "name": "Kishu Ken #313",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/313.png",
        "dna": "667fd04a5484b288e660f85e5077a37f706a72d7",
        "edition": 313,
        "date": 1662952300175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "313",
        "rarity_score": 76.59204039310804,
        "rarity_level": "COMMON"
    },
    "314": {
        "name": "Kishu Ken #314",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/314.png",
        "dna": "22b574eea668a2e856644ab6c42f672322899eec",
        "edition": 314,
        "date": 1662952087015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "314",
        "rarity_score": 30.32199447320106,
        "rarity_level": "COMMON"
    },
    "315": {
        "name": "Kishu Ken #315",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/315.png",
        "dna": "801065439e61c46d8154f5f37fb973188d2aef2d",
        "edition": 315,
        "date": 1662952281234,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "315",
        "rarity_score": 102.78795059656456,
        "rarity_level": "RARE"
    },
    "316": {
        "name": "Kishu Ken #316",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/316.png",
        "dna": "ff935953ec5b8fc267ff93ead187ce767044a1d1",
        "edition": 316,
        "date": 1662952489665,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "316",
        "rarity_score": 95.88917136435651,
        "rarity_level": "RARE"
    },
    "317": {
        "name": "Kishu Ken #317",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/317.png",
        "dna": "7551f6f9b981bd754c9efaa97af1e48fc9aa6084",
        "edition": 317,
        "date": 1662952322211,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "317",
        "rarity_score": 50.858458153594725,
        "rarity_level": "COMMON"
    },
    "318": {
        "name": "Kishu Ken #318",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/318.png",
        "dna": "ff90b0eb54d9db62aeebaf61e5d3ea31394e1db0",
        "edition": 318,
        "date": 1662952257351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "318",
        "rarity_score": 65.43709701604439,
        "rarity_level": "COMMON"
    },
    "319": {
        "name": "Kishu Ken #319",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/319.png",
        "dna": "60be7471f6272fc049c15832f0074c71523489b3",
        "edition": 319,
        "date": 1662951923056,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "319",
        "rarity_score": 59.5240491820077,
        "rarity_level": "COMMON"
    },
    "320": {
        "name": "Kishu Ken #320",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/320.png",
        "dna": "cd75a915b011f5b7b57e3e1f5c2e2d4ebbf0279a",
        "edition": 320,
        "date": 1662952001744,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "320",
        "rarity_score": 39.623267778607584,
        "rarity_level": "COMMON"
    },
    "321": {
        "name": "Kishu Ken #321",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/321.png",
        "dna": "12ea52ae6b4d816821558d34f1f9632088d4ab96",
        "edition": 321,
        "date": 1662951971562,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "321",
        "rarity_score": 72.29930858368064,
        "rarity_level": "COMMON"
    },
    "322": {
        "name": "Kishu Ken #322",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/322.png",
        "dna": "c852202ce6673d443f0b2b9790ebd1d3293d95ea",
        "edition": 322,
        "date": 1662951962169,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "322",
        "rarity_score": 43.450052328283704,
        "rarity_level": "COMMON"
    },
    "323": {
        "name": "Kishu Ken #323",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/323.png",
        "dna": "f6ef8c2b112b5d777e352a9863d1a54bb79c5287",
        "edition": 323,
        "date": 1662951922321,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "323",
        "rarity_score": 41.27069746877418,
        "rarity_level": "COMMON"
    },
    "324": {
        "name": "Kishu Ken #324",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/324.png",
        "dna": "69955821c6b829dae63b9105ec9a74eac2ce27b0",
        "edition": 324,
        "date": 1662951947436,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "324",
        "rarity_score": 56.012613955884206,
        "rarity_level": "COMMON"
    },
    "325": {
        "name": "Kishu Ken #325",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/325.png",
        "dna": "72f7581d8e8e981408c8047609611d6b277d80d5",
        "edition": 325,
        "date": 1662952106439,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.333333333333334,
        "id": "325",
        "rarity_score": 45.096625819517385,
        "rarity_level": "COMMON"
    },
    "326": {
        "name": "Kishu Ken #326",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/326.png",
        "dna": "35b2616955458c1177dfbe01967e83ca406e7af8",
        "edition": 326,
        "date": 1662951997418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "326",
        "rarity_score": 53.94951913939256,
        "rarity_level": "COMMON"
    },
    "327": {
        "name": "Kishu Ken #327",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/327.png",
        "dna": "fb5f51caa11be5c7575edaca4554e0f1cbcb4d94",
        "edition": 327,
        "date": 1662952412106,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "327",
        "rarity_score": 133.0067593225488,
        "rarity_level": "EPIC"
    },
    "328": {
        "name": "Kishu Ken #328",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/328.png",
        "dna": "ca8dd6717280dd37e9f90563268cc19ce29a2379",
        "edition": 328,
        "date": 1662952513756,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "328",
        "rarity_score": 53.64348694468691,
        "rarity_level": "COMMON"
    },
    "329": {
        "name": "Kishu Ken #329",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/329.png",
        "dna": "ba179dec2657de0ccd86411d317a3b1913795442",
        "edition": 329,
        "date": 1662951854412,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "329",
        "rarity_score": 41.52798438102214,
        "rarity_level": "COMMON"
    },
    "330": {
        "name": "Kishu Ken #330",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/330.png",
        "dna": "3973bd93150a61a17f480b681c9aee4aa5b543fb",
        "edition": 330,
        "date": 1662952410445,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "330",
        "rarity_score": 98.52162762610524,
        "rarity_level": "RARE"
    },
    "331": {
        "name": "Kishu Ken #331",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/331.png",
        "dna": "f3d5410951cf46bf869324483206333f5f5a425b",
        "edition": 331,
        "date": 1662952018960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "331",
        "rarity_score": 45.94952410486391,
        "rarity_level": "COMMON"
    },
    "332": {
        "name": "Kishu Ken #332",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/332.png",
        "dna": "32432c2cb6ac59154d35e522ba9b0c0521761896",
        "edition": 332,
        "date": 1662952456093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "332",
        "rarity_score": 61.90469858906142,
        "rarity_level": "COMMON"
    },
    "333": {
        "name": "Kishu Ken #333",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/333.png",
        "dna": "1cb4224e1df3bb54fbcbaf611b9625d1943a84a6",
        "edition": 333,
        "date": 1662952138382,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.333333333333334,
        "id": "333",
        "rarity_score": 94.65082280616261,
        "rarity_level": "RARE"
    },
    "334": {
        "name": "Kishu Ken #334",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/334.png",
        "dna": "e5260ca2e67c860ee23501853db3368c54e0ff01",
        "edition": 334,
        "date": 1662952487980,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "334",
        "rarity_score": 77.2176653755601,
        "rarity_level": "COMMON"
    },
    "335": {
        "name": "Kishu Ken #335",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/335.png",
        "dna": "24535712476d7a2d67fd5629d243300de9fcb963",
        "edition": 335,
        "date": 1662952219396,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "335",
        "rarity_score": 80.03888608591856,
        "rarity_level": "COMMON"
    },
    "336": {
        "name": "Kishu Ken #336",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/336.png",
        "dna": "6268f653a8909cfd01118bdb0564b503518d0c96",
        "edition": 336,
        "date": 1662952065990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "336",
        "rarity_score": 68.6892287359577,
        "rarity_level": "COMMON"
    },
    "337": {
        "name": "Kishu Ken #337",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/337.png",
        "dna": "6f18033954fb95030440c5b9b0e65578fab52051",
        "edition": 337,
        "date": 1662952316659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "337",
        "rarity_score": 43.93788899631397,
        "rarity_level": "COMMON"
    },
    "338": {
        "name": "Kishu Ken #338",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/338.png",
        "dna": "9dc2256850c781ddcb350d57b84add9d647409ee",
        "edition": 338,
        "date": 1662952112193,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.333333333333334,
        "id": "338",
        "rarity_score": 32.617683793445025,
        "rarity_level": "COMMON"
    },
    "339": {
        "name": "Kishu Ken #339",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/339.png",
        "dna": "ef01cfb0fef4057946ab3077ab4514056ac39056",
        "edition": 339,
        "date": 1662951927806,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "339",
        "rarity_score": 60.58593084653381,
        "rarity_level": "COMMON"
    },
    "340": {
        "name": "Kishu Ken #340",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/340.png",
        "dna": "9a9ab6be8b6576eb91c1bd477f4603f479e00a5c",
        "edition": 340,
        "date": 1662952524365,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "340",
        "rarity_score": 52.40894861868939,
        "rarity_level": "COMMON"
    },
    "341": {
        "name": "Kishu Ken #341",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/341.png",
        "dna": "274be4ae89dfd4bc993f131517ed66e6e2ec9203",
        "edition": 341,
        "date": 1662952522097,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "341",
        "rarity_score": 70.66818679721905,
        "rarity_level": "COMMON"
    },
    "342": {
        "name": "Kishu Ken #342",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/342.png",
        "dna": "34f08efa9eea1a88c5f5047af359d88bbb223848",
        "edition": 342,
        "date": 1662952551764,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "342",
        "rarity_score": 77.16811217847027,
        "rarity_level": "COMMON"
    },
    "343": {
        "name": "Kishu Ken #343",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/343.png",
        "dna": "087343e7bc00f0f9073bd6d2e1e74c219f72c88c",
        "edition": 343,
        "date": 1662952303596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "343",
        "rarity_score": 51.07111720014946,
        "rarity_level": "COMMON"
    },
    "344": {
        "name": "Kishu Ken #344",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/344.png",
        "dna": "a520c3383f50251bee7766f1394a7f1072702b2c",
        "edition": 344,
        "date": 1662952002381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "344",
        "rarity_score": 28.89978777801915,
        "rarity_level": "COMMON"
    },
    "345": {
        "name": "Kishu Ken #345",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/345.png",
        "dna": "ea684a15f6ddd4c867aa7a8adaa662c107384fc5",
        "edition": 345,
        "date": 1662952094960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16.666666666666668,
        "id": "345",
        "rarity_score": 24.31428157409899,
        "rarity_level": "COMMON"
    },
    "346": {
        "name": "Kishu Ken #346",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/346.png",
        "dna": "edca1b56839b7c38c20fb002702d864ab14eaa3e",
        "edition": 346,
        "date": 1662952023643,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "346",
        "rarity_score": 64.9101456330372,
        "rarity_level": "COMMON"
    },
    "347": {
        "name": "Kishu Ken #347",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/347.png",
        "dna": "8d08b3f4fe24347954f87984d0474237c764bd81",
        "edition": 347,
        "date": 1662952245154,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "347",
        "rarity_score": 50.51085882697074,
        "rarity_level": "COMMON"
    },
    "348": {
        "name": "Kishu Ken #348",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/348.png",
        "dna": "ca3eee71f49dad09dcc8bf7329e4f0d7ff8d9dc5",
        "edition": 348,
        "date": 1662952003014,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "348",
        "rarity_score": 29.086294855915394,
        "rarity_level": "COMMON"
    },
    "349": {
        "name": "Kishu Ken #349",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/349.png",
        "dna": "2c290a7e618d99419b782077c76bd14d4433e720",
        "edition": 349,
        "date": 1662951994589,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "349",
        "rarity_score": 40.87889543781954,
        "rarity_level": "COMMON"
    },
    "350": {
        "name": "Kishu Ken #350",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/350.png",
        "dna": "f3752d78ae5aa24deec6c6c58be085ed70696b27",
        "edition": 350,
        "date": 1662952230916,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.5,
        "id": "350",
        "rarity_score": 145.76223776223776,
        "rarity_level": "EPIC"
    },
    "351": {
        "name": "Kishu Ken #351",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/351.png",
        "dna": "294242258c0df716830a3bd941441f83af1c9e6e",
        "edition": 351,
        "date": 1662951874129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "351",
        "rarity_score": 53.864582019921826,
        "rarity_level": "COMMON"
    },
    "352": {
        "name": "Kishu Ken #352",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/352.png",
        "dna": "8f2e92b7fd638c8105c30f70181950dc2cd9b390",
        "edition": 352,
        "date": 1662952256646,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "352",
        "rarity_score": 52.199882922774485,
        "rarity_level": "COMMON"
    },
    "353": {
        "name": "Kishu Ken #353",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/353.png",
        "dna": "d321efe47ac2b442eb0507d3162315b70715b7f0",
        "edition": 353,
        "date": 1662952499704,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "353",
        "rarity_score": 150.81740298458564,
        "rarity_level": "EPIC"
    },
    "354": {
        "name": "Kishu Ken #354",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/354.png",
        "dna": "2a9e9c9c1cb6e7eff8218abb7c6d7bf6b080df62",
        "edition": 354,
        "date": 1662952083347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "354",
        "rarity_score": 41.017096208072886,
        "rarity_level": "COMMON"
    },
    "355": {
        "name": "Kishu Ken #355",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/355.png",
        "dna": "88ff27e76c601080d959802056bb6c8324e51265",
        "edition": 355,
        "date": 1662952094322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "355",
        "rarity_score": 38.59530950807449,
        "rarity_level": "COMMON"
    },
    "356": {
        "name": "Kishu Ken #356",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/356.png",
        "dna": "140ef24458325e6874d74506cc8191e6013ee615",
        "edition": 356,
        "date": 1662952386171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.25,
        "id": "356",
        "rarity_score": 141.56983367509685,
        "rarity_level": "EPIC"
    },
    "357": {
        "name": "Kishu Ken #357",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/357.png",
        "dna": "6eff07e68452fddd9508c415d5a24739bc53c291",
        "edition": 357,
        "date": 1662952510070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "357",
        "rarity_score": 84.9527665317139,
        "rarity_level": "COMMON"
    },
    "358": {
        "name": "Kishu Ken #358",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/358.png",
        "dna": "0b7f9a4494567f4ac0f1d80545d74f8bc237cd7f",
        "edition": 358,
        "date": 1662951807053,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.25,
        "id": "358",
        "rarity_score": 37.29522634187095,
        "rarity_level": "COMMON"
    },
    "359": {
        "name": "Kishu Ken #359",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/359.png",
        "dna": "1a95ab4696d580eb52346b50c1c8a59fd95bf236",
        "edition": 359,
        "date": 1662952231645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "359",
        "rarity_score": 95.64206358423058,
        "rarity_level": "RARE"
    },
    "360": {
        "name": "Kishu Ken #360",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/360.png",
        "dna": "cd08ddc7e82c7aa6458e7a06b575068622320f51",
        "edition": 360,
        "date": 1662952082705,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "360",
        "rarity_score": 62.855591010930816,
        "rarity_level": "COMMON"
    },
    "361": {
        "name": "Kishu Ken #361",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/361.png",
        "dna": "724e6dfc87ed68533bfadaed5bc42d681c1520ef",
        "edition": 361,
        "date": 1662951892232,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "361",
        "rarity_score": 121.86568705556047,
        "rarity_level": "EPIC"
    },
    "362": {
        "name": "Kishu Ken #362",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/362.png",
        "dna": "79d715a8a471eba57e8e48283e31d8e4bc8db460",
        "edition": 362,
        "date": 1662952344069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "362",
        "rarity_score": 42.48891043107743,
        "rarity_level": "COMMON"
    },
    "363": {
        "name": "Kishu Ken #363",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/363.png",
        "dna": "ac7f9e35079f102b2a93d8d860ecaaeb01fdf253",
        "edition": 363,
        "date": 1662952547877,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "363",
        "rarity_score": 65.30144046517775,
        "rarity_level": "COMMON"
    },
    "364": {
        "name": "Kishu Ken #364",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/364.png",
        "dna": "e7a6996c94edfff942d5a09472c4c2ad324e69d4",
        "edition": 364,
        "date": 1662952321478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "364",
        "rarity_score": 80.30343635046881,
        "rarity_level": "COMMON"
    },
    "365": {
        "name": "Kishu Ken #365",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/365.png",
        "dna": "82e384211e53f1f22d2fcd07fd3980dee11910ee",
        "edition": 365,
        "date": 1662952123037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "365",
        "rarity_score": 65.19504591793748,
        "rarity_level": "COMMON"
    },
    "366": {
        "name": "Kishu Ken #366",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/366.png",
        "dna": "ebc0650b62cd687d7cfa291fc14ad9fe0735597a",
        "edition": 366,
        "date": 1662952068205,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "366",
        "rarity_score": 32.88223405799529,
        "rarity_level": "COMMON"
    },
    "367": {
        "name": "Kishu Ken #367",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/367.png",
        "dna": "1b18b8ce434e2222b6996688d081b3ca8c4dd37b",
        "edition": 367,
        "date": 1662952304321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "367",
        "rarity_score": 63.953207833994135,
        "rarity_level": "COMMON"
    },
    "368": {
        "name": "Kishu Ken #368",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/368.png",
        "dna": "12b701d8730f6c251108ded5d8978a6e5598022c",
        "edition": 368,
        "date": 1662952245871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "368",
        "rarity_score": 69.42039442039442,
        "rarity_level": "COMMON"
    },
    "369": {
        "name": "Kishu Ken #369",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/369.png",
        "dna": "550699941201d624c7461d8303923cb65bed8a1d",
        "edition": 369,
        "date": 1662952378745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rarity": 6,
        "id": "369",
        "rarity_score": 107.97345928924875,
        "rarity_level": "RARE"
    },
    "370": {
        "name": "Kishu Ken #370",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/370.png",
        "dna": "cafdbc14f2c5201d14ce524558961c817be21f02",
        "edition": 370,
        "date": 1662952366230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "370",
        "rarity_score": 69.2002442002442,
        "rarity_level": "COMMON"
    },
    "371": {
        "name": "Kishu Ken #371",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/371.png",
        "dna": "c8c63c2a3974d448945807d68c1febf28276ecdc",
        "edition": 371,
        "date": 1662952348314,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "371",
        "rarity_score": 61.32043520596079,
        "rarity_level": "COMMON"
    },
    "372": {
        "name": "Kishu Ken #372",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/372.png",
        "dna": "fb78ed6b389994a9a06b9a5878135fd3d1b6f26d",
        "edition": 372,
        "date": 1662952554645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "372",
        "rarity_score": 81.09532862822337,
        "rarity_level": "COMMON"
    },
    "373": {
        "name": "Kishu Ken #373",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/373.png",
        "dna": "e06e8b46a8add463eab4c2b8d9fa582dea79671f",
        "edition": 373,
        "date": 1662952203967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "373",
        "rarity_score": 111.24739815640797,
        "rarity_level": "RARE"
    },
    "374": {
        "name": "Kishu Ken #374",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/374.png",
        "dna": "82ee94d2c1868ad77818327b39d930cdf4c0a6cf",
        "edition": 374,
        "date": 1662952114085,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.333333333333334,
        "id": "374",
        "rarity_score": 48.875708922437894,
        "rarity_level": "COMMON"
    },
    "375": {
        "name": "Kishu Ken #375",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/375.png",
        "dna": "95ece368602f0f38e9af6a443eedfb9beded11a6",
        "edition": 375,
        "date": 1662952437577,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "375",
        "rarity_score": 105.13776131921696,
        "rarity_level": "RARE"
    },
    "376": {
        "name": "Kishu Ken #376",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/376.png",
        "dna": "0a1e77b0359c88ce9fb8bda36498fae9fd6c67ed",
        "edition": 376,
        "date": 1662952295811,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.75,
        "id": "376",
        "rarity_score": 42.906295589720564,
        "rarity_level": "COMMON"
    },
    "377": {
        "name": "Kishu Ken #377",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/377.png",
        "dna": "35aed69cf5ee61768b9516a88f26724318c3ecae",
        "edition": 377,
        "date": 1662951989694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "377",
        "rarity_score": 49.27438946426288,
        "rarity_level": "COMMON"
    },
    "378": {
        "name": "Kishu Ken #378",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/378.png",
        "dna": "5b9270c28a803f77236fb41de65bff2114c17010",
        "edition": 378,
        "date": 1662952443277,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "378",
        "rarity_score": 49.759094817519795,
        "rarity_level": "COMMON"
    },
    "379": {
        "name": "Kishu Ken #379",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/379.png",
        "dna": "5832ae0c1c508c21173613f39c6541f47db70e04",
        "edition": 379,
        "date": 1662951822994,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "379",
        "rarity_score": 65.40239887758402,
        "rarity_level": "COMMON"
    },
    "380": {
        "name": "Kishu Ken #380",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/380.png",
        "dna": "c7a42b2f166270c472e172dc12ce0cd5e70a7f00",
        "edition": 380,
        "date": 1662952556701,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "380",
        "rarity_score": 76.51515151515153,
        "rarity_level": "COMMON"
    },
    "381": {
        "name": "Kishu Ken #381",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/381.png",
        "dna": "9b4ecc7ce0aa5ed9700f9af1bf58f94bc06e7b9d",
        "edition": 381,
        "date": 1662952126537,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "381",
        "rarity_score": 50.5619852640538,
        "rarity_level": "COMMON"
    },
    "382": {
        "name": "Kishu Ken #382",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/382.png",
        "dna": "bf21229fff84cff13fca44aee305584c4edb834a",
        "edition": 382,
        "date": 1662951862600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "382",
        "rarity_score": 64.93702319777847,
        "rarity_level": "COMMON"
    },
    "383": {
        "name": "Kishu Ken #383",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/383.png",
        "dna": "5e5f20219467b6a9304c69ee82d7685ef620d3c6",
        "edition": 383,
        "date": 1662952369407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "383",
        "rarity_score": 66.5805956850733,
        "rarity_level": "COMMON"
    },
    "384": {
        "name": "Kishu Ken #384",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/384.png",
        "dna": "1a8ae18c5dbc0819cc97ef71b7cdb64b89d81056",
        "edition": 384,
        "date": 1662952274555,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.75,
        "id": "384",
        "rarity_score": 49.329306186077375,
        "rarity_level": "COMMON"
    },
    "385": {
        "name": "Kishu Ken #385",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/385.png",
        "dna": "ba632b957aef99a7fd73b727a58fd3731ec22818",
        "edition": 385,
        "date": 1662952353816,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "385",
        "rarity_score": 75.83467858697216,
        "rarity_level": "COMMON"
    },
    "386": {
        "name": "Kishu Ken #386",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/386.png",
        "dna": "3e8ff7b7762bad79ca9b1371ba8e2b1970064474",
        "edition": 386,
        "date": 1662952195864,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "386",
        "rarity_score": 93.68861838133128,
        "rarity_level": "RARE"
    },
    "387": {
        "name": "Kishu Ken #387",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/387.png",
        "dna": "68adfb519382474cd8a8cab4510ca9f5b88b4e3c",
        "edition": 387,
        "date": 1662952557349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "387",
        "rarity_score": 105.90159875432273,
        "rarity_level": "RARE"
    },
    "388": {
        "name": "Kishu Ken #388",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/388.png",
        "dna": "fea807961ffeaf34ad822b91fd308ebd70fc3229",
        "edition": 388,
        "date": 1662952116173,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "388",
        "rarity_score": 45.68921841210998,
        "rarity_level": "COMMON"
    },
    "389": {
        "name": "Kishu Ken #389",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/389.png",
        "dna": "442b61c0b068c3c39e30b523d7f3fc41fec713b2",
        "edition": 389,
        "date": 1662951895257,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "389",
        "rarity_score": 49.65768767571582,
        "rarity_level": "COMMON"
    },
    "390": {
        "name": "Kishu Ken #390",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/390.png",
        "dna": "6e081e2341335ae948ba47b12077e5e36403d9c4",
        "edition": 390,
        "date": 1662951841648,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "390",
        "rarity_score": 52.88102379378878,
        "rarity_level": "COMMON"
    },
    "391": {
        "name": "Kishu Ken #391",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/391.png",
        "dna": "b7d2da7458f0c9268063786f8eb6c1fe5f5de1d5",
        "edition": 391,
        "date": 1662952062698,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "391",
        "rarity_score": 48.899014088887505,
        "rarity_level": "COMMON"
    },
    "392": {
        "name": "Kishu Ken #392",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/392.png",
        "dna": "cb90bee5c88c18f99a4bc50f6de97f8498f151ba",
        "edition": 392,
        "date": 1662952212978,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "392",
        "rarity_score": 75.60505746182866,
        "rarity_level": "COMMON"
    },
    "393": {
        "name": "Kishu Ken #393",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/393.png",
        "dna": "d3ac5c956bab714bc185b72f1caf6d81ad058496",
        "edition": 393,
        "date": 1662951985439,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "393",
        "rarity_score": 42.27041499330656,
        "rarity_level": "COMMON"
    },
    "394": {
        "name": "Kishu Ken #394",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/394.png",
        "dna": "c6e4cd88b4e2fc22c7fd47a71388c2f2bf44af78",
        "edition": 394,
        "date": 1662951946005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "394",
        "rarity_score": 70.03337729319472,
        "rarity_level": "COMMON"
    },
    "395": {
        "name": "Kishu Ken #395",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/395.png",
        "dna": "038828f465cc714ad62ac898a0013b49b62bb751",
        "edition": 395,
        "date": 1662952553940,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "395",
        "rarity_score": 59.10086320534081,
        "rarity_level": "COMMON"
    },
    "396": {
        "name": "Kishu Ken #396",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/396.png",
        "dna": "807efbfe991e2970bb4e4576f1a2252179a879ce",
        "edition": 396,
        "date": 1662951929900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "396",
        "rarity_score": 61.73518854252024,
        "rarity_level": "COMMON"
    },
    "397": {
        "name": "Kishu Ken #397",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/397.png",
        "dna": "353139b1ef0412a36d3338088aa1721c0043a77b",
        "edition": 397,
        "date": 1662952436114,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "397",
        "rarity_score": 69.33979656017524,
        "rarity_level": "COMMON"
    },
    "398": {
        "name": "Kishu Ken #398",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/398.png",
        "dna": "4de48c03a8d12616232147a36d74ca3eeaada2a2",
        "edition": 398,
        "date": 1662952380329,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "398",
        "rarity_score": 67.98868138087153,
        "rarity_level": "COMMON"
    },
    "399": {
        "name": "Kishu Ken #399",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/399.png",
        "dna": "3c60ee8e8d5ae408262faf5e1381b8f7fbb6bada",
        "edition": 399,
        "date": 1662952403722,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "399",
        "rarity_score": 76.84229000018473,
        "rarity_level": "COMMON"
    },
    "400": {
        "name": "Kishu Ken #400",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/400.png",
        "dna": "07c5999fc0fbd1142eff89835624786d28e54add",
        "edition": 400,
        "date": 1662952364092,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "400",
        "rarity_score": 66.72920979986196,
        "rarity_level": "COMMON"
    },
    "401": {
        "name": "Kishu Ken #401",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/401.png",
        "dna": "f0bb337d31ec932ef3a817ff6ed4b81dce2b7a55",
        "edition": 401,
        "date": 1662952496957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "401",
        "rarity_score": 103.62461440272311,
        "rarity_level": "RARE"
    },
    "402": {
        "name": "Kishu Ken #402",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/402.png",
        "dna": "1af58fee1b8d617c31cff3667104a9a5e6d92f22",
        "edition": 402,
        "date": 1662952074502,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15,
        "id": "402",
        "rarity_score": 27.528321822672854,
        "rarity_level": "COMMON"
    },
    "403": {
        "name": "Kishu Ken #403",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/403.png",
        "dna": "ff80dee1f3673222163f8c48af49c942844fe547",
        "edition": 403,
        "date": 1662951993952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "403",
        "rarity_score": 53.79023883696781,
        "rarity_level": "COMMON"
    },
    "404": {
        "name": "Kishu Ken #404",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/404.png",
        "dna": "12d0acca6f1dadb64560994a38253f198ef83a0e",
        "edition": 404,
        "date": 1662952076702,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "404",
        "rarity_score": 32.70642061918561,
        "rarity_level": "COMMON"
    },
    "405": {
        "name": "Kishu Ken #405",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/405.png",
        "dna": "5883a04b9d80d2d8a61cab820dbea85b93b74385",
        "edition": 405,
        "date": 1662952049004,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "405",
        "rarity_score": 62.570690726030534,
        "rarity_level": "COMMON"
    },
    "406": {
        "name": "Kishu Ken #406",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/406.png",
        "dna": "c282806e149ab2a1a0ee38a9d38b681b668af0bc",
        "edition": 406,
        "date": 1662952285061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "406",
        "rarity_score": 114.76301476301478,
        "rarity_level": "RARE"
    },
    "407": {
        "name": "Kishu Ken #407",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/407.png",
        "dna": "caa2e67b9c0a8f17408fcb2a79ebaaf6538e936d",
        "edition": 407,
        "date": 1662952272185,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "407",
        "rarity_score": 48.77542787990549,
        "rarity_level": "COMMON"
    },
    "408": {
        "name": "Kishu Ken #408",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/408.png",
        "dna": "95a2888aead79191b235b4ac85f2903f23a185dc",
        "edition": 408,
        "date": 1662952538590,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.25,
        "id": "408",
        "rarity_score": 118.54839188892075,
        "rarity_level": "RARE"
    },
    "409": {
        "name": "Kishu Ken #409",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/409.png",
        "dna": "d9eb7d9635de40e8c18ab5beca174c1bb75a9544",
        "edition": 409,
        "date": 1662952003681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.333333333333334,
        "id": "409",
        "rarity_score": 94.2878793346083,
        "rarity_level": "RARE"
    },
    "410": {
        "name": "Kishu Ken #410",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/410.png",
        "dna": "c3ee6cce7b36618971bb4e1c48a3e9e438045216",
        "edition": 410,
        "date": 1662952115287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rarity": 6,
        "id": "410",
        "rarity_score": 106.5555847109245,
        "rarity_level": "RARE"
    },
    "411": {
        "name": "Kishu Ken #411",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/411.png",
        "dna": "332c4d45d8e12354911169627fb0b94f3a4ddf54",
        "edition": 411,
        "date": 1662952552490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "411",
        "rarity_score": 43.038360980527976,
        "rarity_level": "COMMON"
    },
    "412": {
        "name": "Kishu Ken #412",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/412.png",
        "dna": "9af724bf5c14aa0f02dce4332a160ce80b222b7f",
        "edition": 412,
        "date": 1662952359962,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "412",
        "rarity_score": 79.53966111860848,
        "rarity_level": "COMMON"
    },
    "413": {
        "name": "Kishu Ken #413",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/413.png",
        "dna": "b6aa6eacb7ddc696b5750bc20859a9d9cc11bb14",
        "edition": 413,
        "date": 1662952335465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "413",
        "rarity_score": 92.5326147971035,
        "rarity_level": "RARE"
    },
    "414": {
        "name": "Kishu Ken #414",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/414.png",
        "dna": "7f4224d823b44a9c60cd3be0a9e0ddf1baab0c61",
        "edition": 414,
        "date": 1662952328847,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "414",
        "rarity_score": 84.08090833897285,
        "rarity_level": "COMMON"
    },
    "415": {
        "name": "Kishu Ken #415",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/415.png",
        "dna": "67b849147140256930a11b90e7dc78dfcf872ca8",
        "edition": 415,
        "date": 1662951852029,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "415",
        "rarity_score": 95.54676826965984,
        "rarity_level": "RARE"
    },
    "416": {
        "name": "Kishu Ken #416",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/416.png",
        "dna": "b15da15068864bf6397022984dc37e280fb03fa7",
        "edition": 416,
        "date": 1662952385462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "416",
        "rarity_score": 69.43668337767187,
        "rarity_level": "COMMON"
    },
    "417": {
        "name": "Kishu Ken #417",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/417.png",
        "dna": "a4aa859ad97f5fe1b28c6fd9de094df0b1ca5e33",
        "edition": 417,
        "date": 1662952292585,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "417",
        "rarity_score": 73.92860213644943,
        "rarity_level": "COMMON"
    },
    "418": {
        "name": "Kishu Ken #418",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/418.png",
        "dna": "81b31e090d507b484cc78ea06706d59dd1c8dc7d",
        "edition": 418,
        "date": 1662951821556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "418",
        "rarity_score": 66.4195685715607,
        "rarity_level": "COMMON"
    },
    "419": {
        "name": "Kishu Ken #419",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/419.png",
        "dna": "bde11bf12c492d679c1e0dd1e7e7340389f7a9d0",
        "edition": 419,
        "date": 1662952249278,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "419",
        "rarity_score": 57.16430716430717,
        "rarity_level": "COMMON"
    },
    "420": {
        "name": "Kishu Ken #420",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/420.png",
        "dna": "7412890ee44e3a9fce947920b1cb15c3cc06eb03",
        "edition": 420,
        "date": 1662952030739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "420",
        "rarity_score": 33.53007781444988,
        "rarity_level": "COMMON"
    },
    "421": {
        "name": "Kishu Ken #421",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/421.png",
        "dna": "1adc282dc4a1b545fd299656f940c7fbea0ef202",
        "edition": 421,
        "date": 1662952035109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "421",
        "rarity_score": 42.757170912510716,
        "rarity_level": "COMMON"
    },
    "422": {
        "name": "Kishu Ken #422",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/422.png",
        "dna": "2de02364455181389057dfa81570c44fd14d2c96",
        "edition": 422,
        "date": 1662952529228,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "422",
        "rarity_score": 77.66057510851304,
        "rarity_level": "COMMON"
    },
    "423": {
        "name": "Kishu Ken #423",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/423.png",
        "dna": "2408253bb8bbfc8c71aa8ecb5e819b165d36f678",
        "edition": 423,
        "date": 1662952509386,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "423",
        "rarity_score": 61.6281275136531,
        "rarity_level": "COMMON"
    },
    "424": {
        "name": "Kishu Ken #424",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/424.png",
        "dna": "d1763d3910fa1ae6ecfad8788014c5d72f9bd520",
        "edition": 424,
        "date": 1662952277868,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5,
        "id": "424",
        "rarity_score": 158.7327694235589,
        "rarity_level": "EPIC"
    },
    "425": {
        "name": "Kishu Ken #425",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/425.png",
        "dna": "f3aec452cf27717db664904980c8c033881fabc1",
        "edition": 425,
        "date": 1662952368678,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "425",
        "rarity_score": 112.78312537136065,
        "rarity_level": "RARE"
    },
    "426": {
        "name": "Kishu Ken #426",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/426.png",
        "dna": "bb3f11b131a9480f774326dd5aa7a3a0c94553fd",
        "edition": 426,
        "date": 1662952132174,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "426",
        "rarity_score": 32.72386310360994,
        "rarity_level": "COMMON"
    },
    "427": {
        "name": "Kishu Ken #427",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/427.png",
        "dna": "3704dec71f652c0ffab93206ae09b3e0aaafce52",
        "edition": 427,
        "date": 1662952301186,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "427",
        "rarity_score": 73.99221474332981,
        "rarity_level": "COMMON"
    },
    "428": {
        "name": "Kishu Ken #428",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/428.png",
        "dna": "efc994bd4a0cd848263a69875b11c9b7cb9aee9d",
        "edition": 428,
        "date": 1662951951759,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "428",
        "rarity_score": 46.784210982287696,
        "rarity_level": "COMMON"
    },
    "429": {
        "name": "Kishu Ken #429",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/429.png",
        "dna": "8d2e9735286505d2526f52e7299693edd438fbb6",
        "edition": 429,
        "date": 1662952520674,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "429",
        "rarity_score": 103.24338099567458,
        "rarity_level": "RARE"
    },
    "430": {
        "name": "Kishu Ken #430",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/430.png",
        "dna": "26547f176bceb4d0f82b4e98559a7bd77025ee56",
        "edition": 430,
        "date": 1662951954634,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "430",
        "rarity_score": 46.939559206666864,
        "rarity_level": "COMMON"
    },
    "431": {
        "name": "Kishu Ken #431",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/431.png",
        "dna": "6cea4519ddba73d59ac50a5cb4c4e3c8bf760b95",
        "edition": 431,
        "date": 1662952397358,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "431",
        "rarity_score": 88.31376760069459,
        "rarity_level": "COMMON"
    },
    "432": {
        "name": "Kishu Ken #432",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/432.png",
        "dna": "be7723122b3b8f528c981a75e58f2021fa3a8021",
        "edition": 432,
        "date": 1662952081505,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "432",
        "rarity_score": 44.19132889339743,
        "rarity_level": "COMMON"
    },
    "433": {
        "name": "Kishu Ken #433",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/433.png",
        "dna": "c798b4b3f93b42743a98fef57523f039a503b23d",
        "edition": 433,
        "date": 1662952229939,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.5,
        "id": "433",
        "rarity_score": 36.93775037346893,
        "rarity_level": "COMMON"
    },
    "434": {
        "name": "Kishu Ken #434",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/434.png",
        "dna": "9a0db1ed080c1204780e6940ce9eda9c9c37b052",
        "edition": 434,
        "date": 1662952314517,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "434",
        "rarity_score": 111.38763197586727,
        "rarity_level": "RARE"
    },
    "435": {
        "name": "Kishu Ken #435",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/435.png",
        "dna": "cede8ec157285b4796e1d7bc2e169a54d3807eef",
        "edition": 435,
        "date": 1662951843032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "435",
        "rarity_score": 55.351659011031074,
        "rarity_level": "COMMON"
    },
    "436": {
        "name": "Kishu Ken #436",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/436.png",
        "dna": "8951cb09694c66999e4e27cd114fb4885f677605",
        "edition": 436,
        "date": 1662951820266,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "436",
        "rarity_score": 42.4194894561551,
        "rarity_level": "COMMON"
    },
    "437": {
        "name": "Kishu Ken #437",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/437.png",
        "dna": "cbdb71e5e8fad330c90f46a8cf39f2238fb38498",
        "edition": 437,
        "date": 1662952449688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "437",
        "rarity_score": 120.48229548229548,
        "rarity_level": "RARE"
    },
    "438": {
        "name": "Kishu Ken #438",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/438.png",
        "dna": "42c104d5e926fa5f2fa338edab0dfe36c3e5d054",
        "edition": 438,
        "date": 1662952279775,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "438",
        "rarity_score": 44.24558130400628,
        "rarity_level": "COMMON"
    },
    "439": {
        "name": "Kishu Ken #439",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/439.png",
        "dna": "0956b949f5afd05424781191cefb65ccda9cad39",
        "edition": 439,
        "date": 1662951995253,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "439",
        "rarity_score": 35.880479225692454,
        "rarity_level": "COMMON"
    },
    "440": {
        "name": "Kishu Ken #440",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/440.png",
        "dna": "ffa1e52638df61a51b72cdc793b20e68e9e47ca1",
        "edition": 440,
        "date": 1662952518641,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "440",
        "rarity_score": 62.60738039047415,
        "rarity_level": "COMMON"
    },
    "441": {
        "name": "Kishu Ken #441",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/441.png",
        "dna": "88dc24b68622f843bad8b268576269d08ce124fb",
        "edition": 441,
        "date": 1662952420948,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "441",
        "rarity_score": 67.81533756763115,
        "rarity_level": "COMMON"
    },
    "442": {
        "name": "Kishu Ken #442",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/442.png",
        "dna": "a24c00868e92a98ab13a3da9d353a6c0fab56d2b",
        "edition": 442,
        "date": 1662952293293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "442",
        "rarity_score": 88.59866788559486,
        "rarity_level": "COMMON"
    },
    "443": {
        "name": "Kishu Ken #443",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/443.png",
        "dna": "7a82cb1836101c2f9710a8c4f900bbde6ef33f39",
        "edition": 443,
        "date": 1662951999825,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "443",
        "rarity_score": 33.39976822713741,
        "rarity_level": "COMMON"
    },
    "444": {
        "name": "Kishu Ken #444",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/444.png",
        "dna": "a6f5ce8dbdfa66e8e6920af35b362ca090dedd48",
        "edition": 444,
        "date": 1662952032955,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "444",
        "rarity_score": 33.31645569620253,
        "rarity_level": "COMMON"
    },
    "445": {
        "name": "Kishu Ken #445",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/445.png",
        "dna": "968675317abc6afc78664408dc3e36c8b9e21e46",
        "edition": 445,
        "date": 1662952218670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "445",
        "rarity_score": 71.51276308611135,
        "rarity_level": "COMMON"
    },
    "446": {
        "name": "Kishu Ken #446",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/446.png",
        "dna": "fe225ca9004e48d0ec3a0b9cc93e051be2f0266e",
        "edition": 446,
        "date": 1662952241605,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.25,
        "id": "446",
        "rarity_score": 37.202300638019196,
        "rarity_level": "COMMON"
    },
    "447": {
        "name": "Kishu Ken #447",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/447.png",
        "dna": "11216e4f6043a8b7b871b9200c684231fd99efcb",
        "edition": 447,
        "date": 1662951905918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "447",
        "rarity_score": 63.61287065540559,
        "rarity_level": "COMMON"
    },
    "448": {
        "name": "Kishu Ken #448",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/448.png",
        "dna": "dd91d77d9c1178c08f8ab7da7dfe48f8087ba132",
        "edition": 448,
        "date": 1662951910425,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "448",
        "rarity_score": 45.070066957638325,
        "rarity_level": "COMMON"
    },
    "449": {
        "name": "Kishu Ken #449",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/449.png",
        "dna": "f7344422c3f419ec8d93ec2ef8d722cbf1e7716b",
        "edition": 449,
        "date": 1662951874873,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "449",
        "rarity_score": 86.85902190575088,
        "rarity_level": "COMMON"
    },
    "450": {
        "name": "Kishu Ken #450",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/450.png",
        "dna": "5b4161d73eba89c87cb8964bfbe2c28ef8f89c51",
        "edition": 450,
        "date": 1662952242294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "450",
        "rarity_score": 68.89723148007877,
        "rarity_level": "COMMON"
    },
    "451": {
        "name": "Kishu Ken #451",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/451.png",
        "dna": "f64f692d4726eb2a4622615984b34bd2521e3318",
        "edition": 451,
        "date": 1662952521386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "451",
        "rarity_score": 101.85155401873666,
        "rarity_level": "RARE"
    },
    "452": {
        "name": "Kishu Ken #452",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/452.png",
        "dna": "8d0bb0f9a8970e76f92163d0a35c600ebb6a2b67",
        "edition": 452,
        "date": 1662952355240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "452",
        "rarity_score": 110.10014082303238,
        "rarity_level": "RARE"
    },
    "453": {
        "name": "Kishu Ken #453",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/453.png",
        "dna": "b54970dd13e9c75ec17c732da6eed95af212fce2",
        "edition": 453,
        "date": 1662952091204,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "453",
        "rarity_score": 38.21993413269912,
        "rarity_level": "COMMON"
    },
    "454": {
        "name": "Kishu Ken #454",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/454.png",
        "dna": "f64fef84cd206ab78bf9160240dcd3478a74b02d",
        "edition": 454,
        "date": 1662952045418,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.666666666666667,
        "id": "454",
        "rarity_score": 150.93708165997322,
        "rarity_level": "EPIC"
    },
    "455": {
        "name": "Kishu Ken #455",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/455.png",
        "dna": "12d8adb1baa174a73d54416e9ac2eb7ce19801f8",
        "edition": 455,
        "date": 1662951881598,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "455",
        "rarity_score": 53.23489905568219,
        "rarity_level": "COMMON"
    },
    "456": {
        "name": "Kishu Ken #456",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/456.png",
        "dna": "4973d14d05acc41ba1920e6afc796c0e4f273f77",
        "edition": 456,
        "date": 1662952500642,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "456",
        "rarity_score": 60.67319277108434,
        "rarity_level": "COMMON"
    },
    "457": {
        "name": "Kishu Ken #457",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/457.png",
        "dna": "c275d31a8f5b13f356cb88534ce36e50cd7cad82",
        "edition": 457,
        "date": 1662952302871,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "457",
        "rarity_score": 51.57167020948938,
        "rarity_level": "COMMON"
    },
    "458": {
        "name": "Kishu Ken #458",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/458.png",
        "dna": "ff88f8e6cf7a8f249ec19d120da7d0a9c93434cc",
        "edition": 458,
        "date": 1662952079683,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "458",
        "rarity_score": 42.18287290576447,
        "rarity_level": "COMMON"
    },
    "459": {
        "name": "Kishu Ken #459",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/459.png",
        "dna": "96273f0bc7b79b24e2d69482e5cb7b91d1c68d0e",
        "edition": 459,
        "date": 1662952047581,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "459",
        "rarity_score": 45.123572170301145,
        "rarity_level": "COMMON"
    },
    "460": {
        "name": "Kishu Ken #460",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/460.png",
        "dna": "1994091722c49978401b0a75ed935bdfdfe8a363",
        "edition": 460,
        "date": 1662951872751,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "460",
        "rarity_score": 57.45070336346834,
        "rarity_level": "COMMON"
    },
    "461": {
        "name": "Kishu Ken #461",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/461.png",
        "dna": "7052f8ad4e93c8601de96529d5b67836bed3912d",
        "edition": 461,
        "date": 1662952063331,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "461",
        "rarity_score": 141.4886515353805,
        "rarity_level": "EPIC"
    },
    "462": {
        "name": "Kishu Ken #462",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/462.png",
        "dna": "7cc62bd7f9410e972ca5c1452481c8be0517c90d",
        "edition": 462,
        "date": 1662952121765,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "462",
        "rarity_score": 47.783928506820075,
        "rarity_level": "COMMON"
    },
    "463": {
        "name": "Kishu Ken #463",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/463.png",
        "dna": "21f871b81d45b9d2705d8e4f01339612e414bfb6",
        "edition": 463,
        "date": 1662952531997,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.25,
        "id": "463",
        "rarity_score": 86.16427432216906,
        "rarity_level": "COMMON"
    },
    "464": {
        "name": "Kishu Ken #464",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/464.png",
        "dna": "f515704c95bc2cc4cdaaa95e1e847c1a39379f61",
        "edition": 464,
        "date": 1662952054660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "464",
        "rarity_score": 40.7321959231726,
        "rarity_level": "COMMON"
    },
    "465": {
        "name": "Kishu Ken #465",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/465.png",
        "dna": "12b149dc3c5d7d9c33dc13397138aaaefeffcb22",
        "edition": 465,
        "date": 1662952224632,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.75,
        "id": "465",
        "rarity_score": 56.89506300989871,
        "rarity_level": "COMMON"
    },
    "466": {
        "name": "Kishu Ken #466",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/466.png",
        "dna": "b00f87f2430210bfe754769f8e28ecb57b98f5b9",
        "edition": 466,
        "date": 1662952405994,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.25,
        "id": "466",
        "rarity_score": 112.30958781362007,
        "rarity_level": "RARE"
    },
    "467": {
        "name": "Kishu Ken #467",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/467.png",
        "dna": "da99717bc6bb99711b2af272b51a1cc205e62011",
        "edition": 467,
        "date": 1662952466304,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "467",
        "rarity_score": 62.57196145748705,
        "rarity_level": "COMMON"
    },
    "468": {
        "name": "Kishu Ken #468",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/468.png",
        "dna": "ce194d23681a81d9d6243094e0f6eab19d19949b",
        "edition": 468,
        "date": 1662952487348,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "468",
        "rarity_score": 79.62628681616023,
        "rarity_level": "COMMON"
    },
    "469": {
        "name": "Kishu Ken #469",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/469.png",
        "dna": "96933b77bacc41c24ce2c8c47856fc9b71a613f2",
        "edition": 469,
        "date": 1662952006617,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "469",
        "rarity_score": 32.096385542168676,
        "rarity_level": "COMMON"
    },
    "470": {
        "name": "Kishu Ken #470",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/470.png",
        "dna": "305c7dca8682098a7439a08c40f77b98377282f4",
        "edition": 470,
        "date": 1662951829398,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "470",
        "rarity_score": 96.39190609397464,
        "rarity_level": "RARE"
    },
    "471": {
        "name": "Kishu Ken #471",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/471.png",
        "dna": "bb456d91043e9ab17729f39f6066e0c233a693f5",
        "edition": 471,
        "date": 1662951812099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "471",
        "rarity_score": 64.12533354706252,
        "rarity_level": "COMMON"
    },
    "472": {
        "name": "Kishu Ken #472",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/472.png",
        "dna": "63b3a9536ba0129291a5208d4abc896aa48da5bf",
        "edition": 472,
        "date": 1662952386791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "472",
        "rarity_score": 70.79047354276712,
        "rarity_level": "COMMON"
    },
    "473": {
        "name": "Kishu Ken #473",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/473.png",
        "dna": "bdb51578eb38570083805e2fbdea15982068c573",
        "edition": 473,
        "date": 1662952046715,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "473",
        "rarity_score": 42.572757762631184,
        "rarity_level": "COMMON"
    },
    "474": {
        "name": "Kishu Ken #474",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/474.png",
        "dna": "e7ee9e27ec4e165061e81280b720b5416e7dcc9d",
        "edition": 474,
        "date": 1662951942463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "474",
        "rarity_score": 100.72340644629801,
        "rarity_level": "RARE"
    },
    "475": {
        "name": "Kishu Ken #475",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/475.png",
        "dna": "f6bf7449ac4a27e9bc2236be2c1858967c1a0c81",
        "edition": 475,
        "date": 1662952478200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "475",
        "rarity_score": 68.85926459680672,
        "rarity_level": "COMMON"
    },
    "476": {
        "name": "Kishu Ken #476",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/476.png",
        "dna": "260e7884431733fec5facf008d8aaeac474364a0",
        "edition": 476,
        "date": 1662952185987,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "476",
        "rarity_score": 55.29118639124812,
        "rarity_level": "COMMON"
    },
    "477": {
        "name": "Kishu Ken #477",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/477.png",
        "dna": "1b29d823bd530b18bfa5738289dceb6aa326588a",
        "edition": 477,
        "date": 1662952493749,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "477",
        "rarity_score": 64.82683433902946,
        "rarity_level": "COMMON"
    },
    "478": {
        "name": "Kishu Ken #478",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/478.png",
        "dna": "87e61a7e56b52e973fad85f71878d6eebbf99f5b",
        "edition": 478,
        "date": 1662952298724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "478",
        "rarity_score": 56.124893925023706,
        "rarity_level": "COMMON"
    },
    "479": {
        "name": "Kishu Ken #479",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/479.png",
        "dna": "05fb42c3c1738f429605f8150b39c785947f8353",
        "edition": 479,
        "date": 1662952549782,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "479",
        "rarity_score": 47.15801826249588,
        "rarity_level": "COMMON"
    },
    "480": {
        "name": "Kishu Ken #480",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/480.png",
        "dna": "8fb7a10959b2fbf54ff0f22a8dc45a5bc2ccb175",
        "edition": 480,
        "date": 1662952525192,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "480",
        "rarity_score": 44.97714125378657,
        "rarity_level": "COMMON"
    },
    "481": {
        "name": "Kishu Ken #481",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/481.png",
        "dna": "790a266c61895bcd3db2a6814b3c787b56b36d67",
        "edition": 481,
        "date": 1662952411397,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "481",
        "rarity_score": 52.826817001946786,
        "rarity_level": "COMMON"
    },
    "482": {
        "name": "Kishu Ken #482",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/482.png",
        "dna": "feef8841ecd74b9c979c4cd184b72d65415794a4",
        "edition": 482,
        "date": 1662952320715,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "482",
        "rarity_score": 83.77815506508205,
        "rarity_level": "COMMON"
    },
    "483": {
        "name": "Kishu Ken #483",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/483.png",
        "dna": "a2f63068562bef1f99161bf190faedbfa9678109",
        "edition": 483,
        "date": 1662952372300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "483",
        "rarity_score": 47.25482797771954,
        "rarity_level": "COMMON"
    },
    "484": {
        "name": "Kishu Ken #484",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/484.png",
        "dna": "ec60d904f853077453bfe52234df0613967292f3",
        "edition": 484,
        "date": 1662952330852,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "484",
        "rarity_score": 77.86638456408252,
        "rarity_level": "COMMON"
    },
    "485": {
        "name": "Kishu Ken #485",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/485.png",
        "dna": "a30ac8aea17125421b07538fe3b012eca13a746b",
        "edition": 485,
        "date": 1662952100534,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "485",
        "rarity_score": 34.90750067712122,
        "rarity_level": "COMMON"
    },
    "486": {
        "name": "Kishu Ken #486",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/486.png",
        "dna": "9eb72db76e4902d81312f1a114d3fc1afda78f65",
        "edition": 486,
        "date": 1662952394215,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "486",
        "rarity_score": 107.7326203208556,
        "rarity_level": "RARE"
    },
    "487": {
        "name": "Kishu Ken #487",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/487.png",
        "dna": "aa5aed92cf5d1ff0b34c9addbf7b46947cd01e86",
        "edition": 487,
        "date": 1662951846121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "487",
        "rarity_score": 44.83555466292384,
        "rarity_level": "COMMON"
    },
    "488": {
        "name": "Kishu Ken #488",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/488.png",
        "dna": "cca9e3a56a325876c1c7c2479643267aa1a4a07d",
        "edition": 488,
        "date": 1662952541468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "488",
        "rarity_score": 41.52325657738909,
        "rarity_level": "COMMON"
    },
    "489": {
        "name": "Kishu Ken #489",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/489.png",
        "dna": "075a8efe396c494da67b4f766dcc2e866b420aff",
        "edition": 489,
        "date": 1662951796298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "489",
        "rarity_score": 61.67976329984053,
        "rarity_level": "COMMON"
    },
    "490": {
        "name": "Kishu Ken #490",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/490.png",
        "dna": "d3695f308ead455bc3fb11369fc062a187a2b8c5",
        "edition": 490,
        "date": 1662952282900,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "490",
        "rarity_score": 43.74405722353483,
        "rarity_level": "COMMON"
    },
    "491": {
        "name": "Kishu Ken #491",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/491.png",
        "dna": "a42a98e9ca7ffa67b5ab8e110567fbb6528f4181",
        "edition": 491,
        "date": 1662952042886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "491",
        "rarity_score": 35.517535754138144,
        "rarity_level": "COMMON"
    },
    "492": {
        "name": "Kishu Ken #492",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/492.png",
        "dna": "0356d790fb35770448cb14ce1a9731465718c04c",
        "edition": 492,
        "date": 1662951911817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "492",
        "rarity_score": 44.462146394778735,
        "rarity_level": "COMMON"
    },
    "493": {
        "name": "Kishu Ken #493",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/493.png",
        "dna": "4bcdc3aa3bdabd24b9e020e0664a02c5457719ad",
        "edition": 493,
        "date": 1662952233978,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "493",
        "rarity_score": 77.93680260689246,
        "rarity_level": "COMMON"
    },
    "494": {
        "name": "Kishu Ken #494",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/494.png",
        "dna": "73d729bda8ff30da7189de089e2ec706378fd30f",
        "edition": 494,
        "date": 1662952102708,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "494",
        "rarity_score": 45.081530128259104,
        "rarity_level": "COMMON"
    },
    "495": {
        "name": "Kishu Ken #495",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/495.png",
        "dna": "dfb39099d60cde914df18ba2158f9a4842fdd2e2",
        "edition": 495,
        "date": 1662951816522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "495",
        "rarity_score": 65.44716135992635,
        "rarity_level": "COMMON"
    },
    "496": {
        "name": "Kishu Ken #496",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/496.png",
        "dna": "e0b47bc713578b663b9fcfa51c6fe72f2b958355",
        "edition": 496,
        "date": 1662952346261,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "496",
        "rarity_score": 93.46345662135136,
        "rarity_level": "RARE"
    },
    "497": {
        "name": "Kishu Ken #497",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/497.png",
        "dna": "c3af22a6fa0498a02093bd677aef8952a4de8d26",
        "edition": 497,
        "date": 1662952339544,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "497",
        "rarity_score": 70.16785089074244,
        "rarity_level": "COMMON"
    },
    "498": {
        "name": "Kishu Ken #498",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/498.png",
        "dna": "7515e0dd12597d23110d5b0653e247037de0ed03",
        "edition": 498,
        "date": 1662952502758,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "498",
        "rarity_score": 92.65984235255526,
        "rarity_level": "RARE"
    },
    "499": {
        "name": "Kishu Ken #499",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/499.png",
        "dna": "1be892cf9591e699ad1b20f07a01adb1ae563829",
        "edition": 499,
        "date": 1662952018301,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16.666666666666668,
        "id": "499",
        "rarity_score": 23.95133810254469,
        "rarity_level": "COMMON"
    },
    "500": {
        "name": "Kishu Ken #500",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/500.png",
        "dna": "8cf090272df0785414f91eb9ee828c56f68da2db",
        "edition": 500,
        "date": 1662951869090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "500",
        "rarity_score": 69.95533410654069,
        "rarity_level": "COMMON"
    },
    "501": {
        "name": "Kishu Ken #501",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/501.png",
        "dna": "6ecac2be33c680471fc9570284ec255f9140fc65",
        "edition": 501,
        "date": 1662952324386,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rarity": 6,
        "id": "501",
        "rarity_score": 122.88759347582877,
        "rarity_level": "EPIC"
    },
    "502": {
        "name": "Kishu Ken #502",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/502.png",
        "dna": "d37f0e514cd898a45b377cf8b4de2c746af22e9b",
        "edition": 502,
        "date": 1662952463431,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "502",
        "rarity_score": 69.0905190905191,
        "rarity_level": "COMMON"
    },
    "503": {
        "name": "Kishu Ken #503",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/503.png",
        "dna": "675002ef7a760309e2520a53a37f60e823d0a73d",
        "edition": 503,
        "date": 1662952476072,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "503",
        "rarity_score": 51.92826005729232,
        "rarity_level": "COMMON"
    },
    "504": {
        "name": "Kishu Ken #504",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/504.png",
        "dna": "aa165cfb4a921b8f360560492ae4a73bd92ca0de",
        "edition": 504,
        "date": 1662951847548,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "504",
        "rarity_score": 62.71279775952673,
        "rarity_level": "COMMON"
    },
    "505": {
        "name": "Kishu Ken #505",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/505.png",
        "dna": "b6e7827a35c1868e5723a94120053279f37ad70b",
        "edition": 505,
        "date": 1662951898930,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4,
        "id": "505",
        "rarity_score": 130.69898642187798,
        "rarity_level": "EPIC"
    },
    "506": {
        "name": "Kishu Ken #506",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/506.png",
        "dna": "413df71e2e62ed723abfa5151e78838ef9fce1fc",
        "edition": 506,
        "date": 1662952220142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "506",
        "rarity_score": 47.50924427806507,
        "rarity_level": "COMMON"
    },
    "507": {
        "name": "Kishu Ken #507",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/507.png",
        "dna": "4b58f8b548d7c43c0e2d64e702372a765de4a082",
        "edition": 507,
        "date": 1662951880851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "507",
        "rarity_score": 61.12515346550332,
        "rarity_level": "COMMON"
    },
    "508": {
        "name": "Kishu Ken #508",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/508.png",
        "dna": "89a59a1a4ee87172e54902f1e4f58bfe9df84102",
        "edition": 508,
        "date": 1662952364795,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "508",
        "rarity_score": 72.28476535541753,
        "rarity_level": "COMMON"
    },
    "509": {
        "name": "Kishu Ken #509",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/509.png",
        "dna": "3f256a3af11e2978422b3e5d6ac7195c3b436c4e",
        "edition": 509,
        "date": 1662952282189,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "509",
        "rarity_score": 53.26754577157803,
        "rarity_level": "COMMON"
    },
    "510": {
        "name": "Kishu Ken #510",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/510.png",
        "dna": "c5e533df77a81b5e9cef128b4ee7a9104ddd2725",
        "edition": 510,
        "date": 1662952426957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "510",
        "rarity_score": 82.43112091059852,
        "rarity_level": "COMMON"
    },
    "511": {
        "name": "Kishu Ken #511",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/511.png",
        "dna": "af4463dee544c95edbedf314fd3ea851dd24b657",
        "edition": 511,
        "date": 1662952288942,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "511",
        "rarity_score": 52.20233653357748,
        "rarity_level": "COMMON"
    },
    "512": {
        "name": "Kishu Ken #512",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/512.png",
        "dna": "5d4b4ccbaaae667720180d683bd1346fca53fea8",
        "edition": 512,
        "date": 1662952111549,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "512",
        "rarity_score": 72.95660111194093,
        "rarity_level": "COMMON"
    },
    "513": {
        "name": "Kishu Ken #513",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/513.png",
        "dna": "c1050729c2e3e9f2b2fc1824c505cc9d30a946d2",
        "edition": 513,
        "date": 1662952052157,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "513",
        "rarity_score": 45.819848975188776,
        "rarity_level": "COMMON"
    },
    "514": {
        "name": "Kishu Ken #514",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/514.png",
        "dna": "3dc1a26d02d49e08c63aef3b64f69967395c0bfc",
        "edition": 514,
        "date": 1662951959154,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "514",
        "rarity_score": 72.72767229254572,
        "rarity_level": "COMMON"
    },
    "515": {
        "name": "Kishu Ken #515",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/515.png",
        "dna": "f0f4e8bbaf6f59221359043d2514c4e5cf85cb53",
        "edition": 515,
        "date": 1662952507697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "515",
        "rarity_score": 105.9756587279523,
        "rarity_level": "RARE"
    },
    "516": {
        "name": "Kishu Ken #516",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/516.png",
        "dna": "45fcf6c475bb33b2ea3566769ee3d3a9f1cfb54d",
        "edition": 516,
        "date": 1662952032046,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "516",
        "rarity_score": 38.67764130106622,
        "rarity_level": "COMMON"
    },
    "517": {
        "name": "Kishu Ken #517",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/517.png",
        "dna": "7fe4707dd7c503fbcb6090c388646be130746a63",
        "edition": 517,
        "date": 1662952306533,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "517",
        "rarity_score": 97.27597634405939,
        "rarity_level": "RARE"
    },
    "518": {
        "name": "Kishu Ken #518",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/518.png",
        "dna": "b4884368ba93d3f57284d9ca33932e8e02dc391e",
        "edition": 518,
        "date": 1662952477477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5,
        "id": "518",
        "rarity_score": 129.87012987012986,
        "rarity_level": "EPIC"
    },
    "519": {
        "name": "Kishu Ken #519",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/519.png",
        "dna": "99a1dbd7d8416b987731a1c057cf4bffc2fdcf3f",
        "edition": 519,
        "date": 1662952201529,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "519",
        "rarity_score": 51.26494897292859,
        "rarity_level": "COMMON"
    },
    "520": {
        "name": "Kishu Ken #520",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/520.png",
        "dna": "70509307cd66896582082ae5c09ea0ce57432951",
        "edition": 520,
        "date": 1662952421766,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "520",
        "rarity_score": 53.37893668013665,
        "rarity_level": "COMMON"
    },
    "521": {
        "name": "Kishu Ken #521",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/521.png",
        "dna": "956aec1db39e7eacc1b8065ca8f822a2ab05e3af",
        "edition": 521,
        "date": 1662952298016,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "521",
        "rarity_score": 69.94255774268754,
        "rarity_level": "COMMON"
    },
    "522": {
        "name": "Kishu Ken #522",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/522.png",
        "dna": "7763d5ab20a80a869354960f78b35e3827fe896c",
        "edition": 522,
        "date": 1662952529918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "522",
        "rarity_score": 83.22376548183001,
        "rarity_level": "COMMON"
    },
    "523": {
        "name": "Kishu Ken #523",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/523.png",
        "dna": "fc6fb1296c0f4dd3f40e0a90a66a0ee12907a0d0",
        "edition": 523,
        "date": 1662951975489,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "523",
        "rarity_score": 83.63201037145225,
        "rarity_level": "COMMON"
    },
    "524": {
        "name": "Kishu Ken #524",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/524.png",
        "dna": "5a61bb5223f253acc3a9a34f2ad0b544f4e7ddb0",
        "edition": 524,
        "date": 1662951989129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "524",
        "rarity_score": 31.849277762042746,
        "rarity_level": "COMMON"
    },
    "525": {
        "name": "Kishu Ken #525",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/525.png",
        "dna": "14ee8a7f3aebb23d5b2923527376fdcd9cb7b854",
        "edition": 525,
        "date": 1662951945283,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "525",
        "rarity_score": 62.31772183253591,
        "rarity_level": "COMMON"
    },
    "526": {
        "name": "Kishu Ken #526",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/526.png",
        "dna": "4e95362acda09084aa901f09408a90561817cf86",
        "edition": 526,
        "date": 1662951932641,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "526",
        "rarity_score": 57.385163133723275,
        "rarity_level": "COMMON"
    },
    "527": {
        "name": "Kishu Ken #527",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/527.png",
        "dna": "50054a81c2ab355521e370ac789554748f7f32c4",
        "edition": 527,
        "date": 1662952117701,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "527",
        "rarity_score": 41.93482359278212,
        "rarity_level": "COMMON"
    },
    "528": {
        "name": "Kishu Ken #528",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/528.png",
        "dna": "47cea7cab9bbe50ddc48ad17e6789d8c0be831d6",
        "edition": 528,
        "date": 1662951983989,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "528",
        "rarity_score": 35.64581952405089,
        "rarity_level": "COMMON"
    },
    "529": {
        "name": "Kishu Ken #529",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/529.png",
        "dna": "f4748171e3ac8ef6b69d6f1520aaba3552eda8cd",
        "edition": 529,
        "date": 1662952381690,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.25,
        "id": "529",
        "rarity_score": 98.8947988947989,
        "rarity_level": "RARE"
    },
    "530": {
        "name": "Kishu Ken #530",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/530.png",
        "dna": "1a56c5bd222eabfbc4e9bd46ff91d60f7d0f2643",
        "edition": 530,
        "date": 1662952015697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.333333333333334,
        "id": "530",
        "rarity_score": 39.726659011031074,
        "rarity_level": "COMMON"
    },
    "531": {
        "name": "Kishu Ken #531",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/531.png",
        "dna": "0d495154c937585efa28e0b93bd5e2b9bb86f6d2",
        "edition": 531,
        "date": 1662952326458,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "531",
        "rarity_score": 52.265017817441176,
        "rarity_level": "COMMON"
    },
    "532": {
        "name": "Kishu Ken #532",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/532.png",
        "dna": "9810e1662342c8863ba317c74731d49389b5e6fd",
        "edition": 532,
        "date": 1662951966362,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "532",
        "rarity_score": 73.66885182419163,
        "rarity_level": "COMMON"
    },
    "533": {
        "name": "Kishu Ken #533",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/533.png",
        "dna": "123998ee69899f2348a9898ec9299dcb13b5949a",
        "edition": 533,
        "date": 1662952131495,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "533",
        "rarity_score": 44.4558791579477,
        "rarity_level": "COMMON"
    },
    "534": {
        "name": "Kishu Ken #534",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/534.png",
        "dna": "a1a49d676aba10393ed4a0f186df1cd27ef1806c",
        "edition": 534,
        "date": 1662952309539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "534",
        "rarity_score": 69.35949711179069,
        "rarity_level": "COMMON"
    },
    "535": {
        "name": "Kishu Ken #535",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/535.png",
        "dna": "317dffd1f7d16590b3d941092475ee0d0ecf8760",
        "edition": 535,
        "date": 1662952193829,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "535",
        "rarity_score": 85.41894083920793,
        "rarity_level": "COMMON"
    },
    "536": {
        "name": "Kishu Ken #536",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/536.png",
        "dna": "3664ca70f31d189dd8f722ef76e4108d7599fcc9",
        "edition": 536,
        "date": 1662952407668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "536",
        "rarity_score": 46.565425669903284,
        "rarity_level": "COMMON"
    },
    "537": {
        "name": "Kishu Ken #537",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/537.png",
        "dna": "bb62e2f5b8bc45e717de054b2a8aa77ef36695d0",
        "edition": 537,
        "date": 1662951837157,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "537",
        "rarity_score": 61.50118422407579,
        "rarity_level": "COMMON"
    },
    "538": {
        "name": "Kishu Ken #538",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/538.png",
        "dna": "5c2621c0676a0871b753d9ac1a93c6de67f9ceae",
        "edition": 538,
        "date": 1662952260346,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "538",
        "rarity_score": 62.78430598071163,
        "rarity_level": "COMMON"
    },
    "539": {
        "name": "Kishu Ken #539",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/539.png",
        "dna": "d89f852765526fc5c13c001c4fd6bb4f0d623798",
        "edition": 539,
        "date": 1662952428310,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "539",
        "rarity_score": 42.66932177379938,
        "rarity_level": "COMMON"
    },
    "540": {
        "name": "Kishu Ken #540",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/540.png",
        "dna": "4d9071be53044d3e1cc4a41b2ef340f15c16b37c",
        "edition": 540,
        "date": 1662952375602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "540",
        "rarity_score": 106.55806182121972,
        "rarity_level": "RARE"
    },
    "541": {
        "name": "Kishu Ken #541",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/541.png",
        "dna": "1124a16975d6c0aa5fbdf2cdb8d84cd9f84734f4",
        "edition": 541,
        "date": 1662952305064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "541",
        "rarity_score": 111.47439305334042,
        "rarity_level": "RARE"
    },
    "542": {
        "name": "Kishu Ken #542",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/542.png",
        "dna": "6397af3ccfb30e21ec0dd8c4dcce9caf99ca1217",
        "edition": 542,
        "date": 1662952290289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "542",
        "rarity_score": 91.7895972098643,
        "rarity_level": "RARE"
    },
    "543": {
        "name": "Kishu Ken #543",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/543.png",
        "dna": "e8f3b7b431317f71ff6dfc0c3fb142473dddd606",
        "edition": 543,
        "date": 1662952265677,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "543",
        "rarity_score": 102.62916453817434,
        "rarity_level": "RARE"
    },
    "544": {
        "name": "Kishu Ken #544",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/544.png",
        "dna": "641696ff9c4c35ac92e9159663b6ecd928d43ae1",
        "edition": 544,
        "date": 1662952095614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "544",
        "rarity_score": 36.194667513573194,
        "rarity_level": "COMMON"
    },
    "545": {
        "name": "Kishu Ken #545",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/545.png",
        "dna": "6ae389904470a6d9aa93d9919d54c92c9afdee4a",
        "edition": 545,
        "date": 1662952143653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "545",
        "rarity_score": 57.10267229254571,
        "rarity_level": "COMMON"
    },
    "546": {
        "name": "Kishu Ken #546",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/546.png",
        "dna": "7339004d3b7290005229e63d6beb62f1207a34dd",
        "edition": 546,
        "date": 1662952190903,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "546",
        "rarity_score": 47.18410948594842,
        "rarity_level": "COMMON"
    },
    "547": {
        "name": "Kishu Ken #547",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/547.png",
        "dna": "c33985b53f3dd9892a9fab440e18f26ef6741481",
        "edition": 547,
        "date": 1662952458908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "547",
        "rarity_score": 52.73919950802029,
        "rarity_level": "COMMON"
    },
    "548": {
        "name": "Kishu Ken #548",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/548.png",
        "dna": "950ab4cce28f0325622cb20e302daf549050a1a4",
        "edition": 548,
        "date": 1662951845410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "548",
        "rarity_score": 60.07221736735394,
        "rarity_level": "COMMON"
    },
    "549": {
        "name": "Kishu Ken #549",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/549.png",
        "dna": "fb44a36b5322848d1e2d7d530104380137b27933",
        "edition": 549,
        "date": 1662952453064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "549",
        "rarity_score": 61.498011698243204,
        "rarity_level": "COMMON"
    },
    "550": {
        "name": "Kishu Ken #550",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/550.png",
        "dna": "e5397286775f28fdfb4fd3fc36c6589395cb3921",
        "edition": 550,
        "date": 1662952082057,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.333333333333334,
        "id": "550",
        "rarity_score": 48.159303882195445,
        "rarity_level": "COMMON"
    },
    "551": {
        "name": "Kishu Ken #551",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/551.png",
        "dna": "e7d3988656987435c10c541c2de2aa3f697015b7",
        "edition": 551,
        "date": 1662952009281,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "551",
        "rarity_score": 45.706660896534316,
        "rarity_level": "COMMON"
    },
    "552": {
        "name": "Kishu Ken #552",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/552.png",
        "dna": "830ef3945e2362f063bde7bb5d80b136de601265",
        "edition": 552,
        "date": 1662952495516,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "552",
        "rarity_score": 70.18341713917961,
        "rarity_level": "COMMON"
    },
    "553": {
        "name": "Kishu Ken #553",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/553.png",
        "dna": "1cba677da438611182de6195e1067e6842ccf07d",
        "edition": 553,
        "date": 1662951967770,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "553",
        "rarity_score": 62.92538575354048,
        "rarity_level": "COMMON"
    },
    "554": {
        "name": "Kishu Ken #554",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/554.png",
        "dna": "c123396872491ee5b3218b8d021dd96533986c56",
        "edition": 554,
        "date": 1662951793476,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "554",
        "rarity_score": 65.12202656780971,
        "rarity_level": "COMMON"
    },
    "555": {
        "name": "Kishu Ken #555",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/555.png",
        "dna": "831a1e86781bc7efa1a4edd0951becd3528d9637",
        "edition": 555,
        "date": 1662952531374,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "555",
        "rarity_score": 73.44477080072689,
        "rarity_level": "COMMON"
    },
    "556": {
        "name": "Kishu Ken #556",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/556.png",
        "dna": "96c49d2a7d8d95183545b7fd372024a3f983aeab",
        "edition": 556,
        "date": 1662952533433,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "556",
        "rarity_score": 42.13329165440602,
        "rarity_level": "COMMON"
    },
    "557": {
        "name": "Kishu Ken #557",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/557.png",
        "dna": "a3a675310945395395bc1b552d65251b6aeee6bf",
        "edition": 557,
        "date": 1662952202265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "557",
        "rarity_score": 75.87522587522588,
        "rarity_level": "COMMON"
    },
    "558": {
        "name": "Kishu Ken #558",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/558.png",
        "dna": "ac034aa346fa616bc40041a59c95376c56feb523",
        "edition": 558,
        "date": 1662952502029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "558",
        "rarity_score": 60.23596407940015,
        "rarity_level": "COMMON"
    },
    "559": {
        "name": "Kishu Ken #559",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/559.png",
        "dna": "c0be41fe98e9f632af422888d260aab1326c4af1",
        "edition": 559,
        "date": 1662951917504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "559",
        "rarity_score": 114.45059517348675,
        "rarity_level": "RARE"
    },
    "560": {
        "name": "Kishu Ken #560",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/560.png",
        "dna": "f6809fe661fcf83cf89c2c2b443ea17f79817383",
        "edition": 560,
        "date": 1662952406938,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "560",
        "rarity_score": 69.67559096251794,
        "rarity_level": "COMMON"
    },
    "561": {
        "name": "Kishu Ken #561",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/561.png",
        "dna": "b5b0e6ee290411dc43204ee48b07e13b5df7e708",
        "edition": 561,
        "date": 1662952359237,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.25,
        "id": "561",
        "rarity_score": 135.8111759116063,
        "rarity_level": "EPIC"
    },
    "562": {
        "name": "Kishu Ken #562",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/562.png",
        "dna": "6ec0520849dce98e6c73fc6ad065710ed8819dab",
        "edition": 562,
        "date": 1662952287421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "562",
        "rarity_score": 82.31693231693231,
        "rarity_level": "COMMON"
    },
    "563": {
        "name": "Kishu Ken #563",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/563.png",
        "dna": "9cbbed1e1c2847d04862caf17ce8cc997b24556e",
        "edition": 563,
        "date": 1662951964918,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "563",
        "rarity_score": 61.73405139200992,
        "rarity_level": "COMMON"
    },
    "564": {
        "name": "Kishu Ken #564",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/564.png",
        "dna": "96ca337630cdd4e923ca6fe92ed42aaa4f544bc5",
        "edition": 564,
        "date": 1662952319763,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "564",
        "rarity_score": 75.22488038277513,
        "rarity_level": "COMMON"
    },
    "565": {
        "name": "Kishu Ken #565",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/565.png",
        "dna": "7ea5765aa1ae5f1f22bac4e55a97e16b910f31e7",
        "edition": 565,
        "date": 1662952075794,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "565",
        "rarity_score": 44.49884712227204,
        "rarity_level": "COMMON"
    },
    "566": {
        "name": "Kishu Ken #566",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/566.png",
        "dna": "6cdceaf24ec7ea429b87b6617fea18d0205fac2d",
        "edition": 566,
        "date": 1662952263459,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "566",
        "rarity_score": 90.82315088445633,
        "rarity_level": "RARE"
    },
    "567": {
        "name": "Kishu Ken #567",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/567.png",
        "dna": "04060302f357a9570878a6df0d9504ec3d334ece",
        "edition": 567,
        "date": 1662952296541,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "567",
        "rarity_score": 70.25462962962962,
        "rarity_level": "COMMON"
    },
    "568": {
        "name": "Kishu Ken #568",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/568.png",
        "dna": "bccb335f7f11ebe99ee4405a9327e9186bee6953",
        "edition": 568,
        "date": 1662951937182,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "568",
        "rarity_score": 53.9396978295119,
        "rarity_level": "COMMON"
    },
    "569": {
        "name": "Kishu Ken #569",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/569.png",
        "dna": "e1e67cda989da64062006581e9387ee6fe7c537c",
        "edition": 569,
        "date": 1662952345519,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "569",
        "rarity_score": 73.83815294263056,
        "rarity_level": "COMMON"
    },
    "570": {
        "name": "Kishu Ken #570",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/570.png",
        "dna": "86a5e51f4bbc87e452987534ab281f069c0a1ba9",
        "edition": 570,
        "date": 1662952217030,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "570",
        "rarity_score": 52.353559543432965,
        "rarity_level": "COMMON"
    },
    "571": {
        "name": "Kishu Ken #571",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/571.png",
        "dna": "0b79c74050d8c35fa9edb9fc16170d2cdce9e1a9",
        "edition": 571,
        "date": 1662952080887,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.333333333333333,
        "id": "571",
        "rarity_score": 62.97411869701027,
        "rarity_level": "COMMON"
    },
    "572": {
        "name": "Kishu Ken #572",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/572.png",
        "dna": "0502605de1aeca6ba48ff2a8878f8e08394a1034",
        "edition": 572,
        "date": 1662952467730,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "572",
        "rarity_score": 54.52649232662211,
        "rarity_level": "COMMON"
    },
    "573": {
        "name": "Kishu Ken #573",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/573.png",
        "dna": "65532a77bbeb9f582e8bc4bdbe6797a4c9f0f116",
        "edition": 573,
        "date": 1662952342598,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "573",
        "rarity_score": 123.86547671177652,
        "rarity_level": "EPIC"
    },
    "574": {
        "name": "Kishu Ken #574",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/574.png",
        "dna": "6ae6f07ae249b22722c070815883912aa8c28c50",
        "edition": 574,
        "date": 1662952546191,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "574",
        "rarity_score": 65.88482505699275,
        "rarity_level": "COMMON"
    },
    "575": {
        "name": "Kishu Ken #575",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/575.png",
        "dna": "2a9b46974e44afd5af49a69f8022ed9844e97eea",
        "edition": 575,
        "date": 1662952451608,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "575",
        "rarity_score": 67.93743389138126,
        "rarity_level": "COMMON"
    },
    "576": {
        "name": "Kishu Ken #576",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/576.png",
        "dna": "3e25ca69316144e61c193f6d31c74847606e1ab8",
        "edition": 576,
        "date": 1662952102045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "576",
        "rarity_score": 42.6791277258567,
        "rarity_level": "COMMON"
    },
    "577": {
        "name": "Kishu Ken #577",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/577.png",
        "dna": "36d0b9e8e1d05a4400cecae968dcceff6d216230",
        "edition": 577,
        "date": 1662952086354,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.666666666666666,
        "id": "577",
        "rarity_score": 48.500333547062525,
        "rarity_level": "COMMON"
    },
    "578": {
        "name": "Kishu Ken #578",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/578.png",
        "dna": "fe8a23bfaea4b071a5de0e5cf51ce001d2bb679a",
        "edition": 578,
        "date": 1662951856874,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "578",
        "rarity_score": 60.86165515600619,
        "rarity_level": "COMMON"
    },
    "579": {
        "name": "Kishu Ken #579",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/579.png",
        "dna": "219298c4591d3a7bf3c40fdd6071fc3c9ee4895f",
        "edition": 579,
        "date": 1662951855375,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "579",
        "rarity_score": 82.07566187468443,
        "rarity_level": "COMMON"
    },
    "580": {
        "name": "Kishu Ken #580",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/580.png",
        "dna": "f692f9af3261b7e6bea8d99b0c4e2c7ad8d6acf4",
        "edition": 580,
        "date": 1662951830229,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "580",
        "rarity_score": 57.67464547366803,
        "rarity_level": "COMMON"
    },
    "581": {
        "name": "Kishu Ken #581",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/581.png",
        "dna": "653b54dfdc72a8789c8c05bed1543e86deed48ab",
        "edition": 581,
        "date": 1662952436769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "581",
        "rarity_score": 57.477934698313376,
        "rarity_level": "COMMON"
    },
    "582": {
        "name": "Kishu Ken #582",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/582.png",
        "dna": "cc1ce5565319bd72115b7ceb0863a39417df3ddd",
        "edition": 582,
        "date": 1662951894512,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "582",
        "rarity_score": 58.74159344366198,
        "rarity_level": "COMMON"
    },
    "583": {
        "name": "Kishu Ken #583",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/583.png",
        "dna": "62d214a3e15fa61aa0de220aa4a9b4871db72652",
        "edition": 583,
        "date": 1662952400500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "583",
        "rarity_score": 50.71452735234652,
        "rarity_level": "COMMON"
    },
    "584": {
        "name": "Kishu Ken #584",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/584.png",
        "dna": "767b5d068ee11cc20f3aba014dc7da83b39460a9",
        "edition": 584,
        "date": 1662951897671,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "584",
        "rarity_score": 41.20400103137021,
        "rarity_level": "COMMON"
    },
    "585": {
        "name": "Kishu Ken #585",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/585.png",
        "dna": "8d8da910e7ba8e19b1de2d5aadecf2e618418e9a",
        "edition": 585,
        "date": 1662952510693,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "585",
        "rarity_score": 65.85803027657403,
        "rarity_level": "COMMON"
    },
    "586": {
        "name": "Kishu Ken #586",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/586.png",
        "dna": "75210fe423e9f3593316b46f240ce853c5fe5475",
        "edition": 586,
        "date": 1662952024298,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "586",
        "rarity_score": 39.26032430705328,
        "rarity_level": "COMMON"
    },
    "587": {
        "name": "Kishu Ken #587",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/587.png",
        "dna": "d9014494a42dc933e9cf735a3d3f0bfa8fbc3d32",
        "edition": 587,
        "date": 1662952417252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "587",
        "rarity_score": 74.055330634278,
        "rarity_level": "COMMON"
    },
    "588": {
        "name": "Kishu Ken #588",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/588.png",
        "dna": "6aa0a84131c5e5750f8ebe08050e0cfbb285e6c7",
        "edition": 588,
        "date": 1662952338917,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "588",
        "rarity_score": 77.54348755384565,
        "rarity_level": "COMMON"
    },
    "589": {
        "name": "Kishu Ken #589",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/589.png",
        "dna": "a8f953f7fffd6c911eea9ed965080b90d5412a74",
        "edition": 589,
        "date": 1662952358550,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "589",
        "rarity_score": 114.90293387826594,
        "rarity_level": "RARE"
    },
    "590": {
        "name": "Kishu Ken #590",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/590.png",
        "dna": "9f23aa109566116337b40a5ec09c889f368ddb80",
        "edition": 590,
        "date": 1662951986976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.666666666666666,
        "id": "590",
        "rarity_score": 33.02439285176203,
        "rarity_level": "COMMON"
    },
    "591": {
        "name": "Kishu Ken #591",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/591.png",
        "dna": "2486280efc819710fcd6c976c15afd1ef8b0fb63",
        "edition": 591,
        "date": 1662952073849,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "591",
        "rarity_score": 38.98533360875852,
        "rarity_level": "COMMON"
    },
    "592": {
        "name": "Kishu Ken #592",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/592.png",
        "dna": "893c47b03b3b3b0cce0bf9cf36a733b1729ebad8",
        "edition": 592,
        "date": 1662952026616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "592",
        "rarity_score": 29.774373119586343,
        "rarity_level": "COMMON"
    },
    "593": {
        "name": "Kishu Ken #593",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/593.png",
        "dna": "018712721e237948a304899c230c1a6651c3abb8",
        "edition": 593,
        "date": 1662952343340,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "593",
        "rarity_score": 66.91248210235553,
        "rarity_level": "COMMON"
    },
    "594": {
        "name": "Kishu Ken #594",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/594.png",
        "dna": "8f009306f40338c49e3ed31682667b83050066cd",
        "edition": 594,
        "date": 1662952361681,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "594",
        "rarity_score": 93.76747154558025,
        "rarity_level": "RARE"
    },
    "595": {
        "name": "Kishu Ken #595",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/595.png",
        "dna": "0f810437aa42f933e9b511738c5432f0b2aa9342",
        "edition": 595,
        "date": 1662952192200,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "595",
        "rarity_score": 94.22700292265509,
        "rarity_level": "RARE"
    },
    "596": {
        "name": "Kishu Ken #596",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/596.png",
        "dna": "3f27408179ffefee6c3202c37c7754e227d53784",
        "edition": 596,
        "date": 1662952204659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "596",
        "rarity_score": 113.29519104748462,
        "rarity_level": "RARE"
    },
    "597": {
        "name": "Kishu Ken #597",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/597.png",
        "dna": "7e257883bc66849d07c7e7e9cdee959a0994233e",
        "edition": 597,
        "date": 1662952044199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "597",
        "rarity_score": 44.53097957770855,
        "rarity_level": "COMMON"
    },
    "598": {
        "name": "Kishu Ken #598",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/598.png",
        "dna": "fa4a2212af35bbd0bb3c293a502efe6c98fbeb35",
        "edition": 598,
        "date": 1662951999264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rarity": 6,
        "id": "598",
        "rarity_score": 94.2878793346083,
        "rarity_level": "RARE"
    },
    "599": {
        "name": "Kishu Ken #599",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/599.png",
        "dna": "c4b7cd17b8fc892c4d21477f386d2283d2ec6193",
        "edition": 599,
        "date": 1662952069771,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "599",
        "rarity_score": 39.765374812103786,
        "rarity_level": "COMMON"
    },
    "600": {
        "name": "Kishu Ken #600",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/600.png",
        "dna": "7a00b5f4f84d17bcf4d23306370949d4898bad1c",
        "edition": 600,
        "date": 1662952108287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "600",
        "rarity_score": 47.21367740465408,
        "rarity_level": "COMMON"
    },
    "601": {
        "name": "Kishu Ken #601",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/601.png",
        "dna": "c60dcdb9a498f9532b261ee3b3f047ed9f2988d0",
        "edition": 601,
        "date": 1662952365509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.25,
        "id": "601",
        "rarity_score": 37.48720092291948,
        "rarity_level": "COMMON"
    },
    "602": {
        "name": "Kishu Ken #602",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/602.png",
        "dna": "badd5a09416d894e05a6ec647ffd7d2c29c8a724",
        "edition": 602,
        "date": 1662952186957,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "602",
        "rarity_score": 98.77618288144603,
        "rarity_level": "RARE"
    },
    "603": {
        "name": "Kishu Ken #603",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/603.png",
        "dna": "a55a9121f52a92cb21b35144df27776ef1bce9ab",
        "edition": 603,
        "date": 1662952191558,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5,
        "id": "603",
        "rarity_score": 143.51759848083378,
        "rarity_level": "EPIC"
    },
    "604": {
        "name": "Kishu Ken #604",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/604.png",
        "dna": "804c91b27ca5a68cb70cf9e67d7d4484889e5d0a",
        "edition": 604,
        "date": 1662952399075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "604",
        "rarity_score": 59.74959305079301,
        "rarity_level": "COMMON"
    },
    "605": {
        "name": "Kishu Ken #605",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/605.png",
        "dna": "4069d677c529db573599099d7d1cdb54b0377a1e",
        "edition": 605,
        "date": 1662952150126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "605",
        "rarity_score": 65.28705916784546,
        "rarity_level": "COMMON"
    },
    "606": {
        "name": "Kishu Ken #606",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/606.png",
        "dna": "eb9bf3f2647f657ba21f1f66dff51d7fd81837b8",
        "edition": 606,
        "date": 1662952485731,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "606",
        "rarity_score": 85.94193338987132,
        "rarity_level": "COMMON"
    },
    "607": {
        "name": "Kishu Ken #607",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/607.png",
        "dna": "4e67d9e4fa39809e89d74f73e3ea0ed086b71506",
        "edition": 607,
        "date": 1662952268745,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "607",
        "rarity_score": 80.5869155972737,
        "rarity_level": "COMMON"
    },
    "608": {
        "name": "Kishu Ken #608",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/608.png",
        "dna": "971412b72de609c8d881cd94b93b376cd905ebe9",
        "edition": 608,
        "date": 1662951887557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "608",
        "rarity_score": 47.92014221449324,
        "rarity_level": "COMMON"
    },
    "609": {
        "name": "Kishu Ken #609",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/609.png",
        "dna": "3849ee8f7b724ffff932fc8a14b85e0015446442",
        "edition": 609,
        "date": 1662952452318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "609",
        "rarity_score": 88.04921733614432,
        "rarity_level": "COMMON"
    },
    "610": {
        "name": "Kishu Ken #610",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/610.png",
        "dna": "a95c0b56d3f53c7699352221450571e62087bc90",
        "edition": 610,
        "date": 1662951857631,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "610",
        "rarity_score": 47.3461562243876,
        "rarity_level": "COMMON"
    },
    "611": {
        "name": "Kishu Ken #611",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/611.png",
        "dna": "4a56e6a82ba54fb2baccec7bf6d53f55b10ae672",
        "edition": 611,
        "date": 1662951853579,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "611",
        "rarity_score": 49.06298056896589,
        "rarity_level": "COMMON"
    },
    "612": {
        "name": "Kishu Ken #612",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/612.png",
        "dna": "0f12624c62c7ff8fd776e5ba2536bee941124011",
        "edition": 612,
        "date": 1662952285990,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "612",
        "rarity_score": 90.85024154589372,
        "rarity_level": "RARE"
    },
    "613": {
        "name": "Kishu Ken #613",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/613.png",
        "dna": "d69f6e164b52bada3937a27aaa15e40e8d1d0f6b",
        "edition": 613,
        "date": 1662951805407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "613",
        "rarity_score": 45.68427340448663,
        "rarity_level": "COMMON"
    },
    "614": {
        "name": "Kishu Ken #614",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/614.png",
        "dna": "823a8e00846fb827e9e996f4c951fa1479e998c0",
        "edition": 614,
        "date": 1662951998060,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "614",
        "rarity_score": 75.35664054651397,
        "rarity_level": "COMMON"
    },
    "615": {
        "name": "Kishu Ken #615",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/615.png",
        "dna": "eaa65dd6267fb688b9569f7f3d9f384ecdaca5a5",
        "edition": 615,
        "date": 1662951812808,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "615",
        "rarity_score": 80.82004591793748,
        "rarity_level": "COMMON"
    },
    "616": {
        "name": "Kishu Ken #616",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/616.png",
        "dna": "09fee468d8c33b8fce60cf54e5d0adfd8f7f59c2",
        "edition": 616,
        "date": 1662952286713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "616",
        "rarity_score": 118.53996853996853,
        "rarity_level": "RARE"
    },
    "617": {
        "name": "Kishu Ken #617",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/617.png",
        "dna": "40979c12fdf06b072a21842e547dbab51f54e0bb",
        "edition": 617,
        "date": 1662952353097,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "617",
        "rarity_score": 55.53829417137405,
        "rarity_level": "COMMON"
    },
    "618": {
        "name": "Kishu Ken #618",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/618.png",
        "dna": "7bd316c9f47098668984680d66797f41a05528ee",
        "edition": 618,
        "date": 1662951871199,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "618",
        "rarity_score": 39.23358533109213,
        "rarity_level": "COMMON"
    },
    "619": {
        "name": "Kishu Ken #619",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/619.png",
        "dna": "300a1924f9002feedc648dd75823bff871820f3a",
        "edition": 619,
        "date": 1662952199867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "619",
        "rarity_score": 105.4205124591935,
        "rarity_level": "RARE"
    },
    "620": {
        "name": "Kishu Ken #620",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/620.png",
        "dna": "49c6f00f6872aab224e02ccb79738c3e927e97e1",
        "edition": 620,
        "date": 1662951803347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.5,
        "id": "620",
        "rarity_score": 82.43954762942104,
        "rarity_level": "COMMON"
    },
    "621": {
        "name": "Kishu Ken #621",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/621.png",
        "dna": "873a5b834be82b0fe1250260394206729e20ddca",
        "edition": 621,
        "date": 1662952028785,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "621",
        "rarity_score": 29.393987163607704,
        "rarity_level": "COMMON"
    },
    "622": {
        "name": "Kishu Ken #622",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/622.png",
        "dna": "5dcae3e4bbe3a4343a5bbdb4b15bbcd50e050374",
        "edition": 622,
        "date": 1662951866670,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "622",
        "rarity_score": 59.872294919023894,
        "rarity_level": "COMMON"
    },
    "623": {
        "name": "Kishu Ken #623",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/623.png",
        "dna": "9b931809f48e4c4af3eac45c2159c518d52fade0",
        "edition": 623,
        "date": 1662952543839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "623",
        "rarity_score": 57.82251666560177,
        "rarity_level": "COMMON"
    },
    "624": {
        "name": "Kishu Ken #624",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/624.png",
        "dna": "48bc8a13322a3954903f8135326867ce9af0c4d9",
        "edition": 624,
        "date": 1662952336143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.5,
        "id": "624",
        "rarity_score": 104.95962014949356,
        "rarity_level": "RARE"
    },
    "625": {
        "name": "Kishu Ken #625",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/625.png",
        "dna": "473d969a286bcb937c92be699b514442760b23f3",
        "edition": 625,
        "date": 1662951895989,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "625",
        "rarity_score": 53.84493413269912,
        "rarity_level": "COMMON"
    },
    "626": {
        "name": "Kishu Ken #626",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/626.png",
        "dna": "3c1ddf8090b697e730850621a9513c581b179f43",
        "edition": 626,
        "date": 1662952459571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "626",
        "rarity_score": 83.2948332948333,
        "rarity_level": "COMMON"
    },
    "627": {
        "name": "Kishu Ken #627",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/627.png",
        "dna": "bbe80b13cd0b8dba39d9fe35776143c82edbc366",
        "edition": 627,
        "date": 1662951863312,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.5,
        "id": "627",
        "rarity_score": 42.822558592179135,
        "rarity_level": "COMMON"
    },
    "628": {
        "name": "Kishu Ken #628",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/628.png",
        "dna": "b7a3fbb5aa1ad9b4b66136ba8d8ba7b230956b60",
        "edition": 628,
        "date": 1662952097769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "628",
        "rarity_score": 78.77780693314673,
        "rarity_level": "COMMON"
    },
    "629": {
        "name": "Kishu Ken #629",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/629.png",
        "dna": "6fb111add34ce978f1c622c53d1438e2d749634f",
        "edition": 629,
        "date": 1662951956064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "629",
        "rarity_score": 43.15332182267285,
        "rarity_level": "COMMON"
    },
    "630": {
        "name": "Kishu Ken #630",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/630.png",
        "dna": "01db68c4d77d35037007cd221b9162a5c82fb380",
        "edition": 630,
        "date": 1662952288233,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "630",
        "rarity_score": 51.43886613656409,
        "rarity_level": "COMMON"
    },
    "631": {
        "name": "Kishu Ken #631",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/631.png",
        "dna": "c8addc5c838f5d1f924263b46840d30b3cbcee45",
        "edition": 631,
        "date": 1662952213704,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "631",
        "rarity_score": 85.12786347563161,
        "rarity_level": "COMMON"
    },
    "632": {
        "name": "Kishu Ken #632",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/632.png",
        "dna": "d7c499d0987407f892d729840eb36218fd30a768",
        "edition": 632,
        "date": 1662951982805,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 15.333333333333334,
        "id": "632",
        "rarity_score": 28.120914415265446,
        "rarity_level": "COMMON"
    },
    "633": {
        "name": "Kishu Ken #633",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/633.png",
        "dna": "2f186c42e737d914c83c382d9e40bb7034a717df",
        "edition": 633,
        "date": 1662952089080,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.333333333333334,
        "id": "633",
        "rarity_score": 32.98062726499933,
        "rarity_level": "COMMON"
    },
    "634": {
        "name": "Kishu Ken #634",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/634.png",
        "dna": "f563e9c8aca3a67838c926df50eabf2e709f6cd0",
        "edition": 634,
        "date": 1662952415673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "634",
        "rarity_score": 75.78671789198106,
        "rarity_level": "COMMON"
    },
    "635": {
        "name": "Kishu Ken #635",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/635.png",
        "dna": "cee82e3e9bbb03353344895b0dc1dbdf80ff54b2",
        "edition": 635,
        "date": 1662952389225,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "635",
        "rarity_score": 97.31419457735247,
        "rarity_level": "RARE"
    },
    "636": {
        "name": "Kishu Ken #636",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/636.png",
        "dna": "772a74a81f2b2360cf2d2a026c800e7c5d554cc9",
        "edition": 636,
        "date": 1662952007198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "636",
        "rarity_score": 33.634427928778955,
        "rarity_level": "COMMON"
    },
    "637": {
        "name": "Kishu Ken #637",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/637.png",
        "dna": "c7cfaf929554f19fd7815e7651ab52d3b627ed91",
        "edition": 637,
        "date": 1662952506137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "637",
        "rarity_score": 89.41273779983457,
        "rarity_level": "COMMON"
    },
    "638": {
        "name": "Kishu Ken #638",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/638.png",
        "dna": "ed83d21e0ab72178255e3a48cd6585e76cece511",
        "edition": 638,
        "date": 1662951850337,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "638",
        "rarity_score": 106.46198461732442,
        "rarity_level": "RARE"
    },
    "639": {
        "name": "Kishu Ken #639",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/639.png",
        "dna": "bcdd9f3c1a9b2bdce3e99e4309b3088d8b6f9abe",
        "edition": 639,
        "date": 1662951808557,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "639",
        "rarity_score": 59.441773190333336,
        "rarity_level": "COMMON"
    },
    "640": {
        "name": "Kishu Ken #640",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/640.png",
        "dna": "4ba2a418f716c65dc960ae4753236cd3de56f9a1",
        "edition": 640,
        "date": 1662952012746,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "640",
        "rarity_score": 32.3987283114933,
        "rarity_level": "COMMON"
    },
    "641": {
        "name": "Kishu Ken #641",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/641.png",
        "dna": "3b784e5f3bc6c5ccb1d590dd29e74213b6f7c8e4",
        "edition": 641,
        "date": 1662951944632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "641",
        "rarity_score": 88.33335226680077,
        "rarity_level": "COMMON"
    },
    "642": {
        "name": "Kishu Ken #642",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/642.png",
        "dna": "8cd25b87f94e9ba993218ff5d7fdebfa9f5b5291",
        "edition": 642,
        "date": 1662952019536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.333333333333334,
        "id": "642",
        "rarity_score": 51.00002915536896,
        "rarity_level": "COMMON"
    },
    "643": {
        "name": "Kishu Ken #643",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/643.png",
        "dna": "7875bebac1283a61bd4f1202d285b5fb067b26d7",
        "edition": 643,
        "date": 1662951842385,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16.25,
        "id": "643",
        "rarity_score": 33.125650029150194,
        "rarity_level": "COMMON"
    },
    "644": {
        "name": "Kishu Ken #644",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/644.png",
        "dna": "740add18abef1a87c076b92609327858e00abc73",
        "edition": 644,
        "date": 1662952535851,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "644",
        "rarity_score": 80.27538543328018,
        "rarity_level": "COMMON"
    },
    "645": {
        "name": "Kishu Ken #645",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/645.png",
        "dna": "152ec93fd736d2da1f99080866a12dbe8e21c5f3",
        "edition": 645,
        "date": 1662952012097,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "645",
        "rarity_score": 45.44211063198405,
        "rarity_level": "COMMON"
    },
    "646": {
        "name": "Kishu Ken #646",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/646.png",
        "dna": "054b9ed6eabab3ec3dfa7f1dcceed202a4895754",
        "edition": 646,
        "date": 1662951935839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "646",
        "rarity_score": 80.39945066005362,
        "rarity_level": "COMMON"
    },
    "647": {
        "name": "Kishu Ken #647",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/647.png",
        "dna": "f6055e4c32d6c7b97510816f88401d2444f8acd1",
        "edition": 647,
        "date": 1662951956869,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "647",
        "rarity_score": 40.90049064491757,
        "rarity_level": "COMMON"
    },
    "648": {
        "name": "Kishu Ken #648",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/648.png",
        "dna": "5c03c8c065a80fd5674a7f1eead0268ddf111204",
        "edition": 648,
        "date": 1662952431151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "648",
        "rarity_score": 52.69128837471336,
        "rarity_level": "COMMON"
    },
    "649": {
        "name": "Kishu Ken #649",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/649.png",
        "dna": "21518e99256953dbb573778df4d7a4e696d8c44a",
        "edition": 649,
        "date": 1662952033830,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "649",
        "rarity_score": 42.98682003354901,
        "rarity_level": "COMMON"
    },
    "650": {
        "name": "Kishu Ken #650",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/650.png",
        "dna": "0eee7744d6a7cdd0e5764cfcc8796b43f32a2ce1",
        "edition": 650,
        "date": 1662951923841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "650",
        "rarity_score": 45.445589739940765,
        "rarity_level": "COMMON"
    },
    "651": {
        "name": "Kishu Ken #651",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/651.png",
        "dna": "4d0b0a3ab9dd5ae6e6a537ac1c39716241db7d84",
        "edition": 651,
        "date": 1662952244120,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "651",
        "rarity_score": 95.38864900610729,
        "rarity_level": "RARE"
    },
    "652": {
        "name": "Kishu Ken #652",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/652.png",
        "dna": "077de1a2d2238d2d042035fe7cb629dfff47a601",
        "edition": 652,
        "date": 1662952351444,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "652",
        "rarity_score": 96.37510341378444,
        "rarity_level": "RARE"
    },
    "653": {
        "name": "Kishu Ken #653",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/653.png",
        "dna": "ca0141005e3b43f5228607f01b31e6cd89d1871e",
        "edition": 653,
        "date": 1662952440894,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "653",
        "rarity_score": 80.98451256345993,
        "rarity_level": "COMMON"
    },
    "654": {
        "name": "Kishu Ken #654",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/654.png",
        "dna": "3141336b1243a1e5c37663b326fef57b2536b00f",
        "edition": 654,
        "date": 1662952184399,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "654",
        "rarity_score": 92.91295938104449,
        "rarity_level": "RARE"
    },
    "655": {
        "name": "Kishu Ken #655",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/655.png",
        "dna": "5d4d7ec496b19268e9bd1878894e549aba7962b0",
        "edition": 655,
        "date": 1662952194530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Cyperpunk Glasses",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "655",
        "rarity_score": 78.708559431451,
        "rarity_level": "COMMON"
    },
    "656": {
        "name": "Kishu Ken #656",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/656.png",
        "dna": "f8672c230e643c7b8872e7d743d9fa029f9c54e9",
        "edition": 656,
        "date": 1662952516886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.25,
        "id": "656",
        "rarity_score": 48.23849491533385,
        "rarity_level": "COMMON"
    },
    "657": {
        "name": "Kishu Ken #657",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/657.png",
        "dna": "ac8621fc71dc9cb95420512901a635580df83b26",
        "edition": 657,
        "date": 1662952261990,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Bone",
                "score": 52.63157894736842
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "657",
        "rarity_score": 93.3389919192701,
        "rarity_level": "RARE"
    },
    "658": {
        "name": "Kishu Ken #658",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/658.png",
        "dna": "875b453d94e17f840bd0e69e13fdf680ba068cd6",
        "edition": 658,
        "date": 1662951893055,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "658",
        "rarity_score": 64.79256557565932,
        "rarity_level": "COMMON"
    },
    "659": {
        "name": "Kishu Ken #659",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/659.png",
        "dna": "48513e032086168b6953ea3e8ef681a04a4bd40e",
        "edition": 659,
        "date": 1662952373952,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "659",
        "rarity_score": 70.23166891587945,
        "rarity_level": "COMMON"
    },
    "660": {
        "name": "Kishu Ken #660",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/660.png",
        "dna": "3db4a5315a3d9b5fd315db56433868b084ed208a",
        "edition": 660,
        "date": 1662952124351,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "660",
        "rarity_score": 51.202731925623496,
        "rarity_level": "COMMON"
    },
    "661": {
        "name": "Kishu Ken #661",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/661.png",
        "dna": "606f3f0735f77bc126faefd72341ab5657325761",
        "edition": 661,
        "date": 1662952099280,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "661",
        "rarity_score": 47.82597054886212,
        "rarity_level": "COMMON"
    },
    "662": {
        "name": "Kishu Ken #662",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/662.png",
        "dna": "4ab60faabb2ff22fb7a06ea2c9afcf29c28e4370",
        "edition": 662,
        "date": 1662952209639,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "662",
        "rarity_score": 105.94562866852024,
        "rarity_level": "RARE"
    },
    "663": {
        "name": "Kishu Ken #663",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/663.png",
        "dna": "ae11a4e0dbc59360eec286c11040144702db2f6e",
        "edition": 663,
        "date": 1662951969188,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "663",
        "rarity_score": 40.48873212354954,
        "rarity_level": "COMMON"
    },
    "664": {
        "name": "Kishu Ken #664",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/664.png",
        "dna": "251c5545d9e279abf732fef3d2a017692156ff27",
        "edition": 664,
        "date": 1662952071634,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "664",
        "rarity_score": 63.16328331862312,
        "rarity_level": "COMMON"
    },
    "665": {
        "name": "Kishu Ken #665",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/665.png",
        "dna": "d8ce294c432656491bf9d6e61184d1ec22040efe",
        "edition": 665,
        "date": 1662951833424,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "665",
        "rarity_score": 49.259427928778955,
        "rarity_level": "COMMON"
    },
    "666": {
        "name": "Kishu Ken #666",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/666.png",
        "dna": "00011e2388f3b273f6e5d252a4bc9d224f637224",
        "edition": 666,
        "date": 1662952322901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "666",
        "rarity_score": 77.56410256410257,
        "rarity_level": "COMMON"
    },
    "667": {
        "name": "Kishu Ken #667",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/667.png",
        "dna": "49d575c6f6a0133e9ef2e7c6eb74f9de1dc23828",
        "edition": 667,
        "date": 1662952482144,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "667",
        "rarity_score": 60.10521182746443,
        "rarity_level": "COMMON"
    },
    "668": {
        "name": "Kishu Ken #668",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/668.png",
        "dna": "cbc77d1eccd6aa90d4374773a00fc7a29be2e08e",
        "edition": 668,
        "date": 1662952441602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.5,
        "id": "668",
        "rarity_score": 50.64509565976158,
        "rarity_level": "COMMON"
    },
    "669": {
        "name": "Kishu Ken #669",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/669.png",
        "dna": "8ae99bed220ae29222eb6f0350e007f0c5934ff7",
        "edition": 669,
        "date": 1662951890069,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "669",
        "rarity_score": 57.27253431926329,
        "rarity_level": "COMMON"
    },
    "670": {
        "name": "Kishu Ken #670",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/670.png",
        "dna": "744fd4e4f9bd9138784e963edd234dbea176e4b1",
        "edition": 670,
        "date": 1662952357835,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "670",
        "rarity_score": 55.38363518376497,
        "rarity_level": "COMMON"
    },
    "671": {
        "name": "Kishu Ken #671",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/671.png",
        "dna": "6fef02d1712e571f70adcdbeb329678ffe7bb91e",
        "edition": 671,
        "date": 1662952123688,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "671",
        "rarity_score": 29.509822855036077,
        "rarity_level": "COMMON"
    },
    "672": {
        "name": "Kishu Ken #672",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/672.png",
        "dna": "d9aafed7b59dc0ee04307248c2688e864c53187e",
        "edition": 672,
        "date": 1662951823696,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "672",
        "rarity_score": 116.3593788897187,
        "rarity_level": "RARE"
    },
    "673": {
        "name": "Kishu Ken #673",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/673.png",
        "dna": "42c1b61e2a06644be37e2b74b3c50f37493c44a9",
        "edition": 673,
        "date": 1662951800468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.25,
        "id": "673",
        "rarity_score": 55.11124845646168,
        "rarity_level": "COMMON"
    },
    "674": {
        "name": "Kishu Ken #674",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/674.png",
        "dna": "64620baddffae5e1372e229ef49e1d550874447b",
        "edition": 674,
        "date": 1662952505411,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.5,
        "id": "674",
        "rarity_score": 179.13851351351352,
        "rarity_level": "LEGENDARY"
    },
    "675": {
        "name": "Kishu Ken #675",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/675.png",
        "dna": "ab78717393d5a2a2531dc1ad9656d1b6960d3224",
        "edition": 675,
        "date": 1662951818126,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "675",
        "rarity_score": 38.56829909021321,
        "rarity_level": "COMMON"
    },
    "676": {
        "name": "Kishu Ken #676",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/676.png",
        "dna": "624c4bdc821e61acd3e4242c807cf9b58b6f5a55",
        "edition": 676,
        "date": 1662952122392,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Police",
                "score": 71.42857142857143
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "676",
        "rarity_score": 87.91722296395194,
        "rarity_level": "COMMON"
    },
    "677": {
        "name": "Kishu Ken #677",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/677.png",
        "dna": "8158a12fb3ee614523cdb97a3127d76ae34280c2",
        "edition": 677,
        "date": 1662952098410,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.666666666666666,
        "id": "677",
        "rarity_score": 41.62713128508981,
        "rarity_level": "COMMON"
    },
    "678": {
        "name": "Kishu Ken #678",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/678.png",
        "dna": "bf0f7944726237fdd74de7bde87f3815b9407977",
        "edition": 678,
        "date": 1662951934438,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "678",
        "rarity_score": 43.353242613060026,
        "rarity_level": "COMMON"
    },
    "679": {
        "name": "Kishu Ken #679",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/679.png",
        "dna": "5129e81b49543add389c2a5fbe3413465c58afa2",
        "edition": 679,
        "date": 1662952103296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.333333333333334,
        "id": "679",
        "rarity_score": 44.13590365071773,
        "rarity_level": "COMMON"
    },
    "680": {
        "name": "Kishu Ken #680",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/680.png",
        "dna": "539ba0b99e48124fe8fef4478edbfea125842eee",
        "edition": 680,
        "date": 1662951933478,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "680",
        "rarity_score": 73.78727457036833,
        "rarity_level": "COMMON"
    },
    "681": {
        "name": "Kishu Ken #681",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/681.png",
        "dna": "23f6580703803f622fb042377b61cc12d81df955",
        "edition": 681,
        "date": 1662952130866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Common Thief",
        "rarity": 11.333333333333334,
        "id": "681",
        "rarity_score": 54.4380825934224,
        "rarity_level": "COMMON"
    },
    "682": {
        "name": "Kishu Ken #682",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/682.png",
        "dna": "bc3cf1e8d3eb52e0e8da2a58bc673be3c9b0143b",
        "edition": 682,
        "date": 1662952423430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "682",
        "rarity_score": 42.95422205869967,
        "rarity_level": "COMMON"
    },
    "683": {
        "name": "Kishu Ken #683",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/683.png",
        "dna": "450ce4011bd6c8aeecbb866279ba31d23cb8de37",
        "edition": 683,
        "date": 1662952236147,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.25,
        "id": "683",
        "rarity_score": 93.26599326599327,
        "rarity_level": "RARE"
    },
    "684": {
        "name": "Kishu Ken #684",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/684.png",
        "dna": "0d3a008dd0aee06dee3af63ccf2a1af1f5fb31d9",
        "edition": 684,
        "date": 1662952384694,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "684",
        "rarity_score": 50.2484230765778,
        "rarity_level": "COMMON"
    },
    "685": {
        "name": "Kishu Ken #685",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/685.png",
        "dna": "82d5b0a69bca4552e7536252f48c340cfd57ede7",
        "edition": 685,
        "date": 1662951848991,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "685",
        "rarity_score": 73.81990729509243,
        "rarity_level": "COMMON"
    },
    "686": {
        "name": "Kishu Ken #686",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/686.png",
        "dna": "19c112ee2e5effc85c14e3a7e2d93b7c07ed6382",
        "edition": 686,
        "date": 1662952530637,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "University Hat",
                "score": 25.641025641025642
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "686",
        "rarity_score": 86.00138600138601,
        "rarity_level": "COMMON"
    },
    "687": {
        "name": "Kishu Ken #687",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/687.png",
        "dna": "6bbe72d51119bb3bc65a28c0682364ff2ba6bc9d",
        "edition": 687,
        "date": 1662952064551,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "687",
        "rarity_score": 34.90750067712122,
        "rarity_level": "COMMON"
    },
    "688": {
        "name": "Kishu Ken #688",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/688.png",
        "dna": "e11eed2d0a5e66dea00b082f98f9a4dffb56f5c9",
        "edition": 688,
        "date": 1662951992608,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 14,
        "id": "688",
        "rarity_score": 29.756930635162007,
        "rarity_level": "COMMON"
    },
    "689": {
        "name": "Kishu Ken #689",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/689.png",
        "dna": "6cf160535a7fe21fb7d722be22403a5f7047dc80",
        "edition": 689,
        "date": 1662952112862,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "689",
        "rarity_score": 33.24517752954959,
        "rarity_level": "COMMON"
    },
    "690": {
        "name": "Kishu Ken #690",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/690.png",
        "dna": "80d1d1f97e31cc8ea5a6a52d0ccae43f62e5e55a",
        "edition": 690,
        "date": 1662952234703,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "690",
        "rarity_score": 63.96807992202729,
        "rarity_level": "COMMON"
    },
    "691": {
        "name": "Kishu Ken #691",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/691.png",
        "dna": "84ac6ac442f7c215ab93551b9b6e54248835edcf",
        "edition": 691,
        "date": 1662952273845,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "691",
        "rarity_score": 61.135664583610335,
        "rarity_level": "COMMON"
    },
    "692": {
        "name": "Kishu Ken #692",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/692.png",
        "dna": "747ae7075ae520b7c23036b80eb1dced439c8a41",
        "edition": 692,
        "date": 1662952135853,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "692",
        "rarity_score": 68.31385336058233,
        "rarity_level": "COMMON"
    },
    "693": {
        "name": "Kishu Ken #693",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/693.png",
        "dna": "dd98b4e0d0677ad66d5c35c371f2debfde67e218",
        "edition": 693,
        "date": 1662952196833,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "693",
        "rarity_score": 62.497291181654006,
        "rarity_level": "COMMON"
    },
    "694": {
        "name": "Kishu Ken #694",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/694.png",
        "dna": "344f77c682a1bcf6384737cc288b22e9b3ad2d75",
        "edition": 694,
        "date": 1662952085093,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "694",
        "rarity_score": 40.07306711979609,
        "rarity_level": "COMMON"
    },
    "695": {
        "name": "Kishu Ken #695",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/695.png",
        "dna": "3e37c0a0d8b770fb9481976c71141ae1b5d33489",
        "edition": 695,
        "date": 1662952020115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "695",
        "rarity_score": 30.32199447320106,
        "rarity_level": "COMMON"
    },
    "696": {
        "name": "Kishu Ken #696",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/696.png",
        "dna": "9a9c5ffcf560a61f2ac1b9e2df4ad0290a2c5474",
        "edition": 696,
        "date": 1662952027906,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "696",
        "rarity_score": 41.42834598727008,
        "rarity_level": "COMMON"
    },
    "697": {
        "name": "Kishu Ken #697",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/697.png",
        "dna": "89e5d032143c6a1c6795922cedf60b3da7b86ba8",
        "edition": 697,
        "date": 1662952409099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "697",
        "rarity_score": 109.36916433239963,
        "rarity_level": "RARE"
    },
    "698": {
        "name": "Kishu Ken #698",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/698.png",
        "dna": "b7163697394e25f0e3a9ad335ac992759a6e7dde",
        "edition": 698,
        "date": 1662952107733,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "698",
        "rarity_score": 42.49262064796045,
        "rarity_level": "COMMON"
    },
    "699": {
        "name": "Kishu Ken #699",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/699.png",
        "dna": "24f986ccf6aa29f0b2608f03ab5bdf51c0fea4ac",
        "edition": 699,
        "date": 1662952453779,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "699",
        "rarity_score": 46.850325954803566,
        "rarity_level": "COMMON"
    },
    "700": {
        "name": "Kishu Ken #700",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/700.png",
        "dna": "b7f3a57992a47ea14fde3ae28557edf45d9e264b",
        "edition": 700,
        "date": 1662951856108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Worker",
                "score": 12.048192771084338
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "700",
        "rarity_score": 46.98321275283329,
        "rarity_level": "COMMON"
    },
    "701": {
        "name": "Kishu Ken #701",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/701.png",
        "dna": "54c13376474b0401f64fc12413b32cc3da24a0b7",
        "edition": 701,
        "date": 1662952305811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "701",
        "rarity_score": 79.85290995832543,
        "rarity_level": "COMMON"
    },
    "702": {
        "name": "Kishu Ken #702",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/702.png",
        "dna": "330cb093fa14a2ef5a91cdb5ba2429f3e3fc8e0c",
        "edition": 702,
        "date": 1662951879442,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "702",
        "rarity_score": 59.30383635056533,
        "rarity_level": "COMMON"
    },
    "703": {
        "name": "Kishu Ken #703",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/703.png",
        "dna": "bb7718b66651dbc31a55027ff9403c3dcedaa056",
        "edition": 703,
        "date": 1662952062117,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.666666666666666,
        "id": "703",
        "rarity_score": 60.6346637900036,
        "rarity_level": "COMMON"
    },
    "704": {
        "name": "Kishu Ken #704",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/704.png",
        "dna": "cfcbb0f75d0c19fcc09054c405e742c58a620f2d",
        "edition": 704,
        "date": 1662952185251,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes",
                "score": 16.949152542372882
            },
            { "trait_type": "Hat", "value": "Halo", "score": 37.03703703703704 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.5,
        "id": "704",
        "rarity_score": 74.83639200855971,
        "rarity_level": "COMMON"
    },
    "705": {
        "name": "Kishu Ken #705",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/705.png",
        "dna": "a80d92a874472dfce3480b1ecf32b824e09a6cd5",
        "edition": 705,
        "date": 1662952096269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "705",
        "rarity_score": 29.696329932932322,
        "rarity_level": "COMMON"
    },
    "706": {
        "name": "Kishu Ken #706",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/706.png",
        "dna": "d65075ec99f066d207da6b37b531718bffa092c3",
        "edition": 706,
        "date": 1662952225988,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $KDA",
                "score": 45.45454545454545
            },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 4.5,
        "id": "706",
        "rarity_score": 148.2323232323232,
        "rarity_level": "EPIC"
    },
    "707": {
        "name": "Kishu Ken #707",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/707.png",
        "dna": "db9dd1142e4dfd14b68a96ed00124e95536fbe77",
        "edition": 707,
        "date": 1662952402998,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "707",
        "rarity_score": 60.57917646470206,
        "rarity_level": "COMMON"
    },
    "708": {
        "name": "Kishu Ken #708",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/708.png",
        "dna": "4c2206452496a9a058284871fa527da178f8e509",
        "edition": 708,
        "date": 1662952349255,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Mask",
                "score": 66.66666666666667
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Chinese Hat",
                "score": 27.77777777777778
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.75,
        "id": "708",
        "rarity_score": 118.06944444444446,
        "rarity_level": "RARE"
    },
    "709": {
        "name": "Kishu Ken #709",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/709.png",
        "dna": "14dd00ae8a7be8c00d3aafde1465775deae9b213",
        "edition": 709,
        "date": 1662951980654,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7.333333333333334,
        "id": "709",
        "rarity_score": 103.68386887374228,
        "rarity_level": "RARE"
    },
    "710": {
        "name": "Kishu Ken #710",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/710.png",
        "dna": "ea2a8f5c741bf2776395b622ed8c0f68cb0b02f6",
        "edition": 710,
        "date": 1662952088426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Sad",
                "score": 12.048192771084338
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 7,
        "id": "710",
        "rarity_score": 81.11726184015342,
        "rarity_level": "COMMON"
    },
    "711": {
        "name": "Kishu Ken #711",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/711.png",
        "dna": "67d8160bd17278fecb89505600bb1e4ef66eabf9",
        "edition": 711,
        "date": 1662951798723,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "711",
        "rarity_score": 48.54878389399711,
        "rarity_level": "COMMON"
    },
    "712": {
        "name": "Kishu Ken #712",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/712.png",
        "dna": "aa6de397dc20b3217133096bc7c153a8fdd41c8d",
        "edition": 712,
        "date": 1662951919024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "712",
        "rarity_score": 39.57633810254469,
        "rarity_level": "COMMON"
    },
    "713": {
        "name": "Kishu Ken #713",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/713.png",
        "dna": "9df42eb701e7f6cd5540dd667810906497b23eae",
        "edition": 713,
        "date": 1662952414948,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "713",
        "rarity_score": 55.4717082240018,
        "rarity_level": "COMMON"
    },
    "714": {
        "name": "Kishu Ken #714",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/714.png",
        "dna": "895bd5ffe20a38ebf0de89bea324c52044f6bf7a",
        "edition": 714,
        "date": 1662952475115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "714",
        "rarity_score": 121.72728682771724,
        "rarity_level": "EPIC"
    },
    "715": {
        "name": "Kishu Ken #715",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/715.png",
        "dna": "d8dba9ec25569569245d4ca76310186de2692362",
        "edition": 715,
        "date": 1662952540037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9,
        "id": "715",
        "rarity_score": 82.28536666036666,
        "rarity_level": "COMMON"
    },
    "716": {
        "name": "Kishu Ken #716",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/716.png",
        "dna": "f54d1a14da992be9a6b50338fc64d77a5de1ff4f",
        "edition": 716,
        "date": 1662952327173,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.75,
        "id": "716",
        "rarity_score": 126.2539713102917,
        "rarity_level": "EPIC"
    },
    "717": {
        "name": "Kishu Ken #717",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/717.png",
        "dna": "a9ef5fea5d6663aabb5d5162efa66299216d6daa",
        "edition": 717,
        "date": 1662952383030,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.5,
        "id": "717",
        "rarity_score": 79.71645407978176,
        "rarity_level": "COMMON"
    },
    "718": {
        "name": "Kishu Ken #718",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/718.png",
        "dna": "5af52cd2e16c45844f4b80adba6161407372261f",
        "edition": 718,
        "date": 1662952269450,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.75,
        "id": "718",
        "rarity_score": 60.78357223151799,
        "rarity_level": "COMMON"
    },
    "719": {
        "name": "Kishu Ken #719",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/719.png",
        "dna": "38fb0a4cec76ae0ece887d29a8356dd9541f0f78",
        "edition": 719,
        "date": 1662952346910,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "719",
        "rarity_score": 61.94609974622953,
        "rarity_level": "COMMON"
    },
    "720": {
        "name": "Kishu Ken #720",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/720.png",
        "dna": "bb5ab80a0e2d13bf129ce52bd9ba7412dbe7e0de",
        "edition": 720,
        "date": 1662952113536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "720",
        "rarity_score": 65.52018071005412,
        "rarity_level": "COMMON"
    },
    "721": {
        "name": "Kishu Ken #721",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/721.png",
        "dna": "dfae549c5d0f51e1186be44a7f844a1a1d096713",
        "edition": 721,
        "date": 1662952520024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Swimming Goggles",
                "score": 14.285714285714286
            },
            {
                "trait_type": "Hat",
                "value": "KDA Cap",
                "score": 21.27659574468085
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "721",
        "rarity_score": 56.48323095131606,
        "rarity_level": "COMMON"
    },
    "722": {
        "name": "Kishu Ken #722",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/722.png",
        "dna": "0c9b5152b7f91ebd69522d3ae76f5d017cf16405",
        "edition": 722,
        "date": 1662952017121,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.666666666666666,
        "id": "722",
        "rarity_score": 30.697369848576432,
        "rarity_level": "COMMON"
    },
    "723": {
        "name": "Kishu Ken #723",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/723.png",
        "dna": "41f94450af9901315fcffcd8d287669e91d02a3f",
        "edition": 723,
        "date": 1662951860317,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 13.25,
        "id": "723",
        "rarity_score": 42.490767622808036,
        "rarity_level": "COMMON"
    },
    "724": {
        "name": "Kishu Ken #724",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/724.png",
        "dna": "a207aad885fc7932f341b6aee598dcebeb002e21",
        "edition": 724,
        "date": 1662952015134,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            { "trait_type": "Hat", "value": "Normal", "score": 125.0 }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "724",
        "rarity_score": 141.85159500693482,
        "rarity_level": "EPIC"
    },
    "725": {
        "name": "Kishu Ken #725",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/725.png",
        "dna": "6350687d6be068c350e19b5507286207b62409aa",
        "edition": 725,
        "date": 1662951886890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "725",
        "rarity_score": 48.60417296925355,
        "rarity_level": "COMMON"
    },
    "726": {
        "name": "Kishu Ken #726",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/726.png",
        "dna": "e6fd5e110d41cfd048aa3cca87c917f7882ca19c",
        "edition": 726,
        "date": 1662952381034,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "726",
        "rarity_score": 143.000693000693,
        "rarity_level": "EPIC"
    },
    "727": {
        "name": "Kishu Ken #727",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/727.png",
        "dna": "8b44ef8893549cb98c3fcf0ee14b5dfcc954399e",
        "edition": 727,
        "date": 1662951981926,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 16.333333333333332,
        "id": "727",
        "rarity_score": 24.863732123549543,
        "rarity_level": "COMMON"
    },
    "728": {
        "name": "Kishu Ken #728",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/728.png",
        "dna": "d0356c8e0fe645c22eebf7d67a6dffdc16a51d53",
        "edition": 728,
        "date": 1662952077563,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "728",
        "rarity_score": 40.436010591350396,
        "rarity_level": "COMMON"
    },
    "729": {
        "name": "Kishu Ken #729",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/729.png",
        "dna": "7fc9808f4a8956313192a738e67faa9d030fb3df",
        "edition": 729,
        "date": 1662951843982,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 14.25,
        "id": "729",
        "rarity_score": 42.715284080364654,
        "rarity_level": "COMMON"
    },
    "730": {
        "name": "Kishu Ken #730",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/730.png",
        "dna": "d28fb05bd20528ae321cd43a1526dcd43507eaee",
        "edition": 730,
        "date": 1662952147145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.333333333333334,
        "id": "730",
        "rarity_score": 39.21592426265323,
        "rarity_level": "COMMON"
    },
    "731": {
        "name": "Kishu Ken #731",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/731.png",
        "dna": "e43c8fa79651d3baeb062e4ac5d786ca37ba643a",
        "edition": 731,
        "date": 1662951806108,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 11,
        "id": "731",
        "rarity_score": 57.39774244447142,
        "rarity_level": "COMMON"
    },
    "732": {
        "name": "Kishu Ken #732",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/732.png",
        "dna": "d83f1d68195b8e407b5bdecdcd83f1962e498a1d",
        "edition": 732,
        "date": 1662951852762,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Military",
                "score": 83.33333333333333
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8,
        "id": "732",
        "rarity_score": 118.36674652208632,
        "rarity_level": "RARE"
    },
    "733": {
        "name": "Kishu Ken #733",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/733.png",
        "dna": "d5b64c2e2e173ba2edb78102eca51602ec4a8263",
        "edition": 733,
        "date": 1662952214684,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "733",
        "rarity_score": 56.14832924839098,
        "rarity_level": "COMMON"
    },
    "734": {
        "name": "Kishu Ken #734",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/734.png",
        "dna": "7e022f84e3f9fc5499418d2103092ac18bd1e065",
        "edition": 734,
        "date": 1662952216040,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 7.75,
        "id": "734",
        "rarity_score": 71.91857849752586,
        "rarity_level": "COMMON"
    },
    "735": {
        "name": "Kishu Ken #735",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/735.png",
        "dna": "093529d48b046c3d74d83f19a4ad6dced59e1f39",
        "edition": 735,
        "date": 1662952060329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "735",
        "rarity_score": 42.757170912510716,
        "rarity_level": "COMMON"
    },
    "736": {
        "name": "Kishu Ken #736",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/736.png",
        "dna": "78c315b42717dda1b903b5b1b187d7086bc2d831",
        "edition": 736,
        "date": 1662952402021,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.75,
        "id": "736",
        "rarity_score": 54.48547674996545,
        "rarity_level": "COMMON"
    },
    "737": {
        "name": "Kishu Ken #737",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/737.png",
        "dna": "8d9cabd118ce41d9e89e7f045481af03611c7f81",
        "edition": 737,
        "date": 1662952474480,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Mouth",
                "value": "Rugged",
                "score": 32.25806451612903
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.5,
        "id": "737",
        "rarity_score": 64.95691842472371,
        "rarity_level": "COMMON"
    },
    "738": {
        "name": "Kishu Ken #738",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/738.png",
        "dna": "1d1d429e44b1df2add92cdda139f01918134b96c",
        "edition": 738,
        "date": 1662952067543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Happy",
                "score": 9.345794392523365
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 12,
        "id": "738",
        "rarity_score": 42.12967717640615,
        "rarity_level": "COMMON"
    },
    "739": {
        "name": "Kishu Ken #739",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/739.png",
        "dna": "6b6066516fff7f82f2054cbd5a0cf9e29f7a883e",
        "edition": 739,
        "date": 1662951827958,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.25,
        "id": "739",
        "rarity_score": 49.915707175524595,
        "rarity_level": "COMMON"
    },
    "740": {
        "name": "Kishu Ken #740",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/740.png",
        "dna": "dbc3a49a463c4555725363b52a9b02e05227f872",
        "edition": 740,
        "date": 1662952503728,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Hodling $ETC",
                "score": 13.157894736842104
            },
            {
                "trait_type": "Eyes",
                "value": "Cyclops",
                "score": 19.23076923076923
            },
            {
                "trait_type": "Hat",
                "value": "Pirate",
                "score": 22.22222222222222
            }
        ],
        "rank": "Common Thief",
        "rarity": 10.5,
        "id": "740",
        "rarity_score": 62.61088618983356,
        "rarity_level": "COMMON"
    },
    "741": {
        "name": "Kishu Ken #741",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/741.png",
        "dna": "7e5fa03ef9cb2de2858011f5732a06e2dd4762d3",
        "edition": 741,
        "date": 1662952458186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            {
                "trait_type": "Mouth",
                "value": "Normal Mouth",
                "score": 21.73913043478261
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Band",
                "score": 38.46153846153846
            },
            {
                "trait_type": "Hat",
                "value": "Shelby",
                "score": 16.129032258064516
            }
        ],
        "rank": "Epic Scammer",
        "rarity": 6.25,
        "id": "741",
        "rarity_score": 90.21859004327447,
        "rarity_level": "COMMON"
    },
    "742": {
        "name": "Kishu Ken #742",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/742.png",
        "dna": "794e21e1ddb19ba3c62f584f14e91d26335595c8",
        "edition": 742,
        "date": 1662951880162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Dark Purple",
                "score": 7.142857142857143
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Monocle",
                "score": 58.8235294117647
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 10,
        "id": "742",
        "rarity_score": 91.28284225082437,
        "rarity_level": "RARE"
    },
    "743": {
        "name": "Kishu Ken #743",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/743.png",
        "dna": "7c8b868bfe4c2be39653f7d2c56c775bf0b912a3",
        "edition": 743,
        "date": 1662952238070,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Yellow",
                "score": 13.88888888888889
            },
            { "trait_type": "Mouth", "value": "Mustache", "score": 25.0 },
            {
                "trait_type": "Eyes",
                "value": "Sun Glasses",
                "score": 33.333333333333336
            },
            {
                "trait_type": "Hat",
                "value": "Cowboy",
                "score": 24.390243902439025
            }
        ],
        "rarity": 6,
        "id": "743",
        "rarity_score": 96.61246612466125,
        "rarity_level": "RARE"
    },
    "744": {
        "name": "Kishu Ken #744",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/744.png",
        "dna": "3a1e0a209122a88eef6799b1d0c749ab6a843feb",
        "edition": 744,
        "date": 1662952205400,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Red",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Mouth",
                "value": "Hodling $BTC",
                "score": 58.8235294117647
            },
            { "trait_type": "Eyes", "value": "3D Glasses", "score": 15.625 },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Legendary Rugpuller",
        "rarity": 5.5,
        "id": "744",
        "rarity_score": 133.41658837982368,
        "rarity_level": "EPIC"
    },
    "745": {
        "name": "Kishu Ken #745",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/745.png",
        "dna": "4bc9ed1fa8949b6747682c1e8acdfaa9a4e113ea",
        "edition": 745,
        "date": 1662951822274,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Kids Cap",
                "score": 7.462686567164179
            }
        ],
        "rank": "Common Thief",
        "rarity": 13,
        "id": "745",
        "rarity_score": 45.99504028939132,
        "rarity_level": "COMMON"
    },
    "746": {
        "name": "Kishu Ken #746",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/746.png",
        "dna": "bcf1d68d627f5c804261696523fa0ffcf2e7c010",
        "edition": 746,
        "date": 1662951935103,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Face",
                "value": "Angry",
                "score": 12.658227848101266
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Wizard",
                "score": 45.45454545454545
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.25,
        "id": "746",
        "rarity_score": 80.80059874276574,
        "rarity_level": "COMMON"
    },
    "747": {
        "name": "Kishu Ken #747",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/747.png",
        "dna": "5181484422c7b37e8add9baae268be6dda612cb7",
        "edition": 747,
        "date": 1662952051583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Hat",
                "value": "Gentelmen",
                "score": 25.641025641025642
            }
        ],
        "rank": "Common Thief",
        "rarity": 11.666666666666666,
        "id": "747",
        "rarity_score": 43.042071197411005,
        "rarity_level": "COMMON"
    },
    "748": {
        "name": "Kishu Ken #748",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/748.png",
        "dna": "b2dce9bc59e2b3206feb98255fbd2bb6693adcf6",
        "edition": 748,
        "date": 1662951846831,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 7.6923076923076925
            },
            {
                "trait_type": "Face",
                "value": "Excited",
                "score": 9.70873786407767
            },
            {
                "trait_type": "Eyes",
                "value": "Inferno",
                "score": 9.174311926605505
            },
            {
                "trait_type": "Hat",
                "value": "Crown",
                "score": 22.727272727272727
            }
        ],
        "rank": "Common Thief",
        "rarity": 12.75,
        "id": "748",
        "rarity_score": 49.302630210263594,
        "rarity_level": "COMMON"
    },
    "749": {
        "name": "Kishu Ken #749",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/749.png",
        "dna": "3bf85d41d0d63008bd3d2d6da3e16a77020d9d60",
        "edition": 749,
        "date": 1662952325836,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange Blue",
                "score": 7.407407407407407
            },
            {
                "trait_type": "Mouth",
                "value": "Cigare",
                "score": 13.513513513513514
            },
            {
                "trait_type": "Eyes",
                "value": "Eye Patch",
                "score": 18.181818181818183
            },
            {
                "trait_type": "Hat",
                "value": "Santa",
                "score": 55.55555555555556
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 8.75,
        "id": "749",
        "rarity_score": 94.65829465829466,
        "rarity_level": "RARE"
    },
    "750": {
        "name": "Kishu Ken #750",
        "description": "Kishu Ken NFT",
        "image": "https://arkade-prod.s3.amazonaws.com/kishu-ken/750.png",
        "dna": "a56cbbf154875b11fb674b00c200f5d2ea99a4e4",
        "edition": 750,
        "date": 1662952480600,
        "attributes": [
            { "trait_type": "Background", "value": "Pink", "score": 8.0 },
            {
                "trait_type": "Mouth",
                "value": "Pipe",
                "score": 26.31578947368421
            },
            {
                "trait_type": "Eyes",
                "value": "Nerd Glasses",
                "score": 17.54385964912281
            },
            {
                "trait_type": "Hat",
                "value": "Party Hat",
                "score": 12.658227848101266
            }
        ],
        "rank": "Rare Fraudster",
        "rarity": 9.75,
        "id": "750",
        "rarity_score": 64.51787697090828,
        "rarity_level": "COMMON"
    }
}

export default kishu_ken_metadata
import { Badge, Box, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import CustomPopover from "../CustomPopover";

const AttributesPanel = () => {
    return (
        <Box mt="28px">
            <Text fontSize="20px" fontWeight="700">
                Attributes
            </Text>
            <SimpleGrid columns={[3]} mt="8px" gap="8px">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => {
                    return (
                        <Box w="100%" key={item}>
                            <Box
                                py="6px"
                                px="8px"
                                style={{ border: "0.5px solid #616161" }}
                                rounded="8px"
                                bgColor="#424242"
                            >
                                <Text
                                    color="#C2C2C2"
                                    fontSize="12px"
                                    fontWeight={"700"}
                                >
                                    Body
                                </Text>{" "}
                                <Text
                                    color="#EDEDED"
                                    fontSize="14px"
                                    fontWeight={"700"}
                                    py="2px"
                                >
                                    Black
                                </Text>
                                <CustomPopover
                                    content={"68% of all NFTs have this trait"}
                                >
                                    <Badge
                                        bgColor="#616161"
                                        p="2px"
                                        color="#F8D2AF"
                                        fontWeight="700"
                                        fontSize="12px"
                                        rounded="4px"
                                    >
                                        68%
                                    </Badge>
                                </CustomPopover>
                            </Box>
                        </Box>
                    );
                })}
            </SimpleGrid>
        </Box>
    );
};

export default AttributesPanel;

import { Box, Icon, Flex, Text, Stack } from "@chakra-ui/react";
import React from "react";

import { RiCloseCircleFill } from "react-icons/ri";

const FailedTask = () => {
    return (
        <Box>
            <Flex justify="right" align="center" gap="12px">
                <Text
                    as="button"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight={"900"}
                    color="white"
                >
                    Dismiss all
                </Text>

                <Text
                    as="button"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight={"900"}
                    color="#AEC9F2"
                >
                    Retry purchase
                </Text>
            </Flex>

            <Stack spacing={"8px"} maxH="" mt="12px">
                {[1, 2, 3, 4, 5].map((item) => {
                    return (
                        <Flex
                            key={item}
                            borderLeft={"4px"}
                            borderColor="#F5AAAA"
                            rounded="8px"
                            py="12px"
                            pl="24px"
                            pr="12px"
                            align="center"
                            minH="92px"
                            gap="16px"
                            bg="#363630"
                            boxShadow={"0 2px 4px rgba(0,0,0,.25)"}
                        >
                            <Box className="failedIcon">
                                <Icon as={RiCloseCircleFill} boxSize="20px" />
                            </Box>

                            <Box>
                                <Box minH="40px">
                                    <Text fontSize={"14px"} fontWeight={"700"}>
                                        Successfully bought Kadcar #234 for
                                        3.2KDA
                                    </Text>
                                </Box>
                                <Flex
                                    align="center"
                                    justify={"right"}
                                    gap="12px"
                                >
                                    <Text
                                        as="button"
                                        w="fit-content"
                                        fontSize="12px"
                                        fontWeight={"900"}
                                        color="white"
                                    >
                                        Dismiss
                                    </Text>{" "}
                                    <Text
                                        as="button"
                                        w="fit-content"
                                        fontSize="12px"
                                        fontWeight={"900"}
                                        color="#AEC9F2"
                                    >
                                        Retry purchase
                                    </Text>
                                </Flex>
                            </Box>
                        </Flex>
                    );
                })}
            </Stack>
        </Box>
    );
};

export default FailedTask;

import {
    Box,
    Button,
    Flex,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";

import Fail from "../assets/images/latest/failed.svg";
import Mark from "../assets/images/latest/mark.svg";

export function SuccessListingModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Mark} alt="Done Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Congratulations!{" "}
                            </Text>
                            <Text my="20px">
                                Your item has successfully been listed!
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Manage NFT
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        onClose();
                                    }}

                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Complete
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function SuccessTransferModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Mark} alt="Done Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Congratulations!{" "}
                            </Text>
                            <Text my="20px">
                                Your item has successfully been transferred!
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        onClose();
                                    }}

                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Complete
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function SuccessDelistingModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Mark} alt="Done Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Complete{" "}
                            </Text>
                            <Text my="20px">
                                Your item has successfully been unlisted{" "}
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Manage NFT
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Complete
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function SuccessMintModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Mark} alt="Done Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Congratulations!{" "}
                            </Text>
                            <Text my="20px">Your Mint was successful! </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Back to Mint
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Manage NFT
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function SuccessPurchaseModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Mark} alt="Done Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Congratulations!{" "}
                            </Text>
                            <Text my="20px">
                                Your transaction was complete!{" "}
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => {
                                        // backNavigateCallback();
                                        onClose();
                                    }}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Manage NFT
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedMintModal({ isOpen, onOpen, onClose }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Oh no, Mint failed
                            </Text>
                            <Text my="20px">
                                Your transaction did not go through{" "}
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedQuickBuyModal({ isOpen, onOpen, onClose, errorMessage }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            {/* <Text fontSize="26px" fontWeight="700">
                                Oh no, Mint failed
                            </Text>
                            <Text my="20px">
                                Your transaction did not go through{" "}
                            </Text> */}
                            <Text fontSize="26px" fontWeight="700">
                                Unable to Execute Transaction
                            </Text>
                            <Text my="20px">
                                {errorMessage}{" "}
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedPurchaseModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Oh no, Transaction failed
                            </Text>
                            <Text my="20px">
                                Your transaction did not go through{" "}
                            </Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedDelistingModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Oh no, delisting failed
                            </Text>
                            <Text my="20px">Your item wasn’t listed</Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Try again
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedListingModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Oh no, Listing failed
                            </Text>
                            <Text my="20px">Your item wasn’t listed</Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Try again
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export function FailedTransferModal({ isOpen, onOpen, onClose, navigateCallback, backNavigateCallback }) {
    return (
        <>
            {/* <Button onClick={onOpen}>Open Modal</Button> */}

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size="sm"
                isCentered
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box
                        bgColor="#160D1A"
                        borderRadius="8px"
                        py="16px"
                        px="20px"
                        maxW="717px"
                    >
                        <Box mt="28px" textAlign="center">
                            <Flex align="center" justify="center" mb="16px">
                                <Image src={Fail} alt="Fail Icon" />
                            </Flex>
                            <Text fontSize="26px" fontWeight="700">
                                Oh no, transfer failed
                            </Text>
                            <Text my="20px">Your item wasn’t trasnferred</Text>

                            <Flex gap="30px">
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="transparent"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    color="#BC3CF0"
                                    onClick={() => onClose()}
                                    mb="28px"
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Close
                                    </span>
                                </Button>
                                <Button
                                    outline={"none"}
                                    border="2px"
                                    borderColor="#BD3DF0"
                                    w="100%"
                                    py="20px"
                                    bgColor="#BC3CF0"
                                    _hover={{
                                        bgColor: "rgba(189, 61, 240, 0.33)",
                                    }}
                                    mb="28px"
                                    onClick={() => {
                                        navigateCallback();
                                        onClose();
                                    }}
                                    rounded={"full"}
                                >
                                    <span style={{ "color": "white" }}>
                                        Try again
                                    </span>
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}

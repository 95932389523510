import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
// import DummyImage from "../assets/images/dummyCar.svg";
import { useNavigate } from "react-router-dom";

const FolderCard = ({ collectionConfig, cardCallback, clickable }) => {
    const navigate = useNavigate();

    function getTotalItems() {
        let totalItems = 0;

        Object.values(collectionConfig["child-collection-map"]).forEach((nft) => {
            totalItems += nft["nft_supply"];
        })

        return totalItems;
    }

    return (
        <Box
            className="kadCard latestDesign"
            cursor="pointer"
            onClick={() => {
                if (clickable) {
                    clickable && cardCallback && cardCallback(collectionConfig);
                }
            }}
        >
            <Box className="" px="0.25rem" pt="0.25rem">
                <Image
                    src={
                        collectionConfig &&
                        collectionConfig["collection_thumbnail_image"]
                    }
                    borderTopRightRadius={"0.75rem"}
                    borderTopLeftRadius={"0.75rem"}
                    alt="nftCard"
                    width={"100%"}
                    height="18.5rem"
                    objectFit={"cover"}
                    className="cardImage"
                />
            </Box>
            <Box
                style={{ padding: "1rem 1rem 1rem 21px" }}
            // bgColor="#160d19"
            >
                <Box>
                    <Box className="flex sBtw alignCenter">
                        {" "}
                        <Text
                            className="kadcarsTitle"
                            fontSize="0.875rem"
                            fontWeight="700"
                            noOfLines={1}
                        >
                            {collectionConfig &&
                                collectionConfig["project-name"]}
                        </Text>
                        <Box
                            className=""
                            fontSize="12px"
                            fontWeight={"700"}
                            px="8px"
                            py="2px"
                            border="1px"
                            borderColor="#E9BEFA"
                            bgColor="#BC3CF0"
                            borderRadius={"32px"}
                            flexShrink={0}
                        >
                            {" "}TRADE NOW{" "}
                        </Box>
                        {/* Card type badge variants */}
                        {/* live badge */}
                    </Box>
                    <Box mt="0.5rem" className="totalItems">
                        <Box className="cardDetails">
                            <Text
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "12px",
                                }}
                                color="#C2C2C2"
                            >
                                # COLLECTIONS
                            </Text>
                            <Text
                                color="#C2C2C2"
                                className=""
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "12px",
                                    textAlign: "right",
                                }}
                            >
                                TOTAL ITEMS
                            </Text>
                        </Box>
                        <Box className="cardDetails cardValue">
                            <Text>
                                {collectionConfig &&
                                    Object.entries(
                                        collectionConfig["child-collection-map"]
                                    ).length}
                            </Text>
                            <Text className="" textAlign={"right"}>
                                {collectionConfig && getTotalItems()}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FolderCard;

import {
    PopoverContent,
    PopoverTrigger,
    Button,
    Popover,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
} from "@chakra-ui/react";
import React from "react";

const CustomPopover = ({ children, content }) => {
    return (
        <>
            <Popover placement="bottom-start" trigger="hover">
                <PopoverTrigger>{children}</PopoverTrigger>
                <PopoverContent
                    maxW="180px"
                    border="none"
                    bgColor="#C685E0"
                    rounded="8px"
                    zIndex="2000"
                >
                    <PopoverArrow bg="#C685E0" />

                    <PopoverBody
                        bgColor={"#C685E0"}
                        maxW="180px"
                        rounded="8px"
                        fontSize="12px"
                        color="#20201D"
                        textAlign="left"
                    >
                        {content}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};

export default CustomPopover;

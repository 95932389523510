import React from "react";

const MintProgressBar = ({ progressPercentage }) => {
    return (
        <div style={{ marginBottom: "30px" }}>
            <div
                className="progress-bar"
                style={{ width: `${100}%`, backgroundColor: "#fff" }}
            >
                <span className="bar">
                    <span
                        className="mintProgress"
                        style={{
                            width: `${progressPercentage}%`,
                        }}
                    ></span>
                </span>
            </div>
        </div>
    );
};

export default MintProgressBar;

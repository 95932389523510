import { useContext } from "react";
import { CrossChainContext } from "../CrossChainOperations/CrossChainContextProvider";
import { PactContext } from "./PactContextProvider";
import { checkIfNullOrUndefined, mkReq, tryLoadLocal } from "../utils/utils";
import { getNetworkUrlWithChainId } from "./PactUtils";
import Pact from "pact-lang-api";
import {
    CONNECTED_WALLET_TYPE,
    ECKO_WALLET_NAME,
    KADENA_SIGNING_API_URL,
    KDA_QUICKSIGN,
    NETWORK_ID,
    WALLET_CONNECT_V2,
} from "../utils/Constants";
import { toast } from "react-toastify";
import { useWalletConnect } from "../WalletConnection/useWalletConnect";

function useQuickSign() {
    const crossChainContext = useContext(CrossChainContext);
    const walletConnect = useWalletConnect();

    async function quickSign(cmdList) {
        let quickSignResponse = null;
        let signedCmds = [];

        let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);
        // if (pactContext.isEckoWallet) {
        if (walletType === ECKO_WALLET_NAME) {
            let response = await quickSignEcko(cmdList);
            console.log(response)
            if (response?.status === "success") {
                signedCmds = response.data;
                quickSignResponse = {
                    status: "success",
                    data: signedCmds,
                    wallet: "EckoWallet",
                };
            } else if (response?.status === "fail") {
                crossChainContext.setQuickBuyErrorMessage(
                    `Quicksign failed, ${response.data}`
                );
                crossChainContext.onOpenFailedQuickBuyModal();
            } else {
                crossChainContext.setQuickBuyErrorMessage(
                    "Quicksign failed, please update your eckoWallet chrome extension"
                );
                crossChainContext.onOpenFailedQuickBuyModal();
            }
        } else if (walletType === WALLET_CONNECT_V2) {
            let quickSignCmd = buildQuickSignCmd(cmdList);
            let parsedCmd = JSON.parse(quickSignCmd["body"]);
            let payload = {
                "commandSigDatas": parsedCmd["cmdSigDatas"]
            }
            console.log(payload)

            let response = await walletConnect.requestQuickSignTransaction(NETWORK_ID, payload);
            console.log(response)

            if (checkIfNullOrUndefined(response)) {
                quickSignResponse = {
                    status: "failure",
                    data: "Failed to QuickSign with Wallet Connect V2",
                    wallet: "Wallet Connect V2",
                };
            } else {
                // quickSignResponse = {
                //     status: "success",
                //     data: response["result"]["responses"],
                //     wallet: "Wallet Connect V2",
                // };
                quickSignResponse = {
                    status: "success",
                    data: response["responses"],
                    wallet: "Wallet Connect V2",
                };
            }
        } else {
            let quickSignCmd = {
                cmdSigDatas: [],
            };

            cmdList.forEach((cmdObj) => {
                console.log(cmdObj)
                let cmd = {
                    sigs: [
                        {
                            pubKey: cmdObj.pubKey,
                            sig: null,
                        },
                    ],
                    cmd: cmdObj.cmd,
                };
                quickSignCmd["cmdSigDatas"].push(cmd);
                console.log(cmdObj.cmd);
            });
            console.log(quickSignCmd);

            await fetch(`${KADENA_SIGNING_API_URL}/v1/quicksign`, mkReq(quickSignCmd))
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    console.log(res);
                    signedCmds = res.responses;
                    quickSignResponse = {
                        status: "success",
                        data: signedCmds,
                        wallet: "Pact Server",
                    };
                })
                .catch((e) => {
                    console.log(e);
                    toast.error(`Pact API Failed to QuickSign: ${e.message}`)
                    quickSignResponse = {
                        status: "failure",
                        data: `Pact API Failed to QuickSign: ${e.message}`,
                        wallet: "Pact Server",
                        message: `Pact API Failed to QuickSign: ${e.message}`
                    };
                });
        }

        return quickSignResponse;
    }

    async function quickSignEcko(cmds) {
        console.log(cmds);
        let signedCmds = [];
        let sigDatas = cmds.map((cmd) => {
            let obj = {
                sigs: [
                    {
                        pubKey: cmd["pubKey"],
                    },
                ],
                cmd: cmd["cmd"],
            };
            return obj;
        });

        let cmdData = {
            networkId: NETWORK_ID,
            commandSigDatas: sigDatas,
        };
        console.log(cmdData);
        let quickSignResponse = null;
        await window.kadena
            .request({
                method: KDA_QUICKSIGN,
                data: cmdData,
            })
            .then((res) => {
                console.log(res)
                quickSignResponse = res;
            })
            .catch((e) => {
                console.error(e.message);
                toast.error(`Ecko Wallet Failed to QuickSign: ${e.message}`);
                return {
                    status: "failure",
                    data: "Exception: " + e.message,
                    wallet: ECKO_WALLET_NAME,
                };
            });
        console.log(quickSignResponse);

        if (quickSignResponse.status === "success") {
            let quickSignDataWithHash = quickSignResponse.responses.map((qsData) => {
                qsData["commandSigData"]["hash"] = qsData["outcome"]["hash"];
                return qsData;
            });
            signedCmds = quickSignDataWithHash;
        } else {
            return { status: "failure", data: "eckoWallet Failure" };
        }

        console.log("NEW QUICKSIGN COMMANDS");
        console.log(signedCmds);
        return {
            status: "success",
            data: signedCmds,
            wallet: ECKO_WALLET_NAME,
        };
    }

    async function sendQuickSigned(quickSignedCmdData) {
        console.log(quickSignedCmdData)
        let sendSignedData = null;

        try {
            let parsedCmd = JSON.parse(
                quickSignedCmdData[0]["commandSigData"]["cmd"]
            );

            let apiHost = getNetworkUrlWithChainId(
                NETWORK_ID,
                parsedCmd["meta"]["chainId"]
            );

            let formattedCmd = {
                cmd: quickSignedCmdData[0]["commandSigData"]["cmd"],
                sigs: quickSignedCmdData[0]["commandSigData"]["sigs"],
                hash: quickSignedCmdData[0]["outcome"]["hash"],
            };

            sendSignedData = await Pact.wallet.sendSigned(
                formattedCmd,
                apiHost
            );
        } catch (e) {
            console.log(e);
            //TODO: throw error here
            toast.error(`Failed to send transaction: ${e.message}`)
        }

        return sendSignedData;
    }

    async function quickSignAndSend(cmdData) {
        let sendSignedResponse = null;
        try {
            let quickSignResponse = await quickSign(cmdData["cmds"]);
            console.log(cmdData)
            console.log(quickSignResponse);

            if (quickSignResponse["status"] !== "success") {
                //TODO THROW ERROR HERE
                return;
            }

            sendSignedResponse = await sendQuickSigned(
                quickSignResponse["data"]
            );

            if (!sendSignedResponse) {
                //TODO throw error here
                return;
            }
        } catch (e) {
            console.log(e);
        }

        return sendSignedResponse;
    }

    function buildQuickSignCmd(cmdList) {
        let quickSignCmd = {
            cmdSigDatas: [],
        };

        cmdList.forEach((cmdObj) => {
            console.log(cmdObj)
            let cmd = {
                sigs: [
                    {
                        pubKey: cmdObj.pubKey,
                        sig: null,
                    },
                ],
                cmd: cmdObj.cmd,
            };
            quickSignCmd["cmdSigDatas"].push(cmd);
        });

        return mkReq(quickSignCmd);
    }

    return {
        quickSign,
        quickSignEcko,
        sendQuickSigned,
        quickSignAndSend,
    };
}

export { useQuickSign };

import React from "react";
import { Box, Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import DummyImage from "../assets/images/dummyCar.svg";

const UserProfileDetails = () => {
    const socialDetails = [
        { id: 1, icon: AiOutlineTwitter, url: "#" },
        { id: 2, icon: FaDiscord, url: "#" },
        { id: 3, icon: IoIosLink, url: "#" },
    ];
    const profileDetails = [
        {
            id: 1,
            label: "FLOOR PRICE",
            value: "12",
            symbol: "KDA",
        },
        {
            id: 2,
            label: "TOTAL VOLUME",
            value: "13,422",
            symbol: "KDA",
        },
        {
            id: 3,
            label: "OWNERS",
            value: "3,546",
            symbol: "",
        },
        {
            id: 4,
            label: "LSITED/SUPPLY",
            value: `324/4,444 (${7.2}%)`,
            symbol: "",
        },
        {
            id: 5,
            label: "CREATOR ROYALTIES",
            value: "4.5",
            symbol: "%",
        },
    ];
    return (
        <Box
            className="latestDesign"
            borderBottom="1px"
            borderColor="#424242"
            pl="1rem"
        >
            <Flex align="center" justify="flex-end">
                {/* Wallet picture and social links */}

                <Flex
                    gap={["20px", null, null, "77px"]}
                    py="16px"
                    flexDir={["column", null, "column", "row"]}
                >
                    <Flex gap="20px" align="center">
                        <Box
                            w="80px"
                            h="80px"
                            rounded={"full"}
                            overflow={"hidden"}
                        >
                            <Image
                                src={DummyImage}
                                alt="dummyimage"
                                w="100%"
                                h="100%"
                                objectFit={"cover"}
                            />
                        </Box>
                        <Box>
                            <Text fontSize="20px" fontWeight={"700"}>
                                KADCARS{" "}
                                <Text fontSize="1rem" as="span">
                                    chain 8
                                </Text>
                            </Text>
                            <Flex gap="1rem" mt="10px">
                                {socialDetails.map((item) => {
                                    return (
                                        <Link href={item?.url}>
                                            {" "}
                                            <Icon as={item?.icon} />
                                        </Link>
                                    );
                                })}
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex gap="16px" align="center">
                        {profileDetails.map((item) => {
                            return (
                                <Box key={item?.id} textAlign="center">
                                    <Text fontSize="16px" fontWeight="700">
                                        {item?.value}
                                        {item?.symbol}
                                    </Text>
                                    <Text
                                        textTransform={"uppercase"}
                                        fontSize="12px"
                                        fontWeight={500}
                                    >
                                        {item?.label}
                                    </Text>
                                </Box>
                            );
                        })}
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default UserProfileDetails;

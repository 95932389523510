import { Box, Flex, Text, useDisclosure, Image } from "@chakra-ui/react";
import React, { memo, useContext } from "react";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import { STACKED_TYPE } from "../utils/Constants";
import { checkIfNullOrUndefined } from "../utils/utils";

const UserNFTCard = memo(
    ({ collectionName, nftImage, nftData, cardCallback }) => {
        const isokoContext = useContext(IsokoDialogContext);

        function handleCardClick() {
            isokoContext.onOpenNftDataPopup();
            cardCallback(nftData)
        }

        return (
            <>
                <Box
                    style={{ cursor: "pointer" }}
                    className="kadCard latestDesign"
                    onClick={() => cardCallback && handleCardClick()}
                >
                    <Flex
                        height="328px"
                        align="center"
                        justify="center"
                        bgColor="#010001"
                        px="0.25rem"
                        pt="0.25rem"
                    >
                        {nftData ? (
                            <Image
                                src={nftData && nftData["nft-uri"]}
                                alt="nftCard"
                                width={"100%"}
                                height="328px"
                                objectFit="cover"
                                className="cardImage"
                                borderTopRightRadius={"0.75rem"}
                                borderTopLeftRadius={"0.75rem"}
                            />
                        ) : (
                            <video>
                                <source
                                    src="/questionmark.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        )}
                    </Flex>
                    <Box
                        style={{ padding: "16px 16px 16px 21px" }}
                    // bgColor="#160d19"
                    // className="bgShade"
                    >
                        <Box>
                            <Flex
                                align="center"
                                justify="space-between"
                                mb="10px"
                            >
                                {" "}
                                <Text
                                    fontWeight="700"
                                    fontSize="20px"
                                    noOfLines={1}
                                >
                                    {collectionName}
                                </Text>
                                <Text
                                    fontWeight="700"
                                    fontSize="20px"
                                    textAlign={"right"}
                                >
                                    #{nftData ? nftData["nft-id"] : "???"}
                                </Text>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }
);

export default UserNFTCard;

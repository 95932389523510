import {
    Box,
    Menu,
    MenuButton,
    Flex,
    Icon,
    Text,
    MenuItem,
    MenuList,
    Divider,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsArrowDownUp, BsChevronDown } from "react-icons/bs";

const RecentlyListed = () => {
    const [listedSort, setListedSort] = useState("Recently Added");
    return (
        <Box flexShrink={0}>
            <Menu>
                <MenuButton
                    bgColor="transparent"
                    _focus={{}}
                    _active={{}}
                    _hover={{}}
                    _focusVisible={{}}
                    cursor="pointer"
                    border="1px"
                    borderColor="#757575"
                    rounded="8px"
                    py="0.75rem"
                    px="16px"
                    // className="dropDown"
                >
                    <Flex align="center" gap="16px">
                        <Text fontSize="14px" fontWeight="700" color="#9E9E9E">
                            {listedSort}
                        </Text>

                        <Icon
                            as={BsChevronDown}
                            boxSize="16px"
                            color="#9E9E9E"
                        />
                    </Flex>
                </MenuButton>

                <MenuList
                    bgColor="#160D1A"
                    minWidth="187px"
                    rounded="16px"
                    border="1px"
                    borderColor="#424242"
                    overflow="hidden"
                    py="0"
                    px="0.75rem"
                    cursor="pointer"
                >
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        onClick={() => setListedSort("Recently Listed")}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        cursor="pointer"
                    >
                        Recently Listed
                    </MenuItem>
                    <Box>
                        <Divider border="1px" borderColor={"#424242"} />
                    </Box>
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        onClick={() => setListedSort("Price: High to Low")}
                    >
                        Price: High to Low
                    </MenuItem>
                    <Box>
                        <Divider border="1px" borderColor={"#424242"} />
                    </Box>
                    <MenuItem
                        bgColor="#160D1A"
                        className="menuItem"
                        fontWeight="900"
                        _hover={{ color: "#28172E" }}
                        maxW={"100%"}
                        h="58px"
                        px="0.75rem"
                        onClick={() => setListedSort("Price: Low to high")}
                    >
                        Price: Low to high
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default RecentlyListed;

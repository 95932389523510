import { Box, useDisclosure } from "@chakra-ui/react";
import { createContext, useState } from "react";
import { useNavigate } from "react-router";
import NavBar from "../CommonComponents/NavBar";
import {
    FailedDelistingModal,
    FailedListingModal,
    FailedMintModal,
    FailedPurchaseModal,
    FailedTransferModal,
    SuccessDelistingModal,
    SuccessListingModal,
    SuccessMintModal,
    SuccessPurchaseModal,
    SuccessTransferModal,
} from "../DialogueModals/StatusModals";
import { db } from "../Firestore/FirestoreService";
import { collection, getDocs, query } from "firebase/firestore";
import useLocalFirestore from "../Firestore/useLocalFirestore";
import ConnectWalletModal from "../WalletConnection/ConnectWalletModal";
import BugReportModal from "../FeedBack/BugReportModal";
import FeedBackModal from "../FeedBack/FeedBackModal";
import ReportBug from "../FeedBack/ReportBug";
import BackToTopButton from "../scrollTo/BackToTopButton";
import { useInterval } from "../utils/utils";

export const IsokoDialogContext = createContext();

const IsokoDialogContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [activeCollectionMetadata, setActiveCollectionMetadata] = useState();
    const [activeNftMetadata, setActiveNftMetadata] = useState();
    const [moveFundsMap, setMoveFundsMap] = useState();
    const [activeModalUrl, setActiveModalUrl] = useState();
    const [backModalUrl, setBackModalUrl] = useState();

    //Connect wallet
    const {
        isOpen: isOpenConnectModal,
        onOpen: onOpenConnectModal,
        onClose: onCloseConnectModal,
    } = useDisclosure();

    //NFT popup
    const {
        isOpen: isOpenNftDataPopup,
        onOpen: onOpenNftDataPopup,
        onClose: onCloseNftDataPopup,
    } = useDisclosure();

    //Success Modals
    const {
        isOpen: isOpenSuccessListModal,
        onOpen: onOpenSuccessListModal,
        onClose: onCloseSuccessListModal,
    } = useDisclosure();
    const {
        isOpen: isOpenSuccessMintModal,
        onOpen: onOpenSuccessMintModal,
        onClose: onCloseSuccessMintModal,
    } = useDisclosure();
    const {
        isOpen: isOpenSuccessPurchaseModal,
        onOpen: onOpenSuccessPurchaseModal,
        onClose: onCloseSuccessPurchaseModal,
    } = useDisclosure();
    const {
        isOpen: isOpenSuccessDelistModal,
        onOpen: onOpenSuccessDelistModal,
        onClose: onCloseSuccessDelistModal,
    } = useDisclosure();
    const {
        isOpen: isOpenSuccessTransferModal,
        onOpen: onOpenSuccessTransferModal,
        onClose: onCloseSuccessTransferModal,
    } = useDisclosure();

    // const {
    //     isOpen: isOpenFeedBackSent,
    //     onOpen: onOpenFeedBackSent,
    //     onClose: onCloseFeedBackSent,
    // } = useDisclosure();

    // const {
    //     isOpen: isOpenBugReportSent,
    //     onOpen: onOpenBugReportSent,
    //     onClose: onCloseBugReportSent,
    // } = useDisclosure();

    //Failure Modals
    const {
        isOpen: isOpenFailedListModal,
        onOpen: onOpenFailedListModal,
        onClose: onCloseFailedListModal,
    } = useDisclosure();
    const {
        isOpen: isOpenFailedMintModal,
        onOpen: onOpenFailedMintModal,
        onClose: onCloseFailedMintModal,
    } = useDisclosure();
    const {
        isOpen: isOpenFailedPurchaseModal,
        onOpen: onOpenFailedPurchaseModal,
        onClose: onCloseFailedPurchaseModal,
    } = useDisclosure();
    const {
        isOpen: isOpenFailedDelistModal,
        onOpen: onOpenFailedDelistModal,
        onClose: onCloseFailedDelistModal,
    } = useDisclosure();
    const {
        isOpen: isOpenFailedTransferModal,
        onOpen: onOpenFailedTransferModal,
        onClose: onCloseFailedTransferModal,
    } = useDisclosure();

    //  const {
    //      isOpen: isOpenFeedBackFailed,
    //      onOpen: onOpenFeedBackFailed,
    //      onClose: onCloseFeedBackFailed,
    //  } = useDisclosure();

    //  const {
    //      isOpen: isOpenBugReportFailed,
    //      onOpen: onOpenBugReportFailed,
    //      onClose: onCloseBugReportFailed,
    //  } = useDisclosure();

    // Feedback modal
    const {
        isOpen: isOpenFeedbackModal,
        onOpen: onOpenFeedbackModal,
        onClose: onCloseFeedbackModal,
    } = useDisclosure();

    function navigateFromModal() {
        navigate(activeModalUrl);
        setActiveModalUrl("/");
    }
    function backNavigateFromModal() {
        navigate(backModalUrl);
        setActiveModalUrl("/");
    }

    // report bug
    const {
        isOpen: isOpenReportBug,
        onOpen: onOpenReportBug,
        onClose: onCloseReportBug,
    } = useDisclosure();

    return (
        <IsokoDialogContext.Provider
            value={{
                onOpenConnectModal,
                onOpenSuccessListModal,
                onOpenSuccessMintModal,
                onOpenSuccessPurchaseModal,
                onOpenSuccessDelistModal,
                onOpenSuccessTransferModal,
                onOpenFailedListModal,
                onOpenFailedMintModal,
                onOpenFailedPurchaseModal,
                onOpenFailedDelistModal,
                onOpenFailedTransferModal,
                onOpenNftDataPopup,
                setActiveModalUrl,
                setBackModalUrl,
                backModalUrl,
                // collections: collections || {},
                isOpenFeedbackModal,
                onOpenFeedbackModal,
                onCloseFeedbackModal,
                isOpenReportBug,
                onOpenReportBug,
                onCloseReportBug,
            }}
        >
            <ConnectWalletModal
                isOpen={isOpenConnectModal}
                onOpen={onOpenConnectModal}
                onClose={onCloseConnectModal}
            />

            <SuccessListingModal
                isOpen={isOpenSuccessListModal}
                onOpen={onOpenSuccessListModal}
                onClose={onCloseSuccessListModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <FailedListingModal
                isOpen={isOpenFailedListModal}
                onOpen={onOpenFailedListModal}
                onClose={onCloseFailedListModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <SuccessDelistingModal
                isOpen={isOpenSuccessDelistModal}
                onOpen={onOpenSuccessDelistModal}
                onClose={onCloseSuccessDelistModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <FailedDelistingModal
                isOpen={isOpenFailedDelistModal}
                onOpen={onOpenFailedDelistModal}
                onClose={onCloseFailedDelistModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <SuccessPurchaseModal
                isOpen={isOpenSuccessPurchaseModal}
                onOpen={onOpenSuccessPurchaseModal}
                onClose={onCloseSuccessPurchaseModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <FailedPurchaseModal
                isOpen={isOpenFailedPurchaseModal}
                onOpen={onOpenFailedPurchaseModal}
                onClose={onCloseFailedPurchaseModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <SuccessTransferModal
                isOpen={isOpenSuccessTransferModal}
                onOpen={onOpenSuccessTransferModal}
                onClose={onCloseSuccessTransferModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <FailedTransferModal
                isOpen={isOpenFailedTransferModal}
                onOpen={onOpenFailedTransferModal}
                onClose={onCloseFailedTransferModal}
                navigateCallback={navigateFromModal}
                backNavigateCallback={backNavigateFromModal}
            />

            <SuccessMintModal
                isOpen={isOpenSuccessMintModal}
                onOpen={onOpenSuccessMintModal}
                onClose={onCloseSuccessMintModal}
            />

            <FailedMintModal
                isOpen={isOpenFailedMintModal}
                onOpen={onOpenFailedMintModal}
                onClose={onCloseFailedMintModal}
            />

            <BugReportModal
                isOpen={isOpenFeedbackModal}
                onOpen={onOpenFeedbackModal}
                onClose={onCloseFeedbackModal}
            />

            {/* <ReportBug
                isOpen={isOpenReportBug}
                onOpen={onOpenReportBug}
                onClose={onCloseReportBug}
            /> */}
            <Box bgColor="transparent" className="navBox latestDesign">
                <BackToTopButton />
                <NavBar />
            </Box>

            {children}
        </IsokoDialogContext.Provider>
    );
};

export { IsokoDialogContextProvider };

import { useEffect, useState } from "react";
import { checkIfNullOrUndefined } from "../utils/utils";
import { queryBlockchainForData } from "../pact/ContractHooks";

function useGetAccountWhitelistData(account, policy) {
    const [accountWlData, setAccountWlData] = useState(null);

    useEffect(() => {
        async function getAccountWhitelistData(account) {
            let pactCode = `(${policy["policy-name"]}.get-account-whitelist-info "${account}")`;
            let response = await queryBlockchainForData(pactCode, policy["chain-id"]);
    
            if (!checkIfNullOrUndefined(response)) {
                setAccountWlData(response);
            }
        }

        !checkIfNullOrUndefined(account) && getAccountWhitelistData();
    }, [account]);

    return accountWlData;
}

export {
    useGetAccountWhitelistData
}

import React, {
    useCallback,
    useState,
    useEffect,
    createContext
} from "react";
import Pact, { wallet } from "pact-lang-api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    CHAINWEAVER_WALLET_NAME,
    CONNECTED_WALLET_TYPE,
    DEFAULT_CHAIN_ID,
    DEFAULT_GAS_PRICE,
    DEFAULT_REQUEST_HEADERS,
    ECKO_WALLET_NAME,
    EZ_BUY_TRANSACTION_STEP_DATA,
    IS_ECKO_WALLET_KEY,
    KOALA_WALLET_NAME,
    K_ACCOUNT_ONLY_ERROR,
    LOCAL_ACCOUNT_DATA_KEY,
    LOCAL_ACCOUNT_KEY,
    NETWORK_ID,
    POLL_INTERVAL_S,
    POST_METHOD,
    SHOW_EZ_BUY_PROGRESS_BAR,
    SHOW_TOTAL_BALANCE,
    SIGNED_QUICKBUY_COMMANDS,
    S_TO_MS_MULTIPLIER,
    USER_GLB_URLS,
    USER_WEBP_URLS,
    WALLET_CONNECT_V2,
    XCHAIN_COMMANDS,
    ZELCORE_WALLET_NAME,
} from "../utils/Constants";
import {
    creationTime,
    parseResponse,
    confirmTransactionWithNetwork,
    tryLoadLocal,
    trySaveLocal,
    wait,
    checkXwalletNetworkAndChainSettings,
    checkIfNullOrUndefined,
    trySaveLocalForage,
} from "../utils/utils";
import {
    getNetworkUrl,
    getAccountTokenBalance,
    calculateTotalFundsOnAllChains,
    getNetworkUrlWithChainId,
} from "./PactUtils";
import {
    connectKadena,
    connectKoala,
    disconnectKadena,
    requestKadenaAccount,
    requestSign,
} from "../kadenaInteraction/KadenaApi";
import { getBalanceForAllChains } from "../CrossChainOperations/CrossChainBalanceUtils";
import { useNavigate } from "react-router";
import { useWalletConnect } from "../WalletConnection/useWalletConnect";

export const PactContext = createContext(); //Define Pact Context

const PactContextProvider = ({ children }) => {
    const [chainId, setChainId] = useState(DEFAULT_CHAIN_ID);
    const [gasPrice, setGasPrice] = useState(DEFAULT_GAS_PRICE);
    const [netId, setNetId] = useState(NETWORK_ID);
    const [userWallet, setUserWallet] = useState(null);
    const [account, setAccount] = useState(() => tryLoadLocal(LOCAL_ACCOUNT_KEY));
    const [networkUrl, setNetworkUrl] = useState(null);
    const [currTransactionState, setCurrTransactionState] = useState({});
    const [isEckoWallet, setIsEckoWallet] = useState(tryLoadLocal(IS_ECKO_WALLET_KEY));
    const navigate = useNavigate();
    const walletConnect = useWalletConnect();

    useEffect(() => {
        let acc = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        acc && pollForAccountBalanceDataOnAllChains(acc.account);
    }, []);

    useEffect(() => {
        setNetworkUrl(getNetworkUrl(netId));
    }, [netId]);

    const executeTransaction = useCallback(() => {
        if (
            !checkIfNullOrUndefined(currTransactionState.cmdToConfirm) &&
            currTransactionState.shouldSign === true
        ) {
            signTransaction(currTransactionState.cmdToConfirm);
        }
    }, [currTransactionState]);

    useEffect(() => {
        executeTransaction();
    }, [executeTransaction]);

    const clearTransaction = () => {
        setCurrTransactionState({});
    };

    const setNetworkSettings = (netId, chainId, gasPrice) => {
        setNetId(netId);
        setChainId(chainId);
        setGasPrice(gasPrice);
    };

    const useSetNetworkSettings = (
        netId,
        chainId,
        gasPrice = DEFAULT_GAS_PRICE
    ) => {
        useEffect(() => {
            setNetworkSettings(netId, chainId, gasPrice);
        }, [netId, chainId, gasPrice]);
    };

    const fetchAccountDetails = async (accountName) => {
        return await readFromContract({
            pactCode: `(coin.details ${JSON.stringify(accountName)})`,
            meta: defaultMeta(),
        });
    };

    const updateTransactionState = (newParams) => {
        const { transactionMessage, successCallback } = {
            currTransactionState,
        };
        successCallback && successCallback();
        setCurrTransactionState({
            transactionMessage,
            successCallback,
            ...newParams,
        });
    };

    const defaultMeta = (gasLimit) => {
        return Pact.lang.mkMeta(
            "",
            "13",
            gasPrice,
            gasLimit ?? 150000,
            creationTime(),
            600
        );
    };

    const sendTransaction = async (
        cmd,
        previewComponent = null,
        transactionMessage = null,
        successCallback = () => { },
        errorHandler = () => { },
        errorToHandle,
        shouldSign
    ) => {
        setCurrTransactionState({
            transactionMessage,
            successCallback,
            errorHandler,
            cmdToConfirm: cmd,
            previewComponent,
            errorToHandle,
            shouldSign: shouldSign,
        });
    };

    const logoutAccount = async () => {
        let inUserProfile = window.location.href.includes("userProfile");
        if (inUserProfile) {
            navigate("/");
        }
        let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);
        // if (isEckoWallet) {
        if (walletType === ECKO_WALLET_NAME) {
            await disconnectKadena(netId);
            // setIsEckoWallet(false);
        } else if (walletType === WALLET_CONNECT_V2) {
            await walletConnect.disconnectWallet();
        }
        setAccount(null);
        setUserWallet(null);
        trySaveLocal("CREATE_ACCOUNT_KEY", false);
        trySaveLocal(SHOW_EZ_BUY_PROGRESS_BAR, false);
        trySaveLocal(XCHAIN_COMMANDS, null);
        trySaveLocal(SIGNED_QUICKBUY_COMMANDS, null);
        trySaveLocal(LOCAL_ACCOUNT_KEY, null);
        trySaveLocal(SHOW_TOTAL_BALANCE, false);
        trySaveLocal(IS_ECKO_WALLET_KEY, false);
        trySaveLocal(USER_WEBP_URLS, null);
        trySaveLocal(USER_GLB_URLS, null);
        trySaveLocal(EZ_BUY_TRANSACTION_STEP_DATA, [
            { label: "Building transactions", value: "1", status: "neutral" },
            { label: "Signing transactions", value: "2", status: "neutral" },
            { label: "Transferring funds", value: "3", status: "neutral" },
            { label: "Continuing transfer", value: "4", status: "neutral" },
            {
                label: "Executing Business Transaction",
                value: "5",
                status: "neutral",
            },
        ]);

        // setIsEckoWallet(false);
        // setIsConnectWallet(false);
    };

    const readFromContract = async (cmd, returnError) => {
        try {
            let data = await Pact.fetch.local(cmd, networkUrl);
            if (data?.result?.status === "success") {
                return data.result.data;
            } else {
                if (returnError === true) {
                    return data?.result?.error?.message;
                } else {
                    return null;
                }
            }
        } catch (e) {
            toast.error("Had trouble fetching data from the blockchain", {
                toastId: "Fetch Local Error",
            });
            console.log(e);
        }
        return null;
    };

    const setConnectedWallet = async (walletType, callback = null, accountWallet = null) => {
        if (walletType === ECKO_WALLET_NAME || walletType === KOALA_WALLET_NAME) {
            if (walletType === ECKO_WALLET_NAME && !window.kadena) {
                toast.error(`Please make sure ${walletType} Chrome extension is installed`);
                return;
            } else if (walletType === KOALA_WALLET_NAME && !window.koala) {
                toast.error(`Please make sure ${walletType} Chrome extension is installed`);
                return;
            }
            var resAccount = null;
            try {
                // await disconnectKadena(netId);
                let res = null;

                if (walletType === ECKO_WALLET_NAME) {
                    res = await connectKadena(netId);
                } else if (walletType === KOALA_WALLET_NAME) {
                    res = await connectKoala(netId);
                }
                console.log(res);
                if (res.status !== "success") {
                    if (res.message === "Network invalid") {
                        toast.error(
                            `Could not connect to ${walletType}, please select ${NETWORK_ID} from your eckoWALLET extension`
                        );
                    }
                    return;
                }

                if (res.account?.account === account?.account) {
                    toast.error("This wallet is already connected and active");
                    return;
                }

                if (res.status === "success") {
                    trySaveLocal(IS_ECKO_WALLET_KEY, true);
                    trySaveLocal(CONNECTED_WALLET_TYPE, walletType);

                    resAccount = res.account;

                    toast.success(
                        `Connected ${resAccount.account.slice(0, 10)}...`,
                        {
                            hideProgressBar: true,
                            autoClose: 2000,
                        }
                    );

                    await pollForAccountBalanceDataOnAllChains(resAccount.account);
                    setUserWallet(resAccount.account);
                    callback && callback();
                }
            } catch (e) {
                console.log(e);
                toast.error(`Couldn't connect to ${walletType}`);
                return;
            }
        } else if (walletType === ZELCORE_WALLET_NAME || walletType === CHAINWEAVER_WALLET_NAME) {
            if (checkIfNullOrUndefined(accountWallet)) {
                toast.error("Failed to connect: Invalid wallet address");
                return;
            }

            let walletData = await pollForAccountBalanceDataOnAllChains(accountWallet);
            toast.success(`Connected ${accountWallet.slice(0, 10)}...`, {
                hideProgressBar: true,
                autoClose: 2000,
            });
            setUserWallet(accountWallet);

            callback && callback();
        } else if (walletType === WALLET_CONNECT_V2) {
            let successful = false;
            await walletConnect.connectWallet().then(async (responseNullable) => {
                console.log(responseNullable)
                if (!checkIfNullOrUndefined(responseNullable) && responseNullable.accounts.length > 0) {
                    const resultAccounts = [];
                    // const wcAccounts = await walletConnect.requestGetAccounts(NETWORK_ID, responseNullable.accounts);
                    // wcAccounts.accounts.forEach((wcAcc) => wcAcc.kadenaAccounts.forEach((kAcc) => resultAccounts.push(kAcc.name)));
                    // console.log(wcAccounts)
                    successful = true;
                    accountWallet = responseNullable.accounts[0];
                }
            });

            if (!successful) {
                toast.error("Failed to connect account via Wallet Connect V2");
                return;
            }

            await pollForAccountBalanceDataOnAllChains(accountWallet);
            toast.success(`Connected ${accountWallet.slice(0, 10)}...`, {
                hideProgressBar: true,
                autoClose: 2000,
            });
            setUserWallet(accountWallet);

            callback && callback();
        }
        trySaveLocal(CONNECTED_WALLET_TYPE, walletType);
    };

    const updateAccountBalanceData = async (accountName) => {
        const balanceData = await getAccountTokenBalance(
            "coin",
            NETWORK_ID,
            accountName
        );
        console.log(balanceData);
        if (balanceData !== "failure") {
            setAccount(balanceData);
            trySaveLocal(LOCAL_ACCOUNT_KEY, balanceData);
        } else {
            let account = {
                guard: {
                    pred: "keys-all",
                    keys: [accountName.split(":")[1]],
                },
                balance: 0.0,
                account: accountName,
            };
            setAccount(account);
            trySaveLocal(LOCAL_ACCOUNT_KEY, account);
        }
        return balanceData;
    };

    const pollForAccountBalanceDataOnAllChains = async (accountName) => {
        setUserWallet(accountName);
        let localAccountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        !localAccountDetails &&
            (localAccountDetails = { chainMap: {}, account: "" });

        const balanceData = await getBalanceForAllChains(
            accountName,
            LOCAL_ACCOUNT_KEY
        );
        // console.log(balanceData)
        if (balanceData) {
            let totalBalanceOnAllChains = calculateTotalFundsOnAllChains(
                balanceData["chainMap"]
            );

            localAccountDetails["chainMap"] = balanceData["chainMap"];
            localAccountDetails["accountGuard"] = balanceData["accountGuard"];
            localAccountDetails["account"] = accountName;
            localAccountDetails["totalBalance"] = totalBalanceOnAllChains;

            setAccount(localAccountDetails);
            trySaveLocal(LOCAL_ACCOUNT_KEY, localAccountDetails);
            trySaveLocalForage(LOCAL_ACCOUNT_DATA_KEY, localAccountDetails);
        } else {
            let defaultData = {
                guard: {
                    pred: "keys-all",
                    keys: [],
                },
                balance: 0.0,
                account: "",
            };
            let accountData = {};
            for (let i = 0; i < 20; i++) {
                accountData[i] = defaultData;
            }

            localAccountDetails["chainMap"] = accountData;
            localAccountDetails["account"] = accountName;
            localAccountDetails["accountGuard"] = defaultData;
            localAccountDetails["totalBalance"] = 0.0;

            setAccount(localAccountDetails);
            trySaveLocal(LOCAL_ACCOUNT_KEY, localAccountDetails);
            trySaveLocalForage(LOCAL_ACCOUNT_DATA_KEY, localAccountDetails);
        }
        return balanceData;
    };

    const pollForTransaction = async (requestKey, chainId = null) => {
        let reqKeyPreview = requestKey.slice(0, 10);
        let time_spent_polling_s = 0;
        let pollRes = null;

        const { transactionMessage } = currTransactionState;
        let waitingText = (
            <span
                onClick={() =>
                    window.open(
                        `https://explorer.chainweb.com/mainnet/txdetail/${requestKey}`,
                        "_blank"
                    )
                }
            >
                {`Waiting ${POLL_INTERVAL_S}s for transaction ${reqKeyPreview}... (${transactionMessage})`}
            </span>
        );
        toast.info(waitingText, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            draggable: true,
            toastId: requestKey,
        });

        let network = networkUrl;
        if (chainId) {
            network = getNetworkUrlWithChainId(NETWORK_ID, chainId);
        }
        while (time_spent_polling_s < 600) {
            await wait(POLL_INTERVAL_S * S_TO_MS_MULTIPLIER);
            try {
                pollRes = await Pact.fetch.poll(
                    { requestKeys: [requestKey] },
                    network
                );
            } catch (e) {
                console.log(e);
                toast.error("Attempting transaction update again...");
                continue;
            }
            if (Object.keys(pollRes).length !== 0) {
                break;
            }
            time_spent_polling_s += POLL_INTERVAL_S;
            waitingText = `Waiting ${time_spent_polling_s + POLL_INTERVAL_S
                }s for transaction ${reqKeyPreview}... (${transactionMessage})`;
            toast.update(requestKey, { render: waitingText });
        }
        console.log(pollRes);
        if (pollRes[requestKey].result.status === "success") {
            toast.update(requestKey, {
                render: `Transaction ${reqKeyPreview}... (${transactionMessage}) completed!`,
                type: "success",
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
            });
            if (currTransactionState?.successCallback != null) {
                currTransactionState.successCallback(pollRes[requestKey]);
            }
        } else {
            console.log(pollRes);
            // if (currTransactionState?.errorHandler != null) {
            //     currTransactionState.errorHandler(pollRes[requestKey]);
            //     return;
            // }

            toast.error(
                `Transaction ${requestKey}... (${transactionMessage}) failed, please try again`,
                {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                }
            );
        }
        clearTransaction();
    };

    const signTransaction = async (cmdToSign) => {
        updateTransactionState({ signingCmd: cmdToSign, shouldSign: false });

        let signedCmd = null;
        let walletType = tryLoadLocal(CONNECTED_WALLET_TYPE);

        if (walletType === ECKO_WALLET_NAME) {
            let xwalletSignRes = null;
            try {
                const accountConnectedRes = await requestKadenaAccount(
                    netId,
                    window.location.hostname
                );
                console.log(accountConnectedRes);
                if (accountConnectedRes?.status !== "success") {
                    const checkRes =
                        await checkXwalletNetworkAndChainSettings();

                    if (checkRes === null) {
                        clearTransaction();
                        // toast.error("Please reconnect your eckoWALLET, also make sure testnet and chain ID 1 are selected.");
                        // logoutAccount();
                        return;
                    } else {
                        cmdToSign.chainId = checkRes.account.chainId;
                        updateTransactionState({ signingCmd: cmdToSign });
                    }
                } else if (
                    accountConnectedRes?.wallet?.account !== account.account
                ) {
                    toast.error(
                        `Please select ${account.account} from your eckoWALLET extension`
                    );
                    return;
                }
                // else if (accountConnectedRes?.wallet?.chainId !== chainId && accountConnectedRes?.wallet?.chainId !== parseInt(chainId)) {
                //     toast.error(`Please make sure you select chain ${chainId} in the eckoWALLET extension`);
                //     return;
                // }
                const dataToSign = {
                    networkId: netId,
                    signingCmd: cmdToSign,
                };
                xwalletSignRes = await requestSign(netId, dataToSign);
                console.log(dataToSign);
                console.log(xwalletSignRes);
            } catch (e) {
                console.log(e);
            }
            if (xwalletSignRes.status !== "success") {
                toast.error("Command could not be signed in eckoWALLET");
                clearTransaction();
                return;
            }
            signedCmd = xwalletSignRes.signedCmd;
        } else if (walletType === WALLET_CONNECT_V2) {
            try {
                signedCmd = (await walletConnect.requestSignTransaction(
                    NETWORK_ID,
                    cmdToSign
                ))["signedCmd"];
                console.log(signedCmd);
            } catch (e) {
                console.log(e);
                toast.error("Could not sign in Koala Wallet");
                clearTransaction();
                return;
            }
        } else {
            try {
                console.log(cmdToSign);
                signedCmd = await Pact.wallet.sign(cmdToSign);
                console.log(signedCmd);
            } catch (e) {
                console.log(e);
                toast.error("Command could not be signed in wallet");
                clearTransaction();
                return;
            }
        }

        updateTransactionState({ signedCmd });
        console.log(currTransactionState);

        let localRes = null;
        let netUrl = getNetworkUrlWithChainId(
            NETWORK_ID,
            currTransactionState["cmdToConfirm"]["chainId"]
        );
        localRes = await confirmTransactionWithNetwork(
            netUrl,
            POST_METHOD,
            DEFAULT_REQUEST_HEADERS,
            signedCmd
        );

        const parsedLocalRes = await parseResponse(localRes);
        console.log(parsedLocalRes);

        // return;
        if (parsedLocalRes?.result?.status === "success") {
            let data = null;
            try {
                data = await Pact.wallet.sendSigned(signedCmd, netUrl);
            } catch (e) {
                console.log(e);
                console.log(parsedLocalRes);
                if (
                    parsedLocalRes?.result?.error?.message.includes(
                        currTransactionState?.errorToHandle
                    )
                ) {
                    clearTransaction();
                    currTransactionState?.errorHandler &&
                        currTransactionState.errorHandler();
                    return;
                }
                toast.error(
                    "Sending transaction to blockchain failed, please make sure the command format is correct"
                );
                clearTransaction();
                return;
            }
            console.log(data);
            const requestKey = data.requestKeys[0];
            updateTransactionState({
                sentCmd: signedCmd,
                requestKey,
            });
            console.log(currTransactionState);
            let chain = JSON.parse(signedCmd["cmd"])["meta"]["chainId"];
            await pollForTransaction(requestKey, chain);
        } else {
            // currTransactionState?.errorHandler(parsedLocalRes);
            console.log(parsedLocalRes);
            if (
                parsedLocalRes?.result?.error?.message === K_ACCOUNT_ONLY_ERROR
            ) {
                toast.error(
                    `Failed to confirm transaction: only "k" accounts supported for security`,
                    {
                        hideProgressBar: true,
                    }
                );
            } else {
                // if (parsedLocalRes?.result?.error?.message.includes(currTransactionState?.errorToHandle)) {
                //     clearTransaction();
                //     currTransactionState?.errorHandler && currTransactionState.errorHandler();
                //     return;
                // }

                //TODO: error callback here
                toast.error(
                    `Transaction Failed: ${parsedLocalRes?.result?.error?.message}`,
                    {
                        hideProgressBar: true,
                    }
                );
            }
            clearTransaction();
            return;
        }
    };

    return (
        <PactContext.Provider
            value={{
                netId,
                chainId,
                account,
                gasPrice,
                isEckoWallet,
                networkUrl,
                userWallet,
                currTransactionState,
                setNetId,
                setChainId,
                setAccount,
                defaultMeta,
                setGasPrice,
                setIsEckoWallet,
                setNetworkUrl,
                sendTransaction,
                signTransaction,
                readFromContract,
                setNetworkSettings,
                useSetNetworkSettings,
                fetchAccountDetails,
                updateAccountBalanceData,
                setConnectedWallet,
                logoutAccount,
                clearTransaction,
                pollForAccountBalanceDataOnAllChains,
            }}
        >
            <ToastContainer
                position="top-right"
                theme="dark"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {children}
        </PactContext.Provider>
    );
};

export { PactContextProvider };

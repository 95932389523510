//Network IDs
const MAINNET_NETWORK_ID = "mainnet01";
const TESTNET_NETWORK_ID = "testnet04";
const NETWORK_ID = MAINNET_NETWORK_ID;
const ISOKO_CHAINWEB_IP = "157.245.141.90";
const ISOKO_CHAINWEB_PORT = "1789";

//Platform
const ISOKO = "isoko";
const ATRIUM = "atrium";
const PLATFORM = ISOKO;
const ISOKO_PLATFORM = "Isoko LLC";

//phases
const PHASE_NAME = "phase_name";
const PHASE_START_TIME = "start_time";
const PHASE_END_TIME = "end_time";

//Default values
const DEFAULT_GAS_PRICE_X_CHAIN_EXEC = 0.00000002;
const DEFAULT_GAS_LIMIT_X_CHAIN_EXEC = 1100;
const DEFAULT_GAS_PRICE_X_CHAIN_CONT = 0.00000002;
const DEFAULT_GAS_LIMIT_X_CHAIN_CONT = 850;
const DEFAULT_GAS_LIMIT_ISOKO_GAS_STATION = 150000;
const DEFAULT_GAS_PRICE_ISOKO_GAS_STATION = 0.00000002;
const DEFAULT_GAS_PRICE = 0.00000002;
const DEFAULT_GAS_LIMIT = 150000;
const DEFAULT_CHAIN_ID = "1";
const POLL_INTERVAL_S = 5;
const FEE_PERCENTAGE = 0.01;
const GAS_STATION = "746d0601603d1cc907ae82fed1c4bdf3";
const ISOKO_GAS_STATION = "isoko-gas-station";
const QUICKBUY_PROVIDER_TX_FEE = 0.000836;
const ISOKO_MARKETPLACE_FEE_PERCENTAGE = 0.0;

//Labels
const USER_KADCAR_GALLERY_LABEL = "USER";
const ALL_KADCAR_GALLERY_LABEL = "ALL";

//Local storage
const LOCAL_GAS_STATION_KEY = "LOCAL_GAS_STATION_KEY";
const LOCAL_ACCOUNT_KEY = "LOCAL_ACCOUNT_KEY";
const LOCAL_ACCOUNT_DATA_KEY = "LOCAL_ACCOUNT_DATA_KEY";
const LOCAL_CHAIN_ID = "LOCAL_CHAIN_ID";
const SHOW_TOTAL_BALANCE = "SHOW_TOTAL_BALANCE";
const XCHAIN_COMMANDS = "XCHAIN_COMMANDS";
const SIGNED_QUICKBUY_COMMANDS = "SIGNED_QUICKBUY_COMMANDS";

//wallet indicators
const IS_ECKO_WALLET_KEY = "IS_ECKO_WALLET_KEY";
const CONNECTED_WALLET_TYPE = "WALLET_TYPE";
const X_WALLET_INSTALLED = "X_WALLET_INSTALLED";
const BURN_WALLET_KEY = "burn-wallet";

//IPFS
// const IPFS_URL_PREFIX = "https://ipfs.io"
const IPFS_URL_PREFIX = "https://kadcarsgateway.myfilebase.com";
const USER_WEBP_URLS = "USER_WEBP_URLS";
const USER_GLB_URLS = "USER_GLB_URLS";
const ALL_WEBP_URLS = "ALL_WEBP_URLS";

//NFT Models
const KADCAR_NFT_OPTIONS = [
  // { value: 'K1', label: 'K1' },
  { value: "K2", label: "K:2" },
];

//Request response constants
const DEFAULT_REQUEST_HEADERS = { "Content-Type": "application/json" };
const POST_METHOD = "POST";
const GET_METHOD = "GET";

//Guards
// const ACCOUNT_GUARD = "";

//Contract constants
const KADCAR_NFT_COLLECTION = "k2";
// const KADCAR_TEST_COLLECTION = "k:2";
const KADCAR_TEST_COLLECTION = "k2:final";
const KADCARS_FACTORY = "kadcars-factory";
const ISOKO_NAMESPACE = "n_f1c962776331c4773136dc1587a8355c9957eae1";
// const ISOKO_CONTRACTS = "free.isoko-orchestrator";
const ISOKO_CONTRACTS = "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator";
const ISOKO_ORCHESTRATOR_V2 = "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator-v2";
const ISOKO_COLLECTION_POLICY = "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-collection-policy-v1";

//Kadena signing api
const KADENA_SIGNING_API_URL = "http://localhost:9467";

//Social URLs
const DISCORD_URL = "https://discord.gg/PPX9CupYZT";
const MEDIUM_URL = "https://medium.com/@kadcarsnft";
const TWITTER_URL = "https://twitter.com/kadcarsnft";
// const KADCARS_URL = "https://kadcarsnft.app.runonflux.io/";
const MUSTANG_KNIGHTS_URL = "https://www.instagram.com/mustangknights/";
const FLUX_LABS_URL =
  "https://runonflux.io/fluxlabs.html?utm_campaign=%20Weekly%20Kadena%20Ecosystem%20Update&utm_medium=email&utm_source=Revue%20newsletter";

//Admin
const ADMIN_ADDRESS =
  "k:ccf45d4b9e7a05b1f8ae03e362fac9502610d239191a3215774c5251a662c1eb";
const NEW_ADMIN_ADDRESS =
  "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const ISOKO_ADMIN_ADDRESS =
  "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS =
  "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS =
  "k:1353c9b2235b8788cfecd567aaa6c7d4b06df2ca4de58e9343dd1131c1364451";
const ISOKO_QUICKBUY_FEE_COLLECTOR_ADDRESS =
  "k:45b39ca9e2b3cb852f474e2f7ed9681b634ac184357a2b9c63ac79e64c62372c";
// const NEW_ADMIN_ADDRESS = "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5";
const WALLET_CONNECT_PROJECT_ID = "dd84c0291840fd2d29610dac38d39bad";

//Screen names
const SCREEN_NAMES = {
  MY_KADCARS: "MY_KADCARS",
  ALL_KADCARS: "ALL_KADCARS",
  MINT_KADCAR: "MINT_KADCAR",
  KADCAR_PREVIEW: "KADCAR_PREVIEW",
};

//REGEX
const REGEX_FOR_NFT_ID = ".*([0-9]+:[0-9]+).*";

//Time
const S_TO_MS_MULTIPLIER = 1000;
const DEFAULT_POLLING_TIMER = 6000;
const LONG_POLLING_TIMER = 20000;
const BLOCKS_IN_A_DAY = 2880;

//KDA API Calls
const KDA_CONNECT = "kda_connect";
const KDA_GET_CHAIN = "kda_getChain";
const KDA_DISCONNECT = "kda_disconnect";
const KDA_GET_NETWORK = "kda_getNetwork";
const KDA_CHECK_STATUS = "kda_checkStatus";
const KDA_REQUEST_SIGN = "kda_requestSign";
const KDA_QUICKSIGN = "kda_requestQuickSign";
const KDA_REQUEST_ACCOUNT = "kda_requestAccount";
const KDA_GET_SELECTED_ACCOUNT = "kda_getSelectedAccount";

//ERROR messages
const K_ACCOUNT_ONLY_ERROR = "For security, only support k: accounts";

//Google sheet field names
const SHEETS_WALLET_ADDRESS_LABEL = "Wallet address";

//Config constants
const MAINNET_CONFIG_KEY = "mainnet_config";
const TESTNET_CONFIG_KEY = "testnet_config";
const KADCARS_PROJECT_NAME_KEY = "kadcars_nft";
const MINT_START_TIME_KEY = "mint_start_time";
const MINT_END_TIME_KEY = "mint_end_time";
const MINT_AMOUNT_LIMIT_KEY = "mint_amount_limit";
const NFT_SUPPLY_KEY = "nft_supply";
const MINT_PHASES_KEY = "mint_phases";
const CHAIN_ID_KEY = "chain_id";
const BASE_URL_KEY = "base_url";
const COLLECTION_NAME_KEY = "collection-name";
const COLLECTION_TRAITS_KEY = "collection_traits";
const COLLECTION_TRAIT_DISTRIBUTION_KEY = "collection_trait_distribution";
const COLLECTION_HAS_MARKETPLACE = "has_marketplace";
const COLLECTION_PROJECT_DESCRIPTION_SUMMARY = "project_description_summary";
const COLLECTION_STANDARD_KEY = "standard";
const COLLECTION_IMAGE_KEY = "collection_thumbnail_image";
const ROYALTY_INFO_KEY = "royalty_info";
const POLICY_INFO_KEY = "policy-info";

//Company Names
const KADCARS_NFT = "kadcars_nft";
const KITTY_KAD = "kitty_kad_nfts";
const ARKADE_BULLS = "arkade_bulls";
const BOXING_BADGER = "boxing_badger";
const ISLANDS_OF_DAMAYA = "islands_of_damaya";
const SECRET_GARDEN_OF_KADENA = "secret_garden_of_kadena";
const KADENA_SKELLIES = "kadena_skellies";
const BATTLE_HEROES = "sgk_battle_heroes";
const KDA_PUNKS = "kda_punks";
const PACT_RATS = "pact_rats";
const ISOKO_TEST = "isoko_test_collections";
const ISOKO_TEST_STACKED = "isoko_test_stacked_collections";
const KAWAII_K9S = "kawaii_k9s";
const KISHU_KEN = "kishu_ken"
const LAZY_APES = "lazy_apes"

//Project names
const KITTY_KADS_PROJECT_NAME_KEY = "kitty_kad_nfts";
const BOXING_BADGER_PROJECT_NAME_KEY = "boxing_badger";
const ARKADE_90S_BULLS_PROJECT_NAME_KEY = "arkade_90s_bulls";
const ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY = "pit_bull_bushido";
const SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY = "secret_garden_of_kadena";
const SECRET_GARDEN_OF_KADENA_GEN_0_PROJECT_NAME_KEY = "secret_garden_of_kadena_gen_0";
const KADENA_SKELLIES_PROJECT_NAME_KEY = "kadena_skellies";
const BATTLE_HEROES_PROJECT_NAME_KEY = "sgk_battle_heroes";
const ARKADE_80S_BULLS_PROJECT_NAME_KEY = "arkade_80s_bulls";
const ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY = "arkade_brawler_bears";
const KDA_PUNKS_PROJECT_NAME_KEY = "kda_punks";
const PACT_RATS_PROJECT_NAME_KEY = "pact_rats";
const ISOKO_TEST_COLLECTION_NAME_KEY = "isoko_test_collection";
const ISOKO_TEST_STACKED_COLLECTION_NAME_KEY = "isoko_test_stacked_collection";
const SGK_WEAPONS_NAME_KEY = "sgk_weapons";
const KAWAII_K9S_PROJECT_NAME_KEY = "kawaii_k9s";
const KISHU_KEN_PROJECT_NAME_KEY = "kishu_ken";
const LAZY_APES_PROJECT_NAME_KEY = "lazy_apes";

//XChain constants
const CHAIN_FUNDS_MAP_KEY = "CHAIN_FUNDS_MAP";
const SHOW_EZ_BUY_PROGRESS_BAR = "SHOW_EZ_BUY_PROGRESS_BAR";
const EZ_BUY_TRANSACTION_STEP_DATA = "EZ_BAR_TRANSACTION_STEP_DATA";

//Wallet names
const ECKO_WALLET_NAME = "eckoWALLET";
const KOALA_WALLET_NAME = "koala wallet";
const ZELCORE_WALLET_NAME = "zelcore";
const CHAINWEAVER_WALLET_NAME = "chainweaver";
const WALLET_CONNECT_V2 = "wallet connect v2";

//Marketplace constants
const MARMALADE_TYPE = "marmalade";
const MARMALADE_V2_TYPE = "marmalade-v2";
const BUTTER_TYPE = "butter";
const STACKED_TYPE = "stacked";

//Heroku Proxy
const HEROKU_PROXY = "https://obscure-reef-84378-2c26a0484747.herokuapp.com/";

//Admin Control Keys
const KILL_SWITCH = "kill_switch";

export {
  BURN_WALLET_KEY,
  POLICY_INFO_KEY,
  CONNECTED_WALLET_TYPE,
  MARMALADE_V2_TYPE,
  MARMALADE_TYPE,
  STACKED_TYPE,
  BUTTER_TYPE,
  EZ_BUY_TRANSACTION_STEP_DATA,
  SIGNED_QUICKBUY_COMMANDS,
  QUICKBUY_PROVIDER_TX_FEE,
  GAS_STATION,
  ISOKO_GAS_STATION,
  ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS,
  ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
  ISOKO_CONTRACTS,
  ECKO_WALLET_NAME,
  KOALA_WALLET_NAME,
  ZELCORE_WALLET_NAME,
  CHAINWEAVER_WALLET_NAME,
  MAINNET_CONFIG_KEY,
  TESTNET_CONFIG_KEY,
  DEFAULT_GAS_LIMIT,
  S_TO_MS_MULTIPLIER,
  POLL_INTERVAL_S,
  KDA_CONNECT,
  SCREEN_NAMES,
  KDA_GET_CHAIN,
  KDA_DISCONNECT,
  KDA_GET_NETWORK,
  KDA_CHECK_STATUS,
  KDA_REQUEST_SIGN,
  KDA_REQUEST_ACCOUNT,
  KDA_GET_SELECTED_ACCOUNT,
  DEFAULT_CHAIN_ID,
  DEFAULT_GAS_PRICE,
  LOCAL_ACCOUNT_KEY,
  LOCAL_ACCOUNT_DATA_KEY,
  NETWORK_ID,
  LOCAL_CHAIN_ID,
  MAINNET_NETWORK_ID,
  TESTNET_NETWORK_ID,
  USER_KADCAR_GALLERY_LABEL,
  ALL_KADCAR_GALLERY_LABEL,
  KADCAR_NFT_COLLECTION,
  ADMIN_ADDRESS,
  IS_ECKO_WALLET_KEY,
  X_WALLET_INSTALLED,
  DEFAULT_REQUEST_HEADERS,
  POST_METHOD,
  GET_METHOD,
  KADCAR_NFT_OPTIONS,
  REGEX_FOR_NFT_ID,
  TWITTER_URL,
  DISCORD_URL,
  FLUX_LABS_URL,
  MEDIUM_URL,
  MUSTANG_KNIGHTS_URL,
  K_ACCOUNT_ONLY_ERROR,
  SHEETS_WALLET_ADDRESS_LABEL,
  KADCARS_FACTORY,
  NEW_ADMIN_ADDRESS,
  IPFS_URL_PREFIX,
  ALL_WEBP_URLS,
  USER_WEBP_URLS,
  USER_GLB_URLS,
  DEFAULT_POLLING_TIMER,
  KADCAR_TEST_COLLECTION,
  KADCARS_PROJECT_NAME_KEY,
  MINT_START_TIME_KEY,
  MINT_END_TIME_KEY,
  MINT_AMOUNT_LIMIT_KEY,
  NFT_SUPPLY_KEY,
  CHAIN_ID_KEY,
  CHAIN_FUNDS_MAP_KEY,
  MINT_PHASES_KEY,
  LONG_POLLING_TIMER,
  SHOW_TOTAL_BALANCE,
  WALLET_CONNECT_V2,
  KITTY_KADS_PROJECT_NAME_KEY,
  BOXING_BADGER_PROJECT_NAME_KEY,
  ARKADE_90S_BULLS_PROJECT_NAME_KEY,
  ISLANDS_OF_DAMAYA_PROJECT_NAME_KEY,
  SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
  SECRET_GARDEN_OF_KADENA_GEN_0_PROJECT_NAME_KEY,
  KADENA_SKELLIES_PROJECT_NAME_KEY,
  KADCARS_NFT,
  KITTY_KAD,
  KADENA_SKELLIES,
  ARKADE_BULLS,
  BOXING_BADGER,
  ISLANDS_OF_DAMAYA,
  SECRET_GARDEN_OF_KADENA,
  BASE_URL_KEY,
  COLLECTION_NAME_KEY,
  COLLECTION_TRAITS_KEY,
  FEE_PERCENTAGE,
  ISOKO_ADMIN_ADDRESS,
  COLLECTION_TRAIT_DISTRIBUTION_KEY,
  DEFAULT_GAS_PRICE_X_CHAIN_EXEC,
  DEFAULT_GAS_LIMIT_X_CHAIN_EXEC,
  KADENA_SIGNING_API_URL,
  KDA_QUICKSIGN,
  XCHAIN_COMMANDS,
  DEFAULT_GAS_PRICE_X_CHAIN_CONT,
  DEFAULT_GAS_LIMIT_X_CHAIN_CONT,
  LOCAL_GAS_STATION_KEY,
  ISOKO_QUICKBUY_FEE_COLLECTOR_ADDRESS,
  SHOW_EZ_BUY_PROGRESS_BAR,
  DEFAULT_GAS_LIMIT_ISOKO_GAS_STATION,
  DEFAULT_GAS_PRICE_ISOKO_GAS_STATION,
  ISOKO_NAMESPACE,
  COLLECTION_HAS_MARKETPLACE,
  WALLET_CONNECT_PROJECT_ID,
  COLLECTION_PROJECT_DESCRIPTION_SUMMARY,
  COLLECTION_STANDARD_KEY,
  COLLECTION_IMAGE_KEY,
  ISOKO_CHAINWEB_IP,
  ISOKO_CHAINWEB_PORT,
  HEROKU_PROXY,
  ROYALTY_INFO_KEY,
  ISOKO_MARKETPLACE_FEE_PERCENTAGE,
  BLOCKS_IN_A_DAY,
  BATTLE_HEROES,
  BATTLE_HEROES_PROJECT_NAME_KEY,
  ARKADE_80S_BULLS_PROJECT_NAME_KEY,
  ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY,
  KDA_PUNKS,
  KDA_PUNKS_PROJECT_NAME_KEY,
  PACT_RATS,
  PACT_RATS_PROJECT_NAME_KEY,
  PLATFORM,
  ISOKO,
  ATRIUM,
  PHASE_START_TIME,
  PHASE_END_TIME,
  PHASE_NAME,
  ISOKO_TEST_COLLECTION_NAME_KEY,
  ISOKO_TEST,
  ISOKO_TEST_STACKED,
  ISOKO_TEST_STACKED_COLLECTION_NAME_KEY,
  SGK_WEAPONS_NAME_KEY,
  KAWAII_K9S_PROJECT_NAME_KEY,
  KAWAII_K9S,
  KISHU_KEN,
  KISHU_KEN_PROJECT_NAME_KEY,
  LAZY_APES,
  LAZY_APES_PROJECT_NAME_KEY,
  KILL_SWITCH,
  ISOKO_ORCHESTRATOR_V2,
  ISOKO_COLLECTION_POLICY,
  ISOKO_PLATFORM
};

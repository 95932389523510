import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import InProgressTask from "./InProgressTask";
import SuccessfulTask from "./SuccessfulTask";
import FailedTask from "./FailedTask";
import TaskOpener from "./TaskOpener";

const navData = [
    { id: 1, label: "In progress", value: 1, color: "#AEC9F2" },
    { id: 2, label: "Successful", value: 2, color: "#AAD5C0" },
    { id: 3, label: "Failed", value: 3, color: "#F5AAAA" },
];

const TaskTracker = () => {
    const [showTask, setShowTask] = useState(false);

    const [navValue, setNavValue] = useState(1);

    const handleNavChange = (value) => {
        setNavValue(value);
    };
    const handleShowTask = () => {
        setShowTask(!showTask);
    };
    return (
        <Box>
            <TaskOpener showTask={showTask} handleShowTask={handleShowTask} />
            <Box
                display={showTask ? "block" : "none"}
                pos="fixed"
                w="100%"
                maxW="352px"
                maxH="90vh"
                h="90vh"
                right="0"
                top="35px"
                bgColor={"#20201D"}
                py="16px"
                px="12px"
                roundedTop="8px"
                overflow={"auto"}
            >
                <Box>
                    <Icon
                        as={BsChevronRight}
                        boxSize={"24px"}
                        cursor="pointer"
                        onClick={handleShowTask}
                    />
                </Box>
                <Flex
                    gap="12px"
                    mt="16px"
                    align="center"
                    justify={"space-between"}
                >
                    {navData.map((item) => {
                        return (
                            <TrackerNav
                                key={item?.id}
                                item={item}
                                handleNavChange={() =>
                                    handleNavChange(item?.value)
                                }
                                navValue={navValue}
                            />
                        );
                    })}
                </Flex>

                <Box mt="16px" py="8px" overflowY={"auto"}>
                    {navValue === 1 && <InProgressTask />}
                    {navValue === 2 && <SuccessfulTask />}
                    {navValue === 3 && <FailedTask />}
                </Box>
            </Box>
        </Box>
    );
};

export default TaskTracker;

const TrackerNav = ({ item, navValue, handleNavChange }) => {
    return (
        <Text
            px="8px"
            py="14px"
            flexShrink={0}
            fontSize="14px"
            fontWeight={700}
            cursor="pointer"
            color={item?.value === navValue ? item?.color : "white"}
            borderBottom={"1px"}
            borderBottomColor={
                item?.value === navValue ? item?.color : "transparent"
            }
            onClick={() => handleNavChange(item?.id)}
        >
            {item?.label} ({item?.value})
        </Text>
    );
};

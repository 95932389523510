import {
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Text,
    Flex,
} from "@chakra-ui/react";

const ComingSoonComponent = ({ children, direction }) => {
    return (
        <Popover
            trigger="hover"
            arrowShadowColor="#BC3CF0"
            placement={direction ? direction : "top"}
            border="none"
        >
            <PopoverTrigger>
                {/* <Text
                    style={{ cursor: "pointer" }}
                    // onClick={() => navigate("/marketplace")}
                    color="gray.500"
                    fontWeight="900"
                >
                    Marketplace
                </Text> */}
                {children}
            </PopoverTrigger>
            <PopoverContent bgColor="transparent" border="none">
                <PopoverArrow bgColor="#BC3CF0" color="#BC3CF0" />
                <Flex
                    w="100%"
                    h="100%"
                    align="center"
                    justify="center"
                    bgColor={"#BC3CF0"}
                    rounded="10px"
                >
                    <Text as="div" textAlign="center" display="block" py="4px">
                        {" "}
                        Coming Soon
                    </Text>
                </Flex>
            </PopoverContent>
        </Popover>
    );
};

export { ComingSoonComponent };

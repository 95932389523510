import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";
import CarSample from "../assets/images/dummyCar.svg";
import { IoCloseOutline } from "react-icons/io5";

const CartBoxItem = ({ item }) => {
    return (
        <Flex align={"center"} justify={"space-between"} gap="20px">
            <Box flexShrink={0}>
                <Image
                    src={CarSample}
                    alt={"SampleImage"}
                    w="72px"
                    h="72px"
                    objectFit={"cover"}
                />
            </Box>

            <Box>
                <Text fontWeight={"900"}>22</Text>
                <Text fontSize="16px" fontWeight={"700"}>
                    Kadcar racing collection
                </Text>
                <Text fontSize="14px" fontWeight={"500"}>
                    {" "}
                    total fees + royalties 2 KDA (4%){" "}
                </Text>
            </Box>

            <Box textAlign={"right"}>
                <Icon as={IoCloseOutline} fontSize="16px" />
                <Text fontSize="20px" fontWeight={"500"} mt="10px">
                    43 KDA
                </Text>
            </Box>
        </Flex>
    );
};

export default CartBoxItem;

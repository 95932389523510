import React from "react";
import ScreenSize from "../layouts/ScreenSize";
import dummyCar from "../../src/assets/images/dummyCar.svg";
import LaunchPadCard from "../Cards/LaunchPadCard";
import { useNavigate } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";

const LiveMint = ({ projects, handleCollectionCardClick }) => {
    const navigate = useNavigate();

    function handleCollectionCardClick(projectConfig) {
        navigate("/mint/" + projectConfig["policy-info"]["project-name"] + "-" + projectConfig["policy-info"]["collection-name"] + "/");
    }

    return (
        <ScreenSize>
            <div className="Upcoming">
                <Text fontSize="36px" fontWeight="700">
                    Live Mints
                </Text>

                <Box mt="30px" className="upcomingCards">
                    {projects
                        ? projects.map((projectObject, index) => {
                            let projectEntry = {};
                            return (
                                <LaunchPadCard
                                    type="live"
                                    key={index}
                                    clickable={true}
                                    projectConfig={projectObject.projectConfig}
                                    cardCallback={handleCollectionCardClick}
                                />
                            );
                        })
                        : null}
                </Box>
            </div>
        </ScreenSize>
    );
};

export default LiveMint;

import {
    Box,
    Divider,
    Flex,
    Text,
    Switch,
    Icon,
    Collapse,
    Checkbox,
    Spinner,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
} from "@chakra-ui/react";
import { useContext, useState, useEffect, memo } from "react";
import { BsChevronDown } from "react-icons/bs";
import { IoMdFunnel } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { NftCardGroup } from "../Cards/NftCardGroup";
import Footer from "../CommonComponents/Footer";
import ScreenSize from "../layouts/ScreenSize";
import MarketplaceCollectionHero from "./MarketplaceCollectionHero";
import CartSummary from "./CartSummary";
import CartBox from "./CartBox";
import { checkIfNullOrUndefined, trySaveLocal } from "../utils/utils";
import { FiChevronLeft } from "react-icons/fi";
import CollectionPagination from "../Pagination/CollectionPagination";
import { MarketplaceContext } from "./MarketplaceContextProvider";
import { BUTTER_TYPE, MARMALADE_TYPE, STACKED_TYPE } from "../utils/Constants";
import { PactContext } from "../pact/PactContextProvider";

const Marketplace = () => {
    const { account } = useContext(PactContext);
    const marketplaceContext = useContext(MarketplaceContext);
    const [currentBatch, setCurrentBatch] = useState(0);
    const [listedNftToggle, setListedNftToggle] = useState(false);
    const [userNftToggle, setUserNftToggle] = useState(false);
    const [traitsToFilterBy, setTraitsToFilterBy] = useState(null);
    const [showFilter, setShowFilter] = useState(true);
    const [sortOrientation, setSortOrientation] = useState("LOW");
    const [filterDropdownLabel, setFilterDropdownLabel] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (checkIfNullOrUndefined(account)) {
            marketplaceContext.setUserNfts([]);
            marketplaceContext.setFilteredNfts([]);
            handleListedSwitchToggle(false);
            handleUserSwitchToggle(false);
        }
    }, [account]);

    function updateFilteredCollection(traitList) {
        let originalCopy = [...marketplaceContext.collectionNfts];
        let filteredList = [];

        setCurrentBatch(0);

        if (Object.entries(traitList).length === 0) {
            filteredList = originalCopy;
        } else {
            for (let i = 0; i < originalCopy.length; i++) {
                let matches = [];
                Object.entries(traitList).forEach(([attribute, values]) => {
                    let currMatches = values.filter((value) => {
                        let includes = [];
                        if (marketplaceContext.collectionMetadata["standard"] === MARMALADE_TYPE) {
                            includes = originalCopy[i]["nft-metadata"]["datum"]["datum"]["attributes"].filter(
                                (att) =>
                                    att["trait_type"] === attribute &&
                                    att["value"] === value
                            );
                        } else if (
                            marketplaceContext.collectionMetadata[
                            "standard"
                            ] === BUTTER_TYPE
                        ) {
                            includes = originalCopy[i]["attributes"].filter(
                                (att) =>
                                    att["trait_type"] === attribute &&
                                    att["value"] === value
                            );
                        }
                        return includes.length > 0;
                    });
                    matches = [...matches, ...currMatches];
                });
                if (matches.length === Object.entries(traitList).length) {
                    filteredList.push(originalCopy[i]);
                }
            }
        }
        marketplaceContext.setFilteredNfts(filteredList);
        marketplaceContext.setNftsToDisplay(filteredList);
        marketplaceContext.calculatePaginationArray(filteredList);
    }

    function handleUserSwitchToggle(switchValue) {
        setCurrentBatch(0);
        setUserNftToggle(switchValue);
        let nfts = [];
        let filteredList = [];

        if (switchValue) {
            nfts = [...marketplaceContext.userNfts];
        } else {
            nfts = [...marketplaceContext.collectionNfts];
        }
        filteredList = nfts;

        if (listedNftToggle) {
            filteredList = filterListed(nfts);
        }

        let filteredSorted = marketplaceContext.sortNfts(sortOrientation, filteredList);

        marketplaceContext.setNftsToDisplay(filteredSorted);
        marketplaceContext.calculatePaginationArray(filteredSorted);
    }

    function handleListedSwitchToggle(switchValue) {
        setCurrentBatch(0);
        setListedNftToggle(switchValue);
        let nfts = [];
        let filteredList = [];

        if (userNftToggle) {
            nfts = [...marketplaceContext.userNfts];
        } else {
            nfts = [...marketplaceContext.collectionNfts];
        }
        filteredList = nfts;

        if (switchValue) {
            filteredList = filterListed(nfts);
        } else {
            filteredList = nfts;
        }

        let filteredSorted = marketplaceContext.sortNfts(sortOrientation, filteredList);
        marketplaceContext.setNftsToDisplay(filteredSorted);
        marketplaceContext.calculatePaginationArray(filteredSorted);
    }

    function filterListed(nftList) {
        let listedNfts = [];

        nftList.forEach((nft) => {
            if (nft["status"] === "listed" && nft["listing-details"]) {
                listedNfts.push(nft);
            }
        });

        return listedNfts;
    }

    function clearFilters() {
        marketplaceContext.setFilteredNfts(null);
        setTraitsToFilterBy(null);
        setCurrentBatch(0);
    }

    function clearCollectionFromLocalStorage() {
        marketplaceContext.clearContext();
        trySaveLocal("current-collection-nfts", null);
        navigate(-1);
    }

    function handleNftCardClick(nftId) {
        clearFilters();
        if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
            navigate(`/marketplace/group/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
        } else {
            navigate(`/marketplace/${marketplaceContext.projectId}/${nftId}`);
        }
    }

    return (
        <Box bgColor="#0A0A0A" className="latestDesign">
            <CartSummary />
            <ScreenSize>
                <Flex
                    align={"center"}
                    className="backBtn"
                    style={{ cursor: "pointer" }}
                    my={["20px", null, "20px", "20px"]}
                    onClick={clearCollectionFromLocalStorage}
                >
                    <Icon
                        as={FiChevronLeft}
                        boxSize="1.25rem"
                        display={"inline-block"}
                    />
                    <Text>Explore Other Collections</Text>
                </Flex>

                <MarketplaceCollectionHero
                    collectionMetadata={marketplaceContext.collectionMetadata}
                />

                <Divider mb="44px" border="1px" borderColor="#5E1E78" />
                {/* Fliter code */}
                <Box mb="26px">
                    <Flex align="center" justify="space-between" gap="20px">
                        <Flex
                            width="50px"
                            h="50px"
                            bgColor={
                                showFilter
                                    ? "rgba(189, 61, 240, 0.3)"
                                    : "transparent"
                            }
                            align="center"
                            justify="center"
                            rounded="8px"
                            cursor="pointer"
                            onClick={() => setShowFilter(!showFilter)}
                            as="button"
                        >
                            <Icon as={IoMdFunnel} size="30px" />
                        </Flex>

                        <Box>
                            <Text>
                                {marketplaceContext.nftsToDisplay &&
                                    marketplaceContext.nftsToDisplay
                                        .length}{" "}
                                result
                                {marketplaceContext.nftsToDisplay?.length > 1
                                    ? "s"
                                    : null}
                            </Text>
                        </Box>
                        <Box flex="1" maxw="715px">
                            {/* <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                        <HiMagnifyingGlass color="#757575" />
                                    }
                                />
                                <Input
                                    type="text"
                                    placeholder="Search by NFT ID using #[NFT ID] or NFT attributes separated by commas"
                                    color="white"
                                    borderColor="rgba(189, 61, 240, 0.3)"
                                    maxw="715px"
                                    _placeholder={{ color: "#757575" }}
                                    outline="none"
                                    onChange={(e) =>
                                        filterCollectionWithSearchWords(
                                            e.target.value
                                        )
                                    }
                                />
                            </InputGroup> */}
                        </Box>

                        <Box>
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rightIcon={<BsChevronDown />}
                                    w="100%"
                                    maxW="211px"
                                    bgColor="transparent"
                                    // border="1px"
                                    rounded="1rem"
                                    boxShadow={
                                        "0px 1px 2px 0px rgba(0, 0, 0, 0.12)"
                                    }
                                    borderColor="rgba(189, 61, 240, 0.3)"
                                    px="8px"
                                    py="12px"
                                    _hover={{ bgColor: "#424242" }}
                                    _active={{}}
                                    _focus={{}}
                                    className="dropDown"
                                >
                                    {filterDropdownLabel
                                        ? filterDropdownLabel
                                        : "Sort NFTs"}
                                </MenuButton>
                                <MenuList bgColor="#000">
                                    <MenuItem
                                        className="menuItem"
                                        bgColor="transparent"
                                        onClick={() => {
                                            let nfts = marketplaceContext.sortNfts(
                                                "LOW",
                                                marketplaceContext.nftsToDisplay
                                            );
                                            marketplaceContext.setNftsToDisplay(
                                                nfts
                                            );
                                            setFilterDropdownLabel(
                                                "Price: low to high"
                                            );
                                            setSortOrientation("LOW");
                                        }}
                                    >
                                        {" "}
                                        Price: low to high
                                    </MenuItem>
                                    <MenuItem
                                        className="menuItem"
                                        bgColor="transparent"
                                        onClick={() => {
                                            let nfts = marketplaceContext.sortNfts(
                                                "HIGH",
                                                marketplaceContext.nftsToDisplay
                                            );
                                            marketplaceContext.setNftsToDisplay(
                                                nfts
                                            );
                                            setFilterDropdownLabel(
                                                "Price: high to low"
                                            );
                                            setSortOrientation("HIGH");
                                        }}
                                    >
                                        {" "}
                                        Price: high to low
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Flex>
                </Box>

                <Flex gap="17px">
                    <>
                        <CartBox />
                    </>
                    {true ? (
                        <Box
                            w={showFilter ? "100%" : "0%"}
                            opacity={showFilter ? 1 : 0}
                            maxW="320px"
                            pt="0px"
                            transition={"ease-in-out"}
                            transitionDuration={".51s"}
                        >
                            {/* Cart mode  */}
                            {/* 
                            <Box mt="27px" mb="32px">
                                <CartMode />
                            </Box> 
                            */}
                            <Box mb="32px" p="6px 16px">
                                <Text fontSize="20px" fontWeight="700">
                                    Status
                                </Text>
                                <FilterToggle label={"Show only Listed NFTs"} toggleCallback={handleListedSwitchToggle} toggleState={listedNftToggle} />
                                <FilterToggle label={"Show only My NFTs"} toggleCallback={handleUserSwitchToggle} toggleState={userNftToggle} />
                            </Box>

                            <Box>
                                <Text
                                    fontSize="20px"
                                    fontWeight="700"
                                    p="6px 16px"
                                >
                                    Collection Traits
                                </Text>
                            </Box>
                            {marketplaceContext.collectionMetadata &&
                                marketplaceContext.collectionMetadata["collection_traits"]?.map((item, i) => {
                                    return (
                                        <SideFilter
                                            key={i}
                                            traitName={item}
                                            traitsToFilterBy={traitsToFilterBy}
                                            setTraitsToFilterBy={setTraitsToFilterBy}
                                            updateCollectionCallback={updateFilteredCollection}
                                            traitOptions={marketplaceContext.collectionMetadata["collection_trait_map"][item]}
                                        />
                                    );
                                })}
                        </Box>
                    ) : (
                        ""
                    )}

                    <Box w="100%">
                        <Text fontSize="36px" fontWeight="700" mb="30px">
                            Collection
                        </Text>
                        {marketplaceContext.loading && (
                            <Flex align="center" justify="center">
                                <Spinner />
                            </Flex>
                        )}
                        {/* {marketplaceContext.nftsToDisplay && marketplaceContext.nftsToDisplay.length === 0 && (
                            <Flex align="center" justify="center">
                                <Text fontSize="20px" fontWeight="700">
                                    No NFTs found
                                </Text>
                            </Flex>
                        )} */}
                        {marketplaceContext.collectionMetadata &&
                            !marketplaceContext.loading && (
                                <NftCardGroup
                                    currentBatch={currentBatch}
                                    currentHeight={
                                        marketplaceContext.currentHeight
                                    }
                                    collectionName={
                                        marketplaceContext.collectionMetadata[
                                        "collection-name"
                                        ]
                                    }
                                    nftsInCollection={
                                        !checkIfNullOrUndefined(marketplaceContext.nftsToDisplay) ?
                                            marketplaceContext.nftsToDisplay :
                                            !checkIfNullOrUndefined(marketplaceContext.illusion) &&
                                            marketplaceContext.illusion
                                    }
                                    onClick={handleNftCardClick}
                                    limit={15}
                                />
                            )}
                        {marketplaceContext?.pageNumArray &&
                            <CollectionPagination
                                numPages={marketplaceContext?.pageNumArray}
                                setCurrentBatch={setCurrentBatch}
                                currentBatch={currentBatch}
                            />
                        }
                    </Box>
                </Flex>
            </ScreenSize>
        </Box>
    );
};

export default Marketplace;

const FilterToggle = memo(
    ({
        label,
        toggleCallback,
        toggleState
    }) => {

        return (
            <Flex
                align="center"
                justifyContent={"space-between"}
                py="8px"
            >
                <Text fontSize="16px" fontWeight="700">
                    {label}
                </Text>
                <Switch
                    checked={toggleState}
                    onChange={(e) => toggleCallback(e.target.checked)}
                />
            </Flex>
        )
    })

const SideFilter = memo(
    ({
        traitName,
        traitOptions,
        updateCollectionCallback,
        traitsToFilterBy,
        setTraitsToFilterBy,
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [checkBoxStates, setCheckBoxStates] = useState(() => {
            let optionMap = {};
            Object.entries(traitOptions).map(([opt, val]) => {
                optionMap[opt] = false;
            });
            return optionMap;
        });

        function handleUpdateFilterTraitList(
            traitCategory,
            traitName,
            checked
        ) {
            let checkedStates = { ...checkBoxStates };
            let traitFilter = { ...traitsToFilterBy };

            if (checked) {
                setCheckBoxStates(traitName);
                if (traitFilter[traitCategory]) {
                    traitFilter[traitCategory] = [
                        ...traitFilter[traitCategory],
                        traitName,
                    ];
                } else {
                    traitFilter[traitCategory] = [traitName];
                }
            } else {
                if (traitFilter[traitCategory]) {
                    let index = traitFilter[traitCategory].indexOf(traitName);
                    traitFilter[traitCategory].splice(index, 1);
                    if (traitFilter[traitCategory].length === 0) {
                        delete traitFilter[traitCategory];
                    }
                }
            }
            checkedStates[traitName] = checked;

            setCheckBoxStates(checkedStates);
            setTraitsToFilterBy(traitFilter);
            updateCollectionCallback(traitFilter);
        }

        return (
            <Box>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    cursor="pointer"
                    onClick={() => setIsOpen(!isOpen)}
                    p="6px 16px"
                >
                    <Text fontSize="16px" fontWeight={600}>
                        {traitName}
                    </Text>
                    <Icon
                        as={BsChevronDown}
                        boxSize={"16px"}
                        transform={isOpen ? "rotate(180deg)" : ""}
                    />
                </Flex>
                <Divider bgColor="#5E1E78" borderColor="#5E1E78" />
                <Collapse in={isOpen} animateOpacity>
                    <Box>
                        {/* inner CheckBox  */}
                        {Object.entries(traitOptions).map(([key, value], i) => {
                            if (key !== "total") {
                                return (
                                    <Flex
                                        key={i}
                                        alignItems="center"
                                        justifyContent={"space-between"}
                                        p="6px 16px"
                                    >
                                        <Text
                                            fontSize="15px"
                                            fontWeight={"500"}
                                        >
                                            {key}
                                        </Text>
                                        <Checkbox
                                            mr="2"
                                            onChange={(e) => {
                                                handleUpdateFilterTraitList(
                                                    traitName,
                                                    key,
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    </Flex>
                                );
                            }
                        })}
                    </Box>
                </Collapse>
            </Box>
        );
    }
);

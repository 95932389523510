import {
    BOXING_BADGER_PROJECT_NAME_KEY,
    BUTTER_TYPE,
    CHAIN_ID_KEY,
    COLLECTION_HAS_MARKETPLACE,
    COLLECTION_IMAGE_KEY,
    COLLECTION_NAME_KEY,
    COLLECTION_PROJECT_DESCRIPTION_SUMMARY,
    COLLECTION_STANDARD_KEY,
    COLLECTION_TRAITS_KEY,
    COLLECTION_TRAIT_DISTRIBUTION_KEY,
    FEE_PERCENTAGE,
    KADCARS_PROJECT_NAME_KEY,
    MARMALADE_TYPE,
    MINT_AMOUNT_LIMIT_KEY,
    MINT_PHASES_KEY,
    NFT_SUPPLY_KEY,
    POLICY_INFO_KEY,
    ROYALTY_INFO_KEY,
    STACKED_TYPE,
} from "../utils/Constants";
import { checkIfNullOrUndefined, getAmountByPercentage } from "../utils/utils";

import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";
import { PactContext } from "../pact/PactContextProvider";
import { firestoreQueryCollection } from "../Firestore/FirestoreService";
import { getBlockHeightForChain } from "../pact/PactUtils";
import { getCollectionNftsMetadata } from "./NftMetadataProvider";
import { useCollectionProviderFactory } from "./CollectionProviderFactory";
import { useContext } from "react";
import useMintProviderFactory from "../Mint/MintProviderFactory";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";

function useCollectionProvider(projectName, collectionName) {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const {
        burnNftMap,
        getNetworkSpecificParameterFromProjectConfig,
        getParameterFromProjectConfig,
        getCollectionConfig,
        getParameterFromProjectConfigForChildCollection
    } = useContext(IsokoCollectionsContext);
    const collectionConfig = getCollectionConfig(projectName, collectionName);
    const collectionProvider = useCollectionProviderFactory(collectionName, projectName, collectionConfig);
    const mintProvider = useMintProviderFactory(projectName, collectionName);
    const policyInfo = getCollectionPolicyInfo(collectionConfig["standard"]);
    const royaltyInfo = getParameterFromProjectConfig(projectName, collectionName, ROYALTY_INFO_KEY);

    function getMintProvider() {
        return mintProvider;
    }

    function getConfig() {
        return collectionConfig;
    }

    function getBurnNftsMap() {
        if (!checkIfNullOrUndefined(burnNftMap[collectionName])) {
            return burnNftMap[collectionName];
        } else {
            return []; //TODO: this is where we handle the case where collection is not from initial fetch
        }
    }

    function getChain() {
        let chainId = getNetworkSpecificParameterFromProjectConfig(
            projectName,
            collectionName,
            CHAIN_ID_KEY
        );

        return chainId;
    }

    const getTotalSupply = () => {
        let totalSupply = getNetworkSpecificParameterFromProjectConfig(
            projectName,
            collectionName,
            NFT_SUPPLY_KEY
        );

        return totalSupply;
    };

    function getNonMintedTokens() { }

    function getNftAmountLimit() {
        let amountLimit = getNetworkSpecificParameterFromProjectConfig(projectName, collectionName, MINT_AMOUNT_LIMIT_KEY);

        return amountLimit;
    }

    function getCollectionName() {
        let name = getParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_NAME_KEY
        );

        return name;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            projectName,
            collectionName,
            MINT_PHASES_KEY
        );

        return mintPhases;
    }

    function getCollectionDescriptionSummary(
        projectName,
        collectionName
    ) {
        let descriptionSummary = getNetworkSpecificParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_PROJECT_DESCRIPTION_SUMMARY
        );

        return descriptionSummary;
    }

    async function getNftImage(nftId) {
        let image = collectionProvider.getNftImage(nftId);
        return image;
    }

    async function getNftMap() {
        let nftMap = await collectionProvider.getNftMap();

        return nftMap;
    }

    function getCollectionStandard() {
        let standard = getParameterFromProjectConfig(projectName, collectionName, COLLECTION_STANDARD_KEY);

        return standard;
    }

    function getNftFieldsForId(collectionName, fieldsList, nftId) {
        //Query Firestore DB here for nft fields
        let nftData = null;

        if (nftData) {
        }

        return nftData;
    }

    function getNftsForUser() { }

    function getAccountWhitelistData() { }

    function getPhaseParticipationRequirement() { }

    function getCollectionTraits() {
        let traitsList = getNetworkSpecificParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_TRAITS_KEY
        );

        return traitsList;
    }

    function getTraitDistribution(trait, traitValue, subCollection = null) {
        let traitCountMap = subCollection ?
            getParameterFromProjectConfigForChildCollection(
                projectName,
                collectionName,
                subCollection,
                COLLECTION_TRAIT_DISTRIBUTION_KEY
            )
            : getParameterFromProjectConfig(
                projectName,
                collectionName,
                COLLECTION_TRAIT_DISTRIBUTION_KEY
            );

        let totalSupply = subCollection ? getParameterFromProjectConfigForChildCollection(
            projectName,
            collectionName,
            subCollection,
            NFT_SUPPLY_KEY
        ) : getTotalSupply();

        let traitFrequency = (
            (traitCountMap[trait][traitValue] / totalSupply) *
            100.0
        ).toFixed(2);

        return traitFrequency;
    }

    function formatNftTraits(nftTraits) {
        let formattedTraits = {}
        let traitsList = getCollectionTraits();

        nftTraits?.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(traitObject["trait_type"], traitObject["value"]);

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency
            };
        });

        return formattedTraits;
    }

    function getTraitCountMap() {
        let traitCountMap = getParameterFromProjectConfig(projectName, collectionName, COLLECTION_TRAIT_DISTRIBUTION_KEY);

        return traitCountMap;
    }

    function getNftTraitStats(nftAttributes, subCollection = null) {
        let formattedTraits = {};

        nftAttributes.forEach((traitObject) => {
            let traitFrequency = getTraitDistribution(
                traitObject["trait_type"],
                traitObject["value"],
                subCollection
            );

            formattedTraits[traitObject["trait_type"]] = {
                name: traitObject["value"],
                frequency: traitFrequency,
            };
        });

        return formattedTraits;
    }

    function getMintPriceForNextPhase() {
        let mintPhaseForCurrentNetwork = getNetworkSpecificParameterFromProjectConfig(projectName, collectionName, MINT_PHASES_KEY);
        let currentTime = Date.now();
        let earliestPhaseStart = null;
        let earliestPhaseData = null;

        for (const [phaseKey, phaseData] of Object.entries(
            mintPhaseForCurrentNetwork
        )) {
            if (!earliestPhaseStart) {
                earliestPhaseStart = phaseData.start_time;
                continue;
            }

            if (earliestPhaseStart > phaseData.start_time) {
                earliestPhaseStart = phaseData.start_time;
                earliestPhaseData = mintPhaseForCurrentNetwork[phaseKey];
            }
        }

        if (currentTime < earliestPhaseStart) {
            return earliestPhaseData;
        }

        for (const [phaseKey, phaseData] of Object.entries(
            mintPhaseForCurrentNetwork
        )) {
            if (
                currentTime < phaseData.start_time &&
                currentTime < phaseData.end_time
            ) {
                return mintPhaseForCurrentNetwork[phaseKey];
            }
        }
    }

    function getCollectionDescriptionSummary() {
        let descriptionSummary = getParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_PROJECT_DESCRIPTION_SUMMARY
        );

        return descriptionSummary;
    }

    function collectionHasMarketplace() {
        let hasMarketplace = getParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_HAS_MARKETPLACE
        );

        return hasMarketplace;
    }

    function getCollectionImage() {
        let collectionImage = getParameterFromProjectConfig(
            projectName,
            collectionName,
            COLLECTION_IMAGE_KEY
        );

        return collectionImage;
    }

    function getCollectionPolicyInfo() {
        let collectionPolicyInfo = getParameterFromProjectConfig(
            projectName,
            collectionName,
            POLICY_INFO_KEY
        );

        return collectionPolicyInfo;
    }

    async function getCollectionFloor(policyInfo, subCollection = null) {
        let floor = await collectionProvider.getCollectionFloor(subCollection);

        return floor;
    }

    async function getMarketplaceCollectionDetails(nftId = null, subCollectionId = null) {
        let floor = null;
        let collMetadata = {};

        collMetadata["policy-info"] = policyInfo;
        collMetadata["chain_id"] = getChain();
        collMetadata["has_marketplace"] = collectionHasMarketplace();
        collMetadata["description"] = getCollectionDescriptionSummary();
        collMetadata["standard"] = getCollectionStandard();
        collMetadata["collection_thumbnail_image"] = getCollectionImage();

        let payload = {
            "getParameterFromProjectConfigForChildCollection": getParameterFromProjectConfigForChildCollection,
            "getTotalSupply": getTotalSupply,
            "getCollectionName": getCollectionName,
            "getCollectionTraits": getCollectionTraits,
            "getTraitCountMap": getTraitCountMap,
            "mintProvider": mintProvider,
            "subCollectionId": subCollectionId,
            "nftId": nftId
        }

        collMetadata = collectionProvider.getMarketplaceCollectionDetails(payload);

        return collMetadata;
    }

    async function getNftListingData(nftId, subCollectionId = null) {
        let nftData = {};
        let royaltyTotal = 0;
        let collectionStandard = getCollectionStandard();
        let payload = {
            "getNetworkSpecificParameterFromProjectConfig": getNetworkSpecificParameterFromProjectConfig,
            "getNftTraitStats": getNftTraitStats,
            "mintProvider": mintProvider,
            "subCollectionId": subCollectionId
        }

        if (policyInfo["collection-name"] === KADCARS_PROJECT_NAME_KEY) {
            nftData = await mintProvider.getNftListingData(nftId, payload);
        } else {
            nftData = await collectionProvider.getNftListingData(nftId, payload);
        }


        nftData["policy-info"] = policyInfo;
        if (nftData["status"] === "listed") {
            if (!checkIfNullOrUndefined(royaltyInfo)) {
                royaltyInfo.forEach((entry) => {
                    royaltyTotal += nftData["price"] * entry["percentage"];
                });
            }
            nftData["transaction-fee"] = nftData["price"] * FEE_PERCENTAGE;
            nftData["royalty-fee"] = royaltyTotal;
        }

        let listingData = {
            ...nftData
        };

        return listingData;
    }

    function sortNfts(nftsList, currentHeight) {
        let nfts = collectionProvider.sortNfts(nftsList, currentHeight);

        return nfts;
    }

    return {
        sortNfts,
        getChain,
        getConfig,
        getTotalSupply,
        getNonMintedTokens,
        getNftAmountLimit,
        getCollectionName,
        getCollectionTraits,
        getMintPhases,
        getCollectionDescriptionSummary,
        getNftImage,
        getNftListingData,
        getNftMap,
        getCollectionStandard,
        getNftFieldsForId,
        getNftsForUser,
        getAccountWhitelistData,
        getPhaseParticipationRequirement,
        getTraitDistribution,
        collectionHasMarketplace,
        getMarketplaceCollectionDetails,
        formatNftTraits,
        getCollectionFloor,
        getNftTraitStats,
        getMintProvider,
        getBurnNftsMap
    };
}

export { useCollectionProvider };

import {
    Box,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalBody,
    Button,
    useDisclosure,
    Flex,
    Icon,
    Text,
} from "@chakra-ui/react";
import React from "react";
import { BsChevronLeft, BsChevronRight, BsXLg } from "react-icons/bs";
import DetailsSection from "./DetailsSection";

import AttributesSection from "./AttributesSection";
import ActivityTable from "../../Activity/ActivityTable";

const CollectionModal = ({ children }) => {
    const tableData = [
        {
            event: "SALE",
            price: "12 KAD",
            from: "sdfingev49ptb...",
            to: "fvpnju6ur5yerb...",
            date: "12 days ago",
        },
        {
            event: "TRANSFER",
            price: "",
            from: "k:fvpnuwp98n...",
            to: "k:fvpnuwp98ERV...",
            date: "14 days ago",
        },
        {
            event: "SALE",
            price: "12 KAD",
            from: "sdfingev49ptb...",
            to: "fvpnju6ur5yerb...",
            date: "12 days ago",
        },
    ];

    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box>
            <Box
                _hover={{}}
                _focus={{}}
                _focusVisible={{}}
                bgColor={"transparent"}
                onClick={onOpen}
            >
                {children}
            </Box>
            <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    bgColor="#20201D"
                    border="1px solid #757575"
                    rounded="16px"
                    p="0px 24px 48px 24px"
                    maxW="936px"
                    w="100%"
                    maxH={["100%", null, "85vh", null, null, "664px"]}
                    overflowY={"auto"}
                    pos="relative"
                    pt="0px"
                    overflowX={"hidden"}
                >
                    <ModalBody pos="relative" pt="0px" className="latestDesign">
                        <Flex
                            pos="sticky"
                            top="0"
                            right="0"
                            left="0"
                            bgColor="#20201D"
                            pt="16px"
                            pb="8px"
                            align="center"
                            justify={"flex-end"}
                            gap="24px"
                            zIndex={"300"}
                        >
                            <Flex gap="8px">
                                <Icon
                                    as={BsChevronLeft}
                                    boxSize="24px"
                                    cursor="pointer"
                                />
                                <Icon
                                    as={BsChevronRight}
                                    boxSize="24px"
                                    cursor="pointer"
                                />
                            </Flex>
                            <Icon
                                as={BsXLg}
                                boxSize="24px"
                                cursor="pointer"
                                onClick={onClose}
                            />
                        </Flex>

                        <Box mt="16px">
                            <Flex gap="16px" flexDir={["column", null, "row"]}>
                                <Box w="100%">
                                    <DetailsSection />
                                </Box>

                                <Box w="100%">
                                    <AttributesSection />
                                </Box>
                            </Flex>
                        </Box>

                        <Box mt="24px">
                            <Text fontSize={"20px"} fontWeight={700}>
                                Activity
                            </Text>

                            <ActivityTable
                                tableData={tableData}
                                color="#424242"
                                stripe="#616161"
                                headerColor={"#424242"}
                                evenColor="#424242"
                            />
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CollectionModal;

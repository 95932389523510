import React, { memo } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    Text,
    Box,
    Flex,
    Image,
    SimpleGrid
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { tryLoadLocal } from "../utils/utils";
import { LOCAL_ACCOUNT_KEY, STACKED_TYPE } from "../utils/Constants";

let darkMode = true;
const dark = "#29242B";
const light = "rgba(189, 61, 240, 0.3)";

const MyNftPopUpModal = memo(({
    isOpen,
    onOpen,
    onClose,
    nftData,
    handleManageNftClick,
}) => {
    const navigate = useNavigate();

    function getColorForRarityLevel(level) {
        let color = "#BC3CF0";

        switch (level) {
            case "COMMON":
                color = "#00994F";
                break;
            case "RARE":
                color = "#1E77FF";
                break;
            case "EPIC":
                color = "#BC3CF0";
                break;
            case "LEGENDARY":
                color = "#EA780E";
                break;
            case "ONE OF ONE":
                color = "#FFD700";
                break;
            default:
                break;
        }

        return color;
    }

    function navigateToProfile() {
        let account = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        navigate(`/userProfile/${account.account}`);
    }

    return (
        <Box>
            <Box className="latestDesign">
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"6xl"}
                    trapFocus={false}
                >
                    <ModalOverlay bgColor={"blackAlpha.800"} />
                    <ModalContent bg="transparent" px="10px">
                        <ModalBody
                            bgColor="#160D1A"
                            py="34px"
                            px={["10px", "20px"]}
                        >
                            <Flex
                                align="flex-start"
                                gap={["20px", "40px"]}
                                flexDir={["column", "row"]}
                            >
                                <Flex
                                    // justify="center"
                                    align="flex-start"
                                    h="100%"
                                    w="100%"
                                    flexDir="column"
                                >
                                    <Image
                                        src={nftData["nft-uri"]}
                                        alt={"bgi card "}
                                        h="575px"
                                        w="100%"
                                        // h="100%"
                                        // maxW="658px"
                                        objectFit="cover"
                                        display="block"
                                    />

                                    <Flex
                                        py="16px"
                                        gap={["15px"]}
                                        mt="10px"
                                        w="100%"
                                    >
                                        {/* <ComingSoonComponent
                                            direction={"bottom"}
                                        > */}
                                        <Button
                                            _focus={{}}
                                            _active={{}}
                                            _hover={{}}
                                            w="100%"
                                            color="#fff"
                                            border="1px"
                                            borderColor="#BC3CF0"
                                            bgColor="#BC3CF0"
                                            borderRadius={"full"}
                                            fontSize={["14px", "16px"]}
                                            onClick={() => { handleManageNftClick(nftData) }}
                                        >
                                            Manage NFT
                                        </Button>
                                        {/* </ComingSoonComponent> */}
                                        <Button
                                            _focus={{}}
                                            _active={{}}
                                            _hover={{}}
                                            w="100%"
                                            color="#BC3CF0"
                                            border="1px"
                                            borderColor="#BC3CF0"
                                            bgColor="transparent"
                                            borderRadius={"4px"}
                                            onClick={navigateToProfile}
                                            rounded={"2rem"}
                                        >
                                            View in profile
                                        </Button>
                                    </Flex>
                                </Flex>

                                <Box
                                    minW="324px"
                                    pb="43px"
                                    w={"100%"}
                                    maxW="524px"
                                >
                                    <Flex
                                        gap="20px"
                                        align="center"
                                        justify="space-between"
                                    >
                                        <Text fontSize="30px" fontWeight="900">
                                            {nftData["name"]}
                                        </Text>
                                        <Text fontWeight="900" fontSize="14px">
                                            RARITY RANK:{" "}
                                            {nftData["rarity_level"] ? (
                                                <Text
                                                    as="span"
                                                    color={() =>
                                                        getColorForRarityLevel(
                                                            nftData[
                                                            "rarity_level"
                                                            ]
                                                        )
                                                    }
                                                >
                                                    {nftData["rarity_level"]}
                                                </Text>
                                            ) : (
                                                <Text as="span">
                                                    {nftData["rarity_score"]}
                                                </Text>
                                            )}{" "}
                                        </Text>
                                    </Flex>
                                    <Box mt="50px">
                                        <Text
                                            as="span"
                                            fontSize="20px"
                                            fontWeight="700"
                                            flex="1"
                                            textAlign="left"
                                        >
                                            ATTRIBUTES
                                        </Text>
                                        <SimpleGrid
                                            columns={[2, 3]}
                                            spacing="20px"
                                            mt="20px"
                                        >
                                            {nftData &&
                                                nftData[
                                                    "collection_traits"
                                                ].map((traitName, index) => {
                                                    if (
                                                        nftData["formatted_attributes"][traitName]
                                                    ) {
                                                        return (
                                                            <Box
                                                                key={index}
                                                                // bgColor="rgba(189, 61, 240, 0.3)"
                                                                // borderColor="#5E1E78"
                                                                // border="1px"
                                                                bgColor={
                                                                    darkMode
                                                                        ? dark
                                                                        : light
                                                                }
                                                                style={{
                                                                    border: `0.5px solid ${darkMode
                                                                        ? "#757575"
                                                                        : "#5E1E78"
                                                                        }`,
                                                                }}
                                                                rounded="8px"
                                                                p="8px"
                                                                textAlign="center"
                                                            >
                                                                <Text fontWeight="900">
                                                                    {traitName}
                                                                </Text>
                                                                {nftData &&
                                                                    nftData[
                                                                    "formatted_attributes"
                                                                    ][traitName] ? (
                                                                    <Text
                                                                        py="8px"
                                                                        fontWeight="500"
                                                                    >
                                                                        {nftData &&
                                                                            nftData["formatted_attributes"][traitName]["name"]
                                                                        }
                                                                    </Text>
                                                                ) : (
                                                                    <Text>
                                                                        NONE
                                                                    </Text>
                                                                )}
                                                                <Text
                                                                    fontSize="14px"
                                                                    fontWeight="700"
                                                                >
                                                                    {
                                                                        nftData["formatted_attributes"][traitName]["frequency"]
                                                                    }
                                                                    %
                                                                </Text>
                                                            </Box>
                                                        );
                                                    }
                                                })}
                                        </SimpleGrid>
                                    </Box>

                                    {/* Commented out because of thenew update in desgn */}
                                    {nftData &&
                                        nftData["formatted_attributes"]["extras"] &&
                                        nftData["formatted_attributes"]["extras"]["collection_skills"] && (
                                            <Box mt="50px">
                                                <Text
                                                    as="span"
                                                    fontSize="20px"
                                                    fontWeight="700"
                                                    flex="1"
                                                    textAlign="left"
                                                >
                                                    Skills
                                                </Text>
                                                <SimpleGrid
                                                    columns={[2, 2]}
                                                    spacing="20px"
                                                    mt="20px"
                                                >
                                                    {nftData &&
                                                        nftData["formatted_attributes"]["extras"][
                                                            "collection_skills"
                                                        ]?.map(
                                                            (
                                                                skillName,
                                                                index
                                                            ) => {
                                                                if (
                                                                    nftData["formatted_attributes"][
                                                                    "extras"
                                                                    ]["skills"][
                                                                    skillName
                                                                    ]
                                                                ) {
                                                                    return (
                                                                        <Box
                                                                            key={
                                                                                index
                                                                            }
                                                                            bgColor="rgba(189, 61, 240, 0.3)"
                                                                            rounded="8px"
                                                                            border="1px"
                                                                            borderColor="#5E1E78"
                                                                            p="8px"
                                                                        >
                                                                            <Text fontWeight="900">
                                                                                {
                                                                                    skillName
                                                                                }
                                                                            </Text>
                                                                            {nftData &&
                                                                                nftData["formatted_attributes"][
                                                                                "extras"
                                                                                ][
                                                                                "skills"
                                                                                ][
                                                                                skillName
                                                                                ] ? (
                                                                                <Text
                                                                                    py="8px"
                                                                                    fontWeight="500"
                                                                                >
                                                                                    {nftData &&
                                                                                        nftData["formatted_attributes"][
                                                                                        "extras"
                                                                                        ][
                                                                                        "skills"
                                                                                        ][
                                                                                        skillName
                                                                                        ][
                                                                                        "name"
                                                                                        ]}
                                                                                </Text>
                                                                            ) : (
                                                                                <Text>
                                                                                    NONE
                                                                                </Text>
                                                                            )}
                                                                            <Text
                                                                                fontSize="14px"
                                                                                fontWeight="400"
                                                                            >
                                                                                {
                                                                                    nftData["formatted_attributes"][
                                                                                    "extras"
                                                                                    ][
                                                                                    "skills"
                                                                                    ][
                                                                                    skillName
                                                                                    ][
                                                                                    "frequency"
                                                                                    ]
                                                                                }

                                                                                %
                                                                                have
                                                                                this
                                                                                skill
                                                                            </Text>
                                                                        </Box>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                </SimpleGrid>
                                            </Box>
                                        )}
                                </Box>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
        </Box>
    );
});

export default MyNftPopUpModal;

import { useEffect, useState } from "react"
import { checkIfNullOrUndefined, tryLoadLocal, tryRemoveLocal, trySaveLocal } from "../utils/utils";

function getSavedValue(key, initialValue) {
    let currentValue = tryLoadLocal(key);
    if (!checkIfNullOrUndefined(currentValue)) {
        return currentValue;
    }

    if (initialValue instanceof Function) return initialValue();
    return initialValue;
}


//TODO: add try catch blocks and more error handling
function useLocalStorage(key, initialValue) {
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue);
    });

    useEffect(() => {
        trySaveLocal(key, value);
    }, [key, value]);

    async function getValue(subKey) {
        let currentValue = await tryLoadLocal(key);
        return currentValue;
    }

    function removeValue() {
        setValue(null);
        tryRemoveLocal(key);
    }

    return [value, setValue, getValue, removeValue];
}

export {
    useLocalStorage
}
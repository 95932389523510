import SignClient from '@walletconnect/sign-client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NETWORK_ID } from '../utils/Constants';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { PactContext } from '../pact/PactContextProvider';
import { checkIfNullOrUndefined, tryLoadLocal } from '../utils/utils';

const KDA_NAMESPACE = 'kadena';
const KDA_CHAINS = ['kadena:mainnet01', 'kadena:testnet04', 'kadena:development'];

const KDA_METHODS = {
    KDA_SIGN: 'kadena_sign_v1',
    KDA_QUICK_SIGN: 'kadena_quicksign_v1',
    KDA_GET_ACCOUNTS: 'kadena_getAccounts_v1',
};

const KDA_EVENTS = {
    KDA_TRANSACTION_UPDATED: 'kadena_transaction_updated',
};

const WALLET_CONNECT_NOT_INITIALIZED = 'WalletConnect is not initialized';
const WALLET_CONNECT_NOT_CONNECTED = 'WalletConnect is not connected';
const WALLET_CONNECT_LOCAL_KEY = "walletConnectState";

const getNamespacesFromChains = (chains) => {
    const supportedNamespaces = [];
    console.log(chains)
    chains.forEach((chainId) => {
        const [namespace] = chainId.split(':');
        if (!supportedNamespaces.includes(namespace)) {
            supportedNamespaces.push(namespace);
        }
    });

    return supportedNamespaces;
};

const getSupportedMethodsByNamespace = (namespace) => {
    switch (namespace) {
        case 'kadena':
            return Object.values(KDA_METHODS);
        default:
            throw new Error(`No default methods for namespace: ${namespace}`);
    }
};

const getSupportedEventsByNamespace = (namespace) => {
    switch (namespace) {
        case 'kadena':
            return Object.values(KDA_EVENTS);
        default:
            throw new Error(`No default events for namespace: ${namespace}`);
    }
};

const getRequiredNamespaces = (chains) => {
    const selectedNamespaces = getNamespacesFromChains(chains);
    return Object.fromEntries(
        selectedNamespaces.map((namespace) => [
            namespace,
            {
                methods: getSupportedMethodsByNamespace(namespace),
                chains: chains.filter((chain) => chain.startsWith(namespace)),
                events: getSupportedEventsByNamespace(namespace),
                // extension: [
                //     {
                //         methods: getSupportedMethodsByNamespace('kadena'),
                //         chains: chains.filter((chain) => chain.startsWith(namespace)),
                //         events: getSupportedEventsByNamespace('kadena'),
                //     },
                // ],
            },
        ])
    );
};

const checkIfItemInitialized = async (item, errorMessage, callback) => {
    if (checkIfNullOrUndefined(item)) {
        const value = await callback();
        return value;
    }
    return item;
}

const initialWalletConnectState = {
    pairingTopic: null,
};

function useWalletConnect(account) {
    const [signClient, setSignClient] = useState(null);
    const [walletConnectState, setWalletConnectState] = useLocalStorage('walletConnectState', initialWalletConnectState);

    const initialize = async () => {
        try {
            const client = await SignClient.init({
                projectId: "24308ed9a37b1853f948dbcb1f72f290"
            });

            setSignClient(client);
            return client;
        } catch (err) {
            return null;
        }
    }

    const connectWallet = useCallback(
        async (pairing = undefined) => {
            // let initialized = checkIfItemInitialized(signClient, WALLET_CONNECT_NOT_INITIALIZED, initialize);
            // if (!initialized) return;

            let client = await initialize();

            try {
                const requiredNamespaces = getRequiredNamespaces(KDA_CHAINS);
                const { uri, approval } = await client.connect({
                    // pairingTopic: pairing?.topic,
                    requiredNamespaces,
                });

                console.log(uri)
                if (uri) {
                    QRCodeModal.open(
                        uri,
                        () => {
                            console.log('EVENT', 'QR Code Modal closed');
                        },
                        {
                            desktopLinks: [],
                            mobileLinks: [],
                        }
                    );
                }

                const session = await approval();
                setWalletConnectState({
                    ...walletConnectState,
                    pairingTopic: session.topic,
                });

                let wallet = session.peer.metadata.name;
                let accounts = session.namespaces.kadena.accounts;
                accounts = session.namespaces["kadena"].accounts.map((item) => {
                    let normalAccountName = item;
                    ["mainnet01"].forEach(chain => {
                        if (wallet && wallet.includes("Koala")) {
                            // normalAccountName = normalAccountName.replace("**", ":").replace(`kadena:${chain}:`, "")
                            normalAccountName = `k:${normalAccountName.replace(`kadena:${chain}:`, "")}`
                        }
                        else {
                            normalAccountName = `k:${normalAccountName.replace(`kadena:${chain}:`, "")}`
                        }

                    })
                    return normalAccountName
                })

                QRCodeModal.close();
                console.log(accounts)
                return {
                    chainIds: KDA_CHAINS,
                    accounts,
                    session,
                };
            } catch (e) {
                console.log(e)
                QRCodeModal.close();
                return null;
            } finally {
                QRCodeModal.close();
            }
        },
        [signClient, initialize, setWalletConnectState]
    );

    const requestSignTransaction = useCallback(
        async (networkId, payload) => {
            // let signClientInitialized = checkIfItemInitialized(signClient, WALLET_CONNECT_NOT_INITIALIZED, initialize);
            // if (!signClientInitialized) return;

            // let walletStateInitialized = checkIfItemInitialized(walletConnectState?.pairingTopic, WALLET_CONNECT_NOT_CONNECTED, connectWallet);
            // if (!walletStateInitialized) return;
            let client = await initialize();

            let sessionTopic = tryLoadLocal(WALLET_CONNECT_LOCAL_KEY);
            console.log(sessionTopic)
            const response = await client?.request({
                topic: sessionTopic?.pairingTopic,
                chainId: `${KDA_NAMESPACE}:${networkId || NETWORK_ID}`,
                request: {
                    method: KDA_METHODS["KDA_SIGN"],
                    params: payload,
                },
            });

            return response;
        },
        [signClient, walletConnectState, connectWallet, initialize]
    );

    const requestQuickSignTransaction = useCallback(
        async (networkId, payload) => {
            // let client = await checkIfItemInitialized(signClient, WALLET_CONNECT_NOT_INITIALIZED, initialize);
            // if (!client) return;

            // let walletStateInitialized = await checkIfItemInitialized(walletConnectState?.pairingTopic, WALLET_CONNECT_NOT_CONNECTED, connectWallet);
            // if (!walletStateInitialized) return;
            let client = await initialize();
            console.log(client)

            console.log(payload["commandSigDatas"])
            let sessionTopic = tryLoadLocal(WALLET_CONNECT_LOCAL_KEY);
            const response = await client?.request({
                topic: sessionTopic?.pairingTopic,
                chainId: `${KDA_NAMESPACE}:${networkId || NETWORK_ID}`,
                request: {
                    method: KDA_METHODS["KDA_QUICK_SIGN"],
                    params: payload,
                },
            });
            console.log(response)

            return response;
        },
        [signClient, walletConnectState, connectWallet, initialize]
    )

    const requestGetAccounts = useCallback(async (networkId, accounts) => {
        // if (!checkIfNullOrUndefined(signClient)) {
        //     const initialized = await initialize();
        //     if (!initialized) {
        //         throw new Error('WalletConnect is not initialized');
        //     }
        // }

        // if (!walletConnectState?.pairingTopic) {
        //     const connectedWallet = await connectWallet();
        //     if (!connectedWallet) {
        //         throw new Error('WalletConnect is not connected');
        //     }
        // }
        let client = await initialize();

        let sessionTopic = tryLoadLocal(WALLET_CONNECT_LOCAL_KEY);
        console.log(sessionTopic)
        console.log(KDA_METHODS["KDA_GET_ACCOUNTS"])
        console.log(`${KDA_NAMESPACE}:${networkId || NETWORK_ID}`)
        const response = await client?.request({
            topic: sessionTopic?.pairingTopic,
            chainId: `${KDA_NAMESPACE}:${networkId || NETWORK_ID}`,
            request: {
                method: KDA_METHODS["KDA_GET_ACCOUNTS"],
                params: {
                    accounts,
                },
            },
        });
        console.log(response)
        return response;
    }, [signClient, walletConnectState, connectWallet, initialize]);

    const sendTransactionUpdateEvent = useCallback(
        async (networkId, payload) => {
            if (!checkIfNullOrUndefined(signClient)) {
                const initialized = await initialize();
                if (!initialized) {
                    throw new Error('WalletConnect is not initialized');
                }
            }
            if (!walletConnectState?.pairingTopic) {
                const connectedWallet = await connectWallet();
                if (!connectedWallet) {
                    throw new Error('WalletConnect is not connected');
                }
            }

            try {
                let sessionTopic = tryLoadLocal(WALLET_CONNECT_LOCAL_KEY);
                console.log(sessionTopic)
                await signClient?.emit({
                    topic: sessionTopic?.pairingTopic,
                    chainId: `${KDA_NAMESPACE}:${networkId || NETWORK_ID}`,
                    event: {
                        name: KDA_EVENTS["KDA_TRANSACTION_UPDATED"],
                        params: payload,
                    },
                });
            } catch (e) { }
        },
        [signClient, walletConnectState, connectWallet, initialize]
    );

    const disconnectWallet = useCallback(async () => {
        try {
            let client = await initialize();
            await client.disconnect();
        } catch (e) {
            console.log(e)
        }
    }, [signClient, walletConnectState, initialize])

    // useEffect(() => {
    //     if (!checkIfNullOrUndefined(signClient)) {
    //         initialize().then((initialized) => {
    //             if (!initialized) {
    //                 throw new Error('WalletConnect is not initialized');
    //             }
    //         });
    //     }
    // }, [signClient, initialize]);

    useEffect(() => {
        if (!checkIfNullOrUndefined(signClient)) {
            let sessionTopic = tryLoadLocal(WALLET_CONNECT_LOCAL_KEY);
            if (!sessionTopic?.pairingTopic) {
                const pairings = signClient.pairing.getAll({ active: true });
                if (pairings && pairings.length > 0) {
                    setWalletConnectState((state) => ({
                        ...state,
                        pairingTopic: pairings[pairings.length - 1].topic,
                    }));
                }
            }
            const onSessionDelete = () => {
                setWalletConnectState(initialWalletConnectState);
                localStorage.removeItem('walletConnectState');
            };
            signClient.on('session_delete', onSessionDelete);
            signClient.on('pairing_delete', onSessionDelete);
            signClient.on('session_expire', onSessionDelete);
            signClient.on('pairing_expire', onSessionDelete);
            return () => {
                signClient.removeListener('session_delete', onSessionDelete);
                signClient.removeListener('pairing_delete', onSessionDelete);
                signClient.removeListener('session_expire', onSessionDelete);
                signClient.removeListener('pairing_expire', onSessionDelete);
            };
        }
    }, [signClient, setWalletConnectState]);

    return {
        signClient,
        initialize,
        setSignClient,
        connectWallet,
        disconnectWallet,
        requestGetAccounts,
        requestSignTransaction,
        requestQuickSignTransaction,
        sendTransactionUpdateEvent
    };
}

export {
    useWalletConnect
}
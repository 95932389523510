import React, { useEffect, useState } from "react";
import { IconButton, useColorMode, useTheme } from "@chakra-ui/react";
import { BsChevronUp } from "react-icons/bs";

const BackToTopButton = () => {
    const theme = useTheme();
    const { colorMode } = useColorMode();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const navbarHeight = 120;

            setIsVisible(scrollY > navbarHeight);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [theme.sizes]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const buttonStyle = {
        position: "fixed",
        bottom: `${theme.sizes["4"]}`, // 4 is a Chakra spacing value, adjust accordingly
        right: `${theme.sizes["4"]}`, // 4 is a Chakra spacing value, adjust accordingly
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.3s ease",
        zIndex: 1000,
    };

    return (
        <IconButton
            aria-label="Back to Top"
            icon={<BsChevronUp />}
            onClick={scrollToTop}
            // size="lg"
            w="2.75rem"
            h="2.75rem"
            colorScheme={"blue"}
            style={buttonStyle}
            borderRadius="full"
            bgColor="#BC3CF0"
        />
    );
};

export default BackToTopButton;

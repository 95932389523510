const pact_rats_metadata = {
    "1": {
        "name": "Pact Rats #1",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/1.png",
        "dna": "5bc67049ca5975c6f9131c0d9f0570b46323fd78",
        "edition": 1,
        "date": 1665265087348,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.243749999999999,
        "id": "1",
        "rarity_score": 129.3582612105292,
        "rarity_level": "RARE"
    },
    "2": {
        "name": "Pact Rats #2",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/2.png",
        "dna": "d0b55662f2193074e4310734b27911d587836a3a",
        "edition": 2,
        "date": 1665265128263,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.572499999999998,
        "id": "2",
        "rarity_score": 63.077349225737315,
        "rarity_level": "COMMON"
    },
    "3": {
        "name": "Pact Rats #3",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/3.png",
        "dna": "019d0c228f5fafc342cd55c9308b50adc4a5a27d",
        "edition": 3,
        "date": 1665265187414,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.6675,
        "id": "3",
        "rarity_score": 70.668317039349,
        "rarity_level": "COMMON"
    },
    "4": {
        "name": "Pact Rats #4",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/4.png",
        "dna": "b80b6f0d1486de81c202afedcfe1f403d2925d83",
        "edition": 4,
        "date": 1665265103110,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Super",
        "rarity": 6.883750000000001,
        "id": "4",
        "rarity_score": 137.611230140242,
        "rarity_level": "RARE"
    },
    "5": {
        "name": "Pact Rats #5",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/5.png",
        "dna": "f444c1b80abb273cc6cb13e337e3f0c361b7f5e4",
        "edition": 5,
        "date": 1665265078950,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.7975,
        "id": "5",
        "rarity_score": 68.87347919319406,
        "rarity_level": "COMMON"
    },
    "6": {
        "name": "Pact Rats #6",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/6.png",
        "dna": "8504f7eeea6a696da258c18e543648d0515b0bfe",
        "edition": 6,
        "date": 1665265118706,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 7.771250000000001,
        "id": "6",
        "rarity_score": 125.33439914366859,
        "rarity_level": "RARE"
    },
    "7": {
        "name": "Pact Rats #7",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/7.png",
        "dna": "196e65e0ec74acd4033053388774c06e71785e83",
        "edition": 7,
        "date": 1665265041676,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 7.251249999999999,
        "id": "7",
        "rarity_score": 162.17375939015338,
        "rarity_level": "EPIC"
    },
    "8": {
        "name": "Pact Rats #8",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/8.png",
        "dna": "7554b9046053c775e6ff92eba56ed9977d28979a",
        "edition": 8,
        "date": 1665265028483,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.669999999999998,
        "id": "8",
        "rarity_score": 52.912572684868955,
        "rarity_level": "COMMON"
    },
    "9": {
        "name": "Pact Rats #9",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/9.png",
        "dna": "3e81132313124ed0ac115933c99347cd8d04e4b7",
        "edition": 9,
        "date": 1665265130799,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Epic",
        "rarity": 8.798749999999998,
        "id": "9",
        "rarity_score": 150.11051259573918,
        "rarity_level": "EPIC"
    },
    "10": {
        "name": "Pact Rats #10",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/10.png",
        "dna": "fbae48da8af3f087a930bc311b6a313f815a3914",
        "edition": 10,
        "date": 1665265100032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 10.788750000000002,
        "id": "10",
        "rarity_score": 142.18597490326368,
        "rarity_level": "RARE"
    },
    "11": {
        "name": "Pact Rats #11",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/11.png",
        "dna": "c47b57b9a38cfe095ef01809724542001bd659bb",
        "edition": 11,
        "date": 1665265132111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.0575,
        "id": "11",
        "rarity_score": 85.05949715732663,
        "rarity_level": "COMMON"
    },
    "12": {
        "name": "Pact Rats #12",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/12.png",
        "dna": "1dc5f4be423435a92308670f3e1c80ab109901b6",
        "edition": 12,
        "date": 1665265170491,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 9.016250000000001,
        "id": "12",
        "rarity_score": 130.1718810548885,
        "rarity_level": "RARE"
    },
    "13": {
        "name": "Pact Rats #13",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/13.png",
        "dna": "35ba0738c18b2a61842b20258b20303d7364c318",
        "edition": 13,
        "date": 1665265065781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.30125,
        "id": "13",
        "rarity_score": 128.48276137975836,
        "rarity_level": "RARE"
    },
    "14": {
        "name": "Pact Rats #14",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/14.png",
        "dna": "f29ca30b39a475b23c213e4301b5a0a1488ce0d3",
        "edition": 14,
        "date": 1665265039887,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.643749999999997,
        "id": "14",
        "rarity_score": 61.335833202289535,
        "rarity_level": "COMMON"
    },
    "15": {
        "name": "Pact Rats #15",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/15.png",
        "dna": "4bbcdb1c367a2ba5eb1a39c586d86a6fa0d03284",
        "edition": 15,
        "date": 1665265027959,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.65,
        "id": "15",
        "rarity_score": 106.15812101241981,
        "rarity_level": "COMMON"
    },
    "16": {
        "name": "Pact Rats #16",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/16.png",
        "dna": "9df36243293866b33b35d80ae94325b12dd5268b",
        "edition": 16,
        "date": 1665265108424,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.98375,
        "id": "16",
        "rarity_score": 72.5159314803034,
        "rarity_level": "COMMON"
    },
    "17": {
        "name": "Pact Rats #17",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/17.png",
        "dna": "0fd8cf44358294411f7db91c07d86db89a1f7b0a",
        "edition": 17,
        "date": 1665265037289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.122499999999999,
        "id": "17",
        "rarity_score": 121.09770580267985,
        "rarity_level": "RARE"
    },
    "18": {
        "name": "Pact Rats #18",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/18.png",
        "dna": "0440b9f9478bbd0bc96ad854802799ed77601966",
        "edition": 18,
        "date": 1665265065536,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.686249999999999,
        "id": "18",
        "rarity_score": 129.9725030599445,
        "rarity_level": "RARE"
    },
    "19": {
        "name": "Pact Rats #19",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/19.png",
        "dna": "a9e9674458c1ddbd901eb09f55cf33b28cb4d5b7",
        "edition": 19,
        "date": 1665265163981,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Rare",
        "rarity": 10.278750000000002,
        "id": "19",
        "rarity_score": 120.41124330896395,
        "rarity_level": "RARE"
    },
    "20": {
        "name": "Pact Rats #20",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/20.png",
        "dna": "fdcba37d6a4ea799e19f0c2936b4c91ea05e3384",
        "edition": 20,
        "date": 1665265124847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.17625,
        "id": "20",
        "rarity_score": 69.18261581311437,
        "rarity_level": "COMMON"
    },
    "21": {
        "name": "Pact Rats #21",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/21.png",
        "dna": "a00bb27d731d5d7bc51c212d5fe5bdb15aae7287",
        "edition": 21,
        "date": 1665265048831,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.62875,
        "id": "21",
        "rarity_score": 125.3974564918889,
        "rarity_level": "RARE"
    },
    "22": {
        "name": "Pact Rats #22",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/22.png",
        "dna": "7afd97349beef9204f53702399e4f53dfa9e068e",
        "edition": 22,
        "date": 1665265152519,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.1125,
        "id": "22",
        "rarity_score": 62.56119522077476,
        "rarity_level": "COMMON"
    },
    "23": {
        "name": "Pact Rats #23",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/23.png",
        "dna": "5ba8684ea967f75ca0d644ddcff5417f075f2b31",
        "edition": 23,
        "date": 1665265155758,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.515,
        "id": "23",
        "rarity_score": 80.98825673895288,
        "rarity_level": "COMMON"
    },
    "24": {
        "name": "Pact Rats #24",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/24.png",
        "dna": "edc1a2f71c7ad42b92d2c997e2674bed03c7301b",
        "edition": 24,
        "date": 1665265147190,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.427499999999998,
        "id": "24",
        "rarity_score": 127.89595088618485,
        "rarity_level": "RARE"
    },
    "25": {
        "name": "Pact Rats #25",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/25.png",
        "dna": "3c3db0ed61f4651e1c9cfa94c15dc8f6fe377923",
        "edition": 25,
        "date": 1665265085082,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 11.35375,
        "id": "25",
        "rarity_score": 112.2457184115617,
        "rarity_level": "RARE"
    },
    "26": {
        "name": "Pact Rats #26",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/26.png",
        "dna": "56f8eea9a2804014e8e22dfc5577e66a1e68e126",
        "edition": 26,
        "date": 1665265156282,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.68125,
        "id": "26",
        "rarity_score": 62.27051857147622,
        "rarity_level": "COMMON"
    },
    "27": {
        "name": "Pact Rats #27",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/27.png",
        "dna": "c482d2d5d32ddf02260f3eed69fec6072607304f",
        "edition": 27,
        "date": 1665265089398,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.67875,
        "id": "27",
        "rarity_score": 86.74214781432411,
        "rarity_level": "COMMON"
    },
    "28": {
        "name": "Pact Rats #28",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/28.png",
        "dna": "0610ddb6330546f2a1fbd4213215ede369aa2440",
        "edition": 28,
        "date": 1665265173093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 11.737500000000004,
        "id": "28",
        "rarity_score": 109.64815433013715,
        "rarity_level": "COMMON"
    },
    "29": {
        "name": "Pact Rats #29",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/29.png",
        "dna": "1334d87e0160951d353b63f6f6e66bb19d29d876",
        "edition": 29,
        "date": 1665265043742,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.84375,
        "id": "29",
        "rarity_score": 83.55737137598851,
        "rarity_level": "COMMON"
    },
    "30": {
        "name": "Pact Rats #30",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/30.png",
        "dna": "a581b2cfaa217dcfc99889421832ca57b0b94528",
        "edition": 30,
        "date": 1665265135769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.6875,
        "id": "30",
        "rarity_score": 98.66940409464559,
        "rarity_level": "COMMON"
    },
    "31": {
        "name": "Pact Rats #31",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/31.png",
        "dna": "2783aba6cc51cee0c5985169c2d37a588385e354",
        "edition": 31,
        "date": 1665265033269,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.495,
        "id": "31",
        "rarity_score": 130.2128517003983,
        "rarity_level": "RARE"
    },
    "32": {
        "name": "Pact Rats #32",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/32.png",
        "dna": "3e24f6d011737a19bfb89130fe90994460143ac3",
        "edition": 32,
        "date": 1665265068086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.283750000000001,
        "id": "32",
        "rarity_score": 101.22657281501658,
        "rarity_level": "COMMON"
    },
    "33": {
        "name": "Pact Rats #33",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/33.png",
        "dna": "0e2f1d4c6069f41ab762dd5be2c72a2548c3ba84",
        "edition": 33,
        "date": 1665265099024,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.715,
        "id": "33",
        "rarity_score": 94.74116592526583,
        "rarity_level": "COMMON"
    },
    "34": {
        "name": "Pact Rats #34",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/34.png",
        "dna": "794e4f0ac151f74b5ad3734f51cbab4c0ed5c389",
        "edition": 34,
        "date": 1665265082559,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.68375,
        "id": "34",
        "rarity_score": 109.42252596558461,
        "rarity_level": "COMMON"
    },
    "35": {
        "name": "Pact Rats #35",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/35.png",
        "dna": "af90c9b455e35353f299729e8ba8a4761af1b9d9",
        "edition": 35,
        "date": 1665265160172,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.719999999999999,
        "id": "35",
        "rarity_score": 131.60517299336937,
        "rarity_level": "RARE"
    },
    "36": {
        "name": "Pact Rats #36",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/36.png",
        "dna": "b7a4234cecdb07901968fd87565acea9726fe83f",
        "edition": 36,
        "date": 1665265045539,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 9.144999999999998,
        "id": "36",
        "rarity_score": 108.84604596647034,
        "rarity_level": "COMMON"
    },
    "37": {
        "name": "Pact Rats #37",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/37.png",
        "dna": "3b41fd47274127940eff917c018dadf165e3f578",
        "edition": 37,
        "date": 1665265140397,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.425,
        "id": "37",
        "rarity_score": 104.97219966175498,
        "rarity_level": "COMMON"
    },
    "38": {
        "name": "Pact Rats #38",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/38.png",
        "dna": "ebe24f2e5bb42ecb8beb24366c701b0b3cbf201b",
        "edition": 38,
        "date": 1665265156016,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.332499999999996,
        "id": "38",
        "rarity_score": 102.54520044772327,
        "rarity_level": "COMMON"
    },
    "39": {
        "name": "Pact Rats #39",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/39.png",
        "dna": "5cec020a992bf7b230f81066923507350f1f444b",
        "edition": 39,
        "date": 1665265088371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Epic",
        "rarity": 9.897499999999999,
        "id": "39",
        "rarity_score": 100.4151496909006,
        "rarity_level": "COMMON"
    },
    "40": {
        "name": "Pact Rats #40",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/40.png",
        "dna": "4abb64dc9539b978cb4d4384b652aac5421cfb64",
        "edition": 40,
        "date": 1665265105138,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.53625,
        "id": "40",
        "rarity_score": 68.86636494642396,
        "rarity_level": "COMMON"
    },
    "41": {
        "name": "Pact Rats #41",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/41.png",
        "dna": "68d499b7d7ce7651023aabd7135824e12551769c",
        "edition": 41,
        "date": 1665265132638,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.71875,
        "id": "41",
        "rarity_score": 113.24929051772011,
        "rarity_level": "RARE"
    },
    "42": {
        "name": "Pact Rats #42",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/42.png",
        "dna": "b6f785e275e025218beec41207703c53e56a3370",
        "edition": 42,
        "date": 1665265146662,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.946250000000003,
        "id": "42",
        "rarity_score": 89.13181837622658,
        "rarity_level": "COMMON"
    },
    "43": {
        "name": "Pact Rats #43",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/43.png",
        "dna": "365fb6614cd26a995b7f20812078a2d78c4b82ca",
        "edition": 43,
        "date": 1665265141957,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.763749999999998,
        "id": "43",
        "rarity_score": 109.84343213087355,
        "rarity_level": "COMMON"
    },
    "44": {
        "name": "Pact Rats #44",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/44.png",
        "dna": "fba4cffb9b7a73cf480cc7021d567f6cc438f97e",
        "edition": 44,
        "date": 1665265155236,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.51125,
        "id": "44",
        "rarity_score": 76.12903561282234,
        "rarity_level": "COMMON"
    },
    "45": {
        "name": "Pact Rats #45",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/45.png",
        "dna": "ec65b5366660212a84e4e0eab4ad7343424ae95b",
        "edition": 45,
        "date": 1665265145871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 10.365000000000002,
        "id": "45",
        "rarity_score": 128.21360648221105,
        "rarity_level": "RARE"
    },
    "46": {
        "name": "Pact Rats #46",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/46.png",
        "dna": "c6e6c3ad1393598085a2a108d260ff28d87beea1",
        "edition": 46,
        "date": 1665265162347,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.580000000000002,
        "id": "46",
        "rarity_score": 68.54998880229174,
        "rarity_level": "COMMON"
    },
    "47": {
        "name": "Pact Rats #47",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/47.png",
        "dna": "b60a24aa97b931e04b8dd643d6cb44bb1c747dcd",
        "edition": 47,
        "date": 1665265060572,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.24,
        "id": "47",
        "rarity_score": 70.22242919607076,
        "rarity_level": "COMMON"
    },
    "48": {
        "name": "Pact Rats #48",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/48.png",
        "dna": "008dc26156ed14fc81bd63d8cc86d24383eb0750",
        "edition": 48,
        "date": 1665265181667,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.00875,
        "id": "48",
        "rarity_score": 80.99901734153168,
        "rarity_level": "COMMON"
    },
    "49": {
        "name": "Pact Rats #49",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/49.png",
        "dna": "adb6a70153d53c17b7a8abb7da0173e844d8bb86",
        "edition": 49,
        "date": 1665265118212,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 12.196250000000003,
        "id": "49",
        "rarity_score": 114.30320246330629,
        "rarity_level": "RARE"
    },
    "50": {
        "name": "Pact Rats #50",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/50.png",
        "dna": "12008214ae43b5391c4e9741e1e3466083d639a3",
        "edition": 50,
        "date": 1665265081259,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.31625,
        "id": "50",
        "rarity_score": 74.64737880656203,
        "rarity_level": "COMMON"
    },
    "51": {
        "name": "Pact Rats #51",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/51.png",
        "dna": "7f37ea65049be93af29270b685985bcd145400fa",
        "edition": 51,
        "date": 1665265030768,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.307500000000001,
        "id": "51",
        "rarity_score": 73.01626295053262,
        "rarity_level": "COMMON"
    },
    "52": {
        "name": "Pact Rats #52",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/52.png",
        "dna": "1d180ac28a74fd9ff62eb4fdf8eb2d0e794eb7c5",
        "edition": 52,
        "date": 1665265109198,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.451250000000002,
        "id": "52",
        "rarity_score": 95.00979004003254,
        "rarity_level": "COMMON"
    },
    "53": {
        "name": "Pact Rats #53",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/53.png",
        "dna": "336a1f4d46b5ccae80a2e9e3988f814899ef21a4",
        "edition": 53,
        "date": 1665265108943,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.61625,
        "id": "53",
        "rarity_score": 77.35285860587105,
        "rarity_level": "COMMON"
    },
    "54": {
        "name": "Pact Rats #54",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/54.png",
        "dna": "3b78cd2617df3c195b42f95289ffdc1516dc6bde",
        "edition": 54,
        "date": 1665265139381,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.9575,
        "id": "54",
        "rarity_score": 62.22558833927956,
        "rarity_level": "COMMON"
    },
    "55": {
        "name": "Pact Rats #55",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/55.png",
        "dna": "e4b1c5ed0b640a08c5615e08624000384b0ec658",
        "edition": 55,
        "date": 1665265106391,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.1775,
        "id": "55",
        "rarity_score": 77.58779351785253,
        "rarity_level": "COMMON"
    },
    "56": {
        "name": "Pact Rats #56",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/56.png",
        "dna": "a29e28679544ce393c465b14994605011abb033b",
        "edition": 56,
        "date": 1665265093391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.5775,
        "id": "56",
        "rarity_score": 89.37707669688801,
        "rarity_level": "COMMON"
    },
    "57": {
        "name": "Pact Rats #57",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/57.png",
        "dna": "f114d036bd9192b07778f649bbd8722aa323aab0",
        "edition": 57,
        "date": 1665265102617,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.064999999999998,
        "id": "57",
        "rarity_score": 80.26248869667192,
        "rarity_level": "COMMON"
    },
    "58": {
        "name": "Pact Rats #58",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/58.png",
        "dna": "3e7efb111dca3349fd5af1b6ad48e287bbe1c5a6",
        "edition": 58,
        "date": 1665265062278,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.497499999999999,
        "id": "58",
        "rarity_score": 72.73357686204164,
        "rarity_level": "COMMON"
    },
    "59": {
        "name": "Pact Rats #59",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/59.png",
        "dna": "69d593790af45f37d0ec6e84ff1eef35aa536ebd",
        "edition": 59,
        "date": 1665265072117,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Super",
        "rarity": 4.001250000000001,
        "id": "59",
        "rarity_score": 240.66715760726697,
        "rarity_level": "LEGENDARY"
    },
    "60": {
        "name": "Pact Rats #60",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/60.png",
        "dna": "5e530fb4039cc20a36cda4d1793058edc153209c",
        "edition": 60,
        "date": 1665265112828,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 11.813749999999999,
        "id": "60",
        "rarity_score": 122.64225522706428,
        "rarity_level": "RARE"
    },
    "61": {
        "name": "Pact Rats #61",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/61.png",
        "dna": "d7818cc86da3f7d4f855612a9e85b796a9639880",
        "edition": 61,
        "date": 1665265039105,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 9.905000000000001,
        "id": "61",
        "rarity_score": 142.51896362154503,
        "rarity_level": "RARE"
    },
    "62": {
        "name": "Pact Rats #62",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/62.png",
        "dna": "c0a23dcca2d606806b684fe701c00e9d484b58c9",
        "edition": 62,
        "date": 1665265172015,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Epic",
        "rarity": 7.12875,
        "id": "62",
        "rarity_score": 130.78939797153998,
        "rarity_level": "RARE"
    },
    "63": {
        "name": "Pact Rats #63",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/63.png",
        "dna": "3f1c6a8459e1993ea370519e601f47462b8d787c",
        "edition": 63,
        "date": 1665265107916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.490000000000002,
        "id": "63",
        "rarity_score": 84.71634341085284,
        "rarity_level": "COMMON"
    },
    "64": {
        "name": "Pact Rats #64",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/64.png",
        "dna": "7c7dd7b1170c9e216136e95a7b4e6595a6dee20b",
        "edition": 64,
        "date": 1665265046275,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.926250000000003,
        "id": "64",
        "rarity_score": 75.41808063711673,
        "rarity_level": "COMMON"
    },
    "65": {
        "name": "Pact Rats #65",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/65.png",
        "dna": "7218c50caa6a4066292d8c4305908f8b5c97735f",
        "edition": 65,
        "date": 1665265084066,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.99625,
        "id": "65",
        "rarity_score": 120.00104066583694,
        "rarity_level": "RARE"
    },
    "66": {
        "name": "Pact Rats #66",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/66.png",
        "dna": "6d02078bb9de4f7c3a887b403803e84d22ed0f28",
        "edition": 66,
        "date": 1665265144826,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.197499999999998,
        "id": "66",
        "rarity_score": 127.33535379261107,
        "rarity_level": "RARE"
    },
    "67": {
        "name": "Pact Rats #67",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/67.png",
        "dna": "2fb9b568d8a9ad03bc020e36e6ec05f4b50baa30",
        "edition": 67,
        "date": 1665265167137,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.61,
        "id": "67",
        "rarity_score": 71.93622943437403,
        "rarity_level": "COMMON"
    },
    "68": {
        "name": "Pact Rats #68",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/68.png",
        "dna": "925cd69269fee9715ba1d99b28bef0102716e512",
        "edition": 68,
        "date": 1665265101082,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.441249999999997,
        "id": "68",
        "rarity_score": 54.749136145099904,
        "rarity_level": "COMMON"
    },
    "69": {
        "name": "Pact Rats #69",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/69.png",
        "dna": "b29d8e868bb865ac8774228c7f8f88d9bfe02e07",
        "edition": 69,
        "date": 1665265075172,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.6675,
        "id": "69",
        "rarity_score": 60.83510664156986,
        "rarity_level": "COMMON"
    },
    "70": {
        "name": "Pact Rats #70",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/70.png",
        "dna": "82df401afec45a2c0a993acaace398211d4f74ed",
        "edition": 70,
        "date": 1665265059278,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.5325,
        "id": "70",
        "rarity_score": 69.75838111923682,
        "rarity_level": "COMMON"
    },
    "71": {
        "name": "Pact Rats #71",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/71.png",
        "dna": "1c68252e7b6d8ca98e86e61f0f5be191705d3aeb",
        "edition": 71,
        "date": 1665265062523,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.395,
        "id": "71",
        "rarity_score": 89.19181379151416,
        "rarity_level": "COMMON"
    },
    "72": {
        "name": "Pact Rats #72",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/72.png",
        "dna": "b118a18c0f5fdae9adfe9818d012326da723b2f9",
        "edition": 72,
        "date": 1665265052176,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Common",
        "rarity": 13.0725,
        "id": "72",
        "rarity_score": 105.93383287362914,
        "rarity_level": "COMMON"
    },
    "73": {
        "name": "Pact Rats #73",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/73.png",
        "dna": "b6d4d64404613731c036255646039ac74f29ac17",
        "edition": 73,
        "date": 1665265075665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Epic",
        "rarity": 8.573750000000002,
        "id": "73",
        "rarity_score": 119.2982592192757,
        "rarity_level": "RARE"
    },
    "74": {
        "name": "Pact Rats #74",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/74.png",
        "dna": "462fe9098dcd8d49c14401937d9c16e666e5e19b",
        "edition": 74,
        "date": 1665265151376,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.544999999999998,
        "id": "74",
        "rarity_score": 62.84642787077894,
        "rarity_level": "COMMON"
    },
    "75": {
        "name": "Pact Rats #75",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/75.png",
        "dna": "5fdc35c5d157c2690cd88c8a0eef77ad919ff2cd",
        "edition": 75,
        "date": 1665265081763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 10.36875,
        "id": "75",
        "rarity_score": 146.97003615356812,
        "rarity_level": "RARE"
    },
    "76": {
        "name": "Pact Rats #76",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/76.png",
        "dna": "65a5a024aefadcee29c045a106d469016efb6f7d",
        "edition": 76,
        "date": 1665265073650,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.75375,
        "id": "76",
        "rarity_score": 164.15303341946014,
        "rarity_level": "EPIC"
    },
    "77": {
        "name": "Pact Rats #77",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/77.png",
        "dna": "c7284ff46e87b6e5c255c2313a2c13c4b08ef304",
        "edition": 77,
        "date": 1665265116639,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.489999999999998,
        "id": "77",
        "rarity_score": 60.94697832063302,
        "rarity_level": "COMMON"
    },
    "78": {
        "name": "Pact Rats #78",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/78.png",
        "dna": "d3aef13bf2f8d7d5ce615902307d2fcb51acd63b",
        "edition": 78,
        "date": 1665265038842,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.838750000000001,
        "id": "78",
        "rarity_score": 68.82803677146636,
        "rarity_level": "COMMON"
    },
    "79": {
        "name": "Pact Rats #79",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/79.png",
        "dna": "4554ee2683ad224db5082efab1bc401273109747",
        "edition": 79,
        "date": 1665265102104,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Super",
        "rarity": 6.83125,
        "id": "79",
        "rarity_score": 166.48395443424073,
        "rarity_level": "EPIC"
    },
    "80": {
        "name": "Pact Rats #80",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/80.png",
        "dna": "fd5d3b4f9566e597eaec7fe48b71198dca1cf94f",
        "edition": 80,
        "date": 1665265029740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.426249999999998,
        "id": "80",
        "rarity_score": 156.19097879077012,
        "rarity_level": "EPIC"
    },
    "81": {
        "name": "Pact Rats #81",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/81.png",
        "dna": "f89ba65bd2514f5f1bab537a3c7f088844029cc3",
        "edition": 81,
        "date": 1665265167663,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.584999999999997,
        "id": "81",
        "rarity_score": 66.47784454150093,
        "rarity_level": "COMMON"
    },
    "82": {
        "name": "Pact Rats #82",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/82.png",
        "dna": "bf029988cab0a168637b3475e0e229f6b7eb3c4b",
        "edition": 82,
        "date": 1665265157295,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.48,
        "id": "82",
        "rarity_score": 111.09459876367748,
        "rarity_level": "RARE"
    },
    "83": {
        "name": "Pact Rats #83",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/83.png",
        "dna": "3d554c42393595b30a742594fec52fa9020fdc5b",
        "edition": 83,
        "date": 1665265114830,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Epic",
        "rarity": 9.46625,
        "id": "83",
        "rarity_score": 132.19482514223012,
        "rarity_level": "RARE"
    },
    "84": {
        "name": "Pact Rats #84",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/84.png",
        "dna": "aad509596768f8036d283f1b63459f68e9269808",
        "edition": 84,
        "date": 1665265101603,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.43875,
        "id": "84",
        "rarity_score": 72.00856822982676,
        "rarity_level": "COMMON"
    },
    "85": {
        "name": "Pact Rats #85",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/85.png",
        "dna": "42b2f894fd8e4c71f034637d373e01d8e105c3c7",
        "edition": 85,
        "date": 1665265122793,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.88,
        "id": "85",
        "rarity_score": 117.87098612278515,
        "rarity_level": "RARE"
    },
    "86": {
        "name": "Pact Rats #86",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/86.png",
        "dna": "2249ab2173b971c0a7470551b25caf4eeb7ffa34",
        "edition": 86,
        "date": 1665265141181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 10.151249999999997,
        "id": "86",
        "rarity_score": 110.45341714043391,
        "rarity_level": "COMMON"
    },
    "87": {
        "name": "Pact Rats #87",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/87.png",
        "dna": "3af37adff8f532720ca8e61122e87b394617f49f",
        "edition": 87,
        "date": 1665265048301,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.559999999999999,
        "id": "87",
        "rarity_score": 122.02291878771507,
        "rarity_level": "RARE"
    },
    "88": {
        "name": "Pact Rats #88",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/88.png",
        "dna": "c4b19780ee3bb3ac471bb8065bf14dc4478d6099",
        "edition": 88,
        "date": 1665265066037,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Rare",
        "rarity": 10.547500000000003,
        "id": "88",
        "rarity_score": 186.37064111072252,
        "rarity_level": "EPIC"
    },
    "89": {
        "name": "Pact Rats #89",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/89.png",
        "dna": "67cd5191af5340df04f13bf4e97564e9003106cd",
        "edition": 89,
        "date": 1665265095187,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Common",
        "rarity": 13.033750000000001,
        "id": "89",
        "rarity_score": 109.58768241820817,
        "rarity_level": "COMMON"
    },
    "90": {
        "name": "Pact Rats #90",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/90.png",
        "dna": "1551b65f2110799f3af725cc207c07936babfbe6",
        "edition": 90,
        "date": 1665265184812,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.38375,
        "id": "90",
        "rarity_score": 83.86807254444749,
        "rarity_level": "COMMON"
    },
    "91": {
        "name": "Pact Rats #91",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/91.png",
        "dna": "397c79a337aedf3f2140c9fc3c3c9ec348507e17",
        "edition": 91,
        "date": 1665265046998,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.0975,
        "id": "91",
        "rarity_score": 89.79137882748408,
        "rarity_level": "COMMON"
    },
    "92": {
        "name": "Pact Rats #92",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/92.png",
        "dna": "72658add39717fa8c1b076f4476d996ec714b3fb",
        "edition": 92,
        "date": 1665265049076,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.05875,
        "id": "92",
        "rarity_score": 92.1418047224856,
        "rarity_level": "COMMON"
    },
    "93": {
        "name": "Pact Rats #93",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/93.png",
        "dna": "d0a01de08c71b3fd0f0827635fe06e15aba24105",
        "edition": 93,
        "date": 1665265123553,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 10.225,
        "id": "93",
        "rarity_score": 123.47148268398267,
        "rarity_level": "RARE"
    },
    "94": {
        "name": "Pact Rats #94",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/94.png",
        "dna": "96ed3439d8a010d47fcf4c8281e5039968974333",
        "edition": 94,
        "date": 1665265032776,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.327499999999999,
        "id": "94",
        "rarity_score": 137.59580840507806,
        "rarity_level": "RARE"
    },
    "95": {
        "name": "Pact Rats #95",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/95.png",
        "dna": "554f06ac9c2c94aee2461d6f63630f86e70704e3",
        "edition": 95,
        "date": 1665265075903,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 6.01125,
        "id": "95",
        "rarity_score": 176.05758258979745,
        "rarity_level": "EPIC"
    },
    "96": {
        "name": "Pact Rats #96",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/96.png",
        "dna": "fec24d9c894add35e0dc1d23994cada357c43aec",
        "edition": 96,
        "date": 1665265153596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.9225,
        "id": "96",
        "rarity_score": 88.28992090160907,
        "rarity_level": "COMMON"
    },
    "97": {
        "name": "Pact Rats #97",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/97.png",
        "dna": "40010dc3f05ef887cce56c129b1cb054bd000081",
        "edition": 97,
        "date": 1665265094665,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Common",
        "rarity": 13.041250000000002,
        "id": "97",
        "rarity_score": 103.2838831691038,
        "rarity_level": "COMMON"
    },
    "98": {
        "name": "Pact Rats #98",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/98.png",
        "dna": "92c906da404c5be35a44640fa7e112832699dae0",
        "edition": 98,
        "date": 1665265053678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.73,
        "id": "98",
        "rarity_score": 68.31857988434753,
        "rarity_level": "COMMON"
    },
    "99": {
        "name": "Pact Rats #99",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/99.png",
        "dna": "1d30e15c453f92b3231f750e0e49bba55b3a2af4",
        "edition": 99,
        "date": 1665265166869,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.537500000000001,
        "id": "99",
        "rarity_score": 68.77046013925407,
        "rarity_level": "COMMON"
    },
    "100": {
        "name": "Pact Rats #100",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/100.png",
        "dna": "c2b42e1e9c82e25e8a8433351775be4b64b02fd8",
        "edition": 100,
        "date": 1665265145095,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.757499999999997,
        "id": "100",
        "rarity_score": 61.73302070355578,
        "rarity_level": "COMMON"
    },
    "101": {
        "name": "Pact Rats #101",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/101.png",
        "dna": "0106d2e6bd4bd2bf233b6f4074d9a732d5dd047d",
        "edition": 101,
        "date": 1665265165556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.68,
        "id": "101",
        "rarity_score": 125.86479541540855,
        "rarity_level": "RARE"
    },
    "102": {
        "name": "Pact Rats #102",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/102.png",
        "dna": "9ac75d0a9e750b68e39a95af3fc0f995849d8b92",
        "edition": 102,
        "date": 1665265169433,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.48875,
        "id": "102",
        "rarity_score": 107.62526562183001,
        "rarity_level": "COMMON"
    },
    "103": {
        "name": "Pact Rats #103",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/103.png",
        "dna": "7c718953a11abc4256cfa5c6052e10b51d4ecf26",
        "edition": 103,
        "date": 1665265064537,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Super",
        "rarity": 5.70125,
        "id": "103",
        "rarity_score": 145.6564142417866,
        "rarity_level": "RARE"
    },
    "104": {
        "name": "Pact Rats #104",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/104.png",
        "dna": "77303d9ee06419fb581c49493eda000dddedd7f3",
        "edition": 104,
        "date": 1665265037543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.98375,
        "id": "104",
        "rarity_score": 101.2471293697332,
        "rarity_level": "COMMON"
    },
    "105": {
        "name": "Pact Rats #105",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/105.png",
        "dna": "47bc93b9aeaf0985bf15d37aef1ccc6a48deedb3",
        "edition": 105,
        "date": 1665265067567,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.729999999999997,
        "id": "105",
        "rarity_score": 72.00804232117856,
        "rarity_level": "COMMON"
    },
    "106": {
        "name": "Pact Rats #106",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/106.png",
        "dna": "2adadb33b272108ced060548ebde6fc85ef2bbe3",
        "edition": 106,
        "date": 1665265051915,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.8725,
        "id": "106",
        "rarity_score": 70.07332183817648,
        "rarity_level": "COMMON"
    },
    "107": {
        "name": "Pact Rats #107",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/107.png",
        "dna": "6b06aa9a6d0dfbacace6850f98310d032d9aaf0d",
        "edition": 107,
        "date": 1665265136289,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 8.188749999999999,
        "id": "107",
        "rarity_score": 149.71926125122172,
        "rarity_level": "EPIC"
    },
    "108": {
        "name": "Pact Rats #108",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/108.png",
        "dna": "27b30cc31449b8e67ed81c7f63e51434a47b6c2f",
        "edition": 108,
        "date": 1665265189739,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.88375,
        "id": "108",
        "rarity_score": 75.22242096096727,
        "rarity_level": "COMMON"
    },
    "109": {
        "name": "Pact Rats #109",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/109.png",
        "dna": "2e38b4f1603b1d967e8fc16a848d34a992366608",
        "edition": 109,
        "date": 1665265114574,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.752500000000001,
        "id": "109",
        "rarity_score": 89.8546937351923,
        "rarity_level": "COMMON"
    },
    "110": {
        "name": "Pact Rats #110",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/110.png",
        "dna": "260f6b22d4bf981c104ee8d37bddc7b3a6a9c3b1",
        "edition": 110,
        "date": 1665265042186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 11.91625,
        "id": "110",
        "rarity_score": 115.41603094687946,
        "rarity_level": "RARE"
    },
    "111": {
        "name": "Pact Rats #111",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/111.png",
        "dna": "36152f6840fd9b64f182c0cf821d5292a4311033",
        "edition": 111,
        "date": 1665265143252,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.3525,
        "id": "111",
        "rarity_score": 78.39136804975847,
        "rarity_level": "COMMON"
    },
    "112": {
        "name": "Pact Rats #112",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/112.png",
        "dna": "81d93b1c8b006b8ef3c5d6a137911b5a39d336d0",
        "edition": 112,
        "date": 1665265150302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 10.517499999999998,
        "id": "112",
        "rarity_score": 106.76142846621673,
        "rarity_level": "COMMON"
    },
    "113": {
        "name": "Pact Rats #113",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/113.png",
        "dna": "0b0e2c842d9c8092b0a450ecf707ffca8b92a590",
        "edition": 113,
        "date": 1665265095969,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.3075,
        "id": "113",
        "rarity_score": 129.04230664623336,
        "rarity_level": "RARE"
    },
    "114": {
        "name": "Pact Rats #114",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/114.png",
        "dna": "1fe30c67d248a799b8f9599fc5c90f694232d27b",
        "edition": 114,
        "date": 1665265154967,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.37125,
        "id": "114",
        "rarity_score": 72.82320051182792,
        "rarity_level": "COMMON"
    },
    "115": {
        "name": "Pact Rats #115",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/115.png",
        "dna": "a94370f46106d8528338bbc6316ebef5672e7793",
        "edition": 115,
        "date": 1665265116105,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.8325,
        "id": "115",
        "rarity_score": 75.26978484059828,
        "rarity_level": "COMMON"
    },
    "116": {
        "name": "Pact Rats #116",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/116.png",
        "dna": "b06ffc680666f2bfe4a2a37305df46e68a5d98a5",
        "edition": 116,
        "date": 1665265190729,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Rat Pacturi",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "116",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "117": {
        "name": "Pact Rats #117",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/117.png",
        "dna": "4039be8d2ead8702bb9e65cdb382ec15cc54033d",
        "edition": 117,
        "date": 1665265137043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 9.17625,
        "id": "117",
        "rarity_score": 123.93885827149728,
        "rarity_level": "RARE"
    },
    "118": {
        "name": "Pact Rats #118",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/118.png",
        "dna": "74952724fd8ce40c237de189de6a388dd81979d9",
        "edition": 118,
        "date": 1665265171527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.416250000000002,
        "id": "118",
        "rarity_score": 87.90638539191914,
        "rarity_level": "COMMON"
    },
    "119": {
        "name": "Pact Rats #119",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/119.png",
        "dna": "c19d3ff397380e710c99321e919c4515144441bb",
        "edition": 119,
        "date": 1665265159909,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Epic",
        "rarity": 8.25625,
        "id": "119",
        "rarity_score": 142.11823099646048,
        "rarity_level": "RARE"
    },
    "120": {
        "name": "Pact Rats #120",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/120.png",
        "dna": "a437e9c98d1eb38af20e80df217b3b447c8283bb",
        "edition": 120,
        "date": 1665265076156,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Rare",
        "rarity": 10.498750000000001,
        "id": "120",
        "rarity_score": 139.06563755208282,
        "rarity_level": "RARE"
    },
    "121": {
        "name": "Pact Rats #121",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/121.png",
        "dna": "a3ef98f53303fbf2fac02f6166e3916da5bb3fa6",
        "edition": 121,
        "date": 1665265185335,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.592500000000001,
        "id": "121",
        "rarity_score": 67.46339770056909,
        "rarity_level": "COMMON"
    },
    "122": {
        "name": "Pact Rats #122",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/122.png",
        "dna": "10bac634953dbbff800b5270e00b83836eeb2b4f",
        "edition": 122,
        "date": 1665265102360,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.2725,
        "id": "122",
        "rarity_score": 109.85348910412634,
        "rarity_level": "COMMON"
    },
    "123": {
        "name": "Pact Rats #123",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/123.png",
        "dna": "3e5d72dfc1cddb0cff36a4e34cc2529b35e232e6",
        "edition": 123,
        "date": 1665265068837,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.39625,
        "id": "123",
        "rarity_score": 87.46991332169709,
        "rarity_level": "COMMON"
    },
    "124": {
        "name": "Pact Rats #124",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/124.png",
        "dna": "1fb0c40ad473258843a2a0ebd13662070a50657b",
        "edition": 124,
        "date": 1665265161241,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.8275,
        "id": "124",
        "rarity_score": 83.06755892652897,
        "rarity_level": "COMMON"
    },
    "125": {
        "name": "Pact Rats #125",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/125.png",
        "dna": "7c2a65628568537fe6d41c87226c125eea6180ee",
        "edition": 125,
        "date": 1665265043226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.799999999999999,
        "id": "125",
        "rarity_score": 138.90436205708937,
        "rarity_level": "RARE"
    },
    "126": {
        "name": "Pact Rats #126",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/126.png",
        "dna": "c90d247b1d8841ce2a8086f6994a0a1ca5c21f7b",
        "edition": 126,
        "date": 1665265073109,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.11375,
        "id": "126",
        "rarity_score": 91.956047069922,
        "rarity_level": "COMMON"
    },
    "127": {
        "name": "Pact Rats #127",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/127.png",
        "dna": "65601ebaa326e84a5a565ee33cff4ab55c914c5c",
        "edition": 127,
        "date": 1665265081515,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.113750000000001,
        "id": "127",
        "rarity_score": 103.43082510814867,
        "rarity_level": "COMMON"
    },
    "128": {
        "name": "Pact Rats #128",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/128.png",
        "dna": "bbf875821319d2cf2304af540d27746015adb058",
        "edition": 128,
        "date": 1665265044036,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.139999999999997,
        "id": "128",
        "rarity_score": 54.79957866253241,
        "rarity_level": "COMMON"
    },
    "129": {
        "name": "Pact Rats #129",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/129.png",
        "dna": "527090987dec9c800687b105912312ead6908ee9",
        "edition": 129,
        "date": 1665265153876,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.48875,
        "id": "129",
        "rarity_score": 133.1067612512217,
        "rarity_level": "RARE"
    },
    "130": {
        "name": "Pact Rats #130",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/130.png",
        "dna": "33a4ac91b12de86f38f1abd100d55143876d3bb3",
        "edition": 130,
        "date": 1665265140916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.64125,
        "id": "130",
        "rarity_score": 111.1274551180322,
        "rarity_level": "RARE"
    },
    "131": {
        "name": "Pact Rats #131",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/131.png",
        "dna": "48a5403d5977712fcc606fd378c208d8c102ebeb",
        "edition": 131,
        "date": 1665265177264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.27375,
        "id": "131",
        "rarity_score": 67.41142479296087,
        "rarity_level": "COMMON"
    },
    "132": {
        "name": "Pact Rats #132",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/132.png",
        "dna": "3734a9d892c06c089c042790585e37e0fff0c781",
        "edition": 132,
        "date": 1665265134724,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 10.97875,
        "id": "132",
        "rarity_score": 119.60761305035554,
        "rarity_level": "RARE"
    },
    "133": {
        "name": "Pact Rats #133",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/133.png",
        "dna": "bfca26e6ae902ed1640bffd27287755a0d370e30",
        "edition": 133,
        "date": 1665265130539,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Epic",
        "rarity": 7.626250000000001,
        "id": "133",
        "rarity_score": 124.21422748599716,
        "rarity_level": "RARE"
    },
    "134": {
        "name": "Pact Rats #134",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/134.png",
        "dna": "a6fe439e47b094a2bac0a4ca307f061f9155de5d",
        "edition": 134,
        "date": 1665265123813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.752500000000001,
        "id": "134",
        "rarity_score": 89.18262338186385,
        "rarity_level": "COMMON"
    },
    "135": {
        "name": "Pact Rats #135",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/135.png",
        "dna": "0cbc8db3c0e7b92a40a591dc898aa6ddd65d07b4",
        "edition": 135,
        "date": 1665265138328,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Super",
        "rarity": 4.223750000000001,
        "id": "135",
        "rarity_score": 214.46759599096552,
        "rarity_level": "LEGENDARY"
    },
    "136": {
        "name": "Pact Rats #136",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/136.png",
        "dna": "334a21ed7a776b248ae05d2885af7801fabaf098",
        "edition": 136,
        "date": 1665265124069,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Super",
        "rarity": 4.3774999999999995,
        "id": "136",
        "rarity_score": 152.98277139527139,
        "rarity_level": "EPIC"
    },
    "137": {
        "name": "Pact Rats #137",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/137.png",
        "dna": "95205d6ff538b6f6fa3d3802ea697560f808bd6d",
        "edition": 137,
        "date": 1665265181933,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.8275,
        "id": "137",
        "rarity_score": 104.47368372798807,
        "rarity_level": "COMMON"
    },
    "138": {
        "name": "Pact Rats #138",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/138.png",
        "dna": "e0bad0dbb3d540cb287416a2a99fda299da36b6f",
        "edition": 138,
        "date": 1665265115592,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 9.5975,
        "id": "138",
        "rarity_score": 124.30576277873762,
        "rarity_level": "RARE"
    },
    "139": {
        "name": "Pact Rats #139",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/139.png",
        "dna": "a0f160e8840d59586d002e2a6be246d102d52684",
        "edition": 139,
        "date": 1665265022319,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.96125,
        "id": "139",
        "rarity_score": 107.32496490262601,
        "rarity_level": "COMMON"
    },
    "140": {
        "name": "Pact Rats #140",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/140.png",
        "dna": "a684e61ffa9169d85fe0cf9e4845895b2e0dc860",
        "edition": 140,
        "date": 1665265169176,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.752500000000001,
        "id": "140",
        "rarity_score": 86.33044116884592,
        "rarity_level": "COMMON"
    },
    "141": {
        "name": "Pact Rats #141",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/141.png",
        "dna": "d0f02af024e30c7e0f820adc1e120ff00ce41c12",
        "edition": 141,
        "date": 1665265125914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.758750000000003,
        "id": "141",
        "rarity_score": 75.35452211482685,
        "rarity_level": "COMMON"
    },
    "142": {
        "name": "Pact Rats #142",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/142.png",
        "dna": "cf94d898b8ce2f14b1bb764da24a7673305ff259",
        "edition": 142,
        "date": 1665265106651,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.225000000000005,
        "id": "142",
        "rarity_score": 132.539216374252,
        "rarity_level": "RARE"
    },
    "143": {
        "name": "Pact Rats #143",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/143.png",
        "dna": "582a9eb95d852a6d88d2840812700a4ef048719f",
        "edition": 143,
        "date": 1665265119738,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Super",
        "rarity": 6.285,
        "id": "143",
        "rarity_score": 152.95241936924583,
        "rarity_level": "EPIC"
    },
    "144": {
        "name": "Pact Rats #144",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/144.png",
        "dna": "571724bc05a7869d3fa4662a2e6280094d180b96",
        "edition": 144,
        "date": 1665265155490,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.811250000000001,
        "id": "144",
        "rarity_score": 70.99685559267314,
        "rarity_level": "COMMON"
    },
    "145": {
        "name": "Pact Rats #145",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/145.png",
        "dna": "2d91cfd92f2fcfc45d935b84714cc22aaee86264",
        "edition": 145,
        "date": 1665265176206,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.947499999999998,
        "id": "145",
        "rarity_score": 107.32416747059227,
        "rarity_level": "COMMON"
    },
    "146": {
        "name": "Pact Rats #146",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/146.png",
        "dna": "5d4ec23c37d9bd9aef004ce8006ab60f2ef0fb89",
        "edition": 146,
        "date": 1665265024640,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.997499999999999,
        "id": "146",
        "rarity_score": 90.78715427844823,
        "rarity_level": "COMMON"
    },
    "147": {
        "name": "Pact Rats #147",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/147.png",
        "dna": "e24eaf46afaf321f21bdc23af2f2f7e0def0c988",
        "edition": 147,
        "date": 1665265129788,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.094999999999999,
        "id": "147",
        "rarity_score": 119.36403769800069,
        "rarity_level": "RARE"
    },
    "148": {
        "name": "Pact Rats #148",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/148.png",
        "dna": "4a8fd3a48cdc85f78b4c4ac748539565070dd7ed",
        "edition": 148,
        "date": 1665265125378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.535,
        "id": "148",
        "rarity_score": 118.23111385351882,
        "rarity_level": "RARE"
    },
    "149": {
        "name": "Pact Rats #149",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/149.png",
        "dna": "fbf8bbe476ff0b410c4b35a06573ad29534553b0",
        "edition": 149,
        "date": 1665265021802,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.752499999999998,
        "id": "149",
        "rarity_score": 74.59804262373899,
        "rarity_level": "COMMON"
    },
    "150": {
        "name": "Pact Rats #150",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/150.png",
        "dna": "369da8c812e5db1ce7c8207a9e4afc93b367e05f",
        "edition": 150,
        "date": 1665265178300,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.561250000000001,
        "id": "150",
        "rarity_score": 92.94082701815884,
        "rarity_level": "COMMON"
    },
    "151": {
        "name": "Pact Rats #151",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/151.png",
        "dna": "364489e8a9f6e342aa24f8a3612481de55d90fdf",
        "edition": 151,
        "date": 1665265123306,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.835,
        "id": "151",
        "rarity_score": 93.41409103947277,
        "rarity_level": "COMMON"
    },
    "152": {
        "name": "Pact Rats #152",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/152.png",
        "dna": "0963c0ef10a8c41e06133d2c3b9e71fb0860b007",
        "edition": 152,
        "date": 1665265190938,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Master Splinter",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "152",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "153": {
        "name": "Pact Rats #153",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/153.png",
        "dna": "b2e1d32d0f653d02839213788582432fb9d7ce73",
        "edition": 153,
        "date": 1665265174640,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.184999999999999,
        "id": "153",
        "rarity_score": 114.51394513432754,
        "rarity_level": "RARE"
    },
    "154": {
        "name": "Pact Rats #154",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/154.png",
        "dna": "a93c37bac22a1848a00da371e961a1cef2f88f07",
        "edition": 154,
        "date": 1665265113324,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.91375,
        "id": "154",
        "rarity_score": 67.2515578564486,
        "rarity_level": "COMMON"
    },
    "155": {
        "name": "Pact Rats #155",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/155.png",
        "dna": "d0a2aaf81da856c9813cc60afa173a833ff11d17",
        "edition": 155,
        "date": 1665265125656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Captain PACT Sparrow Hat",
                "score": 133.2
            }
        ],
        "rank": "Rare",
        "rarity": 12.00375,
        "id": "155",
        "rarity_score": 206.992831914289,
        "rarity_level": "LEGENDARY"
    },
    "156": {
        "name": "Pact Rats #156",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/156.png",
        "dna": "ce4519244a02c8adab84191076ebcff8e2b90a16",
        "edition": 156,
        "date": 1665265085818,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Super",
        "rarity": 3.1174999999999997,
        "id": "156",
        "rarity_score": 246.133804582869,
        "rarity_level": "LEGENDARY"
    },
    "157": {
        "name": "Pact Rats #157",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/157.png",
        "dna": "c52e21e6ca3babec6881be08e5faab7278bafb87",
        "edition": 157,
        "date": 1665265120507,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Rare",
        "rarity": 10.2025,
        "id": "157",
        "rarity_score": 185.62132336453385,
        "rarity_level": "EPIC"
    },
    "158": {
        "name": "Pact Rats #158",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/158.png",
        "dna": "5c30ed8c3445e87313c583d090cdae8b7291d170",
        "edition": 158,
        "date": 1665265112064,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.92125,
        "id": "158",
        "rarity_score": 80.20600723888964,
        "rarity_level": "COMMON"
    },
    "159": {
        "name": "Pact Rats #159",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/159.png",
        "dna": "752c99e97a4d044ed2c7f49c5610de4f00c0c27e",
        "edition": 159,
        "date": 1665265099280,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.02875,
        "id": "159",
        "rarity_score": 68.36919155053728,
        "rarity_level": "COMMON"
    },
    "160": {
        "name": "Pact Rats #160",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/160.png",
        "dna": "b074580d331615ecb48b1811521523747d98d106",
        "edition": 160,
        "date": 1665265178042,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.243750000000002,
        "id": "160",
        "rarity_score": 65.01971128248442,
        "rarity_level": "COMMON"
    },
    "161": {
        "name": "Pact Rats #161",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/161.png",
        "dna": "23a3b11855b64d9e160addbcc8a9043df6da5f19",
        "edition": 161,
        "date": 1665265131587,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.631249999999998,
        "id": "161",
        "rarity_score": 114.0760623148781,
        "rarity_level": "RARE"
    },
    "162": {
        "name": "Pact Rats #162",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/162.png",
        "dna": "e1f2f96af31b7ead81679b67f6a7caaca538da02",
        "edition": 162,
        "date": 1665265036039,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Common",
        "rarity": 13.172500000000003,
        "id": "162",
        "rarity_score": 118.57700166898398,
        "rarity_level": "RARE"
    },
    "163": {
        "name": "Pact Rats #163",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/163.png",
        "dna": "ff3ea8d8b8b6cfbc1b054deaf1c5841b5323e951",
        "edition": 163,
        "date": 1665265099781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 7.97625,
        "id": "163",
        "rarity_score": 124.4925594818416,
        "rarity_level": "RARE"
    },
    "164": {
        "name": "Pact Rats #164",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/164.png",
        "dna": "b9fef94ee50ea23e4c695966e00e6dea16450f1e",
        "edition": 164,
        "date": 1665265050064,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 7.327499999999999,
        "id": "164",
        "rarity_score": 147.5887862137862,
        "rarity_level": "RARE"
    },
    "165": {
        "name": "Pact Rats #165",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/165.png",
        "dna": "5532c61db230934d1cbdd95ff2e1ef8c8973700e",
        "edition": 165,
        "date": 1665265150045,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 6.971250000000001,
        "id": "165",
        "rarity_score": 135.68913146133218,
        "rarity_level": "RARE"
    },
    "166": {
        "name": "Pact Rats #166",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/166.png",
        "dna": "ffdb80ee5a29c45283d44ee5d7188e5a6d1b4bb5",
        "edition": 166,
        "date": 1665265055697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 9.63625,
        "id": "166",
        "rarity_score": 113.05813306001725,
        "rarity_level": "RARE"
    },
    "167": {
        "name": "Pact Rats #167",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/167.png",
        "dna": "87eec25644f7844114d1d950916c80f3d5777f07",
        "edition": 167,
        "date": 1665265146407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Rare",
        "rarity": 11.792499999999997,
        "id": "167",
        "rarity_score": 122.18639418868955,
        "rarity_level": "RARE"
    },
    "168": {
        "name": "Pact Rats #168",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/168.png",
        "dna": "c6d9806195b3baad2c2867a8a904976b7202c4b2",
        "edition": 168,
        "date": 1665265049569,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.14,
        "id": "168",
        "rarity_score": 130.50550499803353,
        "rarity_level": "RARE"
    },
    "169": {
        "name": "Pact Rats #169",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/169.png",
        "dna": "4bc52bba12b3482a6b8a5ee4c50a33d5d0cdda6f",
        "edition": 169,
        "date": 1665265073908,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.53875,
        "id": "169",
        "rarity_score": 78.61212622019649,
        "rarity_level": "COMMON"
    },
    "170": {
        "name": "Pact Rats #170",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/170.png",
        "dna": "93005e7f1257ceda46e8a7b37ff242440846ce45",
        "edition": 170,
        "date": 1665265148504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.4775,
        "id": "170",
        "rarity_score": 95.11316495459165,
        "rarity_level": "COMMON"
    },
    "171": {
        "name": "Pact Rats #171",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/171.png",
        "dna": "fe3dd277a8c60f7fdce40d67a7c8bb9e71d56e7a",
        "edition": 171,
        "date": 1665265062763,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.07875,
        "id": "171",
        "rarity_score": 126.7847887217053,
        "rarity_level": "RARE"
    },
    "172": {
        "name": "Pact Rats #172",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/172.png",
        "dna": "b684cc03a64e82c2c1437239a38b68cfe6500eda",
        "edition": 172,
        "date": 1665265100288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 9.62625,
        "id": "172",
        "rarity_score": 110.38381566038753,
        "rarity_level": "COMMON"
    },
    "173": {
        "name": "Pact Rats #173",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/173.png",
        "dna": "b2e92300cc75f3c8a1108c6a2f5ad55f3169bbe2",
        "edition": 173,
        "date": 1665265046023,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 11.963750000000001,
        "id": "173",
        "rarity_score": 127.22397004362622,
        "rarity_level": "RARE"
    },
    "174": {
        "name": "Pact Rats #174",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/174.png",
        "dna": "7499a088e9d38bf29357f712753c49bc126418ab",
        "edition": 174,
        "date": 1665265097750,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.959999999999999,
        "id": "174",
        "rarity_score": 127.04604345689916,
        "rarity_level": "RARE"
    },
    "175": {
        "name": "Pact Rats #175",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/175.png",
        "dna": "eb4fc94bc89826ce2ec675fc53294c01ba4c8af3",
        "edition": 175,
        "date": 1665265034527,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.673750000000002,
        "id": "175",
        "rarity_score": 69.50833942149636,
        "rarity_level": "COMMON"
    },
    "176": {
        "name": "Pact Rats #176",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/176.png",
        "dna": "75d71dc25b9aa93061cf4af1b8858ed3ac0501e5",
        "edition": 176,
        "date": 1665265143521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.587499999999997,
        "id": "176",
        "rarity_score": 117.62398846422414,
        "rarity_level": "RARE"
    },
    "177": {
        "name": "Pact Rats #177",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/177.png",
        "dna": "c1234c809d182d197b7ea5ad07ca81bbd7b920dc",
        "edition": 177,
        "date": 1665265166076,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.67125,
        "id": "177",
        "rarity_score": 96.93756668221008,
        "rarity_level": "COMMON"
    },
    "178": {
        "name": "Pact Rats #178",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/178.png",
        "dna": "e73295f0a633b46e9c739a1348e9582adc7e8801",
        "edition": 178,
        "date": 1665265138583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Rare",
        "rarity": 10.469999999999999,
        "id": "178",
        "rarity_score": 135.06126001165677,
        "rarity_level": "RARE"
    },
    "179": {
        "name": "Pact Rats #179",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/179.png",
        "dna": "6a6ce9993446dadb462c3df3b307dcd325abcae8",
        "edition": 179,
        "date": 1665265082832,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.054999999999996,
        "id": "179",
        "rarity_score": 78.40491696145406,
        "rarity_level": "COMMON"
    },
    "180": {
        "name": "Pact Rats #180",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/180.png",
        "dna": "3fbf7e0c2913af970762ef5d9e1a00425e292944",
        "edition": 180,
        "date": 1665265109997,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Common",
        "rarity": 13.0125,
        "id": "180",
        "rarity_score": 103.69641755493538,
        "rarity_level": "COMMON"
    },
    "181": {
        "name": "Pact Rats #181",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/181.png",
        "dna": "71db7f7b91dbe03304527ebf4f8215bf54f3926c",
        "edition": 181,
        "date": 1665265092130,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.28625,
        "id": "181",
        "rarity_score": 60.78411206006303,
        "rarity_level": "COMMON"
    },
    "182": {
        "name": "Pact Rats #182",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/182.png",
        "dna": "a42475e3de63ceba1f4c182fce3b1f40d76d5ea5",
        "edition": 182,
        "date": 1665265061781,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Epic",
        "rarity": 8.55625,
        "id": "182",
        "rarity_score": 160.97752826172646,
        "rarity_level": "EPIC"
    },
    "183": {
        "name": "Pact Rats #183",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/183.png",
        "dna": "b20287bed810bbe624339481caa8abb8003f12b0",
        "edition": 183,
        "date": 1665265064791,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.25375,
        "id": "183",
        "rarity_score": 86.2966033966034,
        "rarity_level": "COMMON"
    },
    "184": {
        "name": "Pact Rats #184",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/184.png",
        "dna": "a11a6e4fb00d281f02386ae801c9300c937f7f3f",
        "edition": 184,
        "date": 1665265161793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.09375,
        "id": "184",
        "rarity_score": 115.4362912486892,
        "rarity_level": "RARE"
    },
    "185": {
        "name": "Pact Rats #185",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/185.png",
        "dna": "cfa93aab6ae49802feaa5c3dba7e80c641ecb4f1",
        "edition": 185,
        "date": 1665265180122,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.154999999999998,
        "id": "185",
        "rarity_score": 75.30295309097971,
        "rarity_level": "COMMON"
    },
    "186": {
        "name": "Pact Rats #186",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/186.png",
        "dna": "9bbef2434ed5fdebab1fedf2ce967aaf84f4aad1",
        "edition": 186,
        "date": 1665265130045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.67625,
        "id": "186",
        "rarity_score": 88.60787625667686,
        "rarity_level": "COMMON"
    },
    "187": {
        "name": "Pact Rats #187",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/187.png",
        "dna": "c6c4b9cc50aafec7f3626408b8489d710f342e5e",
        "edition": 187,
        "date": 1665265187145,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.872499999999999,
        "id": "187",
        "rarity_score": 97.40163984166269,
        "rarity_level": "COMMON"
    },
    "188": {
        "name": "Pact Rats #188",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/188.png",
        "dna": "f98f8490eda6a0edb557f067408f0ec048bc0075",
        "edition": 188,
        "date": 1665265103868,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.237499999999997,
        "id": "188",
        "rarity_score": 64.5085396541476,
        "rarity_level": "COMMON"
    },
    "189": {
        "name": "Pact Rats #189",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/189.png",
        "dna": "4c368bca1ab2abb9e79e8c276e210fbb2846a94b",
        "edition": 189,
        "date": 1665265176983,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.55875,
        "id": "189",
        "rarity_score": 84.66712751356833,
        "rarity_level": "COMMON"
    },
    "190": {
        "name": "Pact Rats #190",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/190.png",
        "dna": "eb77c904173ecf23a20777f867727fdc5739fcfa",
        "edition": 190,
        "date": 1665265083822,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.34,
        "id": "190",
        "rarity_score": 126.54703283372098,
        "rarity_level": "RARE"
    },
    "191": {
        "name": "Pact Rats #191",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/191.png",
        "dna": "7cd11c69326a8f6a6c562767ca0d387474d2077a",
        "edition": 191,
        "date": 1665265074909,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.31875,
        "id": "191",
        "rarity_score": 112.43780676528402,
        "rarity_level": "RARE"
    },
    "192": {
        "name": "Pact Rats #192",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/192.png",
        "dna": "d993f7ecd039d3e3e46bd0d59098d0b0fa8e3350",
        "edition": 192,
        "date": 1665265066546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.56875,
        "id": "192",
        "rarity_score": 67.9408478239984,
        "rarity_level": "COMMON"
    },
    "193": {
        "name": "Pact Rats #193",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/193.png",
        "dna": "84acaea49777f74dcb85d8de71b95cda2dc8d21d",
        "edition": 193,
        "date": 1665265049329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.134999999999998,
        "id": "193",
        "rarity_score": 80.9414051597156,
        "rarity_level": "COMMON"
    },
    "194": {
        "name": "Pact Rats #194",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/194.png",
        "dna": "56d02ccdb6eef14766c92ecbde782669582d4532",
        "edition": 194,
        "date": 1665265068594,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Super",
        "rarity": 6.817499999999997,
        "id": "194",
        "rarity_score": 141.18150883634067,
        "rarity_level": "RARE"
    },
    "195": {
        "name": "Pact Rats #195",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/195.png",
        "dna": "09599095f28943e4f0599850a2535215c7c2fef2",
        "edition": 195,
        "date": 1665265054931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.9775,
        "id": "195",
        "rarity_score": 73.37332533065789,
        "rarity_level": "COMMON"
    },
    "196": {
        "name": "Pact Rats #196",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/196.png",
        "dna": "ea7a9b942789be833401fa65c197096f3c45a0e6",
        "edition": 196,
        "date": 1665265050600,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.967499999999998,
        "id": "196",
        "rarity_score": 68.20099018331547,
        "rarity_level": "COMMON"
    },
    "197": {
        "name": "Pact Rats #197",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/197.png",
        "dna": "11f58bd05fa634f44fc52f9bdfe78375e541dfd6",
        "edition": 197,
        "date": 1665265145371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.864999999999998,
        "id": "197",
        "rarity_score": 75.97461248521138,
        "rarity_level": "COMMON"
    },
    "198": {
        "name": "Pact Rats #198",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/198.png",
        "dna": "f1631ee6445c3d57027dd81ea7b70be42ac2e450",
        "edition": 198,
        "date": 1665265022831,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.872499999999999,
        "id": "198",
        "rarity_score": 79.20410649431257,
        "rarity_level": "COMMON"
    },
    "199": {
        "name": "Pact Rats #199",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/199.png",
        "dna": "476df2dbb32d6fd85dc6bacb12ad3b48e6daf564",
        "edition": 199,
        "date": 1665265173616,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.916249999999998,
        "id": "199",
        "rarity_score": 73.99773896912144,
        "rarity_level": "COMMON"
    },
    "200": {
        "name": "Pact Rats #200",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/200.png",
        "dna": "6e719ad7adc2e0ceb1191f611f0c9ed077588a8a",
        "edition": 200,
        "date": 1665265127754,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.918749999999999,
        "id": "200",
        "rarity_score": 133.89732965798726,
        "rarity_level": "RARE"
    },
    "201": {
        "name": "Pact Rats #201",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/201.png",
        "dna": "5309d69ddef6876d042b248b95e6183347ac511d",
        "edition": 201,
        "date": 1665265050340,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.41375,
        "id": "201",
        "rarity_score": 63.509497779593524,
        "rarity_level": "COMMON"
    },
    "202": {
        "name": "Pact Rats #202",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/202.png",
        "dna": "6aa06941977c11f6e0bc15e33126a99e2a639681",
        "edition": 202,
        "date": 1665265163170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 10.60125,
        "id": "202",
        "rarity_score": 121.50926823355817,
        "rarity_level": "RARE"
    },
    "203": {
        "name": "Pact Rats #203",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/203.png",
        "dna": "928f0f59a4fd4dd8a650c5d96777d13204a1c0c4",
        "edition": 203,
        "date": 1665265092379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.225000000000001,
        "id": "203",
        "rarity_score": 112.29852980591176,
        "rarity_level": "RARE"
    },
    "204": {
        "name": "Pact Rats #204",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/204.png",
        "dna": "031bcc122bac031142738fafc9ff3b8c63cd18bb",
        "edition": 204,
        "date": 1665265125115,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.535,
        "id": "204",
        "rarity_score": 113.40612690071288,
        "rarity_level": "RARE"
    },
    "205": {
        "name": "Pact Rats #205",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/205.png",
        "dna": "8b89a5be34155a0c345e2ae545709240e8d63e27",
        "edition": 205,
        "date": 1665265069826,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Rare",
        "rarity": 10.432500000000001,
        "id": "205",
        "rarity_score": 146.06221516288767,
        "rarity_level": "RARE"
    },
    "206": {
        "name": "Pact Rats #206",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/206.png",
        "dna": "2400f189289350aa5b0c89bef9daf5421b3ff81d",
        "edition": 206,
        "date": 1665265175690,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.2,
        "id": "206",
        "rarity_score": 79.12436725210294,
        "rarity_level": "COMMON"
    },
    "207": {
        "name": "Pact Rats #207",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/207.png",
        "dna": "0a5b570f1c31e9be0cd70da0a6aa2dbef5c3b263",
        "edition": 207,
        "date": 1665265037047,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Common",
        "rarity": 13.061249999999998,
        "id": "207",
        "rarity_score": 97.08698033088498,
        "rarity_level": "COMMON"
    },
    "208": {
        "name": "Pact Rats #208",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/208.png",
        "dna": "1a73daaf47b71a1393b8ba69e91c2036e24b213f",
        "edition": 208,
        "date": 1665265187937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.662500000000001,
        "id": "208",
        "rarity_score": 117.48829763129619,
        "rarity_level": "RARE"
    },
    "209": {
        "name": "Pact Rats #209",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/209.png",
        "dna": "8aff6e1fe3cfcba6834e261ca5f6b3f3139b586d",
        "edition": 209,
        "date": 1665265088886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.529999999999998,
        "id": "209",
        "rarity_score": 111.36175612652289,
        "rarity_level": "RARE"
    },
    "210": {
        "name": "Pact Rats #210",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/210.png",
        "dna": "1522e6339801d85a0fb322fe92e443960d997c67",
        "edition": 210,
        "date": 1665265055961,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.544999999999998,
        "id": "210",
        "rarity_score": 96.50520993247815,
        "rarity_level": "COMMON"
    },
    "211": {
        "name": "Pact Rats #211",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/211.png",
        "dna": "67dfd1690054e57dbbe182fbe48afd7d098de1c6",
        "edition": 211,
        "date": 1665265102866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.0575,
        "id": "211",
        "rarity_score": 130.89571230625327,
        "rarity_level": "RARE"
    },
    "212": {
        "name": "Pact Rats #212",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/212.png",
        "dna": "06fba98f0c19bdd6a0fcd2a7eeaf0d8469b160d1",
        "edition": 212,
        "date": 1665265127501,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 6.781249999999998,
        "id": "212",
        "rarity_score": 145.66378611975216,
        "rarity_level": "RARE"
    },
    "213": {
        "name": "Pact Rats #213",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/213.png",
        "dna": "cf59912556963f4a0a4738a04e8b86aabccecc09",
        "edition": 213,
        "date": 1665265133171,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.3,
        "id": "213",
        "rarity_score": 122.69825200495575,
        "rarity_level": "RARE"
    },
    "214": {
        "name": "Pact Rats #214",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/214.png",
        "dna": "f105823901712b747600726c03bc01f7d490d679",
        "edition": 214,
        "date": 1665265074161,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.574999999999998,
        "id": "214",
        "rarity_score": 120.54677318992603,
        "rarity_level": "RARE"
    },
    "215": {
        "name": "Pact Rats #215",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/215.png",
        "dna": "1abc4b07c687ba12a1042f06e13d907724dcbf6a",
        "edition": 215,
        "date": 1665265120759,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Super",
        "rarity": 6.641249999999999,
        "id": "215",
        "rarity_score": 132.4416623805071,
        "rarity_level": "RARE"
    },
    "216": {
        "name": "Pact Rats #216",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/216.png",
        "dna": "c992cd0e2975b4eff529feeff913177cc798104c",
        "edition": 216,
        "date": 1665265076670,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.26125,
        "id": "216",
        "rarity_score": 67.70743193703754,
        "rarity_level": "COMMON"
    },
    "217": {
        "name": "Pact Rats #217",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/217.png",
        "dna": "59783fe7b0cb7706a0aa8854bafd31b74d5c74cf",
        "edition": 217,
        "date": 1665265024905,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 11.651249999999997,
        "id": "217",
        "rarity_score": 108.50492853382573,
        "rarity_level": "COMMON"
    },
    "218": {
        "name": "Pact Rats #218",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/218.png",
        "dna": "e88a1a3ad8c58678fdab923ce5bd53bb90db982a",
        "edition": 218,
        "date": 1665265068350,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.459999999999997,
        "id": "218",
        "rarity_score": 66.04965328895233,
        "rarity_level": "COMMON"
    },
    "219": {
        "name": "Pact Rats #219",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/219.png",
        "dna": "722212e6e1a8dee9460d14e27ba0b82df934e172",
        "edition": 219,
        "date": 1665265082301,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.957499999999996,
        "id": "219",
        "rarity_score": 52.10944319921142,
        "rarity_level": "COMMON"
    },
    "220": {
        "name": "Pact Rats #220",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/220.png",
        "dna": "2347b0408ebcc2b6434f64320fd19343fd243ce2",
        "edition": 220,
        "date": 1665265179061,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.38625,
        "id": "220",
        "rarity_score": 62.28857742781789,
        "rarity_level": "COMMON"
    },
    "221": {
        "name": "Pact Rats #221",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/221.png",
        "dna": "deb215162a049ddd03a8a2040a359b456909aa2c",
        "edition": 221,
        "date": 1665265070342,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.38375,
        "id": "221",
        "rarity_score": 86.06341015908879,
        "rarity_level": "COMMON"
    },
    "222": {
        "name": "Pact Rats #222",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/222.png",
        "dna": "08ada4f319164d3d1fd7111cb0273359dbe6c6ea",
        "edition": 222,
        "date": 1665265048045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.368750000000002,
        "id": "222",
        "rarity_score": 110.46853843217504,
        "rarity_level": "COMMON"
    },
    "223": {
        "name": "Pact Rats #223",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/223.png",
        "dna": "63d6de848450aeeb2b916f4a5e715e8303e346f1",
        "edition": 223,
        "date": 1665265124583,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 9.5475,
        "id": "223",
        "rarity_score": 161.18926537861148,
        "rarity_level": "EPIC"
    },
    "224": {
        "name": "Pact Rats #224",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/224.png",
        "dna": "4d3f22030fa09660313d6786d04e26971865df3e",
        "edition": 224,
        "date": 1665265078215,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Rare",
        "rarity": 10.3025,
        "id": "224",
        "rarity_score": 122.25108398504699,
        "rarity_level": "RARE"
    },
    "225": {
        "name": "Pact Rats #225",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/225.png",
        "dna": "99d9a3c3d1a2982b8b5d83148bc89c58f468009f",
        "edition": 225,
        "date": 1665265122543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.29,
        "id": "225",
        "rarity_score": 89.88116883116884,
        "rarity_level": "COMMON"
    },
    "226": {
        "name": "Pact Rats #226",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/226.png",
        "dna": "a0004796df975e56f882ff4c24d812efd08023e7",
        "edition": 226,
        "date": 1665265040404,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Rare",
        "rarity": 10.12125,
        "id": "226",
        "rarity_score": 139.63026096839874,
        "rarity_level": "RARE"
    },
    "227": {
        "name": "Pact Rats #227",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/227.png",
        "dna": "2ae2363dda3ec02a2f304684d460eb0968b55229",
        "edition": 227,
        "date": 1665265163440,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.561249999999998,
        "id": "227",
        "rarity_score": 81.17453950427671,
        "rarity_level": "COMMON"
    },
    "228": {
        "name": "Pact Rats #228",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/228.png",
        "dna": "541f5ecacad1f5cc20a3ae4a80ca3e21a302425f",
        "edition": 228,
        "date": 1665265040653,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 9.9925,
        "id": "228",
        "rarity_score": 104.19329848629552,
        "rarity_level": "COMMON"
    },
    "229": {
        "name": "Pact Rats #229",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/229.png",
        "dna": "65fb57f5915daf1ed2222411ad49b9ebdb162c9d",
        "edition": 229,
        "date": 1665265033023,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 9.72125,
        "id": "229",
        "rarity_score": 138.17322681302306,
        "rarity_level": "RARE"
    },
    "230": {
        "name": "Pact Rats #230",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/230.png",
        "dna": "d29f86d0336c2a332a92c4749cab6e1a44af7f99",
        "edition": 230,
        "date": 1665265093143,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.08625,
        "id": "230",
        "rarity_score": 107.76131187030906,
        "rarity_level": "COMMON"
    },
    "231": {
        "name": "Pact Rats #231",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/231.png",
        "dna": "74cf72089a29a16d9801dcb7a2ffea6b17b91914",
        "edition": 231,
        "date": 1665265070596,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 10.366249999999999,
        "id": "231",
        "rarity_score": 104.287008946036,
        "rarity_level": "COMMON"
    },
    "232": {
        "name": "Pact Rats #232",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/232.png",
        "dna": "37c25d50aa4278c8abffabeb8347dd46aa21ebe1",
        "edition": 232,
        "date": 1665265141421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.89,
        "id": "232",
        "rarity_score": 114.11660409874611,
        "rarity_level": "RARE"
    },
    "233": {
        "name": "Pact Rats #233",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/233.png",
        "dna": "f1a3b97db6d76a23e57d58633d57fe23259ca19c",
        "edition": 233,
        "date": 1665265095459,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.276249999999997,
        "id": "233",
        "rarity_score": 66.5083422155878,
        "rarity_level": "COMMON"
    },
    "234": {
        "name": "Pact Rats #234",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/234.png",
        "dna": "af48faebc74966bd5948b167b6ab5e25368e90e0",
        "edition": 234,
        "date": 1665265041426,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.59625,
        "id": "234",
        "rarity_score": 130.1479181314488,
        "rarity_level": "RARE"
    },
    "235": {
        "name": "Pact Rats #235",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/235.png",
        "dna": "39696e7b3af80e86dd5f6339d94131de7ada3598",
        "edition": 235,
        "date": 1665265185064,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.927499999999998,
        "id": "235",
        "rarity_score": 83.68822138124894,
        "rarity_level": "COMMON"
    },
    "236": {
        "name": "Pact Rats #236",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/236.png",
        "dna": "5c20f774b07a3a95d7f5f5449351a4188ec12d0d",
        "edition": 236,
        "date": 1665265051391,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.49125,
        "id": "236",
        "rarity_score": 100.26369036478826,
        "rarity_level": "COMMON"
    },
    "237": {
        "name": "Pact Rats #237",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/237.png",
        "dna": "82f1766478ae7c6586d8ed94378608fa41458b57",
        "edition": 237,
        "date": 1665265134993,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.385000000000002,
        "id": "237",
        "rarity_score": 76.55910651303321,
        "rarity_level": "COMMON"
    },
    "238": {
        "name": "Pact Rats #238",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/238.png",
        "dna": "078a105deec163a0a564e52b5b9d71a9811016e7",
        "edition": 238,
        "date": 1665265070845,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 12.883750000000001,
        "id": "238",
        "rarity_score": 111.38516274046437,
        "rarity_level": "RARE"
    },
    "239": {
        "name": "Pact Rats #239",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/239.png",
        "dna": "ce5a4f01a99d7e945c20435613e2e2ed4554fcfa",
        "edition": 239,
        "date": 1665265077191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.5275,
        "id": "239",
        "rarity_score": 87.30549007815281,
        "rarity_level": "COMMON"
    },
    "240": {
        "name": "Pact Rats #240",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/240.png",
        "dna": "86ce8abaef4ef01a0f6d62abc9f026c9b0609d8c",
        "edition": 240,
        "date": 1665265174126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.55875,
        "id": "240",
        "rarity_score": 71.19759433118521,
        "rarity_level": "COMMON"
    },
    "241": {
        "name": "Pact Rats #241",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/241.png",
        "dna": "2b74ce861ad5decca1ce6116df93effbd73963a4",
        "edition": 241,
        "date": 1665265180893,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.00375,
        "id": "241",
        "rarity_score": 92.15997317499601,
        "rarity_level": "COMMON"
    },
    "242": {
        "name": "Pact Rats #242",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/242.png",
        "dna": "5db6fb3a4c1b24c58471ab73185e5699957a00ba",
        "edition": 242,
        "date": 1665265103364,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.8325,
        "id": "242",
        "rarity_score": 108.4992446629879,
        "rarity_level": "COMMON"
    },
    "243": {
        "name": "Pact Rats #243",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/243.png",
        "dna": "89ac62202aa2d57067d1670d7caeee3db27f6c6e",
        "edition": 243,
        "date": 1665265171780,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.6825,
        "id": "243",
        "rarity_score": 82.56614222845934,
        "rarity_level": "COMMON"
    },
    "244": {
        "name": "Pact Rats #244",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/244.png",
        "dna": "0f83d8e4f1e6899fa86f2f03bc699f8701295959",
        "edition": 244,
        "date": 1665265167403,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.88375,
        "id": "244",
        "rarity_score": 97.6781418325202,
        "rarity_level": "COMMON"
    },
    "245": {
        "name": "Pact Rats #245",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/245.png",
        "dna": "316bb87a6ff1f60c0f68a331008971481d849b61",
        "edition": 245,
        "date": 1665265103607,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Super",
        "rarity": 6.05875,
        "id": "245",
        "rarity_score": 143.81486013986014,
        "rarity_level": "RARE"
    },
    "246": {
        "name": "Pact Rats #246",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/246.png",
        "dna": "331ce686ecbd12cfad45f23ee31c33b0055a9f5e",
        "edition": 246,
        "date": 1665265053937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.85,
        "id": "246",
        "rarity_score": 113.40097020889459,
        "rarity_level": "RARE"
    },
    "247": {
        "name": "Pact Rats #247",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/247.png",
        "dna": "b8643b3336fd88ad7f1a2b44fceb18c1e455b869",
        "edition": 247,
        "date": 1665265140142,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.23,
        "id": "247",
        "rarity_score": 133.56911798464554,
        "rarity_level": "RARE"
    },
    "248": {
        "name": "Pact Rats #248",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/248.png",
        "dna": "5e035c5e610a2249e233a9d9a01286ce170f20f9",
        "edition": 248,
        "date": 1665265056468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.46875,
        "id": "248",
        "rarity_score": 80.57126249824321,
        "rarity_level": "COMMON"
    },
    "249": {
        "name": "Pact Rats #249",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/249.png",
        "dna": "7622b3f078bd4290efc412c8b96af232b9dd3d5e",
        "edition": 249,
        "date": 1665265170752,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.425,
        "id": "249",
        "rarity_score": 74.32521233437123,
        "rarity_level": "COMMON"
    },
    "250": {
        "name": "Pact Rats #250",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/250.png",
        "dna": "d41f359c6079bc840b44881b5f3a159e0db7fd53",
        "edition": 250,
        "date": 1665265148761,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.65375,
        "id": "250",
        "rarity_score": 73.76542331747278,
        "rarity_level": "COMMON"
    },
    "251": {
        "name": "Pact Rats #251",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/251.png",
        "dna": "a0663d7e6280551988290b18d80888c358aec696",
        "edition": 251,
        "date": 1665265060811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.30875,
        "id": "251",
        "rarity_score": 71.56961467512625,
        "rarity_level": "COMMON"
    },
    "252": {
        "name": "Pact Rats #252",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/252.png",
        "dna": "50969a6d646ebcdfd1b5591df87160b6763c3fac",
        "edition": 252,
        "date": 1665265150568,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.97375,
        "id": "252",
        "rarity_score": 66.41537108179779,
        "rarity_level": "COMMON"
    },
    "253": {
        "name": "Pact Rats #253",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/253.png",
        "dna": "28f884fbc038cc12ed81c1d2592ee79553b1ab74",
        "edition": 253,
        "date": 1665265179586,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.030000000000001,
        "id": "253",
        "rarity_score": 73.1891666684433,
        "rarity_level": "COMMON"
    },
    "254": {
        "name": "Pact Rats #254",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/254.png",
        "dna": "bbc42fdc4e78bd9d28cf8f310757f2fe20ce0507",
        "edition": 254,
        "date": 1665265165294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.19375,
        "id": "254",
        "rarity_score": 85.42627095500106,
        "rarity_level": "COMMON"
    },
    "255": {
        "name": "Pact Rats #255",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/255.png",
        "dna": "7ae05b39889091e4c9a5da26e07b0effc4974800",
        "edition": 255,
        "date": 1665265115087,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.3,
        "id": "255",
        "rarity_score": 78.88577750451353,
        "rarity_level": "COMMON"
    },
    "256": {
        "name": "Pact Rats #256",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/256.png",
        "dna": "1280656d0f9a34ea6f688611cce4dc76a8491798",
        "edition": 256,
        "date": 1665265173871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.37125,
        "id": "256",
        "rarity_score": 97.11372053486292,
        "rarity_level": "COMMON"
    },
    "257": {
        "name": "Pact Rats #257",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/257.png",
        "dna": "5b74c3276e6ff470b0e1a349b1960358c535a42f",
        "edition": 257,
        "date": 1665265158395,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.41875,
        "id": "257",
        "rarity_score": 97.91590893140003,
        "rarity_level": "COMMON"
    },
    "258": {
        "name": "Pact Rats #258",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/258.png",
        "dna": "e87f4289351d81f964ab5d5fab3f721ef5c80a97",
        "edition": 258,
        "date": 1665265074665,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.01125,
        "id": "258",
        "rarity_score": 84.42076596061933,
        "rarity_level": "COMMON"
    },
    "259": {
        "name": "Pact Rats #259",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/259.png",
        "dna": "3f5a398adf4f1f1231800ed1e0603cb48a77e70e",
        "edition": 259,
        "date": 1665265142252,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.94,
        "id": "259",
        "rarity_score": 145.85662897550617,
        "rarity_level": "RARE"
    },
    "260": {
        "name": "Pact Rats #260",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/260.png",
        "dna": "d82de35b7dad90e8cbd4790d23ac2a1af46564a9",
        "edition": 260,
        "date": 1665265030011,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.057500000000001,
        "id": "260",
        "rarity_score": 76.3495710372976,
        "rarity_level": "COMMON"
    },
    "261": {
        "name": "Pact Rats #261",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/261.png",
        "dna": "ee9c27ceab8f7d1a856ed571f01094c26a940f26",
        "edition": 261,
        "date": 1665265110254,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.43,
        "id": "261",
        "rarity_score": 132.44864736433877,
        "rarity_level": "RARE"
    },
    "262": {
        "name": "Pact Rats #262",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/262.png",
        "dna": "ca7283c3feccbc5d4546704d826747f86116cfc5",
        "edition": 262,
        "date": 1665265081000,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.278750000000002,
        "id": "262",
        "rarity_score": 75.64564032078066,
        "rarity_level": "COMMON"
    },
    "263": {
        "name": "Pact Rats #263",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/263.png",
        "dna": "7c1c88c5be2ef201c2af9ed90a8dbad7dd5bc36f",
        "edition": 263,
        "date": 1665265146141,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.0725,
        "id": "263",
        "rarity_score": 86.80589572953728,
        "rarity_level": "COMMON"
    },
    "264": {
        "name": "Pact Rats #264",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/264.png",
        "dna": "3c0d48b5e91285c56fb6d1da3579c179affcc5f5",
        "edition": 264,
        "date": 1665265157871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.837499999999999,
        "id": "264",
        "rarity_score": 110.66993321866435,
        "rarity_level": "COMMON"
    },
    "265": {
        "name": "Pact Rats #265",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/265.png",
        "dna": "79c35a13ead1cf9a2cdc1708013849e36c98a29b",
        "edition": 265,
        "date": 1665265120250,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.6325,
        "id": "265",
        "rarity_score": 130.44297460171651,
        "rarity_level": "RARE"
    },
    "266": {
        "name": "Pact Rats #266",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/266.png",
        "dna": "da135d64bbdc62fd3f4e2f2cd7ed4475f103c899",
        "edition": 266,
        "date": 1665265167914,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.090000000000002,
        "id": "266",
        "rarity_score": 121.72888204688061,
        "rarity_level": "RARE"
    },
    "267": {
        "name": "Pact Rats #267",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/267.png",
        "dna": "00e8076130a2a4125e663577f283b752c1d77158",
        "edition": 267,
        "date": 1665265139896,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.719999999999999,
        "id": "267",
        "rarity_score": 75.79649130764601,
        "rarity_level": "COMMON"
    },
    "268": {
        "name": "Pact Rats #268",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/268.png",
        "dna": "ec49a470e922e3554c3cbeb147be0c622e70a5bb",
        "edition": 268,
        "date": 1665265087841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 9.325000000000001,
        "id": "268",
        "rarity_score": 178.27168934113547,
        "rarity_level": "EPIC"
    },
    "269": {
        "name": "Pact Rats #269",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/269.png",
        "dna": "7e3a8305e66b9c03bd8508c344a3e40e2af42242",
        "edition": 269,
        "date": 1665265180622,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 12.084999999999997,
        "id": "269",
        "rarity_score": 123.44408125753223,
        "rarity_level": "RARE"
    },
    "270": {
        "name": "Pact Rats #270",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/270.png",
        "dna": "b5a60a3029a79f46d62c0f26a6d4e7c5c1820b31",
        "edition": 270,
        "date": 1665265097510,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.395000000000001,
        "id": "270",
        "rarity_score": 121.72859381796573,
        "rarity_level": "RARE"
    },
    "271": {
        "name": "Pact Rats #271",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/271.png",
        "dna": "fbc407f5480c80a66f1d6648d4ae41128bf5863f",
        "edition": 271,
        "date": 1665265052451,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.396249999999998,
        "id": "271",
        "rarity_score": 80.47524862058387,
        "rarity_level": "COMMON"
    },
    "272": {
        "name": "Pact Rats #272",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/272.png",
        "dna": "0fbd4f7a574de4c2b479d4fe2721a7d2f9843864",
        "edition": 272,
        "date": 1665265126420,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.1225,
        "id": "272",
        "rarity_score": 60.30070403145877,
        "rarity_level": "COMMON"
    },
    "273": {
        "name": "Pact Rats #273",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/273.png",
        "dna": "21ac16306fa385f500e6cbed090380eaf33ad59f",
        "edition": 273,
        "date": 1665265154185,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.5075,
        "id": "273",
        "rarity_score": 60.56960048468189,
        "rarity_level": "COMMON"
    },
    "274": {
        "name": "Pact Rats #274",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/274.png",
        "dna": "342be867d959939a30bf49c803345547730f78a6",
        "edition": 274,
        "date": 1665265084345,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.857499999999998,
        "id": "274",
        "rarity_score": 53.852146628069555,
        "rarity_level": "COMMON"
    },
    "275": {
        "name": "Pact Rats #275",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/275.png",
        "dna": "c6cf4e1b89cc9ac64325f732c8a675cbcf27cea6",
        "edition": 275,
        "date": 1665265092645,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.2925,
        "id": "275",
        "rarity_score": 64.97929995839621,
        "rarity_level": "COMMON"
    },
    "276": {
        "name": "Pact Rats #276",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/276.png",
        "dna": "94735020508216616e7cd2007c920974969bfdf1",
        "edition": 276,
        "date": 1665265113074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.215,
        "id": "276",
        "rarity_score": 78.56783023749334,
        "rarity_level": "COMMON"
    },
    "277": {
        "name": "Pact Rats #277",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/277.png",
        "dna": "277ba8c389d1a10ae21fece2356d597000bed315",
        "edition": 277,
        "date": 1665265069323,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 7.640000000000001,
        "id": "277",
        "rarity_score": 126.53409131346798,
        "rarity_level": "RARE"
    },
    "278": {
        "name": "Pact Rats #278",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/278.png",
        "dna": "a75b1a60600f990483fbbcfc07c9f5b608ca2f1d",
        "edition": 278,
        "date": 1665265186629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.5,
        "id": "278",
        "rarity_score": 107.19499947113609,
        "rarity_level": "COMMON"
    },
    "279": {
        "name": "Pact Rats #279",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/279.png",
        "dna": "83ba13670b0bef3a2918e82dbb00c20e4748bf47",
        "edition": 279,
        "date": 1665265111556,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.647499999999997,
        "id": "279",
        "rarity_score": 68.93743034118644,
        "rarity_level": "COMMON"
    },
    "280": {
        "name": "Pact Rats #280",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/280.png",
        "dna": "6cb1b025d169b04bb3a7a0af2d5ce71d247a5401",
        "edition": 280,
        "date": 1665265180367,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 5.270000000000001,
        "id": "280",
        "rarity_score": 169.71150497338402,
        "rarity_level": "EPIC"
    },
    "281": {
        "name": "Pact Rats #281",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/281.png",
        "dna": "53fe1808ba597bda3934e369c089b701cab4cd0f",
        "edition": 281,
        "date": 1665265084837,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.759999999999998,
        "id": "281",
        "rarity_score": 105.88568093115889,
        "rarity_level": "COMMON"
    },
    "282": {
        "name": "Pact Rats #282",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/282.png",
        "dna": "2815ca4c28dfbdcfe635d4aaf6a00b28282424b8",
        "edition": 282,
        "date": 1665265036302,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.66125,
        "id": "282",
        "rarity_score": 59.362515603942306,
        "rarity_level": "COMMON"
    },
    "283": {
        "name": "Pact Rats #283",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/283.png",
        "dna": "37277140a5d00909e565d39fa4b595b35f5739ac",
        "edition": 283,
        "date": 1665265056716,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.575,
        "id": "283",
        "rarity_score": 84.45125382249574,
        "rarity_level": "COMMON"
    },
    "284": {
        "name": "Pact Rats #284",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/284.png",
        "dna": "2d687889afe0d2d469f2a1e80e5c8d7af8fc8610",
        "edition": 284,
        "date": 1665265191767,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Captain PACT Sparrow",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "284",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "285": {
        "name": "Pact Rats #285",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/285.png",
        "dna": "4757fd0d9e8c2522bf1574808c39e20f3b50c7f3",
        "edition": 285,
        "date": 1665265095723,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.623749999999998,
        "id": "285",
        "rarity_score": 79.4075094161003,
        "rarity_level": "COMMON"
    },
    "286": {
        "name": "Pact Rats #286",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/286.png",
        "dna": "fb594e81e9b9a62362e877fd6d1e158f13ae7c01",
        "edition": 286,
        "date": 1665265119488,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.46375,
        "id": "286",
        "rarity_score": 58.712893100666236,
        "rarity_level": "COMMON"
    },
    "287": {
        "name": "Pact Rats #287",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/287.png",
        "dna": "13407d9c549310b54a9e0e89c8eeffd6c6b8a741",
        "edition": 287,
        "date": 1665265107659,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.272499999999997,
        "id": "287",
        "rarity_score": 66.20728240076268,
        "rarity_level": "COMMON"
    },
    "288": {
        "name": "Pact Rats #288",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/288.png",
        "dna": "e31e869a76d85c8fd813331378814f6c5f5e18c5",
        "edition": 288,
        "date": 1665265176473,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.54375,
        "id": "288",
        "rarity_score": 67.07234305149632,
        "rarity_level": "COMMON"
    },
    "289": {
        "name": "Pact Rats #289",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/289.png",
        "dna": "d2630f29cf958a766582ac3b7527955672d2ccb5",
        "edition": 289,
        "date": 1665265070081,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.09,
        "id": "289",
        "rarity_score": 110.93879019700752,
        "rarity_level": "RARE"
    },
    "290": {
        "name": "Pact Rats #290",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/290.png",
        "dna": "e484f279c823e54f03060b90b53f226d2f6a7a04",
        "edition": 290,
        "date": 1665265114074,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.228749999999998,
        "id": "290",
        "rarity_score": 108.1675131826774,
        "rarity_level": "COMMON"
    },
    "291": {
        "name": "Pact Rats #291",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/291.png",
        "dna": "cf860d5c8ca44075b5ac90e739234f74ab3a3c53",
        "edition": 291,
        "date": 1665265089652,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.969999999999999,
        "id": "291",
        "rarity_score": 92.09062517553015,
        "rarity_level": "COMMON"
    },
    "292": {
        "name": "Pact Rats #292",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/292.png",
        "dna": "96f8341411e76d7625c676e18d0af9a57e2d3268",
        "edition": 292,
        "date": 1665265025159,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.767500000000002,
        "id": "292",
        "rarity_score": 76.7054203616564,
        "rarity_level": "COMMON"
    },
    "293": {
        "name": "Pact Rats #293",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/293.png",
        "dna": "78d1ce8dff5a382c68ca9330fa953aa347e03a5c",
        "edition": 293,
        "date": 1665265131071,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.1125,
        "id": "293",
        "rarity_score": 60.70874020383596,
        "rarity_level": "COMMON"
    },
    "294": {
        "name": "Pact Rats #294",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/294.png",
        "dna": "1753cab2e42694e43a670fa4dd6f3488dbebceaa",
        "edition": 294,
        "date": 1665265169717,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.196249999999996,
        "id": "294",
        "rarity_score": 70.06689017104344,
        "rarity_level": "COMMON"
    },
    "295": {
        "name": "Pact Rats #295",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/295.png",
        "dna": "ab913b1b2103e5ddc4514aab65ea9e309a784fbc",
        "edition": 295,
        "date": 1665265184009,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.23125,
        "id": "295",
        "rarity_score": 104.45375948664189,
        "rarity_level": "COMMON"
    },
    "296": {
        "name": "Pact Rats #296",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/296.png",
        "dna": "6335d03219d40603037c8dbc843c90ca8cbd2934",
        "edition": 296,
        "date": 1665265153331,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.69125,
        "id": "296",
        "rarity_score": 98.33256940664127,
        "rarity_level": "COMMON"
    },
    "297": {
        "name": "Pact Rats #297",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/297.png",
        "dna": "66341cb6c8175a391baf5f3f8c5d59e163935750",
        "edition": 297,
        "date": 1665265034010,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 8.98875,
        "id": "297",
        "rarity_score": 116.61302234930076,
        "rarity_level": "RARE"
    },
    "298": {
        "name": "Pact Rats #298",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/298.png",
        "dna": "652ddab94ac986d2f4c134443266053817502024",
        "edition": 298,
        "date": 1665265086595,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.79,
        "id": "298",
        "rarity_score": 103.01795213303355,
        "rarity_level": "COMMON"
    },
    "299": {
        "name": "Pact Rats #299",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/299.png",
        "dna": "9aad02464022a07dfe51ed14f090e5337ae91f15",
        "edition": 299,
        "date": 1665265025912,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 9.0075,
        "id": "299",
        "rarity_score": 111.5281792754958,
        "rarity_level": "RARE"
    },
    "300": {
        "name": "Pact Rats #300",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/300.png",
        "dna": "a1f7719868e91b658c6b3b34d13429797ebcafbd",
        "edition": 300,
        "date": 1665265062023,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.326250000000002,
        "id": "300",
        "rarity_score": 105.69891408472499,
        "rarity_level": "COMMON"
    },
    "301": {
        "name": "Pact Rats #301",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/301.png",
        "dna": "226fbb91725d3fcca98c6f70b12a053c5042bd0d",
        "edition": 301,
        "date": 1665265109466,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Rare",
        "rarity": 10.225000000000001,
        "id": "301",
        "rarity_score": 153.02137640484017,
        "rarity_level": "EPIC"
    },
    "302": {
        "name": "Pact Rats #302",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/302.png",
        "dna": "f33598dca5ff5efd02e45a11a8d94f2137ac2754",
        "edition": 302,
        "date": 1665265077968,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.186249999999998,
        "id": "302",
        "rarity_score": 71.95735223651852,
        "rarity_level": "COMMON"
    },
    "303": {
        "name": "Pact Rats #303",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/303.png",
        "dna": "64ae3e0fc2179e268cc2e0b729e7caa0d62be9b5",
        "edition": 303,
        "date": 1665265130300,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 12.806250000000006,
        "id": "303",
        "rarity_score": 99.32321493706337,
        "rarity_level": "COMMON"
    },
    "304": {
        "name": "Pact Rats #304",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/304.png",
        "dna": "550dfedc93a5ca4e06209ccf42a0aa8589fa237e",
        "edition": 304,
        "date": 1665265026921,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.0975,
        "id": "304",
        "rarity_score": 97.61801074545394,
        "rarity_level": "COMMON"
    },
    "305": {
        "name": "Pact Rats #305",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/305.png",
        "dna": "fea4d620bc6232a1b3571a2c30242143b96b62b7",
        "edition": 305,
        "date": 1665265172839,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.911249999999999,
        "id": "305",
        "rarity_score": 86.09716764059722,
        "rarity_level": "COMMON"
    },
    "306": {
        "name": "Pact Rats #306",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/306.png",
        "dna": "78c1c890faf4a0f5c3b438e466f458d968edaa7e",
        "edition": 306,
        "date": 1665265093645,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.409999999999998,
        "id": "306",
        "rarity_score": 136.94733559268747,
        "rarity_level": "RARE"
    },
    "307": {
        "name": "Pact Rats #307",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/307.png",
        "dna": "b515d6040a80324393efae7672fc1685cc8d25fb",
        "edition": 307,
        "date": 1665265024379,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.891250000000003,
        "id": "307",
        "rarity_score": 83.0240564629903,
        "rarity_level": "COMMON"
    },
    "308": {
        "name": "Pact Rats #308",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/308.png",
        "dna": "5bf84e1c573c5fc1d466953c4686039457538ad2",
        "edition": 308,
        "date": 1665265127995,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 5.655,
        "id": "308",
        "rarity_score": 167.49057192807192,
        "rarity_level": "EPIC"
    },
    "309": {
        "name": "Pact Rats #309",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/309.png",
        "dna": "8ecc744371427636ff393d657c0c067a2f56b683",
        "edition": 309,
        "date": 1665265159668,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Rare",
        "rarity": 10.26375,
        "id": "309",
        "rarity_score": 144.47563804732127,
        "rarity_level": "RARE"
    },
    "310": {
        "name": "Pact Rats #310",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/310.png",
        "dna": "8466fe3a73e14416101931060e86f9f32b00a6a8",
        "edition": 310,
        "date": 1665265162069,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.01125,
        "id": "310",
        "rarity_score": 68.34395126746139,
        "rarity_level": "COMMON"
    },
    "311": {
        "name": "Pact Rats #311",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/311.png",
        "dna": "81f677b9f06f5d9362e03e1b38deff93203a6f7b",
        "edition": 311,
        "date": 1665265183225,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.03125,
        "id": "311",
        "rarity_score": 99.76946528188373,
        "rarity_level": "COMMON"
    },
    "312": {
        "name": "Pact Rats #312",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/312.png",
        "dna": "9c39d5180955b3ebc5b1db2078f0de808a843a70",
        "edition": 312,
        "date": 1665265149805,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.868749999999999,
        "id": "312",
        "rarity_score": 69.55196053946054,
        "rarity_level": "COMMON"
    },
    "313": {
        "name": "Pact Rats #313",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/313.png",
        "dna": "5531acabf3d3ded0aa58bbc826c570801430d624",
        "edition": 313,
        "date": 1665265107403,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Epic",
        "rarity": 7.897499999999999,
        "id": "313",
        "rarity_score": 123.30404471314462,
        "rarity_level": "RARE"
    },
    "314": {
        "name": "Pact Rats #314",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/314.png",
        "dna": "6e01e87ab5dd24c264ab38021dbce5f259480687",
        "edition": 314,
        "date": 1665265065296,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Common",
        "rarity": 13.02375,
        "id": "314",
        "rarity_score": 115.42063803408901,
        "rarity_level": "RARE"
    },
    "315": {
        "name": "Pact Rats #315",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/315.png",
        "dna": "eab07aad1c554f5a9e90a7190faefb8bebee1e93",
        "edition": 315,
        "date": 1665265091623,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 8.892500000000002,
        "id": "315",
        "rarity_score": 132.62245796457836,
        "rarity_level": "RARE"
    },
    "316": {
        "name": "Pact Rats #316",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/316.png",
        "dna": "e27826e30520c33def44e54a8816ac8633d34e6f",
        "edition": 316,
        "date": 1665265026660,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.50375,
        "id": "316",
        "rarity_score": 81.00451452824124,
        "rarity_level": "COMMON"
    },
    "317": {
        "name": "Pact Rats #317",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/317.png",
        "dna": "aceaacd284c45b581e9505936bed08b1cf76bb36",
        "edition": 317,
        "date": 1665265083591,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.37,
        "id": "317",
        "rarity_score": 133.4968141962308,
        "rarity_level": "RARE"
    },
    "318": {
        "name": "Pact Rats #318",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/318.png",
        "dna": "aeb42c85019307f3fb9c4fbc961a755f7180555d",
        "edition": 318,
        "date": 1665265036805,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.91,
        "id": "318",
        "rarity_score": 115.19112268328686,
        "rarity_level": "RARE"
    },
    "319": {
        "name": "Pact Rats #319",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/319.png",
        "dna": "fc6331a73ac287e873d934395433eb2e8c2cbaff",
        "edition": 319,
        "date": 1665265057995,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 9.700000000000001,
        "id": "319",
        "rarity_score": 101.14789823405295,
        "rarity_level": "COMMON"
    },
    "320": {
        "name": "Pact Rats #320",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/320.png",
        "dna": "c45cc11d1d5790bd93a6e4d3bed9aed865dea1d5",
        "edition": 320,
        "date": 1665265106907,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.532499999999999,
        "id": "320",
        "rarity_score": 61.342234979764946,
        "rarity_level": "COMMON"
    },
    "321": {
        "name": "Pact Rats #321",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/321.png",
        "dna": "92074378c210948a1fcd6d9defc7c02b3b07be40",
        "edition": 321,
        "date": 1665265052935,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.77,
        "id": "321",
        "rarity_score": 105.84484062536808,
        "rarity_level": "COMMON"
    },
    "322": {
        "name": "Pact Rats #322",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/322.png",
        "dna": "cda4c5203ada8f921a793af016ee0f26ae721a5e",
        "edition": 322,
        "date": 1665265035043,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.4975,
        "id": "322",
        "rarity_score": 65.11856713372825,
        "rarity_level": "COMMON"
    },
    "323": {
        "name": "Pact Rats #323",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/323.png",
        "dna": "3702878c4c8b29860587119c4fd0924b4812b136",
        "edition": 323,
        "date": 1665265054673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.250000000000002,
        "id": "323",
        "rarity_score": 110.93129622335695,
        "rarity_level": "RARE"
    },
    "324": {
        "name": "Pact Rats #324",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/324.png",
        "dna": "c6d9fc159ba2af6ba68502d06466d7a2d776875a",
        "edition": 324,
        "date": 1665265069584,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.71,
        "id": "324",
        "rarity_score": 73.16066328958999,
        "rarity_level": "COMMON"
    },
    "325": {
        "name": "Pact Rats #325",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/325.png",
        "dna": "66923be7570d27037821fc32263acdfb39f46ba5",
        "edition": 325,
        "date": 1665265137602,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.9725,
        "id": "325",
        "rarity_score": 59.69445277318288,
        "rarity_level": "COMMON"
    },
    "326": {
        "name": "Pact Rats #326",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/326.png",
        "dna": "2bf01c3533b27606be4a21bea4c8f4b73cd83b7d",
        "edition": 326,
        "date": 1665265060045,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.224999999999998,
        "id": "326",
        "rarity_score": 86.77870796029416,
        "rarity_level": "COMMON"
    },
    "327": {
        "name": "Pact Rats #327",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/327.png",
        "dna": "10136cca81a8d27b6c0cb07dd33edf920f869b2b",
        "edition": 327,
        "date": 1665265149294,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.6475,
        "id": "327",
        "rarity_score": 133.29344923276895,
        "rarity_level": "RARE"
    },
    "328": {
        "name": "Pact Rats #328",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/328.png",
        "dna": "5e704edfdcc9b6c92ad7e14ef452700fd716ed15",
        "edition": 328,
        "date": 1665265098517,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.99875,
        "id": "328",
        "rarity_score": 108.27384487446685,
        "rarity_level": "COMMON"
    },
    "329": {
        "name": "Pact Rats #329",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/329.png",
        "dna": "b87b59fac9b3d3a2030b0fb21ac35b3cd8e2d45a",
        "edition": 329,
        "date": 1665265027673,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.21625,
        "id": "329",
        "rarity_score": 127.25974262688462,
        "rarity_level": "RARE"
    },
    "330": {
        "name": "Pact Rats #330",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/330.png",
        "dna": "83729c889116dde035469f741574e2e1e09cc4fb",
        "edition": 330,
        "date": 1665265043488,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.0625,
        "id": "330",
        "rarity_score": 107.1042732510571,
        "rarity_level": "COMMON"
    },
    "331": {
        "name": "Pact Rats #331",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/331.png",
        "dna": "0a4a17d18b396e8984ea22be59df5c3e34331f06",
        "edition": 331,
        "date": 1665265158892,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 7.6075,
        "id": "331",
        "rarity_score": 131.691288432187,
        "rarity_level": "RARE"
    },
    "332": {
        "name": "Pact Rats #332",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/332.png",
        "dna": "beb1333d9f1f6791bdcd94434dd3e5c597f3bc97",
        "edition": 332,
        "date": 1665265022067,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.68625,
        "id": "332",
        "rarity_score": 65.29797588286861,
        "rarity_level": "COMMON"
    },
    "333": {
        "name": "Pact Rats #333",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/333.png",
        "dna": "a57d91a258b0ddfd0703cc099b241a0fe8a1ae8c",
        "edition": 333,
        "date": 1665265039606,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 12.535,
        "id": "333",
        "rarity_score": 106.04363338250153,
        "rarity_level": "COMMON"
    },
    "334": {
        "name": "Pact Rats #334",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/334.png",
        "dna": "6065ba2dc5b093a188f9e0b87cbe5b6ab754939a",
        "edition": 334,
        "date": 1665265151937,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.0025,
        "id": "334",
        "rarity_score": 72.9739624903892,
        "rarity_level": "COMMON"
    },
    "335": {
        "name": "Pact Rats #335",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/335.png",
        "dna": "6b4b861ca12c0ebacff49df739db29cf0d344606",
        "edition": 335,
        "date": 1665265174381,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.535000000000004,
        "id": "335",
        "rarity_score": 141.83319915088993,
        "rarity_level": "RARE"
    },
    "336": {
        "name": "Pact Rats #336",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/336.png",
        "dna": "7d70ada9a4c6fefb15300422daac71a60c4ed28c",
        "edition": 336,
        "date": 1665265051126,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.401250000000001,
        "id": "336",
        "rarity_score": 63.66193661141044,
        "rarity_level": "COMMON"
    },
    "337": {
        "name": "Pact Rats #337",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/337.png",
        "dna": "240d97bc0286674c67dcfd725137561b6e27ce3a",
        "edition": 337,
        "date": 1665265021257,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Rare",
        "rarity": 11.809999999999999,
        "id": "337",
        "rarity_score": 120.44057898223224,
        "rarity_level": "RARE"
    },
    "338": {
        "name": "Pact Rats #338",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/338.png",
        "dna": "a01ad5130dd827cbe2380f8171d5f466b51a095f",
        "edition": 338,
        "date": 1665265052695,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.645,
        "id": "338",
        "rarity_score": 117.12299789386915,
        "rarity_level": "RARE"
    },
    "339": {
        "name": "Pact Rats #339",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/339.png",
        "dna": "162c3140a6fcc78a06171c42ca00f521c008a3e3",
        "edition": 339,
        "date": 1665265094938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.34875,
        "id": "339",
        "rarity_score": 74.61622349025889,
        "rarity_level": "COMMON"
    },
    "340": {
        "name": "Pact Rats #340",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/340.png",
        "dna": "c3b9d0b1be265891b132ad30a8ed411e35a45c62",
        "edition": 340,
        "date": 1665265182966,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Super",
        "rarity": 5.0575,
        "id": "340",
        "rarity_score": 168.53468727982653,
        "rarity_level": "EPIC"
    },
    "341": {
        "name": "Pact Rats #341",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/341.png",
        "dna": "1e7e71b0a1236b867ad5556e66f5a83f34c610d1",
        "edition": 341,
        "date": 1665265066802,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 12.536250000000003,
        "id": "341",
        "rarity_score": 93.99015155494783,
        "rarity_level": "COMMON"
    },
    "342": {
        "name": "Pact Rats #342",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/342.png",
        "dna": "b3ea8b2fe100a00fa06ab89467b7415f5f6630dc",
        "edition": 342,
        "date": 1665265131850,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.31625,
        "id": "342",
        "rarity_score": 100.68561894789181,
        "rarity_level": "COMMON"
    },
    "343": {
        "name": "Pact Rats #343",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/343.png",
        "dna": "4467e67843478db45356d638383c4342a750be4e",
        "edition": 343,
        "date": 1665265098276,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.173750000000002,
        "id": "343",
        "rarity_score": 75.40311867436382,
        "rarity_level": "COMMON"
    },
    "344": {
        "name": "Pact Rats #344",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/344.png",
        "dna": "fa5a85e07e267484e5469c84776df13ab382495b",
        "edition": 344,
        "date": 1665265136540,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.352500000000001,
        "id": "344",
        "rarity_score": 117.99499145189299,
        "rarity_level": "RARE"
    },
    "345": {
        "name": "Pact Rats #345",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/345.png",
        "dna": "7e7860acb563df15b1fbdf29dd7f69b0ed15f49d",
        "edition": 345,
        "date": 1665265121532,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Super",
        "rarity": 4.7775,
        "id": "345",
        "rarity_score": 210.3126459863007,
        "rarity_level": "LEGENDARY"
    },
    "346": {
        "name": "Pact Rats #346",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/346.png",
        "dna": "741d5e6acae2d33231cd38a8aaf49599f5dfa2a0",
        "edition": 346,
        "date": 1665265158135,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Epic",
        "rarity": 8.875,
        "id": "346",
        "rarity_score": 130.81540954661438,
        "rarity_level": "RARE"
    },
    "347": {
        "name": "Pact Rats #347",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/347.png",
        "dna": "f2d91bdc7a21e22ae13a1cded4734f5aab2edf82",
        "edition": 347,
        "date": 1665265093909,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.02,
        "id": "347",
        "rarity_score": 59.23390958013762,
        "rarity_level": "COMMON"
    },
    "348": {
        "name": "Pact Rats #348",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/348.png",
        "dna": "12130c55da9e640dd3fdaf53f3f3885d629ec028",
        "edition": 348,
        "date": 1665265137842,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.901249999999999,
        "id": "348",
        "rarity_score": 145.33590290306708,
        "rarity_level": "RARE"
    },
    "349": {
        "name": "Pact Rats #349",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/349.png",
        "dna": "8296fdb6bf2e2da85faae6f350299f3c47f94290",
        "edition": 349,
        "date": 1665265067313,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.849999999999998,
        "id": "349",
        "rarity_score": 87.37075310377635,
        "rarity_level": "COMMON"
    },
    "350": {
        "name": "Pact Rats #350",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/350.png",
        "dna": "4dbf105b937581ff7f525209680c8d51e8f6364f",
        "edition": 350,
        "date": 1665265057222,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.252499999999998,
        "id": "350",
        "rarity_score": 65.38604628674265,
        "rarity_level": "COMMON"
    },
    "351": {
        "name": "Pact Rats #351",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/351.png",
        "dna": "07e0d277c23efec1cab6d1121c3ada888d7a24da",
        "edition": 351,
        "date": 1665265117681,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.65375,
        "id": "351",
        "rarity_score": 87.49119279254121,
        "rarity_level": "COMMON"
    },
    "352": {
        "name": "Pact Rats #352",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/352.png",
        "dna": "8e6f5e10bf1898cd5860076198a0f42a4d862a48",
        "edition": 352,
        "date": 1665265150841,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.122500000000002,
        "id": "352",
        "rarity_score": 74.15550828281123,
        "rarity_level": "COMMON"
    },
    "353": {
        "name": "Pact Rats #353",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/353.png",
        "dna": "4900fb8bea5c3e536726fe1008640902f1f296df",
        "edition": 353,
        "date": 1665265152803,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Common",
        "rarity": 13.48,
        "id": "353",
        "rarity_score": 102.26769085078382,
        "rarity_level": "COMMON"
    },
    "354": {
        "name": "Pact Rats #354",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/354.png",
        "dna": "19ee96c6c68d61dd245b67ac5808a21f0bdbcad4",
        "edition": 354,
        "date": 1665265077717,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.07125,
        "id": "354",
        "rarity_score": 66.75052872459466,
        "rarity_level": "COMMON"
    },
    "355": {
        "name": "Pact Rats #355",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/355.png",
        "dna": "1241c225d66da0f9119f48046fc597b84970135c",
        "edition": 355,
        "date": 1665265055191,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Super",
        "rarity": 6.46125,
        "id": "355",
        "rarity_score": 165.83402306812297,
        "rarity_level": "EPIC"
    },
    "356": {
        "name": "Pact Rats #356",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/356.png",
        "dna": "0a74228d5ae93b9b8ea03c3ccfc5047e3909a302",
        "edition": 356,
        "date": 1665265025407,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 11.81375,
        "id": "356",
        "rarity_score": 124.78389471654815,
        "rarity_level": "RARE"
    },
    "357": {
        "name": "Pact Rats #357",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/357.png",
        "dna": "3da5d35af4faffc757a0ea041555720708d22d25",
        "edition": 357,
        "date": 1665265191353,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Fear And Loathing",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "357",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "358": {
        "name": "Pact Rats #358",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/358.png",
        "dna": "083244c604e51d455a85329654588ce1153b1472",
        "edition": 358,
        "date": 1665265188196,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.8325,
        "id": "358",
        "rarity_score": 112.11521450327012,
        "rarity_level": "RARE"
    },
    "359": {
        "name": "Pact Rats #359",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/359.png",
        "dna": "72903b8a79399a618b9b15bd84db346f2c4847f8",
        "edition": 359,
        "date": 1665265094154,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.18375,
        "id": "359",
        "rarity_score": 111.32277995696052,
        "rarity_level": "RARE"
    },
    "360": {
        "name": "Pact Rats #360",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/360.png",
        "dna": "f05c64752fc1066d905f96a63f02cf01d9681196",
        "edition": 360,
        "date": 1665265063026,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.552499999999998,
        "id": "360",
        "rarity_score": 64.85193647230227,
        "rarity_level": "COMMON"
    },
    "361": {
        "name": "Pact Rats #361",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/361.png",
        "dna": "3d63b796ef6ca2f985b95541edda550d0a3d8b11",
        "edition": 361,
        "date": 1665265154446,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.455000000000002,
        "id": "361",
        "rarity_score": 70.03690803573127,
        "rarity_level": "COMMON"
    },
    "362": {
        "name": "Pact Rats #362",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/362.png",
        "dna": "28ea17fd44a8f365a3eb4c9fafa4b3de8245d0a1",
        "edition": 362,
        "date": 1665265133430,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Epic",
        "rarity": 7.002499999999998,
        "id": "362",
        "rarity_score": 225.4963273903635,
        "rarity_level": "LEGENDARY"
    },
    "363": {
        "name": "Pact Rats #363",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/363.png",
        "dna": "8eb71592370c27cd07976ba89863f7c8f8cc2d97",
        "edition": 363,
        "date": 1665265149542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.179999999999998,
        "id": "363",
        "rarity_score": 116.04774306175695,
        "rarity_level": "RARE"
    },
    "364": {
        "name": "Pact Rats #364",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/364.png",
        "dna": "95c1cd3c0dfae520299ec1cc9701f9d2618baf0e",
        "edition": 364,
        "date": 1665265108165,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.258750000000001,
        "id": "364",
        "rarity_score": 111.62139810075584,
        "rarity_level": "RARE"
    },
    "365": {
        "name": "Pact Rats #365",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/365.png",
        "dna": "4dcd1e328eef99ef22b71dc743fc323f08331489",
        "edition": 365,
        "date": 1665265079463,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.5425,
        "id": "365",
        "rarity_score": 79.92175205732335,
        "rarity_level": "COMMON"
    },
    "366": {
        "name": "Pact Rats #366",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/366.png",
        "dna": "8292e52253f282638c1a732de5623738d0faa774",
        "edition": 366,
        "date": 1665265126943,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.19125,
        "id": "366",
        "rarity_score": 83.96699156463677,
        "rarity_level": "COMMON"
    },
    "367": {
        "name": "Pact Rats #367",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/367.png",
        "dna": "09a3958f7c40d54a7e9e8ab7f74e52c6d1fa928d",
        "edition": 367,
        "date": 1665265044547,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.38125,
        "id": "367",
        "rarity_score": 139.50878564595703,
        "rarity_level": "RARE"
    },
    "368": {
        "name": "Pact Rats #368",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/368.png",
        "dna": "c6c55f2c1678bd271a740f29290a8176db0593d3",
        "edition": 368,
        "date": 1665265066272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "Captain PACT Sparrow Hat",
                "score": 133.2
            }
        ],
        "rank": "Super",
        "rarity": 6.344999999999999,
        "id": "368",
        "rarity_score": 224.36679306934354,
        "rarity_level": "LEGENDARY"
    },
    "369": {
        "name": "Pact Rats #369",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/369.png",
        "dna": "2046c5de9c1f94561908ceed88e18204bab63d0a",
        "edition": 369,
        "date": 1665265023855,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Splinter Gui",
                "score": 95.14285714285714
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.618750000000002,
        "id": "369",
        "rarity_score": 182.7684679848947,
        "rarity_level": "EPIC"
    },
    "370": {
        "name": "Pact Rats #370",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/370.png",
        "dna": "5a3a69fb84e22a9d81a66af2f7bc500316a9a1e1",
        "edition": 370,
        "date": 1665265054432,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 12.103749999999998,
        "id": "370",
        "rarity_score": 128.97174050304864,
        "rarity_level": "RARE"
    },
    "371": {
        "name": "Pact Rats #371",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/371.png",
        "dna": "e9cb5fe32d1070c8a15efd565a96cf4b095c2bdd",
        "edition": 371,
        "date": 1665265127231,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Epic",
        "rarity": 8.60375,
        "id": "371",
        "rarity_score": 128.41654908129976,
        "rarity_level": "RARE"
    },
    "372": {
        "name": "Pact Rats #372",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/372.png",
        "dna": "0e812faa1fce07101e49cebd275e32576e9e78bf",
        "edition": 372,
        "date": 1665265105636,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.636249999999997,
        "id": "372",
        "rarity_score": 69.07476839737221,
        "rarity_level": "COMMON"
    },
    "373": {
        "name": "Pact Rats #373",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/373.png",
        "dna": "507c9a1b8ce90dece866e55983f4eff2f4cb7ab8",
        "edition": 373,
        "date": 1665265072365,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.4175,
        "id": "373",
        "rarity_score": 118.34641007859676,
        "rarity_level": "RARE"
    },
    "374": {
        "name": "Pact Rats #374",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/374.png",
        "dna": "804ab97525a88c8a21765d1cc7d416d5bdfc8d6e",
        "edition": 374,
        "date": 1665265174917,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.685,
        "id": "374",
        "rarity_score": 54.615632917658516,
        "rarity_level": "COMMON"
    },
    "375": {
        "name": "Pact Rats #375",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/375.png",
        "dna": "bed6b93f258901565ad1ccf92f14f7c1ddd9a41f",
        "edition": 375,
        "date": 1665265119217,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.717499999999998,
        "id": "375",
        "rarity_score": 96.68100585063253,
        "rarity_level": "COMMON"
    },
    "376": {
        "name": "Pact Rats #376",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/376.png",
        "dna": "7826591c4d7070b2626722adbe5f2452aee6a701",
        "edition": 376,
        "date": 1665265047252,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.39875,
        "id": "376",
        "rarity_score": 81.40959903325374,
        "rarity_level": "COMMON"
    },
    "377": {
        "name": "Pact Rats #377",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/377.png",
        "dna": "098b22f0aae0f883cd39612c87c6bd1d556801fd",
        "edition": 377,
        "date": 1665265177521,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 9.034999999999998,
        "id": "377",
        "rarity_score": 127.75260928387269,
        "rarity_level": "RARE"
    },
    "378": {
        "name": "Pact Rats #378",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/378.png",
        "dna": "e58751efcf24f473035de749e78c3a7442975f89",
        "edition": 378,
        "date": 1665265067052,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.334999999999997,
        "id": "378",
        "rarity_score": 69.90415174965955,
        "rarity_level": "COMMON"
    },
    "379": {
        "name": "Pact Rats #379",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/379.png",
        "dna": "4f6d0437ea4010ff104b19138073501aa4c0d518",
        "edition": 379,
        "date": 1665265160449,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.779999999999998,
        "id": "379",
        "rarity_score": 101.52248009817863,
        "rarity_level": "COMMON"
    },
    "380": {
        "name": "Pact Rats #380",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/380.png",
        "dna": "23c2bceff9ff66013eec84308592b55296280337",
        "edition": 380,
        "date": 1665265084582,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 8.642499999999998,
        "id": "380",
        "rarity_score": 123.57634812791999,
        "rarity_level": "RARE"
    },
    "381": {
        "name": "Pact Rats #381",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/381.png",
        "dna": "b1c58d6c801d9ebcada1e018d3ca1477e09c3d3e",
        "edition": 381,
        "date": 1665265164769,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.50375,
        "id": "381",
        "rarity_score": 86.40076111729823,
        "rarity_level": "COMMON"
    },
    "382": {
        "name": "Pact Rats #382",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/382.png",
        "dna": "e7d597e5c8f50862e959f1dacf03773aede3ca8e",
        "edition": 382,
        "date": 1665265032276,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.16875,
        "id": "382",
        "rarity_score": 93.12296835108319,
        "rarity_level": "COMMON"
    },
    "383": {
        "name": "Pact Rats #383",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/383.png",
        "dna": "dc1b43e85acbe524ee5437bd40331d34413e727b",
        "edition": 383,
        "date": 1665265094399,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.407499999999999,
        "id": "383",
        "rarity_score": 84.28007812472013,
        "rarity_level": "COMMON"
    },
    "384": {
        "name": "Pact Rats #384",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/384.png",
        "dna": "5a1620264679d73cedf1567fb5801c6d8ec1ddaf",
        "edition": 384,
        "date": 1665265085583,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 11.2975,
        "id": "384",
        "rarity_score": 113.45699564676389,
        "rarity_level": "RARE"
    },
    "385": {
        "name": "Pact Rats #385",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/385.png",
        "dna": "305345f2d7b910b98c59ffc8f69e408450e3c9a8",
        "edition": 385,
        "date": 1665265184270,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.1725,
        "id": "385",
        "rarity_score": 80.60654366581309,
        "rarity_level": "COMMON"
    },
    "386": {
        "name": "Pact Rats #386",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/386.png",
        "dna": "2be647431ea417f247a72e3f2d98ca971097673b",
        "edition": 386,
        "date": 1665265090621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 11.52125,
        "id": "386",
        "rarity_score": 102.98031387709773,
        "rarity_level": "COMMON"
    },
    "387": {
        "name": "Pact Rats #387",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/387.png",
        "dna": "b8611718d67a585f7a7058174565e13e666b6c55",
        "edition": 387,
        "date": 1665265183491,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.97625,
        "id": "387",
        "rarity_score": 105.59659168922357,
        "rarity_level": "COMMON"
    },
    "388": {
        "name": "Pact Rats #388",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/388.png",
        "dna": "031f1e97cc903f916b34eb3413702108453c3f2e",
        "edition": 388,
        "date": 1665265159415,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.4925,
        "id": "388",
        "rarity_score": 69.94942087896756,
        "rarity_level": "COMMON"
    },
    "389": {
        "name": "Pact Rats #389",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/389.png",
        "dna": "938ee1403da0b9e867b4594d48529b7f413c0acb",
        "edition": 389,
        "date": 1665265168931,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.017500000000002,
        "id": "389",
        "rarity_score": 66.48846346085286,
        "rarity_level": "COMMON"
    },
    "390": {
        "name": "Pact Rats #390",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/390.png",
        "dna": "752244100bfada94d9c17af89881906cbd6a4f7d",
        "edition": 390,
        "date": 1665265069078,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 7.908750000000001,
        "id": "390",
        "rarity_score": 131.7428296321359,
        "rarity_level": "RARE"
    },
    "391": {
        "name": "Pact Rats #391",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/391.png",
        "dna": "ace0276a07aeb649fab1d3882db860959d2e2ca6",
        "edition": 391,
        "date": 1665265097003,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Epic",
        "rarity": 9.823749999999999,
        "id": "391",
        "rarity_score": 144.2179347916728,
        "rarity_level": "RARE"
    },
    "392": {
        "name": "Pact Rats #392",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/392.png",
        "dna": "52262bc48b2d5aac12d8eabf90e343c3eef023f8",
        "edition": 392,
        "date": 1665265024125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.7425,
        "id": "392",
        "rarity_score": 78.43536296457405,
        "rarity_level": "COMMON"
    },
    "393": {
        "name": "Pact Rats #393",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/393.png",
        "dna": "afa7a00216050842e2da5bf5976ae7dfd0225a61",
        "edition": 393,
        "date": 1665265046747,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.602500000000001,
        "id": "393",
        "rarity_score": 118.3540525623701,
        "rarity_level": "RARE"
    },
    "394": {
        "name": "Pact Rats #394",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/394.png",
        "dna": "f45a2d93df8f7b7c12662e6f1320e6b327e85c57",
        "edition": 394,
        "date": 1665265026407,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Super",
        "rarity": 6.133750000000001,
        "id": "394",
        "rarity_score": 145.4940631238725,
        "rarity_level": "RARE"
    },
    "395": {
        "name": "Pact Rats #395",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/395.png",
        "dna": "abc3d154cb95c6db819d74ce2b6792d6227bade4",
        "edition": 395,
        "date": 1665265107151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.32125,
        "id": "395",
        "rarity_score": 117.56817244709916,
        "rarity_level": "RARE"
    },
    "396": {
        "name": "Pact Rats #396",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/396.png",
        "dna": "becd72c429ae1d2907f65cd8abaf52038744dba2",
        "edition": 396,
        "date": 1665265080217,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.71125,
        "id": "396",
        "rarity_score": 103.59947003718666,
        "rarity_level": "COMMON"
    },
    "397": {
        "name": "Pact Rats #397",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/397.png",
        "dna": "7f3f695fc79e3dace7eac765ddb7df0735b183ce",
        "edition": 397,
        "date": 1665265123050,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.434999999999999,
        "id": "397",
        "rarity_score": 136.5535207547855,
        "rarity_level": "RARE"
    },
    "398": {
        "name": "Pact Rats #398",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/398.png",
        "dna": "abe0d6a47277874ce0e71ca999a4b4426bb98fd3",
        "edition": 398,
        "date": 1665265189468,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 11.805,
        "id": "398",
        "rarity_score": 114.26122071089209,
        "rarity_level": "RARE"
    },
    "399": {
        "name": "Pact Rats #399",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/399.png",
        "dna": "ef8594460580e14aa42307c8af6b489af6f3e725",
        "edition": 399,
        "date": 1665265115850,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.715,
        "id": "399",
        "rarity_score": 75.9323941842661,
        "rarity_level": "COMMON"
    },
    "400": {
        "name": "Pact Rats #400",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/400.png",
        "dna": "e5823d81aa156b45b9ad541fad3f6c008f2428ab",
        "edition": 400,
        "date": 1665265100795,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.340000000000002,
        "id": "400",
        "rarity_score": 131.20443096015566,
        "rarity_level": "RARE"
    },
    "401": {
        "name": "Pact Rats #401",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/401.png",
        "dna": "0e2f9656ddd6ae9c49dea354d7dc158e3a7f8b81",
        "edition": 401,
        "date": 1665265154702,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Super",
        "rarity": 6.088749999999999,
        "id": "401",
        "rarity_score": 145.8204865502879,
        "rarity_level": "RARE"
    },
    "402": {
        "name": "Pact Rats #402",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/402.png",
        "dna": "99ef186ae1607449392c00e44569399bd3c9ab84",
        "edition": 402,
        "date": 1665265188946,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Super",
        "rarity": 6.33375,
        "id": "402",
        "rarity_score": 150.32670272024183,
        "rarity_level": "EPIC"
    },
    "403": {
        "name": "Pact Rats #403",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/403.png",
        "dna": "49b5cf304a49583b38795a55dc15dd1bc862b9ee",
        "edition": 403,
        "date": 1665265108678,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.799999999999997,
        "id": "403",
        "rarity_score": 69.4534070941674,
        "rarity_level": "COMMON"
    },
    "404": {
        "name": "Pact Rats #404",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/404.png",
        "dna": "c4d3506233fd3ca4aaf1739bff6596d4d1f78691",
        "edition": 404,
        "date": 1665265157567,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.985,
        "id": "404",
        "rarity_score": 96.81967475684614,
        "rarity_level": "COMMON"
    },
    "405": {
        "name": "Pact Rats #405",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/405.png",
        "dna": "181a7563b1432194d919dfb4f35baf5d19f64032",
        "edition": 405,
        "date": 1665265092890,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.3325,
        "id": "405",
        "rarity_score": 104.39688757952682,
        "rarity_level": "COMMON"
    },
    "406": {
        "name": "Pact Rats #406",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/406.png",
        "dna": "436fc1ece7db9c843deea12a79c2e40d3983eea7",
        "edition": 406,
        "date": 1665265176724,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.326250000000002,
        "id": "406",
        "rarity_score": 104.07682300706551,
        "rarity_level": "COMMON"
    },
    "407": {
        "name": "Pact Rats #407",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/407.png",
        "dna": "13522179deacb29bdf8bb28ec6ceedd4ac12cce2",
        "edition": 407,
        "date": 1665265041172,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 9.745000000000001,
        "id": "407",
        "rarity_score": 129.5685253666465,
        "rarity_level": "RARE"
    },
    "408": {
        "name": "Pact Rats #408",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/408.png",
        "dna": "f53280e8866958a1ad218f09ff3274bf5959efa7",
        "edition": 408,
        "date": 1665265178545,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 12.18,
        "id": "408",
        "rarity_score": 100.13634957934815,
        "rarity_level": "COMMON"
    },
    "409": {
        "name": "Pact Rats #409",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/409.png",
        "dna": "787c4c8178e8906a3de793121a6ee6b0eab84204",
        "edition": 409,
        "date": 1665265096226,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 8.76375,
        "id": "409",
        "rarity_score": 133.095451915364,
        "rarity_level": "RARE"
    },
    "410": {
        "name": "Pact Rats #410",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/410.png",
        "dna": "1403605904ed642748421036f82bd966acea12a9",
        "edition": 410,
        "date": 1665265075421,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.247499999999999,
        "id": "410",
        "rarity_score": 90.8460264156959,
        "rarity_level": "COMMON"
    },
    "411": {
        "name": "Pact Rats #411",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/411.png",
        "dna": "600a1635a69003db564ba2ba53549833cd4012ae",
        "edition": 411,
        "date": 1665265055453,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Rare",
        "rarity": 11.462500000000002,
        "id": "411",
        "rarity_score": 113.98680770275118,
        "rarity_level": "RARE"
    },
    "412": {
        "name": "Pact Rats #412",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/412.png",
        "dna": "515da304f81b10b39b005c9eac2a1952cde414e1",
        "edition": 412,
        "date": 1665265031272,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.1875,
        "id": "412",
        "rarity_score": 104.14555478339234,
        "rarity_level": "COMMON"
    },
    "413": {
        "name": "Pact Rats #413",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/413.png",
        "dna": "226902a963c355cbd43ff1f88bb825eedc8d7ae9",
        "edition": 413,
        "date": 1665265135504,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.65875,
        "id": "413",
        "rarity_score": 134.21881346104365,
        "rarity_level": "RARE"
    },
    "414": {
        "name": "Pact Rats #414",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/414.png",
        "dna": "8e5e9a397974aec0400c0941c1ef929dba9f5c80",
        "edition": 414,
        "date": 1665265035785,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.1075,
        "id": "414",
        "rarity_score": 128.43640187286326,
        "rarity_level": "RARE"
    },
    "415": {
        "name": "Pact Rats #415",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/415.png",
        "dna": "d9138d24b5c1158101db89936619bd0b08874c30",
        "edition": 415,
        "date": 1665265170242,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.793750000000003,
        "id": "415",
        "rarity_score": 78.97495686921953,
        "rarity_level": "COMMON"
    },
    "416": {
        "name": "Pact Rats #416",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/416.png",
        "dna": "70301e68ff5799da06e17103d9b3a9f3f9489f38",
        "edition": 416,
        "date": 1665265112322,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.05875,
        "id": "416",
        "rarity_score": 77.98334966780261,
        "rarity_level": "COMMON"
    },
    "417": {
        "name": "Pact Rats #417",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/417.png",
        "dna": "42b5a1eda0432c7ab3765b8420e734b68d93ad18",
        "edition": 417,
        "date": 1665265053187,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.213750000000001,
        "id": "417",
        "rarity_score": 117.32933139856304,
        "rarity_level": "RARE"
    },
    "418": {
        "name": "Pact Rats #418",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/418.png",
        "dna": "0b1eed08bfce2c595ca91e2e6441923009ed72a0",
        "edition": 418,
        "date": 1665265128784,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.95,
        "id": "418",
        "rarity_score": 108.33760658439043,
        "rarity_level": "COMMON"
    },
    "419": {
        "name": "Pact Rats #419",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/419.png",
        "dna": "ccd28539bc851816e9a6440eb70a52646a39fb0e",
        "edition": 419,
        "date": 1665265063308,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.563749999999999,
        "id": "419",
        "rarity_score": 61.93876004462962,
        "rarity_level": "COMMON"
    },
    "420": {
        "name": "Pact Rats #420",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/420.png",
        "dna": "e32a168d38aae5899033ee619d6eac0e79e60ff6",
        "edition": 420,
        "date": 1665265079208,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.044999999999998,
        "id": "420",
        "rarity_score": 83.67509109292777,
        "rarity_level": "COMMON"
    },
    "421": {
        "name": "Pact Rats #421",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/421.png",
        "dna": "4d60197a6abdfabd6eaf10038fe4abf9303282ed",
        "edition": 421,
        "date": 1665265059008,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.215,
        "id": "421",
        "rarity_score": 89.75883093150668,
        "rarity_level": "COMMON"
    },
    "422": {
        "name": "Pact Rats #422",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/422.png",
        "dna": "1a630922fbf163de824411509569af72e00504ea",
        "edition": 422,
        "date": 1665265114321,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.557500000000001,
        "id": "422",
        "rarity_score": 80.46842314940504,
        "rarity_level": "COMMON"
    },
    "423": {
        "name": "Pact Rats #423",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/423.png",
        "dna": "8e44df04f55ce91e3944fd7747206a045f365641",
        "edition": 423,
        "date": 1665265090375,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.8325,
        "id": "423",
        "rarity_score": 119.11961446499686,
        "rarity_level": "RARE"
    },
    "424": {
        "name": "Pact Rats #424",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/424.png",
        "dna": "7b63576a89dbbb66f395141c261a268bdf585114",
        "edition": 424,
        "date": 1665265147723,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.578750000000001,
        "id": "424",
        "rarity_score": 128.69198867709252,
        "rarity_level": "RARE"
    },
    "425": {
        "name": "Pact Rats #425",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/425.png",
        "dna": "c74753736aa872a32bff7fffd98eaa74e3b0ac61",
        "edition": 425,
        "date": 1665265051653,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.85625,
        "id": "425",
        "rarity_score": 59.84401454096879,
        "rarity_level": "COMMON"
    },
    "426": {
        "name": "Pact Rats #426",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/426.png",
        "dna": "7f76d9578c95dc6f39be51100f56d0e8a6e4463b",
        "edition": 426,
        "date": 1665265058508,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.57125,
        "id": "426",
        "rarity_score": 61.49892663117221,
        "rarity_level": "COMMON"
    },
    "427": {
        "name": "Pact Rats #427",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/427.png",
        "dna": "797605dfdd1583a514d9bd60dba7206ab9a15902",
        "edition": 427,
        "date": 1665265105879,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Super",
        "rarity": 6.93375,
        "id": "427",
        "rarity_score": 132.77151965091304,
        "rarity_level": "RARE"
    },
    "428": {
        "name": "Pact Rats #428",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/428.png",
        "dna": "22ef0b3eee1ae0d7c89f39736222a9bf451f39d1",
        "edition": 428,
        "date": 1665265028729,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.596250000000001,
        "id": "428",
        "rarity_score": 75.193485268967,
        "rarity_level": "COMMON"
    },
    "429": {
        "name": "Pact Rats #429",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/429.png",
        "dna": "9af80e888d4c8ae545dfbf8fbf72c7e579bb19a0",
        "edition": 429,
        "date": 1665265182186,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Epic",
        "rarity": 9.615,
        "id": "429",
        "rarity_score": 127.66593579989879,
        "rarity_level": "RARE"
    },
    "430": {
        "name": "Pact Rats #430",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/430.png",
        "dna": "c78557bcabe31d21d8f0c8d8f7d2bf79a2de606c",
        "edition": 430,
        "date": 1665265160976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Epic",
        "rarity": 8.330000000000002,
        "id": "430",
        "rarity_score": 117.31959719112423,
        "rarity_level": "RARE"
    },
    "431": {
        "name": "Pact Rats #431",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/431.png",
        "dna": "2722bfccc76fb75a552ac2ba27bd51d22658243d",
        "edition": 431,
        "date": 1665265113822,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.837499999999999,
        "id": "431",
        "rarity_score": 119.95221947581561,
        "rarity_level": "RARE"
    },
    "432": {
        "name": "Pact Rats #432",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/432.png",
        "dna": "91d93de83978612905f603f98d5088215c7d9774",
        "edition": 432,
        "date": 1665265116901,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.195,
        "id": "432",
        "rarity_score": 97.26588556731227,
        "rarity_level": "COMMON"
    },
    "433": {
        "name": "Pact Rats #433",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/433.png",
        "dna": "5c15b4bc76b11675e62cfc4c530bdb14e5fc20f6",
        "edition": 433,
        "date": 1665265131329,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.79,
        "id": "433",
        "rarity_score": 120.67658478718283,
        "rarity_level": "RARE"
    },
    "434": {
        "name": "Pact Rats #434",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/434.png",
        "dna": "2a85a6ed1778419c1c92040fd4aebec917d0356c",
        "edition": 434,
        "date": 1665265139629,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.901250000000001,
        "id": "434",
        "rarity_score": 83.66299532847017,
        "rarity_level": "COMMON"
    },
    "435": {
        "name": "Pact Rats #435",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/435.png",
        "dna": "dce1a5d8356568a0cb101183be0a81f708241ab7",
        "edition": 435,
        "date": 1665265032528,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.7225,
        "id": "435",
        "rarity_score": 123.47913352895881,
        "rarity_level": "RARE"
    },
    "436": {
        "name": "Pact Rats #436",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/436.png",
        "dna": "458b19006976b530af6bf4664c34e6668acb71f6",
        "edition": 436,
        "date": 1665265105384,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 7.016249999999999,
        "id": "436",
        "rarity_score": 149.60708185087577,
        "rarity_level": "EPIC"
    },
    "437": {
        "name": "Pact Rats #437",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/437.png",
        "dna": "8ba7ed78f405d9b8e15fd81add739a0be71ae38f",
        "edition": 437,
        "date": 1665265074412,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.56875,
        "id": "437",
        "rarity_score": 87.16150769880397,
        "rarity_level": "COMMON"
    },
    "438": {
        "name": "Pact Rats #438",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/438.png",
        "dna": "9dca8747f8f9815f0716c785a44f4d0a205cefb1",
        "edition": 438,
        "date": 1665265132377,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.471250000000001,
        "id": "438",
        "rarity_score": 93.52749119852317,
        "rarity_level": "COMMON"
    },
    "439": {
        "name": "Pact Rats #439",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/439.png",
        "dna": "938df71eaf61652ecb336f5925663f6981ed8d4f",
        "edition": 439,
        "date": 1665265135255,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 7.718750000000002,
        "id": "439",
        "rarity_score": 127.51273508444527,
        "rarity_level": "RARE"
    },
    "440": {
        "name": "Pact Rats #440",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/440.png",
        "dna": "46aa7cc256c9faf69195c6464f0466d35e752a7a",
        "edition": 440,
        "date": 1665265179321,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.64125,
        "id": "440",
        "rarity_score": 74.14600819830447,
        "rarity_level": "COMMON"
    },
    "441": {
        "name": "Pact Rats #441",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/441.png",
        "dna": "68cbea453d13af81d9fa73c9aaef6d82ba34e4ef",
        "edition": 441,
        "date": 1665265122288,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.68,
        "id": "441",
        "rarity_score": 117.54020913721874,
        "rarity_level": "RARE"
    },
    "442": {
        "name": "Pact Rats #442",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/442.png",
        "dna": "1d9e33a1fa5b3373e74445eaee832c2b167b2462",
        "edition": 442,
        "date": 1665265061290,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Super",
        "rarity": 6.520000000000001,
        "id": "442",
        "rarity_score": 145.3989217810638,
        "rarity_level": "RARE"
    },
    "443": {
        "name": "Pact Rats #443",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/443.png",
        "dna": "4b4fc2bb74c74c33619d2a03661511a052cc938f",
        "edition": 443,
        "date": 1665265104630,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.279999999999998,
        "id": "443",
        "rarity_score": 80.03711625287622,
        "rarity_level": "COMMON"
    },
    "444": {
        "name": "Pact Rats #444",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/444.png",
        "dna": "d098058f5b68e056b1ae18d3347fdcab45a8c9b7",
        "edition": 444,
        "date": 1665265071596,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Rare",
        "rarity": 10.64875,
        "id": "444",
        "rarity_score": 119.69447767745385,
        "rarity_level": "RARE"
    },
    "445": {
        "name": "Pact Rats #445",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/445.png",
        "dna": "7f18eddfc3dcf99c0bfe9c88f97c3c1b0ea56a1e",
        "edition": 445,
        "date": 1665265036564,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.73375,
        "id": "445",
        "rarity_score": 84.75817803971412,
        "rarity_level": "COMMON"
    },
    "446": {
        "name": "Pact Rats #446",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/446.png",
        "dna": "c545a838da49753a5b7e090669057b2a37772ebb",
        "edition": 446,
        "date": 1665265106129,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 8.791249999999998,
        "id": "446",
        "rarity_score": 148.31239294986347,
        "rarity_level": "EPIC"
    },
    "447": {
        "name": "Pact Rats #447",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/447.png",
        "dna": "527612efb846e8774bb3ac0232dd88409f22d67e",
        "edition": 447,
        "date": 1665265121287,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 10.415,
        "id": "447",
        "rarity_score": 120.29724058120979,
        "rarity_level": "RARE"
    },
    "448": {
        "name": "Pact Rats #448",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/448.png",
        "dna": "f2cc55bded133cbdbaea3c54c03436d2a4d5ef19",
        "edition": 448,
        "date": 1665265040151,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Common",
        "rarity": 13.274999999999999,
        "id": "448",
        "rarity_score": 94.41095763131052,
        "rarity_level": "COMMON"
    },
    "449": {
        "name": "Pact Rats #449",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/449.png",
        "dna": "1a1cda82619747431db6b4a3d9caa5f9b73eb431",
        "edition": 449,
        "date": 1665265099542,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.64125,
        "id": "449",
        "rarity_score": 88.40714597357268,
        "rarity_level": "COMMON"
    },
    "450": {
        "name": "Pact Rats #450",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/450.png",
        "dna": "d36904130504af2480012720e31e838393af97c7",
        "edition": 450,
        "date": 1665265026153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.12125,
        "id": "450",
        "rarity_score": 125.44389833715309,
        "rarity_level": "RARE"
    },
    "451": {
        "name": "Pact Rats #451",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/451.png",
        "dna": "c9b4699065d6057d976a838de53f366682fd030c",
        "edition": 451,
        "date": 1665265111028,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.247500000000002,
        "id": "451",
        "rarity_score": 90.67489036848124,
        "rarity_level": "COMMON"
    },
    "452": {
        "name": "Pact Rats #452",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/452.png",
        "dna": "d2d01e7a9aaaa3b25940a1033683439bed8e6e9f",
        "edition": 452,
        "date": 1665265076933,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.506250000000001,
        "id": "452",
        "rarity_score": 114.66485106743247,
        "rarity_level": "RARE"
    },
    "453": {
        "name": "Pact Rats #453",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/453.png",
        "dna": "911798983071610e630516020ebfde3524cbe4d3",
        "edition": 453,
        "date": 1665265032022,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.9875,
        "id": "453",
        "rarity_score": 149.97974639990167,
        "rarity_level": "EPIC"
    },
    "454": {
        "name": "Pact Rats #454",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/454.png",
        "dna": "d05c5e7f503bb8a2276e32f35224214e64b888c4",
        "edition": 454,
        "date": 1665265186876,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.822499999999998,
        "id": "454",
        "rarity_score": 79.00254829811855,
        "rarity_level": "COMMON"
    },
    "455": {
        "name": "Pact Rats #455",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/455.png",
        "dna": "26150886f3e1e879bbee35b6f14785f014fe9f45",
        "edition": 455,
        "date": 1665265182727,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Rare",
        "rarity": 12.05,
        "id": "455",
        "rarity_score": 171.11514423418134,
        "rarity_level": "EPIC"
    },
    "456": {
        "name": "Pact Rats #456",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/456.png",
        "dna": "809348e56ea40abc906298cfc33ac1371a5aa63f",
        "edition": 456,
        "date": 1665265112578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Bull Horns", "score": 66.6 }
        ],
        "rank": "Rare",
        "rarity": 11.175,
        "id": "456",
        "rarity_score": 128.76152440452296,
        "rarity_level": "RARE"
    },
    "457": {
        "name": "Pact Rats #457",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/457.png",
        "dna": "451be9bc0b6cd05f3a0f6795a0d8b98043a48ba0",
        "edition": 457,
        "date": 1665265137326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.68375,
        "id": "457",
        "rarity_score": 65.4351152305396,
        "rarity_level": "COMMON"
    },
    "458": {
        "name": "Pact Rats #458",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/458.png",
        "dna": "6feeeaa798fb283279589940f640ae9933c60d04",
        "edition": 458,
        "date": 1665265038075,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.749999999999996,
        "id": "458",
        "rarity_score": 62.413362018479944,
        "rarity_level": "COMMON"
    },
    "459": {
        "name": "Pact Rats #459",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/459.png",
        "dna": "3923b7f8b5de9fb39a47c61fee39b91443c5e71e",
        "edition": 459,
        "date": 1665265149036,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.596249999999998,
        "id": "459",
        "rarity_score": 57.78691364415923,
        "rarity_level": "COMMON"
    },
    "460": {
        "name": "Pact Rats #460",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/460.png",
        "dna": "e06ffea1df898cbad7900296d4dea83180970179",
        "edition": 460,
        "date": 1665265086067,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.635,
        "id": "460",
        "rarity_score": 133.0702741975422,
        "rarity_level": "RARE"
    },
    "461": {
        "name": "Pact Rats #461",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/461.png",
        "dna": "99e3f9bddaf3390d5edd2500056b6d2eadde6fc0",
        "edition": 461,
        "date": 1665265023326,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.73,
        "id": "461",
        "rarity_score": 95.38159300469191,
        "rarity_level": "COMMON"
    },
    "462": {
        "name": "Pact Rats #462",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/462.png",
        "dna": "64d5373558b46e3b0c0a0fd6df9cca0103de8e7b",
        "edition": 462,
        "date": 1665265058764,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 11.8875,
        "id": "462",
        "rarity_score": 110.96530097077003,
        "rarity_level": "RARE"
    },
    "463": {
        "name": "Pact Rats #463",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/463.png",
        "dna": "e68c0b7620cbd56e4c658890416f1f092fd882c9",
        "edition": 463,
        "date": 1665265071878,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.841250000000002,
        "id": "463",
        "rarity_score": 61.732398192433806,
        "rarity_level": "COMMON"
    },
    "464": {
        "name": "Pact Rats #464",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/464.png",
        "dna": "4ce1623df32c5a86661de301a35b17ae737249d8",
        "edition": 464,
        "date": 1665265096484,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.7575,
        "id": "464",
        "rarity_score": 83.489638513207,
        "rarity_level": "COMMON"
    },
    "465": {
        "name": "Pact Rats #465",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/465.png",
        "dna": "cbba506e2c108a4aa142d9dcb5141ddf1a2b42fd",
        "edition": 465,
        "date": 1665265049817,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.03125,
        "id": "465",
        "rarity_score": 106.02665648853554,
        "rarity_level": "COMMON"
    },
    "466": {
        "name": "Pact Rats #466",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/466.png",
        "dna": "fbc7a0fa56cf0b036731104eac13fa8765b5d4a1",
        "edition": 466,
        "date": 1665265166347,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.655000000000001,
        "id": "466",
        "rarity_score": 81.55173471816143,
        "rarity_level": "COMMON"
    },
    "467": {
        "name": "Pact Rats #467",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/467.png",
        "dna": "728d50171a1801c10f2e05ecbb07b80881b1126f",
        "edition": 467,
        "date": 1665265144050,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.64125,
        "id": "467",
        "rarity_score": 118.04034565308712,
        "rarity_level": "RARE"
    },
    "468": {
        "name": "Pact Rats #468",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/468.png",
        "dna": "282a96feafa343f34a5d813bf24b6a5cd79b6b73",
        "edition": 468,
        "date": 1665265091871,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 9.147499999999997,
        "id": "468",
        "rarity_score": 110.06650115833676,
        "rarity_level": "COMMON"
    },
    "469": {
        "name": "Pact Rats #469",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/469.png",
        "dna": "5cce8e68132e91ec0b1777e2feb3c4ea725a4969",
        "edition": 469,
        "date": 1665265143002,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.744999999999997,
        "id": "469",
        "rarity_score": 68.7351100956228,
        "rarity_level": "COMMON"
    },
    "470": {
        "name": "Pact Rats #470",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/470.png",
        "dna": "cc841824073d5098adacefb10412b476ec8491e8",
        "edition": 470,
        "date": 1665265189213,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Epic",
        "rarity": 7.6,
        "id": "470",
        "rarity_score": 120.88472370326303,
        "rarity_level": "RARE"
    },
    "471": {
        "name": "Pact Rats #471",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/471.png",
        "dna": "3222194d3178fbf7b90a6a8f66e27be3b32f5904",
        "edition": 471,
        "date": 1665265111811,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.395,
        "id": "471",
        "rarity_score": 87.32740374154852,
        "rarity_level": "COMMON"
    },
    "472": {
        "name": "Pact Rats #472",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/472.png",
        "dna": "82e8fad6a1734d98f3d521e3d8e2f861574f486a",
        "edition": 472,
        "date": 1665265132916,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.3725,
        "id": "472",
        "rarity_score": 85.0875223893943,
        "rarity_level": "COMMON"
    },
    "473": {
        "name": "Pact Rats #473",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/473.png",
        "dna": "e4db7f53623aea79b689f0b877a63956dbce5c09",
        "edition": 473,
        "date": 1665265042451,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.0125,
        "id": "473",
        "rarity_score": 86.18128429838922,
        "rarity_level": "COMMON"
    },
    "474": {
        "name": "Pact Rats #474",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/474.png",
        "dna": "1539f4bf88dbc3fc2a58f076f41827b5ea85287b",
        "edition": 474,
        "date": 1665265182462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.142500000000002,
        "id": "474",
        "rarity_score": 63.9490827278195,
        "rarity_level": "COMMON"
    },
    "475": {
        "name": "Pact Rats #475",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/475.png",
        "dna": "da8a530b4ecfe3e2cde9b09ef45de939f435a41c",
        "edition": 475,
        "date": 1665265087093,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 8.706249999999999,
        "id": "475",
        "rarity_score": 160.67209103863414,
        "rarity_level": "EPIC"
    },
    "476": {
        "name": "Pact Rats #476",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/476.png",
        "dna": "9b08c03ca07b72a20acc139af864edced2212766",
        "edition": 476,
        "date": 1665265121791,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.091250000000002,
        "id": "476",
        "rarity_score": 80.24576967256871,
        "rarity_level": "COMMON"
    },
    "477": {
        "name": "Pact Rats #477",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/477.png",
        "dna": "c201f59aa30cbf8183c7f65590f28ef487aff0f1",
        "edition": 477,
        "date": 1665265129539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 10.65,
        "id": "477",
        "rarity_score": 148.81430275542982,
        "rarity_level": "EPIC"
    },
    "478": {
        "name": "Pact Rats #478",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/478.png",
        "dna": "972dd63ab777d58fa7818b0cd2462157e9f33a2e",
        "edition": 478,
        "date": 1665265039361,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.53875,
        "id": "478",
        "rarity_score": 88.53505373735668,
        "rarity_level": "COMMON"
    },
    "479": {
        "name": "Pact Rats #479",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/479.png",
        "dna": "21700d43fe6dc3380500d55f026b51cc2486e40d",
        "edition": 479,
        "date": 1665265047796,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.157499999999999,
        "id": "479",
        "rarity_score": 118.78016127980317,
        "rarity_level": "RARE"
    },
    "480": {
        "name": "Pact Rats #480",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/480.png",
        "dna": "8b681af81f1399f700361b9a0d172b908adee4d2",
        "edition": 480,
        "date": 1665265117419,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.86875,
        "id": "480",
        "rarity_score": 127.65777976007604,
        "rarity_level": "RARE"
    },
    "481": {
        "name": "Pact Rats #481",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/481.png",
        "dna": "2fd8fec17a0c0655e1d37359d43f8821720ba331",
        "edition": 481,
        "date": 1665265034271,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.932500000000001,
        "id": "481",
        "rarity_score": 68.20850411753116,
        "rarity_level": "COMMON"
    },
    "482": {
        "name": "Pact Rats #482",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/482.png",
        "dna": "78bbc438d6d0ec77a328801d5432f4c7cc047c0b",
        "edition": 482,
        "date": 1665265078465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.52,
        "id": "482",
        "rarity_score": 90.44461446499686,
        "rarity_level": "COMMON"
    },
    "483": {
        "name": "Pact Rats #483",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/483.png",
        "dna": "674537153c307bea644e55866a6e5f8cce9640b4",
        "edition": 483,
        "date": 1665265045032,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 8.60375,
        "id": "483",
        "rarity_score": 99.33925605479646,
        "rarity_level": "COMMON"
    },
    "484": {
        "name": "Pact Rats #484",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/484.png",
        "dna": "93e95505908481b3ccb10fd88451f4ec4293e306",
        "edition": 484,
        "date": 1665265173353,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Captain PACT Sparrow Hat",
                "score": 133.2
            }
        ],
        "rank": "Rare",
        "rarity": 10.942499999999999,
        "id": "484",
        "rarity_score": 198.93612081176474,
        "rarity_level": "LEGENDARY"
    },
    "485": {
        "name": "Pact Rats #485",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/485.png",
        "dna": "c055550585db2d9463857351519a09fbcd56c8e9",
        "edition": 485,
        "date": 1665265188703,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.653749999999999,
        "id": "485",
        "rarity_score": 91.84815683866253,
        "rarity_level": "COMMON"
    },
    "486": {
        "name": "Pact Rats #486",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/486.png",
        "dna": "d462e9736246e8bd79d30a2b70032c291ea589ca",
        "edition": 486,
        "date": 1665265083358,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Common",
        "rarity": 14.186249999999998,
        "id": "486",
        "rarity_score": 94.71976965456594,
        "rarity_level": "COMMON"
    },
    "487": {
        "name": "Pact Rats #487",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/487.png",
        "dna": "ea63a83643b849ba87fce4eb65a22f762780cfda",
        "edition": 487,
        "date": 1665265071100,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.612499999999997,
        "id": "487",
        "rarity_score": 100.9225167812753,
        "rarity_level": "COMMON"
    },
    "488": {
        "name": "Pact Rats #488",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/488.png",
        "dna": "02bb9550756804ccb0b15c225de85daedfce9eb7",
        "edition": 488,
        "date": 1665265118465,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.0075,
        "id": "488",
        "rarity_score": 95.15243818136489,
        "rarity_level": "COMMON"
    },
    "489": {
        "name": "Pact Rats #489",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/489.png",
        "dna": "388fd896d0793d0ecd63d306794f13111eea6ca6",
        "edition": 489,
        "date": 1665265083099,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.6775,
        "id": "489",
        "rarity_score": 70.36898200121105,
        "rarity_level": "COMMON"
    },
    "490": {
        "name": "Pact Rats #490",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/490.png",
        "dna": "b4fb68d8329226cd5364ceb78c6fa6905e321349",
        "edition": 490,
        "date": 1665265152210,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.45,
        "id": "490",
        "rarity_score": 142.91133493729973,
        "rarity_level": "RARE"
    },
    "491": {
        "name": "Pact Rats #491",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/491.png",
        "dna": "fcf1acfddf26c2e92869bca898e959b0cbf62092",
        "edition": 491,
        "date": 1665265111293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.7375,
        "id": "491",
        "rarity_score": 68.33052398963247,
        "rarity_level": "COMMON"
    },
    "492": {
        "name": "Pact Rats #492",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/492.png",
        "dna": "1e1ed3bd9ff7782225ae0f7500dd46b01fdd6286",
        "edition": 492,
        "date": 1665265076412,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.903750000000002,
        "id": "492",
        "rarity_score": 83.25315948921195,
        "rarity_level": "COMMON"
    },
    "493": {
        "name": "Pact Rats #493",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/493.png",
        "dna": "387638abdc3e1883b92d2ccad7dece46bd589a95",
        "edition": 493,
        "date": 1665265151090,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.811250000000001,
        "id": "493",
        "rarity_score": 123.59035423695414,
        "rarity_level": "RARE"
    },
    "494": {
        "name": "Pact Rats #494",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/494.png",
        "dna": "9a5399ae219541b76c4b598585f5c637f8074235",
        "edition": 494,
        "date": 1665265104117,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 11.315000000000001,
        "id": "494",
        "rarity_score": 108.95922795726891,
        "rarity_level": "COMMON"
    },
    "495": {
        "name": "Pact Rats #495",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/495.png",
        "dna": "4b20e809e12557313e683c9cc49ba8747a32a64d",
        "edition": 495,
        "date": 1665265080743,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.357500000000002,
        "id": "495",
        "rarity_score": 97.23949834742271,
        "rarity_level": "COMMON"
    },
    "496": {
        "name": "Pact Rats #496",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/496.png",
        "dna": "ff052d4356635e380b56f51f2138894366726575",
        "edition": 496,
        "date": 1665265027409,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 7.479999999999999,
        "id": "496",
        "rarity_score": 116.38513105847258,
        "rarity_level": "RARE"
    },
    "497": {
        "name": "Pact Rats #497",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/497.png",
        "dna": "1e29b020896e50fc3ccb1d5e347bb20a87f5dca0",
        "edition": 497,
        "date": 1665265044297,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.088750000000001,
        "id": "497",
        "rarity_score": 73.31817696700013,
        "rarity_level": "COMMON"
    },
    "498": {
        "name": "Pact Rats #498",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/498.png",
        "dna": "02e157168d594702f54e10b63ab296cdcfa94f62",
        "edition": 498,
        "date": 1665265035289,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.41375,
        "id": "498",
        "rarity_score": 89.88489150865851,
        "rarity_level": "COMMON"
    },
    "499": {
        "name": "Pact Rats #499",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/499.png",
        "dna": "bab0a60fd4010efe5e1523065397ce3d79a5a677",
        "edition": 499,
        "date": 1665265104875,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.922500000000001,
        "id": "499",
        "rarity_score": 137.0344859814424,
        "rarity_level": "RARE"
    },
    "500": {
        "name": "Pact Rats #500",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/500.png",
        "dna": "ae78cc9d6e252e5a74763266849b2c352f7a58ca",
        "edition": 500,
        "date": 1665265162898,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.946250000000003,
        "id": "500",
        "rarity_score": 81.63883248280965,
        "rarity_level": "COMMON"
    },
    "501": {
        "name": "Pact Rats #501",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/501.png",
        "dna": "9b5aa8e84005803b7d39615fd3cfe744955134c7",
        "edition": 501,
        "date": 1665265073378,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.83125,
        "id": "501",
        "rarity_score": 70.91269392344871,
        "rarity_level": "COMMON"
    },
    "502": {
        "name": "Pact Rats #502",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/502.png",
        "dna": "b219e159f679eee695200da72741163c714b500e",
        "edition": 502,
        "date": 1665265156772,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 9.665,
        "id": "502",
        "rarity_score": 109.8333297106671,
        "rarity_level": "COMMON"
    },
    "503": {
        "name": "Pact Rats #503",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/503.png",
        "dna": "67a49e39e7ccbc34eaeb1b7554ba37d2b0ce7cb6",
        "edition": 503,
        "date": 1665265053423,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.893749999999999,
        "id": "503",
        "rarity_score": 129.57404766286345,
        "rarity_level": "RARE"
    },
    "504": {
        "name": "Pact Rats #504",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/504.png",
        "dna": "f7988b663da91549024ab1af3ecb3101611584cd",
        "edition": 504,
        "date": 1665265172569,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.8925,
        "id": "504",
        "rarity_score": 69.35199751836565,
        "rarity_level": "COMMON"
    },
    "505": {
        "name": "Pact Rats #505",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/505.png",
        "dna": "044b3adc22befc3b630802882feffc58b078ebc7",
        "edition": 505,
        "date": 1665265164500,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.7275,
        "id": "505",
        "rarity_score": 108.4244329945417,
        "rarity_level": "COMMON"
    },
    "506": {
        "name": "Pact Rats #506",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/506.png",
        "dna": "3e49b040341664e581f50a37521ce7b48867a6b4",
        "edition": 506,
        "date": 1665265181421,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.985,
        "id": "506",
        "rarity_score": 79.33992778396488,
        "rarity_level": "COMMON"
    },
    "507": {
        "name": "Pact Rats #507",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/507.png",
        "dna": "afb679d39a92e0e23653ed559984dda80a694885",
        "edition": 507,
        "date": 1665265110766,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 10.11375,
        "id": "507",
        "rarity_score": 147.13837973980645,
        "rarity_level": "RARE"
    },
    "508": {
        "name": "Pact Rats #508",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/508.png",
        "dna": "e0a8de90f8c46e9667288f7ec2f6fd8dacd35492",
        "edition": 508,
        "date": 1665265063566,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 11.898749999999998,
        "id": "508",
        "rarity_score": 106.46863952388202,
        "rarity_level": "COMMON"
    },
    "509": {
        "name": "Pact Rats #509",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/509.png",
        "dna": "0570cb38fafe50eeacac79bfc0936391fb164c6a",
        "edition": 509,
        "date": 1665265164239,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.599999999999998,
        "id": "509",
        "rarity_score": 103.72807491826622,
        "rarity_level": "COMMON"
    },
    "510": {
        "name": "Pact Rats #510",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/510.png",
        "dna": "9eeff04e198b469dc9909079e9bb54e4140c2008",
        "edition": 510,
        "date": 1665265050864,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.436249999999998,
        "id": "510",
        "rarity_score": 60.01601396266045,
        "rarity_level": "COMMON"
    },
    "511": {
        "name": "Pact Rats #511",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/511.png",
        "dna": "09f61927d38be2b48f701afa42b31dee9d29d4e0",
        "edition": 511,
        "date": 1665265025669,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.2275,
        "id": "511",
        "rarity_score": 94.98690127138187,
        "rarity_level": "COMMON"
    },
    "512": {
        "name": "Pact Rats #512",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/512.png",
        "dna": "001b532c5725e2b4c59a4b54bae688cf4f70a562",
        "edition": 512,
        "date": 1665265089142,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.130000000000003,
        "id": "512",
        "rarity_score": 66.02362553171844,
        "rarity_level": "COMMON"
    },
    "513": {
        "name": "Pact Rats #513",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/513.png",
        "dna": "8d2a9e48e946ac257170d60315234d793216f421",
        "edition": 513,
        "date": 1665265041938,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.3125,
        "id": "513",
        "rarity_score": 74.8557958091147,
        "rarity_level": "COMMON"
    },
    "514": {
        "name": "Pact Rats #514",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/514.png",
        "dna": "e9d4eb85844d2634a27cd20f22efb02574737bf1",
        "edition": 514,
        "date": 1665265166602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.1125,
        "id": "514",
        "rarity_score": 105.3863398581527,
        "rarity_level": "COMMON"
    },
    "515": {
        "name": "Pact Rats #515",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/515.png",
        "dna": "0ec9a914cfca2191c3d5e415375dc58d3f532354",
        "edition": 515,
        "date": 1665265175424,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Super",
        "rarity": 4.8525,
        "id": "515",
        "rarity_score": 151.27111528822059,
        "rarity_level": "EPIC"
    },
    "516": {
        "name": "Pact Rats #516",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/516.png",
        "dna": "9d5df072eabea7ea3e02b731b97570b2bd8f9d95",
        "edition": 516,
        "date": 1665265142742,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.9175,
        "id": "516",
        "rarity_score": 84.9650841567654,
        "rarity_level": "COMMON"
    },
    "517": {
        "name": "Pact Rats #517",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/517.png",
        "dna": "fc1e80572ceef1e39563feaf680d0e0c1a26d5d7",
        "edition": 517,
        "date": 1665265177776,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.38625,
        "id": "517",
        "rarity_score": 118.55147084680877,
        "rarity_level": "RARE"
    },
    "518": {
        "name": "Pact Rats #518",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/518.png",
        "dna": "ffcf6007c6b81d7fc130889eb3dbb11cca5e2768",
        "edition": 518,
        "date": 1665265148238,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.7075,
        "id": "518",
        "rarity_score": 79.14806063145019,
        "rarity_level": "COMMON"
    },
    "519": {
        "name": "Pact Rats #519",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/519.png",
        "dna": "f53a33bd0709238519618e8550da67183ed0f829",
        "edition": 519,
        "date": 1665265067830,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.735,
        "id": "519",
        "rarity_score": 82.14637757530427,
        "rarity_level": "COMMON"
    },
    "520": {
        "name": "Pact Rats #520",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/520.png",
        "dna": "70250da8a30ac9ac2ab34fadaf8fffef2f52444f",
        "edition": 520,
        "date": 1665265136036,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.759999999999998,
        "id": "520",
        "rarity_score": 103.34988348370094,
        "rarity_level": "COMMON"
    },
    "521": {
        "name": "Pact Rats #521",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/521.png",
        "dna": "e3f8242bf7e2249721a288780c25e6120a0b31a8",
        "edition": 521,
        "date": 1665265063813,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.28375,
        "id": "521",
        "rarity_score": 130.3329546532089,
        "rarity_level": "RARE"
    },
    "522": {
        "name": "Pact Rats #522",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/522.png",
        "dna": "cfa8fb14a6ae5bc65c4de179da5b2d3a6951a289",
        "edition": 522,
        "date": 1665265168685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.49125,
        "id": "522",
        "rarity_score": 75.82519265937589,
        "rarity_level": "COMMON"
    },
    "523": {
        "name": "Pact Rats #523",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/523.png",
        "dna": "73cea003cdb7634ecb25159b7f9343cb1687e2e7",
        "edition": 523,
        "date": 1665265087594,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.0375,
        "id": "523",
        "rarity_score": 101.70616951513799,
        "rarity_level": "COMMON"
    },
    "524": {
        "name": "Pact Rats #524",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/524.png",
        "dna": "eb071e2a2ddb207c4163e577ef9ce4f93421116e",
        "edition": 524,
        "date": 1665265129293,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 7.805,
        "id": "524",
        "rarity_score": 131.36628541370715,
        "rarity_level": "RARE"
    },
    "525": {
        "name": "Pact Rats #525",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/525.png",
        "dna": "9dc688dd52a95e37d74bfdc1fbb4eef8bc6ae6ee",
        "edition": 525,
        "date": 1665265168162,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.79,
        "id": "525",
        "rarity_score": 127.87656959554626,
        "rarity_level": "RARE"
    },
    "526": {
        "name": "Pact Rats #526",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/526.png",
        "dna": "060a1f6021b1a078f06052504f2f600646f006f5",
        "edition": 526,
        "date": 1665265060301,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.12,
        "id": "526",
        "rarity_score": 88.45915607039409,
        "rarity_level": "COMMON"
    },
    "527": {
        "name": "Pact Rats #527",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/527.png",
        "dna": "b30bea7a44a075deb32b78386c2569f05aa74d83",
        "edition": 527,
        "date": 1665265151641,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.235,
        "id": "527",
        "rarity_score": 71.66956381846667,
        "rarity_level": "COMMON"
    },
    "528": {
        "name": "Pact Rats #528",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/528.png",
        "dna": "a3d001f936c3098853a57d43a49462c033747aaf",
        "edition": 528,
        "date": 1665265165820,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.55375,
        "id": "528",
        "rarity_score": 81.43022003625914,
        "rarity_level": "COMMON"
    },
    "529": {
        "name": "Pact Rats #529",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/529.png",
        "dna": "afcaa16ed97b47d3d27120557ed4ac9e27a7b03f",
        "edition": 529,
        "date": 1665265091369,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Super",
        "rarity": 6.577500000000001,
        "id": "529",
        "rarity_score": 156.62700717257744,
        "rarity_level": "EPIC"
    },
    "530": {
        "name": "Pact Rats #530",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/530.png",
        "dna": "f2cde83d1d2d4d75c5e1b4e0446f0c6efdf3365f",
        "edition": 530,
        "date": 1665265079713,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.432500000000001,
        "id": "530",
        "rarity_score": 91.81920216969856,
        "rarity_level": "COMMON"
    },
    "531": {
        "name": "Pact Rats #531",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/531.png",
        "dna": "69180ecbf0555d6bf391bba8c90e3ffc4ca46c8f",
        "edition": 531,
        "date": 1665265023602,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.535,
        "id": "531",
        "rarity_score": 73.50773771692684,
        "rarity_level": "COMMON"
    },
    "532": {
        "name": "Pact Rats #532",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/532.png",
        "dna": "00797349cafbf7b16c3ffa0e420f85737d30c7d6",
        "edition": 532,
        "date": 1665265158656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.66875,
        "id": "532",
        "rarity_score": 117.76163086463657,
        "rarity_level": "RARE"
    },
    "533": {
        "name": "Pact Rats #533",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/533.png",
        "dna": "b35b0238f53dd8d0380868f5430c2f4341c3db77",
        "edition": 533,
        "date": 1665265065045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Samurai",
                "score": 39.1764705882353
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.35875,
        "id": "533",
        "rarity_score": 109.4345389525976,
        "rarity_level": "COMMON"
    },
    "534": {
        "name": "Pact Rats #534",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/534.png",
        "dna": "7a1efe78f18d3222b9228a61cfa85e544fd3000a",
        "edition": 534,
        "date": 1665265057475,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.788749999999997,
        "id": "534",
        "rarity_score": 93.17401191701047,
        "rarity_level": "COMMON"
    },
    "535": {
        "name": "Pact Rats #535",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/535.png",
        "dna": "bcec96dee3b2882b1680af5ff0e038964c38edfa",
        "edition": 535,
        "date": 1665265072607,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.09,
        "id": "535",
        "rarity_score": 80.78186481192233,
        "rarity_level": "COMMON"
    },
    "536": {
        "name": "Pact Rats #536",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/536.png",
        "dna": "4337ed9ef9ebec8e5e9bd8856a02bb75bf5e09de",
        "edition": 536,
        "date": 1665265175953,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 9.65375,
        "id": "536",
        "rarity_score": 135.25343271237375,
        "rarity_level": "RARE"
    },
    "537": {
        "name": "Pact Rats #537",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/537.png",
        "dna": "e3b8a0b7b8596c3a0af59f5e3658c8f6b580b97c",
        "edition": 537,
        "date": 1665265117965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.248749999999998,
        "id": "537",
        "rarity_score": 65.40075637465256,
        "rarity_level": "COMMON"
    },
    "538": {
        "name": "Pact Rats #538",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/538.png",
        "dna": "f19812f509773c31829b88219dd834054fc0a716",
        "edition": 538,
        "date": 1665265179851,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.0525,
        "id": "538",
        "rarity_score": 64.77460863404691,
        "rarity_level": "COMMON"
    },
    "539": {
        "name": "Pact Rats #539",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/539.png",
        "dna": "04cb856a44f7548246f68fab17af9e06934e11a6",
        "edition": 539,
        "date": 1665265029487,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Rare",
        "rarity": 10.615,
        "id": "539",
        "rarity_score": 179.98417995002325,
        "rarity_level": "EPIC"
    },
    "540": {
        "name": "Pact Rats #540",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/540.png",
        "dna": "1efb64717556026db0a6feca2669cd8809a439a0",
        "edition": 540,
        "date": 1665265100539,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.965,
        "id": "540",
        "rarity_score": 93.89689331928281,
        "rarity_level": "COMMON"
    },
    "541": {
        "name": "Pact Rats #541",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/541.png",
        "dna": "1da5da19997681241d05f00822cd30119b2eb7c3",
        "edition": 541,
        "date": 1665265159162,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.83125,
        "id": "541",
        "rarity_score": 107.46890357451872,
        "rarity_level": "COMMON"
    },
    "542": {
        "name": "Pact Rats #542",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/542.png",
        "dna": "f827fdc8832b1038ce53b2f9ffa3d4ba4c04a5fc",
        "edition": 542,
        "date": 1665265048565,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.13375,
        "id": "542",
        "rarity_score": 78.21584259859686,
        "rarity_level": "COMMON"
    },
    "543": {
        "name": "Pact Rats #543",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/543.png",
        "dna": "1a25853877250b19ed955de35ed98aeed692131c",
        "edition": 543,
        "date": 1665265104370,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.1125,
        "id": "543",
        "rarity_score": 68.37233601190424,
        "rarity_level": "COMMON"
    },
    "544": {
        "name": "Pact Rats #544",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/544.png",
        "dna": "251b64f783bfbb244954facd78ae144acfa98fde",
        "edition": 544,
        "date": 1665265071349,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 10.98875,
        "id": "544",
        "rarity_score": 125.25259772406693,
        "rarity_level": "RARE"
    },
    "545": {
        "name": "Pact Rats #545",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/545.png",
        "dna": "8eb18f796d2077eae1e92c2ad1f662d047d292a4",
        "edition": 545,
        "date": 1665265080477,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.904999999999998,
        "id": "545",
        "rarity_score": 73.29020926284099,
        "rarity_level": "COMMON"
    },
    "546": {
        "name": "Pact Rats #546",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/546.png",
        "dna": "0bd4d4f9174148e524075f5a082a762ea6394a2f",
        "edition": 546,
        "date": 1665265090878,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.55,
        "id": "546",
        "rarity_score": 80.24792366787928,
        "rarity_level": "COMMON"
    },
    "547": {
        "name": "Pact Rats #547",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/547.png",
        "dna": "e99058777cf5cf3744f4fe29e9d4e489ac33c048",
        "edition": 547,
        "date": 1665265172297,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.14,
        "id": "547",
        "rarity_score": 95.74450290887481,
        "rarity_level": "COMMON"
    },
    "548": {
        "name": "Pact Rats #548",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/548.png",
        "dna": "0498ed6cabd5530d90a91cc7bf307cb7290bb029",
        "edition": 548,
        "date": 1665265145614,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.06625,
        "id": "548",
        "rarity_score": 96.57729613024291,
        "rarity_level": "COMMON"
    },
    "549": {
        "name": "Pact Rats #549",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/549.png",
        "dna": "795a08603509abec7feca70a8a07607f13403864",
        "edition": 549,
        "date": 1665265122043,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.104999999999997,
        "id": "549",
        "rarity_score": 113.05613235086142,
        "rarity_level": "RARE"
    },
    "550": {
        "name": "Pact Rats #550",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/550.png",
        "dna": "0564131f35379e173209ac93796386c1411ff16c",
        "edition": 550,
        "date": 1665265168427,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.223749999999999,
        "id": "550",
        "rarity_score": 73.87966913553872,
        "rarity_level": "COMMON"
    },
    "551": {
        "name": "Pact Rats #551",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/551.png",
        "dna": "95227b712e80607a1eb4f729534195dc9a195f27",
        "edition": 551,
        "date": 1665265096750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.5775,
        "id": "551",
        "rarity_score": 84.43390809735907,
        "rarity_level": "COMMON"
    },
    "552": {
        "name": "Pact Rats #552",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/552.png",
        "dna": "314c6820622bd3a17c0807bef1850ee67582211f",
        "edition": 552,
        "date": 1665265136801,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.844999999999999,
        "id": "552",
        "rarity_score": 125.11059317856223,
        "rarity_level": "RARE"
    },
    "553": {
        "name": "Pact Rats #553",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/553.png",
        "dna": "33ba1a89275274ecb5af35426bb612788cd08898",
        "edition": 553,
        "date": 1665265097264,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.6725,
        "id": "553",
        "rarity_score": 64.50025993183843,
        "rarity_level": "COMMON"
    },
    "554": {
        "name": "Pact Rats #554",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/554.png",
        "dna": "5fcacfd8b65d113afaf0e2541505206368c67b76",
        "edition": 554,
        "date": 1665265077449,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.0725,
        "id": "554",
        "rarity_score": 93.60297508880117,
        "rarity_level": "COMMON"
    },
    "555": {
        "name": "Pact Rats #555",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/555.png",
        "dna": "85f449ade1995d4ef78ecfccf9e8f9ef98861d95",
        "edition": 555,
        "date": 1665265157027,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.92875,
        "id": "555",
        "rarity_score": 81.11526285668957,
        "rarity_level": "COMMON"
    },
    "556": {
        "name": "Pact Rats #556",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/556.png",
        "dna": "7e1ed880f694c72a10ba9f2277176ae1a6d7529c",
        "edition": 556,
        "date": 1665265146936,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 10.629999999999999,
        "id": "556",
        "rarity_score": 100.30252229951853,
        "rarity_level": "COMMON"
    },
    "557": {
        "name": "Pact Rats #557",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/557.png",
        "dna": "96c11b76ad421805f6c377daafa209f8c6a3873c",
        "edition": 557,
        "date": 1665265086844,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.591249999999999,
        "id": "557",
        "rarity_score": 75.85254146341771,
        "rarity_level": "COMMON"
    },
    "558": {
        "name": "Pact Rats #558",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/558.png",
        "dna": "e3deb54b36898fd40890a73aa652aff2e4557168",
        "edition": 558,
        "date": 1665265144301,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.493749999999999,
        "id": "558",
        "rarity_score": 114.96344859726338,
        "rarity_level": "RARE"
    },
    "559": {
        "name": "Pact Rats #559",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/559.png",
        "dna": "19967f91bbe28d2c86ce8bef427d0e8c463f0205",
        "edition": 559,
        "date": 1665265115344,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.58625,
        "id": "559",
        "rarity_score": 77.43523559979616,
        "rarity_level": "COMMON"
    },
    "560": {
        "name": "Pact Rats #560",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/560.png",
        "dna": "ac7e453edec919a48411ca1513cfdb18dbf1dc38",
        "edition": 560,
        "date": 1665265120011,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.3875,
        "id": "560",
        "rarity_score": 67.52776964214155,
        "rarity_level": "COMMON"
    },
    "561": {
        "name": "Pact Rats #561",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/561.png",
        "dna": "0f900c06ffe57a4c0e812b0ed8062f6d2c73b78c",
        "edition": 561,
        "date": 1665265133960,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Platinum Chain",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.05125,
        "id": "561",
        "rarity_score": 123.48969816533442,
        "rarity_level": "RARE"
    },
    "562": {
        "name": "Pact Rats #562",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/562.png",
        "dna": "25b8653ad637524859f574c66fa270b2a45bfa94",
        "edition": 562,
        "date": 1665265101336,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.6825,
        "id": "562",
        "rarity_score": 84.45276494217337,
        "rarity_level": "COMMON"
    },
    "563": {
        "name": "Pact Rats #563",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/563.png",
        "dna": "5fd04c01b18af486c91e60dcd19ecc0d379460dd",
        "edition": 563,
        "date": 1665265143792,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.66375,
        "id": "563",
        "rarity_score": 100.34459186101856,
        "rarity_level": "COMMON"
    },
    "564": {
        "name": "Pact Rats #564",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/564.png",
        "dna": "96293ef82308271ca520e5fbaeaabecf31e67d62",
        "edition": 564,
        "date": 1665265118965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.216249999999999,
        "id": "564",
        "rarity_score": 104.64414879533489,
        "rarity_level": "COMMON"
    },
    "565": {
        "name": "Pact Rats #565",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/565.png",
        "dna": "1efc3cd261673568d84951d3fd664efe72c2d2fc",
        "edition": 565,
        "date": 1665265140656,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 8.40625,
        "id": "565",
        "rarity_score": 137.10148600515504,
        "rarity_level": "RARE"
    },
    "566": {
        "name": "Pact Rats #566",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/566.png",
        "dna": "6b3c33db1bee3bbd043b463e446a73e65983f779",
        "edition": 566,
        "date": 1665265190264,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.485,
        "id": "566",
        "rarity_score": 78.4650494664176,
        "rarity_level": "COMMON"
    },
    "567": {
        "name": "Pact Rats #567",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/567.png",
        "dna": "8bfe3b48d4dd8de712ac14aab9e0855441471560",
        "edition": 567,
        "date": 1665265175184,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Rare",
        "rarity": 12.965000000000003,
        "id": "567",
        "rarity_score": 82.89709721540811,
        "rarity_level": "COMMON"
    },
    "568": {
        "name": "Pact Rats #568",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/568.png",
        "dna": "7006abcdff3095b6cd189fb2728d45fb5932cca5",
        "edition": 568,
        "date": 1665265056963,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 10.01,
        "id": "568",
        "rarity_score": 115.53189891847643,
        "rarity_level": "RARE"
    },
    "569": {
        "name": "Pact Rats #569",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/569.png",
        "dna": "b9336f8e3952fca832727bcc90e5da609aaa1098",
        "edition": 569,
        "date": 1665265056214,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.112499999999997,
        "id": "569",
        "rarity_score": 105.10843218735889,
        "rarity_level": "COMMON"
    },
    "570": {
        "name": "Pact Rats #570",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/570.png",
        "dna": "22b3cc36a23247b6b6adc2718aeda89a2141fa9b",
        "edition": 570,
        "date": 1665265163713,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.61125,
        "id": "570",
        "rarity_score": 65.78838652072577,
        "rarity_level": "COMMON"
    },
    "571": {
        "name": "Pact Rats #571",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/571.png",
        "dna": "2ad3a714f0f3ff032baf29aefa2959d95df52311",
        "edition": 571,
        "date": 1665265033512,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Sweatband", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.48,
        "id": "571",
        "rarity_score": 109.86738235254509,
        "rarity_level": "COMMON"
    },
    "572": {
        "name": "Pact Rats #572",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/572.png",
        "dna": "37ce322572a2861f27161e1eeede3552287c5c4b",
        "edition": 572,
        "date": 1665265035552,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.035,
        "id": "572",
        "rarity_score": 84.803047935277,
        "rarity_level": "COMMON"
    },
    "573": {
        "name": "Pact Rats #573",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/573.png",
        "dna": "0d7e861cbbfd08a157e37c51f6295cff5166f6d0",
        "edition": 573,
        "date": 1665265045287,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.07375,
        "id": "573",
        "rarity_score": 71.56333244526462,
        "rarity_level": "COMMON"
    },
    "574": {
        "name": "Pact Rats #574",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/574.png",
        "dna": "5b904b3b885121929034124f74f6692a0c8b8a35",
        "edition": 574,
        "date": 1665265059531,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.59375,
        "id": "574",
        "rarity_score": 111.04414178714035,
        "rarity_level": "RARE"
    },
    "575": {
        "name": "Pact Rats #575",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/575.png",
        "dna": "022184b455333b8157115d5878d83e0f05886109",
        "edition": 575,
        "date": 1665265156530,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Aviation", "score": 55.5 }
        ],
        "rank": "Epic",
        "rarity": 7.0075,
        "id": "575",
        "rarity_score": 135.53239575028527,
        "rarity_level": "RARE"
    },
    "576": {
        "name": "Pact Rats #576",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/576.png",
        "dna": "9dd085af6174af88039125f2e3e878f243faf581",
        "edition": 576,
        "date": 1665265186099,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Epic",
        "rarity": 7.008749999999999,
        "id": "576",
        "rarity_score": 148.06839226974336,
        "rarity_level": "EPIC"
    },
    "577": {
        "name": "Pact Rats #577",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/577.png",
        "dna": "0644abf2849f560fc2afa368311816524feacd7f",
        "edition": 577,
        "date": 1665265187677,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Common",
        "rarity": 14.375,
        "id": "577",
        "rarity_score": 98.82322602580743,
        "rarity_level": "COMMON"
    },
    "578": {
        "name": "Pact Rats #578",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/578.png",
        "dna": "5197a7eb24c0bdff896b4e8a7b4480096c08af57",
        "edition": 578,
        "date": 1665265090132,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.954999999999998,
        "id": "578",
        "rarity_score": 115.47763438150251,
        "rarity_level": "RARE"
    },
    "579": {
        "name": "Pact Rats #579",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/579.png",
        "dna": "3f6d49c4338c4fe7645981710dde8a0d68a020aa",
        "edition": 579,
        "date": 1665265030265,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Viking Helmet", "score": 74.0 }
        ],
        "rank": "Epic",
        "rarity": 7.662499999999998,
        "id": "579",
        "rarity_score": 135.93309114036572,
        "rarity_level": "RARE"
    },
    "580": {
        "name": "Pact Rats #580",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/580.png",
        "dna": "60ac6c5a163f8427f0bc01363558c775ce3694e3",
        "edition": 580,
        "date": 1665265085328,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Flip Cap",
                "score": 60.54545454545455
            }
        ],
        "rank": "Epic",
        "rarity": 7.204999999999998,
        "id": "580",
        "rarity_score": 136.35005536995524,
        "rarity_level": "RARE"
    },
    "581": {
        "name": "Pact Rats #581",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/581.png",
        "dna": "8ad5728ce32b17a89491ab6255804682a0cd1c7a",
        "edition": 581,
        "date": 1665265116375,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.925,
        "id": "581",
        "rarity_score": 105.90912454757552,
        "rarity_level": "COMMON"
    },
    "582": {
        "name": "Pact Rats #582",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/582.png",
        "dna": "3f2a298f170d44575402140466587873154cc89e",
        "edition": 582,
        "date": 1665265061543,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.057500000000001,
        "id": "582",
        "rarity_score": 99.08478477388627,
        "rarity_level": "COMMON"
    },
    "583": {
        "name": "Pact Rats #583",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/583.png",
        "dna": "7aacb92065dc0478229dd3b70d9ad9629b55f2ee",
        "edition": 583,
        "date": 1665265098766,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 11.56875,
        "id": "583",
        "rarity_score": 132.19777856723903,
        "rarity_level": "RARE"
    },
    "584": {
        "name": "Pact Rats #584",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/584.png",
        "dna": "854e09b2706a971e65ffa1e05a5309e91268b54f",
        "edition": 584,
        "date": 1665265042977,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.187499999999996,
        "id": "584",
        "rarity_score": 76.43897260192635,
        "rarity_level": "COMMON"
    },
    "585": {
        "name": "Pact Rats #585",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/585.png",
        "dna": "da714bf49d4115a18ac4075f2e6f38a13d6af377",
        "edition": 585,
        "date": 1665265138847,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Rare",
        "rarity": 11.143749999999997,
        "id": "585",
        "rarity_score": 109.89735189993331,
        "rarity_level": "COMMON"
    },
    "586": {
        "name": "Pact Rats #586",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/586.png",
        "dna": "7ea959132dfd066323184f97dfe0120340ad0395",
        "edition": 586,
        "date": 1665265101866,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.66125,
        "id": "586",
        "rarity_score": 73.15622001888772,
        "rarity_level": "COMMON"
    },
    "587": {
        "name": "Pact Rats #587",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/587.png",
        "dna": "20c13c1a3d1b50f0516be2054ab3a55f029d5f5b",
        "edition": 587,
        "date": 1665265109740,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.244999999999997,
        "id": "587",
        "rarity_score": 67.96033283293666,
        "rarity_level": "COMMON"
    },
    "588": {
        "name": "Pact Rats #588",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/588.png",
        "dna": "04637cb429efb301879af693da60554b4639f5a3",
        "edition": 588,
        "date": 1665265162632,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.307499999999997,
        "id": "588",
        "rarity_score": 76.09609231645811,
        "rarity_level": "COMMON"
    },
    "589": {
        "name": "Pact Rats #589",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/589.png",
        "dna": "76af3e6f8b16be50891146519eaf170be6d4c2cc",
        "edition": 589,
        "date": 1665265021517,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.1275,
        "id": "589",
        "rarity_score": 81.43208133569341,
        "rarity_level": "COMMON"
    },
    "590": {
        "name": "Pact Rats #590",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/590.png",
        "dna": "1876df3b274bf373974508a5b2bc5cf8ccc55f0f",
        "edition": 590,
        "date": 1665265028984,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.451249999999998,
        "id": "590",
        "rarity_score": 81.29798252994298,
        "rarity_level": "COMMON"
    },
    "591": {
        "name": "Pact Rats #591",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/591.png",
        "dna": "9b98dd71c65e94c0177c3fdfab360375cd864a51",
        "edition": 591,
        "date": 1665265190005,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.20375,
        "id": "591",
        "rarity_score": 76.5645061966482,
        "rarity_level": "COMMON"
    },
    "592": {
        "name": "Pact Rats #592",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/592.png",
        "dna": "ea22b568f341e62d22e91dfab72381e38bbd6fa6",
        "edition": 592,
        "date": 1665265171016,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.322500000000002,
        "id": "592",
        "rarity_score": 86.42337440852032,
        "rarity_level": "COMMON"
    },
    "593": {
        "name": "Pact Rats #593",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/593.png",
        "dna": "2f5df2120dd690686a5c7db7fa8813e91d20555a",
        "edition": 593,
        "date": 1665265134470,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 9.00875,
        "id": "593",
        "rarity_score": 121.43922905355709,
        "rarity_level": "RARE"
    },
    "594": {
        "name": "Pact Rats #594",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/594.png",
        "dna": "271dd9453d7ba9682e90849cdc57270024482880",
        "edition": 594,
        "date": 1665265054181,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Banana Peel",
                "score": 47.57142857142857
            }
        ],
        "rank": "Epic",
        "rarity": 9.504999999999999,
        "id": "594",
        "rarity_score": 121.2915141490662,
        "rarity_level": "RARE"
    },
    "595": {
        "name": "Pact Rats #595",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/595.png",
        "dna": "ec96bb4afc81038e375ebc54e8d8ed0a6d3228d6",
        "edition": 595,
        "date": 1665265178802,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.030000000000001,
        "id": "595",
        "rarity_score": 104.58480189996509,
        "rarity_level": "COMMON"
    },
    "596": {
        "name": "Pact Rats #596",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/596.png",
        "dna": "a0965c5e276035cf8dfcc392c2ab465a80416d42",
        "edition": 596,
        "date": 1665265031530,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Tank", "score": 20.8125 },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.15625,
        "id": "596",
        "rarity_score": 93.38090886346166,
        "rarity_level": "COMMON"
    },
    "597": {
        "name": "Pact Rats #597",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/597.png",
        "dna": "ed250ec43ef701cb4b121378642eba9afb1ad5b5",
        "edition": 597,
        "date": 1665265171263,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.96125,
        "id": "597",
        "rarity_score": 67.48060798400788,
        "rarity_level": "COMMON"
    },
    "598": {
        "name": "Pact Rats #598",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/598.png",
        "dna": "898a7916e02e8b3f49bbe9ec8fcad794fb30a5ee",
        "edition": 598,
        "date": 1665265037805,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.568749999999998,
        "id": "598",
        "rarity_score": 72.12174075924075,
        "rarity_level": "COMMON"
    },
    "599": {
        "name": "Pact Rats #599",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/599.png",
        "dna": "4e4db8f3350a3434a7f31824cf7964747b407c57",
        "edition": 599,
        "date": 1665265088621,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.846250000000001,
        "id": "599",
        "rarity_score": 84.17165180709702,
        "rarity_level": "COMMON"
    },
    "600": {
        "name": "Pact Rats #600",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/600.png",
        "dna": "63add45fd1824e3f467d6f2a1aa48fdb4bc010e0",
        "edition": 600,
        "date": 1665265082032,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.42625,
        "id": "600",
        "rarity_score": 53.64539196968446,
        "rarity_level": "COMMON"
    },
    "601": {
        "name": "Pact Rats #601",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/601.png",
        "dna": "0f42b137132ac8875628a824f606a35425a9f773",
        "edition": 601,
        "date": 1665265153079,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.759999999999998,
        "id": "601",
        "rarity_score": 78.57363824722069,
        "rarity_level": "COMMON"
    },
    "602": {
        "name": "Pact Rats #602",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/602.png",
        "dna": "b53113be8dde8d96b134ef5d04b81a04f3baf282",
        "edition": 602,
        "date": 1665265022571,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "Slayer Rabbit",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 12.15125,
        "id": "602",
        "rarity_score": 125.79649762468533,
        "rarity_level": "RARE"
    },
    "603": {
        "name": "Pact Rats #603",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/603.png",
        "dna": "5174ea07604585acfe07ea17154134c4449300d0",
        "edition": 603,
        "date": 1665265057742,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.56,
        "id": "603",
        "rarity_score": 80.05894199616496,
        "rarity_level": "COMMON"
    },
    "604": {
        "name": "Pact Rats #604",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/604.png",
        "dna": "65208e73c7444bc31f62e606daadb034a494ffeb",
        "edition": 604,
        "date": 1665265139111,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.868749999999999,
        "id": "604",
        "rarity_score": 65.95482108768688,
        "rarity_level": "COMMON"
    },
    "605": {
        "name": "Pact Rats #605",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/605.png",
        "dna": "0e82c978e458c5055c79219d367fa53971f419bb",
        "edition": 605,
        "date": 1665265033772,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.848749999999999,
        "id": "605",
        "rarity_score": 80.58034465534465,
        "rarity_level": "COMMON"
    },
    "606": {
        "name": "Pact Rats #606",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/606.png",
        "dna": "5c1fd800e46d35deb787fa1003154c74682356dc",
        "edition": 606,
        "date": 1665265046509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            { "trait_type": "Head", "value": "Samurat", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 8.445,
        "id": "606",
        "rarity_score": 133.40864063363978,
        "rarity_level": "RARE"
    },
    "607": {
        "name": "Pact Rats #607",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/607.png",
        "dna": "72c7ea4345c42609f8368075549d0aa8c3c82472",
        "edition": 607,
        "date": 1665265038333,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 10.226249999999999,
        "id": "607",
        "rarity_score": 144.53087645657283,
        "rarity_level": "RARE"
    },
    "608": {
        "name": "Pact Rats #608",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/608.png",
        "dna": "ab7e20b8edfda5a7c2bc9ff99da047d1fe7d1a4f",
        "edition": 608,
        "date": 1665265058231,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Epic",
        "rarity": 7.994999999999999,
        "id": "608",
        "rarity_score": 131.1467839118344,
        "rarity_level": "RARE"
    },
    "609": {
        "name": "Pact Rats #609",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/609.png",
        "dna": "c37cc2ae568d1b8ddbdf72d0a02c0d31455fb0d1",
        "edition": 609,
        "date": 1665265190522,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.963750000000001,
        "id": "609",
        "rarity_score": 96.80869853830382,
        "rarity_level": "COMMON"
    },
    "610": {
        "name": "Pact Rats #610",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/610.png",
        "dna": "5d9013b3f9ef46e013dbe9a2c50eadf7c49be6d1",
        "edition": 610,
        "date": 1665265045783,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 12.338750000000001,
        "id": "610",
        "rarity_score": 122.67062179543939,
        "rarity_level": "RARE"
    },
    "611": {
        "name": "Pact Rats #611",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/611.png",
        "dna": "54c92ff6a5d6b69a3836712508a2302801da6c74",
        "edition": 611,
        "date": 1665265059793,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.3475,
        "id": "611",
        "rarity_score": 74.13597115986734,
        "rarity_level": "COMMON"
    },
    "612": {
        "name": "Pact Rats #612",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/612.png",
        "dna": "c6de66766f150963d8c55aa91e83335fb95edbc2",
        "edition": 612,
        "date": 1665265117170,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.08,
        "id": "612",
        "rarity_score": 73.19528492682203,
        "rarity_level": "COMMON"
    },
    "613": {
        "name": "Pact Rats #613",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/613.png",
        "dna": "86305679f94aa3cf231a6cee79e59558f028b4c1",
        "edition": 613,
        "date": 1665265126164,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 8.60375,
        "id": "613",
        "rarity_score": 117.0045981970239,
        "rarity_level": "RARE"
    },
    "614": {
        "name": "Pact Rats #614",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/614.png",
        "dna": "7ea53d2f33f66d04109c569beebc3d17ba87e882",
        "edition": 614,
        "date": 1665265191559,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Samurai Rat",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "614",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "615": {
        "name": "Pact Rats #615",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/615.png",
        "dna": "000ea255bbf01db09776ab66e10e7e187cdc0277",
        "edition": 615,
        "date": 1665265086325,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.623749999999998,
        "id": "615",
        "rarity_score": 78.65812550678021,
        "rarity_level": "COMMON"
    },
    "616": {
        "name": "Pact Rats #616",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/616.png",
        "dna": "dced11e6faafc3375de892f168711a7ed2fafb39",
        "edition": 616,
        "date": 1665265089886,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 7.657500000000001,
        "id": "616",
        "rarity_score": 137.342698560783,
        "rarity_level": "RARE"
    },
    "617": {
        "name": "Pact Rats #617",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/617.png",
        "dna": "5343fd464f7070fcdafd41e36d465c1731f1b853",
        "edition": 617,
        "date": 1665265138086,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "Spin Hat",
                "score": 51.23076923076923
            }
        ],
        "rank": "Super",
        "rarity": 6.342499999999999,
        "id": "617",
        "rarity_score": 126.36676477837673,
        "rarity_level": "RARE"
    },
    "618": {
        "name": "Pact Rats #618",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/618.png",
        "dna": "5704ef478408435444481de0527bc74e8c4977b0",
        "edition": 618,
        "date": 1665265061058,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "Halo",
                "score": 30.272727272727273
            }
        ],
        "rank": "Epic",
        "rarity": 8.83625,
        "id": "618",
        "rarity_score": 100.25045677123109,
        "rarity_level": "COMMON"
    },
    "619": {
        "name": "Pact Rats #619",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/619.png",
        "dna": "c96512a07c6c6a4d7d31dbac7cebce9ec94237c1",
        "edition": 619,
        "date": 1665265181153,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Captain PACT Sparrow Hat",
                "score": 133.2
            }
        ],
        "rank": "Rare",
        "rarity": 10.701250000000002,
        "id": "619",
        "rarity_score": 227.62512216404886,
        "rarity_level": "LEGENDARY"
    },
    "620": {
        "name": "Pact Rats #620",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/620.png",
        "dna": "ec91f7d29e809bdac67037c5b17130cab8474568",
        "edition": 620,
        "date": 1665265191143,
        "attributes": [
            {
                "trait_type": "Special Edition",
                "value": "Demon",
                "score": 666.0
            }
        ],
        "rank": "Legendary",
        "rarity": 0,
        "id": "620",
        "rarity_score": 666.0,
        "rarity_level": "ONE OF ONE"
    },
    "621": {
        "name": "Pact Rats #621",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/621.png",
        "dna": "9e46b7382ee4e8a44d531911e754420a28a9e935",
        "edition": 621,
        "date": 1665265161514,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.624999999999996,
        "id": "621",
        "rarity_score": 74.18507198922525,
        "rarity_level": "COMMON"
    },
    "622": {
        "name": "Pact Rats #622",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/622.png",
        "dna": "4b34f84c6540990d9cfd243f83dfee7ada92d37a",
        "edition": 622,
        "date": 1665265078697,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            { "trait_type": "Clothes", "value": "Red Shirt", "score": 13.875 },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 7.308749999999998,
        "id": "622",
        "rarity_score": 122.7603996363445,
        "rarity_level": "RARE"
    },
    "623": {
        "name": "Pact Rats #623",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/623.png",
        "dna": "329875ba59759690d3ca49c1df16c4424fea98c6",
        "edition": 623,
        "date": 1665265031029,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.846249999999998,
        "id": "623",
        "rarity_score": 104.17847096203063,
        "rarity_level": "COMMON"
    },
    "624": {
        "name": "Pact Rats #624",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/624.png",
        "dna": "f4260beaafc616455d4b1476258a6d2b4c95d0f3",
        "edition": 624,
        "date": 1665265110509,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Rare",
        "rarity": 12.985,
        "id": "624",
        "rarity_score": 103.78455320564409,
        "rarity_level": "COMMON"
    },
    "625": {
        "name": "Pact Rats #625",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/625.png",
        "dna": "704a7b142fde1149d41782676d0b1abdfb4c0cec",
        "edition": 625,
        "date": 1665265030524,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.27375,
        "id": "625",
        "rarity_score": 79.58729459853261,
        "rarity_level": "COMMON"
    },
    "626": {
        "name": "Pact Rats #626",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/626.png",
        "dna": "8f28147d55e6cad4879e9c34ddb70819fa80ada3",
        "edition": 626,
        "date": 1665265128522,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.997500000000002,
        "id": "626",
        "rarity_score": 70.12338753883432,
        "rarity_level": "COMMON"
    },
    "627": {
        "name": "Pact Rats #627",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/627.png",
        "dna": "fa74568691367adfe185a60459c7171031d0f72b",
        "edition": 627,
        "date": 1665265044793,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Rare",
        "rarity": 11.65375,
        "id": "627",
        "rarity_score": 111.67240657845257,
        "rarity_level": "RARE"
    },
    "628": {
        "name": "Pact Rats #628",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/628.png",
        "dna": "2b7c3f268e03dbe76a8774484e325bcd63b201bd",
        "edition": 628,
        "date": 1665265113578,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "Hawk",
                "score": 35.05263157894737
            }
        ],
        "rank": "Epic",
        "rarity": 9.034999999999998,
        "id": "628",
        "rarity_score": 123.29571167699696,
        "rarity_level": "RARE"
    },
    "629": {
        "name": "Pact Rats #629",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/629.png",
        "dna": "136083e539a40a0561dbd4e2f1feaeee38490f07",
        "edition": 629,
        "date": 1665265134230,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "Rustic Hat",
                "score": 60.54545454545455
            }
        ],
        "rank": "Rare",
        "rarity": 10.092500000000005,
        "id": "629",
        "rarity_score": 129.2172184836748,
        "rarity_level": "RARE"
    },
    "630": {
        "name": "Pact Rats #630",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/630.png",
        "dna": "9e54eb92d080ec5680b94939baf38be9b944212d",
        "edition": 630,
        "date": 1665265027175,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.3125,
        "id": "630",
        "rarity_score": 75.6161260352931,
        "rarity_level": "COMMON"
    },
    "631": {
        "name": "Pact Rats #631",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/631.png",
        "dna": "8765be61a23f166337ab0d520b2c780b2bdd80b3",
        "edition": 631,
        "date": 1665265185600,
        "attributes": [
            { "trait_type": "Background", "value": "Blue", "score": 16.65 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.247500000000002,
        "id": "631",
        "rarity_score": 92.35141004283675,
        "rarity_level": "COMMON"
    },
    "632": {
        "name": "Pact Rats #632",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/632.png",
        "dna": "a703232bb176b220f64ed420ba4d03eb87ed1b2b",
        "edition": 632,
        "date": 1665265129045,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Lightning",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.41375,
        "id": "632",
        "rarity_score": 96.15929273759508,
        "rarity_level": "COMMON"
    },
    "633": {
        "name": "Pact Rats #633",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/633.png",
        "dna": "457b48904ae17f4695f0980e3d466c373696067c",
        "edition": 633,
        "date": 1665265034784,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Yellow Shirt",
                "score": 13.591836734693878
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.567499999999999,
        "id": "633",
        "rarity_score": 77.2724035886666,
        "rarity_level": "COMMON"
    },
    "634": {
        "name": "Pact Rats #634",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/634.png",
        "dna": "50232f603ab89220aca7e39b1b0f129f88266f8c",
        "edition": 634,
        "date": 1665265042707,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.138749999999998,
        "id": "634",
        "rarity_score": 85.70984397233163,
        "rarity_level": "COMMON"
    },
    "635": {
        "name": "Pact Rats #635",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/635.png",
        "dna": "b31ec68807dcfdcffbbddf6f95e5e244c9e2abd4",
        "edition": 635,
        "date": 1665265186371,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Demon Eyes",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.83625,
        "id": "635",
        "rarity_score": 96.23061447069587,
        "rarity_level": "COMMON"
    },
    "636": {
        "name": "Pact Rats #636",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/636.png",
        "dna": "5970c68e56c10a55b5b970f359eb655a6661b797",
        "edition": 636,
        "date": 1665265142497,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "Crown", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 7.395,
        "id": "636",
        "rarity_score": 144.11765208475734,
        "rarity_level": "RARE"
    },
    "637": {
        "name": "Pact Rats #637",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/637.png",
        "dna": "0faaf086709ef141d85a11901ce8e4f5cf7a1d30",
        "edition": 637,
        "date": 1665265160711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Orange",
                "score": 22.96551724137931
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 12.11,
        "id": "637",
        "rarity_score": 109.60870975102816,
        "rarity_level": "COMMON"
    },
    "638": {
        "name": "Pact Rats #638",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/638.png",
        "dna": "0d442793fc98947f1a9cae5ee145b708ef5fedbb",
        "edition": 638,
        "date": 1665265038576,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Sandy",
                "score": 28.956521739130434
            },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Fear Sunglasses",
                "score": 15.857142857142858
            },
            { "trait_type": "Head", "value": "Trippy Mushroom", "score": 44.4 }
        ],
        "rank": "Epic",
        "rarity": 8.9975,
        "id": "638",
        "rarity_score": 132.54309142831053,
        "rarity_level": "RARE"
    },
    "639": {
        "name": "Pact Rats #639",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/639.png",
        "dna": "9bbbd9137bf9d14454b848c700afc6979982e24b",
        "edition": 639,
        "date": 1665265147987,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.075,
        "id": "639",
        "rarity_score": 77.75978227332962,
        "rarity_level": "COMMON"
    },
    "640": {
        "name": "Pact Rats #640",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/640.png",
        "dna": "7489fe4c2d31b20306eea371151eb3f5f62abf3b",
        "edition": 640,
        "date": 1665265147462,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.92875,
        "id": "640",
        "rarity_score": 77.40805301623165,
        "rarity_level": "COMMON"
    },
    "641": {
        "name": "Pact Rats #641",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/641.png",
        "dna": "a559d1de3510ed6900bb4db61aeded14ed5fc6fe",
        "edition": 641,
        "date": 1665265144575,
        "attributes": [
            { "trait_type": "Background", "value": "Space", "score": 20.8125 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Rare",
        "rarity": 11.899999999999999,
        "id": "641",
        "rarity_score": 188.87908266916406,
        "rarity_level": "EPIC"
    },
    "642": {
        "name": "Pact Rats #642",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/642.png",
        "dna": "33c66c48d2449faf648279c4e7fa2f7764f89a06",
        "edition": 642,
        "date": 1665265040906,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Mars",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Fear Shirt",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 14.963750000000001,
        "id": "642",
        "rarity_score": 91.49511706815802,
        "rarity_level": "COMMON"
    },
    "643": {
        "name": "Pact Rats #643",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/643.png",
        "dna": "95a94dcf48b015f3cfbc2eba473efd83d7ebeec9",
        "edition": 643,
        "date": 1665265028203,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Kaddex",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Sweatbands",
                "score": 8.64935064935065
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.594999999999999,
        "id": "643",
        "rarity_score": 86.2669283203851,
        "rarity_level": "COMMON"
    },
    "644": {
        "name": "Pact Rats #644",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/644.png",
        "dna": "89e6dc8dc70bdfe1fcf6fa0b010b15361c89de70",
        "edition": 644,
        "date": 1665265029240,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.2375,
        "id": "644",
        "rarity_score": 70.54421753174717,
        "rarity_level": "COMMON"
    },
    "645": {
        "name": "Pact Rats #645",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/645.png",
        "dna": "8d97df3423a020fe7f98c53bde1c167e397d3fe0",
        "edition": 645,
        "date": 1665265133711,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Samurai ",
                "score": 24.666666666666668
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Captain PACT Sparrow Hat",
                "score": 133.2
            }
        ],
        "rank": "Rare",
        "rarity": 11.235,
        "id": "645",
        "rarity_score": 218.72325902718572,
        "rarity_level": "LEGENDARY"
    },
    "646": {
        "name": "Pact Rats #646",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/646.png",
        "dna": "91d6086f6044e276ecf2fb6223507a87a5fe2013",
        "edition": 646,
        "date": 1665265124318,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Captain PACT Sparrow Compass",
                "score": 18.0
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 11.66375,
        "id": "646",
        "rarity_score": 108.13589971098251,
        "rarity_level": "COMMON"
    },
    "647": {
        "name": "Pact Rats #647",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/647.png",
        "dna": "a0c814e8fb1a8fb6d246cbf028a5a9ed75e098fb",
        "edition": 647,
        "date": 1665265079965,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Green",
                "score": 25.615384615384617
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Stoned Eyes",
                "score": 14.170212765957446
            },
            {
                "trait_type": "Head",
                "value": "KDA Wizzy Hat",
                "score": 47.57142857142857
            }
        ],
        "rank": "Rare",
        "rarity": 11.361249999999998,
        "id": "647",
        "rarity_score": 116.2966657385013,
        "rarity_level": "RARE"
    },
    "648": {
        "name": "Pact Rats #648",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/648.png",
        "dna": "1058a565ca5c66fe98ae48eb97d16e2ef63deeb2",
        "edition": 648,
        "date": 1665265047534,
        "attributes": [
            { "trait_type": "Background", "value": "Purple", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 18.52875,
        "id": "648",
        "rarity_score": 46.962415348710614,
        "rarity_level": "COMMON"
    },
    "649": {
        "name": "Pact Rats #649",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/649.png",
        "dna": "84da425f7afb4217d86944a04e4551279c62fb7c",
        "edition": 649,
        "date": 1665265064303,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Boxing Gloves",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Splinter Beard",
                "score": 9.652173913043478
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Epic",
        "rarity": 9.307500000000001,
        "id": "649",
        "rarity_score": 114.45025214078589,
        "rarity_level": "RARE"
    },
    "650": {
        "name": "Pact Rats #650",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/650.png",
        "dna": "05c27cad4fe0fbe624f67d1297dce53abf5cba72",
        "edition": 650,
        "date": 1665265064069,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            { "trait_type": "Base", "value": "Blue", "score": 11.1 },
            {
                "trait_type": "Clothes",
                "value": "Captain PACT Sparrow",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.657499999999999,
        "id": "650",
        "rarity_score": 132.63346657326284,
        "rarity_level": "RARE"
    },
    "651": {
        "name": "Pact Rats #651",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/651.png",
        "dna": "2d0889540cdc0db9449268578218e6607a516495",
        "edition": 651,
        "date": 1665265088107,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            { "trait_type": "Base", "value": "KDA", "score": 14.8 },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "Green Beret Cap",
                "score": 41.625
            }
        ],
        "rank": "Common",
        "rarity": 13.681249999999999,
        "id": "651",
        "rarity_score": 107.49716464567888,
        "rarity_level": "COMMON"
    },
    "652": {
        "name": "Pact Rats #652",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/652.png",
        "dna": "8390bdae6e2da114361f716970b7cbf95a478d19",
        "edition": 652,
        "date": 1665265072867,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Pink",
                "score": 27.75
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Plain",
                "score": 8.538461538461538
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Grill",
                "score": 15.857142857142858
            },
            { "trait_type": "Eyewear", "value": "Monocle", "score": 22.2 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.40875,
        "id": "652",
        "rarity_score": 91.53908100416241,
        "rarity_level": "COMMON"
    },
    "653": {
        "name": "Pact Rats #653",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/653.png",
        "dna": "f4f17068cc25944909f7aafe164d7716ef87ef94",
        "edition": 653,
        "date": 1665265141689,
        "attributes": [
            { "trait_type": "Background", "value": "Night", "score": 22.2 },
            {
                "trait_type": "Base",
                "value": "White",
                "score": 12.333333333333334
            },
            { "trait_type": "Clothes", "value": "Arkade Tee", "score": 26.64 },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.622499999999999,
        "id": "653",
        "rarity_score": 90.63592783002774,
        "rarity_level": "COMMON"
    },
    "654": {
        "name": "Pact Rats #654",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/654.png",
        "dna": "a81b93e9c3f17aef5276c8d58658415f4c90106b",
        "edition": 654,
        "date": 1665265183750,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "Fear Cigar",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.285,
        "id": "654",
        "rarity_score": 85.78654407652634,
        "rarity_level": "COMMON"
    },
    "655": {
        "name": "Pact Rats #655",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/655.png",
        "dna": "d4995c4c6b41cb39538dd0589006100e5effd2e5",
        "edition": 655,
        "date": 1665265091125,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Platinum Grill",
                "score": 31.714285714285715
            },
            {
                "trait_type": "Eyewear",
                "value": "3D Glasses",
                "score": 20.8125
            },
            { "trait_type": "Head", "value": "Beret PR Cap", "score": 37.0 }
        ],
        "rank": "Rare",
        "rarity": 10.63875,
        "id": "655",
        "rarity_score": 133.0507896652629,
        "rarity_level": "RARE"
    },
    "656": {
        "name": "Pact Rats #656",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/656.png",
        "dna": "b2c4d92a1fbc3dfc2c621d4eabf4ff53c7408df6",
        "edition": 656,
        "date": 1665265184546,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Chainweb",
                "score": 23.785714285714285
            },
            {
                "trait_type": "Base",
                "value": "Brown",
                "score": 3.9880239520958085
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 15.41375,
        "id": "656",
        "rarity_score": 62.01940541640164,
        "rarity_level": "COMMON"
    },
    "657": {
        "name": "Pact Rats #657",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/657.png",
        "dna": "adc8ec250c1cac476a460d554d50375aceca0b70",
        "edition": 657,
        "date": 1665265185859,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Desert",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Gold Tooth",
                "score": 8.430379746835444
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 13.725000000000001,
        "id": "657",
        "rarity_score": 66.02902311590319,
        "rarity_level": "COMMON"
    },
    "658": {
        "name": "Pact Rats #658",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/658.png",
        "dna": "11b70e4e5476e3f38f1b80a2c3f9a5ba0e0a3396",
        "edition": 658,
        "date": 1665265023072,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Bright Purple",
                "score": 30.272727272727273
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Purple Blue Stripes",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "Arkade Gold Chain",
                "score": 9.514285714285714
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Feat Hat", "score": 41.625 }
        ],
        "rank": "Epic",
        "rarity": 9.728750000000002,
        "id": "658",
        "rarity_score": 121.31028715110604,
        "rarity_level": "RARE"
    },
    "659": {
        "name": "Pact Rats #659",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/659.png",
        "dna": "931db6c54269f617d1803b5d5ed0439322591981",
        "edition": 659,
        "date": 1665265031778,
        "attributes": [
            { "trait_type": "Background", "value": "KDA", "score": 18.5 },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Splinter Staff",
                "score": 15.857142857142858
            },
            {
                "trait_type": "Neck",
                "value": "Black Bandana Neck",
                "score": 6.9375
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Laser Eyes",
                "score": 11.482758620689655
            },
            { "trait_type": "Head", "value": "Pirate Hat", "score": 111.0 }
        ],
        "rank": "Epic",
        "rarity": 7.02375,
        "id": "659",
        "rarity_score": 189.81713148466775,
        "rarity_level": "EPIC"
    },
    "660": {
        "name": "Pact Rats #660",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/660.png",
        "dna": "9ba42978a604d37ec0f63056995f8719a48986ca",
        "edition": 660,
        "date": 1665265121033,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Retro",
                "score": 20.181818181818183
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Black Tee",
                "score": 12.10909090909091
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "None",
                "score": 5.946428571428571
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.72375,
        "id": "660",
        "rarity_score": 63.547039413466116,
        "rarity_level": "COMMON"
    },
    "661": {
        "name": "Pact Rats #661",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/661.png",
        "dna": "c1e3cbd2a1b8b7809965f1715979ec5ac790f025",
        "edition": 661,
        "date": 1665265126685,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Light Blue",
                "score": 18.5
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            {
                "trait_type": "Eyewear",
                "value": "Visor",
                "score": 7.085106382978723
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 17.98625,
        "id": "661",
        "rarity_score": 65.46680572770468,
        "rarity_level": "COMMON"
    },
    "662": {
        "name": "Pact Rats #662",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/662.png",
        "dna": "a3c9a2bf56efe4c1bdb0a1709fcc8ea45f1c0c16",
        "edition": 662,
        "date": 1665265188455,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "purple ",
                "score": 35.05263157894737
            },
            {
                "trait_type": "Base",
                "value": "Smokey",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "None",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "Captain PACT Sparrow Beard",
                "score": 11.68421052631579
            },
            { "trait_type": "Eyewear", "value": "Blind Fold", "score": 13.875 },
            { "trait_type": "Head", "value": "KDA_crown", "score": 44.4 }
        ],
        "rank": "Rare",
        "rarity": 12.33875,
        "id": "662",
        "rarity_score": 132.12855356017099,
        "rarity_level": "RARE"
    },
    "663": {
        "name": "Pact Rats #663",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/663.png",
        "dna": "2aaf677cd23aa7de5a4f85f69fd6d7ebaf2fd9d6",
        "edition": 663,
        "date": 1665265165029,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Cream",
                "score": 4.933333333333334
            },
            {
                "trait_type": "Clothes",
                "value": "Orange Shirt",
                "score": 13.875
            },
            {
                "trait_type": "Hands",
                "value": "KDA Asic",
                "score": 9.940298507462687
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            { "trait_type": "Mouth", "value": "Joint", "score": 9.0 },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.24125,
        "id": "663",
        "rarity_score": 66.78413279272368,
        "rarity_level": "COMMON"
    },
    "664": {
        "name": "Pact Rats #664",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/664.png",
        "dna": "f612de72f8694c2a3cf4d9ad7a432f0b8abbd334",
        "edition": 664,
        "date": 1665265098013,
        "attributes": [
            { "trait_type": "Background", "value": "Fear", "score": 18.0 },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            { "trait_type": "Hands", "value": "Samurat Sword", "score": 14.8 },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            {
                "trait_type": "Eyewear",
                "value": "Eye Patch",
                "score": 12.566037735849056
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Common",
        "rarity": 16.055,
        "id": "664",
        "rarity_score": 73.59312779441628,
        "rarity_level": "COMMON"
    },
    "665": {
        "name": "Pact Rats #665",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/665.png",
        "dna": "f988a70b3e5a03d7570f097db655dc6f22613b7e",
        "edition": 665,
        "date": 1665265169973,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Brick Wall",
                "score": 19.58823529411765
            },
            {
                "trait_type": "Base",
                "value": "Green",
                "score": 9.123287671232877
            },
            {
                "trait_type": "Clothes",
                "value": "Red Pink Stripes",
                "score": 11.68421052631579
            },
            {
                "trait_type": "Hands",
                "value": "Fear Swatter",
                "score": 7.318681318681318
            },
            {
                "trait_type": "Neck",
                "value": "Kaddex Gold Chain",
                "score": 7.48314606741573
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar",
                "score": 9.652173913043478
            },
            {
                "trait_type": "Eyewear",
                "value": "Splinter Eyebrows",
                "score": 20.8125
            },
            {
                "trait_type": "Head",
                "value": "None",
                "score": 1.8920454545454546
            }
        ],
        "rank": "Rare",
        "rarity": 12.9575,
        "id": "665",
        "rarity_score": 87.5542802453523,
        "rarity_level": "COMMON"
    },
    "666": {
        "name": "Pact Rats #666",
        "description": "Peter K. Pact and his Pact Rats have overrun the Arkade!",
        "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/pact-rats/666.png",
        "dna": "662435c41876fa608f80c0ce5c1b16738625e160",
        "edition": 666,
        "date": 1665265020976,
        "attributes": [
            {
                "trait_type": "Background",
                "value": "Smokey",
                "score": 19.02857142857143
            },
            {
                "trait_type": "Base",
                "value": "Purple",
                "score": 10.571428571428571
            },
            {
                "trait_type": "Clothes",
                "value": "Kaddy Cheese",
                "score": 15.136363636363637
            },
            {
                "trait_type": "Hands",
                "value": "None",
                "score": 2.986547085201794
            },
            {
                "trait_type": "Neck",
                "value": "None",
                "score": 1.743455497382199
            },
            {
                "trait_type": "Mouth",
                "value": "None",
                "score": 3.784090909090909
            },
            { "trait_type": "Eyewear", "value": "Nerd Glasses", "score": 7.4 },
            { "trait_type": "Head", "value": "Mushroom Hat", "score": 44.4 }
        ],
        "rank": "Common",
        "rarity": 13.294999999999998,
        "id": "666",
        "rarity_score": 105.05045712803853,
        "rarity_level": "COMMON"
    }
}

export default pact_rats_metadata
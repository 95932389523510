import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import CustomPopover from "../CustomPopover";
import AttributesPanel from "./AttributesPanel";

const AttributesSection = () => {
    const pricesData = [
        { id: 1, title: "PRICE", value: "KDA 40" },
        { id: 2, title: "FEES", value: "KDA 1.2 (3%)" },
        { id: 3, title: "ROYALITES", value: "KDA 2 (5%)" },
    ];
    return (
        <Box>
            <Flex align="center" justify="space-between" p="8px">
                <Text fontSize="20px" fontWeight="700" w="100%">
                    Kadcar #22
                </Text>
                <Text flexShrink={0} fontSize="14px" fontWeight={700}>
                    RARITY RANK:{" "}
                    <CustomPopover
                        content={"In the 35th percentile based on rarity"}
                    >
                        <Text as="span" color="#C685E0" cursor="pointer">
                            EPIC
                        </Text>
                    </CustomPopover>
                </Text>
            </Flex>
            <Stack py="8px" spacing="0px">
                {pricesData?.map((item) => {
                    return (
                        <Flex
                            align="center"
                            justify="space-between"
                            px="8px"
                            py="2px"
                            key={item?.id}
                            gap="16px"
                        >
                            <Text fontSize="14px" fontWeight="700" w="100%">
                                {item?.title}
                            </Text>
                            <Text
                                fontSize="14px"
                                fontWeight="700"
                                w="100%"
                                textAlign="right"
                            >
                                {item?.value}
                            </Text>
                        </Flex>
                    );
                })}
            </Stack>
            {/* Total price section */}
            <Flex
                align="flex-end"
                justify="space-between"
                px="8px"
                py="2px"
                gap="16px"
            >
                <Text fontSize="16px" fontWeight="900" flexShrink={0}>
                    TOTAL
                </Text>
                <Text
                    fontSize="28px"
                    fontWeight="700"
                    w="100%"
                    textAlign="right"
                >
                    {" "}
                    KDA 43.2
                </Text>
            </Flex>
            {/* Buy button section */}
            <Button
                _hover={{}}
                _focusVisible={{}}
                mt="28px"
                w="100%"
                h="auto"
                p="8px 20px 8px 20px"
                rounded="8px"
                fontSize="16px"
                bgColor="#C685E0"
                zIndex={"initial"}
            >
                Buy Now
            </Button>
            {/* Attribute data section */}
            <AttributesPanel />
        </Box>
    );
};

export default AttributesSection;

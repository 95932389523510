import { useContext } from "react";
import { toast } from "react-toastify";
import Pact from "pact-lang-api";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { PactContext } from "../pact/PactContextProvider";
import {
    createUniqueTransferCapabilities,
} from "../pact/PactUtils";
import {
    BATTLE_HEROES,
    BATTLE_HEROES_PROJECT_NAME_KEY,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    ISOKO_CONTRACTS,
    ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
    ISOKO_TEST,
    ISOKO_TEST_COLLECTION_NAME_KEY,
    LOCAL_ACCOUNT_KEY,
    MAINNET_NETWORK_ID,
    MARMALADE_TYPE,
    MINT_PHASES_KEY,
    NETWORK_ID,
    SECRET_GARDEN_OF_KADENA,
    SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
    TESTNET_NETWORK_ID,
} from "../utils/Constants";
import { checkIfNullOrUndefined, tryLoadLocal } from "../utils/utils";
import sgk_battle_heroes_metadata from "../project_config_files/sgk_battle_heroes_metadata";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

const BATTLE_HEROES_CONTRACTS =
    "n_7d47538766e6f80008f253dccd30451f4d483c38.sgk-battle-heroes-policy";
const BATTLE_HEROES_TESTNET_CONTRACTS =
    "free.sgk-battle-heros-policy";
const BATTLE_HEROES_CREATOR_WALLET =
    "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46"; //TODO CHANGE THISS
const BATTLE_HEROES_TESTNET_CREATOR_WALLET =
    "k:04e21d9dbe39517574a3772de910d606baa00a84359ba6c468206c76695650d4";
const BATTLE_HEROES_ADMIN_ADDRESS =
    "k:3744b5b5252cf34412854ca03fa5506819db9fa03eca964874f1798ee4aa2d46";
const BATLLE_HEROES_FEE_PERCENTAGE = 0.03;
// const BATTLE_HEROES_COLLECTION_NAME = "sgk-battle-heroes";

const BATTLE_HEROES_COLLECTION_NAME = "sgk-battle-heroes";
const BATTLE_HEROES_TESTNET_COLLECTION_NAME = "sgk-battle-heroes-nft";

function useIsokoTestCollectionProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);


    /**
     * 
     ******************************************
     ********* Marketplace Functions **********
     ******************************************
     *
     */
    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.97 * priceToPay;
        let chainId = getPolicyInfo()["chain-id"];
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;
        let cantMint = await checkIfUserCantMint(pactContext.account.account, amount);
        console.log(totalPrice)
        console.log(priceToPayLPFee);
        console.log(cantMint);
        if (cantMint) {
            return;
        }

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk 
            ${amount} 
            "${BATTLE_HEROES_COLLECTION_NAME}" 
            "${pactContext.account.account}" 
            (read-keyset "user-ks"))`;


        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        const capDataList = [
            {
                role: "Launchpad fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
                amount: { decimal: parseFloat(priceToPayLPFee) }, //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
                // amount: getPactDecimal(parseFloat(priceToPayLPFee)), //TODO CRITICAL: THIS WILL FAIL ON INTEGER: CRITICAL
            },
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver:
                    NETWORK_ID === MAINNET_NETWORK_ID
                        ? BATTLE_HEROES_CREATOR_WALLET
                        : BATTLE_HEROES_TESTNET_CREATOR_WALLET,
                amount: { decimal: parseFloat(priceToPayContract) },
                // amount: getPactDecimal(priceToPayContract),
            },
        ];
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Account Guard",
                "Account Guard Capability",
                `${NETWORK_ID === MAINNET_NETWORK_ID
                    ? BATTLE_HEROES_CONTRACTS
                    : BATTLE_HEROES_TESTNET_CONTRACTS
                }.ACCOUNT-GUARD`,
                [accountDetails.account]
            )
        );

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>
                Recruiting {amount} Gnomes to Explore Kadena's Secret Garden for{" "}
                {parseFloat(totalPrice.toFixed(4))} KDA
            </p>
        );

        //For quickbuy
        let signers = [
            {
                publicKey: accountDetails.accountGuard.keys[0],
                clist: caps.map((cap) => {
                    return cap.cap;
                }),
            },
        ];
        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200
        );
        let bzCommand = JSON.parse(
            Pact.simple.exec.createCommand(
                signers,
                (Date.now() / 1000).toString(),
                pactCode,
                envData,
                meta,
                NETWORK_ID
            ).cmds[0]["cmd"]
        );
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0],
        };

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            previewContent: previewContent,
            transactionMessage: `Recruiting ${amount} Heroes
                to Battle for ${parseFloat(
                totalPrice.toFixed(4)
            )} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            errorMessage: "",
            shouldSign: true,
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    async function mintBulk(amount) {
        let chainId = getPolicyInfo()["chain-id"];
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);

        let pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${BATTLE_HEROES_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${ISOKO_CONTRACTS}.mint-bulk-reserved 100 "${BATTLE_HEROES_TESTNET_COLLECTION_NAME}" "${pactContext.account.account}" (read-keyset "user-ks"))`;
        }

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        let caps = [];
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "reserved",
                "reserved",
                "n_f1c962776331c4773136dc1587a8355c9957eae1.isoko-orchestrator.ADMIN-OR-COL-OWNER",
                [BATTLE_HEROES_COLLECTION_NAME]
            )
        );

        const previewContent = <p>Training {amount} Badger to box for KDA</p>;

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        pactContext.sendTransaction(
            cmd,
            previewContent,
            "",
            null,
            null,
            null,
            true
        );
    }

    /**
     * 
     **************************************************
     ********* Collection Provider Functions **********
     **************************************************
     *
     */

    function getTransactionFee(price) {
        let txFee = parseFloat((price * BATLLE_HEROES_FEE_PERCENTAGE).toFixed(8));
        return txFee;
    }

    function getPolicyInfo() {
        let policy = {
            "standard": MARMALADE_TYPE,
            "project-name": ISOKO_TEST,
            "collection-name": ISOKO_TEST_COLLECTION_NAME_KEY,
            "policy-name": BATTLE_HEROES_CONTRACTS,
            "creator-wallet": BATTLE_HEROES_CREATOR_WALLET,
            "chain-id": "8",
            "nft-metadata": sgk_battle_heroes_metadata
        };

        return policy;
    }

    async function checkIfUserCantMint(account, amount) {
        let cantMint = false;
        let isInFreeMintWindow = getIsInFreeMintWindow();
        let isInPublicWindow = getIsInPublicWindow();
        let accountHasFreeMint = false;

        let accountWlData = await getAccountWhitelistData(account);
        console.log(isInPublicWindow);

        if (!checkIfNullOrUndefined(accountWlData["free-mints-remaining"]["int"])) {
            if (accountWlData["free-mints-remaining"]["int"] > 0) {
                accountHasFreeMint = true;
            }
        } else if (!checkIfNullOrUndefined(accountWlData["free-mints-remaining"])) {
            if (accountWlData["free-mints-remaining"] > 0) {
                accountHasFreeMint = true;
            }
        }

        return false;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA,
            BATTLE_HEROES_PROJECT_NAME_KEY,
            MINT_PHASES_KEY
        );

        return mintPhases;
    }

    function getIsInFreeMintWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();
        console.log(mintPhases);
        if (currentTime > Date.parse(mintPhases["free_mint"]["start_time"]) &&
            currentTime < Date.parse(mintPhases["free_mint"]["end_time"])) {
            return true;
        }
        return false;
    }

    function getIsInPublicWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();
        console.log(mintPhases);
        if (currentTime > Date.parse(mintPhases["public_sale"]["start_time"]) &&
            currentTime < Date.parse(mintPhases["public_sale"]["end_time"])) {
            return true;
        }
        return false;
    }

    async function getNextPrice(amount, accountWlData = null) {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            SECRET_GARDEN_OF_KADENA_PROJECT_NAME_KEY,
            BATTLE_HEROES,
            MINT_PHASES_KEY
        );
        let currentTime = Date.now();
        let freeMintWindow = new Date(
            mintPhases["free_mint"]["start_time"]
        );
        let publicSaleWindow = new Date(
            mintPhases["public_sale"]["start_time"]
        );
        let price = 0.03;
        return price;
    }

    async function getAccountWhitelistData(account) {
        let chainId = getPolicyInfo()["chain-id"];
        let pactCode = `(${BATTLE_HEROES_CONTRACTS}.get-account-whitelist-info "${account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${BATTLE_HEROES_TESTNET_CONTRACTS}.get-account-whitelist-info "${account}")`;
        }

        let response = await queryBlockChainForData(pactCode, chainId);

        return response;
    }

    function getAllNftsMetadata() {
        let fullCollectionMetadata = sgk_battle_heroes_metadata;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            fullCollectionMetadata = sgk_battle_heroes_metadata;
        }

        return fullCollectionMetadata;
    }

    async function getNftsForUser(account) {
        let chainId = getPolicyInfo()["chain-id"];

        let pactCode = `(${BATTLE_HEROES_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        if (NETWORK_ID === TESTNET_NETWORK_ID) {
            pactCode = `(${BATTLE_HEROES_TESTNET_CONTRACTS}.get-nfts-by-owner "${account.account}")`;
        }

        let userNfts = await queryBlockChainForData(pactCode, chainId);
        let formattedUserNftIds = userNfts.map((nft) => {
            let obj = {
                nftId: nft["token-id"].split(":")[1],
            };
            return obj;
        });

        return formattedUserNftIds;
    }

    async function getPhaseParticipationRequirement(account, phase) {
        let requirementDescription = "";
        let whitelistInfo = null;

        if (phase === "public_sale") {
            requirementDescription = "No Special Requirements";
            return requirementDescription;
        } else if (phase === "free_mint") {
            requirementDescription = "Must be Qualified for Free Mint";
        }

        if (!checkIfNullOrUndefined(account)) {
            whitelistInfo = await getAccountWhitelistData(account);
        } else {
            return requirementDescription;
        }

        if (!checkIfNullOrUndefined(whitelistInfo["free-mints-remaining"]["int"])) {
            if (whitelistInfo["free-mints-remaining"]["int"] > 0) {
                requirementDescription = `This Account has ${whitelistInfo["free-mints-remaining"]["int"]} Free Mint(s)!`;
            } else {
                requirementDescription = "This Account has 0 Free Mints";
            }
        } else if (whitelistInfo["free-mints-remaining"] > 0) {
            requirementDescription = `This Account has ${whitelistInfo["free-mints-remaining"]} Free Mint(s)!`;
        } else {
            requirementDescription = "This Account has 0 Free Mints";
        }

        return requirementDescription;
    }

    function getNftImage(nftId) {
        let image = sgk_battle_heroes_metadata[nftId]["uri"]["data"];

        return image;
    }

    async function getMintedTotal() {
        let pactCode = `(${BATTLE_HEROES_CONTRACTS}.get-minted-supply)`;
        let chainId = getPolicyInfo()["chain-id"];
        let mintedTotal = await queryBlockChainForData(pactCode, chainId);

        return mintedTotal;
    }

    return {
        mint,
        mintBulk,
        getNextPrice,
        getPolicyInfo,
        getAllNftsMetadata,
        getNftsForUser,
        getAccountWhitelistData,
        getPhaseParticipationRequirement,
        getNftImage,
        getMintedTotal
    };
}

export { useIsokoTestCollectionProvider };

const projectConfigs = {
    "active_network": "testnet04",
    "projects": {
        "boxing_badger": [
            {
                "has_marketplace": false,
                "has_launchpad": true,
                "path": "boxing_badger",
                "company_name": "boxing_badger",
                "project-name": "Boxing Badger",
                "project_description": "Boxing Badger is aiming to be a fun yet challenging game where you, our fellow badger, challenge others in competitive boxing gameplay.",
                "project_description_summary": "Your NFT is your character in the game. Each NFT is unique and offer some traits that affect your gameplay. For example a skinny badger has lower HP but has increased Dodge and Stamina, while a Muscular badger has increased Strength and Block Rate, but reduced Stamina.",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/kbadger.png",
                "sample_image_assets": [],
                "mint_window_times_utc": [],
                "consumer_roles": [],
                "skill_distribution": {
                    "elemental_damage": { "Elemental Damage": 620, "total": 620 },
                    "physical_resistance": { "Physical Resistance": 652, "total": 652 },
                    "physical_damage": { "Physical Damage": 684, "total": 684 },
                    "elemental_resistance": { "Elemental Resistance": 639, "total": 639 },
                    "dodge_enhancement": { "Dodge Enhancement": 639, "total": 639 },
                    "stamina_regeneration": { "Stamina Regeneration": 652, "total": 652 },
                    "hp_regeneration": { "HP Regeneration": 659, "total": 659 },
                    "speed_enhancement": { "Speed Enhancement": 655, "total": 655 }
                },
                "collection_trait_distribution": {
                    "Background": {
                        "Red Envy": 193,
                        "Blurple": 191,
                        "Smurfin": 173,
                        "Life is Peachy": 179,
                        "Sky Blue": 180,
                        "Honey Badger Yellow": 204,
                        "Kadena Pink": 201,
                        "Kandy Red": 209,
                        "Indigo": 199,
                        "Purple Nurple": 200,
                        "Boxing Ring": 105,
                        "Greenscreen": 184,
                        "Badger Blue": 180,
                        "Mint Madness": 202,
                        "total": 2600
                    },
                    "Body": {
                        "Muscular Gray": 186,
                        "Skinny Gray": 204,
                        "Skinny Beige": 223,
                        "Regular Beige": 186,
                        "Regular Kadena": 23,
                        "Muscular Beige": 183,
                        "Muscular Black": 213,
                        "Regular Brown": 211,
                        "Regular Black": 194,
                        "Skinny Tie Dye": 34,
                        "Skinny Black": 212,
                        "Regular Gray": 198,
                        "Skinny Brown": 225,
                        "Muscular Tie Dye": 38,
                        "Muscular Brown": 180,
                        "Muscular Kadena": 30,
                        "Regular Tie Dye": 37,
                        "Skinny Kadena": 23,
                        "total": 2600
                    },
                    "Face": {
                        "Normal Face Gray": 130,
                        "Normal Face Beige": 127,
                        "Silly Face Beige": 118,
                        "Smiley Face Kadena": 23,
                        "Smiley Face Beige": 122,
                        "Silly Face Gray": 125,
                        "Smiley Face Black": 124,
                        "Smiley Face Brown": 109,
                        "Mean Face Beige": 125,
                        "Tired Face Brown": 147,
                        "Normal Face Black": 134,
                        "Tired Face Tie Dye": 26,
                        "Silly Face Black": 127,
                        "Mean Face Kadena": 15,
                        "Smiley Face Gray": 110,
                        "Mean Face Gray": 109,
                        "Tired Face Gray": 114,
                        "Mean Face Black": 101,
                        "Silly Face Brown": 108,
                        "Normal Face Brown": 137,
                        "Tired Face Beige": 100,
                        "Mean Face Brown": 115,
                        "Silly Face Kadena": 18,
                        "Tired Face Black": 133,
                        "Silly Face Tie Dye": 19,
                        "Mean Face Tie Dye": 22,
                        "Normal Face Kadena": 10,
                        "Smiley Face Tie Dye": 15,
                        "Normal Face Tie Dye": 27,
                        "Tired Face Kadena": 10,
                        "total": 2600
                    },
                    "Boxing Gloves": {
                        "Teal Gloves": 290,
                        "Red Gloves": 269,
                        "Purple Gloves": 283,
                        "Blue Gloves": 296,
                        "Yellow Gloves": 313,
                        "Punishing Poison Gloves": 96,
                        "Frozen Heart Gloves": 102,
                        "Magma Gloves": 114,
                        "Orange Gloves": 273,
                        "Shock Therapy Gloves": 99,
                        "Pink Gloves": 264,
                        "Max Pain Gloves": 80,
                        "Honey Gloves": 121,
                        "total": 2600
                    },
                    "Headwear": {
                        "Kadena White Cap": 74,
                        "Beanie": 583,
                        "Emerald Crown": 61,
                        "Kadena Helmet": 25,
                        "Halo": 65,
                        "Ecko Dex Cap": 83,
                        "Tophat": 57,
                        "Red Bandana": 22,
                        "Kadena Kandy Cap": 27,
                        "Kaptain Kadena Hat": 18,
                        "Rabbit Ears": 9,
                        "Ecko Helmet": 5,
                        "Red Helmet": 1,
                        "total": 1030
                    },
                    "Necklace": {
                        "Ecko Bling": 178,
                        "Kadena Bling": 177,
                        "Bitcoin Bling": 171,
                        "total": 526
                    },
                    "Glasses": {
                        "Cool Bro Shades": 59,
                        "Aviators": 68,
                        "Hip Pink Shades": 58,
                        "3D Glasses": 59,
                        "Technicolor Shades": 61,
                        "Hip Yellow Shades": 62,
                        "Hip White Shades": 65,
                        "Kadena Shades": 73,
                        "total": 505
                    },
                    "Nose Ring": { "Nose Ring": 303, "total": 303 },
                    "Tattoos": { "Dark Tattoos": 308, "Light Tattoos": 186, "total": 494 },
                    "Sweater": { "Black Sweater": 417, "White Sweater": 123, "total": 540 },
                    "Earring": { "Earring": 304, "total": 304 },
                    "Cigarette": { "Cigarette": 56, "total": 56 }
                },
                "socials": {
                    "twitter": "https://twitter.com/BoxingBadgerNFT",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.com/invite/HckmvdxP5F",
                    "website": "https://boxingbadger.com/"
                },
                "mainnet_config": {
                    "mint_start_time": "2023-04-22T16:00:00Z",
                    "mint_end_time": "2028-04-23T16:00:00Z",
                    "mint_amount_limit": 25,
                    "nft_supply": 2600,
                    "chain_id": "8",
                    "mint_phases": {
                        "private_wl": {
                            "phase_name": "PRIVATE SALE",
                            "start_time": "2023-04-22T16:00:00Z",
                            "end_time": "2023-04-23T15:59:59Z",
                            "base_price": 12.5
                        },
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2023-04-23T16:00:00Z",
                            "end_time": "2028-04-23T16:00:00Z",
                            "base_price": 17.5
                        }
                    },
                    "collection_skills": [
                        'Elemental Damage',
                        'Physical Resistance',
                        'Physical Damage',
                        'Elemental Resistance',
                        'Dodge Enhancement',
                        'Stamina Regeneration',
                        'HP Regeneration',
                        'Speed Enhancement'
                    ],
                    "collection_traits": [
                        'Background',
                        'Body',
                        'Face',
                        'Boxing Gloves',
                        'Headwear',
                        'Necklace',
                        'Glasses',
                        'Nose Ring',
                        'Tattoos',
                        'Sweater',
                        'Earring',
                        'Cigarette'
                    ]
                },
                "testnet_config": {
                    "mint_start_time": "2023-04-22T03:00:00Z",
                    "mint_end_time": "2023-05-16T00:00:00.000Z",
                    "mint_amount_limit": 25,
                    "nft_supply": 2600,
                    "chain_id": "1",
                    "mint_phases": {
                        "private_wl": {
                            "phase_name": "PRIVATE SALE",
                            "start_time": "2023-04-22T16:00:00Z",
                            "end_time": "2023-04-23T07:00:00Z",
                            "base_price": 1.0
                        },
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2022-04-23T16:00:00Z",
                            "end_time": "2030-04-23T16:00:00Z",
                            "base_price": 1.5
                        }
                    },
                    "collection_skills": [
                        'Elemental Damage',
                        'Physical Resistance',
                        'Physical Damage',
                        'Elemental Resistance',
                        'Dodge Enhancement',
                        'Stamina Regeneration',
                        'HP Regeneration',
                        'Speed Enhancement'
                    ],
                    "collection_traits": [
                        'Background',
                        'Body',
                        'Face',
                        'Boxing Gloves',
                        'Headwear',
                        'Necklace',
                        'Glasses',
                        'Nose Ring',
                        'Tattoos',
                        'Sweater',
                        'Earring',
                        'Cigarette'
                    ]
                }
            }
        ],
        "islands_of_damaya": [
            {
                "has_marketplace": false,
                "has_launchpad": true,
                "path": "pit_bull_bushido",
                "company_name": "islands_of_damaya",
                "project-name": "Pit Bull Bushidō",
                "project_description": "Experience the 7 virtues of the samurai",
                "project_description_summary": "Pit Bull Bushidō is the first collection in the Islands of Dāmaya series, featuring a unique set of 47 Samurai-inspired NFTs. Each NFT in the collection showcases a different character, each with their own name and attributes. Collectors who manage to collect all 47 Samurai will receive special edition character cards in real life, as well as a beta test of the upcoming Islands of Dāmaya card game. This collection is a must-have for fans of Pit Bulls and Samurai culture alike, and offers a thrilling opportunity for collectors to dive deeper into the world of Dāmaya.",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/IOD.png",
                "sample_image_assets": [],
                "mint_window_times_utc": [],
                "consumer_roles": [],
                "collection_trait_distribution": {
                    "Virtue": {
                        "Compassion": 329,
                        "Integrity": 329,
                        "Honor": 329,
                        "Sincerity": 329,
                        "Loyalty": 235,
                        "Heroic Courage": 329,
                        "Respect": 282,
                        "Repect": 47,
                        "total": 2209
                    },
                    "Title": {
                        "Daimyo": 564,
                        "Ronin": 376,
                        "Samurai": 1222,
                        "Shogun": 47,
                        "total": 2209
                    },
                    "Name": {
                        "Shimazu": 47,
                        "Masakatsu": 47,
                        "Naosmasa": 47,
                        "Sanada": 47,
                        "Yamagata & Kenji": 47,
                        "Hattori": 47,
                        "Ashikaga": 47,
                        "Yagyu": 47,
                        "Kiyotsuna & Anjin": 47,
                        "Watanabe": 47,
                        "Asakura": 47,
                        "Akechi": 47,
                        "Nobunaga": 47,
                        "Esumi": 47,
                        "Mitsuhide": 47,
                        "Nagahide": 47,
                        "Yasuke": 47,
                        "Masamune": 47,
                        "Kusunoki": 47,
                        "Hachiro": 47,
                        "Rinoie": 47,
                        "Naru": 47,
                        "Minoru": 47,
                        "Saigo": 47,
                        "Oda": 47,
                        "Kenshin": 47,
                        "Miyamoto": 47,
                        "Eto": 47,
                        "Akao": 47,
                        "Hanzo": 47,
                        "Terukage": 47,
                        "Tomoe": 47,
                        "Tsunari": 47,
                        "Akio": 47,
                        "Minokan": 47,
                        "Miura": 47,
                        "Imagawa": 47,
                        "Masashige": 47,
                        "Munenori": 47,
                        "Moriyama": 47,
                        "Tokugawa": 47,
                        "Takeda": 47,
                        "Hideyoshi": 47,
                        "Tadahisa": 47,
                        "Musashi": 47,
                        "Ieyasu": 47,
                        "Toyotomi": 47,
                        "total": 2209
                    },
                    "Outfit": {
                        "O-Yoroi": 282,
                        "Nagagi": 235,
                        "Hakama": 611,
                        "Haori": 329,
                        "Do-Maru": 376,
                        "Jinbaori": 376,
                        "total": 2209
                    },
                    "Headgear": {
                        "None": 1786,
                        "Roningasa": 47,
                        "Benkan": 47,
                        "Kabuto Helmet": 94,
                        "Mengu Mask": 94,
                        "Sandogasa": 94,
                        "Face Paint": 47,
                        "total": 2209
                    },
                    "Primary Weapon": {
                        "None": 188,
                        "Katana": 1034,
                        "Dual Naginata": 94,
                        "Daisho": 517,
                        "Cane Sword": 47,
                        "Nodachi": 141,
                        "Naginata": 47,
                        "Tachi": 94,
                        "Muramasa Sword": 47,
                        "total": 2209
                    },
                    "Secondary Weapon": {
                        "None": 987,
                        "Tanto": 470,
                        "Daisho": 470,
                        "Wakizashi": 188,
                        "Te-Yari": 94,
                        "total": 2209
                    },
                    "Accessory": {
                        "None": 1739,
                        "Puppy Power": 94,
                        "Gunbai": 94,
                        "Yumi Bow": 47,
                        "Fan": 47,
                        "Scroll": 94,
                        "Tekko": 47,
                        "Arrow": 47,
                        "total": 2209
                    },
                    "Background": {
                        "Sunrise Over The Field": 47,
                        "Poppy Nightscape": 47,
                        "The Dojo": 47,
                        "Sakura Village": 47,
                        "Bad Side Of Town": 47,
                        "Valley Village": 47,
                        "Sakura Bridge": 141,
                        "Fishing Village": 94,
                        "Sakura River": 47,
                        "Wildflower Night": 47,
                        "Mountain Village": 47,
                        "Sakura Castle": 141,
                        "Nightime Docks": 47,
                        "Sakura Tera": 47,
                        "Mountain River": 47,
                        "West End Village": 47,
                        "Momiji Castle": 47,
                        "Throne Room": 94,
                        "Throne Room Encounter": 47,
                        "Rice Field Sunset": 47,
                        "Tide Rolling In": 47,
                        "Sakura Courtyard": 141,
                        "Fishing Cove": 47,
                        "East End Village": 47,
                        "Courtyard Fountain": 47,
                        "Mountain Night": 47,
                        "Courtyard": 47,
                        "Moonlight Field": 47,
                        "River Banks": 47,
                        "Cove": 47,
                        "Battlefield": 47,
                        "Mosochiku Forest": 94,
                        "Mountain Lake": 47,
                        "Village Streets": 47,
                        "Field Nightscape": 47,
                        "Foggy Mountain": 47,
                        "Starry Starry Night": 47,
                        "Field": 47,
                        "total": 2209
                    },
                    "Character": {
                        "#35": 47,
                        "#6": 47,
                        "#26": 47,
                        "#33": 47,
                        "#5": 47,
                        "#25": 47,
                        "#39": 47,
                        "#27": 47,
                        "#43": 47,
                        "#41": 47,
                        "#1": 47,
                        "#46": 47,
                        "#21": 47,
                        "#19": 47,
                        "#34": 47,
                        "#40": 47,
                        "#14": 47,
                        "#8": 47,
                        "#18": 47,
                        "#15": 47,
                        "#7": 47,
                        "#24": 47,
                        "#38": 47,
                        "#30": 47,
                        "#23": 47,
                        "#10": 47,
                        "#3": 47,
                        "#42": 47,
                        "#22": 47,
                        "#44": 47,
                        "#2": 47,
                        "#13": 47,
                        "#28": 47,
                        "#32": 47,
                        "#36": 47,
                        "#29": 47,
                        "#37": 47,
                        "#17": 47,
                        "#11": 47,
                        "#20": 47,
                        "#16": 47,
                        "#4": 47,
                        "#9": 47,
                        "#47": 47,
                        "#45": 47,
                        "#31": 47,
                        "#12": 47,
                        "total": 2209
                    }
                },
                "socials": {
                    "twitter": "https://twitter.com/PitBullBushido",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/vSqVN3TfxU",
                    "website": "https://www.islandsofdamaya.com/"
                },
                "mainnet_config": {
                    "mint_start_time": "2023-05-01T18:00:00Z",
                    "mint_end_time": "2025-01-01T00:00:00Z",
                    "mint_amount_limit": 25,
                    "nft_supply": 2209,
                    "chain_id": "8",
                    "nft_price_thresholds": {

                    },
                    "mint_phases": {
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2023-05-01T18:00:00Z",
                            "end_time": "2030-04-23T16:00:00Z",
                            "base_price": 7
                        }
                    },
                    "collection_traits": [
                        'Virtue',
                        'Title',
                        'Name',
                        'Outfit',
                        'Headgear',
                        'Primary Weapon',
                        'Secondary Weapon',
                        'Accessory',
                        'Background',
                        'Character'
                    ]
                },
                "testnet_config": {
                    "mint_start_time": "2023-03-01T00:00:00.000Z",
                    "mint_end_time": "2025-01-01T00:00:00.000Z",
                    "mint_amount_limit": 25,
                    "nft_supply": 2209,
                    "chain_id": "1",
                    "nft_price_thresholds": {

                    },
                    "mint_phases": {
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2022-04-23T16:00:00Z",
                            "end_time": "2030-04-23T16:00:00Z",
                            "base_price": 0.7
                        }
                    },
                    "collection_traits": [
                        'Virtue',
                        'Title',
                        'Name',
                        'Outfit',
                        'Headgear',
                        'Primary Weapon',
                        'Secondary Weapon',
                        'Accessory',
                        'Background',
                        'Character'
                    ]
                }
            }
        ],
        "secret_garden_of_kadena": [
            {
                "has_marketplace": false,
                "has_launchpad": true,
                "path": "secret_garden_of_kadena",
                "company_name": "secret_garden_of_kadena",
                "project-name": "Gen 1",
                "project_description_summary": "The Secret Garden of Kadena is hidden deep in a lost cave. The gnomes discovered this mystical place and have devoted their lives to protecting it. For their good deeds, the garden has accepted them, but the gnomes know it’s only a matter of time before they have to defend it. SGK is a multi-game and NFT project building on Kadena and running on FLUX. SGK is building incredibly fun games from the most popular gaming genres. Free to play and P2E!",
                "project_description": "",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/sgkgen1.jpg",
                "sample_image_assets": [],
                "mint_window_times_utc": [],
                "consumer_roles": [],
                "socials": {
                    "twitter": "https://twitter.com/kdasecretgarden",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/KMkEsTcbaG",
                    "website": "https://kadenasecretgarden.com/"
                },
                "collection_trait_distribution": {
                    "Background": {
                        "14": 136,
                        "17": 158,
                        "13": 115,
                        "3": 127,
                        "5": 119,
                        "10": 125,
                        "7": 130,
                        "9": 146,
                        "16": 126,
                        "2": 128,
                        "30": 113,
                        "1": 171,
                        "21": 126,
                        "22": 136,
                        "28": 131,
                        "15": 116,
                        "25": 140,
                        "24": 163,
                        "18": 122,
                        "4": 123,
                        "32": 122,
                        "26": 133,
                        "19": 105,
                        "20": 119,
                        "8": 141,
                        "29": 118,
                        "31": 154,
                        "12": 120,
                        "23": 102,
                        "6": 126,
                        "11": 100,
                        "27": 109,
                        "total": 4100
                    },
                    "Body": {
                        "Medium Fist": 670,
                        "Light Fist": 620,
                        "Light Hand": 591,
                        "Silver Hand": 434,
                        "Medium Hand": 565,
                        "Blue Fist": 115,
                        "Blue Hand": 132,
                        "Dark Hand": 310,
                        "Silver Fist": 379,
                        "Dark Fist": 279,
                        "Grey Fist": 1,
                        "Light Hand (Prosthetic)": 1,
                        "Grey Hand": 1,
                        "Gold Fist": 1,
                        "Gold Hand": 1,
                        "total": 4100
                    },
                    "Mouth": {
                        "Mouth 3": 727,
                        "Diamond Grill": 301,
                        "Gold Grill": 459,
                        "Rainbow Grill": 489,
                        "Mouth 2": 725,
                        "Mouth 1": 735,
                        "Stitched Mouth": 251,
                        "total": 3687
                    },
                    "Eyes": {
                        "Blue Eyes": 806,
                        "Red Eyes": 776,
                        "Purple Eyes": 770,
                        "Green Eyes": 775,
                        "Yellow Eyes": 779,
                        "Black_Red Eyes": 193,
                        "Gem Eyes": 1,
                        "total": 4100
                    },
                    "Outfit": {
                        "Kadena 3": 237,
                        "Kadena 6": 124,
                        "Eye": 41,
                        "Bearish": 217,
                        "Gems": 153,
                        "Platemail": 251,
                        "SGK 1": 82,
                        "Tools": 170,
                        "Gnomevania": 127,
                        "Kadena 1": 258,
                        "Kadena 4": 252,
                        "SGK 4": 205,
                        "Dark Magic": 83,
                        "Tribal": 193,
                        "Trinkets": 86,
                        "Pauldron": 236,
                        "Bullish": 270,
                        "Kadena 2": 236,
                        "ISOKO": 56,
                        "SGK 3": 182,
                        "SGK Tattoo": 23,
                        "Devil": 102,
                        "Leather": 260,
                        "K2 Tree": 17,
                        "Roots": 70,
                        "Kadena Tattoo": 124,
                        "Kadena 5": 1,
                        "SGK Spyder": 42,
                        "RedDead Outfit": 1,
                        "Treasure Hunter": 1,
                        "total": 4100
                    },
                    "Medallion": {
                        "KOR": 236,
                        "Babena": 297,
                        "Stake Factory": 239,
                        "MOK": 269,
                        "KPN": 264,
                        "Koala": 301,
                        "KMC": 247,
                        "Alpha Slayers": 286,
                        "Kitty Kad": 303,
                        "Swarms": 247,
                        "Zelcore": 209,
                        "Peace": 73,
                        "WizardsArena": 290,
                        "Flux": 140,
                        "&": 81,
                        "Arkade": 253,
                        "Ecko": 239,
                        "Kadcars": 50,
                        "Yin Yang": 74,
                        "Lotus Flower": 1,
                        "total": 4099
                    },
                    "Beard": {
                        "Triple Grey": 182,
                        "Triple Black": 280,
                        "Triple Brown": 261,
                        "Full Green": 262,
                        "Triple Red": 245,
                        "Braids Black": 144,
                        "Tassel Brown": 187,
                        "Full Black": 260,
                        "Full Blue with Cigar": 112,
                        "Cannabis Beard": 84,
                        "Gem Beard": 122,
                        "Tassel Black": 163,
                        "Full Red": 251,
                        "Tassel Blue": 165,
                        "Triple Green": 246,
                        "Tassel Red": 190,
                        "Full Grey": 187,
                        "Mutton Chops": 94,
                        "Tassel Green": 177,
                        "Mystical Stones": 32,
                        "French Beard": 1,
                        "total": 3645
                    },
                    "Accessory": {
                        "Blue Goggles": 474,
                        "Green Goggles": 520,
                        "Monocle": 674,
                        "Jeweler Glasses": 465,
                        "Nerd Glass": 513,
                        "Round Shades": 224,
                        "SGK Eyepatch": 227,
                        "Wayfarer": 152,
                        "Aviator Glasses": 168,
                        "total": 3417
                    },
                    "Cover": {
                        "Beer Can Hat": 195,
                        "English Hat": 207,
                        "Mohawk": 205,
                        "VR": 107,
                        "Man Bun": 210,
                        "Loki": 110,
                        "Raiden": 80,
                        "Black Ponytail": 251,
                        "Miner Helmet": 221,
                        "Brown Top Hat": 90,
                        "Tiki": 200,
                        "Gas Mask": 173,
                        "S Fighter": 277,
                        "Night Vision": 160,
                        "Viking": 123,
                        "Brown Cowboy": 222,
                        "Brown Ponytail": 235,
                        "Metal Top Hat": 136,
                        "Alpha Waves": 40,
                        "WW1 Helmet": 121,
                        "Dragon Bone Hat": 80,
                        "Grey Ponytail": 241,
                        "Ancient Crown": 50,
                        "Aviator": 176,
                        "Rasta": 68,
                        "Assassin Hood": 80,
                        "Black Top Hat": 39,
                        "V Mask": 1,
                        "CoD": 1,
                        "total": 4099
                    },
                    "Gauntlet": {
                        "Bullish Tattoo": 163,
                        "Plated Gauntlet": 350,
                        "Royal Gauntlet": 402,
                        "Jackhammer": 369,
                        "Buckles Gauntlet": 382,
                        "Ancient Gauntlet": 81,
                        "Stones of Power": 52,
                        "Leather Gauntlet": 362,
                        "Mining Drill": 82,
                        "Electro Gauntlet": 346,
                        "Assassin Gauntlet": 358,
                        "TSAO Flux": 38,
                        "Secret Garden Flowers": 358,
                        "Blood Metal": 117,
                        "Death Viper": 80,
                        "Cyborg": 121,
                        "Chainweb Tattoo": 78,
                        "Gauntlet of Light": 115,
                        "W Claws": 80,
                        "TSAO Kadena": 39,
                        "Kadena Tattoo": 85,
                        "SGK shield": 39,
                        "Z Shield": 1,
                        "GnomeLast": 1,
                        "total": 4099
                    },
                    "Closed Left Hand": {
                        "NConsole": 178,
                        "Wrench": 340,
                        "Mining Pick": 318,
                        "AConsole": 131,
                        "PConsole": 214,
                        "Lantern": 314,
                        "NController": 166,
                        "AJoystick": 125,
                        "Pcontroller": 205,
                        "C4": 73,
                        "BTC": 1,
                        "total": 2065
                    },
                    "Open Left Hand": {
                        "Crystal Ball": 270,
                        "Kitty Kad": 228,
                        "Gem": 235,
                        "Mecha Owl": 25,
                        "Gaia": 74,
                        "Telescope": 107,
                        "Blow Torch": 310,
                        "Triple Pipe": 61,
                        "Triple Bowl": 251,
                        "Bud": 98,
                        "Garden Secrets Scroll": 42,
                        "Fireball": 59,
                        "Flower of Life": 99,
                        "Ledger": 85,
                        "KDA Moon": 33,
                        "Miner": 32,
                        "Mecha Dragon": 24,
                        "Yin Yang": 1,
                        "Kadena": 1,
                        "total": 2035
                    },
                    "Special": { "Prosthetic": 1, "Mechanical Claw": 1, "total": 2 }
                },
                "mainnet_config": {
                    "mint_start_time": "2023-05-13T16:30:00Z",
                    "mint_end_time": "2025-01-01T00:00:00.000Z",
                    "mint_amount_limit": 25,
                    "nft_supply": 4100,
                    "chain_id": "8",
                    "nft_price_thresholds": {

                    },
                    "mint_phases": {
                        "og_sale": {
                            "phase_name": "OG SALE",
                            "start_time": "2023-05-13T16:30:00Z",
                            "end_time": "2023-05-14T16:29:00Z",
                            "base_price": 7
                        },
                        "private_wl": {
                            "phase_name": "PRIVATE SALE",
                            "start_time": "2023-05-14T16:30:00Z",
                            "end_time": "2023-05-15T16:29:00Z",
                            "base_price": 7
                        },
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2023-05-15T16:30:00Z",
                            "end_time": "2030-05-23T16:00:00Z",
                            "base_price": 7
                        }
                    },
                    "collection_traits": [
                        'Background',
                        'Body',
                        'Mouth',
                        'Eyes',
                        'Outfit',
                        'Medallion',
                        'Beard',
                        'Accessory',
                        'Cover',
                        'Gauntlet',
                        'Closed Left Hand',
                        'Open Left Hand',
                        'Special'
                    ]
                },
                "testnet_config": {
                    "mint_start_time": "2023-04-20T03:31:00.000Z",
                    "mint_end_time": "2025-01-01T00:00:00.000Z",
                    "mint_amount_limit": 10,
                    "nft_supply": 4100,
                    "chain_id": "1",
                    "nft_price_thresholds": {

                    },
                    "mint_phases": {
                        "og_sale": {
                            "phase_name": "OG SALE",
                            "start_time": "2023-05-10T18:00:00Z",
                            "end_time": "2023-05-10T19:00:00Z",
                            "base_price": 7
                        },
                        "private_wl": {
                            "phase_name": "PRIVATE SALE",
                            "start_time": "2023-05-11T19:00:00Z",
                            "end_time": "2023-04-23T16:00:00Z",
                            "base_price": 7
                        },
                        "public_sale": {
                            "phase_name": "PUBLIC SALE",
                            "start_time": "2023-05-01T18:00:00Z",
                            "end_time": "2030-04-23T16:00:00Z",
                            "base_price": 7
                        }
                    },
                    "collection_traits": [
                        'Background',
                        'Body',
                        'Mouth',
                        'Eyes',
                        'Outfit',
                        'Medallion',
                        'Beard',
                        'Accessory',
                        'Cover',
                        'Gauntlet',
                        'Closed Left Hand',
                        'Open Left Hand',
                        'Special'
                    ]
                }
            },
            {
                "has_marketplace": true,
                "has_launchpad": false,
                "path": "secret_garden_of_kadena_gen_0",
                "company_name": "",
                "project-name": "Secret Garden of Kadena Gen 0",
                "project_description_summary": "Gnomes discover new technology",
                "project_description": "Gnomes discover new technology",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/gnome.png",
                "base_url": "https://arkade-prod.s3.us-east-1.amazonaws.com/secret-garden-of-kadena/",
                "socials": {
                    "twitter": "https://twitter.com/kdasecretgarden",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/KMkEsTcbaG",
                    "website": "https://kadenasecretgarden.com/"
                },
                "collection_trait_distribution": {
                    "Background": {
                        "Yellow": 104,
                        "Orange SGK": 10,
                        "Green": 99,
                        "Grey": 77,
                        "Light Blue": 102,
                        "Red": 94,
                        "Coral": 90,
                        "Deep Purple": 100,
                        "Light Purple": 90,
                        "Light Purple SGK": 12,
                        "Pink": 98,
                        "Orange": 106,
                        "Red SGK": 8,
                        "Grey SGK": 11,
                        "Coral SGK": 13,
                        "Pink SGK": 8,
                        "Green SGK": 7,
                        "Light Blue SGK": 7,
                        "Yellow SGK": 11,
                        "Deep Purple SGK": 3,
                        "total": 1050
                    },
                    "Base": { "Base": 1050, "total": 1050 },
                    "Outfit": {
                        "Blue": 186,
                        "Purple": 194,
                        "Orange": 314,
                        "Pink": 250,
                        "Green": 58,
                        "Yellow": 48,
                        "total": 1050
                    },
                    "Eyes": {
                        "Pink Eyes": 116,
                        "Yellow Eyes": 126,
                        "Orange Eyes": 133,
                        "RedvEyes": 149,
                        "Purple Eyes": 125,
                        "Rainbow Sunglasses": 39,
                        "Brown Sunglasses": 63,
                        "Silver Sunglasses": 46,
                        "Green Eyes": 115,
                        "Blood Eyes": 1,
                        "Ski Goggles": 69,
                        "Jason Mask": 4,
                        "Square Sunglasses": 32,
                        "Wayfarer Sunglasses": 13,
                        "Kadena Eyepatch": 19,
                        "total": 1050
                    },
                    "Necklace": {
                        "None": 275,
                        "Gold Chain": 172,
                        "Medallion": 150,
                        "Flower Lei": 155,
                        "Relic": 149,
                        "KLogo": 34,
                        "Ankh": 62,
                        "Kadena Chain": 49,
                        "Gold Ankh": 4,
                        "total": 1050
                    },
                    "Beard": {
                        "Pink Regular": 94,
                        "Orange Long Stache": 56,
                        "Braided Gotee": 8,
                        "Green Braided": 53,
                        "Pink Long Stache": 53,
                        "Purple Long Stache": 52,
                        "Green Regular": 124,
                        "Purple Regular": 120,
                        "Grey Curly Stache": 11,
                        "Pink Curly Stache": 42,
                        "Tie Dye": 52,
                        "Orange Braided": 40,
                        "Green Long Stache": 49,
                        "Purple Curly Stache": 58,
                        "Pink Braided": 48,
                        "Orange Curly Stache": 55,
                        "Green Curly Stache": 43,
                        "Grey Braided": 4,
                        "Grey Regular": 11,
                        "Purple Braided": 45,
                        "Peach Regular": 6,
                        "Blue Regular": 2,
                        "Grey Long Stache": 5,
                        "Goatee": 7,
                        "Teal Regular": 6,
                        "Light Blue Regular": 4,
                        "Blonde Regular": 2,
                        "total": 1050
                    },
                    "Cover": {
                        "Blue Curved": 90,
                        "Green Straight": 65,
                        "Baby Blue": 46,
                        "Green Curved": 88,
                        "Red Straight": 39,
                        "Purple Curved": 79,
                        "Pink Curved": 58,
                        "Orange Curved": 93,
                        "Red Curved": 92,
                        "Light Pink Straight": 33,
                        "Blue": 52,
                        "Yellow Curved": 85,
                        "Yellow Straight": 52,
                        "Purple Straight": 46,
                        "Light Purple Straight": 62,
                        "Hanging Spider": 13,
                        "Orange Straight": 35,
                        "TheCryptoSpyder": 10,
                        "Top Hat": 5,
                        "Mushroom": 3,
                        "Brown With Grass": 2,
                        "Green With Grass": 2,
                        "total": 1050
                    },
                    "Mouth": {
                        "Bubble Gum": 193,
                        "Old Pipe": 261,
                        "Gold Teeth": 253,
                        "Pre Roll": 207,
                        "Rainbow Teeth": 91,
                        "Dynamite": 45,
                        "total": 1050
                    },
                    "Belt": {
                        "Purple Mushroom": 127,
                        "Orange Mushroom": 147,
                        "Red Mushroom": 134,
                        "Green Mushroom": 151,
                        "Belt Buckle": 197,
                        "Pink Mushroom": 139,
                        "Kadena": 24,
                        "White Flower": 42,
                        "Pink Flower": 52,
                        "Alien": 37,
                        "total": 1050
                    },
                    "Accessory": {
                        "Kadena": 110,
                        "Butterfly2 Pink": 142,
                        "Butterfly2 Red": 152,
                        "Butterfly2 Blue": 179,
                        "Miner Light": 141,
                        "Butterfly1 Blue": 45,
                        "Caterpillar": 65,
                        "Butterfly1 Yellow": 54,
                        "Headphones": 57,
                        "Ladybug": 27,
                        "Butterfly1 Pink": 46,
                        "C4 Logo New": 5,
                        "Lizard": 26,
                        "Eye": 1,
                        "total": 1050
                    },
                    "Right Hand": {
                        "Staff": 49,
                        "Shovel": 107,
                        "Purple Hand Mushroom": 91,
                        "SGK Shield": 43,
                        "Red Heart": 26,
                        "Watering Can": 73,
                        "Green Hand Mushroom": 79,
                        "Axe Double Gold": 4,
                        "Kadena Flag": 96,
                        "Pink Hand Mushroom": 84,
                        "Orange Hand Mushroom": 92,
                        "Axe Blue": 41,
                        "Pickaxe": 73,
                        "Red Hand Mushroom": 97,
                        "Sceptre": 32,
                        "Axe Dual Gold": 4,
                        "Axe Silver": 4,
                        "Axe Black": 7,
                        "Wand": 2,
                        "Wooden Shield": 43,
                        "Axe Single": 1,
                        "Axe Single Gold": 2,
                        "total": 1050
                    }
                },
                "mainnet_config": {
                    "nft_supply": 1050,
                    "chain_id": "1",
                    "collection_traits": [
                        "Background",
                        "Base",
                        "Outfit",
                        "Eyes",
                        "Necklace",
                        "Beard",
                        "Cover",
                        "Mouth",
                        "Belt",
                        "Accessory",
                        "Right Hand"
                    ]
                },
                "testnet_config": {
                    "nft_supply": 1050,
                    "chain_id": "1",
                    "collection_traits": [
                        "Background",
                        "Base",
                        "Outfit",
                        "Eyes",
                        "Necklace",
                        "Beard",
                        "Cover",
                        "Mouth",
                        "Belt",
                        "Accessory",
                        "Right Hand"
                    ]
                },
            }
        ],

        "kadcars_nft": [
            {
                "has_marketplace": false,
                "has_launchpad": true,
                "path": "kadcars_nft",
                "company_name": "kadcars_nft",
                "project-name": "Kadcars NFT",
                "project_description_summary": "GameFi project where NFT owners can race and upgrade their cars",
                "project_description": "GameFi project where NFT owners can race and upgrade their cars",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/kc.webp",
                "sample_image_assets": [],
                "mint_window_times_utc": [],
                "consumer_roles": [],
                "socials": {
                    "twitter": "https://twitter.com/kadcarsnft",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/67gxU6nWWu",
                    "website": "https://kadcarsnft.app.runonflux.io/"
                },
                "mainnet_config": {
                    "mint_start_time": "2025-05-21T00:00:00.000Z",
                    "mint_end_time": "2026-01-01T00:00:00.000Z",
                    "mint_amount_limit": 5,
                    "nft_supply": 5337,
                    "chain_id": "13",
                    "nft_price_thresholds": {

                    }
                },
                "testnet_config": {
                    "mint_start_time": "2025-05-08T00:00:00.000Z",
                    "mint_end_time": "2026-01-01T00:00:00.000Z",
                    "mint_amount_limit": 5,
                    "nft_supply": 5337,
                    "chain_id": "1",
                    "nft_price_thresholds": {

                    }
                }
            }
        ],


        "kitty_kad_nfts": [
            {
                "has_marketplace": false,
                "has_launchpad": false,
                "path": "kitty_kad_nfts",
                "company_name": "Kitty Kads",
                "project-name": "Kitty Kad NFTs",
                "project_description_summary": "Kitty Kads are NFT kitties you can play with.",
                "project_description": "Each one being a one of a kind NFT you can collect, trade and breed. You can read their origin story here. Oh, did we mention that they also provide purrks for future Kitty Kad games and dapps?",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/kittykad.png",
                "base_url": "https://dc7daf10.kitty-images-static.pages.dev/",
                "socials": {
                    "twitter": "https://twitter.com/KittyKadToken",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/ac5QDrYmhq",
                    "website": "https://kitties.kittykad.com/"
                },
                "mainnet_config": {
                    "nft_supply": 10000,
                    "chain_id": "8"
                },
                "testnet_config": {
                    "nft_supply": 10000,
                    "chain_id": "1"
                }
            }
        ],
        "arkade_bulls": [
            {
                "has_marketplace": true,
                "has_launchpad": false,
                "path": "arkade_90s_bulls",
                "company_name": "Arkade Bulls",
                "project-name": "Arkade 90s Bulls",
                "project_description_summary": "Groovy bulls rocking all the styles and themes of the 1990s.",
                "project_description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
                "banner_asset": "",
                "logo_asset": "",
                "collection_thumbnail_image": "/images/project_collection_thumbnails/arkade.png",
                "base_url": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/",
                "socials": {
                    "twitter": "https://twitter.com/kdasecretgarden",
                    "facebook": "",
                    "instagram": "",
                    "medium": "",
                    "discord": "https://discord.gg/KMkEsTcbaG",
                    "website": "https://kadenasecretgarden.com/"
                },
                "collection_trait_distribution": {
                    "Background": {
                        "Blue": 270,
                        "Red": 86,
                        "Blue Gradient": 90,
                        "Green": 281,
                        "Pink": 181,
                        "Orange": 295,
                        "Yellow": 218,
                        "Pink Gradient": 87,
                        "Purple": 186,
                        "Black Gradient": 76,
                        "Purple Gradient": 104,
                        "Green Gradient": 99,
                        "total": 1973
                    },
                    "Back": {
                        "NONE": 1530,
                        "Sword And Shield": 82,
                        "Ninja Dual Swords": 89,
                        "Ninja Nunchucks": 77,
                        "Slayer Sword": 35,
                        "Ninja Staff": 65,
                        "Ninja Knife": 70,
                        "total": 1948
                    },
                    "Base": {
                        "Bronze Angry": 55,
                        "Bronze Evil": 51,
                        "Bronze Smile": 83,
                        "Bronze Happy": 64,
                        "Bronze Sad": 31,
                        "Bronze Tongue Out": 16,
                        "Silver Angry": 36,
                        "Silver Sad": 38,
                        "Silver Happy": 49,
                        "Silver Tongue Out": 12,
                        "Silver Smile": 47,
                        "Silver Evil": 18,
                        "Gold Happy": 31,
                        "Gold Angry": 25,
                        "Gold Sad": 14,
                        "Gold Smile": 49,
                        "Gold Tongue Out": 10,
                        "Gold Evil": 21,
                        "Diamond Sad": 16,
                        "Diamond Smile": 34,
                        "Diamond Evil": 20,
                        "Diamond Angry": 9,
                        "Diamond Tongue Out": 7,
                        "Diamond Happy": 14,
                        "Superstar Evil": 4,
                        "Superstar Smile": 15,
                        "Superstar Happy": 16,
                        "Superstar Angry": 8,
                        "Superstar Sad": 5,
                        "Superstar Tongue Out": 2,
                        "Black Sad": 23,
                        "Black Smile": 58,
                        "Black Mustache": 19,
                        "Black Happy": 39,
                        "Black Evil": 26,
                        "Black Angry": 23,
                        "Black Tongue Out": 12,
                        "Brown Happy": 34,
                        "Brown Angry": 24,
                        "Brown Sad": 27,
                        "Brown Smile": 56,
                        "Brown Tongue Out": 17,
                        "Brown Mustache": 11,
                        "Brown Evil": 31,
                        "Gray Happy": 53,
                        "Gray Tongue Out": 18,
                        "Gray Smile": 39,
                        "Gray Sad": 28,
                        "Gray Evil": 37,
                        "Gray Angry": 25,
                        "Green Angry": 15,
                        "Green Sad": 14,
                        "Green Smile": 28,
                        "Green Evil": 12,
                        "Green Tongue Out": 12,
                        "Green Happy": 19,
                        "Red Evil": 12,
                        "Red Angry": 22,
                        "Red Sad": 13,
                        "Red Tongue Out": 3,
                        "Purple Happy": 27,
                        "Purple Smile": 23,
                        "Purple Tongue Out": 8,
                        "Purple Evil": 21,
                        "Purple Mustache": 1,
                        "Purple Sad": 15,
                        "Purple Angry": 5,
                        "Ghost Angry": 3,
                        "Ghost Sad": 5,
                        "Ghost Smile": 9,
                        "Ghost Happy": 5,
                        "Ghost Tongue Out": 2,
                        "Ghost Evil": 1,
                        "Light Brown Tongue Out": 30,
                        "Light Brown Sad": 56,
                        "Light Brown Happy": 72,
                        "Light Brown Mustache": 24,
                        "Light Brown Evil": 51,
                        "Light Brown Angry": 57,
                        "Smile": 8,
                        "total": 1973
                    },
                    "Eyes": {
                        "Left Brown Eyes": 57,
                        "Forward Brown Eyes": 466,
                        "Forward Green Eyes": 392,
                        "Forward Blue Eyes": 404,
                        "Left Blue Eyes": 54,
                        "Right Blue Eyes": 42,
                        "Down Green Eyes": 16,
                        "Forward Red Eyes": 162,
                        "Up Brown Eyes": 15,
                        "Right Red Eyes": 57,
                        "Left Red Eyes": 39,
                        "Right Green Eyes": 52,
                        "Up Blue Eyes": 15,
                        "Down Red Eyes": 19,
                        "Up Red Eyes": 16,
                        "Left Green Eyes": 50,
                        "Right Brown Eyes": 56,
                        "Down Brown Eyes": 12,
                        "Up Green Eyes": 11,
                        "Down Blue Eyes": 13,
                        "total": 1948
                    },
                    "Outfit": {
                        "NONE": 163,
                        "Tough Turtle": 7,
                        "Beige Bull Tee": 35,
                        "Dad Bod": 38,
                        "Habibi Vest": 16,
                        "Superbull": 11,
                        "Great Burger Uniform": 30,
                        "Jean Jacket": 53,
                        "Spiderbull": 29,
                        "Red Guardian Suit": 12,
                        "Ten Piece": 12,
                        "Kool Tee 1": 43,
                        "Kaid Muscle Tee": 46,
                        "Sweater Vest": 38,
                        "Green Guardian Suit": 13,
                        "KDSwap": 40,
                        "Kadena Buddies": 55,
                        "Red Pool": 31,
                        "Quickster Suit": 24,
                        "Sailor Mouse": 30,
                        "K Planet": 51,
                        "Arkade": 62,
                        "Blue Not Smart Suit": 26,
                        "Blue Tunic": 24,
                        "Orange Not Smart Suit": 8,
                        "Letterman Jacket": 47,
                        "X Wallet": 26,
                        "Hoodie": 34,
                        "Kold": 6,
                        "Green Tunic": 13,
                        "Termination Leather Jacket": 21,
                        "Vanta Space Suit": 13,
                        "Fresh King Shirt": 29,
                        "Detective Suit": 28,
                        "Yellow Tee": 20,
                        "Red 33": 22,
                        "Tuxedo": 17,
                        "Plaid Cube": 39,
                        "Turbo Bull": 17,
                        "Lucky Jacket": 20,
                        "Robe": 10,
                        "Block Bulls": 34,
                        "Rocket Squad": 11,
                        "Space Robe": 27,
                        "Black Suit": 33,
                        "Gold Miner Tee": 39,
                        "The Stone": 25,
                        "Kirvana Tee": 42,
                        "Party Tee": 6,
                        "Kosmos": 38,
                        "Blue Plaid": 21,
                        "Hedgehog": 23,
                        "Captain Sustainable": 29,
                        "90s Vest": 13,
                        "Sailor": 22,
                        "Dragon Fighter": 21,
                        "Pact Rats": 35,
                        "Nerd": 29,
                        "Red Tunic": 3,
                        "Adventurer": 18,
                        "Koala Wallet": 25,
                        "Spinner": 5,
                        "Blue Guardian Suit": 11,
                        "Jokester": 15,
                        "Yellow Guardian Suit": 13,
                        "Moon Astronaut": 25,
                        "Wallaby Tee": 23,
                        "Toy Cowboy": 16,
                        "Space Army": 29,
                        "Kool Tee 2": 23,
                        "Luiki": 6,
                        "Pink Guardian Suit": 10,
                        "Bullverine": 7,
                        "Moon Walker": 11,
                        "Black Guardian Suit": 10,
                        "Red Stripes": 2,
                        "Pickels Overalls": 3,
                        "Hey": 10,
                        "Black Tee": 1,
                        "total": 1973
                    },
                    "Mask": {
                        "NONE": 416,
                        "Red Guardian Mask": 7,
                        "Punk Helmet": 22,
                        "Ninja Mask Purple": 20,
                        "Spiderbull": 18,
                        "Ninja Mask Red": 11,
                        "Blue Guardian Mask": 3,
                        "Jokester Mouth": 12,
                        "Punk Helmet 2": 12,
                        "The Closer Face": 11,
                        "Racer Helmet": 19,
                        "Ninja Mask Orange": 12,
                        "Hedgehog Mask": 15,
                        "Dark Invader Mask": 7,
                        "Ninja Mask Blue": 16,
                        "Bullverine Mask": 18,
                        "Green Guardian Mask": 4,
                        "Quickster Mask": 16,
                        "Scar": 12,
                        "Yellow Guardian Mask": 4,
                        "Turbobull": 5,
                        "MF Doom Mask": 11,
                        "Kajoras Mask": 21,
                        "Black Guardian Mask": 1,
                        "Deadbull Mask": 6,
                        "Krooper Helmet": 7,
                        "Robot Helmet": 2,
                        "Pink Guardian Helmet": 2,
                        "total": 710
                    },
                    "Accessory": {
                        "NONE": 1689,
                        "Koala": 115,
                        "Kittygotchi": 119,
                        "Wizard Staff": 29,
                        "Light Saber": 17,
                        "Gold Chain": 4,
                        "total": 1973
                    },
                    "Overlay": { "NONE": 1964, "UFO": 3, "Game Screen": 6, "total": 1973 },
                    "Head": {
                        "Green Tunic Hat": 14,
                        "Space Ranger Hat": 16,
                        "Mohawk": 14,
                        "Snapback Backwards Hat": 37,
                        "Employee Hat Black": 17,
                        "Tall Fro": 21,
                        "Walkman": 33,
                        "NONE": 288,
                        "Fictional Fro": 34,
                        "Arkade Hat": 31,
                        "90s Hat 3": 48,
                        "90s Hat 2": 31,
                        "Party Hat": 6,
                        "Abducted Hat": 17,
                        "PACt Bandana": 29,
                        "Skottie Hair": 9,
                        "Spinner Hat": 19,
                        "Short Hair": 26,
                        "Friday Hat": 30,
                        "90s Hat 1": 51,
                        "Superbull Hair": 5,
                        "North Park Beanie": 14,
                        "Employee Hat Red": 15,
                        "Thriller Hair": 20,
                        "Girl Mouse Ears": 20,
                        "Yuki Muko Hair": 5,
                        "Habibi Hat": 9,
                        "Frosted Tips": 25,
                        "Good Burger Hat": 17,
                        "Ten Piece Hat": 10,
                        "Blue Not Smart Hat": 16,
                        "Diggers Hat": 18,
                        "Dennis Hair": 12,
                        "Billy the Bull Hat": 32,
                        "Wayne Head": 13,
                        "Captain Sustainable Hair": 13,
                        "Blue Tunic Hat": 14,
                        "Karth Hair": 17,
                        "KDSwap": 16,
                        "Football Head": 5,
                        "Forrest Bulls Hat": 16,
                        "Blonde Hair": 16,
                        "Red Tunic Hat": 17,
                        "Kace Adventure Hair": 6,
                        "Halo": 16,
                        "Luiki Hat": 5,
                        "Okko Hair": 5,
                        "Wooden Cowboy Hat": 8,
                        "Mouse Ears": 2,
                        "Brown Hair": 6,
                        "Banana Peel": 3,
                        "Fictional Long Hair": 30,
                        "Kaldo Hat": 13,
                        "Kyle Hat": 6,
                        "Koku Hair": 7,
                        "Orange Not Smart Hat": 20,
                        "Kaddex Hat": 10,
                        "Rocket Hair": 8,
                        "Pickles Hair": 2,
                        "total": 1263
                    },
                    "Glasses": {
                        "Peace Glasses": 47,
                        "NONE": 573,
                        "Nose Ring": 86,
                        "Auto Glasses": 105,
                        "Circle Glasses": 85,
                        "Scavenger Glasses": 46,
                        "White 3D Glasses": 43,
                        "Shoobie Glasses": 46,
                        "Karth Glasses": 106,
                        "Termination Glasses": 37,
                        "Baby Glasses": 40,
                        "Vintage Steve Glasses": 49,
                        "total": 1263
                    },
                    "Special Edition": {
                        "Notorious KDA": 1,
                        "Firebreather": 1,
                        "Spiderbull": 1,
                        "Founder Bull Kadi": 1,
                        "Sphynxxx": 1,
                        "Wizard": 1,
                        "Santa": 1,
                        "Kong": 1,
                        "Founder Bull Norrizzy": 1,
                        "Arkade": 1,
                        "Super Kario": 1,
                        "Red Pill": 1,
                        "Krazy": 1,
                        "Fry Kook": 1,
                        "Founder Bull Sn0": 1,
                        "Slayer": 1,
                        "Purple Dragon": 1,
                        "total": 17
                    }
                },
                "mainnet_config": {
                    "nft_supply": 1990,
                    "chain_id": "1",
                    "collection_traits": [
                        "Background",
                        "Back",
                        "Base",
                        "Eyes",
                        "Outfit",
                        "Head",
                        "Glasses",
                        "Accessory",
                        "Overlay"
                    ]
                },
                "testnet_config": {
                    "nft_supply": 1990,
                    "chain_id": "1",
                    "collection_traits": [
                        "Background",
                        "Back",
                        "Base",
                        "Eyes",
                        "Outfit",
                        "Head",
                        "Glasses",
                        "Accessory",
                        "Overlay"
                    ]
                },
            },
        ],
    }
}

export {
    projectConfigs
}
import localforage from "localforage";
import { fetchAccountDetailsWithChainId } from "../pact/PactUtils";
import { LOCAL_ACCOUNT_KEY, QUICKBUY_PROVIDER_TX_FEE } from "../utils/Constants";
import { checkIfNullOrUndefined, tryLoadLocal } from "../utils/utils";

function roundDown(value, digitsAfterDecimal) {
    let finalValue = null;
    let splitTokens = value.toString().split(".");
    console.log(splitTokens);

    if (splitTokens.length === 1) {
        return value;
    }

    if (splitTokens[1].length > digitsAfterDecimal) {
        console.log("TOKEN LENGTH: " + splitTokens[1].length);
        splitTokens[1] = splitTokens[1].slice(0, digitsAfterDecimal);
    }

    finalValue = parseFloat(`${splitTokens[0]}.${splitTokens[1]}`);

    return finalValue;
}

async function getChainsWithFundsToTransfer(targetChainId, priceToPay) {
    let chainMap = [
        { chain: "0", accountData: {} },
        { chain: "1", accountData: {} },
        { chain: "2", accountData: {} },
        { chain: "3", accountData: {} },
        { chain: "4", accountData: {} },
        { chain: "5", accountData: {} },
        { chain: "6", accountData: {} },
        { chain: "7", accountData: {} },
        { chain: "8", accountData: {} },
        { chain: "9", accountData: {} },
        { chain: "10", accountData: {} },
        { chain: "11", accountData: {} },
        { chain: "12", accountData: {} },
        { chain: "13", accountData: {} },
        { chain: "14", accountData: {} },
        { chain: "15", accountData: {} },
        { chain: "16", accountData: {} },
        { chain: "17", accountData: {} },
        { chain: "18", accountData: {} },
        { chain: "19", accountData: {} },
    ];

    let chainBalanceList = tryLoadLocal(LOCAL_ACCOUNT_KEY).chainMap;
    if (checkIfNullOrUndefined(chainBalanceList)) {
        chainBalanceList = chainMap;
    }

    let chainAmtMap = {};
    let quickBuyProviderFee = QUICKBUY_PROVIDER_TX_FEE;
    let totalSum = roundDown(
        chainBalanceList[parseInt(targetChainId)].accountData.balance,
        8
    );
    let chainsWithEnoughFundsList = [];
    // let finalPrice = parseFloat((quickBuyProviderFee + priceToPay).toFixed(8));
    let finalPrice = quickBuyProviderFee + priceToPay;

    console.log("HOW MUCH IS LEFT:  " + (finalPrice - totalSum));
    for (let i = 0; i < chainBalanceList.length; i++) {
        console.log("--------------------START --------------------");
        if (chainBalanceList[i].chain === targetChainId) {
            continue;
        }

        let roundedDownBalance = roundDown(
            chainBalanceList[i].accountData.balance,
            8
        );
        console.log("roundedDownBalance:  " + roundedDownBalance);

        if (totalSum + roundedDownBalance >= finalPrice) {
            let difference = finalPrice - totalSum;
            console.log("Difference: " + difference);

            let roundedUpDiff = parseFloat(difference.toFixed(8));
            console.log("roundedUpDiff: " + roundedUpDiff);

            if (roundedUpDiff < difference) {
                console.log("less than diff");
                roundedUpDiff += 0.00000001;
            }
            if (roundedUpDiff > roundedDownBalance) {
                console.log("greater than chain");
                totalSum += roundedDownBalance;

                console.log("total sum" + totalSum);
                chainAmtMap[chainBalanceList[i].chain] = roundedDownBalance;

                continue;
            }
            totalSum += roundedUpDiff;
            console.log("total sum w rounded up diff" + totalSum);

            // chainAmtMap[chainBalanceList[i].chain] = parseFloat((difference).toFixed(8));
            chainAmtMap[chainBalanceList[i].chain] = roundedUpDiff;
            break;
        }

        if (roundedDownBalance > 0.0) {
            totalSum += roundedDownBalance;
            chainsWithEnoughFundsList.push(chainBalanceList[i].chain);

            // chainAmtMap[chainBalanceList[i].chain] = parseFloat((chainBalanceList[i].accountData.balance).toFixed(8));
            chainAmtMap[chainBalanceList[i].chain] = roundedDownBalance;
        }
    }

    if (totalSum < finalPrice) {
        console.log("TOTALZZ: " + totalSum);
        return null;
    }
    console.log(chainAmtMap);
    console.log("total: " + totalSum);
    console.log("final: " + finalPrice);
    return chainAmtMap;
}

async function getBalanceForAllChains(account, localStorageKey) {
    let chainMap = [];
    for (let i = 0; i < 20; i++) {
        chainMap[i] = { chain: i.toString(), accountData: {} };
    }

    let defaultData = {
        guard: {
            pred: "keys-all",
            keys: [],
        },
        balance: 0.0,
        account: account,
    };

    let balanceData = {
        chainMap: chainMap,
        account: account,
        accountGuard: null,
    };

    await Promise.allSettled(
        chainMap.map((entry, index) => {
            return getBalanceForChain(account, index.toString());
        })
    )
        .then((results) => {
            results.map((result, index) => {
                if (result.status === "fulfilled") {
                    let balance = 0.0;
                    if (result.value) {
                        if (result.value.balance.decimal) {
                            result.value.balance = parseFloat(
                                result.value.balance.decimal
                            );
                        }
                        //TODO: THIS ISTENTATIVE
                        if (result.value.balance < 0.00000001) {
                            chainMap[index].accountData = defaultData;
                            if (result.value.balance > 0) {
                                balanceData["accountGuard"] =
                                    result.value.guard;
                            }
                        } else {
                            chainMap[index].accountData = result.value;
                            if (result.value.balance > 0) {
                                balanceData["accountGuard"] =
                                    result.value.guard;
                            }
                        }
                        balanceData["chainMap"] = chainMap;
                    } else {
                        chainMap[index].accountData = defaultData;
                    }
                } else {
                    //TODO: handle rejection her
                    chainMap[index].accountData = defaultData;
                    console.log(chainMap[index].accountData);
                }
            });
        })
        .then(async () => {
            chainMap["account"] = account;
            await localforage.setItem(localStorageKey, chainMap);
        })
        .catch((e) => {
            console.log(e);
            balanceData = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        });

    return balanceData;
}

async function getBalanceForChain(account, targetChain) {
    let meta = {
        account: account,
        chainId: targetChain,
        gasLimit: 150000,
        gasPrice: 1e-5,
    };

    let details = await fetchAccountDetailsWithChainId(meta);

    return details;
}

export {
    getChainsWithFundsToTransfer,
    getBalanceForChain,
    getBalanceForAllChains,
};

import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { CartModeContext } from "../Context/CartContext";
import { HiChevronLeft, HiShoppingCart } from "react-icons/hi2";

// called from MarketplaceCollection component line 168
const CartSummary = () => {
    const { isCartActive, showCart, ToggleShowCart } =
        useContext(CartModeContext);

    return (
        <Flex
            display={isCartActive && showCart !== true ? "block" : "none"}
            position="fixed"
            right={"0px"}
            top="50%"
            transform={"auto"}
            translateY={"-50%"}
            height="154px"
            bgColor={"#BC3CF0"}
            borderLeftRadius={"8px"}
            flexDir={"column"}
            justify={"center"}
            align={"center"}
            py="12px"
            textAlign={"center"}
            w="58px"
        >
            {/* Cart Section */}
            <Box px="11px">
                <Flex
                    align={"center"}
                    justify={"space-between"}
                    cursor={"pointer"}
                    onClick={ToggleShowCart}
                >
                    <Icon as={HiChevronLeft} />
                    <Icon as={HiShoppingCart} />
                </Flex>
                <Divider
                    mt="4px"
                    bgColor={"#9D33C8"}
                    borderColor="#9D33C8"
                // h="1px"
                />
            </Box>
            {/* Items */}
            <Flex flexDir={"column"} gap="18px" pt="12px">
                <Box>
                    <Text fontWeight={"700"} fontSize={"12px"}>
                        Items
                    </Text>
                    <Text fontWeight={"900"}> 3 </Text>
                </Box>

                <Box>
                    <Text fontWeight={"700"} fontSize={"12px"}>
                        Price
                    </Text>
                    <Text fontWeight={"900"} fontSize="14px">
                        172
                        <Text as="span" fontWeight={"400"} fontSize={"12px"}>
                            {" "}
                            KDA
                        </Text>
                    </Text>
                </Box>
            </Flex>

            {/* Price */}
        </Flex>
    );
};

export default CartSummary;

import { queryBlockchainForData, useQueryBlockchainForData } from "../pact/ContractHooks";
import {
    ARKADE_80S_BULLS_PROJECT_NAME_KEY,
    ARKADE_90S_BULLS_PROJECT_NAME_KEY,
    ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY,
    ARKADE_BULLS,
    FEE_PERCENTAGE,
    KADENA_SKELLIES,
    KADENA_SKELLIES_PROJECT_NAME_KEY,
    KISHU_KEN,
    KISHU_KEN_PROJECT_NAME_KEY,
    LOCAL_ACCOUNT_KEY,
    PACT_RATS,
    PACT_RATS_PROJECT_NAME_KEY,
} from "../utils/Constants";
import { transformNftDataToIsokoStandard } from "../utils/transformers";
import { checkIfNullOrUndefined, getAmountByPercentage, tryLoadLocal } from "../utils/utils";
import { PactContext } from "../pact/PactContextProvider";
import { useContext } from "react";
import arkade_80s_bulls_metadata from "../project_config_files/arkade_80s_bulls_metadata";
import arkade_90s_bulls_metadata from "../project_config_files/arkade_90s_bulls_metadata";
import pact_rats_metadata from "../project_config_files/pact_rats_metadata";
import arkade_brawler_bears_metadata from "../project_config_files/arkade_brawler_bears_metadata";
import kadena_skellies_metadata from "../project_config_files/kadena_skellies_metadata";
import { getCollectionNftsMetadata } from "./NftMetadataProvider";
import kishu_ken_metadata from "../project_config_files/kishu_ken_metadata";

function getCollectionNftsManifestsMap(policyInfo) {
    if (policyInfo["project-name"] === KADENA_SKELLIES) {
        if (policyInfo["collection-name"] === KADENA_SKELLIES_PROJECT_NAME_KEY) {
            return kadena_skellies_metadata;
        }
    } else if (policyInfo["project-name"] === ARKADE_BULLS) {
        if (policyInfo["collection-name"] === ARKADE_80S_BULLS_PROJECT_NAME_KEY) {
            return arkade_80s_bulls_metadata;
        } else if (policyInfo["collection-name"] === ARKADE_90S_BULLS_PROJECT_NAME_KEY) {
            return arkade_90s_bulls_metadata;
        } else if (policyInfo["collection-name"] === ARKADE_BRAWLER_BEARS_PROJECT_NAME_KEY) {
            return arkade_brawler_bears_metadata;
        }
    } else if (policyInfo["project-name"] === KADENA_SKELLIES) {
        if (policyInfo["collection-name"] === KADENA_SKELLIES_PROJECT_NAME_KEY) {
            return kadena_skellies_metadata;
        }
    } else if (policyInfo["project-name"] === PACT_RATS) {
        if (policyInfo["collection-name"] === PACT_RATS_PROJECT_NAME_KEY) {
            return pact_rats_metadata
        }
    } else if (policyInfo["project-name"] === KISHU_KEN) {
        if (policyInfo["collection-name"] === KISHU_KEN_PROJECT_NAME_KEY) {
            return kishu_ken_metadata
        }
    }
}

async function getIdsOwnedBuy(pactContext, policyInfo) {
    let pactCode = `(${policyInfo["policy-name"]}.ids-owned-by "${pactContext.account.account}")`;
    let results = await queryBlockchainForData(pactContext, pactCode, policyInfo["chain-id"]);
    return results;
}

async function getNftFieldsForIds(pactContext, policyInfo, fieldsList, nftIds) {
    let pactCode = "";
    if (nftIds instanceof Array) {
        pactCode = `(${policyInfo["policy-name"]}.get-nft-fields-for-ids [${fieldsList}] [${nftIds}])`;
    } else {
        pactCode = `(${policyInfo["policy-name"]}.get-nft-fields-for-id [${fieldsList}] "${nftIds}")`;
    }

    let blockchainResponse = await queryBlockchainForData(pactContext, pactCode, policyInfo["chain-id"]);
    let nftFields = {};
    // console.log(blockchainResponse)
    if (blockchainResponse !== null) {
        Object.entries(blockchainResponse).map(([field, value]) => {
            nftFields[field] = value;

            if (field === "price") {
                // console.log(value)
                if (value.decimal) {
                    nftFields[field] = parseFloat(value.decimal);
                }
            }
        })
    } else {
        return null;
    }

    return nftFields;
}

async function getMarketplaceFieldsForIds(policyInfo, fieldsList, nftIds, pactContext) {
    let pactCode = "";
    if (nftIds instanceof Array) {
        pactCode = `(${policyInfo["policy-name"]}.get-marketplace-fields-for-ids [${fieldsList}] [${nftIds}])`;
    } else {
        pactCode = `(${policyInfo["policy-name"]}.get-marketplace-fields-for-id [${fieldsList}] "${nftIds}")`;
    }

    let blockchainResponse = await queryBlockchainForData(pactContext, pactCode, policyInfo["chain-id"]);
    let nftFields = {};

    if (blockchainResponse !== null) {
        Object.entries(blockchainResponse).map(([field, value]) => {
            nftFields[field] = value;

            if (field === "price") {
                // console.log(value)
                if (value.decimal) {
                    nftFields[field] = parseFloat(value.decimal);
                }
            }
        })
    } else {
        return null;
    }

    return nftFields;
}

async function getUserNftsInStandardFormat(policyInfo, pactContext) {
    let allNfts = getCollectionNftsManifestsMap(policyInfo);
    let nftsOnSale = await getAllOnSale(policyInfo, pactContext);
    let results = [];

    if (nftsOnSale) {
        //Get ids
        let nftIds = nftsOnSale.map((nft) => {
            return `"${nft.id}"`;
            // return nft.id;
        });
        let fieldList = [`"owner"`, `"price"`];

        let nftMarketData = await getMarketplaceFieldsForIds(policyInfo, fieldList, nftIds, pactContext);
        nftMarketData = Object.values(nftMarketData)

        let userNftMap = nftMarketData.filter((nft) => {
            return nft["owner"] === pactContext.account.account;
        });

        for (const nftData of userNftMap) {
            let nftDataShallowCopy = { ...allNfts[nftData["id"]] };
            nftDataShallowCopy["owner"] = nftData["owner"];
            nftDataShallowCopy["price"] = nftData["price"];

            results.push(transformNftDataToIsokoStandard(policyInfo, nftDataShallowCopy));
        }
    }

    return results;
}

async function getAllOnSale(policyInfo, pactContext) {
    let pactCode = `(${policyInfo["policy-name"]}.get-all-on-sale)`;

    let blockchainResponse = await queryBlockchainForData(pactContext, pactCode, policyInfo["chain-id"]);

    return blockchainResponse;
}





function useButterProvider(collectionConfig) {
    const queryBlockChainForData = useQueryBlockchainForData();
    const policy = collectionConfig["policy-info"];
    const projectName = policy["project-name"];
    const collectionName = policy["collection-name"];

    async function getNftMap() {
        let allNfts = await getCollectionNftsMetadata(collectionName);
        let account = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let nftsOnSale = await getAllOnSale();
        let allOnSaleByUser = {};
        let idsOnSale = [];
        let nftMap = {};
        // console.log(nftsOnSale)
        if (nftsOnSale) {
            let nftIds = nftsOnSale.map((nft) => {
                return `"${nft.id}"`;
            });
            let allIds = Object.values(allNfts).map((nft) => {
                return `"${nft.id}"`;
            })

            let fieldListForMktQuery = [`"owner"`, `"price"`];
            let fieldList = [`"owner"`];
            let nftMarketData = await getMarketplaceFieldsForIds(fieldListForMktQuery, nftIds);
            let allNftData = await getNftFieldsForIds(fieldList, allIds);
            let unlistedUserNfts = {}

            Object.entries(allNftData).forEach(([key, nft]) => {
                if (nft["owner"] === account?.account) {
                    unlistedUserNfts[nft["id"]] = nft;
                }
            });

            for (const [id, nftData] of Object.entries(nftMarketData)) {
                let nftMetadata = allNfts[nftData["id"]];
                let nftDataShallowCopy = { ...allNfts[nftData["id"]], ...nftMetadata };

                nftDataShallowCopy["owner"] = nftData["owner"];
                nftDataShallowCopy["price"] = nftData["price"];
                nftDataShallowCopy["nft-id"] = nftData["id"];
                nftDataShallowCopy["nft-uri"] = nftMetadata["image"];
                nftDataShallowCopy["status"] = "listed";
                nftDataShallowCopy["listing-details"] = {
                    price: nftData["price"]
                }

                if (!nftMap[nftData["owner"]]) {
                    nftMap[nftData["owner"]] = {};
                }

                // nftMap[nftData["owner"]][nftData["id"]] = transformNftDataToIsokoStandard(policy, nftDataShallowCopy, collectionUtility);
                nftMap[nftData["owner"]][nftData["id"]] = nftDataShallowCopy;

                idsOnSale.push(nftData["id"])
            }

            for (const [id, nftData] of Object.entries(unlistedUserNfts)) {
                if (idsOnSale.includes(id)) {
                    continue;
                }
                let nftMetadata = allNfts[nftData["id"]];
                let nftDataShallowCopy = { ...allNfts[nftData["id"]], ...nftMetadata };

                nftDataShallowCopy["owner"] = nftData["owner"];
                nftDataShallowCopy["price"] = nftData["price"];
                nftDataShallowCopy["nft-id"] = nftData["id"];
                nftDataShallowCopy["nft-uri"] = nftMetadata["image"];
                nftDataShallowCopy["status"] = "unlocked";
                nftDataShallowCopy["listing-details"] = null

                if (!nftMap[nftData["owner"]]) {
                    nftMap[nftData["owner"]] = {};
                }

                // nftMap[nftData["owner"]][nftData["id"]] = transformNftDataToIsokoStandard(policy, nftDataShallowCopy, collectionUtility);
                nftMap[nftData["owner"]][nftData["id"]] = nftDataShallowCopy;
            }

            //Handle unlisted items
            nftMap["unlisted"] = {};
            for (const [id, nftData] of Object.entries(allNfts)) {
                if (idsOnSale.includes(id) || Object.keys(unlistedUserNfts).includes(id)) {
                    continue;
                }

                let nftMetadata = allNfts[nftData["id"]];
                let nftDataShallowCopy = { ...nftData, ...nftMetadata };

                nftDataShallowCopy["price"] = null;
                nftDataShallowCopy["owner"] = null;
                nftDataShallowCopy["status"] = "unlocked";
                nftDataShallowCopy["nft-id"] = nftData["id"];
                nftDataShallowCopy["nft-uri"] = nftMetadata["image"];
                nftDataShallowCopy["listing-details"] = null;

                // nftMap["unlisted"][id] = transformNftDataToIsokoStandard(policy, nftDataShallowCopy, collectionUtility);
                nftMap["unlisted"][id] = nftDataShallowCopy;
            }
        }
        return nftMap;
    }

    async function getNftData(nftId) {
        let allNfts = await getCollectionNftsMetadata(collectionName);
        return allNfts[nftId];
    }

    async function getNftListingData(nftId, payload) {
        let nftData = {};
        let nftMetadata = await getNftData(nftId);
        let nftFields = await getMarketplaceFieldsForIds([`"owner"`, `"price"`], nftId);

        if (nftFields === null) {
            nftFields = await getNftFieldsForIds([`"owner"`], nftId);
        }
        nftData["name"] = nftMetadata["name"];
        nftData["rarity_level"] = nftMetadata["rarity_level"];
        nftData["nft-uri"] = await getNftImage(nftId);
        nftData["formatted_attributes"] = payload["getNftTraitStats"](nftMetadata["attributes"]);

        nftData["nft-id"] = nftId;
        nftData["chain-id"] = policy["chain-id"];
        nftData["current-owner"] = nftFields["owner"];
        nftData["price"] = nftFields["price"] > -1 ? nftFields["price"] : null;
        nftData["policy-info"] = policy;
        nftData["transaction-fee"] = getAmountByPercentage(nftData["price"], FEE_PERCENTAGE);

        if (nftData["price"]) {
            nftData["listing-details"] = {
                price: nftData["price"],
                timeout: undefined
            }
            nftData["status"] = "listed";
        } else {
            nftData["listing-details"] = null;
            nftData["status"] = "unlocked";
        }

        return nftData;
    }

    function getNftImage(nftId) {
        let nftImageUrl = `${policy["base_image_url"]}${nftId}.png`;
        return nftImageUrl;
    }

    async function getAllOnSale() {
        let pactCode = `(${policy["policy-name"]}.get-all-on-sale)`;

        let blockchainResponse = await queryBlockChainForData(
            pactCode,
            policy["chain-id"]
        );

        if (blockchainResponse) {
            return blockchainResponse;
        }
        return null;
    }

    async function getTotalVolumeForCollection() {
        let pactCode = `(${policy["policy-name"]}.get-count "total-volume-count-key")`;
        let chainId = policy["chain-id"];
        let blockchainResponse = await queryBlockChainForData(pactCode, chainId);

        if (blockchainResponse) {
            return blockchainResponse;
        }

        return null;
    }

    async function getNftFieldsForIds(fieldsList, nftIds) {
        let pactCode = "";
        if (nftIds instanceof Array) {
            pactCode = `(${policy["policy-name"]}.get-nft-fields-for-ids [${fieldsList}] [${nftIds}])`;
        } else {
            pactCode = `(${policy["policy-name"]}.get-nft-fields-for-id [${fieldsList}] "${nftIds}")`;
        }
        let blockchainResponse = await queryBlockChainForData(pactCode, policy["chain-id"]);
        let nftFields = {};

        if (blockchainResponse !== null) {
            Object.entries(blockchainResponse).map(([field, value]) => {
                nftFields[field] = value;

                if (field === "price") {
                    if (value.decimal) {
                        nftFields[field] = parseFloat(value.decimal);
                    }
                }
            })
        } else {
            return null;
        }

        return nftFields;
    }

    async function getMarketplaceFieldsForIds(fieldsList, nftIds) {
        let pactCode = "";
        if (nftIds instanceof Array) {
            pactCode = `(${policy["policy-name"]}.get-marketplace-fields-for-ids [${fieldsList}] [${nftIds}])`;
        } else {
            pactCode = `(${policy["policy-name"]}.get-marketplace-fields-for-id [${fieldsList}] "${nftIds}")`;
        }

        let blockchainResponse = await queryBlockChainForData(pactCode, policy["chain-id"]);
        let nftFields = {};
        // console.log(blockchainResponse)
        if (blockchainResponse !== null) {
            Object.entries(blockchainResponse).map(([field, value]) => {
                nftFields[field] = value;

                if (field === "price") {
                    // console.log(value)
                    if (value.decimal) {
                        nftFields[field] = parseFloat(value.decimal);
                    }
                }
            })
        } else {
            return null;
        }

        return nftFields;
    }

    async function getCollectionFloor() {
        let allOnSale = await getAllOnSale();

        let nftIds = allOnSale.map((nft) => {
            return `"${nft.id}"`;
        });

        let fieldList = [`"price"`];
        let entries = await getMarketplaceFieldsForIds(fieldList, nftIds);

        let floor = null;

        if (!checkIfNullOrUndefined(entries)) {
            Object.values(entries).forEach((nft) => {
                if (checkIfNullOrUndefined(floor)) {
                    floor = nft["price"];
                } else {
                    if (nft["price"] < floor) {
                        floor = nft["price"]
                    }
                }
            });
        }

        return floor;
    }

    async function getRoyaltyFeeFromPrice(price) {
        if (!price) {
            return null;
        }
        let pactCode = `(${policy["policy-name"]}.get-market-fee-from-price ${price.toFixed(2)})`;
        let chainId = policy["chain-id"];
        let blockchainResponse = await queryBlockChainForData(pactCode, chainId);

        if (blockchainResponse) {
            return blockchainResponse;
        }

        return null;
    }

    async function getTotalVolumeForCollection() {
        let pactCode = `(${policy["policy-name"]}.get-count "total-volume-count-key")`;
        let chainId = policy["chain-id"];
        let blockchainResponse = queryBlockChainForData(pactCode, chainId);

        if (blockchainResponse) {
            return blockchainResponse;
        }

        return null;
    }

    function getMarketplaceCollectionDetails(payload) {
        let collMetadata = {};

        collMetadata["total_supply"] = payload["getTotalSupply"]();
        collMetadata["collection_name"] = payload["getCollectionName"]();
        collMetadata["collection_traits"] = payload["getCollectionTraits"]();
        collMetadata["collection_trait_map"] = payload["getTraitCountMap"]();

        collMetadata = {
            ...collMetadata,
            ...collectionConfig
        }

        return collMetadata;
    }

    function sortNfts(nftsList, currentHeight) {
        let unlistedNfts = null;
        let listedNfts = null;

        unlistedNfts = nftsList.filter((nft) => {
            return (
                checkIfNullOrUndefined(nft["listing-details"]) ||
                checkIfNullOrUndefined(nft["listing-details"]["price"])
            )
        });
        listedNfts = nftsList.filter((nft) => {
            return (
                !checkIfNullOrUndefined(nft["listing-details"]) &&
                !checkIfNullOrUndefined(nft["listing-details"]["price"])
            );
        });

        return {
            listed: listedNfts,
            unlisted: unlistedNfts
        }
    }

    return {
        sortNfts,
        getNftMap,
        getNftData,
        getNftImage,
        getNftListingData,
        getCollectionFloor,
        getRoyaltyFeeFromPrice,
        getTotalVolumeForCollection,
        transformNftDataToIsokoStandard,
        getMarketplaceCollectionDetails
    }
}

export {
    useButterProvider,
    getIdsOwnedBuy,
    getAllOnSale,
    getUserNftsInStandardFormat
}
import React, { useContext, useState } from "react";
import {
    Menu,
    MenuButton,
    MenuList,
    Flex,
    Text,
    Box,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";

import {
    FaArrowDown,
    FaCaretDown,
    FaEye,
    FaEyeSlash,
    FaUserAlt,
} from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { PactContext } from "../pact/PactContextProvider";
import { tryLoadLocal, trySaveLocal } from "../utils/utils";
import { SHOW_TOTAL_BALANCE } from "../utils/Constants";
import { IoWallet } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const AccountDropDown = ({ chainMapData, totalBalance }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const pactContext = useContext(PactContext);
    const navigate = useNavigate();

    function handleNavigateClick() {
        navigate(`/userProfile/${pactContext.account.account}`);
    }
    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    return (
        <Box position="relative">
            <Flex
                align="center"
                gap="4px"
                borderBottom={"2px"}
                borderBottomColor={isModalOpen ? "#5E1E78" : "transparent"}
            >
                <Flex align="center" gap="5px" color="#BC3CF0" minW="142px">
                    <Box
                        w="16px"
                        h="16px"
                        rounded="full"
                        bgColor={"#BC3CF0"}
                    ></Box>
                    <Text as="strong">
                        {pactContext.account?.account?.slice(0, 7)}...
                        {pactContext.account?.account?.slice(
                            pactContext.account?.account?.length - 3,
                            pactContext.account?.account?.length
                        )}
                    </Text>
                </Flex>
            </Flex>
            <Box position="absolute" bottom="" className="centerPos" w="100%">
                <Menu
                    closeOnBlur={true}
                    onClose={() => {
                        onClose();
                        handleModalClose();
                    }}
                    closeOnSelect={true}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    // onClose={onClose}
                >
                    <MenuButton
                        alignItems="center"
                        as={Flex}
                        _hover={{}}
                        _focus={{}}
                        _active={{}}
                        onClick={() => {
                            setIsModalOpen(!isModalOpen);
                            onOpen();
                        }}
                        cursor="pointer"
                    >
                        <Flex
                            align="center"
                            justify="center"
                            cursor="pointer"
                            onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                onOpen();
                            }}
                        >
                            {!isModalOpen ? <BsChevronDown /> : <BsChevronUp />}
                            {/* <BsChevronDown /> */}
                        </Flex>
                    </MenuButton>

                    <MenuList
                        p="0px"
                        minW="142px"
                        w="100%"
                        top="0px"
                        boxShadow="none"
                        border="none"
                        outline="none"
                        bgColor="transparent"
                        pb="30px"
                        onClick={() => {
                            onClose();
                            handleModalClose();
                        }}
                    >
                        <DropdownRow>
                            <Text
                                fontSize={"14px"}
                                onClick={handleNavigateClick}
                            >
                                My Profile
                            </Text>
                            <FaUserAlt />
                        </DropdownRow>
                        <DropdownRow>
                            <Text
                                fontSize={"14px"}
                                onClick={() => pactContext.logoutAccount()}
                            >
                                Disconnect wallet
                            </Text>
                            <IoWallet />
                        </DropdownRow>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
};

export default AccountDropDown;

const DropdownRow = ({ children }) => {
    return (
        <Flex
            mt="4px"
            cursor={"pointer"}
            maxW="142px"
            gap="8px"
            align={"center"}
            justify={"space-between"}
        >
            {children}
        </Flex>
    );
};

const AccountRow = ({ chainId, balance }) => {
    return (
        <Flex maxW="164px" mb="4px">
            <Text fontSize="14px">Chain {chainId}:</Text>
            <Text
                fontSize="14px"
                textAlign="right"
                flex="1"
                alignContent={"flex-end"}
            >
                {" "}
                {balance?.toFixed(2)} KDA{" "}
            </Text>
        </Flex>
    );
};

import { useMarmaladeProvider } from "./MarmaladeProvider";
import { useButterMarketplaceProvider } from "./ButterMarketplaceProvider";
import { BUTTER_TYPE, MARMALADE_TYPE, MARMALADE_V2_TYPE, STACKED_TYPE } from "../utils/Constants";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { useContext } from "react";
import { PactContext } from "../pact/PactContextProvider";
import { useMarmaladeV2Provider } from "./MarmaladeV2Provider";

function useMarketplaceProviderFactory(collectionConfig) {
    const ezBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const marmaladeProvider = useMarmaladeProvider(collectionConfig, ezBuy, pactContext);
    const butterProvider = useButterMarketplaceProvider(collectionConfig, ezBuy, pactContext);
    const marmaladev2Provider = useMarmaladeV2Provider(collectionConfig, ezBuy, pactContext)

    switch (collectionConfig["policy-info"]["standard"]) {
        case MARMALADE_TYPE:
            return marmaladeProvider;
        case MARMALADE_V2_TYPE:
            return marmaladev2Provider;
        case STACKED_TYPE:
            return marmaladeProvider;
        case BUTTER_TYPE:
            return butterProvider;
        case MARMALADE_V2_TYPE:
            return marmaladev2Provider;
        default:
            return null;
    }
}

export {
    useMarketplaceProviderFactory
}
import { Box, Flex } from "@chakra-ui/react";
import React from "react";

const ProfileLoadingBar = ({ progress }) => {
    return (
        <Box pos="fixed" top="0" left="0" right="0" w="100%" zIndex={"modal"}>
            <Flex minH="0.7rem" bgColor="#757575">
                <Box bgColor={"#BC3CF0"} w={`${progress}%`} h="18px">
                    {" "}
                </Box>
            </Flex>
        </Box>
    );
};

export default ProfileLoadingBar;

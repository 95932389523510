import { useEffect, useState } from "react";
import { buildCollectionQuery } from "../Firestore/FirestoreService";
import useFirestore from "../Firestore/useFirestore";
import { BUTTER_TYPE } from "../utils/Constants";
import { checkIfNullOrUndefined } from "../utils/utils";

function buildCollectionPath(collectionConfig, subCollectionId = null) {
    let projectName = collectionConfig["policy-info"]["project-name"];
    let collectionName = collectionConfig["policy-info"]["collection-name"];
    let path = `/projects/${projectName}/nft-collections/${collectionName}/nft-map`;

    if (!checkIfNullOrUndefined(subCollectionId)) {
        path = path.concat(`-${subCollectionId}`);
    }

    return path;
}

async function processUserNftMapForMarm(nftMap, fullMap) {
    nftMap.forEach((entry) => {
        processUserNftMap(entry, fullMap)
    })
}

async function processUserNftMap(map, fullMap) {
    let nftCount = 0;
    for (const [user, nfts] of Object.entries(map)) {
        if (Object.values(nfts).length === 0) {
            continue;
        }
        let nftsNew = {};
        if (!fullMap[user]) {
            fullMap[user] = {};
        }
        fullMap[user] = {
            ...fullMap[user],
            ...nfts
        }
    }
    return fullMap;
}

function useNftMap(collectionProvider, subCollectionId = null, deps = null) {
    const [nftMap, setNftMap] = useState(null);
    const nftMapQuery = useFirestore(
        buildCollectionQuery(buildCollectionPath(collectionProvider.getConfig(), subCollectionId)),
        async function (queryResult) {
            let fullMap = {};
            let maps = queryResult.map((map) => {
                return map.data();
            });

            await processUserNftMapForMarm(maps, fullMap).then(() => {
                setNftMap(fullMap);
            });

            return fullMap;
        },
        null,
        deps
    );

    useEffect(() => {
        async function processMap() {
            let collectionConfig = collectionProvider.getConfig();
            if (collectionConfig["policy-info"]["standard"] === BUTTER_TYPE) {
                let fullMap = {};
                let map = await collectionProvider.getNftMap();
                await processUserNftMap(map, fullMap).then(() => {
                    setNftMap(fullMap);
                });
            }
        }
        processMap();
    }, [nftMapQuery]);

    return nftMap;
}

export default useNftMap;
const sgk_battle_heroes_metadata = {
    "1": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "6": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #6",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "6"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "7": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #7",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "7"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "8": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #8",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "8"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "9": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #9",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "9"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "10": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #10",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "10"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "11": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #11",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "11"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "12": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #12",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "12"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "13": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #13",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "13"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "14": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #14",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "14"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "15": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #15",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "15"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "16": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #16",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "16"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "17": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #17",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "17"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "18": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #18",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "18"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "19": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #19",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "19"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "20": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #20",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "20"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "21": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #21",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "21"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "22": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #22",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "22"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "23": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #23",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "23"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "24": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #24",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "24"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "25": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #25",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "25"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "26": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #26",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "26"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "27": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #27",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "27"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "28": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #28",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "28"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "29": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #29",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "29"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "30": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #30",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "30"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "31": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #31",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "31"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "32": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #32",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "32"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "33": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #33",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "33"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "34": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #34",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "34"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "35": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #35",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "35"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "36": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #36",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "36"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "37": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #37",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "37"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "38": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #38",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "38"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "39": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #39",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "39"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "40": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #40",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "40"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "41": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #41",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "41"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "42": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #42",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "42"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "43": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #43",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "43"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "44": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #44",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "44"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "45": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #45",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "45"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "46": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #46",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "46"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "47": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #47",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "47"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "48": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #48",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "48"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "49": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #49",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "49"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "50": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #50",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "50"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "51": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #51",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "51"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "52": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #52",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "52"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "53": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #53",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "53"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "54": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #54",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "54"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "55": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #55",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "55"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "56": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #56",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "56"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "57": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #57",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "57"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "58": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #58",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "58"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "59": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #59",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "59"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "60": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #60",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "60"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "61": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #61",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "61"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "62": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #62",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "62"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "63": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #63",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "63"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "64": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #64",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "64"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "65": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #65",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "65"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "66": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #66",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "66"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "67": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #67",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "67"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "68": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #68",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "68"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "69": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #69",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "69"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "70": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #70",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "70"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "71": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #71",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "71"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "72": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #72",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "72"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "73": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #73",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "73"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "74": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #74",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "74"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "75": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #75",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "75"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "76": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #76",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "76"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "77": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #77",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "77"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "78": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #78",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "78"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "79": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #79",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "79"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "80": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #80",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "80"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "81": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #81",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "81"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "82": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #82",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "82"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "83": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #83",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "83"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "84": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #84",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "84"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "85": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #85",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "85"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "86": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #86",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "86"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "87": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #87",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "87"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "88": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #88",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "88"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "89": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #89",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "89"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "90": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #90",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "90"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "91": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #91",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "91"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "92": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #92",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "92"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "93": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #93",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "93"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "94": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #94",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "94"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "95": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #95",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "95"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "96": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #96",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "96"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "97": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #97",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "97"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "98": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #98",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "98"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "99": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #99",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "99"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "100": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #100",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "100"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "101": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #101",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "101"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "102": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #102",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "102"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "103": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #103",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "103"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "104": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #104",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "104"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "105": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #105",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "105"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "106": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #106",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "106"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "107": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #107",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "107"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "108": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #108",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "108"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "109": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #109",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "109"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "110": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #110",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "110"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "111": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #111",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "111"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "112": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #112",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "112"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "113": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #113",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "113"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "114": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #114",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "114"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "115": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #115",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "115"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "116": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #116",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "116"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "117": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #117",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "117"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "118": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #118",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "118"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "119": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #119",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "119"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "120": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #120",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "120"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "121": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #121",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "121"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "122": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #122",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "122"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "123": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #123",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "123"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "124": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #124",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "124"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "125": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #125",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "125"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "126": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #126",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "126"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "127": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #127",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "127"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "128": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #128",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "128"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "129": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #129",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "129"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "130": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #130",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "130"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "131": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #131",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "131"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "132": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #132",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "132"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "133": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #133",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "133"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "134": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #134",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "134"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "135": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #135",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "135"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "136": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #136",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "136"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "137": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #137",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "137"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "138": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #138",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "138"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "139": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #139",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "139"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "140": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #140",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "140"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "141": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #141",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "141"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "142": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #142",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "142"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "143": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #143",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "143"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "144": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #144",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "144"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "145": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #145",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "145"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "146": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #146",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "146"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "147": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #147",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "147"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "148": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #148",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "148"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "149": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #149",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "149"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "150": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #150",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "150"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "151": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #151",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "151"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "152": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #152",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "152"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "153": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #153",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "153"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "154": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #154",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "154"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "155": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #155",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "155"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "156": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #156",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "156"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "157": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #157",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "157"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "158": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #158",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "158"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "159": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #159",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "159"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "160": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #160",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "160"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "161": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #161",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "161"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "162": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #162",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "162"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "163": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #163",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "163"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "164": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #164",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "164"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "165": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #165",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "165"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "166": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #166",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "166"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "167": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #167",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "167"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "168": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #168",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "168"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "169": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #169",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "169"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "170": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #170",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "170"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "171": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #171",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "171"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "172": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #172",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "172"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "173": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #173",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "173"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "174": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #174",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "174"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "175": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #175",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "175"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "176": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #176",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "176"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "177": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #177",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "177"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "178": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #178",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "178"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "179": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #179",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "179"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "180": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #180",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "180"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "181": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #181",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "181"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "182": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #182",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "182"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "183": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #183",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "183"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "184": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #184",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "184"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "185": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #185",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "185"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "186": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #186",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "186"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "187": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #187",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "187"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "188": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #188",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "188"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "189": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #189",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "189"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "190": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #190",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "190"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "191": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #191",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "191"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "192": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #192",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "192"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "193": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #193",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "193"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "194": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #194",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "194"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "195": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #195",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "195"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "196": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #196",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "196"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "197": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #197",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "197"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "198": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #198",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "198"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "199": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #199",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "199"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "200": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #200",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "200"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "201": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #201",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "201"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "202": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #202",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "202"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "203": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #203",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "203"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "204": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #204",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "204"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "205": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #205",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "205"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "206": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #206",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "206"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "207": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #207",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "207"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "208": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #208",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "208"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "209": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #209",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "209"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "210": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #210",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "210"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "211": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #211",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "211"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "212": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #212",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "212"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "213": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #213",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "213"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "214": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #214",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "214"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "215": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #215",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "215"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "216": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #216",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "216"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "217": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #217",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "217"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "218": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #218",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "218"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "219": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #219",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "219"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "220": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #220",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "220"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "221": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #221",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "221"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "222": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #222",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "222"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "223": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #223",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "223"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "224": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #224",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "224"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "225": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #225",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "225"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "226": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #226",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "226"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "227": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #227",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "227"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "228": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #228",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "228"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "229": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #229",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "229"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "230": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #230",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "230"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "231": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #231",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "231"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "232": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #232",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "232"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "233": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #233",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "233"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "234": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #234",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "234"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "235": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #235",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "235"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "236": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #236",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "236"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "237": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #237",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "237"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "238": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #238",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "238"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "239": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #239",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "239"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "240": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #240",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "240"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "241": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #241",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "241"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "242": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #242",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "242"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "243": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #243",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "243"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "244": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #244",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "244"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "245": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #245",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "245"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "246": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #246",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "246"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "247": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #247",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "247"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "248": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #248",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "248"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "249": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #249",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "249"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "250": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #250",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "250"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "251": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #251",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "251"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "252": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #252",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "252"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "253": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #253",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "253"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "254": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #254",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "254"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "255": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #255",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "255"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "256": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #256",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "256"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "257": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #257",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "257"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "258": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #258",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "258"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "259": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #259",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "259"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "260": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #260",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "260"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "261": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #261",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "261"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "262": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #262",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "262"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "263": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #263",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "263"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "264": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #264",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "264"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "265": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #265",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "265"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "266": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #266",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "266"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "267": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #267",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "267"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "268": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #268",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "268"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "269": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #269",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "269"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "270": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #270",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "270"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "271": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #271",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "271"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "272": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #272",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "272"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "273": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #273",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "273"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "274": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #274",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "274"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "275": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #275",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "275"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "276": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #276",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "276"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "277": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #277",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "277"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "278": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #278",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "278"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "279": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #279",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "279"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "280": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #280",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "280"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "281": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #281",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "281"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "282": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #282",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "282"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "283": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #283",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "283"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "284": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #284",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "284"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "285": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #285",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "285"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "286": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #286",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "286"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "287": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #287",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "287"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "288": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #288",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "288"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "289": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #289",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "289"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "290": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #290",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "290"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "291": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #291",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "291"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "292": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #292",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "292"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "293": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #293",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "293"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "294": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #294",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "294"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "295": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #295",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "295"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "296": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #296",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "296"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "297": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #297",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "297"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "298": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #298",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "298"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "299": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #299",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "299"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "300": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #300",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "300"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "301": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #301",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "301"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "302": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #302",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "302"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "303": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #303",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "303"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "304": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #304",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "304"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "305": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #305",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "305"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "306": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #306",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "306"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "307": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #307",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "307"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "308": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #308",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "308"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "309": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #309",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "309"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "310": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #310",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "310"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "311": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #311",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "311"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "312": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #312",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "312"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "313": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #313",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "313"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "314": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #314",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "314"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "315": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #315",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "315"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "316": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #316",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "316"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "317": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #317",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "317"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "318": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #318",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "318"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "319": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #319",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "319"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "320": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #320",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "320"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "321": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #321",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "321"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "322": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #322",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "322"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "323": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #323",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "323"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "324": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #324",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "324"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "325": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #325",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "325"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "326": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #326",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "326"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "327": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #327",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "327"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "328": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #328",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "328"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "329": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #329",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "329"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "330": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #330",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "330"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "331": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #331",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "331"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "332": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #332",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "332"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "333": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #333",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "333"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "334": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #334",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "334"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "335": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #335",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "335"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "336": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #336",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "336"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "337": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #337",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "337"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "338": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #338",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "338"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "339": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #339",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "339"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "340": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #340",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "340"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "341": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #341",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "341"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "342": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #342",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "342"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "343": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #343",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "343"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "344": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #344",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "344"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "345": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #345",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "345"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "346": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #346",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "346"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "347": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #347",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "347"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "348": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #348",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "348"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "349": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #349",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "349"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "350": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #350",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "350"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "351": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #351",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "351"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "352": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #352",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "352"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "353": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #353",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "353"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "354": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #354",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "354"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "355": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #355",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "355"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "356": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #356",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "356"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "357": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #357",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "357"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "358": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #358",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "358"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "359": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #359",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "359"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "360": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #360",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "360"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "361": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #361",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "361"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "362": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #362",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "362"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "363": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #363",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "363"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "364": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #364",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "364"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "365": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #365",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "365"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "366": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #366",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "366"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "367": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #367",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "367"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "368": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #368",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "368"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "369": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #369",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "369"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "370": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #370",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "370"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "371": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #371",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "371"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "372": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #372",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "372"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "373": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #373",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "373"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "374": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #374",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "374"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "375": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #375",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "375"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "376": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #376",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "376"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "377": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #377",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "377"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "378": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #378",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "378"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "379": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #379",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "379"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "380": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #380",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "380"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "381": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #381",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "381"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "382": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #382",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "382"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "383": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #383",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "383"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "384": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #384",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "384"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "385": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #385",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "385"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "386": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #386",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "386"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "387": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #387",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "387"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "388": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #388",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "388"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "389": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #389",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "389"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "390": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #390",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "390"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "391": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #391",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "391"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "392": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #392",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "392"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "393": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #393",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "393"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "394": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #394",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "394"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "395": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #395",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "395"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "396": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #396",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "396"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "397": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #397",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "397"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "398": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #398",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "398"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "399": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #399",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "399"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "400": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #400",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "400"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "401": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #401",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "401"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "402": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #402",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "402"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "403": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #403",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "403"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "404": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #404",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "404"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "405": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #405",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "405"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "406": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #406",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "406"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "407": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #407",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "407"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "408": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #408",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "408"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "409": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #409",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "409"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "410": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #410",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "410"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "411": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #411",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "411"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "412": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #412",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "412"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "413": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #413",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "413"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "414": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #414",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "414"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "415": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #415",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "415"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "416": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #416",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "416"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "417": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #417",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "417"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "418": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #418",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "418"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "419": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #419",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "419"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "420": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #420",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "420"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "421": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #421",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "421"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "422": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #422",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "422"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "423": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #423",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "423"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "424": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #424",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "424"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "425": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #425",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "425"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "426": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #426",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "426"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "427": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #427",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "427"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "428": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #428",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "428"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "429": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #429",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "429"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "430": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #430",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "430"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "431": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #431",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "431"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "432": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #432",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "432"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "433": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #433",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "433"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "434": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #434",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "434"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "435": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #435",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "435"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "436": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #436",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "436"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "437": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #437",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "437"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "438": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #438",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "438"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "439": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #439",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "439"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "440": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #440",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "440"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "441": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #441",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "441"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "442": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #442",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "442"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "443": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #443",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "443"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "444": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #444",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "444"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "445": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #445",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "445"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "446": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #446",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "446"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "447": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #447",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "447"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "448": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #448",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "448"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "449": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #449",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "449"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "450": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #450",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "450"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "451": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #451",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "451"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "452": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #452",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "452"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "453": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #453",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "453"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "454": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #454",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "454"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "455": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #455",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "455"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "456": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #456",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "456"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "457": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #457",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "457"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "458": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #458",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "458"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "459": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #459",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "459"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "460": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #460",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "460"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "461": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #461",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "461"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "462": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #462",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "462"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "463": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #463",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "463"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "464": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #464",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "464"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "465": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #465",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "465"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "466": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #466",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "466"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "467": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #467",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "467"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "468": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #468",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "468"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "469": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #469",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "469"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "470": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #470",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "470"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "471": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #471",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "471"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "472": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #472",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "472"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "473": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #473",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "473"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "474": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #474",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "474"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "475": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #475",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "475"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "476": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #476",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "476"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "477": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #477",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "477"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "478": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #478",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "478"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "479": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #479",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "479"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "480": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #480",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "480"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "481": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #481",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "481"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "482": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #482",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "482"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "483": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #483",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "483"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "484": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #484",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "484"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "485": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #485",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "485"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "486": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #486",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "486"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "487": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #487",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "487"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "488": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #488",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "488"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "489": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #489",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "489"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "490": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #490",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "490"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "491": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #491",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "491"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "492": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #492",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "492"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "493": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #493",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "493"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "494": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #494",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "494"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "495": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #495",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "495"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "496": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #496",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "496"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "497": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #497",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "497"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "498": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #498",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "498"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "499": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #499",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "499"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "500": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #500",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "500"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "501": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #501",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "501"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "502": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #502",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "502"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "503": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #503",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "503"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "504": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #504",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "504"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "505": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #505",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "505"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "506": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #506",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "506"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "507": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #507",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "507"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "508": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #508",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "508"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "509": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #509",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "509"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "510": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #510",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "510"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "511": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #511",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "511"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "512": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #512",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "512"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "513": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #513",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "513"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "514": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #514",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "514"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "515": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #515",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "515"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "516": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #516",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "516"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "517": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #517",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "517"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "518": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #518",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "518"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "519": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #519",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "519"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "520": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #520",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "520"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "521": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #521",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "521"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "522": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #522",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "522"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "523": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #523",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "523"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "524": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #524",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "524"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "525": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #525",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "525"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "526": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #526",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "526"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "527": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #527",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "527"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "528": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #528",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "528"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "529": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #529",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "529"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "530": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #530",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "530"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "531": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #531",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "531"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "532": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #532",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "532"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "533": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #533",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "533"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "534": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #534",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "534"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "535": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #535",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "535"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "536": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #536",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "536"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "537": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #537",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "537"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "538": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #538",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "538"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "539": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #539",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "539"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "540": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #540",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "540"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "541": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #541",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "541"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "542": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #542",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "542"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "543": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #543",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "543"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "544": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #544",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "544"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "545": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #545",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "545"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "546": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #546",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "546"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "547": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #547",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "547"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "548": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #548",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "548"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "549": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #549",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "549"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "550": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #550",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "550"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "551": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #551",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "551"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "552": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #552",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "552"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "553": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #553",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "553"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "554": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #554",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "554"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "555": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #555",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "555"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "556": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #556",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "556"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "557": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #557",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "557"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "558": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #558",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "558"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "559": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #559",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "559"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "560": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #560",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "560"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "561": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #561",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "561"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "562": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #562",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "562"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "563": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #563",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "563"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "564": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #564",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "564"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "565": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #565",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "565"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "566": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #566",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "566"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "567": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #567",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "567"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "568": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #568",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "568"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "569": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #569",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "569"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "570": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #570",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "570"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "571": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #571",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "571"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "572": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #572",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "572"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "573": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #573",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "573"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "574": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #574",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "574"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "575": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #575",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "575"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "576": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #576",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "576"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "577": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #577",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "577"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "578": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #578",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "578"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "579": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #579",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "579"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "580": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #580",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "580"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "581": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #581",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "581"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "582": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #582",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "582"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "583": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #583",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "583"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "584": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #584",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "584"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "585": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #585",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "585"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "586": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #586",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "586"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "587": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #587",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "587"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "588": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #588",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "588"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "589": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #589",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "589"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "590": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #590",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "590"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "591": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #591",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "591"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "592": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #592",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "592"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "593": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #593",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "593"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "594": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #594",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "594"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "595": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #595",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "595"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "596": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #596",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "596"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "597": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #597",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "597"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "598": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #598",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "598"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "599": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #599",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "599"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "600": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #600",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "600"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "601": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #601",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "601"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "602": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #602",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "602"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "603": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #603",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "603"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "604": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #604",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "604"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "605": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #605",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "605"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "606": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #606",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "606"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "607": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #607",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "607"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "608": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #608",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "608"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "609": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #609",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "609"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "610": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #610",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "610"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "611": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #611",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "611"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "612": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #612",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "612"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "613": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #613",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "613"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "614": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #614",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "614"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "615": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #615",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "615"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "616": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #616",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "616"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "617": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #617",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "617"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "618": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #618",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "618"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "619": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #619",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "619"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "620": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #620",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "620"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "621": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #621",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "621"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "622": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #622",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "622"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "623": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #623",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "623"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "624": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #624",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "624"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "625": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #625",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "625"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "626": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #626",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "626"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "627": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #627",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "627"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "628": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #628",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "628"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "629": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #629",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "629"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "630": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #630",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "630"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "631": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #631",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "631"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "632": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #632",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "632"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "633": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #633",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "633"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "634": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #634",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "634"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "635": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #635",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "635"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "636": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #636",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "636"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "637": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #637",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "637"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "638": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #638",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "638"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "639": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #639",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "639"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "640": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #640",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "640"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "641": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #641",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "641"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "642": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #642",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "642"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "643": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #643",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "643"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "644": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #644",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "644"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "645": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #645",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "645"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "646": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #646",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "646"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "647": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #647",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "647"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "648": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #648",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "648"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "649": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #649",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "649"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "650": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #650",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "650"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "651": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #651",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "651"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "652": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #652",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "652"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "653": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #653",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "653"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "654": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #654",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "654"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "655": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #655",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "655"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "656": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #656",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "656"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "657": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #657",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "657"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "658": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #658",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "658"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "659": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #659",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "659"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "660": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #660",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "660"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "661": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #661",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "661"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "662": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #662",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "662"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "663": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #663",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "663"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "664": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #664",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "664"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "665": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #665",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "665"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "666": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #666",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "666"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "667": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #667",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "667"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "668": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #668",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "668"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "669": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #669",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "669"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "670": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #670",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "670"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "671": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #671",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "671"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "672": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #672",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "672"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "673": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #673",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "673"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "674": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #674",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "674"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "675": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #675",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "675"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "676": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #676",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "676"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "677": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #677",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "677"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "678": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #678",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "678"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "679": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #679",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "679"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "680": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #680",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "680"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "681": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #681",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "681"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "682": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #682",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "682"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "683": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #683",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "683"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "684": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #684",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "684"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "685": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #685",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "685"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "686": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #686",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "686"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "687": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #687",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "687"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "688": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #688",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "688"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "689": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #689",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "689"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "690": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #690",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "690"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "691": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #691",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "691"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "692": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #692",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "692"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "693": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #693",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "693"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "694": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #694",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "694"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "695": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #695",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "695"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "696": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #696",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "696"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "697": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #697",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "697"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "698": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #698",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "698"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "699": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #699",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "699"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "700": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #700",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "700"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "701": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #701",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "701"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "702": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #702",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "702"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "703": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #703",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "703"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "704": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #704",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "704"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "705": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #705",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "705"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "706": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #706",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "706"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "707": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #707",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "707"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "708": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #708",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "708"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "709": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #709",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "709"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "710": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #710",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "710"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "711": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #711",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "711"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "712": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #712",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "712"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "713": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #713",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "713"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "714": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #714",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "714"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "715": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #715",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "715"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "716": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #716",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "716"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "717": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #717",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "717"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "718": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #718",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "718"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "719": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #719",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "719"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "720": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #720",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "720"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "721": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #721",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "721"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "722": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #722",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "722"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "723": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #723",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "723"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "724": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #724",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "724"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "725": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #725",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "725"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "726": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #726",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "726"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "727": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #727",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "727"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "728": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #728",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "728"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "729": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #729",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "729"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "730": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #730",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "730"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "731": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #731",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "731"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "732": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #732",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "732"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "733": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #733",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "733"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "734": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #734",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "734"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "735": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #735",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "735"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "736": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #736",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "736"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "737": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #737",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "737"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "738": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #738",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "738"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "739": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #739",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "739"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "740": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #740",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "740"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "741": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #741",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "741"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "742": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #742",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "742"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "743": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #743",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "743"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "744": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #744",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "744"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "745": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #745",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "745"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "746": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #746",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "746"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "747": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #747",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "747"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "748": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #748",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "748"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "749": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #749",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "749"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "750": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #750",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "750"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "751": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #751",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "751"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "752": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #752",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "752"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "753": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #753",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "753"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "754": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #754",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "754"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "755": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #755",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "755"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "756": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #756",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "756"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "757": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #757",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "757"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "758": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #758",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "758"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "759": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #759",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "759"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "760": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #760",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "760"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "761": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #761",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "761"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "762": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #762",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "762"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "763": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #763",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "763"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "764": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #764",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "764"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "765": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #765",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "765"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "766": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #766",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "766"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "767": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #767",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "767"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "768": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #768",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "768"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "769": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #769",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "769"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "770": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #770",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "770"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "771": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #771",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "771"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "772": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #772",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "772"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "773": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #773",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "773"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "774": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #774",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "774"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "775": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #775",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "775"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "776": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #776",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "776"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "777": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #777",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "777"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "778": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #778",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "778"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "779": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #779",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "779"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "780": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #780",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "780"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "781": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #781",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "781"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "782": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #782",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "782"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "783": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #783",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "783"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "784": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #784",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "784"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "785": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #785",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "785"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "786": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #786",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "786"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "787": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #787",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "787"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "788": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #788",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "788"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "789": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #789",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "789"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "790": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #790",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "790"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "791": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #791",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "791"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "792": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #792",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "792"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "793": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #793",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "793"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "794": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #794",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "794"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "795": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #795",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "795"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "796": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #796",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "796"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "797": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #797",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "797"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "798": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #798",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "798"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "799": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #799",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "799"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "800": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #800",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "800"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "801": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #801",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "801"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "802": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #802",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "802"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "803": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #803",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "803"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "804": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #804",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "804"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "805": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #805",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "805"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "806": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #806",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "806"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "807": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #807",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "807"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "808": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #808",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "808"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "809": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #809",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "809"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "810": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #810",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "810"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "811": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #811",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "811"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "812": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #812",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "812"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "813": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #813",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "813"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "814": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #814",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "814"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "815": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #815",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "815"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "816": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #816",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "816"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "817": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #817",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "817"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "818": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #818",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "818"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "819": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #819",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "819"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "820": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #820",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "820"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "821": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #821",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "821"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "822": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #822",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "822"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "823": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #823",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "823"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "824": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #824",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "824"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "825": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #825",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "825"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "826": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #826",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "826"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "827": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #827",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "827"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "828": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #828",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "828"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "829": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #829",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "829"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "830": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #830",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "830"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "831": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #831",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "831"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "832": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #832",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "832"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "833": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #833",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "833"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "834": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #834",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "834"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "835": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #835",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "835"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "836": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #836",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "836"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "837": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #837",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "837"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "838": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #838",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "838"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "839": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #839",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "839"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "840": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #840",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "840"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "841": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #841",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "841"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "842": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #842",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "842"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "843": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #843",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "843"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "844": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #844",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "844"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "845": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #845",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "845"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "846": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #846",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "846"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "847": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #847",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "847"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "848": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #848",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "848"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "849": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #849",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "849"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "850": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #850",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "850"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "851": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #851",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "851"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "852": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #852",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "852"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "853": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #853",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "853"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "854": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #854",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "854"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "855": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #855",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "855"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "856": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #856",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "856"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "857": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #857",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "857"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "858": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #858",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "858"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "859": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #859",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "859"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "860": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #860",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "860"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "861": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #861",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "861"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "862": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #862",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "862"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "863": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #863",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "863"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "864": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #864",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "864"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "865": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #865",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "865"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "866": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #866",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "866"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "867": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #867",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "867"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "868": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #868",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "868"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "869": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #869",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "869"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "870": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #870",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "870"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "871": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #871",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "871"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "872": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #872",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "872"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "873": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #873",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "873"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "874": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #874",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "874"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "875": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #875",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "875"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "876": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #876",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "876"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "877": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #877",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "877"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "878": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #878",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "878"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "879": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #879",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "879"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "880": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #880",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "880"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "881": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #881",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "881"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "882": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #882",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "882"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "883": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #883",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "883"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "884": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #884",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "884"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "885": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #885",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "885"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "886": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #886",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "886"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "887": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #887",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "887"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "888": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #888",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "888"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "889": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #889",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "889"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "890": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #890",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "890"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "891": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #891",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "891"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "892": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #892",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "892"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "893": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #893",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "893"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "894": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #894",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "894"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "895": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #895",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "895"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "896": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #896",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "896"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "897": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #897",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "897"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "898": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #898",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "898"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "899": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #899",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "899"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "900": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #900",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "900"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "901": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #901",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "901"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "902": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #902",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "902"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "903": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #903",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "903"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "904": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #904",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "904"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "905": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #905",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "905"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "906": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #906",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "906"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "907": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #907",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "907"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "908": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #908",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "908"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "909": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #909",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "909"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "910": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #910",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "910"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "911": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #911",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "911"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "912": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #912",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "912"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "913": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #913",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "913"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "914": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #914",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "914"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "915": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #915",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "915"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "916": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #916",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "916"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "917": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #917",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "917"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "918": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #918",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "918"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "919": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #919",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "919"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "920": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #920",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "920"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "921": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #921",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "921"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "922": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #922",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "922"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "923": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #923",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "923"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "924": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #924",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "924"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "925": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #925",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "925"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "926": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #926",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "926"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "927": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #927",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "927"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "928": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #928",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "928"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "929": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #929",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "929"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "930": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #930",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "930"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "931": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #931",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "931"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "932": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #932",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "932"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "933": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #933",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "933"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "934": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #934",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "934"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "935": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #935",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "935"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "936": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #936",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "936"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "937": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #937",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "937"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "938": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #938",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "938"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "939": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #939",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "939"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "940": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #940",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "940"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "941": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #941",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "941"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "942": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #942",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "942"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "943": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #943",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "943"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "944": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #944",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "944"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "945": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #945",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "945"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "946": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #946",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "946"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "947": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #947",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "947"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "948": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #948",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "948"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "949": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #949",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "949"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "950": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #950",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "950"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "951": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #951",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "951"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "952": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #952",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "952"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "953": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #953",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "953"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "954": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #954",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "954"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "955": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #955",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "955"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "956": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #956",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "956"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "957": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #957",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "957"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "958": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #958",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "958"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "959": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #959",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "959"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "960": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #960",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "960"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "961": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #961",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "961"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "962": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #962",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "962"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "963": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #963",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "963"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "964": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #964",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "964"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "965": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #965",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "965"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "966": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #966",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "966"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "967": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #967",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "967"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "968": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #968",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "968"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "969": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #969",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "969"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "970": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #970",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "970"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "971": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #971",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "971"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "972": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #972",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "972"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "973": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #973",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "973"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "974": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #974",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "974"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "975": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #975",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "975"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "976": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #976",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "976"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "977": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #977",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "977"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "978": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #978",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "978"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "979": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #979",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "979"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "980": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #980",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "980"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "981": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #981",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "981"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "982": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #982",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "982"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "983": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #983",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "983"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "984": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #984",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "984"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "985": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #985",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "985"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "986": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #986",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "986"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "987": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #987",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "987"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "988": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #988",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "988"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "989": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #989",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "989"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "990": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #990",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "990"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "991": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #991",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "991"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "992": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #992",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "992"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "993": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #993",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "993"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "994": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #994",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "994"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "995": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #995",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "995"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "996": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #996",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "996"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "997": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #997",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "997"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "998": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #998",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "998"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "999": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #999",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "999"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1000": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1000",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1000"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1001": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1001",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1001"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1002": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1002",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1002"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1003": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1003",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1003"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1004": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1004",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1004"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1005": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1005",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1005"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1006": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1006",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1006"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1007": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1007",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1007"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1008": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1008",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1008"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1009": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1009",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1009"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1010": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1010",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1010"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1011": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1011",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1011"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1012": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1012",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1012"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1013": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1013",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1013"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1014": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1014",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1014"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1015": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1015",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1015"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1016": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1016",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1016"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1017": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1017",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1017"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1018": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1018",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1018"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1019": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1019",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1019"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1020": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1020",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1020"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1021": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1021",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1021"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1022": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1022",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1022"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1023": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1023",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1023"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1024": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1024",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1024"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1025": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1025",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1025"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1026": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1026",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1026"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1027": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1027",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1027"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1028": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1028",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1028"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1029": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1029",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1029"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1030": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1030",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1030"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1031": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1031",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1031"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1032": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1032",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1032"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1033": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1033",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1033"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1034": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1034",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1034"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1035": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1035",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1035"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1036": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1036",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1036"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1037": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1037",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1037"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1038": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1038",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1038"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1039": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1039",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1039"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1040": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1040",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1040"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1041": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1041",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1041"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1042": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1042",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1042"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1043": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1043",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1043"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1044": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1044",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1044"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1045": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1045",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1045"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1046": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1046",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1046"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1047": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1047",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1047"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1048": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1048",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1048"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1049": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1049",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1049"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1050": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1050",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1050"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1051": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1051",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1051"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1052": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1052",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1052"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1053": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1053",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1053"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1054": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1054",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1054"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1055": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1055",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1055"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1056": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1056",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1056"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1057": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1057",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1057"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1058": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1058",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1058"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1059": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1059",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1059"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1060": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1060",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1060"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1061": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1061",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1061"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1062": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1062",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1062"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1063": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1063",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1063"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1064": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1064",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1064"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1065": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1065",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1065"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1066": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1066",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1066"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1067": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1067",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1067"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1068": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1068",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1068"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1069": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1069",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1069"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1070": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1070",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1070"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1071": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1071",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1071"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1072": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1072",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1072"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1073": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1073",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1073"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1074": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1074",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1074"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1075": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1075",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1075"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1076": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1076",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1076"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1077": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1077",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1077"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1078": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1078",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1078"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1079": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1079",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1079"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1080": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1080",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1080"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1081": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1081",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1081"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1082": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1082",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1082"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1083": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1083",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1083"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1084": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1084",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1084"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1085": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1085",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1085"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1086": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1086",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1086"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1087": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1087",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1087"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1088": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1088",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1088"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1089": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1089",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1089"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1090": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1090",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1090"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1091": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1091",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1091"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1092": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1092",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1092"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1093": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1093",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1093"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1094": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1094",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1094"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1095": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1095",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1095"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1096": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1096",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1096"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1097": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1097",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1097"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1098": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1098",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1098"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1099": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1099",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1099"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1100": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1100",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1100"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1101": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1101",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1101"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1102": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1102",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1102"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1103": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1103",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1103"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1104": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1104",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1104"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1105": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1105",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1105"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1106": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1106",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1106"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1107": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1107",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1107"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1108": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1108",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1108"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1109": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1109",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1109"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1110": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1110",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1110"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1111": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1111",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1111"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1112": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1112",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1112"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1113": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1113",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1113"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1114": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1114",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1114"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1115": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1115",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1115"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1116": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1116",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1116"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1117": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1117",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1117"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1118": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1118",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1118"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1119": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1119",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1119"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1120": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1120",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1120"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1121": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1121",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1121"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1122": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1122",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1122"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1123": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1123",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1123"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1124": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1124",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1124"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1125": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1125",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1125"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1126": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1126",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1126"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1127": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1127",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1127"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1128": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1128",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1128"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1129": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1129",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1129"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1130": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1130",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1130"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1131": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1131",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1131"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1132": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1132",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1132"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1133": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1133",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1133"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1134": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1134",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1134"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1135": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1135",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1135"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1136": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1136",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1136"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1137": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1137",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1137"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1138": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1138",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1138"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1139": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1139",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1139"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1140": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1140",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1140"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1141": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1141",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1141"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1142": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1142",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1142"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1143": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1143",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1143"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1144": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1144",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1144"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1145": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1145",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1145"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1146": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1146",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1146"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1147": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1147",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1147"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1148": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1148",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1148"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1149": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1149",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1149"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1150": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1150",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1150"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1151": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1151",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1151"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1152": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1152",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1152"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1153": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1153",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1153"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1154": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1154",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1154"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1155": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1155",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1155"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1156": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1156",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1156"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1157": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1157",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1157"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1158": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1158",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1158"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1159": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1159",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1159"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1160": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1160",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1160"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1161": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1161",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1161"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1162": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1162",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1162"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1163": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1163",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1163"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1164": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1164",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1164"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1165": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1165",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1165"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1166": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1166",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1166"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1167": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1167",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1167"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1168": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1168",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1168"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1169": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1169",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1169"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1170": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1170",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1170"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1171": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1171",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1171"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1172": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1172",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1172"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1173": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1173",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1173"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1174": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1174",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1174"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1175": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1175",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1175"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1176": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1176",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1176"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1177": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1177",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1177"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1178": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1178",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1178"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1179": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1179",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1179"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1180": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1180",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1180"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1181": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1181",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1181"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1182": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1182",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1182"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1183": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1183",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1183"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1184": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1184",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1184"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1185": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1185",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1185"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1186": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1186",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1186"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1187": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1187",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1187"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1188": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1188",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1188"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1189": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1189",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1189"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1190": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1190",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1190"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1191": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1191",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1191"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1192": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1192",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1192"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1193": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1193",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1193"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1194": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1194",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1194"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1195": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1195",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1195"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1196": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1196",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1196"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1197": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1197",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1197"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1198": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1198",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1198"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1199": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1199",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1199"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1200": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1200",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1200"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1201": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1201",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1201"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1202": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1202",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1202"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1203": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1203",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1203"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1204": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1204",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1204"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1205": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1205",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1205"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1206": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1206",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1206"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1207": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1207",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1207"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1208": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1208",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1208"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1209": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1209",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1209"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1210": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1210",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1210"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1211": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1211",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1211"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1212": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1212",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1212"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1213": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1213",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1213"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1214": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1214",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1214"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1215": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1215",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1215"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1216": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1216",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1216"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1217": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1217",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1217"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1218": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1218",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1218"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1219": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1219",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1219"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1220": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1220",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1220"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1221": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1221",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1221"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1222": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1222",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1222"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1223": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1223",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1223"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1224": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1224",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1224"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1225": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1225",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1225"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1226": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1226",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1226"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1227": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1227",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1227"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1228": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1228",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1228"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1229": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1229",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1229"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1230": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1230",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1230"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1231": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1231",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1231"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1232": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1232",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1232"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1233": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1233",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1233"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1234": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1234",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1234"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1235": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1235",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1235"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1236": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1236",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1236"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1237": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1237",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1237"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1238": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1238",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1238"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1239": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1239",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1239"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1240": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1240",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1240"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1241": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1241",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1241"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1242": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1242",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1242"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1243": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1243",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1243"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1244": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1244",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1244"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1245": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1245",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1245"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1246": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1246",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1246"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1247": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1247",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1247"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1248": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1248",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1248"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1249": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1249",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1249"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1250": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1250",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1250"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1251": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1251",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1251"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1252": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1252",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1252"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1253": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1253",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1253"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1254": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1254",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1254"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1255": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1255",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1255"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1256": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1256",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1256"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1257": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1257",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1257"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1258": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1258",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1258"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1259": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1259",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1259"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1260": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1260",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1260"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1261": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1261",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1261"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1262": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1262",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1262"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1263": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1263",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1263"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1264": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1264",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1264"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1265": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1265",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1265"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1266": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1266",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1266"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1267": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1267",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1267"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1268": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1268",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1268"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1269": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1269",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1269"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1270": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1270",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1270"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1271": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1271",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1271"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1272": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1272",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1272"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1273": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1273",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1273"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1274": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1274",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1274"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1275": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1275",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1275"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1276": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1276",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1276"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1277": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1277",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1277"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1278": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1278",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1278"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1279": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1279",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1279"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1280": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1280",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1280"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1281": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1281",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1281"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1282": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1282",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1282"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1283": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1283",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1283"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1284": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1284",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1284"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1285": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1285",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1285"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1286": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1286",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1286"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1287": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1287",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1287"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1288": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1288",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1288"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1289": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1289",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1289"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1290": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1290",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1290"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1291": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1291",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1291"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1292": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1292",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1292"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1293": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1293",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1293"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1294": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1294",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1294"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1295": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1295",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1295"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1296": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1296",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1296"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1297": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1297",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1297"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1298": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1298",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1298"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1299": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1299",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1299"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1300": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1300",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1300"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1301": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1301",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1301"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1302": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1302",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1302"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1303": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1303",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1303"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1304": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1304",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1304"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1305": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1305",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1305"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1306": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1306",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1306"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1307": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1307",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1307"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1308": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1308",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1308"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1309": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1309",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1309"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1310": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1310",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1310"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1311": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1311",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1311"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1312": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1312",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1312"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1313": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1313",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1313"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1314": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1314",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1314"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1315": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1315",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1315"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1316": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1316",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1316"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1317": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1317",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1317"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1318": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1318",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1318"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1319": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1319",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1319"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1320": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1320",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1320"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1321": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1321",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1321"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1322": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1322",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1322"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1323": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1323",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1323"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1324": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1324",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1324"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1325": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1325",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1325"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1326": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1326",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1326"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1327": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1327",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1327"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1328": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1328",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1328"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1329": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1329",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1329"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1330": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1330",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1330"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1331": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1331",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1331"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1332": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1332",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1332"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1333": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1333",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1333"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1334": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1334",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1334"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1335": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1335",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1335"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1336": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1336",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1336"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1337": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1337",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1337"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1338": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1338",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1338"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1339": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1339",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1339"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1340": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1340",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1340"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1341": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1341",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1341"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1342": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1342",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1342"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1343": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1343",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1343"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1344": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1344",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1344"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1345": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1345",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1345"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1346": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1346",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1346"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1347": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1347",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1347"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1348": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1348",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1348"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1349": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1349",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1349"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1350": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1350",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1350"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1351": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1351",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1351"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1352": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1352",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1352"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1353": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1353",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1353"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1354": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1354",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1354"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1355": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1355",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1355"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1356": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1356",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1356"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1357": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1357",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1357"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1358": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1358",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1358"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1359": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1359",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1359"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1360": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1360",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1360"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1361": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1361",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1361"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1362": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1362",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1362"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1363": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1363",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1363"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1364": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1364",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1364"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1365": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1365",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1365"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1366": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1366",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1366"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1367": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1367",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1367"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1368": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1368",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1368"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1369": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1369",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1369"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1370": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1370",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1370"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1371": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1371",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1371"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1372": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1372",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1372"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1373": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1373",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1373"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1374": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1374",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1374"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1375": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1375",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1375"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1376": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1376",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1376"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1377": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1377",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1377"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1378": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1378",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1378"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1379": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1379",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1379"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1380": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1380",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1380"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1381": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1381",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1381"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1382": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1382",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1382"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1383": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1383",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1383"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1384": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1384",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1384"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1385": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1385",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1385"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1386": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1386",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1386"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1387": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1387",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1387"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1388": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1388",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1388"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1389": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1389",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1389"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1390": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1390",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1390"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1391": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1391",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1391"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1392": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1392",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1392"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1393": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1393",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1393"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1394": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1394",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1394"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1395": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1395",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1395"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1396": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1396",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1396"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1397": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1397",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1397"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1398": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1398",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1398"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1399": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1399",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1399"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1400": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1400",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1400"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1401": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1401",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1401"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1402": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1402",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1402"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1403": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1403",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1403"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1404": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1404",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1404"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1405": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1405",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1405"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1406": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1406",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1406"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1407": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1407",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1407"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1408": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1408",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1408"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1409": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1409",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1409"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1410": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1410",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1410"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1411": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1411",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1411"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1412": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1412",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1412"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1413": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1413",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1413"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1414": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1414",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1414"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1415": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1415",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1415"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1416": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1416",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1416"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1417": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1417",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1417"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1418": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1418",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1418"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1419": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1419",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1419"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1420": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1420",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1420"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1421": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1421",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1421"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1422": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1422",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1422"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1423": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1423",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1423"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1424": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1424",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1424"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1425": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1425",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1425"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1426": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1426",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1426"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1427": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1427",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1427"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1428": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1428",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1428"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1429": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1429",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1429"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1430": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1430",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1430"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1431": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1431",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1431"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1432": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1432",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1432"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1433": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1433",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1433"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1434": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1434",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1434"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1435": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1435",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1435"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1436": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1436",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1436"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1437": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1437",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1437"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1438": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1438",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1438"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1439": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1439",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1439"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1440": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1440",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1440"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1441": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1441",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1441"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1442": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1442",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1442"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1443": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1443",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1443"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1444": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1444",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1444"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1445": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1445",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1445"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1446": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1446",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1446"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1447": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1447",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1447"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1448": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1448",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1448"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1449": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1449",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1449"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1450": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1450",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1450"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1451": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1451",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1451"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1452": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1452",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1452"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1453": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1453",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1453"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1454": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1454",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1454"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1455": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1455",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1455"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1456": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1456",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1456"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1457": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1457",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1457"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1458": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1458",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1458"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1459": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1459",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1459"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1460": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1460",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1460"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1461": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1461",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1461"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1462": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1462",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1462"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1463": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1463",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1463"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1464": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1464",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1464"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1465": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1465",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1465"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1466": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1466",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1466"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1467": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1467",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1467"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1468": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1468",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1468"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1469": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1469",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1469"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1470": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1470",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1470"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1471": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1471",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1471"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1472": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1472",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1472"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1473": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1473",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1473"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1474": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1474",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1474"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1475": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1475",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1475"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1476": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1476",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1476"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1477": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1477",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1477"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1478": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1478",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1478"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1479": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1479",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1479"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1480": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1480",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1480"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1481": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1481",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1481"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1482": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1482",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1482"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1483": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1483",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1483"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1484": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1484",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1484"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1485": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1485",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1485"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1486": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1486",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1486"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1487": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1487",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1487"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1488": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1488",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1488"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1489": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1489",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1489"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1490": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1490",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1490"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1491": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1491",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1491"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1492": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1492",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1492"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1493": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1493",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1493"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1494": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1494",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1494"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1495": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1495",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1495"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1496": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1496",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1496"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1497": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1497",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1497"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1498": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1498",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1498"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1499": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1499",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1499"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1500": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1500",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1500"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1501": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1501",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1501"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1502": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1502",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1502"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1503": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1503",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1503"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1504": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1504",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1504"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1505": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1505",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1505"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1506": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1506",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1506"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1507": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1507",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1507"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1508": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1508",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1508"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1509": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1509",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1509"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1510": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1510",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1510"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1511": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1511",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1511"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1512": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1512",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1512"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1513": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1513",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1513"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1514": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1514",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1514"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1515": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1515",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1515"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1516": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1516",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1516"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1517": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1517",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1517"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1518": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1518",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1518"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1519": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1519",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1519"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1520": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1520",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1520"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1521": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1521",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1521"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1522": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1522",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1522"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1523": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1523",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1523"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1524": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1524",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1524"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1525": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1525",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1525"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1526": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1526",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1526"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1527": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1527",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1527"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1528": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1528",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1528"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1529": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1529",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1529"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1530": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1530",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1530"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1531": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1531",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1531"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1532": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1532",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1532"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1533": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1533",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1533"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1534": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1534",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1534"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1535": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1535",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1535"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1536": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1536",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1536"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1537": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1537",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1537"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1538": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1538",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1538"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1539": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1539",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1539"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1540": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1540",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1540"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1541": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1541",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1541"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1542": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1542",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1542"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1543": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1543",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1543"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1544": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1544",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1544"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1545": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1545",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1545"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1546": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1546",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1546"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1547": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1547",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1547"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1548": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1548",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1548"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1549": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1549",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1549"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1550": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1550",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1550"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1551": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1551",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1551"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1552": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1552",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1552"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1553": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1553",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1553"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1554": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1554",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1554"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1555": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1555",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1555"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1556": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1556",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1556"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1557": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1557",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1557"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1558": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1558",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1558"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1559": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1559",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1559"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1560": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1560",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1560"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1561": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1561",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1561"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1562": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1562",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1562"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1563": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1563",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1563"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1564": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1564",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1564"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1565": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1565",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1565"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1566": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1566",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1566"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1567": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1567",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1567"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1568": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1568",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1568"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1569": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1569",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1569"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1570": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1570",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1570"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1571": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1571",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1571"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1572": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1572",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1572"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1573": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1573",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1573"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1574": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1574",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1574"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1575": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1575",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1575"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1576": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1576",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1576"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1577": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1577",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1577"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1578": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1578",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1578"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1579": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1579",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1579"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1580": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1580",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1580"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1581": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1581",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1581"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1582": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1582",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1582"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1583": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1583",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1583"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1584": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1584",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1584"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1585": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1585",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1585"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1586": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1586",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1586"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1587": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1587",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1587"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1588": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1588",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1588"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1589": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1589",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1589"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1590": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1590",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1590"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1591": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1591",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1591"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1592": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1592",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1592"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1593": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1593",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1593"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1594": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1594",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1594"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1595": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1595",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1595"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1596": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1596",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1596"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1597": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1597",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1597"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1598": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1598",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1598"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1599": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1599",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1599"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1600": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1600",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1600"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1601": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1601",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1601"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1602": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1602",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1602"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1603": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1603",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1603"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1604": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1604",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1604"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1605": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1605",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1605"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1606": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1606",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1606"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1607": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1607",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1607"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1608": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1608",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1608"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1609": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1609",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1609"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1610": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1610",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1610"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1611": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1611",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1611"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1612": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1612",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1612"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1613": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1613",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1613"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1614": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1614",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1614"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1615": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1615",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1615"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1616": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1616",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1616"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1617": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1617",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1617"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1618": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1618",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1618"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1619": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1619",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1619"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1620": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1620",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1620"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1621": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1621",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1621"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1622": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1622",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1622"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1623": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1623",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1623"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1624": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1624",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1624"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1625": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1625",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1625"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1626": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1626",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1626"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1627": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1627",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1627"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1628": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1628",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1628"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1629": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1629",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1629"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1630": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1630",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1630"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1631": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1631",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1631"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1632": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1632",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1632"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1633": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1633",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1633"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1634": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1634",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1634"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1635": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1635",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1635"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1636": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1636",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1636"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1637": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1637",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1637"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1638": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1638",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1638"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1639": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1639",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1639"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1640": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1640",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1640"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1641": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1641",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1641"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1642": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1642",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1642"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1643": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1643",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1643"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1644": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1644",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1644"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1645": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1645",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1645"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1646": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1646",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1646"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1647": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1647",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1647"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1648": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1648",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1648"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1649": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1649",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1649"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1650": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1650",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1650"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1651": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1651",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1651"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1652": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1652",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1652"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1653": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1653",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1653"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1654": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1654",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1654"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1655": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1655",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1655"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1656": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1656",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1656"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1657": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1657",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1657"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1658": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1658",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1658"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1659": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1659",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1659"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1660": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1660",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1660"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1661": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1661",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1661"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1662": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1662",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1662"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1663": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1663",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1663"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1664": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1664",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1664"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1665": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1665",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1665"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1666": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1666",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1666"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1667": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1667",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1667"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1668": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1668",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1668"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1669": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1669",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1669"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1670": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1670",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1670"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1671": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1671",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1671"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1672": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1672",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1672"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1673": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1673",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1673"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1674": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1674",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1674"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1675": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1675",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1675"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1676": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1676",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1676"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1677": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1677",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1677"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1678": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1678",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1678"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1679": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1679",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1679"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1680": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1680",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1680"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1681": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1681",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1681"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1682": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1682",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1682"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1683": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1683",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1683"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1684": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1684",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1684"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1685": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1685",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1685"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1686": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1686",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1686"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1687": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1687",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1687"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1688": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1688",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1688"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1689": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1689",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1689"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1690": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1690",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1690"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1691": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1691",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1691"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1692": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1692",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1692"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1693": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1693",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1693"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1694": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1694",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1694"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1695": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1695",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1695"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1696": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1696",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1696"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1697": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1697",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1697"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1698": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1698",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1698"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1699": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1699",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1699"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1700": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1700",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1700"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1701": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1701",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1701"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1702": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1702",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1702"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1703": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1703",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1703"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1704": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1704",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1704"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1705": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1705",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1705"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1706": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1706",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1706"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1707": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1707",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1707"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1708": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1708",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1708"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1709": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1709",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1709"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1710": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1710",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1710"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1711": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1711",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1711"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1712": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1712",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1712"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1713": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1713",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1713"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1714": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1714",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1714"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1715": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1715",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1715"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1716": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1716",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1716"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1717": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1717",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1717"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1718": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1718",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1718"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1719": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1719",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1719"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1720": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1720",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1720"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1721": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1721",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1721"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1722": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1722",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1722"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1723": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1723",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1723"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1724": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1724",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1724"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1725": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1725",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1725"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1726": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1726",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1726"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1727": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1727",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1727"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1728": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1728",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1728"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1729": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1729",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1729"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1730": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1730",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1730"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1731": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1731",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1731"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1732": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1732",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1732"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1733": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1733",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1733"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1734": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1734",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1734"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1735": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1735",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1735"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1736": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1736",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1736"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1737": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1737",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1737"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1738": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1738",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1738"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1739": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1739",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1739"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1740": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1740",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1740"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1741": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1741",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1741"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1742": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1742",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1742"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1743": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1743",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1743"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1744": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1744",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1744"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1745": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1745",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1745"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1746": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1746",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1746"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1747": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1747",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1747"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1748": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1748",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1748"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1749": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1749",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1749"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1750": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1750",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1750"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1751": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1751",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1751"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1752": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1752",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1752"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1753": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1753",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1753"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1754": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1754",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1754"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1755": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1755",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1755"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1756": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1756",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1756"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1757": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1757",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1757"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1758": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1758",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1758"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1759": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1759",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1759"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1760": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1760",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1760"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1761": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1761",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1761"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1762": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1762",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1762"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1763": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1763",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1763"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1764": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1764",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1764"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1765": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1765",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1765"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1766": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1766",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1766"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1767": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1767",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1767"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1768": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1768",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1768"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1769": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1769",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1769"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1770": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1770",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1770"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1771": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1771",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1771"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1772": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1772",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1772"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1773": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1773",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1773"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1774": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1774",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1774"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1775": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1775",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1775"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1776": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1776",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1776"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1777": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1777",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1777"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1778": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1778",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1778"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1779": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1779",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1779"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1780": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1780",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1780"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1781": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1781",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1781"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1782": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1782",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1782"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1783": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1783",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1783"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1784": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1784",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1784"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1785": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1785",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1785"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1786": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1786",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1786"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1787": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1787",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1787"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1788": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1788",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1788"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1789": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1789",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1789"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1790": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1790",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1790"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1791": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1791",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1791"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1792": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1792",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1792"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1793": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1793",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1793"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1794": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1794",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1794"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1795": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1795",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1795"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1796": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1796",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1796"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1797": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1797",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1797"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1798": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1798",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1798"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1799": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1799",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1799"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1800": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1800",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1800"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1801": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1801",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1801"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1802": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1802",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1802"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1803": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1803",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1803"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1804": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1804",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1804"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1805": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1805",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1805"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1806": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1806",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1806"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1807": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1807",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1807"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1808": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1808",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1808"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1809": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1809",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1809"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1810": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1810",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1810"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1811": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1811",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1811"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1812": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1812",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1812"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1813": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1813",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1813"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1814": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1814",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1814"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1815": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1815",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1815"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1816": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1816",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1816"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1817": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1817",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1817"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1818": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1818",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1818"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1819": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1819",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1819"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1820": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1820",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1820"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1821": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1821",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1821"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1822": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1822",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1822"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1823": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1823",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1823"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1824": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1824",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1824"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1825": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1825",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1825"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1826": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1826",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1826"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1827": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1827",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1827"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1828": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1828",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1828"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1829": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1829",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1829"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1830": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1830",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1830"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1831": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1831",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1831"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1832": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1832",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1832"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1833": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1833",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1833"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1834": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1834",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1834"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1835": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1835",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1835"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1836": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1836",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1836"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1837": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1837",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1837"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1838": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1838",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1838"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1839": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1839",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1839"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1840": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1840",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1840"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1841": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1841",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1841"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1842": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1842",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1842"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1843": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1843",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1843"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1844": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1844",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1844"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1845": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1845",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1845"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1846": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1846",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1846"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1847": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1847",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1847"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1848": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1848",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1848"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1849": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1849",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1849"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1850": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1850",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1850"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1851": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1851",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1851"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1852": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1852",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1852"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1853": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1853",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1853"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1854": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1854",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1854"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1855": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1855",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1855"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1856": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1856",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1856"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1857": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1857",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1857"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1858": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1858",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1858"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1859": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1859",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1859"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1860": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1860",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1860"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1861": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1861",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1861"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1862": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1862",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1862"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1863": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1863",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1863"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1864": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1864",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1864"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1865": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1865",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1865"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1866": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1866",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1866"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1867": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1867",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1867"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1868": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1868",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1868"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1869": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1869",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1869"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1870": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1870",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1870"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1871": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1871",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1871"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1872": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1872",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1872"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1873": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1873",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1873"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1874": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1874",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1874"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1875": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1875",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1875"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1876": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1876",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1876"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1877": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1877",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1877"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1878": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1878",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1878"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1879": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1879",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1879"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1880": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1880",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1880"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1881": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1881",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1881"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1882": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1882",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1882"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1883": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1883",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1883"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1884": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1884",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1884"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1885": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1885",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1885"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1886": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1886",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1886"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1887": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1887",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1887"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1888": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1888",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1888"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1889": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1889",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1889"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1890": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1890",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1890"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1891": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1891",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1891"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1892": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1892",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1892"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1893": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1893",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1893"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1894": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1894",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1894"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1895": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1895",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1895"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1896": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1896",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1896"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1897": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1897",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1897"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1898": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1898",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1898"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1899": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1899",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1899"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1900": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1900",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1900"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1901": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1901",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1901"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1902": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1902",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1902"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1903": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1903",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1903"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1904": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1904",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1904"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1905": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1905",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1905"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1906": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1906",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1906"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1907": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1907",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1907"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1908": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1908",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1908"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1909": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1909",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1909"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1910": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1910",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1910"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1911": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1911",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1911"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1912": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1912",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1912"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1913": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1913",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1913"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1914": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1914",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1914"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1915": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1915",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1915"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1916": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1916",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1916"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1917": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1917",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1917"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1918": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1918",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1918"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1919": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1919",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1919"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1920": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1920",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1920"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1921": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1921",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1921"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1922": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1922",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1922"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1923": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1923",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1923"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1924": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1924",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1924"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1925": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1925",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1925"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1926": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1926",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1926"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1927": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1927",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1927"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1928": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1928",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1928"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1929": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1929",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1929"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1930": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1930",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1930"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1931": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1931",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1931"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1932": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1932",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1932"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1933": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1933",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1933"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1934": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1934",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1934"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1935": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1935",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1935"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1936": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1936",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1936"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1937": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1937",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1937"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1938": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1938",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1938"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1939": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1939",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1939"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1940": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1940",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1940"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1941": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1941",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1941"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1942": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1942",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1942"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1943": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1943",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1943"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1944": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1944",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1944"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1945": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1945",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1945"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1946": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1946",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1946"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1947": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1947",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1947"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1948": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1948",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1948"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1949": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1949",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1949"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1950": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1950",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1950"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1951": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1951",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1951"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1952": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1952",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1952"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1953": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1953",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1953"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1954": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1954",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1954"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1955": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1955",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1955"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1956": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1956",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1956"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1957": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1957",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1957"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1958": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1958",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1958"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1959": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1959",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1959"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1960": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1960",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1960"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1961": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1961",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1961"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1962": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1962",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1962"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1963": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1963",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1963"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1964": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1964",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1964"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1965": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1965",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1965"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1966": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1966",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1966"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1967": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1967",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1967"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1968": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1968",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1968"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1969": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1969",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1969"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1970": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1970",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1970"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1971": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1971",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1971"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1972": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1972",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1972"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1973": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1973",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1973"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1974": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1974",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1974"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1975": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1975",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1975"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1976": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1976",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1976"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1977": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1977",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1977"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1978": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1978",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1978"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1979": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1979",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1979"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1980": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1980",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1980"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1981": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1981",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1981"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1982": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1982",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1982"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1983": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1983",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1983"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1984": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1984",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1984"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1985": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1985",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1985"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1986": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1986",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1986"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1987": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1987",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1987"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1988": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1988",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1988"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1989": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1989",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1989"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1990": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1990",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1990"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1991": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1991",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1991"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1992": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1992",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1992"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1993": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1993",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1993"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1994": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1994",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1994"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1995": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1995",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1995"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1996": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #1996",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "1996"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1997": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1997",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1997"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1998": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1998",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1998"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "1999": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #1999",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "1999"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2000": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2000",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2000"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2001": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2001",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2001"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2002": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2002",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2002"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2003": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2003",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2003"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2004": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2004",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2004"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2005": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2005",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2005"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2006": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2006",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2006"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2007": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2007",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2007"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2008": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2008",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2008"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2009": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2009",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2009"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2010": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2010",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2010"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2011": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2011",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2011"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2012": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2012",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2012"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2013": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2013",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2013"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2014": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2014",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2014"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2015": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2015",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2015"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2016": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2016",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2016"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2017": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2017",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2017"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2018": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2018",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2018"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2019": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2019",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2019"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2020": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2020",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2020"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2021": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2021",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2021"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2022": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2022",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2022"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2023": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2023",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2023"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2024": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2024",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2024"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2025": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2025",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2025"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2026": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2026",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2026"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2027": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2027",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2027"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2028": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2028",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2028"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2029": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2029",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2029"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2030": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2030",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2030"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2031": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2031",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2031"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2032": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2032",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2032"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2033": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2033",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2033"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2034": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2034",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2034"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2035": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2035",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2035"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2036": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2036",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2036"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2037": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2037",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2037"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2038": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2038",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2038"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2039": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2039",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2039"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2040": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2040",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2040"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2041": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2041",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2041"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2042": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2042",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2042"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2043": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2043",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2043"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2044": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2044",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2044"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2045": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2045",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2045"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2046": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2046",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2046"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2047": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2047",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2047"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2048": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2048",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2048"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2049": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2049",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2049"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2050": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2050",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2050"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2051": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2051",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2051"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2052": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2052",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2052"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2053": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2053",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2053"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2054": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2054",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2054"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2055": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2055",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2055"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2056": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2056",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2056"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2057": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2057",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2057"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2058": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2058",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2058"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2059": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2059",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2059"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2060": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2060",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2060"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2061": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2061",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2061"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2062": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2062",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2062"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2063": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2063",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2063"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2064": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2064",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2064"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2065": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2065",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2065"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2066": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2066",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2066"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2067": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2067",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2067"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2068": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2068",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2068"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2069": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2069",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2069"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2070": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2070",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2070"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2071": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2071",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2071"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2072": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2072",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2072"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2073": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2073",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2073"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2074": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2074",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2074"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2075": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2075",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2075"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2076": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2076",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2076"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2077": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2077",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2077"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2078": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2078",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2078"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2079": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2079",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2079"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2080": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2080",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2080"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2081": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2081",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2081"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2082": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2082",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2082"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2083": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2083",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2083"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2084": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2084",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2084"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2085": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2085",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2085"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2086": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2086",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2086"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2087": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2087",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2087"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2088": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2088",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2088"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2089": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2089",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2089"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2090": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2090",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2090"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2091": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2091",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2091"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2092": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2092",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2092"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2093": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2093",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2093"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2094": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2094",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2094"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2095": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2095",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2095"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2096": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2096",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2096"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2097": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2097",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2097"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2098": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2098",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2098"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2099": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2099",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2099"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2100": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2100",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2100"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2101": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2101",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2101"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2102": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2102",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2102"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2103": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2103",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2103"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2104": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2104",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2104"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2105": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2105",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2105"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2106": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2106",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2106"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2107": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2107",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2107"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2108": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2108",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2108"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2109": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2109",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2109"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2110": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2110",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2110"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2111": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2111",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2111"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2112": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2112",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2112"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2113": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2113",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2113"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2114": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2114",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2114"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2115": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2115",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2115"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2116": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2116",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2116"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2117": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2117",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2117"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2118": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2118",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2118"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2119": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2119",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2119"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2120": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2120",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2120"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2121": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2121",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2121"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2122": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2122",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2122"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2123": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2123",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2123"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2124": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2124",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2124"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2125": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2125",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2125"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2126": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2126",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2126"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2127": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2127",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2127"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2128": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2128",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2128"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2129": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2129",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2129"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2130": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2130",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2130"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2131": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2131",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2131"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2132": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2132",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2132"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2133": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2133",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2133"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2134": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2134",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2134"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2135": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2135",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2135"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2136": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2136",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2136"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2137": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2137",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2137"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2138": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2138",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2138"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2139": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2139",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2139"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2140": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2140",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2140"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2141": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2141",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2141"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2142": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2142",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2142"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2143": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2143",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2143"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2144": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2144",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2144"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2145": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2145",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2145"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2146": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2146",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2146"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2147": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2147",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2147"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2148": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2148",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2148"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2149": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2149",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2149"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2150": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2150",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2150"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2151": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2151",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2151"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2152": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2152",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2152"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2153": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2153",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2153"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2154": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2154",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2154"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2155": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2155",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2155"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2156": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2156",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2156"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2157": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2157",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2157"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2158": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2158",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2158"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2159": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2159",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2159"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2160": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2160",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2160"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2161": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2161",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2161"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2162": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2162",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2162"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2163": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2163",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2163"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2164": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2164",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2164"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2165": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2165",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2165"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2166": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2166",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2166"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2167": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2167",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2167"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2168": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2168",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2168"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2169": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2169",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2169"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2170": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2170",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2170"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2171": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2171",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2171"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2172": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2172",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2172"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2173": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2173",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2173"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2174": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2174",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2174"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2175": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2175",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2175"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2176": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2176",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2176"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2177": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2177",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2177"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2178": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2178",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2178"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2179": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2179",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2179"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2180": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2180",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2180"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2181": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2181",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2181"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2182": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2182",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2182"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2183": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2183",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2183"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2184": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2184",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2184"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2185": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2185",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2185"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2186": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2186",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2186"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2187": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2187",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2187"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2188": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2188",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2188"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2189": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2189",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2189"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2190": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2190",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2190"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2191": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2191",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2191"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2192": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2192",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2192"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2193": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2193",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2193"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2194": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2194",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2194"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2195": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2195",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2195"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2196": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2196",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2196"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2197": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2197",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2197"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2198": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2198",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2198"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2199": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2199",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2199"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2200": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2200",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2200"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2201": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2201",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2201"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2202": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2202",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2202"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2203": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2203",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2203"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2204": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2204",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2204"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2205": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2205",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2205"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2206": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2206",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2206"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2207": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2207",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2207"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2208": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2208",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2208"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2209": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2209",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2209"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2210": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2210",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2210"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2211": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2211",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2211"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2212": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2212",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2212"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2213": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2213",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2213"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2214": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2214",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2214"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2215": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2215",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2215"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2216": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2216",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2216"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2217": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2217",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2217"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2218": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2218",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2218"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2219": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2219",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2219"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2220": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2220",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2220"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2221": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2221",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2221"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2222": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2222",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2222"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2223": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2223",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2223"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2224": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2224",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2224"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2225": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2225",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2225"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2226": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2226",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2226"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2227": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2227",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2227"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2228": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2228",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2228"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2229": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2229",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2229"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2230": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2230",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2230"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2231": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2231",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2231"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2232": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2232",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2232"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2233": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2233",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2233"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2234": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2234",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2234"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2235": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2235",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2235"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2236": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2236",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2236"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2237": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2237",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2237"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2238": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2238",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2238"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2239": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2239",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2239"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2240": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2240",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2240"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2241": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2241",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2241"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2242": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2242",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2242"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2243": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2243",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2243"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2244": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2244",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2244"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2245": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2245",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2245"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2246": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2246",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2246"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2247": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2247",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2247"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2248": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2248",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2248"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2249": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2249",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2249"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2250": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2250",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2250"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2251": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2251",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2251"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2252": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2252",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2252"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2253": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2253",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2253"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2254": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2254",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2254"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2255": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2255",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2255"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2256": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2256",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2256"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2257": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2257",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2257"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2258": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2258",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2258"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2259": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2259",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2259"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2260": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2260",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2260"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2261": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2261",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2261"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2262": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2262",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2262"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2263": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2263",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2263"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2264": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2264",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2264"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2265": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2265",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2265"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2266": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2266",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2266"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2267": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2267",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2267"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2268": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2268",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2268"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2269": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2269",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2269"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2270": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2270",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2270"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2271": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2271",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2271"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2272": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2272",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2272"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2273": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2273",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2273"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2274": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2274",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2274"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2275": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2275",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2275"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2276": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2276",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2276"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2277": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2277",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2277"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2278": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2278",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2278"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2279": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2279",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2279"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2280": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2280",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2280"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2281": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2281",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2281"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2282": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2282",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2282"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2283": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2283",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2283"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2284": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2284",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2284"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2285": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2285",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2285"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2286": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2286",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2286"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2287": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2287",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2287"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2288": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2288",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2288"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2289": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2289",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2289"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2290": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2290",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2290"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2291": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2291",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2291"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2292": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2292",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2292"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2293": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2293",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2293"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2294": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2294",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2294"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2295": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2295",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2295"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2296": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2296",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2296"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2297": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2297",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2297"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2298": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2298",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2298"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2299": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2299",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2299"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2300": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2300",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2300"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2301": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2301",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2301"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2302": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2302",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2302"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2303": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2303",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2303"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2304": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2304",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2304"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2305": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2305",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2305"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2306": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2306",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2306"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2307": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2307",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2307"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2308": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2308",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2308"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2309": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2309",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2309"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2310": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2310",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2310"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2311": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2311",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2311"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2312": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2312",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2312"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2313": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2313",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2313"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2314": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2314",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2314"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2315": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2315",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2315"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2316": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2316",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2316"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2317": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2317",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2317"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2318": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2318",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2318"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2319": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2319",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2319"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2320": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2320",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2320"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2321": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2321",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2321"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2322": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2322",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2322"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2323": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2323",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2323"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2324": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2324",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2324"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2325": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2325",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2325"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2326": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2326",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2326"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2327": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2327",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2327"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2328": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2328",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2328"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2329": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2329",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2329"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2330": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2330",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2330"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2331": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2331",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2331"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2332": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2332",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2332"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2333": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2333",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2333"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2334": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2334",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2334"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2335": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2335",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2335"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2336": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2336",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2336"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2337": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2337",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2337"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2338": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2338",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2338"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2339": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2339",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2339"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2340": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2340",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2340"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2341": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2341",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2341"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2342": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2342",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2342"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2343": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2343",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2343"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2344": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2344",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2344"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2345": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2345",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2345"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2346": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2346",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2346"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2347": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2347",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2347"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2348": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2348",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2348"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2349": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2349",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2349"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2350": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2350",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2350"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2351": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2351",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2351"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2352": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2352",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2352"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2353": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2353",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2353"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2354": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2354",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2354"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2355": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2355",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2355"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2356": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2356",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2356"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2357": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2357",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2357"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2358": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2358",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2358"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2359": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2359",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2359"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2360": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2360",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2360"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2361": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2361",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2361"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2362": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2362",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2362"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2363": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2363",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2363"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2364": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2364",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2364"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2365": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2365",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2365"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2366": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2366",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2366"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2367": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2367",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2367"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2368": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2368",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2368"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2369": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2369",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2369"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2370": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2370",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2370"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2371": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2371",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2371"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2372": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2372",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2372"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2373": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2373",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2373"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2374": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2374",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2374"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2375": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2375",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2375"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2376": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2376",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2376"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2377": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2377",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2377"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2378": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2378",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2378"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2379": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2379",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2379"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2380": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2380",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2380"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2381": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2381",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2381"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2382": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2382",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2382"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2383": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2383",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2383"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2384": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2384",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2384"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2385": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2385",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2385"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2386": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2386",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2386"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2387": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2387",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2387"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2388": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2388",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2388"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2389": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2389",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2389"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2390": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2390",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2390"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2391": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2391",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2391"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2392": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2392",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2392"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2393": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2393",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2393"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2394": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2394",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2394"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2395": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2395",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2395"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2396": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2396",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2396"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2397": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2397",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2397"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2398": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2398",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2398"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2399": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2399",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2399"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2400": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2400",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2400"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2401": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2401",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2401"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2402": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2402",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2402"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2403": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2403",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2403"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2404": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2404",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2404"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2405": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2405",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2405"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2406": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2406",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2406"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2407": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2407",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2407"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2408": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2408",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2408"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2409": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2409",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2409"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2410": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2410",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2410"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2411": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2411",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2411"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2412": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2412",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2412"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2413": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2413",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2413"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2414": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2414",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2414"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2415": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2415",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2415"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2416": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2416",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2416"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2417": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2417",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2417"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2418": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2418",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2418"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2419": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2419",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2419"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2420": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2420",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2420"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2421": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2421",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2421"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2422": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2422",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2422"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2423": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2423",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2423"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2424": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2424",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2424"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2425": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2425",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2425"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2426": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2426",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2426"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2427": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2427",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2427"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2428": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2428",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2428"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2429": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2429",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2429"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2430": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2430",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2430"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2431": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2431",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2431"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2432": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2432",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2432"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2433": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2433",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2433"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2434": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2434",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2434"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2435": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2435",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2435"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2436": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2436",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2436"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2437": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2437",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2437"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2438": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2438",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2438"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2439": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2439",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2439"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2440": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2440",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2440"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2441": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2441",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2441"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2442": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2442",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2442"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2443": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2443",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2443"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2444": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2444",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2444"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2445": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2445",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2445"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2446": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2446",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2446"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2447": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2447",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2447"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2448": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2448",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2448"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2449": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2449",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2449"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2450": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2450",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2450"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2451": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2451",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2451"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2452": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2452",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2452"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2453": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2453",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2453"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2454": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2454",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2454"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2455": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2455",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2455"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2456": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2456",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2456"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2457": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2457",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2457"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2458": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2458",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2458"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2459": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2459",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2459"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2460": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2460",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2460"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2461": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2461",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2461"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2462": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2462",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2462"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2463": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2463",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2463"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2464": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2464",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2464"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2465": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2465",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2465"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2466": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2466",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2466"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2467": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2467",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2467"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2468": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2468",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2468"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2469": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2469",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2469"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2470": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2470",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2470"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2471": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2471",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2471"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2472": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2472",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2472"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2473": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2473",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2473"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2474": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2474",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2474"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2475": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2475",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2475"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2476": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2476",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2476"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2477": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2477",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2477"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2478": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2478",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2478"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2479": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2479",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2479"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2480": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2480",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2480"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2481": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2481",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2481"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2482": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2482",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2482"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2483": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2483",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2483"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2484": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2484",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2484"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2485": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2485",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2485"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2486": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2486",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2486"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2487": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2487",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2487"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2488": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2488",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2488"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2489": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2489",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2489"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2490": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2490",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2490"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2491": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2491",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2491"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2492": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2492",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2492"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2493": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2493",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2493"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2494": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2494",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2494"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2495": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2495",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2495"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2496": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2496",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2496"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2497": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2497",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2497"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2498": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2498",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2498"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2499": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2499",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2499"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2500": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2500",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2500"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2501": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2501",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2501"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2502": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2502",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2502"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2503": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2503",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2503"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2504": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2504",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2504"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2505": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2505",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2505"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2506": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2506",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2506"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2507": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2507",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2507"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2508": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2508",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2508"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2509": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2509",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2509"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2510": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2510",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2510"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2511": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2511",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2511"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2512": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2512",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2512"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2513": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2513",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2513"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2514": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2514",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2514"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2515": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2515",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2515"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2516": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2516",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2516"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2517": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2517",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2517"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2518": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2518",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2518"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2519": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2519",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2519"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2520": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2520",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2520"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2521": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2521",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2521"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2522": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2522",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2522"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2523": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2523",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2523"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2524": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2524",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2524"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2525": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2525",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2525"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2526": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2526",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2526"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2527": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2527",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2527"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2528": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2528",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2528"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2529": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2529",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2529"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2530": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2530",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2530"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2531": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2531",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2531"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2532": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2532",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2532"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2533": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2533",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2533"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2534": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2534",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2534"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2535": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2535",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2535"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2536": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2536",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2536"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2537": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2537",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2537"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2538": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2538",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2538"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2539": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2539",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2539"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2540": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2540",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2540"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2541": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2541",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2541"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2542": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2542",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2542"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2543": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2543",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2543"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2544": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2544",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2544"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2545": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2545",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2545"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2546": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2546",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2546"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2547": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2547",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2547"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2548": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2548",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2548"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2549": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2549",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2549"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2550": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2550",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2550"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2551": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2551",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2551"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2552": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2552",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2552"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2553": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2553",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2553"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2554": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2554",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2554"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2555": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2555",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2555"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2556": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2556",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2556"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2557": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2557",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2557"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2558": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2558",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2558"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2559": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2559",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2559"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2560": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2560",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2560"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2561": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2561",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2561"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2562": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2562",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2562"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2563": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2563",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2563"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2564": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2564",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2564"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2565": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2565",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2565"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2566": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2566",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2566"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2567": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2567",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2567"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2568": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2568",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2568"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2569": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2569",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2569"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2570": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2570",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2570"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2571": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2571",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2571"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2572": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2572",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2572"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2573": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2573",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2573"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2574": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2574",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2574"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2575": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2575",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2575"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2576": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2576",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2576"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2577": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2577",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2577"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2578": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2578",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2578"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2579": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2579",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2579"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2580": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2580",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2580"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2581": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2581",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2581"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2582": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2582",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2582"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2583": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2583",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2583"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2584": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2584",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2584"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2585": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2585",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2585"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2586": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2586",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2586"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2587": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2587",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2587"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2588": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2588",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2588"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2589": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2589",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2589"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2590": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2590",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2590"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2591": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2591",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2591"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2592": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2592",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2592"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2593": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2593",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2593"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2594": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2594",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2594"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2595": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2595",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2595"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2596": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2596",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2596"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2597": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2597",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2597"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2598": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2598",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2598"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2599": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2599",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2599"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2600": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2600",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2600"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2601": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2601",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2601"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2602": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2602",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2602"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2603": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2603",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2603"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2604": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2604",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2604"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2605": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2605",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2605"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2606": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2606",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2606"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2607": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2607",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2607"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2608": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2608",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2608"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2609": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2609",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2609"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2610": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2610",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2610"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2611": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2611",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2611"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2612": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2612",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2612"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2613": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2613",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2613"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2614": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2614",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2614"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2615": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2615",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2615"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2616": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2616",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2616"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2617": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2617",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2617"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2618": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2618",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2618"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2619": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2619",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2619"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2620": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2620",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2620"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2621": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2621",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2621"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2622": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2622",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2622"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2623": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2623",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2623"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2624": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2624",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2624"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2625": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2625",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2625"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2626": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2626",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2626"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2627": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2627",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2627"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2628": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2628",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2628"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2629": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2629",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2629"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2630": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2630",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2630"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2631": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2631",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2631"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2632": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2632",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2632"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2633": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2633",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2633"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2634": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2634",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2634"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2635": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2635",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2635"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2636": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2636",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2636"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2637": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2637",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2637"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2638": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2638",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2638"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2639": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2639",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2639"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2640": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2640",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2640"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2641": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2641",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2641"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2642": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2642",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2642"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2643": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2643",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2643"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2644": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2644",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2644"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2645": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2645",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2645"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2646": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2646",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2646"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2647": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2647",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2647"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2648": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2648",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2648"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2649": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2649",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2649"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2650": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2650",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2650"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2651": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2651",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2651"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2652": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2652",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2652"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2653": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2653",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2653"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2654": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2654",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2654"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2655": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2655",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2655"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2656": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2656",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2656"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2657": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2657",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2657"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2658": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2658",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2658"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2659": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2659",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2659"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2660": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2660",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2660"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2661": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2661",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2661"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2662": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2662",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2662"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2663": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2663",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2663"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2664": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2664",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2664"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2665": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2665",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2665"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2666": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2666",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2666"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2667": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2667",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2667"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2668": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2668",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2668"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2669": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2669",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2669"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2670": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2670",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2670"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2671": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2671",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2671"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2672": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2672",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2672"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2673": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2673",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2673"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2674": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2674",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2674"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2675": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2675",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2675"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2676": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2676",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2676"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2677": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2677",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2677"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2678": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2678",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2678"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2679": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2679",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2679"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2680": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2680",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2680"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2681": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2681",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2681"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2682": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2682",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2682"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2683": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2683",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2683"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2684": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2684",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2684"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2685": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2685",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2685"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2686": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2686",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2686"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2687": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2687",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2687"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2688": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2688",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2688"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2689": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2689",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2689"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2690": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2690",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2690"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2691": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2691",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2691"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2692": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2692",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2692"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2693": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2693",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2693"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2694": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2694",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2694"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2695": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2695",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2695"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2696": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2696",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2696"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2697": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2697",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2697"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2698": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2698",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2698"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2699": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2699",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2699"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2700": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2700",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2700"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2701": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2701",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2701"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2702": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2702",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2702"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2703": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2703",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2703"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2704": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2704",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2704"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2705": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2705",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2705"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2706": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2706",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2706"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2707": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2707",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2707"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2708": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2708",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2708"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2709": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2709",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2709"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2710": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2710",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2710"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2711": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2711",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2711"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2712": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2712",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2712"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2713": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2713",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2713"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2714": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2714",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2714"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2715": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2715",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2715"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2716": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2716",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2716"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2717": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2717",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2717"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2718": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2718",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2718"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2719": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2719",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2719"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2720": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2720",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2720"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2721": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2721",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2721"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2722": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2722",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2722"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2723": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2723",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2723"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2724": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2724",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2724"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2725": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2725",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2725"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2726": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2726",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2726"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2727": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2727",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2727"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2728": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2728",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2728"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2729": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2729",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2729"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2730": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2730",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2730"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2731": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2731",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2731"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2732": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2732",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2732"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2733": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2733",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2733"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2734": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2734",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2734"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2735": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2735",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2735"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2736": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2736",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2736"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2737": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2737",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2737"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2738": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2738",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2738"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2739": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2739",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2739"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2740": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2740",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2740"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2741": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2741",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2741"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2742": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2742",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2742"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2743": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2743",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2743"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2744": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2744",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2744"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2745": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2745",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2745"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2746": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2746",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2746"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2747": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2747",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2747"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2748": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2748",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2748"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2749": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2749",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2749"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2750": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2750",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2750"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2751": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2751",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2751"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2752": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2752",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2752"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2753": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2753",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2753"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2754": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2754",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2754"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2755": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2755",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2755"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2756": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2756",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2756"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2757": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2757",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2757"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2758": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2758",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2758"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2759": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2759",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2759"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2760": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2760",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2760"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2761": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2761",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2761"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2762": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2762",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2762"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2763": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2763",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2763"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2764": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2764",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2764"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2765": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2765",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2765"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2766": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2766",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2766"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2767": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2767",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2767"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2768": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2768",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2768"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2769": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2769",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2769"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2770": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2770",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2770"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2771": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2771",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2771"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2772": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2772",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2772"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2773": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2773",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2773"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2774": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2774",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2774"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2775": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2775",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2775"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2776": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2776",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2776"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2777": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2777",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2777"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2778": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2778",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2778"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2779": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2779",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2779"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2780": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2780",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2780"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2781": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2781",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2781"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2782": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2782",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2782"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2783": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2783",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2783"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2784": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2784",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2784"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2785": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2785",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2785"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2786": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2786",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2786"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2787": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2787",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2787"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2788": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2788",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2788"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2789": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2789",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2789"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2790": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2790",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2790"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2791": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2791",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2791"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2792": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2792",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2792"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2793": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2793",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2793"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2794": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2794",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2794"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2795": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2795",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2795"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2796": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2796",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2796"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2797": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2797",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2797"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2798": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2798",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2798"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2799": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2799",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2799"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2800": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2800",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2800"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2801": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2801",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2801"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2802": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2802",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2802"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2803": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2803",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2803"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2804": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2804",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2804"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2805": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2805",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2805"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2806": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2806",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2806"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2807": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2807",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2807"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2808": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2808",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2808"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2809": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2809",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2809"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2810": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2810",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2810"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2811": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2811",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2811"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2812": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2812",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2812"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2813": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2813",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2813"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2814": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2814",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2814"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2815": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2815",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2815"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2816": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2816",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2816"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2817": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2817",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2817"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2818": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2818",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2818"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2819": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2819",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2819"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2820": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2820",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2820"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2821": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2821",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2821"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2822": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2822",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2822"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2823": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2823",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2823"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2824": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2824",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2824"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2825": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2825",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2825"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2826": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2826",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2826"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2827": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2827",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2827"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2828": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2828",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2828"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2829": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2829",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2829"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2830": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2830",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2830"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2831": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2831",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2831"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2832": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2832",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2832"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2833": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2833",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2833"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2834": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2834",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2834"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2835": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2835",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2835"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2836": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2836",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2836"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2837": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2837",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2837"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2838": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2838",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2838"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2839": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2839",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2839"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2840": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2840",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2840"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2841": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2841",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2841"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2842": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2842",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2842"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2843": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2843",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2843"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2844": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2844",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2844"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2845": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2845",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2845"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2846": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2846",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2846"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2847": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2847",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2847"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2848": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2848",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2848"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2849": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2849",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2849"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2850": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2850",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2850"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2851": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2851",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2851"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2852": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2852",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2852"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2853": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2853",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2853"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2854": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2854",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2854"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2855": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2855",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2855"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2856": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2856",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2856"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2857": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2857",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2857"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2858": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2858",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2858"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2859": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2859",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2859"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2860": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2860",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2860"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2861": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2861",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2861"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2862": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2862",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2862"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2863": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2863",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2863"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2864": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2864",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2864"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2865": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2865",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2865"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2866": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2866",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2866"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2867": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2867",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2867"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2868": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2868",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2868"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2869": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2869",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2869"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2870": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2870",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2870"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2871": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2871",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2871"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2872": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2872",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2872"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2873": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2873",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2873"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2874": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2874",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2874"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2875": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2875",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2875"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2876": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2876",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2876"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2877": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2877",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2877"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2878": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2878",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2878"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2879": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2879",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2879"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2880": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2880",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2880"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2881": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2881",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2881"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2882": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2882",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2882"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2883": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2883",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2883"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2884": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2884",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2884"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2885": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2885",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2885"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2886": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2886",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2886"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2887": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2887",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2887"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2888": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2888",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2888"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2889": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2889",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2889"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2890": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2890",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2890"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2891": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2891",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2891"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2892": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2892",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2892"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2893": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2893",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2893"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2894": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2894",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2894"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2895": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2895",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2895"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2896": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2896",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2896"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2897": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2897",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2897"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2898": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2898",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2898"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2899": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2899",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2899"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2900": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2900",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2900"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2901": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2901",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2901"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2902": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2902",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2902"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2903": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2903",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2903"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2904": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2904",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2904"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2905": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2905",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2905"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2906": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2906",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2906"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2907": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2907",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2907"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2908": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2908",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2908"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2909": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2909",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2909"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2910": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2910",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2910"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2911": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2911",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2911"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2912": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2912",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2912"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2913": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2913",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2913"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2914": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2914",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2914"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2915": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2915",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2915"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2916": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2916",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2916"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2917": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2917",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2917"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2918": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2918",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2918"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2919": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2919",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2919"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2920": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2920",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2920"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2921": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2921",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2921"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2922": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2922",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2922"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2923": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2923",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2923"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2924": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2924",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2924"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2925": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2925",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2925"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2926": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2926",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2926"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2927": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2927",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2927"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2928": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2928",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2928"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2929": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2929",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2929"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2930": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2930",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2930"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2931": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2931",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2931"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2932": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2932",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2932"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2933": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2933",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2933"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2934": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2934",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2934"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2935": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2935",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2935"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2936": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2936",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2936"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2937": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2937",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2937"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2938": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2938",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2938"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2939": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2939",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2939"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2940": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2940",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2940"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2941": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2941",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2941"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2942": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2942",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2942"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2943": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2943",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2943"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2944": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2944",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2944"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2945": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2945",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2945"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2946": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2946",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2946"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2947": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2947",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2947"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2948": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2948",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2948"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2949": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2949",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2949"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2950": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2950",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2950"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2951": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2951",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2951"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2952": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2952",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2952"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2953": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2953",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2953"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2954": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2954",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2954"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2955": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2955",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2955"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2956": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2956",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2956"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2957": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2957",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2957"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2958": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2958",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2958"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2959": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2959",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2959"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2960": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2960",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2960"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2961": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2961",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2961"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2962": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2962",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2962"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2963": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2963",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2963"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2964": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2964",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2964"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2965": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2965",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2965"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2966": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2966",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2966"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2967": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2967",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2967"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2968": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2968",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2968"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2969": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2969",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2969"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2970": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2970",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2970"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2971": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2971",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2971"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2972": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2972",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2972"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2973": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2973",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2973"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2974": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2974",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2974"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2975": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2975",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2975"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2976": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2976",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2976"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2977": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2977",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2977"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2978": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2978",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2978"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2979": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2979",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2979"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2980": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2980",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2980"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2981": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2981",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2981"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2982": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2982",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2982"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2983": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2983",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2983"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2984": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2984",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2984"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2985": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2985",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2985"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2986": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2986",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2986"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2987": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2987",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2987"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2988": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2988",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2988"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2989": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2989",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2989"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2990": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2990",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2990"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2991": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2991",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2991"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2992": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2992",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2992"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2993": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2993",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2993"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2994": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2994",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2994"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2995": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2995",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2995"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2996": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2996",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2996"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2997": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #2997",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "2997"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2998": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2998",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2998"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "2999": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #2999",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "2999"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3000": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3000",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3000"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3001": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3001",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3001"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3002": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3002",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3002"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3003": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3003",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3003"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3004": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3004",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3004"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3005": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3005",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3005"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3006": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3006",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3006"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3007": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3007",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3007"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3008": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3008",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3008"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3009": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3009",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3009"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3010": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3010",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3010"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3011": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3011",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3011"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3012": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3012",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3012"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3013": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3013",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3013"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3014": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3014",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3014"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3015": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3015",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3015"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3016": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3016",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3016"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3017": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3017",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3017"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3018": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3018",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3018"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3019": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3019",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3019"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3020": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3020",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3020"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3021": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3021",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3021"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3022": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3022",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3022"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3023": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3023",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3023"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3024": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3024",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3024"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3025": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3025",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3025"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3026": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3026",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3026"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3027": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3027",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3027"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3028": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3028",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3028"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3029": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3029",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3029"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3030": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3030",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3030"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3031": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3031",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3031"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3032": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3032",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3032"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3033": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3033",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3033"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3034": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3034",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3034"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3035": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3035",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3035"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3036": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3036",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3036"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3037": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3037",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3037"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3038": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3038",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3038"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3039": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3039",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3039"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3040": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3040",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3040"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3041": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3041",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3041"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3042": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3042",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3042"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3043": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3043",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3043"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3044": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3044",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3044"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3045": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3045",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3045"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3046": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3046",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3046"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3047": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3047",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3047"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3048": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3048",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3048"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3049": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3049",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3049"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3050": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3050",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3050"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3051": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3051",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3051"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3052": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3052",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3052"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3053": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3053",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3053"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3054": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3054",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3054"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3055": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3055",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3055"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3056": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3056",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3056"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3057": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3057",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3057"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3058": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3058",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3058"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3059": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3059",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3059"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3060": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3060",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3060"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3061": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3061",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3061"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3062": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3062",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3062"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3063": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3063",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3063"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3064": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3064",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3064"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3065": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3065",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3065"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3066": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3066",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3066"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3067": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3067",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3067"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3068": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3068",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3068"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3069": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3069",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3069"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3070": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3070",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3070"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3071": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3071",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3071"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3072": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3072",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3072"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3073": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3073",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3073"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3074": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3074",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3074"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3075": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3075",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3075"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3076": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3076",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3076"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3077": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3077",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3077"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3078": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3078",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3078"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3079": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3079",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3079"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3080": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3080",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3080"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3081": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3081",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3081"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3082": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3082",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3082"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3083": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3083",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3083"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3084": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3084",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3084"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3085": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3085",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3085"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3086": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3086",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3086"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3087": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3087",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3087"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3088": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3088",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3088"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3089": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3089",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3089"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3090": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3090",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3090"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3091": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3091",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3091"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3092": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3092",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3092"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3093": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3093",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3093"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3094": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3094",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3094"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3095": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3095",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3095"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3096": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3096",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3096"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3097": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3097",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3097"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3098": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3098",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3098"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3099": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3099",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3099"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3100": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3100",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3100"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3101": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3101",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3101"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3102": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3102",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3102"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3103": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3103",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3103"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3104": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3104",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3104"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3105": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3105",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3105"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3106": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3106",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3106"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3107": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3107",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3107"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3108": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3108",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3108"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3109": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3109",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3109"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3110": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3110",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3110"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3111": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3111",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3111"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3112": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3112",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3112"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3113": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3113",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3113"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3114": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3114",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3114"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3115": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3115",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3115"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3116": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3116",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3116"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3117": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3117",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3117"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3118": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3118",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3118"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3119": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3119",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3119"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3120": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3120",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3120"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3121": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3121",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3121"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3122": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3122",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3122"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3123": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3123",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3123"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3124": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3124",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3124"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3125": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3125",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3125"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3126": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3126",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3126"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3127": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3127",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3127"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3128": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3128",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3128"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3129": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3129",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3129"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3130": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3130",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3130"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3131": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3131",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3131"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3132": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3132",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3132"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3133": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3133",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3133"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3134": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3134",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3134"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3135": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3135",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3135"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3136": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3136",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3136"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3137": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3137",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3137"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3138": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3138",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3138"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3139": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3139",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3139"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3140": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3140",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3140"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3141": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3141",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3141"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3142": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3142",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3142"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3143": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3143",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3143"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3144": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3144",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3144"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3145": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3145",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3145"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3146": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3146",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3146"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3147": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3147",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3147"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3148": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3148",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3148"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3149": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3149",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3149"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3150": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3150",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3150"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3151": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3151",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3151"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3152": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3152",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3152"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3153": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3153",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3153"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3154": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3154",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3154"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3155": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3155",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3155"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3156": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3156",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3156"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3157": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3157",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3157"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3158": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3158",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3158"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3159": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3159",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3159"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3160": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3160",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3160"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3161": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3161",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3161"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3162": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3162",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3162"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3163": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3163",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3163"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3164": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3164",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3164"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3165": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3165",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3165"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3166": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3166",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3166"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3167": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3167",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3167"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3168": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3168",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3168"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3169": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3169",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3169"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3170": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3170",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3170"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3171": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3171",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3171"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3172": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3172",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3172"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3173": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3173",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3173"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3174": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3174",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3174"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3175": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3175",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3175"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3176": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3176",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3176"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3177": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3177",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3177"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3178": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3178",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3178"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3179": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3179",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3179"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3180": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3180",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3180"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3181": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3181",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3181"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3182": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3182",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3182"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3183": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3183",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3183"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3184": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3184",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3184"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3185": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3185",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3185"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3186": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3186",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3186"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3187": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3187",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3187"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3188": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3188",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3188"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3189": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3189",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3189"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3190": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3190",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3190"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3191": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3191",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3191"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3192": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3192",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3192"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3193": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3193",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3193"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3194": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3194",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3194"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3195": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3195",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3195"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3196": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3196",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3196"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3197": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3197",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3197"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3198": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3198",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3198"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3199": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3199",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3199"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3200": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3200",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3200"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3201": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3201",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3201"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3202": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3202",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3202"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3203": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3203",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3203"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3204": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3204",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3204"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3205": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3205",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3205"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3206": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3206",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3206"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3207": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3207",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3207"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3208": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3208",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3208"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3209": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3209",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3209"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3210": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3210",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3210"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3211": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3211",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3211"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3212": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3212",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3212"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3213": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3213",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3213"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3214": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3214",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3214"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3215": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3215",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3215"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3216": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3216",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3216"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3217": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3217",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3217"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3218": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3218",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3218"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3219": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3219",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3219"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3220": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3220",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3220"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3221": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3221",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3221"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3222": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3222",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3222"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3223": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3223",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3223"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3224": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3224",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3224"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3225": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3225",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3225"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3226": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3226",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3226"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3227": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3227",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3227"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3228": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3228",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3228"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3229": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3229",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3229"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3230": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3230",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3230"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3231": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3231",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3231"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3232": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3232",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3232"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3233": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3233",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3233"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3234": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3234",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3234"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3235": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3235",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3235"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3236": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3236",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3236"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3237": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3237",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3237"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3238": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3238",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3238"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3239": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3239",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3239"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3240": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3240",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3240"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3241": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3241",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3241"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3242": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3242",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3242"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3243": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3243",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3243"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3244": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3244",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3244"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3245": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3245",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3245"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3246": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3246",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3246"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3247": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3247",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3247"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3248": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3248",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3248"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3249": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3249",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3249"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3250": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3250",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3250"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3251": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3251",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3251"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3252": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3252",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3252"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3253": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3253",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3253"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3254": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3254",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3254"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3255": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3255",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3255"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3256": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3256",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3256"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3257": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3257",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3257"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3258": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3258",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3258"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3259": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3259",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3259"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3260": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3260",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3260"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3261": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3261",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3261"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3262": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3262",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3262"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3263": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3263",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3263"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3264": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3264",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3264"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3265": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3265",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3265"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3266": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3266",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3266"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3267": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3267",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3267"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3268": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3268",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3268"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3269": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3269",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3269"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3270": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3270",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3270"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3271": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3271",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3271"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3272": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3272",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3272"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3273": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3273",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3273"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3274": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3274",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3274"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3275": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3275",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3275"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3276": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3276",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3276"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3277": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3277",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3277"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3278": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3278",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3278"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3279": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3279",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3279"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3280": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3280",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3280"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3281": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3281",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3281"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3282": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3282",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3282"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3283": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3283",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3283"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3284": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3284",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3284"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3285": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3285",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3285"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3286": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3286",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3286"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3287": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3287",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3287"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3288": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3288",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3288"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3289": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3289",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3289"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3290": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3290",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3290"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3291": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3291",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3291"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3292": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3292",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3292"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3293": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3293",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3293"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3294": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3294",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3294"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3295": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3295",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3295"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3296": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3296",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3296"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3297": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3297",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3297"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3298": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3298",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3298"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3299": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3299",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3299"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3300": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3300",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3300"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3301": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3301",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3301"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3302": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3302",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3302"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3303": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3303",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3303"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3304": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3304",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3304"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3305": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3305",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3305"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3306": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3306",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3306"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3307": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3307",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3307"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3308": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3308",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3308"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3309": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3309",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3309"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3310": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3310",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3310"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3311": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3311",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3311"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3312": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3312",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3312"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3313": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3313",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3313"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3314": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3314",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3314"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3315": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3315",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3315"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3316": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3316",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3316"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3317": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3317",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3317"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3318": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3318",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3318"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3319": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3319",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3319"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3320": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3320",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3320"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3321": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3321",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3321"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3322": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3322",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3322"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3323": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3323",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3323"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3324": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3324",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3324"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3325": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3325",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3325"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3326": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3326",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3326"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3327": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3327",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3327"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3328": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3328",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3328"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3329": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3329",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3329"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3330": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3330",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3330"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3331": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3331",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3331"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3332": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3332",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3332"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3333": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3333",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3333"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3334": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3334",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3334"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3335": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3335",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3335"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3336": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3336",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3336"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3337": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3337",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3337"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3338": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3338",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3338"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3339": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3339",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3339"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3340": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3340",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3340"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3341": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3341",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3341"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3342": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3342",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3342"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3343": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3343",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3343"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3344": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3344",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3344"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3345": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3345",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3345"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3346": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3346",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3346"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3347": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3347",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3347"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3348": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3348",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3348"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3349": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3349",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3349"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3350": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3350",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3350"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3351": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3351",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3351"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3352": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3352",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3352"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3353": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3353",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3353"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3354": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3354",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3354"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3355": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3355",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3355"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3356": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3356",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3356"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3357": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3357",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3357"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3358": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3358",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3358"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3359": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3359",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3359"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3360": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3360",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3360"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3361": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3361",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3361"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3362": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3362",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3362"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3363": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3363",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3363"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3364": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3364",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3364"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3365": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3365",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3365"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3366": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3366",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3366"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3367": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3367",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3367"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3368": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3368",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3368"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3369": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3369",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3369"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3370": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3370",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3370"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3371": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3371",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3371"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3372": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3372",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3372"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3373": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3373",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3373"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3374": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3374",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3374"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3375": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3375",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3375"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3376": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3376",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3376"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3377": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3377",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3377"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3378": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3378",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3378"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3379": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3379",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3379"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3380": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3380",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3380"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3381": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3381",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3381"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3382": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3382",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3382"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3383": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3383",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3383"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3384": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3384",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3384"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3385": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3385",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3385"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3386": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3386",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3386"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3387": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3387",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3387"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3388": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3388",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3388"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3389": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3389",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3389"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3390": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3390",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3390"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3391": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3391",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3391"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3392": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3392",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3392"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3393": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3393",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3393"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3394": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3394",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3394"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3395": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3395",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3395"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3396": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3396",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3396"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3397": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3397",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3397"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3398": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3398",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3398"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3399": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3399",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3399"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3400": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3400",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3400"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3401": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3401",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3401"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3402": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3402",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3402"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3403": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3403",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3403"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3404": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3404",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3404"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3405": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3405",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3405"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3406": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3406",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3406"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3407": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3407",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3407"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3408": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3408",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3408"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3409": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3409",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3409"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3410": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3410",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3410"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3411": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3411",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3411"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3412": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3412",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3412"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3413": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3413",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3413"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3414": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3414",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3414"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3415": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3415",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3415"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3416": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3416",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3416"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3417": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3417",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3417"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3418": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3418",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3418"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3419": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3419",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3419"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3420": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3420",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3420"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3421": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3421",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3421"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3422": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3422",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3422"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3423": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3423",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3423"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3424": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3424",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3424"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3425": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3425",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3425"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3426": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3426",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3426"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3427": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3427",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3427"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3428": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3428",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3428"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3429": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3429",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3429"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3430": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3430",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3430"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3431": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3431",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3431"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3432": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3432",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3432"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3433": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3433",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3433"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3434": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3434",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3434"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3435": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3435",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3435"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3436": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3436",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3436"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3437": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3437",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3437"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3438": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3438",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3438"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3439": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3439",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3439"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3440": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3440",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3440"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3441": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3441",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3441"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3442": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3442",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3442"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3443": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3443",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3443"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3444": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3444",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3444"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3445": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3445",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3445"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3446": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3446",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3446"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3447": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3447",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3447"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3448": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3448",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3448"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3449": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3449",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3449"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3450": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3450",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3450"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3451": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3451",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3451"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3452": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3452",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3452"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3453": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3453",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3453"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3454": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3454",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3454"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3455": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3455",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3455"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3456": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3456",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3456"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3457": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3457",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3457"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3458": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3458",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3458"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3459": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3459",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3459"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3460": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3460",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3460"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3461": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3461",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3461"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3462": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3462",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3462"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3463": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3463",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3463"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3464": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3464",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3464"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3465": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3465",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3465"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3466": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3466",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3466"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3467": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3467",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3467"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3468": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3468",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3468"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3469": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3469",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3469"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3470": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3470",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3470"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3471": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3471",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3471"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3472": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3472",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3472"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3473": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3473",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3473"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3474": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3474",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3474"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3475": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3475",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3475"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3476": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3476",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3476"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3477": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3477",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3477"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3478": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3478",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3478"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3479": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3479",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3479"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3480": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3480",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3480"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3481": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3481",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3481"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3482": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3482",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3482"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3483": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3483",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3483"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3484": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3484",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3484"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3485": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3485",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3485"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3486": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3486",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3486"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3487": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3487",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3487"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3488": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3488",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3488"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3489": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3489",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3489"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3490": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3490",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3490"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3491": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3491",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3491"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3492": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3492",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3492"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3493": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3493",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3493"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3494": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3494",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3494"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3495": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3495",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3495"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3496": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3496",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3496"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3497": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3497",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3497"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3498": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3498",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3498"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3499": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3499",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3499"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3500": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3500",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3500"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3501": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3501",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3501"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3502": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3502",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3502"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3503": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3503",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3503"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3504": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3504",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3504"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3505": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3505",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3505"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3506": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3506",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3506"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3507": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3507",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3507"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3508": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3508",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3508"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3509": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3509",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3509"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3510": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3510",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3510"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3511": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3511",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3511"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3512": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3512",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3512"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3513": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3513",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3513"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3514": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3514",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3514"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3515": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3515",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3515"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3516": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3516",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3516"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3517": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3517",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3517"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3518": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3518",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3518"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3519": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3519",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3519"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3520": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3520",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3520"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3521": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3521",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3521"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3522": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3522",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3522"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3523": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3523",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3523"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3524": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3524",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3524"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3525": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3525",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3525"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3526": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3526",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3526"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3527": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3527",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3527"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3528": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3528",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3528"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3529": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3529",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3529"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3530": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3530",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3530"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3531": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3531",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3531"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3532": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3532",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3532"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3533": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3533",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3533"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3534": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3534",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3534"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3535": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3535",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3535"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3536": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3536",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3536"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3537": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3537",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3537"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3538": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3538",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3538"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3539": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3539",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3539"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3540": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3540",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3540"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3541": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3541",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3541"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3542": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3542",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3542"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3543": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3543",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3543"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3544": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3544",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3544"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3545": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3545",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3545"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3546": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3546",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3546"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3547": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3547",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3547"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3548": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3548",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3548"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3549": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3549",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3549"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3550": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3550",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3550"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3551": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3551",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3551"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3552": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3552",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3552"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3553": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3553",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3553"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3554": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3554",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3554"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3555": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3555",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3555"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3556": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3556",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3556"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3557": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3557",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3557"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3558": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3558",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3558"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3559": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3559",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3559"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3560": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3560",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3560"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3561": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3561",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3561"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3562": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3562",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3562"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3563": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3563",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3563"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3564": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3564",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3564"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3565": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3565",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3565"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3566": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3566",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3566"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3567": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3567",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3567"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3568": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3568",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3568"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3569": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3569",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3569"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3570": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3570",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3570"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3571": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3571",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3571"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3572": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3572",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3572"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3573": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3573",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3573"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3574": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3574",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3574"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3575": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3575",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3575"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3576": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3576",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3576"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3577": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3577",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3577"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3578": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3578",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3578"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3579": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3579",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3579"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3580": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3580",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3580"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3581": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3581",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3581"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3582": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3582",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3582"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3583": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3583",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3583"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3584": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3584",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3584"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3585": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3585",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3585"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3586": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3586",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3586"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3587": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3587",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3587"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3588": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3588",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3588"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3589": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3589",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3589"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3590": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3590",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3590"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3591": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3591",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3591"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3592": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3592",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3592"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3593": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3593",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3593"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3594": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3594",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3594"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3595": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3595",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3595"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3596": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3596",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3596"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3597": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3597",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3597"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3598": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3598",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3598"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3599": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3599",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3599"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3600": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3600",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3600"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3601": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3601",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3601"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3602": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3602",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3602"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3603": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3603",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3603"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3604": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3604",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3604"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3605": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3605",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3605"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3606": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3606",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3606"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3607": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3607",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3607"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3608": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3608",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3608"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3609": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3609",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3609"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3610": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3610",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3610"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3611": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3611",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3611"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3612": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3612",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3612"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3613": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3613",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3613"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3614": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3614",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3614"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3615": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3615",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3615"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3616": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3616",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3616"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3617": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3617",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3617"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3618": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3618",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3618"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3619": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3619",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3619"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3620": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3620",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3620"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3621": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3621",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3621"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3622": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3622",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3622"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3623": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3623",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3623"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3624": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3624",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3624"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3625": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3625",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3625"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3626": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3626",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3626"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3627": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3627",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3627"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3628": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3628",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3628"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3629": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3629",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3629"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3630": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3630",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3630"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3631": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3631",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3631"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3632": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3632",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3632"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3633": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3633",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3633"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3634": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3634",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3634"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3635": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3635",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3635"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3636": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3636",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3636"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3637": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3637",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3637"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3638": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3638",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3638"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3639": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3639",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3639"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3640": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3640",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3640"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3641": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3641",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3641"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3642": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3642",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3642"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3643": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3643",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3643"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3644": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3644",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3644"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3645": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3645",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3645"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3646": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3646",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3646"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3647": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3647",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3647"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3648": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3648",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3648"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3649": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3649",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3649"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3650": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3650",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3650"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3651": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3651",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3651"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3652": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3652",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3652"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3653": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3653",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3653"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3654": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3654",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3654"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3655": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3655",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3655"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3656": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3656",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3656"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3657": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3657",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3657"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3658": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3658",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3658"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3659": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3659",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3659"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3660": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3660",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3660"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3661": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3661",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3661"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3662": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3662",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3662"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3663": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3663",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3663"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3664": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3664",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3664"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3665": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3665",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3665"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3666": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3666",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3666"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3667": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3667",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3667"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3668": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3668",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3668"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3669": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3669",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3669"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3670": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3670",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3670"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3671": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3671",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3671"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3672": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3672",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3672"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3673": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3673",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3673"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3674": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3674",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3674"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3675": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3675",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3675"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3676": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3676",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3676"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3677": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3677",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3677"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3678": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3678",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3678"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3679": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3679",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3679"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3680": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3680",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3680"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3681": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3681",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3681"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3682": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3682",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3682"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3683": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3683",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3683"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3684": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3684",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3684"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3685": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3685",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3685"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3686": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3686",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3686"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3687": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3687",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3687"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3688": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3688",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3688"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3689": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3689",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3689"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3690": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3690",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3690"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3691": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3691",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3691"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3692": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3692",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3692"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3693": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3693",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3693"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3694": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3694",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3694"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3695": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3695",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3695"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3696": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3696",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3696"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3697": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3697",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3697"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3698": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3698",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3698"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3699": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3699",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3699"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3700": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3700",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3700"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3701": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3701",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3701"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3702": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3702",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3702"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3703": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3703",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3703"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3704": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3704",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3704"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3705": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3705",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3705"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3706": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3706",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3706"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3707": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3707",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3707"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3708": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3708",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3708"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3709": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3709",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3709"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3710": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3710",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3710"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3711": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3711",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3711"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3712": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3712",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3712"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3713": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3713",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3713"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3714": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3714",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3714"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3715": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3715",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3715"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3716": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3716",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3716"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3717": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3717",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3717"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3718": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3718",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3718"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3719": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3719",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3719"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3720": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3720",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3720"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3721": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3721",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3721"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3722": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3722",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3722"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3723": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3723",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3723"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3724": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3724",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3724"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3725": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3725",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3725"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3726": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3726",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3726"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3727": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3727",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3727"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3728": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3728",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3728"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3729": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3729",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3729"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3730": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3730",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3730"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3731": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3731",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3731"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3732": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3732",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3732"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3733": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3733",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3733"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3734": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3734",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3734"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3735": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3735",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3735"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3736": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3736",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3736"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3737": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3737",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3737"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3738": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3738",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3738"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3739": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3739",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3739"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3740": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3740",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3740"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3741": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3741",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3741"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3742": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3742",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3742"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3743": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3743",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3743"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3744": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3744",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3744"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3745": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3745",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3745"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3746": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3746",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3746"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3747": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3747",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3747"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3748": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3748",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3748"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3749": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3749",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3749"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3750": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3750",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3750"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3751": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3751",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3751"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3752": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3752",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3752"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3753": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3753",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3753"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3754": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3754",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3754"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3755": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3755",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3755"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3756": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3756",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3756"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3757": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3757",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3757"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3758": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3758",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3758"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3759": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3759",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3759"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3760": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3760",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3760"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3761": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3761",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3761"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3762": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3762",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3762"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3763": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3763",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3763"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3764": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3764",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3764"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3765": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3765",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3765"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3766": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3766",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3766"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3767": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3767",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3767"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3768": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3768",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3768"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3769": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3769",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3769"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3770": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3770",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3770"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3771": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3771",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3771"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3772": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3772",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3772"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3773": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3773",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3773"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3774": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3774",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3774"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3775": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3775",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3775"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3776": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3776",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3776"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3777": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3777",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3777"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3778": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3778",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3778"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3779": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3779",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3779"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3780": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3780",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3780"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3781": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3781",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3781"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3782": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3782",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3782"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3783": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3783",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3783"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3784": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3784",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3784"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3785": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3785",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3785"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3786": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3786",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3786"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3787": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3787",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3787"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3788": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3788",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3788"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3789": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3789",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3789"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3790": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3790",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3790"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3791": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3791",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3791"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3792": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3792",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3792"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3793": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3793",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3793"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3794": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3794",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3794"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3795": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3795",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3795"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3796": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3796",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3796"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3797": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3797",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3797"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3798": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3798",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3798"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3799": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3799",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3799"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3800": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3800",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3800"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3801": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3801",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3801"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3802": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3802",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3802"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3803": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3803",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3803"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3804": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3804",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3804"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3805": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3805",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3805"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3806": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3806",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3806"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3807": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3807",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3807"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3808": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3808",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3808"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3809": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3809",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3809"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3810": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3810",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3810"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3811": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3811",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3811"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3812": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3812",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3812"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3813": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3813",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3813"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3814": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3814",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3814"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3815": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3815",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3815"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3816": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3816",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3816"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3817": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3817",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3817"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3818": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3818",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3818"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3819": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3819",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3819"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3820": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3820",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3820"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3821": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3821",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3821"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3822": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3822",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3822"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3823": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3823",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3823"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3824": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3824",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3824"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3825": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3825",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3825"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3826": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3826",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3826"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3827": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3827",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3827"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3828": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3828",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3828"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3829": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3829",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3829"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3830": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3830",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3830"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3831": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3831",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3831"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3832": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3832",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3832"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3833": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3833",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3833"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3834": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3834",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3834"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3835": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3835",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3835"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3836": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3836",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3836"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3837": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3837",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3837"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3838": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3838",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3838"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3839": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3839",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3839"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3840": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3840",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3840"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3841": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3841",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3841"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3842": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3842",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3842"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3843": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3843",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3843"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3844": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3844",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3844"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3845": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3845",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3845"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3846": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3846",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3846"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3847": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3847",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3847"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3848": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3848",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3848"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3849": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3849",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3849"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3850": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3850",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3850"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3851": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3851",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3851"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3852": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3852",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3852"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3853": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3853",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3853"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3854": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3854",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3854"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3855": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3855",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3855"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3856": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3856",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3856"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3857": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3857",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3857"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3858": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3858",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3858"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3859": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3859",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3859"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3860": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3860",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3860"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3861": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3861",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3861"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3862": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3862",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3862"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3863": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3863",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3863"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3864": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3864",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3864"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3865": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3865",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3865"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3866": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3866",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3866"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3867": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3867",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3867"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3868": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3868",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3868"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3869": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3869",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3869"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3870": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3870",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3870"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3871": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3871",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3871"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3872": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3872",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3872"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3873": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3873",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3873"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3874": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3874",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3874"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3875": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3875",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3875"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3876": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3876",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3876"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3877": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3877",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3877"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3878": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3878",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3878"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3879": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3879",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3879"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3880": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3880",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3880"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3881": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3881",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3881"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3882": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3882",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3882"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3883": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3883",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3883"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3884": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3884",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3884"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3885": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3885",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3885"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3886": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3886",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3886"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3887": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3887",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3887"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3888": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3888",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3888"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3889": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3889",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3889"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3890": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3890",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3890"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3891": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3891",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3891"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3892": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3892",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3892"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3893": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3893",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3893"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3894": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3894",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3894"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3895": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3895",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3895"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3896": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3896",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3896"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3897": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3897",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3897"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3898": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3898",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3898"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3899": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3899",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3899"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3900": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3900",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3900"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3901": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3901",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3901"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3902": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3902",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3902"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3903": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3903",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3903"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3904": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3904",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3904"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3905": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3905",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3905"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3906": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3906",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3906"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3907": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3907",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3907"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3908": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3908",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3908"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3909": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3909",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3909"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3910": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3910",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3910"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3911": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3911",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3911"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3912": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3912",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3912"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3913": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3913",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3913"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3914": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3914",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3914"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3915": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3915",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3915"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3916": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3916",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3916"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3917": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3917",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3917"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3918": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3918",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3918"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3919": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3919",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3919"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3920": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3920",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3920"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3921": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3921",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3921"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3922": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3922",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3922"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3923": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3923",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3923"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3924": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3924",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3924"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3925": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3925",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3925"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3926": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3926",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3926"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3927": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3927",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3927"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3928": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3928",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3928"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3929": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3929",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3929"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3930": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3930",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3930"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3931": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3931",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3931"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3932": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3932",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3932"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3933": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3933",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3933"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3934": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3934",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3934"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3935": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3935",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3935"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3936": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3936",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3936"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3937": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3937",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3937"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3938": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3938",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3938"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3939": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3939",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3939"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3940": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3940",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3940"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3941": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3941",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3941"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3942": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3942",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3942"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3943": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3943",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3943"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3944": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3944",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3944"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3945": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3945",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3945"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3946": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3946",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3946"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3947": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3947",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3947"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3948": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3948",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3948"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3949": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3949",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3949"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3950": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3950",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3950"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3951": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3951",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3951"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3952": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3952",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3952"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3953": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3953",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3953"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3954": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3954",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3954"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3955": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3955",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3955"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3956": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3956",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3956"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3957": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3957",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3957"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3958": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3958",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3958"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3959": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3959",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3959"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3960": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3960",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3960"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3961": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3961",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3961"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3962": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3962",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3962"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3963": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3963",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3963"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3964": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3964",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3964"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3965": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3965",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3965"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3966": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3966",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3966"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3967": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3967",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3967"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3968": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3968",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3968"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3969": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3969",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3969"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3970": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3970",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3970"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3971": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3971",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3971"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3972": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3972",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3972"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3973": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3973",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3973"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3974": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3974",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3974"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3975": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3975",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3975"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3976": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3976",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3976"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3977": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3977",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3977"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3978": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3978",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3978"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3979": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3979",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3979"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3980": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3980",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3980"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3981": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3981",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3981"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3982": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3982",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3982"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3983": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3983",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3983"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3984": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3984",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3984"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3985": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3985",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3985"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3986": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3986",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3986"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3987": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3987",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3987"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3988": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3988",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3988"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3989": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3989",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3989"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3990": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3990",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3990"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3991": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3991",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3991"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3992": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3992",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3992"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3993": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3993",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3993"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3994": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3994",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3994"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3995": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3995",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3995"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3996": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3996",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3996"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3997": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3997",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3997"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3998": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #3998",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "3998"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "3999": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #3999",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "3999"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4000": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4000",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4000"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4001": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4001",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4001"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4002": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4002",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4002"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4003": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4003",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4003"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4004": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4004",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4004"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4005": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4005",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4005"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4006": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4006",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4006"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4007": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4007",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4007"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4008": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4008",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4008"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4009": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4009",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4009"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4010": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4010",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4010"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4011": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4011",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4011"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4012": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4012",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4012"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4013": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4013",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4013"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4014": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4014",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4014"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4015": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4015",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4015"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4016": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4016",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4016"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4017": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4017",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4017"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4018": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4018",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4018"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4019": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4019",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4019"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4020": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4020",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4020"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4021": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4021",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4021"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4022": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4022",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4022"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4023": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4023",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4023"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4024": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4024",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4024"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4025": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4025",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4025"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4026": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4026",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4026"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4027": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4027",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4027"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4028": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4028",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4028"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4029": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4029",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4029"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4030": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4030",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4030"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4031": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4031",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4031"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4032": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4032",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4032"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4033": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4033",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4033"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4034": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4034",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4034"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4035": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4035",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4035"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4036": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4036",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4036"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4037": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4037",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4037"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4038": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4038",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4038"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4039": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4039",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4039"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4040": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4040",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4040"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4041": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4041",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4041"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4042": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4042",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4042"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4043": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4043",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4043"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4044": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4044",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4044"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4045": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4045",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4045"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4046": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4046",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4046"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4047": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4047",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4047"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4048": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4048",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4048"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4049": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4049",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4049"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4050": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4050",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4050"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4051": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4051",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4051"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4052": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4052",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4052"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4053": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4053",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4053"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4054": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4054",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4054"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4055": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4055",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4055"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4056": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4056",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4056"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4057": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4057",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4057"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4058": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4058",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4058"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4059": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4059",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4059"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4060": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4060",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4060"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4061": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4061",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4061"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4062": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4062",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4062"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4063": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4063",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4063"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4064": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4064",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4064"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4065": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4065",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4065"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4066": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4066",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4066"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4067": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4067",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4067"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4068": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4068",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4068"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4069": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4069",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4069"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4070": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4070",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4070"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4071": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4071",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4071"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4072": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4072",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4072"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4073": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4073",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4073"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4074": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4074",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4074"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4075": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4075",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4075"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4076": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4076",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4076"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4077": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4077",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4077"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4078": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4078",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4078"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4079": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4079",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4079"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4080": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4080",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4080"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4081": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4081",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4081"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4082": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4082",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4082"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4083": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4083",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4083"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4084": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4084",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4084"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4085": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4085",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4085"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4086": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4086",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4086"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4087": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4087",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4087"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4088": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4088",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4088"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4089": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4089",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4089"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4090": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4090",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4090"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4091": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4091",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4091"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4092": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4092",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4092"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4093": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4093",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4093"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4094": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4094",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4094"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4095": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4095",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4095"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4096": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4096",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4096"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4097": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4097",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4097"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4098": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4098",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4098"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4099": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4099",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4099"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4100": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4100",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4100"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4101": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4101",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4101"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4102": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4102",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4102"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4103": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4103",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4103"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4104": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4104",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4104"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4105": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4105",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4105"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4106": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4106",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4106"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4107": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4107",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4107"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4108": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4108",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4108"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4109": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4109",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4109"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4110": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4110",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4110"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4111": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4111",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4111"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4112": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4112",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4112"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4113": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4113",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4113"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4114": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4114",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4114"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4115": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4115",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4115"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4116": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4116",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4116"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4117": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4117",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4117"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4118": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4118",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4118"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4119": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4119",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4119"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4120": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4120",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4120"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4121": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4121",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4121"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4122": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4122",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4122"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4123": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4123",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4123"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4124": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4124",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4124"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4125": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4125",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4125"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4126": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4126",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4126"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4127": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4127",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4127"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4128": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4128",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4128"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4129": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4129",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4129"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4130": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4130",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4130"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4131": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4131",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4131"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4132": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4132",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4132"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4133": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4133",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4133"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4134": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4134",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4134"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4135": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4135",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4135"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4136": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4136",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4136"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4137": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4137",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4137"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4138": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4138",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4138"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4139": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4139",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4139"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4140": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4140",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4140"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4141": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4141",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4141"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4142": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4142",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4142"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4143": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4143",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4143"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4144": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4144",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4144"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4145": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4145",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4145"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4146": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4146",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4146"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4147": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4147",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4147"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4148": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4148",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4148"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4149": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4149",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4149"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4150": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4150",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4150"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4151": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4151",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4151"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4152": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4152",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4152"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4153": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4153",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4153"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4154": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4154",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4154"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4155": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4155",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4155"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4156": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4156",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4156"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4157": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4157",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4157"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4158": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4158",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4158"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4159": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4159",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4159"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4160": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4160",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4160"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4161": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4161",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4161"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4162": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4162",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4162"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4163": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4163",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4163"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4164": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4164",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4164"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4165": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4165",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4165"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4166": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4166",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4166"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4167": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4167",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4167"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4168": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4168",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4168"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4169": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4169",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4169"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4170": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4170",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4170"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4171": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4171",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4171"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4172": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4172",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4172"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4173": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4173",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4173"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4174": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4174",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4174"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4175": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4175",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4175"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4176": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4176",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4176"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4177": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4177",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4177"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4178": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4178",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4178"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4179": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4179",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4179"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4180": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4180",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4180"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4181": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4181",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4181"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4182": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4182",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4182"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4183": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4183",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4183"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4184": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4184",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4184"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4185": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4185",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4185"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4186": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4186",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4186"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4187": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4187",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4187"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4188": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4188",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4188"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4189": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4189",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4189"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4190": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4190",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4190"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4191": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4191",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4191"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4192": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4192",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4192"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4193": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4193",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4193"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4194": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4194",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4194"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4195": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4195",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4195"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4196": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4196",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4196"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4197": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4197",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4197"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4198": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4198",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4198"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4199": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4199",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4199"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4200": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4200",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4200"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4201": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4201",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4201"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4202": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4202",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4202"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4203": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4203",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4203"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4204": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4204",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4204"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4205": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4205",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4205"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4206": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4206",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4206"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4207": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4207",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4207"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4208": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4208",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4208"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4209": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4209",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4209"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4210": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4210",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4210"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4211": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4211",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4211"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4212": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4212",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4212"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4213": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4213",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4213"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4214": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4214",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4214"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4215": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4215",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4215"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4216": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4216",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4216"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4217": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4217",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4217"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4218": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4218",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4218"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4219": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4219",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4219"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4220": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4220",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4220"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4221": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4221",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4221"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4222": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4222",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4222"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4223": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4223",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4223"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4224": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4224",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4224"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4225": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4225",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4225"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4226": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4226",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4226"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4227": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4227",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4227"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4228": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4228",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4228"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4229": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4229",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4229"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4230": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4230",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4230"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4231": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4231",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4231"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4232": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4232",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4232"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4233": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4233",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4233"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4234": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4234",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4234"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4235": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4235",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4235"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4236": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4236",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4236"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4237": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4237",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4237"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4238": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4238",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4238"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4239": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4239",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4239"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4240": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4240",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4240"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4241": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4241",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4241"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4242": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4242",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4242"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4243": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4243",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4243"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4244": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4244",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4244"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4245": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4245",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4245"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4246": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4246",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4246"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4247": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4247",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4247"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4248": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4248",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4248"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4249": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4249",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4249"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4250": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4250",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4250"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4251": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4251",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4251"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4252": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4252",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4252"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4253": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4253",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4253"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4254": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4254",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4254"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4255": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4255",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4255"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4256": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4256",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4256"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4257": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4257",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4257"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4258": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4258",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4258"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4259": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4259",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4259"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4260": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4260",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4260"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4261": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4261",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4261"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4262": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4262",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4262"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4263": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4263",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4263"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4264": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4264",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4264"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4265": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4265",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4265"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4266": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4266",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4266"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4267": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4267",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4267"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4268": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4268",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4268"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4269": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4269",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4269"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4270": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4270",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4270"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4271": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4271",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4271"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4272": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4272",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4272"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4273": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4273",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4273"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4274": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4274",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4274"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4275": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4275",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4275"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4276": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4276",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4276"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4277": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4277",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4277"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4278": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4278",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4278"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4279": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4279",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4279"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4280": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4280",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4280"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4281": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4281",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4281"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4282": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4282",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4282"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4283": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4283",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4283"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4284": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4284",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4284"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4285": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4285",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4285"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4286": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4286",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4286"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4287": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4287",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4287"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4288": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4288",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4288"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4289": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4289",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4289"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4290": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4290",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4290"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4291": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4291",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4291"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4292": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4292",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4292"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4293": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4293",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4293"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4294": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4294",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4294"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4295": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4295",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4295"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4296": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4296",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4296"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4297": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4297",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4297"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4298": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4298",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4298"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4299": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4299",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4299"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4300": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4300",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4300"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4301": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4301",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4301"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4302": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4302",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4302"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4303": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4303",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4303"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4304": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4304",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4304"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4305": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4305",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4305"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4306": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4306",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4306"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4307": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4307",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4307"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4308": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4308",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4308"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4309": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4309",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4309"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4310": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4310",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4310"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4311": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4311",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4311"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4312": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4312",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4312"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4313": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4313",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4313"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4314": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4314",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4314"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4315": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4315",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4315"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4316": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4316",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4316"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4317": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4317",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4317"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4318": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4318",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4318"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4319": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4319",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4319"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4320": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4320",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4320"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4321": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4321",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4321"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4322": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4322",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4322"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4323": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4323",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4323"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4324": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4324",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4324"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4325": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4325",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4325"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4326": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4326",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4326"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4327": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4327",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4327"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4328": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4328",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4328"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4329": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4329",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4329"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4330": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4330",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4330"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4331": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4331",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4331"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4332": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4332",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4332"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4333": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4333",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4333"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4334": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4334",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4334"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4335": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4335",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4335"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4336": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4336",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4336"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4337": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4337",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4337"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4338": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4338",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4338"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4339": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4339",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4339"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4340": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4340",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4340"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4341": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4341",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4341"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4342": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4342",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4342"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4343": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4343",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4343"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4344": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4344",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4344"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4345": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4345",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4345"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4346": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4346",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4346"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4347": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4347",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4347"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4348": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4348",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4348"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4349": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4349",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4349"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4350": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4350",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4350"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4351": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4351",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4351"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4352": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4352",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4352"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4353": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4353",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4353"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4354": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4354",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4354"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4355": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4355",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4355"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4356": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4356",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4356"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4357": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4357",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4357"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4358": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4358",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4358"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4359": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4359",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4359"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4360": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4360",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4360"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4361": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4361",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4361"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4362": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4362",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4362"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4363": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4363",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4363"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4364": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4364",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4364"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4365": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4365",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4365"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4366": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4366",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4366"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4367": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4367",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4367"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4368": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4368",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4368"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4369": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4369",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4369"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4370": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4370",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4370"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4371": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4371",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4371"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4372": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4372",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4372"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4373": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4373",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4373"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4374": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4374",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4374"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4375": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4375",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4375"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4376": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4376",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4376"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4377": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4377",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4377"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4378": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4378",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4378"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4379": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4379",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4379"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4380": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4380",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4380"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4381": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4381",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4381"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4382": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4382",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4382"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4383": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4383",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4383"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4384": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4384",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4384"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4385": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4385",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4385"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4386": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4386",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4386"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4387": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4387",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4387"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4388": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4388",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4388"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4389": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4389",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4389"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4390": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4390",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4390"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4391": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4391",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4391"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4392": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4392",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4392"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4393": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4393",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4393"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4394": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4394",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4394"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4395": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4395",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4395"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4396": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4396",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4396"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4397": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4397",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4397"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4398": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4398",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4398"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4399": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4399",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4399"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4400": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4400",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4400"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4401": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4401",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4401"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4402": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4402",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4402"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4403": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4403",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4403"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4404": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4404",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4404"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4405": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4405",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4405"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4406": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4406",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4406"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4407": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4407",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4407"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4408": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4408",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4408"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4409": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4409",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4409"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4410": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4410",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4410"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4411": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4411",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4411"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4412": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4412",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4412"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4413": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4413",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4413"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4414": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4414",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4414"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4415": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4415",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4415"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4416": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4416",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4416"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4417": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4417",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4417"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4418": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4418",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4418"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4419": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4419",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4419"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4420": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4420",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4420"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4421": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4421",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4421"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4422": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4422",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4422"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4423": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4423",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4423"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4424": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4424",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4424"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4425": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4425",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4425"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4426": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4426",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4426"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4427": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4427",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4427"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4428": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4428",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4428"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4429": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4429",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4429"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4430": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4430",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4430"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4431": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4431",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4431"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4432": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4432",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4432"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4433": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4433",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4433"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4434": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4434",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4434"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4435": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4435",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4435"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4436": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4436",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4436"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4437": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4437",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4437"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4438": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4438",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4438"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4439": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4439",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4439"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4440": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4440",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4440"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4441": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4441",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4441"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4442": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4442",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4442"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4443": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4443",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4443"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4444": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4444",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4444"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4445": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4445",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4445"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4446": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4446",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4446"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4447": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4447",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4447"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4448": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4448",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4448"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4449": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4449",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4449"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4450": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4450",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4450"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4451": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4451",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4451"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4452": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4452",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4452"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4453": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4453",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4453"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4454": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4454",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4454"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4455": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4455",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4455"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4456": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4456",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4456"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4457": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4457",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4457"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4458": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4458",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4458"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4459": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4459",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4459"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4460": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4460",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4460"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4461": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4461",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4461"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4462": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4462",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4462"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4463": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4463",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4463"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4464": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4464",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4464"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4465": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4465",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4465"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4466": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4466",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4466"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4467": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4467",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4467"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4468": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4468",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4468"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4469": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4469",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4469"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4470": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4470",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4470"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4471": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4471",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4471"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4472": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4472",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4472"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4473": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4473",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4473"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4474": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4474",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4474"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4475": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4475",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4475"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4476": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4476",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4476"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4477": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4477",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4477"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4478": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4478",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4478"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4479": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4479",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4479"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4480": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4480",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4480"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4481": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4481",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4481"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4482": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4482",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4482"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4483": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4483",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4483"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4484": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4484",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4484"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4485": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4485",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4485"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4486": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4486",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4486"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4487": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4487",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4487"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4488": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4488",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4488"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4489": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4489",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4489"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4490": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4490",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4490"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4491": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4491",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4491"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4492": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4492",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4492"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4493": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4493",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4493"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4494": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4494",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4494"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4495": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4495",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4495"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4496": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4496",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4496"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4497": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4497",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4497"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4498": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4498",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4498"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4499": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4499",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4499"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4500": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4500",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4500"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4501": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4501",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4501"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4502": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4502",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4502"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4503": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4503",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4503"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4504": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4504",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4504"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4505": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4505",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4505"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4506": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4506",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4506"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4507": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4507",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4507"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4508": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4508",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4508"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4509": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4509",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4509"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4510": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4510",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4510"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4511": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4511",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4511"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4512": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4512",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4512"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4513": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4513",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4513"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4514": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4514",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4514"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4515": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4515",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4515"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4516": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4516",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4516"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4517": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4517",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4517"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4518": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4518",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4518"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4519": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4519",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4519"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4520": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4520",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4520"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4521": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4521",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4521"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4522": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4522",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4522"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4523": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4523",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4523"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4524": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4524",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4524"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4525": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4525",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4525"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4526": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4526",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4526"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4527": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4527",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4527"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4528": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4528",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4528"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4529": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4529",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4529"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4530": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4530",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4530"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4531": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4531",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4531"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4532": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4532",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4532"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4533": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4533",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4533"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4534": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4534",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4534"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4535": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4535",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4535"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4536": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4536",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4536"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4537": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4537",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4537"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4538": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4538",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4538"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4539": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4539",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4539"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4540": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4540",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4540"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4541": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4541",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4541"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4542": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4542",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4542"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4543": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4543",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4543"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4544": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4544",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4544"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4545": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4545",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4545"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4546": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4546",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4546"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4547": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4547",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4547"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4548": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4548",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4548"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4549": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4549",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4549"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4550": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4550",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4550"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4551": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4551",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4551"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4552": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4552",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4552"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4553": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4553",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4553"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4554": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4554",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4554"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4555": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4555",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4555"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4556": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4556",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4556"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4557": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4557",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4557"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4558": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4558",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4558"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4559": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4559",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4559"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4560": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4560",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4560"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4561": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4561",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4561"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4562": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4562",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4562"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4563": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4563",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4563"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4564": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4564",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4564"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4565": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4565",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4565"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4566": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4566",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4566"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4567": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4567",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4567"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4568": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4568",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4568"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4569": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4569",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4569"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4570": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4570",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4570"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4571": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4571",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4571"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4572": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4572",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4572"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4573": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4573",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4573"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4574": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4574",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4574"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4575": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4575",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4575"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4576": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4576",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4576"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4577": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4577",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4577"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4578": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4578",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4578"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4579": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4579",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4579"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4580": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4580",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4580"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4581": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4581",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4581"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4582": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4582",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4582"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4583": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4583",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4583"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4584": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4584",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4584"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4585": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4585",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4585"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4586": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4586",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4586"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4587": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4587",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4587"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4588": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4588",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4588"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4589": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4589",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4589"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4590": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4590",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4590"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4591": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4591",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4591"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4592": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4592",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4592"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4593": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4593",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4593"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4594": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4594",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4594"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4595": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4595",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4595"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4596": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4596",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4596"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4597": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4597",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4597"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4598": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4598",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4598"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4599": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4599",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4599"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4600": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4600",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4600"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4601": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4601",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4601"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4602": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4602",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4602"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4603": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4603",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4603"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4604": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4604",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4604"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4605": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4605",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4605"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4606": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4606",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4606"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4607": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4607",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4607"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4608": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4608",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4608"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4609": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4609",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4609"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4610": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4610",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4610"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4611": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4611",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4611"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4612": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4612",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4612"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4613": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4613",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4613"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4614": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4614",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4614"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4615": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4615",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4615"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4616": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4616",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4616"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4617": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4617",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4617"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4618": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4618",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4618"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4619": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4619",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4619"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4620": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4620",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4620"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4621": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4621",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4621"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4622": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4622",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4622"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4623": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4623",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4623"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4624": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4624",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4624"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4625": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4625",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4625"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4626": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4626",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4626"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4627": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4627",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4627"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4628": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4628",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4628"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4629": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4629",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4629"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4630": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4630",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4630"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4631": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4631",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4631"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4632": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4632",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4632"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4633": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4633",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4633"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4634": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4634",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4634"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4635": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4635",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4635"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4636": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4636",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4636"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4637": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4637",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4637"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4638": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4638",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4638"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4639": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4639",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4639"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4640": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4640",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4640"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4641": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4641",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4641"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4642": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4642",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4642"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4643": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4643",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4643"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4644": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4644",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4644"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4645": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4645",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4645"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4646": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4646",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4646"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4647": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4647",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4647"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4648": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4648",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4648"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4649": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4649",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4649"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4650": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4650",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4650"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4651": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4651",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4651"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4652": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4652",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4652"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4653": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4653",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4653"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4654": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4654",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4654"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4655": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4655",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4655"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4656": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4656",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4656"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4657": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4657",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4657"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4658": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4658",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4658"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4659": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4659",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4659"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4660": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4660",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4660"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4661": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4661",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4661"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4662": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4662",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4662"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4663": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4663",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4663"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4664": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4664",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4664"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4665": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4665",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4665"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4666": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4666",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4666"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4667": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4667",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4667"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4668": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4668",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4668"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4669": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4669",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4669"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4670": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4670",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4670"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4671": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4671",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4671"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4672": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4672",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4672"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4673": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4673",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4673"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4674": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4674",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4674"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4675": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4675",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4675"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4676": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4676",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4676"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4677": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4677",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4677"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4678": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4678",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4678"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4679": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4679",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4679"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4680": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4680",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4680"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4681": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4681",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4681"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4682": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4682",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4682"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4683": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4683",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4683"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4684": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4684",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4684"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4685": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4685",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4685"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4686": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4686",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4686"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4687": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4687",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4687"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4688": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4688",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4688"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4689": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4689",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4689"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4690": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4690",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4690"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4691": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4691",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4691"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4692": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4692",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4692"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4693": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4693",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4693"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4694": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4694",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4694"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4695": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4695",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4695"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4696": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4696",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4696"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4697": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4697",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4697"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4698": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4698",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4698"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4699": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4699",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4699"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4700": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4700",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4700"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4701": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4701",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4701"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4702": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4702",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4702"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4703": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4703",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4703"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4704": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4704",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4704"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4705": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4705",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4705"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4706": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4706",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4706"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4707": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4707",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4707"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4708": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4708",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4708"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4709": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4709",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4709"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4710": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4710",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4710"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4711": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4711",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4711"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4712": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4712",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4712"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4713": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4713",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4713"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4714": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4714",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4714"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4715": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4715",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4715"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4716": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4716",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4716"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4717": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4717",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4717"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4718": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4718",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4718"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4719": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4719",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4719"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4720": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4720",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4720"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4721": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4721",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4721"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4722": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4722",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4722"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4723": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4723",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4723"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4724": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4724",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4724"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4725": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4725",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4725"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4726": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4726",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4726"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4727": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4727",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4727"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4728": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4728",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4728"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4729": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4729",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4729"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4730": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4730",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4730"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4731": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4731",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4731"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4732": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4732",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4732"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4733": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4733",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4733"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4734": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4734",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4734"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4735": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4735",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4735"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4736": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4736",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4736"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4737": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4737",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4737"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4738": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4738",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4738"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4739": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4739",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4739"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4740": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4740",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4740"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4741": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4741",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4741"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4742": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4742",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4742"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4743": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4743",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4743"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4744": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4744",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4744"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4745": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4745",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4745"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4746": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4746",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4746"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4747": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4747",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4747"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4748": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4748",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4748"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4749": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4749",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4749"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4750": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4750",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4750"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4751": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4751",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4751"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4752": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4752",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4752"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4753": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4753",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4753"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4754": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4754",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4754"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4755": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4755",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4755"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4756": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4756",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4756"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4757": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4757",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4757"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4758": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4758",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4758"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4759": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4759",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4759"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4760": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4760",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4760"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4761": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4761",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4761"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4762": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4762",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4762"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4763": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4763",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4763"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4764": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4764",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4764"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4765": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4765",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4765"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4766": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4766",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4766"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4767": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4767",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4767"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4768": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4768",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4768"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4769": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4769",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4769"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4770": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4770",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4770"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4771": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4771",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4771"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4772": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4772",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4772"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4773": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4773",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4773"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4774": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4774",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4774"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4775": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4775",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4775"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4776": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4776",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4776"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4777": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4777",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4777"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4778": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4778",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4778"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4779": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4779",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4779"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4780": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4780",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4780"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4781": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4781",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4781"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4782": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4782",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4782"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4783": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4783",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4783"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4784": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4784",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4784"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4785": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4785",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4785"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4786": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4786",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4786"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4787": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4787",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4787"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4788": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4788",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4788"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4789": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4789",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4789"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4790": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4790",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4790"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4791": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4791",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4791"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4792": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4792",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4792"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4793": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4793",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4793"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4794": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4794",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4794"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4795": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4795",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4795"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4796": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4796",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4796"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4797": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4797",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4797"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4798": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4798",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4798"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4799": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4799",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4799"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4800": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4800",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4800"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4801": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4801",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4801"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4802": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4802",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4802"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4803": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4803",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4803"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4804": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4804",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4804"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4805": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4805",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4805"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4806": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4806",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4806"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4807": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4807",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4807"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4808": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4808",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4808"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4809": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4809",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4809"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4810": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4810",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4810"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4811": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4811",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4811"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4812": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4812",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4812"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4813": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4813",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4813"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4814": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4814",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4814"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4815": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4815",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4815"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4816": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4816",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4816"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4817": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4817",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4817"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4818": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4818",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4818"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4819": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4819",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4819"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4820": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4820",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4820"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4821": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4821",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4821"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4822": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4822",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4822"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4823": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4823",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4823"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4824": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4824",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4824"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4825": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4825",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4825"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4826": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4826",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4826"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4827": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4827",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4827"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4828": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4828",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4828"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4829": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4829",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4829"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4830": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4830",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4830"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4831": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4831",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4831"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4832": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4832",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4832"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4833": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4833",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4833"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4834": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4834",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4834"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4835": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4835",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4835"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4836": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4836",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4836"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4837": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4837",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4837"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4838": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4838",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4838"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4839": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4839",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4839"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4840": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4840",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4840"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4841": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4841",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4841"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4842": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4842",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4842"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4843": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4843",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4843"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4844": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4844",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4844"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4845": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4845",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4845"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4846": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4846",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4846"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4847": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4847",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4847"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4848": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4848",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4848"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4849": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4849",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4849"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4850": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4850",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4850"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4851": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4851",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4851"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4852": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4852",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4852"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4853": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4853",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4853"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4854": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4854",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4854"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4855": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4855",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4855"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4856": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4856",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4856"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4857": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4857",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4857"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4858": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4858",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4858"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4859": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4859",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4859"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4860": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4860",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4860"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4861": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4861",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4861"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4862": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4862",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4862"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4863": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4863",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4863"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4864": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4864",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4864"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4865": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4865",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4865"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4866": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4866",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4866"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4867": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4867",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4867"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4868": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4868",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4868"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4869": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4869",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4869"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4870": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4870",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4870"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4871": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4871",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4871"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4872": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4872",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4872"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4873": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4873",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4873"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4874": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4874",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4874"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4875": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4875",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4875"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4876": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4876",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4876"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4877": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4877",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4877"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4878": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4878",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4878"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4879": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4879",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4879"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4880": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4880",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4880"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4881": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4881",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4881"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4882": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4882",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4882"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4883": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4883",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4883"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4884": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4884",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4884"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4885": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4885",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4885"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4886": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4886",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4886"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4887": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4887",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4887"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4888": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4888",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4888"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4889": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4889",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4889"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4890": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4890",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4890"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4891": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4891",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4891"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4892": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4892",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4892"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4893": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4893",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4893"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4894": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4894",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4894"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4895": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4895",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4895"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4896": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4896",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4896"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4897": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4897",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4897"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4898": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4898",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4898"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4899": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4899",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4899"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4900": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4900",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4900"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4901": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4901",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4901"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4902": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4902",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4902"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4903": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4903",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4903"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4904": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4904",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4904"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4905": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4905",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4905"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4906": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4906",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4906"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4907": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4907",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4907"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4908": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4908",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4908"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4909": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4909",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4909"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4910": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4910",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4910"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4911": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4911",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4911"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4912": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4912",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4912"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4913": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4913",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4913"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4914": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4914",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4914"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4915": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4915",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4915"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4916": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4916",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4916"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4917": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4917",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4917"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4918": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4918",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4918"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4919": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4919",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4919"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4920": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4920",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4920"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4921": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4921",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4921"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4922": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4922",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4922"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4923": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4923",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4923"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4924": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4924",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4924"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4925": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4925",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4925"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4926": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4926",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4926"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4927": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4927",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4927"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4928": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4928",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4928"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4929": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4929",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4929"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4930": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4930",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4930"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4931": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4931",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4931"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4932": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4932",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4932"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4933": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4933",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4933"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4934": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4934",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4934"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4935": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4935",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4935"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4936": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4936",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4936"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4937": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4937",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4937"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4938": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4938",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4938"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4939": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4939",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4939"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4940": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4940",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4940"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4941": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4941",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4941"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4942": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4942",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4942"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4943": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4943",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4943"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4944": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4944",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4944"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4945": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4945",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4945"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4946": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4946",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4946"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4947": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4947",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4947"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4948": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4948",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4948"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4949": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4949",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4949"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4950": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4950",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4950"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4951": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4951",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4951"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4952": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4952",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4952"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4953": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4953",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4953"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4954": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4954",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4954"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4955": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4955",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4955"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4956": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4956",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4956"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4957": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4957",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4957"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4958": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4958",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4958"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4959": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4959",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4959"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4960": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4960",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4960"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4961": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4961",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4961"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4962": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4962",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4962"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4963": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4963",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4963"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4964": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4964",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4964"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4965": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4965",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4965"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4966": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4966",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4966"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4967": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4967",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4967"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4968": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4968",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4968"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4969": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4969",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4969"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4970": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4970",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4970"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4971": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4971",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4971"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4972": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4972",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4972"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4973": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4973",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4973"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4974": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4974",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4974"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4975": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4975",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4975"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4976": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4976",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4976"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4977": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4977",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4977"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4978": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4978",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4978"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4979": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4979",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4979"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4980": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4980",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4980"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4981": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4981",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4981"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4982": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4982",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4982"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4983": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4983",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4983"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4984": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4984",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4984"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4985": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4985",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4985"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4986": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4986",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4986"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4987": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4987",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4987"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4988": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4988",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4988"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4989": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4989",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4989"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4990": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4990",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4990"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4991": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4991",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4991"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4992": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4992",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4992"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4993": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4993",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4993"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4994": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4994",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4994"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4995": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4995",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4995"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4996": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4996",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4996"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4997": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4997",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4997"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4998": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #4998",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "4998"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "4999": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #4999",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "4999"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5000": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5000",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5000"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5001": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5001",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5001"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5002": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5002",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5002"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5003": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5003",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5003"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5004": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5004",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5004"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5005": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5005",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5005"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5006": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5006",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5006"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5007": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5007",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5007"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5008": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5008",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5008"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5009": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5009",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5009"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5010": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5010",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5010"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5011": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5011",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5011"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5012": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5012",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5012"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5013": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5013",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5013"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5014": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5014",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5014"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5015": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5015",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5015"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5016": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5016",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5016"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5017": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5017",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5017"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5018": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5018",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5018"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5019": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5019",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5019"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5020": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5020",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5020"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5021": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5021",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5021"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5022": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5022",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5022"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5023": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5023",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5023"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5024": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5024",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5024"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5025": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5025",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5025"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5026": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5026",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5026"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5027": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5027",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5027"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5028": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5028",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5028"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5029": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5029",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5029"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5030": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5030",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5030"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5031": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5031",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5031"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5032": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5032",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5032"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5033": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5033",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5033"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5034": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5034",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5034"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5035": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5035",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5035"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5036": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5036",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5036"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5037": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5037",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5037"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5038": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5038",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5038"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5039": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5039",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5039"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5040": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5040",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5040"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5041": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5041",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5041"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5042": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5042",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5042"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5043": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5043",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5043"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5044": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5044",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5044"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5045": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5045",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5045"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5046": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Barbarian.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Barbarian #5046",
                "attributes": [{ "trait_type": "Name", "value": "Barbarian" }],
                "id": "5046"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5047": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5047",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5047"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5048": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5048",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5048"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5049": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5049",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5049"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    },
    "5050": {
        "uri": {
            "scheme": "https",
            "data": "https://sgk-battle-heroes.nyc3.digitaloceanspaces.com/Mage.jpg"
        },
        "datum": {
            "datum": {
                "name": "Battle Heroes Mage #5050",
                "attributes": [{ "trait_type": "Name", "value": "Mage" }],
                "id": "5050"
            },
            "uri": { "scheme": "pact:schema", "data": "nft-datum" }
        }
    }
}



export default sgk_battle_heroes_metadata
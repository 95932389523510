
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Box,
    Flex,
    Divider,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function MoveFundsModal({
    isOpen,
    onOpen,
    onClose,
    transferBreakdown,
    ezBuyHeader,
    targetChainId,
    businessTxList,
    setShowEzBarProgress
}) {
    const ezBuy = useQuickBuy();

    function handleMoveFundsClick() {
        ezBuy.executeQuickSignAndTx(targetChainId, businessTxList);
        onClose();
    }

    function handleClose() {
        onClose();
        setShowEzBarProgress(false);
    }

    return (
        <>


            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={handleClose}
                size="lg"
                trapFocus={false}
            >
                <ModalOverlay />
                <ModalContent bgColor="transparent" className="latestDesign">
                    <Box bgColor="#160D1A" borderRadius="8px" py="32px">
                        <Box my="8px">
                            <ModalCloseButton />
                        </Box>
                        <ModalBody>
                            <Flex justify="center" gap="10px" py="12px">
                                <FaInfoCircle fontSize={"26px"} />
                                <Text fontSize="26px" fontWeight="700">
                                    {ezBuyHeader}
                                </Text>
                            </Flex>

                            <Text mt="8px">
                                It seems you do not have sufficient funds on the
                                collection’s chain (Chain {targetChainId}).
                            </Text>
                            <Text mt="30px">
                                Would you like to move funds from the following
                                chains to complete the transaction?
                            </Text>
                            <Text mt="30px">
                                WARNING: EZ-Buy is in BETA
                            </Text>
                            <Text mt="30px">
                                Do not refresh your browser!!!
                            </Text>
                            <Divider
                                my="24px"
                                border="1px"
                                borderColor="#5E1E78"
                            />

                            <Box mb="8px">
                                {
                                    transferBreakdown?.map((transferMessage, index) => {
                                        return (
                                            <Text key={index} fontWeight="900" fontSize="16px">
                                                {transferMessage}
                                            </Text>
                                        );
                                    })
                                }
                            </Box>
                            <Flex py="16px" gap={["15px", "30px"]}>
                                <Button
                                    _focus={{}}
                                    _active={{}}
                                    _hover={{}}
                                    w="100%"
                                    color="#BC3CF0"
                                    border="1px"
                                    borderColor="#BC3CF0"
                                    bgColor="transparent"
                                    borderRadius={"full"}
                                    onClick={() => handleClose()}
                                >
                                    No, thanks
                                </Button>

                                <Button
                                    _focus={{}}
                                    _active={{}}
                                    _hover={{}}
                                    w="100%"
                                    color="#fff"
                                    border="1px"
                                    borderColor="#BC3CF0"
                                    bgColor="#BC3CF0"
                                    borderRadius={"full"}
                                    fontSize={["14px", "16px"]}
                                    onClick={handleMoveFundsClick}
                                >
                                    Move Funds + Mint
                                </Button>
                            </Flex>
                        </ModalBody>
                    </Box>
                    {/* </Box> */}
                </ModalContent>
            </Modal>
        </>
    );
}

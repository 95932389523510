import React, { memo } from "react";
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Hero_1 from "../assets/images/hero_1.svg";
import Hero_2 from "../assets/images/hero_2.svg";
import Hero_3 from "../assets/images/hero_3.svg";
import Circle from "../assets/images/circleColor.png";
import { useNavigate } from "react-router-dom";

const HeroSlider = memo(() => {
    const navigate = useNavigate();
    const settings = {
        dots: true,
        autoplay: false,
        autoplaySpeed: 10000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows: false,
        adaptiveHeight: false,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
    };

    return (
        <Box className="">
            <Slider {...settings}>
                <Box className="testimoni--wrapper" bgColor={"transparent"}>
                    <Content
                        fText="Discover, Collect, & Sell"
                        sText="Extraordinary"
                        lText="NFTs"
                        maxW={"39rem"}
                        img={Hero_1}
                        href="#collections"
                        url={"/marketplace/kadena_skellies-kadena_skellies/"}
                        as="a"
                        btnClick="/marketplace/kadena_skellies-kadena_skellies/"
                    />
                </Box>{" "}
                <Box className="testimoni--wrapper" bgColor={"transparent"}>
                    <Content
                        fText="Did you hear about the Gnomes"
                        sText="Secret?"
                        lText=""
                        btnText={"Explore Collection"}
                        img={Hero_2}
                        url={
                            "/marketplace/secret_garden_of_kadena-secret_garden_of_kadena_gen_0/"
                        }
                    />
                </Box>{" "}
                <Box className="testimoni--wrapper" bgColor={"transparent"}>
                    <Content
                        fText="Choose your fighter with "
                        sText="Boxing Badgers!"
                        lText=""
                        btnText={"Explore Collection"}
                        img={Hero_3}
                        url={"/marketplace/boxing_badger-boxing_badger/"}
                    />
                </Box>
            </Slider>
        </Box>
    );
});

export default HeroSlider;

const Content = memo(
    ({ fText, sText, lText, maxW, btnText, img, url, href, btnClick }) => {
        const navigate = useNavigate();

        return (
            <Box
                pt={["2rem", "3rem", "3rem"]}
                pb={["2rem", "3rem", "5rem"]}
                bgImage={Circle}
                bgRepeat={"no-repeat"}
                bgPos="right"
                bgSize={"1100px"}
            >
                <Flex
                    flexDir={["column", null, "row"]}
                    gap="46px"
                    align={"center"}
                    minH={["auto", null, "307px", "412px", "507px"]}
                >
                    <Box w="100%">
                        <Text
                            maxW={maxW || "42rem"}
                            fontSize={["3rem", null, "3rem", null, "5.125rem"]}
                            fontWeight={"800"}
                            lineHeight={[
                                "auto",
                                "3.375rem",
                                "4.375rem",
                                "6.375rem",
                            ]}
                            letterSpacing={"-0.05775rem"}
                            className="testimoni--wrapper--text"
                        >
                            {fText} {/* explore collection */}
                            <Text
                                bg={
                                    "linear-gradient(278deg, #6300FF 0%, #E8E597 53.13%, #7DAEDF 98.44%)"
                                }
                                bgClip={"text"}
                                as="span"
                                display={"inline-block"}
                            >
                                {sText}
                            </Text>{" "}
                            {lText}
                        </Text>
                    </Box>
                    <Box w="100%">
                        <Image src={img} alt="sam" />
                    </Box>
                </Flex>
                <Box py="1.83rem">
                    <Link href={url}>
                        <Button
                            // as="a"
                            // href="#collection"
                            rounded={"full"}
                            color="white"
                            bgColor={"#C55BF0"}
                            // fontSize={"1.25rem"}
                            _hover={{}}
                            _active={{}}
                            _focusVisible={{}}
                            py="1.25rem"
                            px="2.5rem"
                        >
                            {btnText || "Explore collections"}
                        </Button>
                    </Link>
                </Box>
            </Box>
        );
    }
);

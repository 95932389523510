import { initializeApp } from "firebase/app";
import {
    getFirestore,
    onSnapshot,
    collection,
    getDocs,
    doc,
    getDoc,
    updateDoc,
    setDoc,
    query,
    orderBy,
    limitToLast,
    where,
} from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useEffect, useState } from "react";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const authenticateAnonymously = () => {
    return signInAnonymously(getAuth(app));
};

async function getAll() {
    let docs = await getDocs(collection(db, "/Collections"));
    docs.forEach((document) => {
        console.log(document.id);
    });
    console.log(docs);
    return docs;
}

/*
    @returns {Promise} - A promise that resolves with the document data
    @param {string} collectionName - The name of the collection to query
    @param {string} documentId - The id of the document to query
    @param {function} callback - A callback function that will be called with the document data

    @return {function} - A function that can be called to unsubscribe from the snapshot listener
*/
export const subscribeToNFTMetadata = (collectionName, nftId, callback) => {
    const collectionRef = collection(db, `Collections/${collectionName}/NFTs`);
    const docRef = doc(collectionRef, nftId);
    return onSnapshot(docRef, (snapshot) => {
        const metadata = snapshot.docs;
        callback(metadata);
    });
};

/**
 * Hook to subscribe to a query and get the latest results
 */
export const useQuery = (query) => {
    const [queryResult, setQuerySnapshot] = useState(null);

    useEffect(() => {
        const unsubscribe = onSnapshot(query, (snapshot) => {
            setQuerySnapshot(snapshot);
        });
        return () => unsubscribe();
    }, []);

    return queryResult;
};

export async function firestoreGetDocument(documentPath) {
    const docRef = doc(db, documentPath);
    const docData = await getDoc(docRef);

    if (docData.exists()) {
        let data = docData.data();

        return data;
    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        return null;
    }
}

export async function firestoreGetCollection(collectionPath) {
    const collectionRef = collection(db, collectionPath);
    let collectionData = await getDocsFromFirestoreCollection(collectionRef);

    return collectionData;
}

export async function firestoreUpdateDocumentValues(documentPath, data) {
    const docRef = doc(db, documentPath);

    await updateDoc(docRef, data);
}

export async function firestoreAddDocumentToCollection(
    collectionPath,
    documentId,
    documentData
) {
    const docRef = doc(collectionPath, documentId);

    await setDoc(docRef, documentData);
}

export async function getDocsFromFirestoreCollection(collectionRef) {
    let collectionData = await getDocs(collectionRef);

    if (!collectionData.empty) {
        let documentsData = [];

        collectionData.forEach((document) => {
            documentsData.push(document.data());
        });

        return documentsData;
    } else {
        return null;
    }
}

export async function firestoreQueryCollection(collectionPath, field, condition = null, limit = null) {
    let collectionRef = await collection(db, collectionPath);
    let q = null;

    //TODO: create list of 'where()' conditions here

    if (condition) {
        if (limit) {
            q = query(
                collectionRef,
                where(condition[0], condition[1], condition[2]),
                orderBy(field, "desc"),
                limitToLast(limit)
            );
        } else {
            q = query(
                collectionRef,
                where(condition[0], condition[1], condition[2]),
                orderBy(field, "desc")
            );
        }
    } else {
        if (limit) {
            q = query(
                collectionRef,
                orderBy(field),
                limitToLast(limit)
            );
        } else {
            q = query(
                collectionRef,
                orderBy(field)
            );
        }
    }

    let docs = await getDocs(q);
    let results = [];
    docs.forEach((doc) => {
        results.push(doc.data());
    })
    return results;
}

export function buildCollectionQuery(collectionPath) {
    let collectionQuery = query(collection(db, collectionPath));
    return collectionQuery;
}

export function buildDocumentQuery(documentPath) {
    let docQuery = query(doc(db, documentPath));
    return docQuery;
}

export { authenticateAnonymously, db, getAll, app };

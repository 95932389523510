import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScreenSize from "../layouts/ScreenSize";
import More from "./More";
import NftProductPageHero from "./NftProductPageHero";
import { PactContext } from "../pact/PactContextProvider";
import { IsokoDialogContext } from "../IsokoContextProvider/IsokoDialogContextProvider";
import { getBlockHeightForChain, pollForTransactionProgress } from "../pact/PactUtils";
import { MarketplaceContext } from "../MarketplaceContext/MarketplaceContextProvider";
import { checkIfNullOrUndefined, getRandomElementsFromList } from "../utils/utils";
import { BUTTER_TYPE, STACKED_TYPE } from "../utils/Constants";
import { Back } from "../CommonComponents/BackButton";
import Activity from "../Activity/Activity";
import { buildDocumentQuery } from "../Firestore/FirestoreService";
import { buildNftPath } from "../utils/dbUtils";
import useFirestore from "../Firestore/useFirestore";

const NftProductPage = () => {
    const navigate = useNavigate();
    const { nftId } = useParams();
    const { account } = useContext(PactContext);
    const marketplaceContext = useContext(MarketplaceContext);
    const [moreNfts, setMoreNfts] = useState([]);
    const [nftData, setNftData] = useState(null);
    const [currentHeight, setCurrentHeight] = useState(null);
    const {
        setActiveModalUrl,
        setBackModalUrl,
        onOpenSuccessPurchaseModal,
        onOpenFailedPurchaseModal
    } = useContext(IsokoDialogContext);
    const nftDocListener = useFirestore(
        buildDocumentQuery(buildNftPath(marketplaceContext.collectionConfig, nftId, marketplaceContext.subCollectionId)),
        async function (data) {
            if (!checkIfNullOrUndefined(data) && !checkIfNullOrUndefined(nftData)) {
                formatNftMetadata();
            }
        },
        "doc"
    );

    useEffect(() => {
        if (checkIfNullOrUndefined(nftData) || (!checkIfNullOrUndefined(nftData) && nftData["nft-id"] !== nftId)) {
            formatNftMetadata()
        }
    }, [nftId]);

    async function formatNftMetadata() {
        let id = nftId;
        // if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
        //     let mintProvider = marketplaceContext.collectionProvider.getMintProvider();
        //     id = mintProvider.getNftName(marketplaceContext.subCollectionId, nftId);
        // }
        let nftMetadata = await marketplaceContext.collectionProvider.getNftListingData(id, marketplaceContext.subCollectionId);
        nftMetadata = {
            ...nftMetadata,
            ...marketplaceContext.collectionMetadata
        };

        let currHeight = await getBlockHeightForChain(nftMetadata["chain-id"]);
        let randomSelect = getRandomElementsFromList(marketplaceContext.filterListed(marketplaceContext.collectionNfts), 4);

        setNftData(nftMetadata);
        setMoreNfts(randomSelect);
        setCurrentHeight(currHeight);
    }

    function handleProductPageActionButtonClick(action, nftId) {
        switch (action) {
            case "purchase":
                handleNftPurchase();
                break;
            case "list":
                handleNavigateToNftListPage(nftId);
                break;
            default:
                break;
        }
    }

    function handleNftCardClick(nftId) {
        if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
            navigate(`/marketplace/group/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
        } else {
            navigate(`/marketplace/${marketplaceContext.projectId}/${nftId}`);
        }
    }

    function handleNavigateToNftListPage(nftId) {
        if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
            navigate(`/listNft/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
        } else {
            navigate(`/listNft/${marketplaceContext.projectId}/${nftId}`);
        }
    }

    async function handleNftPurchase() {
        await marketplaceContext.marketplaceProvider.buy(
            nftData,
            account.account,
            onBuyTransactionComplete
        );
    }

    async function onBuyTransactionComplete(data) {
        if (nftData["standard"] === BUTTER_TYPE) {
            if (data?.result?.status === "success") {
                let nftData = await marketplaceContext.collectionProvider.getNftListingData(nftId, marketplaceContext.subCollectionId);
                setNftData(nftData);
                // userContext.addNft(nftData, marketplaceContext.collectionMetadata)

                setBackModalUrl(`/marketplace/${marketplaceContext.projectId}/`);
                setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${nftId}`);

                onOpenSuccessPurchaseModal();
            } else {
                setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${nftId}`);
                onOpenFailedPurchaseModal();
            }
        } else {
            if (data?.requestKeys) {
                let response = await pollForTransactionProgress(data?.requestKeys[0], nftData["chain-id"]);
                if (response === "success") {
                    // let nft = nftId;
                    // if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                    //     let mintProvider = marketplaceContext.collectionProvider.getMintProvider();
                    //     nft = mintProvider.getNftName(marketplaceContext.subCollectionId, nftId)
                    // }
                    let nftData = await marketplaceContext.collectionProvider.getNftListingData(nftId, marketplaceContext.subCollectionId);
                    setNftData(nftData);
                    // userContext.addNft(nftData, marketplaceContext.collectionMetadata)

                    if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                        setBackModalUrl(`/marketplace/group/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}`);
                        setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
                    } else {
                        setBackModalUrl(`/marketplace/${marketplaceContext.projectId}/`);
                        setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${nftId}`);
                    }
                    onOpenSuccessPurchaseModal();
                } else {
                    if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                        setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
                    } else {
                        setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${nftId}`);
                    }
                    onOpenFailedPurchaseModal();
                }
            } else {
                if (marketplaceContext.collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                    setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${marketplaceContext.subCollectionId}/${nftId}`);
                } else {
                    setActiveModalUrl(`/listNft/${marketplaceContext.projectId}/${nftId}`);
                }
                onOpenFailedPurchaseModal();
            }
        }
    }

    return (
        <Box bgColor="#0A0A0A" minH="100vh" className="latestDesign">
            <ScreenSize>
                <Back callback={() => navigate(-1)} />

                <NftProductPageHero
                    nftData={nftData}
                    currentHeight={currentHeight}
                    getNftState={marketplaceContext.getNftState}
                    collectionData={marketplaceContext.collectionMetadata}
                    actionButtonCallback={handleProductPageActionButtonClick}
                />

                {/* {
                    nftData &&
                    marketplaceContext.collectionMetadata?.standard === MARMALADE_TYPE &&
                    <Activity queryField={"nftId"} queryValue={nftData["nft-id"]} />
                } */}

                <More
                    projectId={marketplaceContext.projectId}
                    collectionName={
                        !checkIfNullOrUndefined(marketplaceContext.collectionMetadata) &&
                        marketplaceContext.collectionMetadata["collection-name"]
                    }
                    // nftsInCollection={getRandomElementsFromList(marketplaceContext.collectionNfts, 5)}
                    nftsInCollection={moreNfts}
                    onClick={handleNftCardClick}
                />
            </ScreenSize>
        </Box>
    );
};

export default NftProductPage;

import { Box, Text } from "@chakra-ui/react";
import React from "react";
import ScreenSize from "../layouts/ScreenSize";
import LaunchPadCard from "../Cards/LaunchPadCard";

const Ended = ({ projects }) => {
    return (
        <ScreenSize>
            <div className="Upcoming">
                <Text fontSize="36px" fontWeight="700">
                    Ended
                </Text>

                <div className="upcomingCards">
                    {
                        projects ? 
                            projects.map((projectObject, index) => {
                                return (
                                    <LaunchPadCard
                                        type="ended"
                                        key={index}
                                        clickable={true}
                                        projectName={projectObject.projectName}
                                        projectConfig={projectObject.projectConfig}
                                    />
                                )
                            }) 
                         : null
                    }
                </div>
            </div>
        </ScreenSize>
    );
};

export default Ended;

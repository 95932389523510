import { Box, Image } from "@chakra-ui/react";
import React from "react";
import dummyCar from "../../src/assets/images/dummyCar.svg";

const DummyCard = () => {
    return (
        <div className="kadCard latestDesign">
            <div className="" px="0.25rem" pt="0.25rem">
                <Image
                    borderTopRightRadius={"0.75rem"}
                    borderTopLeftRadius={"0.75rem"}
                    src={dummyCar}
                    alt="nftCard"
                    width={"100%"}
                    height="19rem"
                    objectFit={"cover"}
                    className="cardImage"
                />
            </div>
            <Box style={{ padding: "16px 16px 16px 21px" }} bgColor="#160d19">
                <div>
                    <div className="flex sBtw alignCenter">
                        {" "}
                        <h4 className="kadcarsTitle"> Lorem ipsum dolor</h4>
                        {/* Card true badge variants */}
                        {/* live badge */}
                        {true === "live" && (
                            <Box
                                className=""
                                fontSize="12px"
                                fontWeight={"700"}
                                px="8px"
                                py="2px"
                                border="1px"
                                borderColor="#E9BEFA"
                                bgColor="green"
                                borderRadius={"32px"}
                            >
                                {" "}
                                LIVE{" "}
                            </Box>
                        )}
                        {true === "startingSoon" && (
                            <div className="startingSoonBadge">
                                {" "}
                                COMING SOON{" "}
                            </div>
                        )}
                        {true === "ended" && (
                            <div className="endedBadge"> ENDED </div>
                        )}
                        {/* Card true badge variants */}
                    </div>
                    <div className="totalItems">
                        <div className="cardDetails">
                            <p
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "12px",
                                }}
                            >
                                TOTAL ITEMS
                            </p>
                            <p
                                className=""
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "12px",
                                }}
                            >
                                {" "}
                                {true !== "startingSoon" &&
                                    (true ? "PRICE" : "FLOOR")}{" "}
                            </p>
                        </div>

                        <div className="cardDetails cardValue">
                            <p>Lorem, ipsum dolor. </p>
                            <p className="">{"???"} KDA</p>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default DummyCard;

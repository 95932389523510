const arkade_90s_bulls_metadata = {
  "1030": {
    "name": "Arkade 90s Bulls #1030",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1030.png",
    "dna": "12c151c3090924fdaf64c571d966b728ef47e123",
    "edition": 1030,
    "date": 1672531977245,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1030",
    "rarity_score": 98.94881590086842,
    "rarity_level": "COMMON"
  },
  "1620": {
    "name": "Arkade 90s Bulls #1620",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1620.png",
    "dna": "da06b99c11f2622daf5151c7fe5be142933c60a7",
    "edition": 1620,
    "date": 1672531978019,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1620",
    "rarity_score": 358.9910009685372,
    "rarity_level": "COMMON"
  },
  "1837": {
    "name": "Arkade 90s Bulls #1837",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1837.png",
    "dna": "8ea499bf6c7e29725a22bd6773b5a38653ed2052",
    "edition": 1837,
    "date": 1672531979308,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1837",
    "rarity_score": 116.29644588482697,
    "rarity_level": "COMMON"
  },
  "611": {
    "name": "Arkade 90s Bulls #611",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/611.png",
    "dna": "ede848df7a351cbfb752ffaac33818620a33c1c2",
    "edition": 611,
    "date": 1672531979915,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "611",
    "rarity_score": 383.2475822901276,
    "rarity_level": "COMMON"
  },
  "1585": {
    "name": "Arkade 90s Bulls #1585",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1585.png",
    "dna": "d6184f768cfeac22eea6c9aeeb663e7cc64a1109",
    "edition": 1585,
    "date": 1672531980614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1585",
    "rarity_score": 294.6735788314114,
    "rarity_level": "COMMON"
  },
  "1487": {
    "name": "Arkade 90s Bulls #1487",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1487.png",
    "dna": "42806caccbf54b21248e22bd607b4ee372c9ce35",
    "edition": 1487,
    "date": 1672531981660,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1487",
    "rarity_score": 472.2486576091282,
    "rarity_level": "RARE"
  },
  "596": {
    "name": "Arkade 90s Bulls #596",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/596.png",
    "dna": "c0a8b0f5c1646ef7f863eec5b003d5956d318ab3",
    "edition": 596,
    "date": 1672531982373,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "596",
    "rarity_score": 145.9169294392301,
    "rarity_level": "COMMON"
  },
  "868": {
    "name": "Arkade 90s Bulls #868",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/868.png",
    "dna": "aee26266717fc2551baded27ef1d95939504a51d",
    "edition": 868,
    "date": 1672531983308,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "868",
    "rarity_score": 114.73514793615755,
    "rarity_level": "COMMON"
  },
  "851": {
    "name": "Arkade 90s Bulls #851",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/851.png",
    "dna": "7e8ae8443cd414ea4caaec010bb2465e95252ebf",
    "edition": 851,
    "date": 1672531983890,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "851",
    "rarity_score": 358.9967724536444,
    "rarity_level": "COMMON"
  },
  "656": {
    "name": "Arkade 90s Bulls #656",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/656.png",
    "dna": "2d0ab2bc25e4a85be32bbb898837b3abff0d9289",
    "edition": 656,
    "date": 1672531984508,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "656",
    "rarity_score": 302.8813257396605,
    "rarity_level": "COMMON"
  },
  "952": {
    "name": "Arkade 90s Bulls #952",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/952.png",
    "dna": "8b24bba33728235d8031ef210f78d1dd8a07dbda",
    "edition": 952,
    "date": 1672531985794,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "952",
    "rarity_score": 459.5658739471718,
    "rarity_level": "COMMON"
  },
  "393": {
    "name": "Arkade 90s Bulls #393",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/393.png",
    "dna": "73ca21f370cbfaeafe1602a97fb859ffbca3d2e6",
    "edition": 393,
    "date": 1672531986403,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "Blue Guardian Mask",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "393",
    "rarity_score": 763.3405080675398,
    "rarity_level": "RARE"
  },
  "821": {
    "name": "Arkade 90s Bulls #821",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/821.png",
    "dna": "c0b0cf27356f345c8885b450e1e95b96b1a9b870",
    "edition": 821,
    "date": 1672531987806,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "821",
    "rarity_score": 306.81672595606824,
    "rarity_level": "COMMON"
  },
  "1200": {
    "name": "Arkade 90s Bulls #1200",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1200.png",
    "dna": "c541d257362e2a84fba403ec4b42fbba85315008",
    "edition": 1200,
    "date": 1672531988411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1200",
    "rarity_score": 452.42631170404104,
    "rarity_level": "COMMON"
  },
  "1700": {
    "name": "Arkade 90s Bulls #1700",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1700.png",
    "dna": "4576dc275adddbe82086457024dd0c45b7fde3fe",
    "edition": 1700,
    "date": 1672531989077,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1700",
    "rarity_score": 341.3751762226847,
    "rarity_level": "COMMON"
  },
  "1523": {
    "name": "Arkade 90s Bulls #1523",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1523.png",
    "dna": "588d8ef3e98ec73987e7438d8a0683f24df78ad3",
    "edition": 1523,
    "date": 1672531989733,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1523",
    "rarity_score": 253.4098560630745,
    "rarity_level": "COMMON"
  },
  "164": {
    "name": "Arkade 90s Bulls #164",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/164.png",
    "dna": "147084d094dced5675aeef800c59b185c7dcfc2e",
    "edition": 164,
    "date": 1672531990540,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "164",
    "rarity_score": 219.5272927413898,
    "rarity_level": "COMMON"
  },
  "1387": {
    "name": "Arkade 90s Bulls #1387",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1387.png",
    "dna": "b7ef2aeaa97e2c713c9c32528ebadcaac43ca059",
    "edition": 1387,
    "date": 1672531991166,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1387",
    "rarity_score": 256.1983684881271,
    "rarity_level": "COMMON"
  },
  "427": {
    "name": "Arkade 90s Bulls #427",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/427.png",
    "dna": "fa7c31b5ce6f7c56c08b309f734aef765db28fe8",
    "edition": 427,
    "date": 1672531991850,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "427",
    "rarity_score": 251.23743112057878,
    "rarity_level": "COMMON"
  },
  "1974": {
    "name": "Arkade 90s Bulls #1974",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1974.png",
    "dna": "8b0c4715645f0f30d7218b0e384b735209112cbd",
    "edition": 1974,
    "date": 1672531992499,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1974",
    "rarity_score": 240.83539577322804,
    "rarity_level": "COMMON"
  },
  "980": {
    "name": "Arkade 90s Bulls #980",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/980.png",
    "dna": "65c800e2233f6271683abf3cb73f233aa272dc3e",
    "edition": 980,
    "date": 1672531993114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "980",
    "rarity_score": 142.96596912346408,
    "rarity_level": "COMMON"
  },
  "1400": {
    "name": "Arkade 90s Bulls #1400",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1400.png",
    "dna": "1a223a8d0e8248907e37f9bbf51cfb85ec2728b8",
    "edition": 1400,
    "date": 1672531994004,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1400",
    "rarity_score": 136.6478170997586,
    "rarity_level": "COMMON"
  },
  "1272": {
    "name": "Arkade 90s Bulls #1272",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1272.png",
    "dna": "d84b6ccfb0c3ebffb165a91215e929dae9a0ab5a",
    "edition": 1272,
    "date": 1672531994652,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1272",
    "rarity_score": 150.62493273440967,
    "rarity_level": "COMMON"
  },
  "175": {
    "name": "Arkade 90s Bulls #175",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/175.png",
    "dna": "005c31beaee9fe2e4b1a004e4e7997f4552767b5",
    "edition": 175,
    "date": 1672531996059,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "175",
    "rarity_score": 279.82546320189687,
    "rarity_level": "COMMON"
  },
  "1422": {
    "name": "Arkade 90s Bulls #1422",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1422.png",
    "dna": "5fd2888f9daefa229db9bd1c1094b8ced512c497",
    "edition": 1422,
    "date": 1672531996754,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1422",
    "rarity_score": 168.89840076447064,
    "rarity_level": "COMMON"
  },
  "1867": {
    "name": "Arkade 90s Bulls #1867",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1867.png",
    "dna": "970e86341fb1e0a8c51b0900a7edf539a3c31778",
    "edition": 1867,
    "date": 1672531997440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1867",
    "rarity_score": 170.87179184757426,
    "rarity_level": "COMMON"
  },
  "423": {
    "name": "Arkade 90s Bulls #423",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/423.png",
    "dna": "7c1681be338a28d250c0ccd15224611f2f2857c2",
    "edition": 423,
    "date": 1672531998066,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "423",
    "rarity_score": 218.3307081026243,
    "rarity_level": "COMMON"
  },
  "1572": {
    "name": "Arkade 90s Bulls #1572",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1572.png",
    "dna": "d38486706f99032521c13037c14b9c26f484f430",
    "edition": 1572,
    "date": 1672531999441,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1572",
    "rarity_score": 95.48241514432564,
    "rarity_level": "COMMON"
  },
  "1454": {
    "name": "Arkade 90s Bulls #1454",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1454.png",
    "dna": "04e5f921d9ede06c49c50e01f82714f3c46d0548",
    "edition": 1454,
    "date": 1672532000084,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1454",
    "rarity_score": 135.93240768709595,
    "rarity_level": "COMMON"
  },
  "1567": {
    "name": "Arkade 90s Bulls #1567",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1567.png",
    "dna": "52b9cd00146071e2283eb308a18ebec1d0631e54",
    "edition": 1567,
    "date": 1672532001434,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1567",
    "rarity_score": 89.37518817314401,
    "rarity_level": "COMMON"
  },
  "804": {
    "name": "Arkade 90s Bulls #804",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/804.png",
    "dna": "7059c9c4e9c6c077efac7853c4eb7a33c1b0dda2",
    "edition": 804,
    "date": 1672532002932,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "804",
    "rarity_score": 209.10188342660373,
    "rarity_level": "COMMON"
  },
  "193": {
    "name": "Arkade 90s Bulls #193",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/193.png",
    "dna": "091c057b5ca90e24e760e6f20e4c736f0e937a7c",
    "edition": 193,
    "date": 1672532003867,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "193",
    "rarity_score": 136.4911453357543,
    "rarity_level": "COMMON"
  },
  "1735": {
    "name": "Arkade 90s Bulls #1735",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1735.png",
    "dna": "c70a77af524defec82bf9130bf5f28e5087804b7",
    "edition": 1735,
    "date": 1672532004517,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1735",
    "rarity_score": 339.95466893348396,
    "rarity_level": "COMMON"
  },
  "865": {
    "name": "Arkade 90s Bulls #865",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/865.png",
    "dna": "d1e8902cd76f680fd5836842cb9ba330a76e0391",
    "edition": 865,
    "date": 1672532005180,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "865",
    "rarity_score": 124.48068636995906,
    "rarity_level": "COMMON"
  },
  "1323": {
    "name": "Arkade 90s Bulls #1323",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1323.png",
    "dna": "737e22c6aa1bcc2e2cd1502138b7ec428f9c966c",
    "edition": 1323,
    "date": 1672532005846,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1323",
    "rarity_score": 102.0826920475377,
    "rarity_level": "COMMON"
  },
  "338": {
    "name": "Arkade 90s Bulls #338",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/338.png",
    "dna": "dc2bb5c4509ad693a67909537d6a7141ed466cf9",
    "edition": 338,
    "date": 1672532006490,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "338",
    "rarity_score": 126.92411820629063,
    "rarity_level": "COMMON"
  },
  "19": {
    "name": "Arkade 90s Bulls #19",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/19.png",
    "dna": "8e09089ac38827cdc0f7cd500d8dec287a9c2ed8",
    "edition": 19,
    "date": 1672532007139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "19",
    "rarity_score": 148.0019811174895,
    "rarity_level": "COMMON"
  },
  "1453": {
    "name": "Arkade 90s Bulls #1453",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1453.png",
    "dna": "9682c32e4a61fec4757c2105d8de5bdb38f5c482",
    "edition": 1453,
    "date": 1672532007964,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1453",
    "rarity_score": 698.8625888798363,
    "rarity_level": "RARE"
  },
  "779": {
    "name": "Arkade 90s Bulls #779",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/779.png",
    "dna": "3174cf773d0af8d92af4ed3b124b3fed2af6d4bd",
    "edition": 779,
    "date": 1672532008480,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "779",
    "rarity_score": 369.86960261923116,
    "rarity_level": "COMMON"
  },
  "1696": {
    "name": "Arkade 90s Bulls #1696",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1696.png",
    "dna": "e4e1fdc0d6d666aa5d9acf32cfa9bc8eeb8b3e0b",
    "edition": 1696,
    "date": 1672532009181,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1696",
    "rarity_score": 305.40198489444936,
    "rarity_level": "COMMON"
  },
  "252": {
    "name": "Arkade 90s Bulls #252",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/252.png",
    "dna": "189bdfe3039df64a911edf5f0086e4e18b1759b5",
    "edition": 252,
    "date": 1672532010136,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "252",
    "rarity_score": 203.7986675666956,
    "rarity_level": "COMMON"
  },
  "1698": {
    "name": "Arkade 90s Bulls #1698",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1698.png",
    "dna": "64e5d537e1b41af63ed7f4c22d0f01cbd49aecae",
    "edition": 1698,
    "date": 1672532010776,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1698",
    "rarity_score": 217.80336830349574,
    "rarity_level": "COMMON"
  },
  "1452": {
    "name": "Arkade 90s Bulls #1452",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1452.png",
    "dna": "1e74b49e3d12ab483409c8286786b70bf2315891",
    "edition": 1452,
    "date": 1672532011470,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1452",
    "rarity_score": 142.6293417193201,
    "rarity_level": "COMMON"
  },
  "1415": {
    "name": "Arkade 90s Bulls #1415",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1415.png",
    "dna": "ff0ad3c22d9d985c29bcd975d6077e1c8c9c2a12",
    "edition": 1415,
    "date": 1672532012117,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1415",
    "rarity_score": 136.9212965224972,
    "rarity_level": "COMMON"
  },
  "467": {
    "name": "Arkade 90s Bulls #467",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/467.png",
    "dna": "e04e0bb34f849136a5733a4a64be73ccf87e6304",
    "edition": 467,
    "date": 1672532012759,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "467",
    "rarity_score": 144.77498845562292,
    "rarity_level": "COMMON"
  },
  "770": {
    "name": "Arkade 90s Bulls #770",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/770.png",
    "dna": "b9b29a4f5633bedad5bd9bd49828bd70768a97c4",
    "edition": 770,
    "date": 1672532013371,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "770",
    "rarity_score": 274.40714344387635,
    "rarity_level": "COMMON"
  },
  "1102": {
    "name": "Arkade 90s Bulls #1102",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1102.png",
    "dna": "a12a280bd50b48e7ff57b3a4b93bcc03d1fc33a7",
    "edition": 1102,
    "date": 1672532013987,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1102",
    "rarity_score": 217.71143997585315,
    "rarity_level": "COMMON"
  },
  "1134": {
    "name": "Arkade 90s Bulls #1134",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1134.png",
    "dna": "70f41e5f1ce36d1a41572d49f9dcfa4048d94af2",
    "edition": 1134,
    "date": 1672532014784,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1134",
    "rarity_score": 64.97692327999239,
    "rarity_level": "COMMON"
  },
  "1269": {
    "name": "Arkade 90s Bulls #1269",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1269.png",
    "dna": "532db5ca4f4c877c699fa1de373cc6c04fbef20a",
    "edition": 1269,
    "date": 1672532016333,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1269",
    "rarity_score": 84.68149520331774,
    "rarity_level": "COMMON"
  },
  "1489": {
    "name": "Arkade 90s Bulls #1489",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1489.png",
    "dna": "69e0fab5a51a26626803de9ce7f6c4c8c103c324",
    "edition": 1489,
    "date": 1672532016969,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1489",
    "rarity_score": 204.4540238748586,
    "rarity_level": "COMMON"
  },
  "195": {
    "name": "Arkade 90s Bulls #195",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/195.png",
    "dna": "35575ead486f7201ef6448403d714bb235c8a5b2",
    "edition": 195,
    "date": 1672532017767,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "195",
    "rarity_score": 333.643899589985,
    "rarity_level": "COMMON"
  },
  "880": {
    "name": "Arkade 90s Bulls #880",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/880.png",
    "dna": "aa72897883a9c1f84584bfc8abb66965acdbb81f",
    "edition": 880,
    "date": 1672532018813,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "880",
    "rarity_score": 265.38599009412326,
    "rarity_level": "COMMON"
  },
  "1556": {
    "name": "Arkade 90s Bulls #1556",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1556.png",
    "dna": "2d12c1216a67a8a4967be3a88084dfba155f6525",
    "edition": 1556,
    "date": 1672532020333,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1556",
    "rarity_score": 80.07116647945347,
    "rarity_level": "COMMON"
  },
  "1498": {
    "name": "Arkade 90s Bulls #1498",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1498.png",
    "dna": "5cd68647169ecc452ae0fbeca5e116bd6e08fc2d",
    "edition": 1498,
    "date": 1672532021139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1498",
    "rarity_score": 115.33068298382193,
    "rarity_level": "COMMON"
  },
  "1119": {
    "name": "Arkade 90s Bulls #1119",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1119.png",
    "dna": "27fc399573fceeb07fb36d241a9b0a3821429af7",
    "edition": 1119,
    "date": 1672532022382,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1119",
    "rarity_score": 307.77807861307303,
    "rarity_level": "COMMON"
  },
  "1180": {
    "name": "Arkade 90s Bulls #1180",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1180.png",
    "dna": "0e1f3213602832b355a93be198cf6cc5e2caf14b",
    "edition": 1180,
    "date": 1672532022942,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1180",
    "rarity_score": 414.36664834448675,
    "rarity_level": "COMMON"
  },
  "1677": {
    "name": "Arkade 90s Bulls #1677",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1677.png",
    "dna": "6ececc6ec39a5f03af48cbabed811c55896f5c6a",
    "edition": 1677,
    "date": 1672532023603,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1677",
    "rarity_score": 219.10109849746362,
    "rarity_level": "COMMON"
  },
  "593": {
    "name": "Arkade 90s Bulls #593",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/593.png",
    "dna": "f318a29fed7186b3bceb7b6acc2b8cb0c19d3dce",
    "edition": 593,
    "date": 1672532024966,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "593",
    "rarity_score": 195.07536718371733,
    "rarity_level": "COMMON"
  },
  "654": {
    "name": "Arkade 90s Bulls #654",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/654.png",
    "dna": "937ca086189a0599886c9fa7462e603c6c910790",
    "edition": 654,
    "date": 1672532025539,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Green Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "654",
    "rarity_score": 655.028444886449,
    "rarity_level": "RARE"
  },
  "8": {
    "name": "Arkade 90s Bulls #8",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/8.png",
    "dna": "5a1da5a3915101697b83dd3c7639b801f5a072d5",
    "edition": 8,
    "date": 1672532026221,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "8",
    "rarity_score": 252.58115780896753,
    "rarity_level": "COMMON"
  },
  "552": {
    "name": "Arkade 90s Bulls #552",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/552.png",
    "dna": "02616fbfadbdac7f0e1ca892d9c0e3339ccdc4a6",
    "edition": 552,
    "date": 1672532026942,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "552",
    "rarity_score": 221.8519992493191,
    "rarity_level": "COMMON"
  },
  "1127": {
    "name": "Arkade 90s Bulls #1127",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1127.png",
    "dna": "98a9a88abe97f20961996e2fef13e7b268e45b13",
    "edition": 1127,
    "date": 1672532028487,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1127",
    "rarity_score": 83.96305736521953,
    "rarity_level": "COMMON"
  },
  "1610": {
    "name": "Arkade 90s Bulls #1610",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1610.png",
    "dna": "b5100725fe52eb50f3aea8ce54d1a8f66be8c202",
    "edition": 1610,
    "date": 1672532029090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1610",
    "rarity_score": 161.31807794180514,
    "rarity_level": "COMMON"
  },
  "36": {
    "name": "Arkade 90s Bulls #36",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/36.png",
    "dna": "d4762b2a97390eb4c1c535787b570e32f9eddcb0",
    "edition": 36,
    "date": 1672532029898,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "36",
    "rarity_score": 105.91148321715002,
    "rarity_level": "COMMON"
  },
  "1223": {
    "name": "Arkade 90s Bulls #1223",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1223.png",
    "dna": "78a65705c8ba4d4184c38ef6c2e55863cc725ecd",
    "edition": 1223,
    "date": 1672532030692,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1223",
    "rarity_score": 56.618631407436055,
    "rarity_level": "COMMON"
  },
  "1156": {
    "name": "Arkade 90s Bulls #1156",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1156.png",
    "dna": "405f5dc5ae0e562524ee12055a6a02f795cf0107",
    "edition": 1156,
    "date": 1672532031326,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1156",
    "rarity_score": 452.858193070693,
    "rarity_level": "COMMON"
  },
  "1009": {
    "name": "Arkade 90s Bulls #1009",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1009.png",
    "dna": "5827672dfccd99f32a35d358aded11d70594263a",
    "edition": 1009,
    "date": 1672532032013,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1009",
    "rarity_score": 381.06714489737175,
    "rarity_level": "COMMON"
  },
  "797": {
    "name": "Arkade 90s Bulls #797",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/797.png",
    "dna": "a7de8f2da5da9245376889842c77cf6936ffb8f7",
    "edition": 797,
    "date": 1672532033284,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "797",
    "rarity_score": 304.2255494104953,
    "rarity_level": "COMMON"
  },
  "764": {
    "name": "Arkade 90s Bulls #764",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/764.png",
    "dna": "389e89a549c182dd62aa0637c5da89e66955d1e5",
    "edition": 764,
    "date": 1672532033986,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "764",
    "rarity_score": 147.86819793912576,
    "rarity_level": "COMMON"
  },
  "910": {
    "name": "Arkade 90s Bulls #910",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/910.png",
    "dna": "eb8a0905d6db94a44aef669f74ebf9fee24f8b3e",
    "edition": 910,
    "date": 1672532034639,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "910",
    "rarity_score": 404.8268349877597,
    "rarity_level": "COMMON"
  },
  "1662": {
    "name": "Arkade 90s Bulls #1662",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1662.png",
    "dna": "40ad40f5ffdb5ec8c0796b2d642241d650317c4d",
    "edition": 1662,
    "date": 1672532035323,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1662",
    "rarity_score": 176.34563924698614,
    "rarity_level": "COMMON"
  },
  "544": {
    "name": "Arkade 90s Bulls #544",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/544.png",
    "dna": "a699efe04abf9ff1767043f4d846ec18d5022406",
    "edition": 544,
    "date": 1672532036813,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "544",
    "rarity_score": 126.82222292851435,
    "rarity_level": "COMMON"
  },
  "1155": {
    "name": "Arkade 90s Bulls #1155",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1155.png",
    "dna": "25e9dd7f31e68a08ebd68ed878e9652e42fe6f58",
    "edition": 1155,
    "date": 1672532037519,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1155",
    "rarity_score": 191.46870257550356,
    "rarity_level": "COMMON"
  },
  "1159": {
    "name": "Arkade 90s Bulls #1159",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1159.png",
    "dna": "64c36c3e3c69a31735d81ad06b4b0008407702fb",
    "edition": 1159,
    "date": 1672532038774,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1159",
    "rarity_score": 296.6254449029655,
    "rarity_level": "COMMON"
  },
  "897": {
    "name": "Arkade 90s Bulls #897",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/897.png",
    "dna": "e0eff2280869e99cbd47bc0f24437bf73a10d7d1",
    "edition": 897,
    "date": 1672532039399,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "897",
    "rarity_score": 168.3401981628882,
    "rarity_level": "COMMON"
  },
  "1711": {
    "name": "Arkade 90s Bulls #1711",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1711.png",
    "dna": "bf7fa95620fa599d1dd75260b7bd8c4d4b263d15",
    "edition": 1711,
    "date": 1672532040015,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1711",
    "rarity_score": 242.25863938332833,
    "rarity_level": "COMMON"
  },
  "1612": {
    "name": "Arkade 90s Bulls #1612",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1612.png",
    "dna": "111ba77f27299faf9e49a02546426092c0ba4d4d",
    "edition": 1612,
    "date": 1672532040549,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1612",
    "rarity_score": 273.84098956514856,
    "rarity_level": "COMMON"
  },
  "924": {
    "name": "Arkade 90s Bulls #924",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/924.png",
    "dna": "6ce7d5eb867fcdf5e0496fb3e3939a2832eff028",
    "edition": 924,
    "date": 1672532041957,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "924",
    "rarity_score": 299.6230091088841,
    "rarity_level": "COMMON"
  },
  "1462": {
    "name": "Arkade 90s Bulls #1462",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1462.png",
    "dna": "3a2f733c4411f73605403cd78a8b1398456e39f9",
    "edition": 1462,
    "date": 1672532042577,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1462",
    "rarity_score": 330.01448530857374,
    "rarity_level": "COMMON"
  },
  "1925": {
    "name": "Arkade 90s Bulls #1925",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1925.png",
    "dna": "e4361c664bc487d4157045118eb701b374a3dd23",
    "edition": 1925,
    "date": 1672532043197,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1925",
    "rarity_score": 227.16118645691117,
    "rarity_level": "COMMON"
  },
  "1734": {
    "name": "Arkade 90s Bulls #1734",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1734.png",
    "dna": "af4f55220489e4fecc587fe09b8fe3577f9bac7d",
    "edition": 1734,
    "date": 1672532044088,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1734",
    "rarity_score": 109.20339273740181,
    "rarity_level": "COMMON"
  },
  "537": {
    "name": "Arkade 90s Bulls #537",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/537.png",
    "dna": "7bbadbf55ce85660ede19bad813c075f2509e4e0",
    "edition": 537,
    "date": 1672532044615,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "537",
    "rarity_score": 340.68237317334035,
    "rarity_level": "COMMON"
  },
  "755": {
    "name": "Arkade 90s Bulls #755",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/755.png",
    "dna": "be88ef502338c1d37a38af622e75c5c91aea1251",
    "edition": 755,
    "date": 1672532045270,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "755",
    "rarity_score": 117.30760180793406,
    "rarity_level": "COMMON"
  },
  "915": {
    "name": "Arkade 90s Bulls #915",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/915.png",
    "dna": "4384fd732bfa1ee5d2186308c336fc8b86a88250",
    "edition": 915,
    "date": 1672532046669,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "915",
    "rarity_score": 115.94907473936212,
    "rarity_level": "COMMON"
  },
  "1936": {
    "name": "Arkade 90s Bulls #1936",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1936.png",
    "dna": "80bc180b94d83e9c331a557e1b9ec5cb375ddf3c",
    "edition": 1936,
    "date": 1672532048044,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1936",
    "rarity_score": 400.4956389054597,
    "rarity_level": "COMMON"
  },
  "1073": {
    "name": "Arkade 90s Bulls #1073",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1073.png",
    "dna": "790c1a273d50c6e86239f6f523c628aec5aadfa7",
    "edition": 1073,
    "date": 1672532048709,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1073",
    "rarity_score": 224.81695879815732,
    "rarity_level": "COMMON"
  },
  "864": {
    "name": "Arkade 90s Bulls #864",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/864.png",
    "dna": "6b63bc27f7242c6ee63bcf01859d000d782e522d",
    "edition": 864,
    "date": 1672532049472,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "864",
    "rarity_score": 371.93607908038393,
    "rarity_level": "COMMON"
  },
  "1820": {
    "name": "Arkade 90s Bulls #1820",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1820.png",
    "dna": "c11bfb54d6f5836257d30bf8df6d4fbe0a148864",
    "edition": 1820,
    "date": 1672532050683,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1820",
    "rarity_score": 347.8689119535467,
    "rarity_level": "COMMON"
  },
  "902": {
    "name": "Arkade 90s Bulls #902",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/902.png",
    "dna": "336c85fe8f62211803d5c3bfae07e289693bc112",
    "edition": 902,
    "date": 1672532051317,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "902",
    "rarity_score": 113.6735744709168,
    "rarity_level": "COMMON"
  },
  "549": {
    "name": "Arkade 90s Bulls #549",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/549.png",
    "dna": "0f76f337ac5084b04a69ef0516b55b3b71840c72",
    "edition": 549,
    "date": 1672532052563,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Yellow Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "549",
    "rarity_score": 668.846010189686,
    "rarity_level": "RARE"
  },
  "870": {
    "name": "Arkade 90s Bulls #870",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/870.png",
    "dna": "70fe63e1a753c13dc29721f933714c2d95289e18",
    "edition": 870,
    "date": 1672532053229,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "870",
    "rarity_score": 263.72035727038116,
    "rarity_level": "COMMON"
  },
  "1031": {
    "name": "Arkade 90s Bulls #1031",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1031.png",
    "dna": "8dbd4e49cb67993fb63336b7f4e72bbedee128b0",
    "edition": 1031,
    "date": 1672532053849,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1031",
    "rarity_score": 514.3315073377958,
    "rarity_level": "RARE"
  },
  "763": {
    "name": "Arkade 90s Bulls #763",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/763.png",
    "dna": "6da053673badd2783680b915984a1c725b2b0d3e",
    "edition": 763,
    "date": 1672532055201,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "763",
    "rarity_score": 115.79828670137465,
    "rarity_level": "COMMON"
  },
  "192": {
    "name": "Arkade 90s Bulls #192",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/192.png",
    "dna": "65bc1c8effb309a9c2dd361460080c85cc834c70",
    "edition": 192,
    "date": 1672532055882,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "192",
    "rarity_score": 105.94289972171921,
    "rarity_level": "COMMON"
  },
  "224": {
    "name": "Arkade 90s Bulls #224",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/224.png",
    "dna": "97083a887b253419f13094f2d104eee54045e2e9",
    "edition": 224,
    "date": 1672532056588,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "224",
    "rarity_score": 151.0417739577025,
    "rarity_level": "COMMON"
  },
  "706": {
    "name": "Arkade 90s Bulls #706",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/706.png",
    "dna": "90b43e86420a5cb99891a1464a26f7c72253159a",
    "edition": 706,
    "date": 1672532057139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      { "trait_type": "Mask", "value": "Turbobull", "score": 398.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "706",
    "rarity_score": 533.3125639533686,
    "rarity_level": "RARE"
  },
  "1181": {
    "name": "Arkade 90s Bulls #1181",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1181.png",
    "dna": "dddacde5a08a14ff447915ce27f280612986cbfd",
    "edition": 1181,
    "date": 1672532057796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1181",
    "rarity_score": 250.76536763919378,
    "rarity_level": "COMMON"
  },
  "661": {
    "name": "Arkade 90s Bulls #661",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/661.png",
    "dna": "71cded6a862cc4ce5bc69d3540feac1c6b214704",
    "edition": 661,
    "date": 1672532058440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "661",
    "rarity_score": 271.3691294853922,
    "rarity_level": "COMMON"
  },
  "318": {
    "name": "Arkade 90s Bulls #318",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/318.png",
    "dna": "360b968d159d177bb8739ef0d58f3b4eaeb4cb2a",
    "edition": 318,
    "date": 1672532059070,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "318",
    "rarity_score": 120.52667194715482,
    "rarity_level": "COMMON"
  },
  "288": {
    "name": "Arkade 90s Bulls #288",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/288.png",
    "dna": "d34ce7dc84eadadef6ecb7049d118f70438dee57",
    "edition": 288,
    "date": 1672532060514,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "288",
    "rarity_score": 164.3970213468482,
    "rarity_level": "COMMON"
  },
  "1815": {
    "name": "Arkade 90s Bulls #1815",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1815.png",
    "dna": "de2df83251098ad1a2a9b9a255e8c16853940a40",
    "edition": 1815,
    "date": 1672532061274,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1815",
    "rarity_score": 596.5480723072759,
    "rarity_level": "RARE"
  },
  "1095": {
    "name": "Arkade 90s Bulls #1095",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1095.png",
    "dna": "11f88bcfd9515d694e4487e5de585c5aa3813529",
    "edition": 1095,
    "date": 1672532061906,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1095",
    "rarity_score": 260.1981356172524,
    "rarity_level": "COMMON"
  },
  "308": {
    "name": "Arkade 90s Bulls #308",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/308.png",
    "dna": "e51ce09e940ce167532acda2d21af54097ffcbc6",
    "edition": 308,
    "date": 1672532063294,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "308",
    "rarity_score": 330.3837300243137,
    "rarity_level": "COMMON"
  },
  "322": {
    "name": "Arkade 90s Bulls #322",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/322.png",
    "dna": "77c09210e6b44cc13342a5b6477bdd036b8f297f",
    "edition": 322,
    "date": 1672532064038,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "322",
    "rarity_score": 275.7977986466735,
    "rarity_level": "COMMON"
  },
  "1402": {
    "name": "Arkade 90s Bulls #1402",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1402.png",
    "dna": "4d852bd96a5fc71c25ef5759932ab6cb9a0ce940",
    "edition": 1402,
    "date": 1672532064936,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1402",
    "rarity_score": 231.15815562102372,
    "rarity_level": "COMMON"
  },
  "907": {
    "name": "Arkade 90s Bulls #907",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/907.png",
    "dna": "e1842ec84a423acd5fff1d909736f36eb0af9dba",
    "edition": 907,
    "date": 1672532065566,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "907",
    "rarity_score": 307.25728957062836,
    "rarity_level": "COMMON"
  },
  "497": {
    "name": "Arkade 90s Bulls #497",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/497.png",
    "dna": "6bc021ee13e2b9d3c4c47cccbf808ec3f67f4db7",
    "edition": 497,
    "date": 1672532066220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "497",
    "rarity_score": 208.20442875217202,
    "rarity_level": "COMMON"
  },
  "1673": {
    "name": "Arkade 90s Bulls #1673",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1673.png",
    "dna": "1fc5d9838e84a955dcc132e9c9d9aa11bf7051f5",
    "edition": 1673,
    "date": 1672532066910,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1673",
    "rarity_score": 166.29105631087276,
    "rarity_level": "COMMON"
  },
  "463": {
    "name": "Arkade 90s Bulls #463",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/463.png",
    "dna": "b1e42cdd3accb8dd0b91f324c82e71d2fc5299ab",
    "edition": 463,
    "date": 1672532068356,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "463",
    "rarity_score": 172.98210562212964,
    "rarity_level": "COMMON"
  },
  "700": {
    "name": "Arkade 90s Bulls #700",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/700.png",
    "dna": "82b72d8cee618706541f466ad91fbd5c8150d780",
    "edition": 700,
    "date": 1672532069738,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "700",
    "rarity_score": 231.2179547113575,
    "rarity_level": "COMMON"
  },
  "1512": {
    "name": "Arkade 90s Bulls #1512",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1512.png",
    "dna": "8e59bec01c743e033acbb5ecf77b6c713bb06da8",
    "edition": 1512,
    "date": 1672532070606,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1512",
    "rarity_score": 274.2656099962022,
    "rarity_level": "COMMON"
  },
  "692": {
    "name": "Arkade 90s Bulls #692",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/692.png",
    "dna": "db70271872e2d758170e7b1aa99dd8603de6916f",
    "edition": 692,
    "date": 1672532071331,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "692",
    "rarity_score": 158.78782719851594,
    "rarity_level": "COMMON"
  },
  "621": {
    "name": "Arkade 90s Bulls #621",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/621.png",
    "dna": "45c47e3c14b78645d2c5a5cf67ac2df98205d540",
    "edition": 621,
    "date": 1672532072039,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "621",
    "rarity_score": 223.87125758951967,
    "rarity_level": "COMMON"
  },
  "286": {
    "name": "Arkade 90s Bulls #286",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/286.png",
    "dna": "ae5c4e49da2fdef17e05f5223bfe876e689c9b20",
    "edition": 286,
    "date": 1672532072717,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "286",
    "rarity_score": 213.3267922221451,
    "rarity_level": "COMMON"
  },
  "1367": {
    "name": "Arkade 90s Bulls #1367",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1367.png",
    "dna": "f1ae3da7cceb9e9147c6aaf4d21e86f7edde38f0",
    "edition": 1367,
    "date": 1672532073465,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1367",
    "rarity_score": 213.82394627283492,
    "rarity_level": "COMMON"
  },
  "548": {
    "name": "Arkade 90s Bulls #548",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/548.png",
    "dna": "20f53a344cd4b299db942fdbe3bced2289e7b107",
    "edition": 548,
    "date": 1672532075104,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "548",
    "rarity_score": 432.29939238615117,
    "rarity_level": "COMMON"
  },
  "707": {
    "name": "Arkade 90s Bulls #707",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/707.png",
    "dna": "fe78dfb797a69fd0d897f5da28e6e17489865dd3",
    "edition": 707,
    "date": 1672532076429,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "707",
    "rarity_score": 326.22736333631104,
    "rarity_level": "COMMON"
  },
  "1607": {
    "name": "Arkade 90s Bulls #1607",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1607.png",
    "dna": "4b55615862377ce1d18519cee90ad2bc99ca25c0",
    "edition": 1607,
    "date": 1672532077101,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1607",
    "rarity_score": 119.31698729211716,
    "rarity_level": "COMMON"
  },
  "1730": {
    "name": "Arkade 90s Bulls #1730",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1730.png",
    "dna": "2d7c95627cb2bc69d634b7c0a0132dbc3356e349",
    "edition": 1730,
    "date": 1672532077755,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1730",
    "rarity_score": 119.20984943870725,
    "rarity_level": "COMMON"
  },
  "595": {
    "name": "Arkade 90s Bulls #595",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/595.png",
    "dna": "569a2334ce8cc4deb6d103b4745d6fa19efbde7e",
    "edition": 595,
    "date": 1672532078389,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "595",
    "rarity_score": 203.81813017531815,
    "rarity_level": "COMMON"
  },
  "130": {
    "name": "Arkade 90s Bulls #130",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/130.png",
    "dna": "44684079999e3988f04d9e46310e9fd67cb60270",
    "edition": 130,
    "date": 1672532079152,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "130",
    "rarity_score": 233.74023460366294,
    "rarity_level": "COMMON"
  },
  "136": {
    "name": "Arkade 90s Bulls #136",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/136.png",
    "dna": "62a023247e99610433251f1ce7b9f01670a94ad0",
    "edition": 136,
    "date": 1672532079814,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "136",
    "rarity_score": 174.31277534491613,
    "rarity_level": "COMMON"
  },
  "1505": {
    "name": "Arkade 90s Bulls #1505",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1505.png",
    "dna": "4982963871eb32573dc53d427072675b7954eebe",
    "edition": 1505,
    "date": 1672532081180,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1505",
    "rarity_score": 405.286455393985,
    "rarity_level": "COMMON"
  },
  "665": {
    "name": "Arkade 90s Bulls #665",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/665.png",
    "dna": "76dcfc019bc94d4fd8c62e64605bd18a9c09e24c",
    "edition": 665,
    "date": 1672532081827,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "665",
    "rarity_score": 277.1967775862805,
    "rarity_level": "COMMON"
  },
  "1750": {
    "name": "Arkade 90s Bulls #1750",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1750.png",
    "dna": "3bbe421070a7557f828c26434e2b34694b92e59e",
    "edition": 1750,
    "date": 1672532082845,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Tunic",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1750",
    "rarity_score": 785.4122242345186,
    "rarity_level": "RARE"
  },
  "1114": {
    "name": "Arkade 90s Bulls #1114",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1114.png",
    "dna": "bb558560cb50def775f6c0a0e171f676ed6e5c77",
    "edition": 1114,
    "date": 1672532083492,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1114",
    "rarity_score": 358.2822439644925,
    "rarity_level": "COMMON"
  },
  "1481": {
    "name": "Arkade 90s Bulls #1481",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1481.png",
    "dna": "fd63dc658676d7fb83cec791a83ab33592d6dd38",
    "edition": 1481,
    "date": 1672532084939,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1481",
    "rarity_score": 207.8824760176144,
    "rarity_level": "COMMON"
  },
  "738": {
    "name": "Arkade 90s Bulls #738",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/738.png",
    "dna": "eca2382d48135fab694e09437f1648ee8e7bcacf",
    "edition": 738,
    "date": 1672532085570,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "738",
    "rarity_score": 239.40969903780953,
    "rarity_level": "COMMON"
  },
  "685": {
    "name": "Arkade 90s Bulls #685",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/685.png",
    "dna": "83cf82088545e8a5565f782af00c50593419cc4b",
    "edition": 685,
    "date": 1672532086525,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "685",
    "rarity_score": 221.0203680959356,
    "rarity_level": "COMMON"
  },
  "1363": {
    "name": "Arkade 90s Bulls #1363",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1363.png",
    "dna": "6650c51423ba8b3ac0dd870d0bfdd652713c13a4",
    "edition": 1363,
    "date": 1672532087200,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1363",
    "rarity_score": 180.06301309175134,
    "rarity_level": "COMMON"
  },
  "1656": {
    "name": "Arkade 90s Bulls #1656",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1656.png",
    "dna": "73bcd2c670122b552ca34787637c2e9c6ace81b6",
    "edition": 1656,
    "date": 1672532088392,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1656",
    "rarity_score": 192.1589964818887,
    "rarity_level": "COMMON"
  },
  "1832": {
    "name": "Arkade 90s Bulls #1832",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1832.png",
    "dna": "feee1af89d133e33860af20dbc55dcdec8225313",
    "edition": 1832,
    "date": 1672532089351,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1832",
    "rarity_score": 237.75903078248365,
    "rarity_level": "COMMON"
  },
  "889": {
    "name": "Arkade 90s Bulls #889",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/889.png",
    "dna": "615eb660c5a3a0fdcc77358c40d58c0316c4444d",
    "edition": 889,
    "date": 1672532090120,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "889",
    "rarity_score": 256.051631797846,
    "rarity_level": "COMMON"
  },
  "512": {
    "name": "Arkade 90s Bulls #512",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/512.png",
    "dna": "878c74a6e17e48c8c8f6eb74d54389056c6d48c5",
    "edition": 512,
    "date": 1672532090781,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      { "trait_type": "Head", "value": "Superbull Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "512",
    "rarity_score": 589.5834349824894,
    "rarity_level": "RARE"
  },
  "995": {
    "name": "Arkade 90s Bulls #995",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/995.png",
    "dna": "184aac420f96387798bd69d7789b26605db39f65",
    "edition": 995,
    "date": 1672532091690,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "995",
    "rarity_score": 223.47225185911861,
    "rarity_level": "COMMON"
  },
  "1469": {
    "name": "Arkade 90s Bulls #1469",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1469.png",
    "dna": "bd49bcd7d5a0f1badf85a802814e4251167589d7",
    "edition": 1469,
    "date": 1672532093053,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1469",
    "rarity_score": 320.46347802027003,
    "rarity_level": "COMMON"
  },
  "1842": {
    "name": "Arkade 90s Bulls #1842",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1842.png",
    "dna": "adbbc6ff7ca42ca4af4b486e9c5a8d306c4dc7ac",
    "edition": 1842,
    "date": 1672532093912,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1842",
    "rarity_score": 245.21855305847436,
    "rarity_level": "COMMON"
  },
  "1440": {
    "name": "Arkade 90s Bulls #1440",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1440.png",
    "dna": "343a9b114057a124a27bbaa7f5edba319e1da059",
    "edition": 1440,
    "date": 1672532094751,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1440",
    "rarity_score": 112.66621536586328,
    "rarity_level": "COMMON"
  },
  "1015": {
    "name": "Arkade 90s Bulls #1015",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1015.png",
    "dna": "dd27de675d2cd3e9b169998a1ce5ee5558f0b49f",
    "edition": 1015,
    "date": 1672532095433,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1015",
    "rarity_score": 149.39496723290986,
    "rarity_level": "COMMON"
  },
  "606": {
    "name": "Arkade 90s Bulls #606",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/606.png",
    "dna": "9f90b41e236bd11702dec497173405f6dbea3916",
    "edition": 606,
    "date": 1672532096085,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "606",
    "rarity_score": 413.57560844810735,
    "rarity_level": "COMMON"
  },
  "1370": {
    "name": "Arkade 90s Bulls #1370",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1370.png",
    "dna": "feed8302098505e2271ea623ecc4846cbf259a26",
    "edition": 1370,
    "date": 1672532096821,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1370",
    "rarity_score": 240.2741063740519,
    "rarity_level": "COMMON"
  },
  "39": {
    "name": "Arkade 90s Bulls #39",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/39.png",
    "dna": "88a04139eb417145825a0c679a1f5918413ebe95",
    "edition": 39,
    "date": 1672532097433,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "39",
    "rarity_score": 239.2896320552508,
    "rarity_level": "COMMON"
  },
  "474": {
    "name": "Arkade 90s Bulls #474",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/474.png",
    "dna": "550dcae7c8a189f988fd29528a309f6c941b41e9",
    "edition": 474,
    "date": 1672532098071,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Yuki Muko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "474",
    "rarity_score": 599.1495385819086,
    "rarity_level": "RARE"
  },
  "713": {
    "name": "Arkade 90s Bulls #713",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/713.png",
    "dna": "2ebbc6e8bcc1ded321f4e80e1e23cac77841c6b4",
    "edition": 713,
    "date": 1672532098730,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "713",
    "rarity_score": 241.49788863158687,
    "rarity_level": "COMMON"
  },
  "830": {
    "name": "Arkade 90s Bulls #830",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/830.png",
    "dna": "adf70017c9c174d8e960de9498ce2bbd933ab8ce",
    "edition": 830,
    "date": 1672532099445,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "830",
    "rarity_score": 486.80940871200954,
    "rarity_level": "RARE"
  },
  "1483": {
    "name": "Arkade 90s Bulls #1483",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1483.png",
    "dna": "092a62eddc47b1d25fb7556c066d0c08d20f3930",
    "edition": 1483,
    "date": 1672532100088,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1483",
    "rarity_score": 380.86958253614523,
    "rarity_level": "COMMON"
  },
  "1352": {
    "name": "Arkade 90s Bulls #1352",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1352.png",
    "dna": "901af877e2bceefcc7feed28ee82a4c74690225f",
    "edition": 1352,
    "date": 1672532100729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1352",
    "rarity_score": 164.43107319505603,
    "rarity_level": "COMMON"
  },
  "531": {
    "name": "Arkade 90s Bulls #531",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/531.png",
    "dna": "e490dbc55cf3498247b5444a35440a1517d5dba2",
    "edition": 531,
    "date": 1672532101479,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Spinner", "score": 398.0 },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "531",
    "rarity_score": 540.1349143673142,
    "rarity_level": "RARE"
  },
  "890": {
    "name": "Arkade 90s Bulls #890",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/890.png",
    "dna": "4e46f3ca543bd9d5515414e79d8f2a70318a24eb",
    "edition": 890,
    "date": 1672532102110,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "890",
    "rarity_score": 310.7115336064136,
    "rarity_level": "COMMON"
  },
  "452": {
    "name": "Arkade 90s Bulls #452",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/452.png",
    "dna": "d7fc953e9234d30eb20285581a08c9aab8a70824",
    "edition": 452,
    "date": 1672532102891,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "452",
    "rarity_score": 242.52240717242103,
    "rarity_level": "COMMON"
  },
  "527": {
    "name": "Arkade 90s Bulls #527",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/527.png",
    "dna": "2221b1e822a034ca36d6835fdc8a9ee12e23cda1",
    "edition": 527,
    "date": 1672532103529,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "527",
    "rarity_score": 237.80363535468297,
    "rarity_level": "COMMON"
  },
  "1939": {
    "name": "Arkade 90s Bulls #1939",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1939.png",
    "dna": "635022709538b69a40bec15ca3b576fa3f3936ca",
    "edition": 1939,
    "date": 1672532104334,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1939",
    "rarity_score": 241.9645106771642,
    "rarity_level": "COMMON"
  },
  "623": {
    "name": "Arkade 90s Bulls #623",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/623.png",
    "dna": "3ef70b01085f1a16812b7e70ffd31e6bf5bdffc9",
    "edition": 623,
    "date": 1672532105031,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "623",
    "rarity_score": 190.82428644144164,
    "rarity_level": "COMMON"
  },
  "1242": {
    "name": "Arkade 90s Bulls #1242",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1242.png",
    "dna": "83f3069aefb3c1dde559dd75abe9fdfca713e675",
    "edition": 1242,
    "date": 1672532106400,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1242",
    "rarity_score": 272.27341903972905,
    "rarity_level": "COMMON"
  },
  "1752": {
    "name": "Arkade 90s Bulls #1752",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1752.png",
    "dna": "fdd27532860f6923466d7b75f29cf349dac16cbf",
    "edition": 1752,
    "date": 1672532107350,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1752",
    "rarity_score": 233.37801452830954,
    "rarity_level": "COMMON"
  },
  "395": {
    "name": "Arkade 90s Bulls #395",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/395.png",
    "dna": "0d8e7b606a041cfa72858bf88a103683bf86a06b",
    "edition": 395,
    "date": 1672532108192,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "395",
    "rarity_score": 282.3468384702756,
    "rarity_level": "COMMON"
  },
  "203": {
    "name": "Arkade 90s Bulls #203",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/203.png",
    "dna": "7ccc97f694a6c8ccfa8966f2d8d89c9269f52d2a",
    "edition": 203,
    "date": 1672532108856,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "203",
    "rarity_score": 264.30072007274964,
    "rarity_level": "COMMON"
  },
  "1353": {
    "name": "Arkade 90s Bulls #1353",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1353.png",
    "dna": "973801011791f7c99af81a2478335587e4eb3b62",
    "edition": 1353,
    "date": 1672532109603,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1353",
    "rarity_score": 262.9428780299586,
    "rarity_level": "COMMON"
  },
  "735": {
    "name": "Arkade 90s Bulls #735",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/735.png",
    "dna": "e10bcd2ba4c54d90f99d42b79a54f25699eef2e0",
    "edition": 735,
    "date": 1672532110390,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "735",
    "rarity_score": 247.0446642800438,
    "rarity_level": "COMMON"
  },
  "1923": {
    "name": "Arkade 90s Bulls #1923",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1923.png",
    "dna": "43972cc63603af806182064c2a9f40e4aca31266",
    "edition": 1923,
    "date": 1672532111895,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1923",
    "rarity_score": 185.6315291002175,
    "rarity_level": "COMMON"
  },
  "1068": {
    "name": "Arkade 90s Bulls #1068",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1068.png",
    "dna": "79c8949adf4fafe5f5e8eb07cbf54ff9201ef3a9",
    "edition": 1068,
    "date": 1672532112664,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1068",
    "rarity_score": 365.8264985269532,
    "rarity_level": "COMMON"
  },
  "1060": {
    "name": "Arkade 90s Bulls #1060",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1060.png",
    "dna": "c88ba4ce813fe59e8fa688f4068ce5304e4546b6",
    "edition": 1060,
    "date": 1672532113421,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1060",
    "rarity_score": 309.67500876518545,
    "rarity_level": "COMMON"
  },
  "1522": {
    "name": "Arkade 90s Bulls #1522",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1522.png",
    "dna": "97ea7220a93214e44cdd5bd89219d60d643a2798",
    "edition": 1522,
    "date": 1672532114113,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "UFO",
        "score": 663.3333333333334
      }
    ],
    "id": "1522",
    "rarity_score": 1013.1671161356842,
    "rarity_level": "RARE"
  },
  "1566": {
    "name": "Arkade 90s Bulls #1566",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1566.png",
    "dna": "abfc1a53244346c4078f25d94f6e9cb5192e861f",
    "edition": 1566,
    "date": 1672532114771,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1566",
    "rarity_score": 256.9401929034994,
    "rarity_level": "COMMON"
  },
  "205": {
    "name": "Arkade 90s Bulls #205",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/205.png",
    "dna": "e0bef2dc66d3da222f32ac39ac6b6343e7b14d0f",
    "edition": 205,
    "date": 1672532115436,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "205",
    "rarity_score": 178.05380964064156,
    "rarity_level": "COMMON"
  },
  "1096": {
    "name": "Arkade 90s Bulls #1096",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1096.png",
    "dna": "9d88d8d8d6d1cfbfedb0c58353cf1c141dda47e3",
    "edition": 1096,
    "date": 1672532116235,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1096",
    "rarity_score": 165.78333972039584,
    "rarity_level": "COMMON"
  },
  "1649": {
    "name": "Arkade 90s Bulls #1649",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1649.png",
    "dna": "5d37177ed96447c5b1e6d77c490bb547ed5528fb",
    "edition": 1649,
    "date": 1672532116893,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1649",
    "rarity_score": 265.7889844561201,
    "rarity_level": "COMMON"
  },
  "957": {
    "name": "Arkade 90s Bulls #957",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/957.png",
    "dna": "a1c24ed5d1d92fcc8663e2cc725cd192c680a86a",
    "edition": 957,
    "date": 1672532117864,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "957",
    "rarity_score": 246.09388706307897,
    "rarity_level": "COMMON"
  },
  "1446": {
    "name": "Arkade 90s Bulls #1446",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1446.png",
    "dna": "848a3fb985ce17af65ea5b25aa4cce9b5e35a877",
    "edition": 1446,
    "date": 1672532118514,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1446",
    "rarity_score": 282.1389589061273,
    "rarity_level": "COMMON"
  },
  "1783": {
    "name": "Arkade 90s Bulls #1783",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1783.png",
    "dna": "041af07ba6c5fd5fc64eab8d70ab22b792d41d1a",
    "edition": 1783,
    "date": 1672532119237,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1783",
    "rarity_score": 522.1675521909706,
    "rarity_level": "RARE"
  },
  "1660": {
    "name": "Arkade 90s Bulls #1660",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1660.png",
    "dna": "e0b371a7b94c44c8d6de170d2c3a9b20da57470b",
    "edition": 1660,
    "date": 1672532119959,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1660",
    "rarity_score": 173.33942137417318,
    "rarity_level": "COMMON"
  },
  "116": {
    "name": "Arkade 90s Bulls #116",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/116.png",
    "dna": "ec118f7351ea26d3ea39c3fb3d61c06fc2a08cdd",
    "edition": 116,
    "date": 1672532120599,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "116",
    "rarity_score": 198.33443084533914,
    "rarity_level": "COMMON"
  },
  "1245": {
    "name": "Arkade 90s Bulls #1245",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1245.png",
    "dna": "814617d3f172e8fb2782a9466fa46f6d00deed89",
    "edition": 1245,
    "date": 1672532121325,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1245",
    "rarity_score": 128.7594369714743,
    "rarity_level": "COMMON"
  },
  "1474": {
    "name": "Arkade 90s Bulls #1474",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1474.png",
    "dna": "bc6fc938f157af8f324620dc03eacae95a5b2c09",
    "edition": 1474,
    "date": 1672532121953,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1474",
    "rarity_score": 363.1546562078384,
    "rarity_level": "COMMON"
  },
  "1293": {
    "name": "Arkade 90s Bulls #1293",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1293.png",
    "dna": "f3d10aecc6cad4620889dd61bc409bcbc09ebd5b",
    "edition": 1293,
    "date": 1672532122997,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1293",
    "rarity_score": 248.29338448309827,
    "rarity_level": "COMMON"
  },
  "1063": {
    "name": "Arkade 90s Bulls #1063",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1063.png",
    "dna": "dcd682e5b8f4916867380100efe2b7f784d5f084",
    "edition": 1063,
    "date": 1672532123802,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1063",
    "rarity_score": 276.6558657096149,
    "rarity_level": "COMMON"
  },
  "161": {
    "name": "Arkade 90s Bulls #161",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/161.png",
    "dna": "8bd27af54ca3a695773ccfe0625a26266a78c081",
    "edition": 161,
    "date": 1672532124515,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "161",
    "rarity_score": 161.38653293241913,
    "rarity_level": "COMMON"
  },
  "382": {
    "name": "Arkade 90s Bulls #382",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/382.png",
    "dna": "cc5880267396566d7f7e25c1e86df7dd607794ae",
    "edition": 382,
    "date": 1672532125374,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "382",
    "rarity_score": 175.716035879501,
    "rarity_level": "COMMON"
  },
  "1347": {
    "name": "Arkade 90s Bulls #1347",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1347.png",
    "dna": "0281075804547b584c4acd379c9933f45426865e",
    "edition": 1347,
    "date": 1672532126193,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1347",
    "rarity_score": 219.4427072359613,
    "rarity_level": "COMMON"
  },
  "54": {
    "name": "Arkade 90s Bulls #54",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/54.png",
    "dna": "d5baa5ed58e7c56b4e838df8846a53106205551c",
    "edition": 54,
    "date": 1672532126860,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "54",
    "rarity_score": 222.91418536989025,
    "rarity_level": "COMMON"
  },
  "1689": {
    "name": "Arkade 90s Bulls #1689",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1689.png",
    "dna": "2dfcbfbbc072394e0bdd7de02b86989a0a7074fa",
    "edition": 1689,
    "date": 1672532127520,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1689",
    "rarity_score": 237.0036139055541,
    "rarity_level": "COMMON"
  },
  "96": {
    "name": "Arkade 90s Bulls #96",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/96.png",
    "dna": "380d17379b916e0382e5e73f5db1c004ce9bab53",
    "edition": 96,
    "date": 1672532128620,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "96",
    "rarity_score": 430.1523087783591,
    "rarity_level": "COMMON"
  },
  "904": {
    "name": "Arkade 90s Bulls #904",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/904.png",
    "dna": "5e142f3b6d530e295351712790a2a22ea7eae2fb",
    "edition": 904,
    "date": 1672532129431,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "904",
    "rarity_score": 107.94947856615185,
    "rarity_level": "COMMON"
  },
  "1263": {
    "name": "Arkade 90s Bulls #1263",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1263.png",
    "dna": "60045226b05b3b84c0c36917c233e3729ed0099e",
    "edition": 1263,
    "date": 1672532130108,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1263",
    "rarity_score": 142.75504876013977,
    "rarity_level": "COMMON"
  },
  "532": {
    "name": "Arkade 90s Bulls #532",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/532.png",
    "dna": "7746c557db57ae5dc44a35faac3f8f601c04bb2a",
    "edition": 532,
    "date": 1672532131470,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "532",
    "rarity_score": 329.89662081606986,
    "rarity_level": "COMMON"
  },
  "1686": {
    "name": "Arkade 90s Bulls #1686",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1686.png",
    "dna": "f9a538470067e457810d52be6ad5bb23085924ed",
    "edition": 1686,
    "date": 1672532132134,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1686",
    "rarity_score": 374.6382657636883,
    "rarity_level": "COMMON"
  },
  "335": {
    "name": "Arkade 90s Bulls #335",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/335.png",
    "dna": "10cafbcbb7c3bf33677febecf0c41e6968d8bc96",
    "edition": 335,
    "date": 1672532132763,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "335",
    "rarity_score": 397.61706831903115,
    "rarity_level": "COMMON"
  },
  "1050": {
    "name": "Arkade 90s Bulls #1050",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1050.png",
    "dna": "d10583ae4f821a05141fd86d99aae8dec5a17dd3",
    "edition": 1050,
    "date": 1672532134064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1050",
    "rarity_score": 268.1567426933137,
    "rarity_level": "COMMON"
  },
  "1260": {
    "name": "Arkade 90s Bulls #1260",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1260.png",
    "dna": "f8934f98988ac9c22e47a724ba096bb1ecb962c6",
    "edition": 1260,
    "date": 1672532134879,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1260",
    "rarity_score": 171.89822101230604,
    "rarity_level": "COMMON"
  },
  "583": {
    "name": "Arkade 90s Bulls #583",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/583.png",
    "dna": "dcfad6635308041ff5105b9b48d28c3e0ba50b1e",
    "edition": 583,
    "date": 1672532135514,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "583",
    "rarity_score": 220.86008303370596,
    "rarity_level": "COMMON"
  },
  "1005": {
    "name": "Arkade 90s Bulls #1005",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1005.png",
    "dna": "f7ab118c41fcff79ca280fd0e4453afa5e46fafb",
    "edition": 1005,
    "date": 1672532136246,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "1005",
    "rarity_score": 594.4338021566017,
    "rarity_level": "RARE"
  },
  "629": {
    "name": "Arkade 90s Bulls #629",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/629.png",
    "dna": "a98bd6e25c63b3f4fc9a97afa8b5656607d30071",
    "edition": 629,
    "date": 1672532136916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "629",
    "rarity_score": 184.11487646049102,
    "rarity_level": "COMMON"
  },
  "1257": {
    "name": "Arkade 90s Bulls #1257",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1257.png",
    "dna": "cdb8362c67ae79d345cc6298fff4c39ecdd71e87",
    "edition": 1257,
    "date": 1672532138276,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      { "trait_type": "Head", "value": "Football Head", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1257",
    "rarity_score": 599.9890577342433,
    "rarity_level": "RARE"
  },
  "1749": {
    "name": "Arkade 90s Bulls #1749",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1749.png",
    "dna": "91ad55859795fb6fff80d120cb772b6dee665f98",
    "edition": 1749,
    "date": 1672532138968,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1749",
    "rarity_score": 128.59411158725516,
    "rarity_level": "COMMON"
  },
  "1895": {
    "name": "Arkade 90s Bulls #1895",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1895.png",
    "dna": "96edb13504b4b1631e8259ddb949a2b17383b331",
    "edition": 1895,
    "date": 1672532140217,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1895",
    "rarity_score": 210.48743120632838,
    "rarity_level": "COMMON"
  },
  "1311": {
    "name": "Arkade 90s Bulls #1311",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1311.png",
    "dna": "644555b683ff42453c43be266d604c7446e9c273",
    "edition": 1311,
    "date": 1672532141041,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1311",
    "rarity_score": 267.8007876000961,
    "rarity_level": "COMMON"
  },
  "574": {
    "name": "Arkade 90s Bulls #574",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/574.png",
    "dna": "c0c384c4446b16b58694c3d24ac3f837aaaa3654",
    "edition": 574,
    "date": 1672532141700,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "574",
    "rarity_score": 289.22981613366113,
    "rarity_level": "COMMON"
  },
  "166": {
    "name": "Arkade 90s Bulls #166",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/166.png",
    "dna": "a7b28cd6ebe014895ffbce33d40c8b89d817fb0f",
    "edition": 166,
    "date": 1672532142443,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "166",
    "rarity_score": 126.69054601204334,
    "rarity_level": "COMMON"
  },
  "1811": {
    "name": "Arkade 90s Bulls #1811",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1811.png",
    "dna": "7c42b3fee4cacfb691a155e2603b11ee570fff58",
    "edition": 1811,
    "date": 1672532143170,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1811",
    "rarity_score": 300.64866477847806,
    "rarity_level": "COMMON"
  },
  "1776": {
    "name": "Arkade 90s Bulls #1776",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1776.png",
    "dna": "21ae5de07db54619b57b5f42dbc479d406642b1c",
    "edition": 1776,
    "date": 1672532143852,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1776",
    "rarity_score": 323.58834506799786,
    "rarity_level": "COMMON"
  },
  "1000": {
    "name": "Arkade 90s Bulls #1000",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1000.png",
    "dna": "151a7a4f31444b244cf024d5551a2962ec026779",
    "edition": 1000,
    "date": 1672532144589,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1000",
    "rarity_score": 268.68328534911353,
    "rarity_level": "COMMON"
  },
  "913": {
    "name": "Arkade 90s Bulls #913",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/913.png",
    "dna": "2f30f99b75a897d27e415234fce84974e8e7b034",
    "edition": 913,
    "date": 1672532145649,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "913",
    "rarity_score": 305.123552961593,
    "rarity_level": "COMMON"
  },
  "34": {
    "name": "Arkade 90s Bulls #34",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/34.png",
    "dna": "7eebd55306a51fa05ec510c730b92a479ee21b28",
    "edition": 34,
    "date": 1672532146475,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "34",
    "rarity_score": 172.9692587470466,
    "rarity_level": "COMMON"
  },
  "1104": {
    "name": "Arkade 90s Bulls #1104",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1104.png",
    "dna": "ff6da827d4a56f4001217b34267eae059db4c2e8",
    "edition": 1104,
    "date": 1672532147172,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1104",
    "rarity_score": 542.3486321058201,
    "rarity_level": "RARE"
  },
  "137": {
    "name": "Arkade 90s Bulls #137",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/137.png",
    "dna": "a95d8977d386ef7844b743da4541ecdaf2fda1b1",
    "edition": 137,
    "date": 1672532148418,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "137",
    "rarity_score": 699.2517458874945,
    "rarity_level": "RARE"
  },
  "1881": {
    "name": "Arkade 90s Bulls #1881",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1881.png",
    "dna": "4f58344eda17ab07ef28a68a61230d05519f3938",
    "edition": 1881,
    "date": 1672532149113,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1881",
    "rarity_score": 302.7792179821457,
    "rarity_level": "COMMON"
  },
  "1503": {
    "name": "Arkade 90s Bulls #1503",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1503.png",
    "dna": "86bb4b0fc6cc8c0ac96ab250935b0b2ebe4d34c0",
    "edition": 1503,
    "date": 1672532150346,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1503",
    "rarity_score": 244.64383649395427,
    "rarity_level": "COMMON"
  },
  "838": {
    "name": "Arkade 90s Bulls #838",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/838.png",
    "dna": "b28dbdbd047446246e2d9f1bacd2f9fc2e06622b",
    "edition": 838,
    "date": 1672532151295,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Red Tunic",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "838",
    "rarity_score": 1078.409884535935,
    "rarity_level": "EPIC"
  },
  "426": {
    "name": "Arkade 90s Bulls #426",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/426.png",
    "dna": "df8d3631665c6e007b1874d213223386edc6ac96",
    "edition": 426,
    "date": 1672532151992,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "426",
    "rarity_score": 187.13015357723032,
    "rarity_level": "COMMON"
  },
  "1267": {
    "name": "Arkade 90s Bulls #1267",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1267.png",
    "dna": "5c465996804f67aabf6ef10711a8e5943c392992",
    "edition": 1267,
    "date": 1672532152738,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1267",
    "rarity_score": 230.41985232326337,
    "rarity_level": "COMMON"
  },
  "374": {
    "name": "Arkade 90s Bulls #374",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/374.png",
    "dna": "2412aafd153e0a64673f21b5ed62dda3ebe90a2d",
    "edition": 374,
    "date": 1672532153466,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "374",
    "rarity_score": 255.17137712217962,
    "rarity_level": "COMMON"
  },
  "1262": {
    "name": "Arkade 90s Bulls #1262",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1262.png",
    "dna": "8a32083fdac009ea5ef26033d8e3f415718f6c25",
    "edition": 1262,
    "date": 1672532154135,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1262",
    "rarity_score": 322.6767652475542,
    "rarity_level": "COMMON"
  },
  "554": {
    "name": "Arkade 90s Bulls #554",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/554.png",
    "dna": "3724367b14ef611470d52e94ff05683e5167b092",
    "edition": 554,
    "date": 1672532154810,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "554",
    "rarity_score": 186.16598638486144,
    "rarity_level": "COMMON"
  },
  "1271": {
    "name": "Arkade 90s Bulls #1271",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1271.png",
    "dna": "f5a749e5d0cf3ecfb7c5432d4a9e6e718c624d68",
    "edition": 1271,
    "date": 1672532156175,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1271",
    "rarity_score": 376.8825567340919,
    "rarity_level": "COMMON"
  },
  "535": {
    "name": "Arkade 90s Bulls #535",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/535.png",
    "dna": "3bbc0e7618530c09c4186d952dcc57c6de0875c0",
    "edition": 535,
    "date": 1672532157004,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "535",
    "rarity_score": 179.9714467870245,
    "rarity_level": "COMMON"
  },
  "569": {
    "name": "Arkade 90s Bulls #569",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/569.png",
    "dna": "89ccac54ec17dd2918f0630f9bf1016f6317dae2",
    "edition": 569,
    "date": 1672532158005,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "569",
    "rarity_score": 229.25548139912271,
    "rarity_level": "COMMON"
  },
  "1917": {
    "name": "Arkade 90s Bulls #1917",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1917.png",
    "dna": "e584e64dbcbc5819cef6c65bcf60c9c7824161bc",
    "edition": 1917,
    "date": 1672532158887,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1917",
    "rarity_score": 298.2409998531534,
    "rarity_level": "COMMON"
  },
  "466": {
    "name": "Arkade 90s Bulls #466",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/466.png",
    "dna": "1a8ce045fa3d9e45490149cf44470343a823a508",
    "edition": 466,
    "date": 1672532159558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "466",
    "rarity_score": 290.2642220294167,
    "rarity_level": "COMMON"
  },
  "1963": {
    "name": "Arkade 90s Bulls #1963",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1963.png",
    "dna": "86dbf9704d056e232871e3b664115b15b4ab06ef",
    "edition": 1963,
    "date": 1672532160206,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1963",
    "rarity_score": 234.79105302580294,
    "rarity_level": "COMMON"
  },
  "57": {
    "name": "Arkade 90s Bulls #57",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/57.png",
    "dna": "a1726e0c8582cd348a63cd9dd6e38e95902558f2",
    "edition": 57,
    "date": 1672532160918,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "57",
    "rarity_score": 272.84676313088227,
    "rarity_level": "COMMON"
  },
  "406": {
    "name": "Arkade 90s Bulls #406",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/406.png",
    "dna": "5c77ea1a3b4172c07d4a364361569a02b599206c",
    "edition": 406,
    "date": 1672532161677,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "406",
    "rarity_score": 261.5575248356555,
    "rarity_level": "COMMON"
  },
  "1268": {
    "name": "Arkade 90s Bulls #1268",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1268.png",
    "dna": "26b4808698a91287dff2fe2b58d4967482e5bac8",
    "edition": 1268,
    "date": 1672532162379,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1268",
    "rarity_score": 191.6041637106706,
    "rarity_level": "COMMON"
  },
  "1340": {
    "name": "Arkade 90s Bulls #1340",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1340.png",
    "dna": "fadbce60afc0102e7f99eb1f80cd5208b363cc34",
    "edition": 1340,
    "date": 1672532163559,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1340",
    "rarity_score": 376.9803131525043,
    "rarity_level": "COMMON"
  },
  "955": {
    "name": "Arkade 90s Bulls #955",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/955.png",
    "dna": "214a534e4b32169da2a3dcc91acbc84bb436a969",
    "edition": 955,
    "date": 1672532165035,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "955",
    "rarity_score": 245.12562784105668,
    "rarity_level": "COMMON"
  },
  "1602": {
    "name": "Arkade 90s Bulls #1602",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1602.png",
    "dna": "dea0cf8ba91aabf763f0bc2b18059726075716bc",
    "edition": 1602,
    "date": 1672532165912,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      { "trait_type": "Head", "value": "Luiki Hat", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1602",
    "rarity_score": 563.796821226621,
    "rarity_level": "RARE"
  },
  "1226": {
    "name": "Arkade 90s Bulls #1226",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1226.png",
    "dna": "823bc2a2555eaa49bd87ebb64bf12aeac76249a1",
    "edition": 1226,
    "date": 1672532166593,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1226",
    "rarity_score": 221.8704546409958,
    "rarity_level": "COMMON"
  },
  "999": {
    "name": "Arkade 90s Bulls #999",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/999.png",
    "dna": "06efaacd1cacfb1bab6785fcc2f0b2af4589833e",
    "edition": 999,
    "date": 1672532167289,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "999",
    "rarity_score": 152.08056987412468,
    "rarity_level": "COMMON"
  },
  "310": {
    "name": "Arkade 90s Bulls #310",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/310.png",
    "dna": "46f1187d4b1654dddd710eef355177ff2fdf19fe",
    "edition": 310,
    "date": 1672532167908,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "310",
    "rarity_score": 317.71822352788223,
    "rarity_level": "COMMON"
  },
  "1500": {
    "name": "Arkade 90s Bulls #1500",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1500.png",
    "dna": "e84635725181face75910f5291cd796968a1dde1",
    "edition": 1500,
    "date": 1672532169155,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1500",
    "rarity_score": 517.5804493009248,
    "rarity_level": "RARE"
  },
  "784": {
    "name": "Arkade 90s Bulls #784",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/784.png",
    "dna": "686bd7a5e85eb53a32a8f04683210f0fe4ffd3b8",
    "edition": 784,
    "date": 1672532169833,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "784",
    "rarity_score": 122.53840379951968,
    "rarity_level": "COMMON"
  },
  "1709": {
    "name": "Arkade 90s Bulls #1709",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1709.png",
    "dna": "3dc794362842f067f16baf244b253de705b1384f",
    "edition": 1709,
    "date": 1672532170627,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1709",
    "rarity_score": 288.699813243705,
    "rarity_level": "COMMON"
  },
  "1947": {
    "name": "Arkade 90s Bulls #1947",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1947.png",
    "dna": "6886ee4573a2df17956edd8fc30cc01fcfa8f8e5",
    "edition": 1947,
    "date": 1672532171315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1947",
    "rarity_score": 352.8375472435934,
    "rarity_level": "COMMON"
  },
  "80": {
    "name": "Arkade 90s Bulls #80",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/80.png",
    "dna": "4fabc2647b0e468dc83c31d81049214a517c320c",
    "edition": 80,
    "date": 1672532172144,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      { "trait_type": "Head", "value": "Okko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "80",
    "rarity_score": 668.2906560084602,
    "rarity_level": "RARE"
  },
  "1373": {
    "name": "Arkade 90s Bulls #1373",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1373.png",
    "dna": "41e6a724b40e67f2d3d9345153c185abe3a6b6be",
    "edition": 1373,
    "date": 1672532172917,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1373",
    "rarity_score": 193.87640445739336,
    "rarity_level": "COMMON"
  },
  "1154": {
    "name": "Arkade 90s Bulls #1154",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1154.png",
    "dna": "0a0a54a4853643f99e3ca2d4d1af35d995b530ea",
    "edition": 1154,
    "date": 1672532173634,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1154",
    "rarity_score": 356.80283758309764,
    "rarity_level": "COMMON"
  },
  "1780": {
    "name": "Arkade 90s Bulls #1780",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1780.png",
    "dna": "6e108734b4d104d68b156812b2a90adbc343b1a0",
    "edition": 1780,
    "date": 1672532175043,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1780",
    "rarity_score": 228.39220682990882,
    "rarity_level": "COMMON"
  },
  "979": {
    "name": "Arkade 90s Bulls #979",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/979.png",
    "dna": "e5e1132caea81bfa8e4fc0c9238d6e7f6e8a87da",
    "edition": 979,
    "date": 1672532175824,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "979",
    "rarity_score": 337.1645193549458,
    "rarity_level": "COMMON"
  },
  "991": {
    "name": "Arkade 90s Bulls #991",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/991.png",
    "dna": "56e2d95f3d26c3f96ac2107e7df76f34ef0eaa13",
    "edition": 991,
    "date": 1672532176509,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "991",
    "rarity_score": 317.45295382609004,
    "rarity_level": "COMMON"
  },
  "441": {
    "name": "Arkade 90s Bulls #441",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/441.png",
    "dna": "f88659db1e4346d28de1bb1485931da7ac4cbf5e",
    "edition": 441,
    "date": 1672532177243,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "441",
    "rarity_score": 217.91923300263613,
    "rarity_level": "COMMON"
  },
  "1407": {
    "name": "Arkade 90s Bulls #1407",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1407.png",
    "dna": "8863a46e7864d128ba1869598aa93499eb827897",
    "edition": 1407,
    "date": 1672532177973,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1407",
    "rarity_score": 170.46937336613175,
    "rarity_level": "COMMON"
  },
  "366": {
    "name": "Arkade 90s Bulls #366",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/366.png",
    "dna": "dee9dda08ad6e8347c7877995b92fa0309d3acd8",
    "edition": 366,
    "date": 1672532178651,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "366",
    "rarity_score": 368.38426665984986,
    "rarity_level": "COMMON"
  },
  "209": {
    "name": "Arkade 90s Bulls #209",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/209.png",
    "dna": "390d11b067710a070918985f853df383f51f5cda",
    "edition": 209,
    "date": 1672532179443,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "209",
    "rarity_score": 415.71264453391535,
    "rarity_level": "COMMON"
  },
  "730": {
    "name": "Arkade 90s Bulls #730",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/730.png",
    "dna": "f969dcf3bb4341f6044294250af9791eea2d9e15",
    "edition": 730,
    "date": 1672532180069,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "730",
    "rarity_score": 384.8743934435638,
    "rarity_level": "COMMON"
  },
  "165": {
    "name": "Arkade 90s Bulls #165",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/165.png",
    "dna": "d5c6ac525d36a9a0cdedc9e7ea27138bcb0eb818",
    "edition": 165,
    "date": 1672532180732,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "165",
    "rarity_score": 481.7338239893386,
    "rarity_level": "RARE"
  },
  "896": {
    "name": "Arkade 90s Bulls #896",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/896.png",
    "dna": "4e20abc875fd113921559011a3716c38a9781d1c",
    "edition": 896,
    "date": 1672532181665,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "896",
    "rarity_score": 474.7296670658526,
    "rarity_level": "RARE"
  },
  "602": {
    "name": "Arkade 90s Bulls #602",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/602.png",
    "dna": "88b0d087a72e7bda75d4970cf0c374be50555e8e",
    "edition": 602,
    "date": 1672532183019,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Head", "value": "Mouse Ears", "score": 995.0 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "602",
    "rarity_score": 1153.6624951776414,
    "rarity_level": "EPIC"
  },
  "644": {
    "name": "Arkade 90s Bulls #644",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/644.png",
    "dna": "a810892214b04cb6ba166dd19b2beebdb762d978",
    "edition": 644,
    "date": 1672532183716,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "644",
    "rarity_score": 118.1428740108705,
    "rarity_level": "COMMON"
  },
  "140": {
    "name": "Arkade 90s Bulls #140",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/140.png",
    "dna": "89b7dac6ae9dfe9d150a4b8edc7d99739af3b7e5",
    "edition": 140,
    "date": 1672532184330,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "140",
    "rarity_score": 428.1318944711081,
    "rarity_level": "COMMON"
  },
  "634": {
    "name": "Arkade 90s Bulls #634",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/634.png",
    "dna": "38470c6d78d0034c2d4c2c9f72edec020142c261",
    "edition": 634,
    "date": 1672532185545,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "634",
    "rarity_score": 151.97499493805842,
    "rarity_level": "COMMON"
  },
  "691": {
    "name": "Arkade 90s Bulls #691",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/691.png",
    "dna": "f43f5ec0aecc7e3ee85e6b8c7376f1353b44b747",
    "edition": 691,
    "date": 1672532186195,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "691",
    "rarity_score": 305.96836333833994,
    "rarity_level": "COMMON"
  },
  "809": {
    "name": "Arkade 90s Bulls #809",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/809.png",
    "dna": "e53f040366f2654c23377fdab69dbfa0ecec48a6",
    "edition": 809,
    "date": 1672532186834,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "809",
    "rarity_score": 235.60815178864826,
    "rarity_level": "COMMON"
  },
  "30": {
    "name": "Arkade 90s Bulls #30",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/30.png",
    "dna": "ab9e4e7fbfc7be235e6d9437d867de39fd9457c4",
    "edition": 30,
    "date": 1672532187507,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "30",
    "rarity_score": 243.75415681350106,
    "rarity_level": "COMMON"
  },
  "189": {
    "name": "Arkade 90s Bulls #189",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/189.png",
    "dna": "60ce9200dfecc0d04c08352ff82cd54d73a0e22c",
    "edition": 189,
    "date": 1672532188228,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "189",
    "rarity_score": 263.3691997761677,
    "rarity_level": "COMMON"
  },
  "333": {
    "name": "Arkade 90s Bulls #333",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/333.png",
    "dna": "33bb21fdc9f6199e400656b359f863f837df7ac2",
    "edition": 333,
    "date": 1672532188869,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "333",
    "rarity_score": 210.43295865662046,
    "rarity_level": "COMMON"
  },
  "1804": {
    "name": "Arkade 90s Bulls #1804",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1804.png",
    "dna": "fdd50337f406bee63a48af903a5b8356c45382c5",
    "edition": 1804,
    "date": 1672532189801,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      { "trait_type": "Head", "value": "Superbull Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1804",
    "rarity_score": 771.9876927403468,
    "rarity_level": "RARE"
  },
  "750": {
    "name": "Arkade 90s Bulls #750",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/750.png",
    "dna": "2f6081418bd9fe60d2a4a0328776131f7cae2b05",
    "edition": 750,
    "date": 1672532190451,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "750",
    "rarity_score": 425.93466810876106,
    "rarity_level": "COMMON"
  },
  "716": {
    "name": "Arkade 90s Bulls #716",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/716.png",
    "dna": "bd9d72f7afbf936c949371008e1bbb287d5ec41f",
    "edition": 716,
    "date": 1672532191081,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "716",
    "rarity_score": 380.5811138232939,
    "rarity_level": "COMMON"
  },
  "1794": {
    "name": "Arkade 90s Bulls #1794",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1794.png",
    "dna": "eedb222b90362ef039c5d789af9cea63d7971d08",
    "edition": 1794,
    "date": 1672532191934,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1794",
    "rarity_score": 221.01255512021942,
    "rarity_level": "COMMON"
  },
  "1740": {
    "name": "Arkade 90s Bulls #1740",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1740.png",
    "dna": "d17370c910b2e8c6a7c6e133052078a12af86736",
    "edition": 1740,
    "date": 1672532192595,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1740",
    "rarity_score": 225.45360585063213,
    "rarity_level": "COMMON"
  },
  "1183": {
    "name": "Arkade 90s Bulls #1183",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1183.png",
    "dna": "0f192ced1535527ded43f5af8fcd96a2545c409c",
    "edition": 1183,
    "date": 1672532193338,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1183",
    "rarity_score": 451.5513585970193,
    "rarity_level": "COMMON"
  },
  "1919": {
    "name": "Arkade 90s Bulls #1919",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1919.png",
    "dna": "370bba8f18b801bc35b03ae49f172ef5a0c3e56c",
    "edition": 1919,
    "date": 1672532194285,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1919",
    "rarity_score": 216.20480087040627,
    "rarity_level": "COMMON"
  },
  "1617": {
    "name": "Arkade 90s Bulls #1617",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1617.png",
    "dna": "6d375ef321d69a98431c7fbddf3e1541b7d22763",
    "edition": 1617,
    "date": 1672532195879,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1617",
    "rarity_score": 187.45441746802993,
    "rarity_level": "COMMON"
  },
  "522": {
    "name": "Arkade 90s Bulls #522",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/522.png",
    "dna": "e0c3b30e7b756670e8328f35acb4918f53c7107d",
    "edition": 522,
    "date": 1672532196671,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "522",
    "rarity_score": 231.89137822448222,
    "rarity_level": "COMMON"
  },
  "1959": {
    "name": "Arkade 90s Bulls #1959",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1959.png",
    "dna": "f2d0490247bdaf4b1c87548625d8e74ee238a355",
    "edition": 1959,
    "date": 1672532197337,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1959",
    "rarity_score": 219.0209977967722,
    "rarity_level": "COMMON"
  },
  "1736": {
    "name": "Arkade 90s Bulls #1736",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1736.png",
    "dna": "7d78f1e811f30299877530d6adfabb3674da948e",
    "edition": 1736,
    "date": 1672532198535,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1736",
    "rarity_score": 375.88813468880903,
    "rarity_level": "COMMON"
  },
  "1049": {
    "name": "Arkade 90s Bulls #1049",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1049.png",
    "dna": "9dfc4a3ffe9ff8eab9995336f8438600cb509f67",
    "edition": 1049,
    "date": 1672532199238,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Banana Peel",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1049",
    "rarity_score": 809.3026518700003,
    "rarity_level": "RARE"
  },
  "1866": {
    "name": "Arkade 90s Bulls #1866",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1866.png",
    "dna": "25aa15fab8924901cd8055c4efbd3fa7b634e9fa",
    "edition": 1866,
    "date": 1672532199902,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1866",
    "rarity_score": 323.5193616492643,
    "rarity_level": "COMMON"
  },
  "1349": {
    "name": "Arkade 90s Bulls #1349",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1349.png",
    "dna": "6884e4f6b3915aac39b0c4c0b89b5d4e6d328cfe",
    "edition": 1349,
    "date": 1672532200531,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1349",
    "rarity_score": 208.75160571625557,
    "rarity_level": "COMMON"
  },
  "1540": {
    "name": "Arkade 90s Bulls #1540",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1540.png",
    "dna": "33b1966929835715c0b7ba56d58f6601f8fdc6e9",
    "edition": 1540,
    "date": 1672532201262,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1540",
    "rarity_score": 295.92922919687607,
    "rarity_level": "COMMON"
  },
  "1169": {
    "name": "Arkade 90s Bulls #1169",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1169.png",
    "dna": "f507ab12b02ef291a9f009f21008892af79f2a82",
    "edition": 1169,
    "date": 1672532201935,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1169",
    "rarity_score": 151.55272354113245,
    "rarity_level": "COMMON"
  },
  "1396": {
    "name": "Arkade 90s Bulls #1396",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1396.png",
    "dna": "5e2ff7e6209ed4c62d29a846b7c982650d7e4cb9",
    "edition": 1396,
    "date": 1672532202583,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1396",
    "rarity_score": 359.29340410823113,
    "rarity_level": "COMMON"
  },
  "542": {
    "name": "Arkade 90s Bulls #542",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/542.png",
    "dna": "e85c6e02cb2ac59ebb1dc831e8dc09421e78c1ee",
    "edition": 542,
    "date": 1672532203244,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "542",
    "rarity_score": 165.98806275264747,
    "rarity_level": "COMMON"
  },
  "1170": {
    "name": "Arkade 90s Bulls #1170",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1170.png",
    "dna": "74e77073933b4d4871e811e35f83ff235b544717",
    "edition": 1170,
    "date": 1672532203946,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1170",
    "rarity_score": 233.43109120414042,
    "rarity_level": "COMMON"
  },
  "1853": {
    "name": "Arkade 90s Bulls #1853",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1853.png",
    "dna": "d29482e86850a14ff73d24097db9e7f7664acdeb",
    "edition": 1853,
    "date": 1672532205077,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Tongue Out",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1853",
    "rarity_score": 394.970941390131,
    "rarity_level": "COMMON"
  },
  "1357": {
    "name": "Arkade 90s Bulls #1357",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1357.png",
    "dna": "cf7fd0ddd5aae04ed142078cb132efecfdc77268",
    "edition": 1357,
    "date": 1672532206018,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1357",
    "rarity_score": 115.68622824293884,
    "rarity_level": "COMMON"
  },
  "1024": {
    "name": "Arkade 90s Bulls #1024",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1024.png",
    "dna": "766b6b0740d67459a86a3716eea2172e12fd1fdd",
    "edition": 1024,
    "date": 1672532206855,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1024",
    "rarity_score": 293.17653796806036,
    "rarity_level": "COMMON"
  },
  "933": {
    "name": "Arkade 90s Bulls #933",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/933.png",
    "dna": "cdf0d9b64722e0c9cc3b17915b7a718b901953f3",
    "edition": 933,
    "date": 1672532207561,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "933",
    "rarity_score": 284.36071246116705,
    "rarity_level": "COMMON"
  },
  "556": {
    "name": "Arkade 90s Bulls #556",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/556.png",
    "dna": "8314b5dd8a8fef298ce326fe991ed9e24ca25c52",
    "edition": 556,
    "date": 1672532208243,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "556",
    "rarity_score": 156.84855815816377,
    "rarity_level": "COMMON"
  },
  "920": {
    "name": "Arkade 90s Bulls #920",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/920.png",
    "dna": "0ef9d42449c8270b8cbcbd42c56dcf6ebc37dffc",
    "edition": 920,
    "date": 1672532208982,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Banana Peel",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "920",
    "rarity_score": 831.8683119882646,
    "rarity_level": "RARE"
  },
  "1691": {
    "name": "Arkade 90s Bulls #1691",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1691.png",
    "dna": "7aa0339b40b68632a54cb5ff20e029b840a41794",
    "edition": 1691,
    "date": 1672532209917,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1691",
    "rarity_score": 168.2988667290549,
    "rarity_level": "COMMON"
  },
  "1819": {
    "name": "Arkade 90s Bulls #1819",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1819.png",
    "dna": "657e9f58b1ff7ba69904c9bbece63b78ca1f6916",
    "edition": 1819,
    "date": 1672532210574,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1819",
    "rarity_score": 335.7752083683967,
    "rarity_level": "COMMON"
  },
  "848": {
    "name": "Arkade 90s Bulls #848",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/848.png",
    "dna": "3bb484e74dc2e65007e30b4330b6f425629b875a",
    "edition": 848,
    "date": 1672532211463,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "848",
    "rarity_score": 392.8505763976228,
    "rarity_level": "COMMON"
  },
  "1859": {
    "name": "Arkade 90s Bulls #1859",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1859.png",
    "dna": "eb5512ecefaad4b60dd5d7668ea3032c99fdb268",
    "edition": 1859,
    "date": 1672532212499,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1859",
    "rarity_score": 243.23061712219462,
    "rarity_level": "COMMON"
  },
  "276": {
    "name": "Arkade 90s Bulls #276",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/276.png",
    "dna": "e4e6120e054db23247c80a692f23f04a50cce3e8",
    "edition": 276,
    "date": 1672532213125,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "276",
    "rarity_score": 311.36594709368273,
    "rarity_level": "COMMON"
  },
  "1701": {
    "name": "Arkade 90s Bulls #1701",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1701.png",
    "dna": "e859e310af00f5c990df57df6541fcb6df632b8c",
    "edition": 1701,
    "date": 1672532213947,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1701",
    "rarity_score": 147.06148825956305,
    "rarity_level": "COMMON"
  },
  "302": {
    "name": "Arkade 90s Bulls #302",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/302.png",
    "dna": "c6f1c138dde585ebe6f155fac45b3cf937d1435c",
    "edition": 302,
    "date": 1672532215109,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "302",
    "rarity_score": 341.06361278648336,
    "rarity_level": "COMMON"
  },
  "1717": {
    "name": "Arkade 90s Bulls #1717",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1717.png",
    "dna": "dfcfe3d76732a731f042052e6d285e589368bd8c",
    "edition": 1717,
    "date": 1672532216116,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1717",
    "rarity_score": 240.10439233393518,
    "rarity_level": "COMMON"
  },
  "1731": {
    "name": "Arkade 90s Bulls #1731",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1731.png",
    "dna": "8326dae1db902722e8588c7fa05b73e507d6f011",
    "edition": 1731,
    "date": 1672532216754,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1731",
    "rarity_score": 256.8313770372092,
    "rarity_level": "COMMON"
  },
  "316": {
    "name": "Arkade 90s Bulls #316",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/316.png",
    "dna": "342c3fa3ba44cccff2a42d796ef5a0943374aff2",
    "edition": 316,
    "date": 1672532217684,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "316",
    "rarity_score": 313.0473699172879,
    "rarity_level": "COMMON"
  },
  "666": {
    "name": "Arkade 90s Bulls #666",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/666.png",
    "dna": "7120b5fadc19cc1c367fd97ea4ad18fa08fa897c",
    "edition": 666,
    "date": 1672532218363,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "666",
    "rarity_score": 154.81182179526152,
    "rarity_level": "COMMON"
  },
  "1975": {
    "name": "Arkade 90s Bulls #1975",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1975.png",
    "dna": "782d22b01c9f93d1a27d92ae9041d06bc6d3fa43",
    "edition": 1975,
    "date": 1672532219090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1975",
    "rarity_score": 131.5238295762168,
    "rarity_level": "COMMON"
  },
  "272": {
    "name": "Arkade 90s Bulls #272",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/272.png",
    "dna": "763001b79dae2e27843f3dc92eb4cc0cc0944b58",
    "edition": 272,
    "date": 1672532219818,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      { "trait_type": "Head", "value": "Okko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "272",
    "rarity_score": 605.7209474179065,
    "rarity_level": "RARE"
  },
  "223": {
    "name": "Arkade 90s Bulls #223",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/223.png",
    "dna": "83c33c6f25e15b3089e255a52b731b2ae85a3994",
    "edition": 223,
    "date": 1672532220445,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Sad",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "223",
    "rarity_score": 342.90853290563854,
    "rarity_level": "COMMON"
  },
  "1674": {
    "name": "Arkade 90s Bulls #1674",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1674.png",
    "dna": "5a5f4a2850ca3eea6e475fb54b875556b99794aa",
    "edition": 1674,
    "date": 1672532221778,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1674",
    "rarity_score": 173.17239747923935,
    "rarity_level": "COMMON"
  },
  "1166": {
    "name": "Arkade 90s Bulls #1166",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1166.png",
    "dna": "bb49e66528c1653ca4c82457cd184e9191ba301d",
    "edition": 1166,
    "date": 1672532222412,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Smile",
        "score": 23.97590361445783
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1166",
    "rarity_score": 264.1316205217363,
    "rarity_level": "COMMON"
  },
  "1855": {
    "name": "Arkade 90s Bulls #1855",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1855.png",
    "dna": "84485c39dd75ef43f6e685a77ac6790ff5946ae4",
    "edition": 1855,
    "date": 1672532223799,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1855",
    "rarity_score": 144.71067700835533,
    "rarity_level": "COMMON"
  },
  "1571": {
    "name": "Arkade 90s Bulls #1571",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1571.png",
    "dna": "afe8c15568d4e22beaa86bd86218602dc9d21ab4",
    "edition": 1571,
    "date": 1672532224465,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1571",
    "rarity_score": 101.80768881813931,
    "rarity_level": "COMMON"
  },
  "1625": {
    "name": "Arkade 90s Bulls #1625",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1625.png",
    "dna": "9224ecdf602235a5047f28a33eb6c5dbd4c2ec99",
    "edition": 1625,
    "date": 1672532225150,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Angry",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1625",
    "rarity_score": 249.97086345214495,
    "rarity_level": "COMMON"
  },
  "1482": {
    "name": "Arkade 90s Bulls #1482",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1482.png",
    "dna": "ec8352ea6fe600cccc51ccf5a1fa9646050d0fa4",
    "edition": 1482,
    "date": 1672532225917,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1482",
    "rarity_score": 357.3872305089889,
    "rarity_level": "COMMON"
  },
  "1045": {
    "name": "Arkade 90s Bulls #1045",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1045.png",
    "dna": "28e6a1814aa889a6b0d71b8af90e22700425aaea",
    "edition": 1045,
    "date": 1672532226587,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Bronze Happy",
        "score": 31.09375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1045",
    "rarity_score": 306.15026638059015,
    "rarity_level": "COMMON"
  },
  "953": {
    "name": "Arkade 90s Bulls #953",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/953.png",
    "dna": "d04c0eaac4a3b6a1873de9be38afc0a602c8d7df",
    "edition": 953,
    "date": 1672532227641,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "953",
    "rarity_score": 444.78862437007194,
    "rarity_level": "COMMON"
  },
  "440": {
    "name": "Arkade 90s Bulls #440",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/440.png",
    "dna": "dae093b68619997acbbe672e54c15eaa1b7bc435",
    "edition": 440,
    "date": 1672532228150,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "440",
    "rarity_score": 471.39407974106626,
    "rarity_level": "RARE"
  },
  "244": {
    "name": "Arkade 90s Bulls #244",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/244.png",
    "dna": "ab81b71b7f5e74d723762a1a191de0de1b7a8994",
    "edition": 244,
    "date": 1672532228653,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "244",
    "rarity_score": 112.60866044036328,
    "rarity_level": "COMMON"
  },
  "1751": {
    "name": "Arkade 90s Bulls #1751",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1751.png",
    "dna": "9369fd26745ca9ad1a7f219786a97d135d86a544",
    "edition": 1751,
    "date": 1672532229186,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1751",
    "rarity_score": 249.74797007630778,
    "rarity_level": "COMMON"
  },
  "1541": {
    "name": "Arkade 90s Bulls #1541",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1541.png",
    "dna": "020f6a3e7ff0d971a07b769e0fbce55c62b4ccee",
    "edition": 1541,
    "date": 1672532229793,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1541",
    "rarity_score": 296.903555594737,
    "rarity_level": "COMMON"
  },
  "1065": {
    "name": "Arkade 90s Bulls #1065",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1065.png",
    "dna": "e540b933c4ede65de9c77a80eeb23e5508d640fc",
    "edition": 1065,
    "date": 1672532230284,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1065",
    "rarity_score": 269.3218706344981,
    "rarity_level": "COMMON"
  },
  "850": {
    "name": "Arkade 90s Bulls #850",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/850.png",
    "dna": "3941527d388be4d0450b747a8b938a6224fc244e",
    "edition": 850,
    "date": 1672532230824,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "850",
    "rarity_score": 147.08124700438606,
    "rarity_level": "COMMON"
  },
  "1716": {
    "name": "Arkade 90s Bulls #1716",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1716.png",
    "dna": "d1b639ab6dee156fac3cf236a7ffa360de2752a5",
    "edition": 1716,
    "date": 1672532231374,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1716",
    "rarity_score": 242.95263694429065,
    "rarity_level": "COMMON"
  },
  "257": {
    "name": "Arkade 90s Bulls #257",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/257.png",
    "dna": "2e16e64186cbe18e1777b864349ccd0093a9f619",
    "edition": 257,
    "date": 1672532232494,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "257",
    "rarity_score": 186.48705881758107,
    "rarity_level": "COMMON"
  },
  "1601": {
    "name": "Arkade 90s Bulls #1601",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1601.png",
    "dna": "9f0489d98bf4c81c0502a1b42054d4f97e1e7874",
    "edition": 1601,
    "date": 1672532232991,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1601",
    "rarity_score": 179.8341662355836,
    "rarity_level": "COMMON"
  },
  "899": {
    "name": "Arkade 90s Bulls #899",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/899.png",
    "dna": "23ac433770ec605509a92e8ae0ae1f3da216204c",
    "edition": 899,
    "date": 1672532233491,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "899",
    "rarity_score": 125.4595007777487,
    "rarity_level": "COMMON"
  },
  "499": {
    "name": "Arkade 90s Bulls #499",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/499.png",
    "dna": "e7ba28a6a3c33b46d63e71660960d7d7009f8bb3",
    "edition": 499,
    "date": 1672532234042,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "499",
    "rarity_score": 78.08963358305635,
    "rarity_level": "COMMON"
  },
  "875": {
    "name": "Arkade 90s Bulls #875",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/875.png",
    "dna": "0bcc5dc8371e5840127bad0e6a4ff77db5b90c38",
    "edition": 875,
    "date": 1672532235200,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "875",
    "rarity_score": 317.60072547826394,
    "rarity_level": "COMMON"
  },
  "1889": {
    "name": "Arkade 90s Bulls #1889",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1889.png",
    "dna": "52d63e8ebb0c4bfac3d2a1931bfaf4137feecfdc",
    "edition": 1889,
    "date": 1672532235735,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1889",
    "rarity_score": 268.83573833544244,
    "rarity_level": "COMMON"
  },
  "852": {
    "name": "Arkade 90s Bulls #852",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/852.png",
    "dna": "31077431c705f08b8e61e0e3ba9ab576a10491f4",
    "edition": 852,
    "date": 1672532236262,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "852",
    "rarity_score": 165.19438150157396,
    "rarity_level": "COMMON"
  },
  "1297": {
    "name": "Arkade 90s Bulls #1297",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1297.png",
    "dna": "f88130be2950eb0ca229fe84a4068a2b28f31c10",
    "edition": 1297,
    "date": 1672532237121,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1297",
    "rarity_score": 265.0732124001332,
    "rarity_level": "COMMON"
  },
  "273": {
    "name": "Arkade 90s Bulls #273",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/273.png",
    "dna": "e70b02c0579078d07be80332fbce9691cb3ef215",
    "edition": 273,
    "date": 1672532238142,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "273",
    "rarity_score": 645.824702149741,
    "rarity_level": "RARE"
  },
  "210": {
    "name": "Arkade 90s Bulls #210",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/210.png",
    "dna": "3eda274ab1e9b7e52a2f83981ebeae4fda6175d7",
    "edition": 210,
    "date": 1672532238715,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "210",
    "rarity_score": 212.13960534319162,
    "rarity_level": "COMMON"
  },
  "1789": {
    "name": "Arkade 90s Bulls #1789",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1789.png",
    "dna": "e12e92aec232e5ca1ee85ddd96eb427e6c741d11",
    "edition": 1789,
    "date": 1672532239197,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1789",
    "rarity_score": 471.93735145994856,
    "rarity_level": "RARE"
  },
  "586": {
    "name": "Arkade 90s Bulls #586",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/586.png",
    "dna": "f1e375f5f96223e2c3b30a0c8eafab886c79d258",
    "edition": 586,
    "date": 1672532239716,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "586",
    "rarity_score": 254.33707666190355,
    "rarity_level": "COMMON"
  },
  "785": {
    "name": "Arkade 90s Bulls #785",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/785.png",
    "dna": "51a1fe4646a4289b3ca8afe5c0a2dd98a4139cdf",
    "edition": 785,
    "date": 1672532240291,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "785",
    "rarity_score": 195.29044299651707,
    "rarity_level": "COMMON"
  },
  "1582": {
    "name": "Arkade 90s Bulls #1582",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1582.png",
    "dna": "15eef870905b1b7ad030575c7234390042881c70",
    "edition": 1582,
    "date": 1672532240832,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1582",
    "rarity_score": 151.00711954928647,
    "rarity_level": "COMMON"
  },
  "456": {
    "name": "Arkade 90s Bulls #456",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/456.png",
    "dna": "aeb3f45f7a6730b57da3a9e4558b60e9f08a259e",
    "edition": 456,
    "date": 1672532241333,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "456",
    "rarity_score": 147.5177159169778,
    "rarity_level": "COMMON"
  },
  "1356": {
    "name": "Arkade 90s Bulls #1356",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1356.png",
    "dna": "64099528060b69cc330e30d23188f361f28497ae",
    "edition": 1356,
    "date": 1672532241851,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1356",
    "rarity_score": 210.33731514518715,
    "rarity_level": "COMMON"
  },
  "418": {
    "name": "Arkade 90s Bulls #418",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/418.png",
    "dna": "f10165e8711e3817548aa4a3ffe7d081b15c8d0c",
    "edition": 418,
    "date": 1672532242628,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "418",
    "rarity_score": 315.6266738743069,
    "rarity_level": "COMMON"
  },
  "1043": {
    "name": "Arkade 90s Bulls #1043",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1043.png",
    "dna": "21b1c01cdec8d2cc4c4a0856d78db8924b13168f",
    "edition": 1043,
    "date": 1672532243126,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1043",
    "rarity_score": 200.02716456401976,
    "rarity_level": "COMMON"
  },
  "1044": {
    "name": "Arkade 90s Bulls #1044",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1044.png",
    "dna": "b9dfd7db44118fb85eff36f618e4390a2cde7220",
    "edition": 1044,
    "date": 1672532243626,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1044",
    "rarity_score": 319.6968480408551,
    "rarity_level": "COMMON"
  },
  "365": {
    "name": "Arkade 90s Bulls #365",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/365.png",
    "dna": "240bdb6fc0967412583a90c1991827d957e8b58d",
    "edition": 365,
    "date": 1672532244371,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "365",
    "rarity_score": 204.18736362779202,
    "rarity_level": "COMMON"
  },
  "1760": {
    "name": "Arkade 90s Bulls #1760",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1760.png",
    "dna": "50618fda9c7344901fee161c022e77443209cd35",
    "edition": 1760,
    "date": 1672532245590,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1760",
    "rarity_score": 215.602701037039,
    "rarity_level": "COMMON"
  },
  "1408": {
    "name": "Arkade 90s Bulls #1408",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1408.png",
    "dna": "75e55f1ce94289b8235137e2a4b5e11e0b33fff2",
    "edition": 1408,
    "date": 1672532246851,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1408",
    "rarity_score": 291.87209549042467,
    "rarity_level": "COMMON"
  },
  "1632": {
    "name": "Arkade 90s Bulls #1632",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1632.png",
    "dna": "ae793ed82a3139608176085635160bdda58a257f",
    "edition": 1632,
    "date": 1672532247407,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1632",
    "rarity_score": 167.59739561964813,
    "rarity_level": "COMMON"
  },
  "1405": {
    "name": "Arkade 90s Bulls #1405",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1405.png",
    "dna": "381c65a4b372ad36829aa77c533c0e677f3ff973",
    "edition": 1405,
    "date": 1672532248021,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1405",
    "rarity_score": 262.0929735670469,
    "rarity_level": "COMMON"
  },
  "32": {
    "name": "Arkade 90s Bulls #32",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/32.png",
    "dna": "7279e0773dce57610f228fd9de7be21e2d31a365",
    "edition": 32,
    "date": 1672532248525,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "32",
    "rarity_score": 187.61649138819502,
    "rarity_level": "COMMON"
  },
  "1581": {
    "name": "Arkade 90s Bulls #1581",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1581.png",
    "dna": "ba417062bb82ab0d009e54832a7c9735dcafd088",
    "edition": 1581,
    "date": 1672532249106,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1581",
    "rarity_score": 173.72912897772267,
    "rarity_level": "COMMON"
  },
  "1528": {
    "name": "Arkade 90s Bulls #1528",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1528.png",
    "dna": "3deec54e24247c142d175428aeb15bc5d20c94b2",
    "edition": 1528,
    "date": 1672532249631,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1528",
    "rarity_score": 293.4588863237201,
    "rarity_level": "COMMON"
  },
  "594": {
    "name": "Arkade 90s Bulls #594",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/594.png",
    "dna": "2359f61ef2a5bad0b0799e5fc1683d321d45e1f5",
    "edition": 594,
    "date": 1672532250122,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "594",
    "rarity_score": 143.8122623625614,
    "rarity_level": "COMMON"
  },
  "1258": {
    "name": "Arkade 90s Bulls #1258",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1258.png",
    "dna": "7c69f4bf3530b502b2ca0d55a1daee962408438f",
    "edition": 1258,
    "date": 1672532250733,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1258",
    "rarity_score": 330.6504718938647,
    "rarity_level": "COMMON"
  },
  "1885": {
    "name": "Arkade 90s Bulls #1885",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1885.png",
    "dna": "4bafa21c9d12c4923234c3c6cd413ebe7bcaffa6",
    "edition": 1885,
    "date": 1672532251269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1885",
    "rarity_score": 158.79251049018123,
    "rarity_level": "COMMON"
  },
  "1014": {
    "name": "Arkade 90s Bulls #1014",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1014.png",
    "dna": "e05a0a50152354bb9c0f3e3d66285b362a79ede0",
    "edition": 1014,
    "date": 1672532252394,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1014",
    "rarity_score": 174.35355959629265,
    "rarity_level": "COMMON"
  },
  "590": {
    "name": "Arkade 90s Bulls #590",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/590.png",
    "dna": "96c33de9b6017f779df915706d67cfb27771272e",
    "edition": 590,
    "date": 1672532252959,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "590",
    "rarity_score": 245.55507933477602,
    "rarity_level": "COMMON"
  },
  "553": {
    "name": "Arkade 90s Bulls #553",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/553.png",
    "dna": "c8d0677725c683f0d8d32b44084e00d97b6d3bdf",
    "edition": 553,
    "date": 1672532253868,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "553",
    "rarity_score": 241.1493302968226,
    "rarity_level": "COMMON"
  },
  "749": {
    "name": "Arkade 90s Bulls #749",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/749.png",
    "dna": "f3664c47df65e51397adfab39b9755f5059ff1fc",
    "edition": 749,
    "date": 1672532254436,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "749",
    "rarity_score": 197.95843661672362,
    "rarity_level": "COMMON"
  },
  "1509": {
    "name": "Arkade 90s Bulls #1509",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1509.png",
    "dna": "ffe70f55068be965abadde25e7f4eada725f8c24",
    "edition": 1509,
    "date": 1672532254942,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1509",
    "rarity_score": 423.4362676302806,
    "rarity_level": "COMMON"
  },
  "401": {
    "name": "Arkade 90s Bulls #401",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/401.png",
    "dna": "958a6ad3e7cb8667f0934ca34ab2ae3e3e13a817",
    "edition": 401,
    "date": 1672532256209,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "401",
    "rarity_score": 167.86105334659447,
    "rarity_level": "COMMON"
  },
  "1379": {
    "name": "Arkade 90s Bulls #1379",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1379.png",
    "dna": "d7367c1aceeb7aa2525f8659b07a91473228314d",
    "edition": 1379,
    "date": 1672532256776,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1379",
    "rarity_score": 309.567280919112,
    "rarity_level": "COMMON"
  },
  "1499": {
    "name": "Arkade 90s Bulls #1499",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1499.png",
    "dna": "dd3d119ed6f1ca2ce05c7b969f2d22f2a14b1858",
    "edition": 1499,
    "date": 1672532257369,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1499",
    "rarity_score": 358.7165151688736,
    "rarity_level": "COMMON"
  },
  "350": {
    "name": "Arkade 90s Bulls #350",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/350.png",
    "dna": "cf03b1cb59799be51e89bf0cd3c949069c4a3587",
    "edition": 350,
    "date": 1672532257996,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "350",
    "rarity_score": 308.67602984346763,
    "rarity_level": "COMMON"
  },
  "1504": {
    "name": "Arkade 90s Bulls #1504",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1504.png",
    "dna": "c99f4527e951dbd94a0c5a1c869b900c1a76bce6",
    "edition": 1504,
    "date": 1672532258585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1504",
    "rarity_score": 215.11550479309076,
    "rarity_level": "COMMON"
  },
  "1642": {
    "name": "Arkade 90s Bulls #1642",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1642.png",
    "dna": "b53345ef56da21521b815c662a51c89d99e1d949",
    "edition": 1642,
    "date": 1672532259740,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1642",
    "rarity_score": 307.41552351131054,
    "rarity_level": "COMMON"
  },
  "101": {
    "name": "Arkade 90s Bulls #101",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/101.png",
    "dna": "2a1b94725ed99feec6b606392d7c8a4a2e7c519b",
    "edition": 101,
    "date": 1672532260326,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "101",
    "rarity_score": 112.51992029466726,
    "rarity_level": "COMMON"
  },
  "1737": {
    "name": "Arkade 90s Bulls #1737",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1737.png",
    "dna": "19d18669a373f8261196b827431497476fb69fdf",
    "edition": 1737,
    "date": 1672532260945,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1737",
    "rarity_score": 423.9491516018047,
    "rarity_level": "COMMON"
  },
  "1818": {
    "name": "Arkade 90s Bulls #1818",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1818.png",
    "dna": "a558c024691b5c8a840ea6dae8345f0ae7c46cb0",
    "edition": 1818,
    "date": 1672532262213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1818",
    "rarity_score": 194.5070621227652,
    "rarity_level": "COMMON"
  },
  "885": {
    "name": "Arkade 90s Bulls #885",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/885.png",
    "dna": "e440adeac75263cf5269dd2a03d90d3b17ed58c8",
    "edition": 885,
    "date": 1672532262737,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "885",
    "rarity_score": 97.5167217522629,
    "rarity_level": "COMMON"
  },
  "473": {
    "name": "Arkade 90s Bulls #473",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/473.png",
    "dna": "3b40bacf4e53622dad32d210efa7a629ef3e9750",
    "edition": 473,
    "date": 1672532264022,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "Yellow Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "473",
    "rarity_score": 660.7584369905915,
    "rarity_level": "RARE"
  },
  "1847": {
    "name": "Arkade 90s Bulls #1847",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1847.png",
    "dna": "eae167d4e2776f53a2c92cceaa506d5705f4dc32",
    "edition": 1847,
    "date": 1672532264614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1847",
    "rarity_score": 72.1127405228435,
    "rarity_level": "COMMON"
  },
  "1773": {
    "name": "Arkade 90s Bulls #1773",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1773.png",
    "dna": "4205e274a60cc16d30b4200609b8957b132ffeaa",
    "edition": 1773,
    "date": 1672532265796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1773",
    "rarity_score": 232.3973552869122,
    "rarity_level": "COMMON"
  },
  "1844": {
    "name": "Arkade 90s Bulls #1844",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1844.png",
    "dna": "08fd8ba2ed86cd47a3b6f08fddd6a7662ad3b3b5",
    "edition": 1844,
    "date": 1672532267128,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1844",
    "rarity_score": 274.74175243829353,
    "rarity_level": "COMMON"
  },
  "1221": {
    "name": "Arkade 90s Bulls #1221",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1221.png",
    "dna": "26465fd6f0669d4a8cb8358528ca98dd837900f9",
    "edition": 1221,
    "date": 1672532267898,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1221",
    "rarity_score": 129.34125705523488,
    "rarity_level": "COMMON"
  },
  "1109": {
    "name": "Arkade 90s Bulls #1109",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1109.png",
    "dna": "9e7d64017b257895613b1f9f0784aca2bfd9d0ae",
    "edition": 1109,
    "date": 1672532268505,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1109",
    "rarity_score": 221.44370021446716,
    "rarity_level": "COMMON"
  },
  "1931": {
    "name": "Arkade 90s Bulls #1931",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1931.png",
    "dna": "807df8a1627c625e9472edd56aaaabddbf4b26c5",
    "edition": 1931,
    "date": 1672532269035,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1931",
    "rarity_score": 131.08204342149395,
    "rarity_level": "COMMON"
  },
  "1493": {
    "name": "Arkade 90s Bulls #1493",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1493.png",
    "dna": "38bc47fb03dba834960ff71601056c6a78435590",
    "edition": 1493,
    "date": 1672532269576,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1493",
    "rarity_score": 203.99954121801449,
    "rarity_level": "COMMON"
  },
  "280": {
    "name": "Arkade 90s Bulls #280",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/280.png",
    "dna": "f3ab0d067083bb13dfff3c2917825c95b651db86",
    "edition": 280,
    "date": 1672532270065,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "280",
    "rarity_score": 261.55134699535995,
    "rarity_level": "COMMON"
  },
  "1458": {
    "name": "Arkade 90s Bulls #1458",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1458.png",
    "dna": "a5aed32214bbf8312837648d428ceab2c710f00d",
    "edition": 1458,
    "date": 1672532271143,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1458",
    "rarity_score": 145.6183702541631,
    "rarity_level": "COMMON"
  },
  "1951": {
    "name": "Arkade 90s Bulls #1951",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1951.png",
    "dna": "83a827d3776524be43a778e32f56b2ce5e6b939c",
    "edition": 1951,
    "date": 1672532272319,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1951",
    "rarity_score": 102.7989781100718,
    "rarity_level": "COMMON"
  },
  "1392": {
    "name": "Arkade 90s Bulls #1392",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1392.png",
    "dna": "b686d88fa79c6ae4a0435fe6c51cd0d0a8094ac7",
    "edition": 1392,
    "date": 1672532272954,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1392",
    "rarity_score": 159.27457465517443,
    "rarity_level": "COMMON"
  },
  "1433": {
    "name": "Arkade 90s Bulls #1433",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1433.png",
    "dna": "c22a3232a59d93ec2ba80074f4bf71410311f329",
    "edition": 1433,
    "date": 1672532273502,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1433",
    "rarity_score": 150.99885061498466,
    "rarity_level": "COMMON"
  },
  "550": {
    "name": "Arkade 90s Bulls #550",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/550.png",
    "dna": "7140c21dabf320277cfd583ebca862f39f821faf",
    "edition": 550,
    "date": 1672532274355,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "550",
    "rarity_score": 314.89636274471195,
    "rarity_level": "COMMON"
  },
  "674": {
    "name": "Arkade 90s Bulls #674",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/674.png",
    "dna": "f0df8b4f2be74fac66b8c85683b86b99ed48828b",
    "edition": 674,
    "date": 1672532275058,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "674",
    "rarity_score": 269.3337583286439,
    "rarity_level": "COMMON"
  },
  "1834": {
    "name": "Arkade 90s Bulls #1834",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1834.png",
    "dna": "37a99ad715d821d1dfd31bbf3da575cc6239af34",
    "edition": 1834,
    "date": 1672532275968,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1834",
    "rarity_score": 169.7835362656212,
    "rarity_level": "COMMON"
  },
  "1653": {
    "name": "Arkade 90s Bulls #1653",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1653.png",
    "dna": "63ac302ced58fe7b4abc5925323a21ad372b9a40",
    "edition": 1653,
    "date": 1672532276795,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Black Guardian Mask",
        "score": 1990.0
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1653",
    "rarity_score": 2127.6084851562296,
    "rarity_level": "LEGENDARY"
  },
  "994": {
    "name": "Arkade 90s Bulls #994",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/994.png",
    "dna": "1b60b9600a2683c7c55f0e11ab6c5f09ffdebaf5",
    "edition": 994,
    "date": 1672532279954,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "994",
    "rarity_score": 126.33767446035975,
    "rarity_level": "COMMON"
  },
  "846": {
    "name": "Arkade 90s Bulls #846",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/846.png",
    "dna": "dd0d010b318a56935dd545ffa6e4716d109e2d08",
    "edition": 846,
    "date": 1672532283658,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "846",
    "rarity_score": 170.41028964958292,
    "rarity_level": "COMMON"
  },
  "238": {
    "name": "Arkade 90s Bulls #238",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/238.png",
    "dna": "1c163ba6a5c87b236a36df96ad762a12e2af7110",
    "edition": 238,
    "date": 1672532284567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "238",
    "rarity_score": 475.6846524391689,
    "rarity_level": "RARE"
  },
  "1726": {
    "name": "Arkade 90s Bulls #",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1726.png",
    "dna": "c35ac249c3390f2f06af97ca7af87051a95328d1",
    "edition": 0,
    "date": 1672532285215,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1726",
    "rarity_score": 157.7658163883859,
    "rarity_level": "COMMON"
  },
  "1237": {
    "name": "Arkade 90s Bulls #1237",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1237.png",
    "dna": "b389c12ad7954241d5f8bea5b1c385f3ed8894dd",
    "edition": 1237,
    "date": 1672532286094,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1237",
    "rarity_score": 235.51869391226802,
    "rarity_level": "COMMON"
  },
  "614": {
    "name": "Arkade 90s Bulls #614",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/614.png",
    "dna": "7c4432e7ae5822f3dcee41580b81e920bee66e34",
    "edition": 614,
    "date": 1672532286676,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "614",
    "rarity_score": 232.37718635232545,
    "rarity_level": "COMMON"
  },
  "1940": {
    "name": "Arkade 90s Bulls #1940",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1940.png",
    "dna": "7fecfaae28b84c1be900912b10f509042e37390b",
    "edition": 1940,
    "date": 1672532287289,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1940",
    "rarity_score": 119.08891963865717,
    "rarity_level": "COMMON"
  },
  "201": {
    "name": "Arkade 90s Bulls #201",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/201.png",
    "dna": "2d542c4561edc616f416b2ca77fdfffd633ff7b5",
    "edition": 201,
    "date": 1672532288074,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "201",
    "rarity_score": 470.1523008997469,
    "rarity_level": "RARE"
  },
  "1492": {
    "name": "Arkade 90s Bulls #1492",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1492.png",
    "dna": "fce1d8097b29c46068e9b12497936fbb8a905afe",
    "edition": 1492,
    "date": 1672532288805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1492",
    "rarity_score": 151.73275364257438,
    "rarity_level": "COMMON"
  },
  "185": {
    "name": "Arkade 90s Bulls #185",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/185.png",
    "dna": "6666d92e8d9fcce574a94284ef7d9fd8c5bbdda3",
    "edition": 185,
    "date": 1672532290045,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "185",
    "rarity_score": 241.16817901792538,
    "rarity_level": "COMMON"
  },
  "567": {
    "name": "Arkade 90s Bulls #567",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/567.png",
    "dna": "7aa0b78becf6dca383e6e2532b0de9f8a1e900f2",
    "edition": 567,
    "date": 1672532290663,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "567",
    "rarity_score": 124.9202500028317,
    "rarity_level": "COMMON"
  },
  "141": {
    "name": "Arkade 90s Bulls #141",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/141.png",
    "dna": "716a9d864348d06674b28c6dcf6d3444d4f7d3ac",
    "edition": 141,
    "date": 1672532291232,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "141",
    "rarity_score": 76.7212588503249,
    "rarity_level": "COMMON"
  },
  "1343": {
    "name": "Arkade 90s Bulls #1343",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1343.png",
    "dna": "b96065f34eb817ef87857adce4aee8e2ad08cc9f",
    "edition": 1343,
    "date": 1672532291761,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1343",
    "rarity_score": 110.22482355738694,
    "rarity_level": "COMMON"
  },
  "61": {
    "name": "Arkade 90s Bulls #61",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/61.png",
    "dna": "205ce7c014322cc66233463c8145997fa1d77083",
    "edition": 61,
    "date": 1672532292578,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "61",
    "rarity_score": 286.77253254384334,
    "rarity_level": "COMMON"
  },
  "207": {
    "name": "Arkade 90s Bulls #207",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/207.png",
    "dna": "dc415844e4f181721b08fbb226797f0e8ac85345",
    "edition": 207,
    "date": 1672532293082,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "207",
    "rarity_score": 113.13418028253312,
    "rarity_level": "COMMON"
  },
  "555": {
    "name": "Arkade 90s Bulls #555",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/555.png",
    "dna": "1bff74a567f3649762f7924cfbb9274be5d66d5c",
    "edition": 555,
    "date": 1672532293580,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "555",
    "rarity_score": 241.2091621250907,
    "rarity_level": "COMMON"
  },
  "867": {
    "name": "Arkade 90s Bulls #867",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/867.png",
    "dna": "ac2103e18709538e392f902668575a39543c1224",
    "edition": 867,
    "date": 1672532294129,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "867",
    "rarity_score": 190.28388572741008,
    "rarity_level": "COMMON"
  },
  "1979": {
    "name": "Arkade 90s Bulls #1979",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1979.png",
    "dna": "558d3ec6cc245d6588206e0ae442d3fbcfd9937e",
    "edition": 1979,
    "date": 1672532294661,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1979",
    "rarity_score": 340.55347693097696,
    "rarity_level": "COMMON"
  },
  "511": {
    "name": "Arkade 90s Bulls #511",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/511.png",
    "dna": "f435e9f175a628a20717bc5dc01078ebe152f140",
    "edition": 511,
    "date": 1672532295796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "511",
    "rarity_score": 231.73454975306615,
    "rarity_level": "COMMON"
  },
  "711": {
    "name": "Arkade 90s Bulls #711",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/711.png",
    "dna": "d18715ec19e22afdd3f63865f440ea2e27034c30",
    "edition": 711,
    "date": 1672532296545,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "711",
    "rarity_score": 259.78096483095754,
    "rarity_level": "COMMON"
  },
  "715": {
    "name": "Arkade 90s Bulls #715",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/715.png",
    "dna": "2dc77ac7b7ee43c555d60db54d7f34516c0dfc8e",
    "edition": 715,
    "date": 1672532297099,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "715",
    "rarity_score": 89.51884035585994,
    "rarity_level": "COMMON"
  },
  "1139": {
    "name": "Arkade 90s Bulls #1139",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1139.png",
    "dna": "406d4c9422388b3e070a665234452959c57c7c5a",
    "edition": 1139,
    "date": 1672532297616,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1139",
    "rarity_score": 196.460462669655,
    "rarity_level": "COMMON"
  },
  "348": {
    "name": "Arkade 90s Bulls #348",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/348.png",
    "dna": "dea8fde7053551ae3a5662414d05420b7a335249",
    "edition": 348,
    "date": 1672532298172,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "348",
    "rarity_score": 434.9253845114812,
    "rarity_level": "COMMON"
  },
  "879": {
    "name": "Arkade 90s Bulls #879",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/879.png",
    "dna": "bc01628addefd12cf5dfc5b40d6f18e447c199da",
    "edition": 879,
    "date": 1672532298646,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "879",
    "rarity_score": 335.25619268155924,
    "rarity_level": "COMMON"
  },
  "1275": {
    "name": "Arkade 90s Bulls #1275",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1275.png",
    "dna": "7c529a2fcc9a32cc89b9a8a466aa9499225d9831",
    "edition": 1275,
    "date": 1672532299718,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1275",
    "rarity_score": 471.7480432565186,
    "rarity_level": "RARE"
  },
  "341": {
    "name": "Arkade 90s Bulls #341",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/341.png",
    "dna": "e62ed43e926130ad68260920df37f59adea33436",
    "edition": 341,
    "date": 1672532300220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "341",
    "rarity_score": 109.04913629426333,
    "rarity_level": "COMMON"
  },
  "1234": {
    "name": "Arkade 90s Bulls #1234",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1234.png",
    "dna": "0527c9e142952bc8fdd5c3659cc1b35d9bb83f6d",
    "edition": 1234,
    "date": 1672532301460,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1234",
    "rarity_score": 133.20267183670924,
    "rarity_level": "COMMON"
  },
  "1559": {
    "name": "Arkade 90s Bulls #1559",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1559.png",
    "dna": "49f57d9da3faa85cf28e219867774c4b781f65de",
    "edition": 1559,
    "date": 1672532302003,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1559",
    "rarity_score": 412.4116501946173,
    "rarity_level": "COMMON"
  },
  "1921": {
    "name": "Arkade 90s Bulls #1921",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1921.png",
    "dna": "ef70f7950a53d6d6899acd3ded05c3656085790b",
    "edition": 1921,
    "date": 1672532302549,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1921",
    "rarity_score": 250.87743568164115,
    "rarity_level": "COMMON"
  },
  "1755": {
    "name": "Arkade 90s Bulls #1755",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1755.png",
    "dna": "f1606e4fc365827bcaaa1cb5a3e4a1667f229a46",
    "edition": 1755,
    "date": 1672532303096,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1755",
    "rarity_score": 324.3010937840115,
    "rarity_level": "COMMON"
  },
  "939": {
    "name": "Arkade 90s Bulls #939",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/939.png",
    "dna": "45eef7cd64db1acca3ee6952a2eb84f86f88915d",
    "edition": 939,
    "date": 1672532303653,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "939",
    "rarity_score": 193.59063236257262,
    "rarity_level": "COMMON"
  },
  "1048": {
    "name": "Arkade 90s Bulls #1048",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1048.png",
    "dna": "f6306640f5d19f227e6f20e9f36a6dd31fa0b760",
    "edition": 1048,
    "date": 1672532304302,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1048",
    "rarity_score": 292.31146288202024,
    "rarity_level": "COMMON"
  },
  "479": {
    "name": "Arkade 90s Bulls #479",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/479.png",
    "dna": "80dcc690fdad05e4522e0a0c250e5166a74fd7f5",
    "edition": 479,
    "date": 1672532304868,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "479",
    "rarity_score": 151.06279994177163,
    "rarity_level": "COMMON"
  },
  "775": {
    "name": "Arkade 90s Bulls #775",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/775.png",
    "dna": "91d644dc6f8f7e373df2a89d706eb604982a132b",
    "edition": 775,
    "date": 1672532305412,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "775",
    "rarity_score": 271.2448939505849,
    "rarity_level": "COMMON"
  },
  "722": {
    "name": "Arkade 90s Bulls #722",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/722.png",
    "dna": "24e1381b307f39d96153c962c8de23ea354172e6",
    "edition": 722,
    "date": 1672532305928,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "722",
    "rarity_score": 215.37887079666004,
    "rarity_level": "COMMON"
  },
  "1608": {
    "name": "Arkade 90s Bulls #1608",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1608.png",
    "dna": "27b6dc360c80bddc4a8d583896621b5b39a245c2",
    "edition": 1608,
    "date": 1672532306472,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1608",
    "rarity_score": 220.75403600797833,
    "rarity_level": "COMMON"
  },
  "1928": {
    "name": "Arkade 90s Bulls #1928",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1928.png",
    "dna": "5a10f72c07638d293e8e0bb17ba68c2a8d530a2e",
    "edition": 1928,
    "date": 1672532307096,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1928",
    "rarity_score": 226.33246716788028,
    "rarity_level": "COMMON"
  },
  "1463": {
    "name": "Arkade 90s Bulls #1463",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1463.png",
    "dna": "37319158d9722fa5ea82e3be822df916dff45fc7",
    "edition": 1463,
    "date": 1672532307961,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1463",
    "rarity_score": 383.2777987331687,
    "rarity_level": "COMMON"
  },
  "135": {
    "name": "Arkade 90s Bulls #135",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/135.png",
    "dna": "1516fba1009c1d9ef600812ff27c8416c72af9bc",
    "edition": 135,
    "date": 1672532308674,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "135",
    "rarity_score": 170.0554205801917,
    "rarity_level": "COMMON"
  },
  "1039": {
    "name": "Arkade 90s Bulls #1039",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1039.png",
    "dna": "e2b5935f392d273c9c9bff2cb0d28e682acdd650",
    "edition": 1039,
    "date": 1672532309233,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1039",
    "rarity_score": 267.86734202998116,
    "rarity_level": "COMMON"
  },
  "217": {
    "name": "Arkade 90s Bulls #217",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/217.png",
    "dna": "6baea7e95f3b5cb8bf98a4d859755aa29f0c69b9",
    "edition": 217,
    "date": 1672532309761,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "217",
    "rarity_score": 272.01166616809616,
    "rarity_level": "COMMON"
  },
  "585": {
    "name": "Arkade 90s Bulls #585",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/585.png",
    "dna": "cd10080477f2845d46568cdbedaccdde0b0cffc4",
    "edition": 585,
    "date": 1672532310352,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "585",
    "rarity_score": 178.99045543105964,
    "rarity_level": "COMMON"
  },
  "349": {
    "name": "Arkade 90s Bulls #349",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/349.png",
    "dna": "feb43985770a261178c4048306029cf2e3d56830",
    "edition": 349,
    "date": 1672532311567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "349",
    "rarity_score": 283.49994279002,
    "rarity_level": "COMMON"
  },
  "932": {
    "name": "Arkade 90s Bulls #932",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/932.png",
    "dna": "2645ee1fb4837ddb50f94b68b127c5a41d2fe63b",
    "edition": 932,
    "date": 1672532312135,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "932",
    "rarity_score": 237.68965645390745,
    "rarity_level": "COMMON"
  },
  "304": {
    "name": "Arkade 90s Bulls #304",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/304.png",
    "dna": "4142bec100d9a32a5ef74867366def7d6a2e63d8",
    "edition": 304,
    "date": 1672532313365,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "304",
    "rarity_score": 362.63320386156755,
    "rarity_level": "COMMON"
  },
  "935": {
    "name": "Arkade 90s Bulls #935",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/935.png",
    "dna": "a0e85e53f55ed377c670812cf7964df06fc95f85",
    "edition": 935,
    "date": 1672532313884,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "935",
    "rarity_score": 306.1690865331879,
    "rarity_level": "COMMON"
  },
  "1729": {
    "name": "Arkade 90s Bulls #1729",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1729.png",
    "dna": "927afa938c199c63045ccb3d965953e2414517f8",
    "edition": 1729,
    "date": 1672532314465,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1729",
    "rarity_score": 318.27518640004,
    "rarity_level": "COMMON"
  },
  "1038": {
    "name": "Arkade 90s Bulls #1038",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1038.png",
    "dna": "06e8cdd6d5a4fdb1150c45942c44df5541bb821a",
    "edition": 1038,
    "date": 1672532315323,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1038",
    "rarity_score": 350.16940124812294,
    "rarity_level": "COMMON"
  },
  "18": {
    "name": "Arkade 90s Bulls #18",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/18.png",
    "dna": "09469bac6cc1b0acc0d7b8d2ff61406925d1d162",
    "edition": 18,
    "date": 1672532315906,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "18",
    "rarity_score": 457.46962738671675,
    "rarity_level": "COMMON"
  },
  "799": {
    "name": "Arkade 90s Bulls #799",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/799.png",
    "dna": "a5a9f15aa354b40d1a7a9d1d30c307b456f3f000",
    "edition": 799,
    "date": 1672532316420,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "799",
    "rarity_score": 406.7282501006844,
    "rarity_level": "COMMON"
  },
  "573": {
    "name": "Arkade 90s Bulls #573",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/573.png",
    "dna": "78600d0983227a7db4dfd55d7a2da248a2e9f208",
    "edition": 573,
    "date": 1672532316972,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "573",
    "rarity_score": 437.60036001261295,
    "rarity_level": "COMMON"
  },
  "894": {
    "name": "Arkade 90s Bulls #894",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/894.png",
    "dna": "0a02a8ac08a792fb744e20e814c7ffec763b2b3f",
    "edition": 894,
    "date": 1672532317490,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "894",
    "rarity_score": 455.981515283018,
    "rarity_level": "COMMON"
  },
  "587": {
    "name": "Arkade 90s Bulls #587",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/587.png",
    "dna": "7880268315d32b4d5a35ec1664ecab246e62ca1f",
    "edition": 587,
    "date": 1672532318078,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "587",
    "rarity_score": 172.0049537990705,
    "rarity_level": "COMMON"
  },
  "92": {
    "name": "Arkade 90s Bulls #92",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/92.png",
    "dna": "740a33fd86e546509959b28b61af0989f5c784a5",
    "edition": 92,
    "date": 1672532318615,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "92",
    "rarity_score": 157.23144924474443,
    "rarity_level": "COMMON"
  },
  "353": {
    "name": "Arkade 90s Bulls #353",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/353.png",
    "dna": "e819aa743b723ff38fc4919b7fd709acf7ee424a",
    "edition": 353,
    "date": 1672532319130,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "353",
    "rarity_score": 223.36419927002203,
    "rarity_level": "COMMON"
  },
  "1757": {
    "name": "Arkade 90s Bulls #1757",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1757.png",
    "dna": "3cc287d75375c1c855ad6e460b4008e910fef555",
    "edition": 1757,
    "date": 1672532319688,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1757",
    "rarity_score": 145.839122403116,
    "rarity_level": "COMMON"
  },
  "1490": {
    "name": "Arkade 90s Bulls #1490",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1490.png",
    "dna": "88e50a6fb46de8458cd509d7176d2e53e81c1b3e",
    "edition": 1490,
    "date": 1672532320219,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1490",
    "rarity_score": 515.2597214339869,
    "rarity_level": "RARE"
  },
  "377": {
    "name": "Arkade 90s Bulls #377",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/377.png",
    "dna": "41d147bf2d61c4c0acae7c3bcd579c01bad66bb1",
    "edition": 377,
    "date": 1672532320728,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "377",
    "rarity_score": 446.871821707637,
    "rarity_level": "COMMON"
  },
  "410": {
    "name": "Arkade 90s Bulls #410",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/410.png",
    "dna": "5015b8472d2bb623a7a2525191a4bd38b170a696",
    "edition": 410,
    "date": 1672532321518,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "410",
    "rarity_score": 219.17267168393377,
    "rarity_level": "COMMON"
  },
  "63": {
    "name": "Arkade 90s Bulls #63",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/63.png",
    "dna": "aa6854fdef0b665e6f88bdabcac8c39143c78ebb",
    "edition": 63,
    "date": 1672532322196,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "63",
    "rarity_score": 234.0380532135926,
    "rarity_level": "COMMON"
  },
  "1308": {
    "name": "Arkade 90s Bulls #1308",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1308.png",
    "dna": "6ebbef61b4f4503b740a441b3d6a069d2f496045",
    "edition": 1308,
    "date": 1672532322778,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1308",
    "rarity_score": 412.7228893171155,
    "rarity_level": "COMMON"
  },
  "1799": {
    "name": "Arkade 90s Bulls #1799",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1799.png",
    "dna": "325ac0cee2058a43108c31ba08d25aab76281d3c",
    "edition": 1799,
    "date": 1672532323363,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1799",
    "rarity_score": 218.68302535571323,
    "rarity_level": "COMMON"
  },
  "1707": {
    "name": "Arkade 90s Bulls #1707",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1707.png",
    "dna": "c9b0e97d2120f5c1f1d009bfd3a0b8247b50f9d8",
    "edition": 1707,
    "date": 1672532323903,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1707",
    "rarity_score": 431.3086792199689,
    "rarity_level": "COMMON"
  },
  "1346": {
    "name": "Arkade 90s Bulls #1346",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1346.png",
    "dna": "6c842b6e80cc84938b160ba52d812b0dfe262d1a",
    "edition": 1346,
    "date": 1672532324491,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1346",
    "rarity_score": 199.57299156952115,
    "rarity_level": "COMMON"
  },
  "1386": {
    "name": "Arkade 90s Bulls #1386",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1386.png",
    "dna": "486cded1db00e342ac8437a6ca5ae19aef48118e",
    "edition": 1386,
    "date": 1672532325680,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1386",
    "rarity_score": 300.66352149611686,
    "rarity_level": "COMMON"
  },
  "1259": {
    "name": "Arkade 90s Bulls #1259",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1259.png",
    "dna": "9f3df896c290a89160763d9dc62d902093031fc3",
    "edition": 1259,
    "date": 1672532326520,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1259",
    "rarity_score": 383.04267664029135,
    "rarity_level": "COMMON"
  },
  "842": {
    "name": "Arkade 90s Bulls #842",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/842.png",
    "dna": "f0d0c516ff9b01c03ff336748e6683b985da0ce6",
    "edition": 842,
    "date": 1672532327244,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "842",
    "rarity_score": 215.94718055795943,
    "rarity_level": "COMMON"
  },
  "1329": {
    "name": "Arkade 90s Bulls #1329",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1329.png",
    "dna": "a4d53076bcafab05724d5ac274ba1153417da82e",
    "edition": 1329,
    "date": 1672532327807,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1329",
    "rarity_score": 165.14861807645727,
    "rarity_level": "COMMON"
  },
  "1041": {
    "name": "Arkade 90s Bulls #1041",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1041.png",
    "dna": "3b91385dd19f1a6cb600674979b98ff73e8490cc",
    "edition": 1041,
    "date": 1672532328351,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1041",
    "rarity_score": 371.8277340900477,
    "rarity_level": "COMMON"
  },
  "1377": {
    "name": "Arkade 90s Bulls #1377",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1377.png",
    "dna": "bf3ee52f6eeee1e36b075e2316d88db75dd39894",
    "edition": 1377,
    "date": 1672532328863,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1377",
    "rarity_score": 430.00766543906906,
    "rarity_level": "COMMON"
  },
  "618": {
    "name": "Arkade 90s Bulls #618",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/618.png",
    "dna": "9aa031a16dc89c57459acb470270b20d25255bc8",
    "edition": 618,
    "date": 1672532329395,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "618",
    "rarity_score": 124.2357161222229,
    "rarity_level": "COMMON"
  },
  "1976": {
    "name": "Arkade 90s Bulls #1976",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1976.png",
    "dna": "b484a939ddd1913735608422e2a94188c8a6a3e8",
    "edition": 1976,
    "date": 1672532330050,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1976",
    "rarity_score": 334.7032896844772,
    "rarity_level": "COMMON"
  },
  "1022": {
    "name": "Arkade 90s Bulls #1022",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1022.png",
    "dna": "37ec7e063e3b7d6addeb1578465926010da885d2",
    "edition": 1022,
    "date": 1672532331391,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1022",
    "rarity_score": 739.9222272594468,
    "rarity_level": "RARE"
  },
  "1301": {
    "name": "Arkade 90s Bulls #1301",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1301.png",
    "dna": "b59f3f4028a6d6310ba49307185bdf4eeeff1445",
    "edition": 1301,
    "date": 1672532331936,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1301",
    "rarity_score": 552.8212199765043,
    "rarity_level": "RARE"
  },
  "831": {
    "name": "Arkade 90s Bulls #831",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/831.png",
    "dna": "3f1c3db23ca99bce1d8e55ad5cbbcc3ebffa05ca",
    "edition": 831,
    "date": 1672532332534,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "831",
    "rarity_score": 303.96878307736176,
    "rarity_level": "COMMON"
  },
  "1508": {
    "name": "Arkade 90s Bulls #1508",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1508.png",
    "dna": "7551c05dcc55725f0b7b3655bbf4fe13dcf09adb",
    "edition": 1508,
    "date": 1672532333114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1508",
    "rarity_score": 285.2583310024588,
    "rarity_level": "COMMON"
  },
  "46": {
    "name": "Arkade 90s Bulls #46",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/46.png",
    "dna": "ad48de66852b84f1763cfe827cc1988c20b1c1ed",
    "edition": 46,
    "date": 1672532333700,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "46",
    "rarity_score": 291.55724733963285,
    "rarity_level": "COMMON"
  },
  "9": {
    "name": "Arkade 90s Bulls #9",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/9.png",
    "dna": "1801ca6f8588c464ed4db988cf26eef1ca9dec51",
    "edition": 9,
    "date": 1672532334544,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "9",
    "rarity_score": 389.2307491898729,
    "rarity_level": "COMMON"
  },
  "1381": {
    "name": "Arkade 90s Bulls #1381",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1381.png",
    "dna": "23c10957f784ba4fb840b98699b4d95679785a68",
    "edition": 1381,
    "date": 1672532335155,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Yuki Muko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1381",
    "rarity_score": 534.137844895876,
    "rarity_level": "RARE"
  },
  "680": {
    "name": "Arkade 90s Bulls #680",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/680.png",
    "dna": "4f8b8d76eb2f233b32a0160492ebc94d1b5bf438",
    "edition": 680,
    "date": 1672532335724,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "680",
    "rarity_score": 125.02402522584875,
    "rarity_level": "COMMON"
  },
  "431": {
    "name": "Arkade 90s Bulls #431",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/431.png",
    "dna": "8cdb8c91702645bb35a05373aee7dbb3b9dd2a27",
    "edition": 431,
    "date": 1672532336426,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "431",
    "rarity_score": 208.01157945608898,
    "rarity_level": "COMMON"
  },
  "327": {
    "name": "Arkade 90s Bulls #327",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/327.png",
    "dna": "dfb080f6412957090738553520223933da08ee20",
    "edition": 327,
    "date": 1672532337748,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "327",
    "rarity_score": 317.0721289524104,
    "rarity_level": "COMMON"
  },
  "525": {
    "name": "Arkade 90s Bulls #525",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/525.png",
    "dna": "55af3e6c245d1ac6fdd4233d17c4131b3967dfb9",
    "edition": 525,
    "date": 1672532339013,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "525",
    "rarity_score": 623.1855884453306,
    "rarity_level": "RARE"
  },
  "336": {
    "name": "Arkade 90s Bulls #336",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/336.png",
    "dna": "f50e7c87cae9f06d994864602242aaf4c7f23398",
    "edition": 336,
    "date": 1672532339561,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Banana Peel",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "336",
    "rarity_score": 924.7169717122307,
    "rarity_level": "RARE"
  },
  "1448": {
    "name": "Arkade 90s Bulls #1448",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1448.png",
    "dna": "24b6f9bad14002261b4db7172127d1971b180f1a",
    "edition": 1448,
    "date": 1672532340121,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1448",
    "rarity_score": 258.52026893397846,
    "rarity_level": "COMMON"
  },
  "218": {
    "name": "Arkade 90s Bulls #218",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/218.png",
    "dna": "bef3e1bb5d1e6866f479e15677bc35dc8cc0be83",
    "edition": 218,
    "date": 1672532340708,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "218",
    "rarity_score": 197.53504867828465,
    "rarity_level": "COMMON"
  },
  "615": {
    "name": "Arkade 90s Bulls #615",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/615.png",
    "dna": "ad7f5c6c425982e149a83a93fab0abe330cdb6cd",
    "edition": 615,
    "date": 1672532342058,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "615",
    "rarity_score": 220.57165407073632,
    "rarity_level": "COMMON"
  },
  "1359": {
    "name": "Arkade 90s Bulls #1359",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1359.png",
    "dna": "aa54f1336fe14c1d6f2d6dc8e97f0dc79ab45248",
    "edition": 1359,
    "date": 1672532342613,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1359",
    "rarity_score": 182.44921878831923,
    "rarity_level": "COMMON"
  },
  "1468": {
    "name": "Arkade 90s Bulls #1468",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1468.png",
    "dna": "5ce4088ad9fae3b3d8fd462be8b11dad797123d8",
    "edition": 1468,
    "date": 1672532343363,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1468",
    "rarity_score": 254.20966424564747,
    "rarity_level": "COMMON"
  },
  "332": {
    "name": "Arkade 90s Bulls #332",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/332.png",
    "dna": "4c7f7f62bca99959abfa01760c3996cc5289fe3f",
    "edition": 332,
    "date": 1672532344426,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "332",
    "rarity_score": 288.88315073602575,
    "rarity_level": "COMMON"
  },
  "1249": {
    "name": "Arkade 90s Bulls #1249",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1249.png",
    "dna": "7f0114e473b857ad223343bcdf95e7dfaf825fea",
    "edition": 1249,
    "date": 1672532344949,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1249",
    "rarity_score": 445.46094741263516,
    "rarity_level": "COMMON"
  },
  "330": {
    "name": "Arkade 90s Bulls #330",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/330.png",
    "dna": "035fdd19f36ee160346ce35597eb930a87a01aec",
    "edition": 330,
    "date": 1672532346117,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "330",
    "rarity_score": 263.8923248552667,
    "rarity_level": "COMMON"
  },
  "372": {
    "name": "Arkade 90s Bulls #372",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/372.png",
    "dna": "3543707f3a0bea0e77125bf8ddba44c5bc9fc0a0",
    "edition": 372,
    "date": 1672532347469,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "372",
    "rarity_score": 379.7106844577901,
    "rarity_level": "COMMON"
  },
  "962": {
    "name": "Arkade 90s Bulls #962",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/962.png",
    "dna": "c144cc9efb67cf01d1c6744b6941639fe0a5b9e0",
    "edition": 962,
    "date": 1672532348714,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "962",
    "rarity_score": 439.1649007990032,
    "rarity_level": "COMMON"
  },
  "1034": {
    "name": "Arkade 90s Bulls #1034",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1034.png",
    "dna": "7d2ea603da551007df34d7b06215f19ef2053d53",
    "edition": 1034,
    "date": 1672532349283,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1034",
    "rarity_score": 180.47684050719954,
    "rarity_level": "COMMON"
  },
  "1905": {
    "name": "Arkade 90s Bulls #1905",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1905.png",
    "dna": "360d5c6be657a0f5b7d192c53ac13c02d4d00773",
    "edition": 1905,
    "date": 1672532349790,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1905",
    "rarity_score": 185.40139452166343,
    "rarity_level": "COMMON"
  },
  "878": {
    "name": "Arkade 90s Bulls #878",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/878.png",
    "dna": "3eeb264f3d8f231746aae4e025d39ec9a1311e55",
    "edition": 878,
    "date": 1672532350547,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "878",
    "rarity_score": 552.9286412444719,
    "rarity_level": "RARE"
  },
  "1062": {
    "name": "Arkade 90s Bulls #1062",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1062.png",
    "dna": "dc5817a02d5c10bf3bb7a467e0bed8a2dc77832f",
    "edition": 1062,
    "date": 1672532351140,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1062",
    "rarity_score": 291.59232675593347,
    "rarity_level": "COMMON"
  },
  "1197": {
    "name": "Arkade 90s Bulls #1197",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1197.png",
    "dna": "ddd7498681b8d30854b10eaa90d0bcc38e95b1dd",
    "edition": 1197,
    "date": 1672532352429,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1197",
    "rarity_score": 272.8953398831688,
    "rarity_level": "COMMON"
  },
  "1587": {
    "name": "Arkade 90s Bulls #1587",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1587.png",
    "dna": "5cdeb877cfaa492ca31dd4618960bcb7db96bf05",
    "edition": 1587,
    "date": 1672532353049,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1587",
    "rarity_score": 208.64928116383464,
    "rarity_level": "COMMON"
  },
  "559": {
    "name": "Arkade 90s Bulls #559",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/559.png",
    "dna": "a992d9c28f9cac710f5a56ddce24e3bfdf96680b",
    "edition": 559,
    "date": 1672532353651,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "559",
    "rarity_score": 403.9878208958171,
    "rarity_level": "COMMON"
  },
  "971": {
    "name": "Arkade 90s Bulls #971",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/971.png",
    "dna": "62f860fdf876920ce3a8e8d2515701fb45303d07",
    "edition": 971,
    "date": 1672532354461,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "971",
    "rarity_score": 482.04720144250985,
    "rarity_level": "RARE"
  },
  "1927": {
    "name": "Arkade 90s Bulls #1927",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1927.png",
    "dna": "15952fd62aa93f9add9ccd1bf0ef4a266592f5a6",
    "edition": 1927,
    "date": 1672532355247,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1927",
    "rarity_score": 153.69490689450174,
    "rarity_level": "COMMON"
  },
  "1772": {
    "name": "Arkade 90s Bulls #1772",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1772.png",
    "dna": "c3b40bcf429a5f9f970006ef5c5a0c4bfafca413",
    "edition": 1772,
    "date": 1672532355832,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1772",
    "rarity_score": 125.44644221281169,
    "rarity_level": "COMMON"
  },
  "650": {
    "name": "Arkade 90s Bulls #650",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/650.png",
    "dna": "09725776b553c5facaba7daffb948eeaaccd60ce",
    "edition": 650,
    "date": 1672532356432,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "650",
    "rarity_score": 269.20752868758797,
    "rarity_level": "COMMON"
  },
  "1891": {
    "name": "Arkade 90s Bulls #1891",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1891.png",
    "dna": "6343087eab0c4b006a18eeaf9683028aed8d5bdd",
    "edition": 1891,
    "date": 1672532357695,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1891",
    "rarity_score": 324.30731172666356,
    "rarity_level": "COMMON"
  },
  "1787": {
    "name": "Arkade 90s Bulls #1787",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1787.png",
    "dna": "3c15bc1f385ea8648470c687f01f02f465af0694",
    "edition": 1787,
    "date": 1672532358267,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1787",
    "rarity_score": 288.6882739679847,
    "rarity_level": "COMMON"
  },
  "1860": {
    "name": "Arkade 90s Bulls #1860",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1860.png",
    "dna": "751f62535ea6200895cb236a1fd80c31e313c23e",
    "edition": 1860,
    "date": 1672532358844,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1860",
    "rarity_score": 110.71400766500095,
    "rarity_level": "COMMON"
  },
  "727": {
    "name": "Arkade 90s Bulls #727",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/727.png",
    "dna": "8f7931e78318169887aec70f192fd7d793757cde",
    "edition": 727,
    "date": 1672532359435,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "727",
    "rarity_score": 168.4604001633922,
    "rarity_level": "COMMON"
  },
  "966": {
    "name": "Arkade 90s Bulls #966",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/966.png",
    "dna": "9ac6b3c4d22c8e7ede6d7f7413a9a8c504a306a8",
    "edition": 966,
    "date": 1672532360141,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "966",
    "rarity_score": 163.25414124078708,
    "rarity_level": "COMMON"
  },
  "1957": {
    "name": "Arkade 90s Bulls #1957",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1957.png",
    "dna": "d16a11e979c9999793388d8df425dd104042139c",
    "edition": 1957,
    "date": 1672532360787,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Evil",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1957",
    "rarity_score": 470.454781065767,
    "rarity_level": "RARE"
  },
  "242": {
    "name": "Arkade 90s Bulls #242",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/242.png",
    "dna": "f8cf7400b90adfb8457274c534f6eb897b7bf52e",
    "edition": 242,
    "date": 1672532361358,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "242",
    "rarity_score": 256.26619473941463,
    "rarity_level": "COMMON"
  },
  "981": {
    "name": "Arkade 90s Bulls #981",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/981.png",
    "dna": "8eb5436f40a6d98ecd344f960ac6df41e6f3fec2",
    "edition": 981,
    "date": 1672532361985,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "981",
    "rarity_score": 198.97598034385354,
    "rarity_level": "COMMON"
  },
  "882": {
    "name": "Arkade 90s Bulls #882",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/882.png",
    "dna": "750fa95573b462b4c0c9bdd2723fca9497bfeb8e",
    "edition": 882,
    "date": 1672532363305,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "882",
    "rarity_score": 328.94743648366523,
    "rarity_level": "COMMON"
  },
  "677": {
    "name": "Arkade 90s Bulls #677",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/677.png",
    "dna": "fe6e215dde02416b6f2a34a4923970a0128e2048",
    "edition": 677,
    "date": 1672532364426,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "677",
    "rarity_score": 237.03496020551313,
    "rarity_level": "COMMON"
  },
  "1019": {
    "name": "Arkade 90s Bulls #1019",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1019.png",
    "dna": "62581856a49a30a76a8332ff8c565e25ac350716",
    "edition": 1019,
    "date": 1672532365016,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1019",
    "rarity_score": 136.99427178825843,
    "rarity_level": "COMMON"
  },
  "177": {
    "name": "Arkade 90s Bulls #177",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/177.png",
    "dna": "4ebc86f7216806354b30cef807d4ae46a34c7e10",
    "edition": 177,
    "date": 1672532365563,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "177",
    "rarity_score": 298.83810165611624,
    "rarity_level": "COMMON"
  },
  "1615": {
    "name": "Arkade 90s Bulls #1615",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1615.png",
    "dna": "4f9520a71bf05ddefd9e03e8d6cacddb2a0f9d78",
    "edition": 1615,
    "date": 1672532366223,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1615",
    "rarity_score": 311.9859335446457,
    "rarity_level": "COMMON"
  },
  "1851": {
    "name": "Arkade 90s Bulls #1851",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1851.png",
    "dna": "78cec1af664f465f8d7c06f14bacef3c2c0a8443",
    "edition": 1851,
    "date": 1672532367619,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1851",
    "rarity_score": 146.4248131080267,
    "rarity_level": "COMMON"
  },
  "1238": {
    "name": "Arkade 90s Bulls #1238",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1238.png",
    "dna": "b801a0b70a624385417bfc899eab61bd2cf569b6",
    "edition": 1238,
    "date": 1672532368902,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1238",
    "rarity_score": 314.72238348168804,
    "rarity_level": "COMMON"
  },
  "814": {
    "name": "Arkade 90s Bulls #814",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/814.png",
    "dna": "206e28e537de7ff0228b94f842d4bdf759cff99a",
    "edition": 814,
    "date": 1672532370052,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Silver Smile",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "814",
    "rarity_score": 336.87688584485346,
    "rarity_level": "COMMON"
  },
  "834": {
    "name": "Arkade 90s Bulls #834",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/834.png",
    "dna": "205a136f5c63aae4f1ecafd515779b82fb7ad6fd",
    "edition": 834,
    "date": 1672532371349,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "834",
    "rarity_score": 438.82350627649424,
    "rarity_level": "COMMON"
  },
  "1873": {
    "name": "Arkade 90s Bulls #1873",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1873.png",
    "dna": "e19fbdb76271651890dd68328a52ab943009c7d2",
    "edition": 1873,
    "date": 1672532372635,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1873",
    "rarity_score": 329.79008476622465,
    "rarity_level": "COMMON"
  },
  "1351": {
    "name": "Arkade 90s Bulls #1351",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1351.png",
    "dna": "bfc76e63dcf446a060f640b1350f050072c0bc50",
    "edition": 1351,
    "date": 1672532373304,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1351",
    "rarity_score": 601.7523223162087,
    "rarity_level": "RARE"
  },
  "408": {
    "name": "Arkade 90s Bulls #408",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/408.png",
    "dna": "3da7d19a839e28c4ccc43873b3b802161c6f2949",
    "edition": 408,
    "date": 1672532373855,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "408",
    "rarity_score": 168.51851260021544,
    "rarity_level": "COMMON"
  },
  "245": {
    "name": "Arkade 90s Bulls #245",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/245.png",
    "dna": "8b80e2cd982a322e8e7137de3645d8a2c28993dc",
    "edition": 245,
    "date": 1672532374384,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "245",
    "rarity_score": 268.5198665677367,
    "rarity_level": "COMMON"
  },
  "523": {
    "name": "Arkade 90s Bulls #523",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/523.png",
    "dna": "a324653819b6d6573c5c6059fd69c77b408b3c56",
    "edition": 523,
    "date": 1672532375626,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Sad",
        "score": 52.36842105263158
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "523",
    "rarity_score": 262.5064189084724,
    "rarity_level": "COMMON"
  },
  "1108": {
    "name": "Arkade 90s Bulls #1108",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1108.png",
    "dna": "44dafa520e9754a06484c998d4eeba760c5cc1c7",
    "edition": 1108,
    "date": 1672532376872,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Silver Happy",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1108",
    "rarity_score": 564.4633945676618,
    "rarity_level": "RARE"
  },
  "854": {
    "name": "Arkade 90s Bulls #854",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/854.png",
    "dna": "9ffcad51fb9ca98f4f8b17ff246faf9c1476c523",
    "edition": 854,
    "date": 1672532378148,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "854",
    "rarity_score": 217.6130683057813,
    "rarity_level": "COMMON"
  },
  "428": {
    "name": "Arkade 90s Bulls #428",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/428.png",
    "dna": "f86f3fc805bdca4dd690a3b162acff524bbf88ad",
    "edition": 428,
    "date": 1672532378680,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Silver Angry",
        "score": 55.27777777777778
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "428",
    "rarity_score": 237.3599581744329,
    "rarity_level": "COMMON"
  },
  "5": {
    "name": "Arkade 90s Bulls #5",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/5.png",
    "dna": "f4860dff5a933229ebfb158c14032162dfed7226",
    "edition": 5,
    "date": 1672532379480,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "5",
    "rarity_score": 98.07672166708915,
    "rarity_level": "COMMON"
  },
  "1191": {
    "name": "Arkade 90s Bulls #1191",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1191.png",
    "dna": "70be31442b6b836bfc2a4a6baa3ce44f8c583073",
    "edition": 1191,
    "date": 1672532380123,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1191",
    "rarity_score": 414.4492612365881,
    "rarity_level": "COMMON"
  },
  "1745": {
    "name": "Arkade 90s Bulls #1745",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1745.png",
    "dna": "8e443a056f0fdb79fd48a3a75dadb4d9488c4bf8",
    "edition": 1745,
    "date": 1672532381631,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1745",
    "rarity_score": 242.4114690124724,
    "rarity_level": "COMMON"
  },
  "1595": {
    "name": "Arkade 90s Bulls #1595",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1595.png",
    "dna": "9899e61f58f7319053c9c7270d4912339266b84f",
    "edition": 1595,
    "date": 1672532382374,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1595",
    "rarity_score": 199.1698728442746,
    "rarity_level": "COMMON"
  },
  "26": {
    "name": "Arkade 90s Bulls #26",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/26.png",
    "dna": "e25785ee112875ee5de24b36bbedfc77f819865f",
    "edition": 26,
    "date": 1672532383310,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "26",
    "rarity_score": 109.71359815455571,
    "rarity_level": "COMMON"
  },
  "642": {
    "name": "Arkade 90s Bulls #642",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/642.png",
    "dna": "a1fb3429548c42b4cbf37cb5016c4f9aec5c24aa",
    "edition": 642,
    "date": 1672532383996,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Green Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "642",
    "rarity_score": 606.6841727085466,
    "rarity_level": "RARE"
  },
  "1916": {
    "name": "Arkade 90s Bulls #1916",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1916.png",
    "dna": "b9572c544224b70eb1c8f68e460f841de6e5272c",
    "edition": 1916,
    "date": 1672532384639,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1916",
    "rarity_score": 264.2311065221989,
    "rarity_level": "COMMON"
  },
  "1017": {
    "name": "Arkade 90s Bulls #1017",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1017.png",
    "dna": "504ad750f95d84ed6a120a5a9605dd6142863be9",
    "edition": 1017,
    "date": 1672532385988,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1017",
    "rarity_score": 145.3079980356501,
    "rarity_level": "COMMON"
  },
  "681": {
    "name": "Arkade 90s Bulls #681",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/681.png",
    "dna": "1da788412b681e8f46ae7240fe4f650104b79ed1",
    "edition": 681,
    "date": 1672532387473,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "681",
    "rarity_score": 169.04008956277517,
    "rarity_level": "COMMON"
  },
  "610": {
    "name": "Arkade 90s Bulls #610",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/610.png",
    "dna": "bfeae8918d7c70e65e5e76812fd64e1007e9de0d",
    "edition": 610,
    "date": 1672532388242,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "610",
    "rarity_score": 230.33910610931503,
    "rarity_level": "COMMON"
  },
  "494": {
    "name": "Arkade 90s Bulls #494",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/494.png",
    "dna": "b87af30656a51972f2ae8be0bca9849909b93bf6",
    "edition": 494,
    "date": 1672532389148,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "494",
    "rarity_score": 257.5498678453111,
    "rarity_level": "COMMON"
  },
  "863": {
    "name": "Arkade 90s Bulls #863",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/863.png",
    "dna": "9fe14eaa109e9e6757bd6b11ac2d9c2f4b9b6cd6",
    "edition": 863,
    "date": 1672532390078,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "863",
    "rarity_score": 122.17485703434052,
    "rarity_level": "COMMON"
  },
  "1871": {
    "name": "Arkade 90s Bulls #1871",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1871.png",
    "dna": "d21056ad1f66facfc8e3aec712a8ee4a0ff99e68",
    "edition": 1871,
    "date": 1672532390796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1871",
    "rarity_score": 308.60518921455616,
    "rarity_level": "COMMON"
  },
  "360": {
    "name": "Arkade 90s Bulls #360",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/360.png",
    "dna": "47850b8662be215cc5e9c8cf37c9d1c00b8f97ed",
    "edition": 360,
    "date": 1672532391527,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "360",
    "rarity_score": 167.8486841449449,
    "rarity_level": "COMMON"
  },
  "1252": {
    "name": "Arkade 90s Bulls #1252",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1252.png",
    "dna": "dab3286188ef717ebcb6e473b12ebe6ec84f74a2",
    "edition": 1252,
    "date": 1672532392236,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1252",
    "rarity_score": 222.45625415603,
    "rarity_level": "COMMON"
  },
  "90": {
    "name": "Arkade 90s Bulls #90",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/90.png",
    "dna": "72d9134c2f5863bc56490c8fdba64d11041085d4",
    "edition": 90,
    "date": 1672532392853,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "90",
    "rarity_score": 345.52573709945744,
    "rarity_level": "COMMON"
  },
  "1421": {
    "name": "Arkade 90s Bulls #1421",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1421.png",
    "dna": "f6df785f507307c458175fe28dd0e9b5ce1d6d8a",
    "edition": 1421,
    "date": 1672532394294,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1421",
    "rarity_score": 264.11679356508677,
    "rarity_level": "COMMON"
  },
  "87": {
    "name": "Arkade 90s Bulls #87",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/87.png",
    "dna": "dcb7353af4b7282a378805ee5c479a7ffb298a02",
    "edition": 87,
    "date": 1672532395057,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "87",
    "rarity_score": 461.4060333722625,
    "rarity_level": "RARE"
  },
  "1056": {
    "name": "Arkade 90s Bulls #1056",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1056.png",
    "dna": "dd64beed8764153cd2b6ccf21287cd3d51e88cdd",
    "edition": 1056,
    "date": 1672532396039,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1056",
    "rarity_score": 211.02903919872716,
    "rarity_level": "COMMON"
  },
  "1753": {
    "name": "Arkade 90s Bulls #1753",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1753.png",
    "dna": "54c8be15cbd4e507584039fb939baaba936b6d75",
    "edition": 1753,
    "date": 1672532396714,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1753",
    "rarity_score": 396.3602454904059,
    "rarity_level": "COMMON"
  },
  "1718": {
    "name": "Arkade 90s Bulls #1718",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1718.png",
    "dna": "c7ba0ce32243fbb1f5efa5f4ada170a402b47686",
    "edition": 1718,
    "date": 1672532397402,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1718",
    "rarity_score": 237.70461029994283,
    "rarity_level": "COMMON"
  },
  "1106": {
    "name": "Arkade 90s Bulls #1106",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1106.png",
    "dna": "04e0dec998a184974647eaaacd9ea12088626410",
    "edition": 1106,
    "date": 1672532398819,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1106",
    "rarity_score": 207.81520316503003,
    "rarity_level": "COMMON"
  },
  "1036": {
    "name": "Arkade 90s Bulls #1036",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1036.png",
    "dna": "79d54e7c5f87d32b937782fe9f44f4934fbe3ea3",
    "edition": 1036,
    "date": 1672532399523,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1036",
    "rarity_score": 107.94046025490367,
    "rarity_level": "COMMON"
  },
  "659": {
    "name": "Arkade 90s Bulls #659",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/659.png",
    "dna": "1fd8de8715eefd94e06750064238f13fe9774ddb",
    "edition": 659,
    "date": 1672532400375,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      { "trait_type": "Mask", "value": "Turbobull", "score": 398.0 },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "659",
    "rarity_score": 547.5825774039948,
    "rarity_level": "RARE"
  },
  "1502": {
    "name": "Arkade 90s Bulls #1502",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1502.png",
    "dna": "9139fe135eda9f3419ef2f8af9430df10dca213e",
    "edition": 1502,
    "date": 1672532401057,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1502",
    "rarity_score": 146.31320860336484,
    "rarity_level": "COMMON"
  },
  "1529": {
    "name": "Arkade 90s Bulls #1529",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1529.png",
    "dna": "f09484b14ee4e6bbadac47c7b3890a6c9fa03ab4",
    "edition": 1529,
    "date": 1672532401730,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1529",
    "rarity_score": 135.45838701400137,
    "rarity_level": "COMMON"
  },
  "226": {
    "name": "Arkade 90s Bulls #226",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/226.png",
    "dna": "35f3425bcfc61dcb57a736cc966e2b90d718f814",
    "edition": 226,
    "date": 1672532402630,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "226",
    "rarity_score": 271.6019532776962,
    "rarity_level": "COMMON"
  },
  "170": {
    "name": "Arkade 90s Bulls #170",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/170.png",
    "dna": "f216d60a5e2ff03a3e710770ab9fded369bbf73b",
    "edition": 170,
    "date": 1672532403399,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "170",
    "rarity_score": 287.3895237893461,
    "rarity_level": "COMMON"
  },
  "777": {
    "name": "Arkade 90s Bulls #777",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/777.png",
    "dna": "de319735804213da51a6865506444f56ed33c164",
    "edition": 777,
    "date": 1672532404203,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "777",
    "rarity_score": 158.85600598269937,
    "rarity_level": "COMMON"
  },
  "1535": {
    "name": "Arkade 90s Bulls #1535",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1535.png",
    "dna": "e9c154aec417d15d93a72108d131e8d6baefb995",
    "edition": 1535,
    "date": 1672532404860,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Spinner", "score": 398.0 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "UFO",
        "score": 663.3333333333334
      }
    ],
    "id": "1535",
    "rarity_score": 1285.194849959969,
    "rarity_level": "EPIC"
  },
  "1598": {
    "name": "Arkade 90s Bulls #1598",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1598.png",
    "dna": "84d49d85035998978473725e8b634d8a7d1e795d",
    "edition": 1598,
    "date": 1672532405442,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1598",
    "rarity_score": 352.156552158477,
    "rarity_level": "COMMON"
  },
  "1029": {
    "name": "Arkade 90s Bulls #1029",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1029.png",
    "dna": "7ccccdd615b41223f22dc5d845640757a619cff9",
    "edition": 1029,
    "date": 1672532406101,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1029",
    "rarity_score": 445.8945501751562,
    "rarity_level": "COMMON"
  },
  "1530": {
    "name": "Arkade 90s Bulls #1530",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1530.png",
    "dna": "1a083e457c9850a74f4ef1eebd979fe2bd6f2afa",
    "edition": 1530,
    "date": 1672532406783,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1530",
    "rarity_score": 163.015708500826,
    "rarity_level": "COMMON"
  },
  "1298": {
    "name": "Arkade 90s Bulls #1298",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1298.png",
    "dna": "15a77c1ede99775875ef6425c5c52a0b2ea376ec",
    "edition": 1298,
    "date": 1672532407532,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1298",
    "rarity_score": 206.016726876616,
    "rarity_level": "COMMON"
  },
  "1640": {
    "name": "Arkade 90s Bulls #1640",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1640.png",
    "dna": "0653555dd48869d28e2ad4af640270a64b62bb70",
    "edition": 1640,
    "date": 1672532408441,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Red Stripes", "score": 995.0 },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1640",
    "rarity_score": 1300.1658101820694,
    "rarity_level": "EPIC"
  },
  "1230": {
    "name": "Arkade 90s Bulls #1230",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1230.png",
    "dna": "8811dfc0c010aaf259126372947111cf439892d4",
    "edition": 1230,
    "date": 1672532409843,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1230",
    "rarity_score": 533.8920716393924,
    "rarity_level": "RARE"
  },
  "42": {
    "name": "Arkade 90s Bulls #42",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/42.png",
    "dna": "0cfca6290e7d8776d595564da5d9cebe22c13de0",
    "edition": 42,
    "date": 1672532410471,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "42",
    "rarity_score": 231.24552993063855,
    "rarity_level": "COMMON"
  },
  "653": {
    "name": "Arkade 90s Bulls #653",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/653.png",
    "dna": "a1df8888f35c8793b6a30e1601045e71514c6893",
    "edition": 653,
    "date": 1672532411147,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "653",
    "rarity_score": 230.34205995519326,
    "rarity_level": "COMMON"
  },
  "1967": {
    "name": "Arkade 90s Bulls #1967",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1967.png",
    "dna": "ee0e6976f0f58a8c2fbf959b1a1588297110d201",
    "edition": 1967,
    "date": 1672532412585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Mask", "value": "Turbobull", "score": 398.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1967",
    "rarity_score": 558.6786180994683,
    "rarity_level": "RARE"
  },
  "855": {
    "name": "Arkade 90s Bulls #855",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/855.png",
    "dna": "461ed374ac39d99250a71cb916703440675ff1d5",
    "edition": 855,
    "date": 1672532413454,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "855",
    "rarity_score": 395.10252630807145,
    "rarity_level": "COMMON"
  },
  "381": {
    "name": "Arkade 90s Bulls #381",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/381.png",
    "dna": "e753058db9efa6d3814525ebb5afe22e95b706fb",
    "edition": 381,
    "date": 1672532414773,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "381",
    "rarity_score": 208.31628056568726,
    "rarity_level": "COMMON"
  },
  "829": {
    "name": "Arkade 90s Bulls #829",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/829.png",
    "dna": "40288c6df1f92627f6fc47498ac85403192cb119",
    "edition": 829,
    "date": 1672532415341,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "829",
    "rarity_score": 362.96001441209916,
    "rarity_level": "COMMON"
  },
  "1161": {
    "name": "Arkade 90s Bulls #1161",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1161.png",
    "dna": "a1d1fd32faf6bf1ff3282f7d96175f0b1ed1d5a7",
    "edition": 1161,
    "date": 1672532415969,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1161",
    "rarity_score": 241.48155300557875,
    "rarity_level": "COMMON"
  },
  "1432": {
    "name": "Arkade 90s Bulls #1432",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1432.png",
    "dna": "fdb99e07e121792c080bb5d88950075a9a22248b",
    "edition": 1432,
    "date": 1672532417359,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1432",
    "rarity_score": 352.45764198204876,
    "rarity_level": "COMMON"
  },
  "505": {
    "name": "Arkade 90s Bulls #505",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/505.png",
    "dna": "5fe97b9cb4b805b43828867ed26634bcbb01140f",
    "edition": 505,
    "date": 1672532418589,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "505",
    "rarity_score": 261.7197367087415,
    "rarity_level": "COMMON"
  },
  "1629": {
    "name": "Arkade 90s Bulls #1629",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1629.png",
    "dna": "f3abd1184cc2f54a672163c0ddc659e8643b0cae",
    "edition": 1629,
    "date": 1672532419230,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1629",
    "rarity_score": 243.6548446991956,
    "rarity_level": "COMMON"
  },
  "1539": {
    "name": "Arkade 90s Bulls #1539",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1539.png",
    "dna": "312f5e50ac9da34f0b8c133a83e976976ff9451f",
    "edition": 1539,
    "date": 1672532419807,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1539",
    "rarity_score": 308.68864968877716,
    "rarity_level": "COMMON"
  },
  "922": {
    "name": "Arkade 90s Bulls #922",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/922.png",
    "dna": "51bbc5e2d861981630c2c1765505f161bf651a8a",
    "edition": 922,
    "date": 1672532420630,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "922",
    "rarity_score": 330.42264598882656,
    "rarity_level": "COMMON"
  },
  "447": {
    "name": "Arkade 90s Bulls #447",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/447.png",
    "dna": "e046e85dcb10032ea026e2e8ff929c4f0104505f",
    "edition": 447,
    "date": 1672532421302,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "447",
    "rarity_score": 146.18965110938913,
    "rarity_level": "COMMON"
  },
  "1061": {
    "name": "Arkade 90s Bulls #1061",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1061.png",
    "dna": "c380c3d47f68c8ddb9eaad42f366fac0a828eaeb",
    "edition": 1061,
    "date": 1672532421993,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1061",
    "rarity_score": 187.65962363935085,
    "rarity_level": "COMMON"
  },
  "641": {
    "name": "Arkade 90s Bulls #641",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/641.png",
    "dna": "e9976345854b79f087332e42385543b9c8ca55c6",
    "edition": 641,
    "date": 1672532422922,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "641",
    "rarity_score": 233.1811656733033,
    "rarity_level": "COMMON"
  },
  "397": {
    "name": "Arkade 90s Bulls #397",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/397.png",
    "dna": "eca914f47cdb9efa40bb7e898c06812f85555316",
    "edition": 397,
    "date": 1672532423609,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "397",
    "rarity_score": 229.51343571970955,
    "rarity_level": "COMMON"
  },
  "1496": {
    "name": "Arkade 90s Bulls #1496",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1496.png",
    "dna": "9ce80e012ef54f4e138ba917c6467cb2d493b1d7",
    "edition": 1496,
    "date": 1672532424277,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1496",
    "rarity_score": 218.94685948802405,
    "rarity_level": "COMMON"
  },
  "1732": {
    "name": "Arkade 90s Bulls #1732",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1732.png",
    "dna": "ec84eb48a5de16dcff122ae0254d745bbb27b409",
    "edition": 1732,
    "date": 1672532424959,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1732",
    "rarity_score": 212.00453819289714,
    "rarity_level": "COMMON"
  },
  "733": {
    "name": "Arkade 90s Bulls #733",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/733.png",
    "dna": "f9b2b73b7cee1a8fd73d5296920dda5a3573d2a7",
    "edition": 733,
    "date": 1672532425578,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Mask", "value": "Turbobull", "score": 398.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "733",
    "rarity_score": 697.1116868808907,
    "rarity_level": "RARE"
  },
  "1744": {
    "name": "Arkade 90s Bulls #1744",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1744.png",
    "dna": "860b5c331899f3b3800e6e5168e107392e24cc84",
    "edition": 1744,
    "date": 1672532426240,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1744",
    "rarity_score": 613.69769559999,
    "rarity_level": "RARE"
  },
  "1845": {
    "name": "Arkade 90s Bulls #1845",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1845.png",
    "dna": "2f5070b664e04ecc805ba1cd1060913d89dde3ef",
    "edition": 1845,
    "date": 1672532427259,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1845",
    "rarity_score": 227.03399033030976,
    "rarity_level": "COMMON"
  },
  "329": {
    "name": "Arkade 90s Bulls #329",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/329.png",
    "dna": "a64969676a1cb310db6b298768730083273a00a0",
    "edition": 329,
    "date": 1672532427953,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "329",
    "rarity_score": 234.574395063305,
    "rarity_level": "COMMON"
  },
  "714": {
    "name": "Arkade 90s Bulls #714",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/714.png",
    "dna": "a709e730c107e5610c674df7857525aad503f0f6",
    "edition": 714,
    "date": 1672532428891,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "Gold Chain",
        "score": 497.5
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "714",
    "rarity_score": 783.0174397254141,
    "rarity_level": "RARE"
  },
  "1590": {
    "name": "Arkade 90s Bulls #1590",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1590.png",
    "dna": "f00e03332ba6c0084ea77ff36614f0d951d24640",
    "edition": 1590,
    "date": 1672532429952,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1590",
    "rarity_score": 484.0649625965478,
    "rarity_level": "RARE"
  },
  "1892": {
    "name": "Arkade 90s Bulls #1892",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1892.png",
    "dna": "974551205ef6fe75ea38d1a3045c755a643c0eaa",
    "edition": 1892,
    "date": 1672532430596,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1892",
    "rarity_score": 139.50415156578828,
    "rarity_level": "COMMON"
  },
  "167": {
    "name": "Arkade 90s Bulls #167",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/167.png",
    "dna": "53b884da20d0e71ab7521766985295bf92582b70",
    "edition": 167,
    "date": 1672532431253,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "167",
    "rarity_score": 322.74653732030004,
    "rarity_level": "COMMON"
  },
  "213": {
    "name": "Arkade 90s Bulls #213",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/213.png",
    "dna": "82451e33eb3595f526285f0c0654cc44fb292090",
    "edition": 213,
    "date": 1672532431955,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "213",
    "rarity_score": 163.8882927481098,
    "rarity_level": "COMMON"
  },
  "109": {
    "name": "Arkade 90s Bulls #109",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/109.png",
    "dna": "0e67154368604b63daab3d750126b97a2a572958",
    "edition": 109,
    "date": 1672532432614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "109",
    "rarity_score": 152.9555816567412,
    "rarity_level": "COMMON"
  },
  "951": {
    "name": "Arkade 90s Bulls #951",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/951.png",
    "dna": "6cde191c011c261df4fb03ab66fae938eded9ba3",
    "edition": 951,
    "date": 1672532433127,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "951",
    "rarity_score": 428.9659850990546,
    "rarity_level": "COMMON"
  },
  "1635": {
    "name": "Arkade 90s Bulls #1635",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1635.png",
    "dna": "14fd9fdc4a34dd20f041e46c0aaa981ff0b66fd0",
    "edition": 1635,
    "date": 1672532433946,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1635",
    "rarity_score": 236.4593544014332,
    "rarity_level": "COMMON"
  },
  "183": {
    "name": "Arkade 90s Bulls #183",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/183.png",
    "dna": "5e60f939a8a6e8e2106e8a99f8ab911a47507db7",
    "edition": 183,
    "date": 1672532434567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "183",
    "rarity_score": 247.38946444062395,
    "rarity_level": "COMMON"
  },
  "1908": {
    "name": "Arkade 90s Bulls #1908",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1908.png",
    "dna": "ef939bcc5c876078449fd44005f573e64a020537",
    "edition": 1908,
    "date": 1672532435180,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1908",
    "rarity_score": 186.26747216925634,
    "rarity_level": "COMMON"
  },
  "1080": {
    "name": "Arkade 90s Bulls #1080",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1080.png",
    "dna": "518a33da56393ebb28326fdbdb219f4489d4e52c",
    "edition": 1080,
    "date": 1672532435826,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1080",
    "rarity_score": 143.29483078630074,
    "rarity_level": "COMMON"
  },
  "1896": {
    "name": "Arkade 90s Bulls #1896",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1896.png",
    "dna": "135222d59df5a7d21dedd853fdd3f6f189e98ea7",
    "edition": 1896,
    "date": 1672532437350,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1896",
    "rarity_score": 173.4951594794691,
    "rarity_level": "COMMON"
  },
  "1777": {
    "name": "Arkade 90s Bulls #1777",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1777.png",
    "dna": "04cd032a6cb807c2ff431f1fa0955b1d7a8a119d",
    "edition": 1777,
    "date": 1672532437881,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1777",
    "rarity_score": 435.4480807339225,
    "rarity_level": "COMMON"
  },
  "1650": {
    "name": "Arkade 90s Bulls #1650",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1650.png",
    "dna": "7e0f756502b19655fbb79c2a965634627b492003",
    "edition": 1650,
    "date": 1672532438695,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1650",
    "rarity_score": 126.67202626958446,
    "rarity_level": "COMMON"
  },
  "1365": {
    "name": "Arkade 90s Bulls #1365",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1365.png",
    "dna": "fac01cec490c6cd9d1361c6110e2cffdcfe457f8",
    "edition": 1365,
    "date": 1672532439445,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1365",
    "rarity_score": 301.6703126773546,
    "rarity_level": "COMMON"
  },
  "415": {
    "name": "Arkade 90s Bulls #415",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/415.png",
    "dna": "4ca88ffe84de9a4492e0f3083938879509856f1d",
    "edition": 415,
    "date": 1672532440099,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "415",
    "rarity_score": 221.97977549247128,
    "rarity_level": "COMMON"
  },
  "1634": {
    "name": "Arkade 90s Bulls #1634",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1634.png",
    "dna": "4f79248f58a573535c25cef70299a393ce32c29a",
    "edition": 1634,
    "date": 1672532440740,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1634",
    "rarity_score": 377.01190727554865,
    "rarity_level": "COMMON"
  },
  "1059": {
    "name": "Arkade 90s Bulls #1059",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1059.png",
    "dna": "36acbfa410daba3d9335020ebd03f44ffe2d24ee",
    "edition": 1059,
    "date": 1672532441381,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1059",
    "rarity_score": 305.8309756132969,
    "rarity_level": "COMMON"
  },
  "436": {
    "name": "Arkade 90s Bulls #436",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/436.png",
    "dna": "1c9e3bfe7e5486978d04d61d4712198211d069e1",
    "edition": 436,
    "date": 1672532442252,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "436",
    "rarity_score": 486.01859636584464,
    "rarity_level": "RARE"
  },
  "1439": {
    "name": "Arkade 90s Bulls #1439",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1439.png",
    "dna": "d02d83559bd4b3f1b248a058e6ea1b9e456cbd57",
    "edition": 1439,
    "date": 1672532443680,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1439",
    "rarity_score": 288.909139842634,
    "rarity_level": "COMMON"
  },
  "1802": {
    "name": "Arkade 90s Bulls #1802",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1802.png",
    "dna": "a2c10aa1e4b2745ee3a69c2ec1b5f17dc8c2cb13",
    "edition": 1802,
    "date": 1672532444351,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1802",
    "rarity_score": 212.47409436398635,
    "rarity_level": "COMMON"
  },
  "132": {
    "name": "Arkade 90s Bulls #132",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/132.png",
    "dna": "96d816304aeb88b23a81c8dbd743a5f58fbe05b0",
    "edition": 132,
    "date": 1672532444985,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "132",
    "rarity_score": 330.4628491902438,
    "rarity_level": "COMMON"
  },
  "780": {
    "name": "Arkade 90s Bulls #780",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/780.png",
    "dna": "1495855283070d66e1547e9388370aace30f1bd3",
    "edition": 780,
    "date": 1672532446320,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "780",
    "rarity_score": 400.24682677685456,
    "rarity_level": "COMMON"
  },
  "761": {
    "name": "Arkade 90s Bulls #761",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/761.png",
    "dna": "67625fb26c33ddacf2f4a13e1e758399e7ca4935",
    "edition": 761,
    "date": 1672532446964,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "761",
    "rarity_score": 587.4974496726089,
    "rarity_level": "RARE"
  },
  "1782": {
    "name": "Arkade 90s Bulls #1782",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1782.png",
    "dna": "3c999bf853aa4ab94bf6bfa84208a43429751b91",
    "edition": 1782,
    "date": 1672532447727,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1782",
    "rarity_score": 225.53146870473014,
    "rarity_level": "COMMON"
  },
  "1525": {
    "name": "Arkade 90s Bulls #1525",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1525.png",
    "dna": "06648beb03a86e87308ef96f82378032d123562e",
    "edition": 1525,
    "date": 1672532448420,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1525",
    "rarity_score": 215.74859923244034,
    "rarity_level": "COMMON"
  },
  "1579": {
    "name": "Arkade 90s Bulls #1579",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1579.png",
    "dna": "eb8c7ce501017d2e59629c090f9d89c8cff45d75",
    "edition": 1579,
    "date": 1672532449109,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1579",
    "rarity_score": 234.46303581525234,
    "rarity_level": "COMMON"
  },
  "898": {
    "name": "Arkade 90s Bulls #898",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/898.png",
    "dna": "1c22ce3d7e7fcd657756eb22986cb4d39d864949",
    "edition": 898,
    "date": 1672532450468,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "898",
    "rarity_score": 420.0426073316941,
    "rarity_level": "COMMON"
  },
  "1532": {
    "name": "Arkade 90s Bulls #1532",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1532.png",
    "dna": "3cef878a7ae00fe44c1aabd4bac8cae005c3cf8f",
    "edition": 1532,
    "date": 1672532451819,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1532",
    "rarity_score": 231.71489602329387,
    "rarity_level": "COMMON"
  },
  "315": {
    "name": "Arkade 90s Bulls #315",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/315.png",
    "dna": "825bd94c1edb0a2ccebe87a31fa6264f552a710e",
    "edition": 315,
    "date": 1672532452640,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "315",
    "rarity_score": 313.3219052072747,
    "rarity_level": "COMMON"
  },
  "1236": {
    "name": "Arkade 90s Bulls #1236",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1236.png",
    "dna": "fac1aeeb1a027525792879218323f32e8ce8aded",
    "edition": 1236,
    "date": 1672532453435,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1236",
    "rarity_score": 387.148167695912,
    "rarity_level": "COMMON"
  },
  "708": {
    "name": "Arkade 90s Bulls #708",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/708.png",
    "dna": "53ebd9a08815792b93065132df966cd4027e4937",
    "edition": 708,
    "date": 1672532454741,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "708",
    "rarity_score": 343.24573147690217,
    "rarity_level": "COMMON"
  },
  "1284": {
    "name": "Arkade 90s Bulls #1284",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1284.png",
    "dna": "dcd195aa6f660c6008c30e88d15a64d9ea807a98",
    "edition": 1284,
    "date": 1672532456075,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1284",
    "rarity_score": 267.50867571463004,
    "rarity_level": "COMMON"
  },
  "126": {
    "name": "Arkade 90s Bulls #126",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/126.png",
    "dna": "e9b0e30d2ba71f0c89973ab5c2e9712f8a8ab538",
    "edition": 126,
    "date": 1672532456748,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pickels Overalls",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "126",
    "rarity_score": 744.9712905357339,
    "rarity_level": "RARE"
  },
  "346": {
    "name": "Arkade 90s Bulls #346",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/346.png",
    "dna": "9fe3fc15df172ad35043fd7160d97a7b81ef9f24",
    "edition": 346,
    "date": 1672532458100,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "346",
    "rarity_score": 261.8943753817023,
    "rarity_level": "COMMON"
  },
  "464": {
    "name": "Arkade 90s Bulls #464",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/464.png",
    "dna": "44aca883dee8145c0e1c1d24b09c85b291d9dae2",
    "edition": 464,
    "date": 1672532458787,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "464",
    "rarity_score": 417.0876501449617,
    "rarity_level": "COMMON"
  },
  "1657": {
    "name": "Arkade 90s Bulls #1657",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1657.png",
    "dna": "db36bdb1c4f1fa39ff4c404d4ed21f249c76fa72",
    "edition": 1657,
    "date": 1672532459495,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1657",
    "rarity_score": 199.748526718546,
    "rarity_level": "COMMON"
  },
  "918": {
    "name": "Arkade 90s Bulls #918",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/918.png",
    "dna": "a206d52ba142462cf3d229324b45bd2852d9b5eb",
    "edition": 918,
    "date": 1672532460145,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "918",
    "rarity_score": 387.270522550984,
    "rarity_level": "COMMON"
  },
  "1444": {
    "name": "Arkade 90s Bulls #1444",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1444.png",
    "dna": "54f5efb9c3a89a7fcdf492a879462fcca01602ba",
    "edition": 1444,
    "date": 1672532461758,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1444",
    "rarity_score": 164.2744424570078,
    "rarity_level": "COMMON"
  },
  "107": {
    "name": "Arkade 90s Bulls #107",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/107.png",
    "dna": "296f1c515d80bd6b0e261cc3f219e80795ceeba3",
    "edition": 107,
    "date": 1672532462482,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "107",
    "rarity_score": 321.5810980862048,
    "rarity_level": "COMMON"
  },
  "411": {
    "name": "Arkade 90s Bulls #411",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/411.png",
    "dna": "554d65ed79f09cc8aec96abd65ccbb077ae25c12",
    "edition": 411,
    "date": 1672532463166,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "411",
    "rarity_score": 324.32090918035817,
    "rarity_level": "COMMON"
  },
  "1914": {
    "name": "Arkade 90s Bulls #1914",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1914.png",
    "dna": "ccbd6df189aecec7a7047b6b9171cabd21b49614",
    "edition": 1914,
    "date": 1672532464008,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1914",
    "rarity_score": 279.5919611927071,
    "rarity_level": "COMMON"
  },
  "949": {
    "name": "Arkade 90s Bulls #949",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/949.png",
    "dna": "2d94c77a59c1132492c8f4e991043d1709d109bd",
    "edition": 949,
    "date": 1672532464661,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "949",
    "rarity_score": 265.7351164194611,
    "rarity_level": "COMMON"
  },
  "1416": {
    "name": "Arkade 90s Bulls #1416",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1416.png",
    "dna": "e8454114c93ac398884045c01c3e8bd8e890f4d0",
    "edition": 1416,
    "date": 1672532465313,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1416",
    "rarity_score": 218.0889913580187,
    "rarity_level": "COMMON"
  },
  "1526": {
    "name": "Arkade 90s Bulls #1526",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1526.png",
    "dna": "e9c1526cfc4992dfa528420714eb245f950ee95a",
    "edition": 1526,
    "date": 1672532466720,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1526",
    "rarity_score": 199.7757353142864,
    "rarity_level": "COMMON"
  },
  "839": {
    "name": "Arkade 90s Bulls #839",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/839.png",
    "dna": "3d27bff9af12662fea86b8e3dba61dd3cacc08cf",
    "edition": 839,
    "date": 1672532467340,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "839",
    "rarity_score": 557.8999682814812,
    "rarity_level": "RARE"
  },
  "1477": {
    "name": "Arkade 90s Bulls #1477",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1477.png",
    "dna": "6928b1533faadde1437a627213e8ca2592f580b3",
    "edition": 1477,
    "date": 1672532467960,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1477",
    "rarity_score": 498.9967843178712,
    "rarity_level": "RARE"
  },
  "849": {
    "name": "Arkade 90s Bulls #849",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/849.png",
    "dna": "c6854d221a57f4adcd73cc129b39d3ad338fa7de",
    "edition": 849,
    "date": 1672532469408,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "849",
    "rarity_score": 281.40090594234437,
    "rarity_level": "COMMON"
  },
  "241": {
    "name": "Arkade 90s Bulls #241",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/241.png",
    "dna": "b831a382a9e895c7462226c76bfcac36069d05c9",
    "edition": 241,
    "date": 1672532470217,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "241",
    "rarity_score": 323.89748337445366,
    "rarity_level": "COMMON"
  },
  "1561": {
    "name": "Arkade 90s Bulls #1561",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1561.png",
    "dna": "887a39c991882f59e0c746d1e51058f88abdec9d",
    "edition": 1561,
    "date": 1672532470853,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1561",
    "rarity_score": 152.64951611997716,
    "rarity_level": "COMMON"
  },
  "1754": {
    "name": "Arkade 90s Bulls #1754",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1754.png",
    "dna": "2a4ce0cbf52bcf89d8dc177bb57584df1a7dbf6a",
    "edition": 1754,
    "date": 1672532472365,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1754",
    "rarity_score": 336.4579567847657,
    "rarity_level": "COMMON"
  },
  "438": {
    "name": "Arkade 90s Bulls #438",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/438.png",
    "dna": "dd4db2d320d2268199bc11d93d21c523ef5c969a",
    "edition": 438,
    "date": 1672532473081,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "438",
    "rarity_score": 246.74410701126203,
    "rarity_level": "COMMON"
  },
  "1987": {
    "name": "Arkade 90s Bulls #1987",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1987.png",
    "dna": "053da141914a4f7f689452a8d33f2dc94eca007d",
    "edition": 1987,
    "date": 1672532473733,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1987",
    "rarity_score": 371.10769916277775,
    "rarity_level": "COMMON"
  },
  "396": {
    "name": "Arkade 90s Bulls #396",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/396.png",
    "dna": "1a5bf673aeacaf4cfcfd19d7b2de143b7d06ea53",
    "edition": 396,
    "date": 1672532474416,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "396",
    "rarity_score": 558.9274410676418,
    "rarity_level": "RARE"
  },
  "1816": {
    "name": "Arkade 90s Bulls #1816",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1816.png",
    "dna": "88c0769ac58b3a26caa11f82c640b137abe78a3f",
    "edition": 1816,
    "date": 1672532475051,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1816",
    "rarity_score": 232.40965659474136,
    "rarity_level": "COMMON"
  },
  "1231": {
    "name": "Arkade 90s Bulls #1231",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1231.png",
    "dna": "da28a6190bdec76200150892a74f16eec1f79263",
    "edition": 1231,
    "date": 1672532476592,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1231",
    "rarity_score": 183.51071287913416,
    "rarity_level": "COMMON"
  },
  "212": {
    "name": "Arkade 90s Bulls #212",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/212.png",
    "dna": "36a19dc6c061899bafbc867f422bc667345a7dc8",
    "edition": 212,
    "date": 1672532477362,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "212",
    "rarity_score": 319.3884414100514,
    "rarity_level": "COMMON"
  },
  "82": {
    "name": "Arkade 90s Bulls #82",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/82.png",
    "dna": "9dca2a900f810619ad265fbf57cafac40d725d9e",
    "edition": 82,
    "date": 1672532478005,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "82",
    "rarity_score": 428.1849793766911,
    "rarity_level": "COMMON"
  },
  "1952": {
    "name": "Arkade 90s Bulls #1952",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1952.png",
    "dna": "819e2a62f068a0f6cd9d8ce7d121ad4453a3c571",
    "edition": 1952,
    "date": 1672532478670,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1952",
    "rarity_score": 419.31962676928805,
    "rarity_level": "COMMON"
  },
  "60": {
    "name": "Arkade 90s Bulls #60",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/60.png",
    "dna": "b91029e84ecb14d9fcb376af455cb04193c8cbd2",
    "edition": 60,
    "date": 1672532479370,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "60",
    "rarity_score": 338.43916272734657,
    "rarity_level": "COMMON"
  },
  "1527": {
    "name": "Arkade 90s Bulls #1527",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1527.png",
    "dna": "98a1ba570072f9ed101257f91d95b138c4d9bd73",
    "edition": 1527,
    "date": 1672532480022,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1527",
    "rarity_score": 334.5360410381242,
    "rarity_level": "COMMON"
  },
  "432": {
    "name": "Arkade 90s Bulls #432",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/432.png",
    "dna": "dde0fed217ff256299f983787a6838c67f582d85",
    "edition": 432,
    "date": 1672532480646,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "432",
    "rarity_score": 358.95335613822857,
    "rarity_level": "COMMON"
  },
  "123": {
    "name": "Arkade 90s Bulls #123",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/123.png",
    "dna": "d1b4ae9cd4d6c49a5efbc1dcddae6e541c92b71a",
    "edition": 123,
    "date": 1672532481327,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "123",
    "rarity_score": 288.7603536620115,
    "rarity_level": "COMMON"
  },
  "1722": {
    "name": "Arkade 90s Bulls #1722",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1722.png",
    "dna": "28b789875bf46cf01717dc2db523a864ef7395f8",
    "edition": 1722,
    "date": 1672532481981,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1722",
    "rarity_score": 388.0822756397497,
    "rarity_level": "COMMON"
  },
  "1168": {
    "name": "Arkade 90s Bulls #1168",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1168.png",
    "dna": "601a10409f3719a4886bbb8742b0dc656265bcbd",
    "edition": 1168,
    "date": 1672532482680,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1168",
    "rarity_score": 375.72481805197333,
    "rarity_level": "COMMON"
  },
  "826": {
    "name": "Arkade 90s Bulls #826",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/826.png",
    "dna": "461cdb6bfc34bfc1364b637b53fc49c6c19d47fe",
    "edition": 826,
    "date": 1672532483461,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "826",
    "rarity_score": 296.3332223562789,
    "rarity_level": "COMMON"
  },
  "934": {
    "name": "Arkade 90s Bulls #934",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/934.png",
    "dna": "a68d357e87dc79a5dc5f633a0addf7886d1eb30d",
    "edition": 934,
    "date": 1672532484107,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "934",
    "rarity_score": 243.29707469141985,
    "rarity_level": "COMMON"
  },
  "721": {
    "name": "Arkade 90s Bulls #721",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/721.png",
    "dna": "bbc8fe43c6eba2ec1b07efabc503f706fa1490c7",
    "edition": 721,
    "date": 1672532484726,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "721",
    "rarity_score": 244.8164713446948,
    "rarity_level": "COMMON"
  },
  "1932": {
    "name": "Arkade 90s Bulls #1932",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1932.png",
    "dna": "ef451960932c0cf18a54fd0ab62667a83da71508",
    "edition": 1932,
    "date": 1672532485382,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1932",
    "rarity_score": 610.8944534608312,
    "rarity_level": "RARE"
  },
  "220": {
    "name": "Arkade 90s Bulls #220",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/220.png",
    "dna": "6117068e5177c8c21d12d6131ae967f08859e919",
    "edition": 220,
    "date": 1672532486766,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "220",
    "rarity_score": 216.75582289403545,
    "rarity_level": "COMMON"
  },
  "1597": {
    "name": "Arkade 90s Bulls #1597",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1597.png",
    "dna": "01e8c540203581fabacb4912d680f9269b8b8b7a",
    "edition": 1597,
    "date": 1672532487462,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1597",
    "rarity_score": 246.50046439712463,
    "rarity_level": "COMMON"
  },
  "1600": {
    "name": "Arkade 90s Bulls #1600",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1600.png",
    "dna": "fafa5884625c2bb4ef1985320f7103bc4f3a083e",
    "edition": 1600,
    "date": 1672532488133,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1600",
    "rarity_score": 412.3010547848721,
    "rarity_level": "COMMON"
  },
  "1326": {
    "name": "Arkade 90s Bulls #1326",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1326.png",
    "dna": "efb27de57655c220a200ef93de9291ddc52f9302",
    "edition": 1326,
    "date": 1672532489139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1326",
    "rarity_score": 364.5623544071928,
    "rarity_level": "COMMON"
  },
  "1637": {
    "name": "Arkade 90s Bulls #1637",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1637.png",
    "dna": "a2860ece1089b56a9147e8f30632f358c40dda55",
    "edition": 1637,
    "date": 1672532490192,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1637",
    "rarity_score": 194.22793171560883,
    "rarity_level": "COMMON"
  },
  "526": {
    "name": "Arkade 90s Bulls #526",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/526.png",
    "dna": "be5e57dcc192fc802bc542e0fbf84b6091537cbc",
    "edition": 526,
    "date": 1672532490811,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      { "trait_type": "Head", "value": "Yuki Muko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "526",
    "rarity_score": 534.4525028351188,
    "rarity_level": "RARE"
  },
  "1128": {
    "name": "Arkade 90s Bulls #1128",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1128.png",
    "dna": "711e4520224476a1b844dc21e15695ccedeeafb7",
    "edition": 1128,
    "date": 1672532491499,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1128",
    "rarity_score": 171.96648993450444,
    "rarity_level": "COMMON"
  },
  "1103": {
    "name": "Arkade 90s Bulls #1103",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1103.png",
    "dna": "3bb29e82e520a594bdf9ad6aa63d74be1e0ff3c0",
    "edition": 1103,
    "date": 1672532492218,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1103",
    "rarity_score": 289.5310653962513,
    "rarity_level": "COMMON"
  },
  "448": {
    "name": "Arkade 90s Bulls #448",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/448.png",
    "dna": "a2b0f24ff0ca5354578e6c17afeef1ae3826d1e2",
    "edition": 448,
    "date": 1672532492888,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "448",
    "rarity_score": 266.4839423239122,
    "rarity_level": "COMMON"
  },
  "1093": {
    "name": "Arkade 90s Bulls #1093",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1093.png",
    "dna": "6f84d9cec001146b8a6cacea1030b75c764fabc0",
    "edition": 1093,
    "date": 1672532493577,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1093",
    "rarity_score": 170.3578290602342,
    "rarity_level": "COMMON"
  },
  "592": {
    "name": "Arkade 90s Bulls #592",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/592.png",
    "dna": "3cc21062223ca3b7709ee00346738e5f29092bba",
    "edition": 592,
    "date": 1672532494229,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "592",
    "rarity_score": 138.28555536316588,
    "rarity_level": "COMMON"
  },
  "789": {
    "name": "Arkade 90s Bulls #789",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/789.png",
    "dna": "1997767652eb7485039b9eea45d8df98fe23ef72",
    "edition": 789,
    "date": 1672532494831,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "789",
    "rarity_score": 268.0257195708806,
    "rarity_level": "COMMON"
  },
  "1378": {
    "name": "Arkade 90s Bulls #1378",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1378.png",
    "dna": "7a655e2731f074d28d81081f8fe989bd4a688b00",
    "edition": 1378,
    "date": 1672532495705,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1378",
    "rarity_score": 359.3770648609322,
    "rarity_level": "COMMON"
  },
  "1630": {
    "name": "Arkade 90s Bulls #1630",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1630.png",
    "dna": "90234c9bc3e406c17d6f24bc0cf3337e761f495d",
    "edition": 1630,
    "date": 1672532496354,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1630",
    "rarity_score": 300.02727063822596,
    "rarity_level": "COMMON"
  },
  "230": {
    "name": "Arkade 90s Bulls #230",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/230.png",
    "dna": "58ec731d53006f945f1b3dfa65ce82ce5e1a5415",
    "edition": 230,
    "date": 1672532498020,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "230",
    "rarity_score": 227.839072560323,
    "rarity_level": "COMMON"
  },
  "1712": {
    "name": "Arkade 90s Bulls #1712",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1712.png",
    "dna": "1e7d42282acfbe4f5b0d540cc3c8ce5c173aae49",
    "edition": 1712,
    "date": 1672532499378,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1712",
    "rarity_score": 352.8995617412364,
    "rarity_level": "COMMON"
  },
  "1795": {
    "name": "Arkade 90s Bulls #1795",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1795.png",
    "dna": "3d3d06e563f887fc208457c9bffbf5fc010ea6ba",
    "edition": 1795,
    "date": 1672532500174,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Smile",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1795",
    "rarity_score": 190.89889355855226,
    "rarity_level": "COMMON"
  },
  "386": {
    "name": "Arkade 90s Bulls #386",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/386.png",
    "dna": "fae54dcbae70d11b902464fc55eba3f934615b36",
    "edition": 386,
    "date": 1672532500788,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "386",
    "rarity_score": 235.0298230617199,
    "rarity_level": "COMMON"
  },
  "612": {
    "name": "Arkade 90s Bulls #612",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/612.png",
    "dna": "cd84b9b368f6ae295a00628a69c65aaeb677badf",
    "edition": 612,
    "date": 1672532501565,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "612",
    "rarity_score": 175.1836142113141,
    "rarity_level": "COMMON"
  },
  "311": {
    "name": "Arkade 90s Bulls #311",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/311.png",
    "dna": "6ec3872986edb2b5b28f896b0c03e38999d0d303",
    "edition": 311,
    "date": 1672532502255,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Tongue Out",
        "score": 199.0
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "311",
    "rarity_score": 424.7731648027068,
    "rarity_level": "COMMON"
  },
  "657": {
    "name": "Arkade 90s Bulls #657",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/657.png",
    "dna": "46929a1c20901ae454f2d5e01ee69f7304f7ddbf",
    "edition": 657,
    "date": 1672532502920,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "657",
    "rarity_score": 314.6122108659632,
    "rarity_level": "COMMON"
  },
  "1212": {
    "name": "Arkade 90s Bulls #1212",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1212.png",
    "dna": "570d85b597275608e4c247c4d3d232e0d196258b",
    "edition": 1212,
    "date": 1672532503648,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gold Happy",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1212",
    "rarity_score": 255.39704819109375,
    "rarity_level": "COMMON"
  },
  "1256": {
    "name": "Arkade 90s Bulls #1256",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1256.png",
    "dna": "9bb7825e539a3ac6f05e2cdbbf3f4a32723e2bd4",
    "edition": 1256,
    "date": 1672532505082,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      { "trait_type": "Base", "value": "Gold Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1256",
    "rarity_score": 231.75532338963302,
    "rarity_level": "COMMON"
  },
  "1176": {
    "name": "Arkade 90s Bulls #1176",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1176.png",
    "dna": "982b6163bc77dc669048a55c08546e4e3bc8f2f1",
    "edition": 1176,
    "date": 1672532505760,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1176",
    "rarity_score": 300.5515874718858,
    "rarity_level": "COMMON"
  },
  "560": {
    "name": "Arkade 90s Bulls #560",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/560.png",
    "dna": "1656679d42050355f9a95c356ddc399c95335d4a",
    "edition": 560,
    "date": 1672532506446,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "560",
    "rarity_score": 144.2502192474372,
    "rarity_level": "COMMON"
  },
  "786": {
    "name": "Arkade 90s Bulls #786",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/786.png",
    "dna": "9e3e4d81c7da3821bb0c1494cd14149e831d5fd8",
    "edition": 786,
    "date": 1672532507816,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "786",
    "rarity_score": 321.87113963959405,
    "rarity_level": "COMMON"
  },
  "1100": {
    "name": "Arkade 90s Bulls #1100",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1100.png",
    "dna": "4759b950dc0bdb1846e9ac2f8e483e558055a54b",
    "edition": 1100,
    "date": 1672532508677,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1100",
    "rarity_score": 234.80548233492186,
    "rarity_level": "COMMON"
  },
  "1184": {
    "name": "Arkade 90s Bulls #1184",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1184.png",
    "dna": "a4561aa47b3c493cf29535d86ea742ff38679bdd",
    "edition": 1184,
    "date": 1672532509883,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1184",
    "rarity_score": 456.5216806533826,
    "rarity_level": "COMMON"
  },
  "1564": {
    "name": "Arkade 90s Bulls #1564",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1564.png",
    "dna": "929ee2602b03296a0f5c7ab58482c363cf6bc7cd",
    "edition": 1564,
    "date": 1672532510530,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1564",
    "rarity_score": 359.8951286627231,
    "rarity_level": "COMMON"
  },
  "1679": {
    "name": "Arkade 90s Bulls #1679",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1679.png",
    "dna": "75cec6cd3ea5558e5827302e159ee76df19de5ef",
    "edition": 1679,
    "date": 1672532511347,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1679",
    "rarity_score": 305.86877088381993,
    "rarity_level": "COMMON"
  },
  "1194": {
    "name": "Arkade 90s Bulls #1194",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1194.png",
    "dna": "08e2babdca46df172b87e31a78ba2e0b4c8cf51b",
    "edition": 1194,
    "date": 1672532512745,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1194",
    "rarity_score": 190.92408124776662,
    "rarity_level": "COMMON"
  },
  "147": {
    "name": "Arkade 90s Bulls #147",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/147.png",
    "dna": "cffea73166cbd9e25125303aa7b7617893de8c77",
    "edition": 147,
    "date": 1672532513406,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "147",
    "rarity_score": 210.43415106854508,
    "rarity_level": "COMMON"
  },
  "833": {
    "name": "Arkade 90s Bulls #833",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/833.png",
    "dna": "750ba0c6c0fd82383710d18cfc440dba46acc960",
    "edition": 833,
    "date": 1672532514042,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "833",
    "rarity_score": 236.1031335886376,
    "rarity_level": "COMMON"
  },
  "1721": {
    "name": "Arkade 90s Bulls #1721",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1721.png",
    "dna": "74a278dbddaa0a1c70b34c23444f453b58d1be49",
    "edition": 1721,
    "date": 1672532514681,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1721",
    "rarity_score": 266.27789292241044,
    "rarity_level": "COMMON"
  },
  "1511": {
    "name": "Arkade 90s Bulls #1511",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1511.png",
    "dna": "622244b763b82eb3fdf28bd9a5bc7e89eba20125",
    "edition": 1511,
    "date": 1672532515948,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1511",
    "rarity_score": 559.8540534910162,
    "rarity_level": "RARE"
  },
  "1002": {
    "name": "Arkade 90s Bulls #1002",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1002.png",
    "dna": "806f73b01cc8e8bd9eb12f096c03b291ffc4b23d",
    "edition": 1002,
    "date": 1672532516638,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1002",
    "rarity_score": 369.2264511626964,
    "rarity_level": "COMMON"
  },
  "1574": {
    "name": "Arkade 90s Bulls #1574",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1574.png",
    "dna": "f4d82801264e87278d4a4f66d71bc08a14bbfa19",
    "edition": 1574,
    "date": 1672532517590,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1574",
    "rarity_score": 612.5841463470396,
    "rarity_level": "RARE"
  },
  "357": {
    "name": "Arkade 90s Bulls #357",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/357.png",
    "dna": "216b93c857e67e275e0f6b0887ea48810d7c3192",
    "edition": 357,
    "date": 1672532518277,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "357",
    "rarity_score": 199.36320388235765,
    "rarity_level": "COMMON"
  },
  "1583": {
    "name": "Arkade 90s Bulls #1583",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1583.png",
    "dna": "84aa63f084ac30e7ee57b949c9c068f091af8814",
    "edition": 1583,
    "date": 1672532518947,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1583",
    "rarity_score": 378.181127917106,
    "rarity_level": "COMMON"
  },
  "269": {
    "name": "Arkade 90s Bulls #269",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/269.png",
    "dna": "bd456e9440ba3bd0203e7d478ba0abbd4bec5d59",
    "edition": 269,
    "date": 1672532519601,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "269",
    "rarity_score": 290.5420971438579,
    "rarity_level": "COMMON"
  },
  "1115": {
    "name": "Arkade 90s Bulls #1115",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1115.png",
    "dna": "b0f669f6559756ee686cf3c317504cefe030ca14",
    "edition": 1115,
    "date": 1672532520265,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1115",
    "rarity_score": 362.16966742992906,
    "rarity_level": "COMMON"
  },
  "94": {
    "name": "Arkade 90s Bulls #94",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/94.png",
    "dna": "6b5778b0943e17c77f443d8ca4d49b41c2119da0",
    "edition": 94,
    "date": 1672532520888,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "94",
    "rarity_score": 415.90286243431143,
    "rarity_level": "COMMON"
  },
  "1988": {
    "name": "Arkade 90s Bulls #1988",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1988.png",
    "dna": "eadd0d20bf5bcf08dea1ce41d634c75b8078efb5",
    "edition": 1988,
    "date": 1672532521545,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1988",
    "rarity_score": 303.8535470711957,
    "rarity_level": "COMMON"
  },
  "872": {
    "name": "Arkade 90s Bulls #872",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/872.png",
    "dna": "18a596698fb0ab7e392d9197ace464925153e1e0",
    "edition": 872,
    "date": 1672532522239,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "872",
    "rarity_score": 271.8839749100243,
    "rarity_level": "COMMON"
  },
  "237": {
    "name": "Arkade 90s Bulls #237",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/237.png",
    "dna": "64df723b8c7ab12c455f7d2b93eb73e892e33fde",
    "edition": 237,
    "date": 1672532522896,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "237",
    "rarity_score": 633.888679025708,
    "rarity_level": "RARE"
  },
  "1136": {
    "name": "Arkade 90s Bulls #1136",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1136.png",
    "dna": "260668ad1d72209c545181fcaef4770a05a0b2dd",
    "edition": 1136,
    "date": 1672532523603,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1136",
    "rarity_score": 346.111760021996,
    "rarity_level": "COMMON"
  },
  "1215": {
    "name": "Arkade 90s Bulls #1215",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1215.png",
    "dna": "6953c85f86157b9de016f0dfdfd3767adc46a783",
    "edition": 1215,
    "date": 1672532524330,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1215",
    "rarity_score": 268.3929222786799,
    "rarity_level": "COMMON"
  },
  "202": {
    "name": "Arkade 90s Bulls #202",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/202.png",
    "dna": "e238f53fb512fd3a6807fa835b9f0f1f345a0586",
    "edition": 202,
    "date": 1672532525219,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "202",
    "rarity_score": 448.2498240360243,
    "rarity_level": "COMMON"
  },
  "1235": {
    "name": "Arkade 90s Bulls #1235",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1235.png",
    "dna": "f1489d665dd43dc1ce67fd3f2beed9b38785b347",
    "edition": 1235,
    "date": 1672532526043,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1235",
    "rarity_score": 267.2435822337177,
    "rarity_level": "COMMON"
  },
  "1054": {
    "name": "Arkade 90s Bulls #1054",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1054.png",
    "dna": "3901f7be7e8c05c434bb68697f67b9b2148aa09a",
    "edition": 1054,
    "date": 1672532526722,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1054",
    "rarity_score": 203.8232558553637,
    "rarity_level": "COMMON"
  },
  "1282": {
    "name": "Arkade 90s Bulls #1282",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1282.png",
    "dna": "6df66bede4a9f9e6b62592d356bee825309ef0c1",
    "edition": 1282,
    "date": 1672532527487,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1282",
    "rarity_score": 338.6447868097974,
    "rarity_level": "COMMON"
  },
  "1165": {
    "name": "Arkade 90s Bulls #1165",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1165.png",
    "dna": "b39a31045f633a42329da3472e3877d14213e523",
    "edition": 1165,
    "date": 1672532528323,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1165",
    "rarity_score": 375.3691467688855,
    "rarity_level": "COMMON"
  },
  "1705": {
    "name": "Arkade 90s Bulls #1705",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1705.png",
    "dna": "0f0900c8482bd927721eeeab9f51c5851575e85d",
    "edition": 1705,
    "date": 1672532529770,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1705",
    "rarity_score": 256.80388296062256,
    "rarity_level": "COMMON"
  },
  "1376": {
    "name": "Arkade 90s Bulls #1376",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1376.png",
    "dna": "c11a2723e12163e3f93f3ae988deb9fa0bbd18ef",
    "edition": 1376,
    "date": 1672532530718,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1376",
    "rarity_score": 437.12491728836363,
    "rarity_level": "COMMON"
  },
  "1894": {
    "name": "Arkade 90s Bulls #1894",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1894.png",
    "dna": "fd3af52b7d2100aa59652e9885a807cd8b4527ce",
    "edition": 1894,
    "date": 1672532531555,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1894",
    "rarity_score": 446.8349644995169,
    "rarity_level": "COMMON"
  },
  "437": {
    "name": "Arkade 90s Bulls #437",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/437.png",
    "dna": "e9829cf0dc82593ec71df20ddd7aec756cb23154",
    "edition": 437,
    "date": 1672532532192,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "437",
    "rarity_score": 335.1561637548396,
    "rarity_level": "COMMON"
  },
  "1910": {
    "name": "Arkade 90s Bulls #1910",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1910.png",
    "dna": "b4a3706003f3e46fb3c9bfc538469ecbdc1dd55a",
    "edition": 1910,
    "date": 1672532532962,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1910",
    "rarity_score": 209.05567974747973,
    "rarity_level": "COMMON"
  },
  "501": {
    "name": "Arkade 90s Bulls #501",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/501.png",
    "dna": "c815822616653252619fe056e6be52fbf52766e3",
    "edition": 501,
    "date": 1672532533932,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "501",
    "rarity_score": 227.5245098112786,
    "rarity_level": "COMMON"
  },
  "1175": {
    "name": "Arkade 90s Bulls #1175",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1175.png",
    "dna": "63e6464ab0f1fda25566e02bca91f2832085eebf",
    "edition": 1175,
    "date": 1672532534569,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1175",
    "rarity_score": 197.25961905725893,
    "rarity_level": "COMMON"
  },
  "1965": {
    "name": "Arkade 90s Bulls #1965",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1965.png",
    "dna": "dd3be6853d14fb47983a2b5e279a0f018b90baae",
    "edition": 1965,
    "date": 1672532535727,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1965",
    "rarity_score": 442.21542496703756,
    "rarity_level": "COMMON"
  },
  "191": {
    "name": "Arkade 90s Bulls #191",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/191.png",
    "dna": "ca9c50bbf959a96b857cc8ed7dd430d0e036a9fe",
    "edition": 191,
    "date": 1672532537100,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "191",
    "rarity_score": 276.2327839314408,
    "rarity_level": "COMMON"
  },
  "38": {
    "name": "Arkade 90s Bulls #38",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/38.png",
    "dna": "9bd556935f0b3a75cb0046a5aa89888e8a365191",
    "edition": 38,
    "date": 1672532537753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "38",
    "rarity_score": 215.6468050033718,
    "rarity_level": "COMMON"
  },
  "1186": {
    "name": "Arkade 90s Bulls #1186",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1186.png",
    "dna": "2572be0ce08372c3ff9950e1566412aa9d4c60bf",
    "edition": 1186,
    "date": 1672532538408,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1186",
    "rarity_score": 494.36959184667666,
    "rarity_level": "RARE"
  },
  "1411": {
    "name": "Arkade 90s Bulls #1411",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1411.png",
    "dna": "4820c87b2b142e06145ae1047465f6dc97e40045",
    "edition": 1411,
    "date": 1672532539885,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1411",
    "rarity_score": 282.4167702381273,
    "rarity_level": "COMMON"
  },
  "1094": {
    "name": "Arkade 90s Bulls #1094",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1094.png",
    "dna": "500d732ab3097f2b924178412c22adc265243b80",
    "edition": 1094,
    "date": 1672532541427,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Superbull Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1094",
    "rarity_score": 614.2474062861343,
    "rarity_level": "RARE"
  },
  "1876": {
    "name": "Arkade 90s Bulls #1876",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1876.png",
    "dna": "62ddec7168a1c344530d43e8f3d9cc210c9d0e6c",
    "edition": 1876,
    "date": 1672532542117,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1876",
    "rarity_score": 276.63415835944306,
    "rarity_level": "COMMON"
  },
  "673": {
    "name": "Arkade 90s Bulls #673",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/673.png",
    "dna": "a51eb7b0b39e6d8bd07243fe52df305c63c68cca",
    "edition": 673,
    "date": 1672532542819,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "673",
    "rarity_score": 264.0021527489958,
    "rarity_level": "COMMON"
  },
  "1904": {
    "name": "Arkade 90s Bulls #1904",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1904.png",
    "dna": "c2616518291404a1d82671c486cec1ed215c2d4d",
    "edition": 1904,
    "date": 1672532543568,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1904",
    "rarity_score": 469.296816812015,
    "rarity_level": "RARE"
  },
  "154": {
    "name": "Arkade 90s Bulls #154",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/154.png",
    "dna": "204300eb1828531d5fb85b0661a20aa7272315ed",
    "edition": 154,
    "date": 1672532544170,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "154",
    "rarity_score": 375.59832554061217,
    "rarity_level": "COMMON"
  },
  "1823": {
    "name": "Arkade 90s Bulls #1823",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1823.png",
    "dna": "ea9fa1ed8dfab5df480db09a6c1447c1895bc41d",
    "edition": 1823,
    "date": 1672532544826,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1823",
    "rarity_score": 319.16268043115366,
    "rarity_level": "COMMON"
  },
  "1350": {
    "name": "Arkade 90s Bulls #1350",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1350.png",
    "dna": "7ba5a1e86133db9f97783ce62047daaf8adf977a",
    "edition": 1350,
    "date": 1672532545463,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1350",
    "rarity_score": 240.17650034242894,
    "rarity_level": "COMMON"
  },
  "49": {
    "name": "Arkade 90s Bulls #49",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/49.png",
    "dna": "835d51af1290bb9e2006bed10d42b849581d3090",
    "edition": 49,
    "date": 1672532546145,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "49",
    "rarity_score": 583.259574392034,
    "rarity_level": "RARE"
  },
  "1150": {
    "name": "Arkade 90s Bulls #1150",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1150.png",
    "dna": "dfd8c89b419d1ecf23ce86c469f8deee18caad75",
    "edition": 1150,
    "date": 1672532546843,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1150",
    "rarity_score": 343.2393604821308,
    "rarity_level": "COMMON"
  },
  "1562": {
    "name": "Arkade 90s Bulls #1562",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1562.png",
    "dna": "7fe87e802d2d85593d2e597af1806af49c2a0a6f",
    "edition": 1562,
    "date": 1672532547539,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1562",
    "rarity_score": 210.04188860030442,
    "rarity_level": "COMMON"
  },
  "655": {
    "name": "Arkade 90s Bulls #655",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/655.png",
    "dna": "a06c41338b1ce15bba2c3b53e29345d28310f7a3",
    "edition": 655,
    "date": 1672532548377,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "655",
    "rarity_score": 486.09152133949465,
    "rarity_level": "RARE"
  },
  "917": {
    "name": "Arkade 90s Bulls #917",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/917.png",
    "dna": "93d6decef95e23fd2a5d48a23d0c4ea087009f61",
    "edition": 917,
    "date": 1672532549071,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "917",
    "rarity_score": 140.54806361588356,
    "rarity_level": "COMMON"
  },
  "1020": {
    "name": "Arkade 90s Bulls #1020",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1020.png",
    "dna": "48ec6ecf09b5988fd37b59cec9b0bfbce2f6b6eb",
    "edition": 1020,
    "date": 1672532549729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1020",
    "rarity_score": 247.07487964756092,
    "rarity_level": "COMMON"
  },
  "1071": {
    "name": "Arkade 90s Bulls #1071",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1071.png",
    "dna": "21222f3271e317bad13075241fb468c416ed2c18",
    "edition": 1071,
    "date": 1672532550557,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1071",
    "rarity_score": 448.1335915497546,
    "rarity_level": "COMMON"
  },
  "671": {
    "name": "Arkade 90s Bulls #671",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/671.png",
    "dna": "39672b5c34286a4e82db1c356fe83ea446e08fc8",
    "edition": 671,
    "date": 1672532551954,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "671",
    "rarity_score": 379.89405845396794,
    "rarity_level": "COMMON"
  },
  "27": {
    "name": "Arkade 90s Bulls #27",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/27.png",
    "dna": "7a9bbcb70c964621568282170f6973b5b3d07fa0",
    "edition": 27,
    "date": 1672532552805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "27",
    "rarity_score": 184.90432695635045,
    "rarity_level": "COMMON"
  },
  "987": {
    "name": "Arkade 90s Bulls #987",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/987.png",
    "dna": "8d1773708f5008818b772315970f36544487ab72",
    "edition": 987,
    "date": 1672532553524,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "987",
    "rarity_score": 367.8915297583962,
    "rarity_level": "COMMON"
  },
  "1182": {
    "name": "Arkade 90s Bulls #1182",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1182.png",
    "dna": "f462a20c1b3ea77707811d380c9f3f0b0acd1a52",
    "edition": 1182,
    "date": 1672532554225,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1182",
    "rarity_score": 366.688737436562,
    "rarity_level": "COMMON"
  },
  "1703": {
    "name": "Arkade 90s Bulls #1703",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1703.png",
    "dna": "c3fcd2bc39a58459fd5fc00ae2de55c9cc092092",
    "edition": 1703,
    "date": 1672532554888,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1703",
    "rarity_score": 203.94980259229422,
    "rarity_level": "COMMON"
  },
  "1915": {
    "name": "Arkade 90s Bulls #1915",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1915.png",
    "dna": "6ad5ec1fe9dc76adaf0349da9176a7fe8fca9e9f",
    "edition": 1915,
    "date": 1672532555562,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1915",
    "rarity_score": 444.699254142354,
    "rarity_level": "COMMON"
  },
  "1451": {
    "name": "Arkade 90s Bulls #1451",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1451.png",
    "dna": "098cda145b9827632186029b9cb781c70679e735",
    "edition": 1451,
    "date": 1672532556238,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1451",
    "rarity_score": 346.3405256440829,
    "rarity_level": "COMMON"
  },
  "15": {
    "name": "Arkade 90s Bulls #15",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/15.png",
    "dna": "ae2abfa5dcf87942cc925f84013cbd66729f00a4",
    "edition": 15,
    "date": 1672532556982,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "15",
    "rarity_score": 449.3227455829068,
    "rarity_level": "COMMON"
  },
  "584": {
    "name": "Arkade 90s Bulls #584",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/584.png",
    "dna": "8e28a0d6c441ba59fa234f015270c242c190c882",
    "edition": 584,
    "date": 1672532558346,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "584",
    "rarity_score": 384.00948830825433,
    "rarity_level": "COMMON"
  },
  "1289": {
    "name": "Arkade 90s Bulls #1289",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1289.png",
    "dna": "3eb04c18df15be8dd27bc086ccb00e1264fb5454",
    "edition": 1289,
    "date": 1672532558994,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1289",
    "rarity_score": 344.18412176120654,
    "rarity_level": "COMMON"
  },
  "985": {
    "name": "Arkade 90s Bulls #985",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/985.png",
    "dna": "ef6421a375db9e8505ab4877af8b25cf54f450a6",
    "edition": 985,
    "date": 1672532559614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "985",
    "rarity_score": 260.7889830542214,
    "rarity_level": "COMMON"
  },
  "40": {
    "name": "Arkade 90s Bulls #40",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/40.png",
    "dna": "b2bd5552ab9e4cc24d71652656913f6bc70608c2",
    "edition": 40,
    "date": 1672532560263,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "40",
    "rarity_score": 543.5513612603808,
    "rarity_level": "RARE"
  },
  "1704": {
    "name": "Arkade 90s Bulls #1704",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1704.png",
    "dna": "7bf67bb58fc1d65aeccbf9bd65c5246e54d05dc5",
    "edition": 1704,
    "date": 1672532561093,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1704",
    "rarity_score": 389.43049272375924,
    "rarity_level": "COMMON"
  },
  "1553": {
    "name": "Arkade 90s Bulls #1553",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1553.png",
    "dna": "60f7d5a112be70f931dfa6b97f406ba1aac45d4e",
    "edition": 1553,
    "date": 1672532561772,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1553",
    "rarity_score": 347.08668443400524,
    "rarity_level": "COMMON"
  },
  "72": {
    "name": "Arkade 90s Bulls #72",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/72.png",
    "dna": "fb7e019791a0403d61bcfa4cb5d3fdfce8bf042f",
    "edition": 72,
    "date": 1672532562450,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "72",
    "rarity_score": 240.2124068075873,
    "rarity_level": "COMMON"
  },
  "930": {
    "name": "Arkade 90s Bulls #930",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/930.png",
    "dna": "27b52c585e7f1668b3f131bd1b49a20913954ddb",
    "edition": 930,
    "date": 1672532563638,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "930",
    "rarity_score": 434.6010617672264,
    "rarity_level": "COMMON"
  },
  "1578": {
    "name": "Arkade 90s Bulls #1578",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1578.png",
    "dna": "9fa411b38b9fcd94c97141ad6a28da6d29093c36",
    "edition": 1578,
    "date": 1672532564261,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1578",
    "rarity_score": 390.13702476621233,
    "rarity_level": "COMMON"
  },
  "709": {
    "name": "Arkade 90s Bulls #709",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/709.png",
    "dna": "f0869ed5fe5cf974410ce42f7bc7970b339dfd80",
    "edition": 709,
    "date": 1672532565654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "709",
    "rarity_score": 168.8276412168077,
    "rarity_level": "COMMON"
  },
  "1403": {
    "name": "Arkade 90s Bulls #1403",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1403.png",
    "dna": "0e1527a5dc6143cfd499eb220a4375345036342c",
    "edition": 1403,
    "date": 1672532566315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1403",
    "rarity_score": 333.8320532077522,
    "rarity_level": "COMMON"
  },
  "403": {
    "name": "Arkade 90s Bulls #403",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/403.png",
    "dna": "fcf1f187f2414287688dd6768b6e56165d133da4",
    "edition": 403,
    "date": 1672532567687,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "403",
    "rarity_score": 400.72277785380226,
    "rarity_level": "COMMON"
  },
  "1971": {
    "name": "Arkade 90s Bulls #1971",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1971.png",
    "dna": "1fddd4ed645ca7e0ae182ae3f0aed8ee73d6358e",
    "edition": 1971,
    "date": 1672532568351,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Head", "value": "Football Head", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1971",
    "rarity_score": 666.0434697443469,
    "rarity_level": "RARE"
  },
  "459": {
    "name": "Arkade 90s Bulls #459",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/459.png",
    "dna": "b5e1939407b1085d56e3f409f0bd256984450a9e",
    "edition": 459,
    "date": 1672532568992,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "459",
    "rarity_score": 241.4109173962427,
    "rarity_level": "COMMON"
  },
  "1450": {
    "name": "Arkade 90s Bulls #1450",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1450.png",
    "dna": "2eb02471791c51143ed61c340f600c6735959bc5",
    "edition": 1450,
    "date": 1672532569600,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      { "trait_type": "Base", "value": "Diamond Evil", "score": 99.5 },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1450",
    "rarity_score": 347.812445979221,
    "rarity_level": "COMMON"
  },
  "1088": {
    "name": "Arkade 90s Bulls #1088",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1088.png",
    "dna": "1f49df2f937179eed7fc38f572f7886db7863e75",
    "edition": 1088,
    "date": 1672532570406,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1088",
    "rarity_score": 228.10597101886336,
    "rarity_level": "COMMON"
  },
  "1233": {
    "name": "Arkade 90s Bulls #1233",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1233.png",
    "dna": "df6358db5221bdb4e71577fb38cf38be9a216624",
    "edition": 1233,
    "date": 1672532571234,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1233",
    "rarity_score": 425.3975699813308,
    "rarity_level": "COMMON"
  },
  "320": {
    "name": "Arkade 90s Bulls #320",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/320.png",
    "dna": "7b95163ae79e6a6c2b7a14aa4d28f01617bf66ec",
    "edition": 320,
    "date": 1672532572009,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "320",
    "rarity_score": 401.5378467570855,
    "rarity_level": "COMMON"
  },
  "1264": {
    "name": "Arkade 90s Bulls #1264",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1264.png",
    "dna": "17db22ee76f8cc5e598fa8eba078e56ae592099a",
    "edition": 1264,
    "date": 1672532573023,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1264",
    "rarity_score": 384.2984071035524,
    "rarity_level": "COMMON"
  },
  "1756": {
    "name": "Arkade 90s Bulls #1756",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1756.png",
    "dna": "e947068b9355ea48887e3f46f45c29d394549908",
    "edition": 1756,
    "date": 1672532573765,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1756",
    "rarity_score": 352.39805962491306,
    "rarity_level": "COMMON"
  },
  "942": {
    "name": "Arkade 90s Bulls #942",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/942.png",
    "dna": "d99a9a5d28f936de53c15ccf33adbf542db55295",
    "edition": 942,
    "date": 1672532574444,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "942",
    "rarity_score": 454.30427359009275,
    "rarity_level": "COMMON"
  },
  "908": {
    "name": "Arkade 90s Bulls #908",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/908.png",
    "dna": "3fe26793eb3d2ae2dc0c86793fd2f89d40e5345a",
    "edition": 908,
    "date": 1672532575278,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Diamond Tongue Out",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "908",
    "rarity_score": 556.6301298515876,
    "rarity_level": "RARE"
  },
  "398": {
    "name": "Arkade 90s Bulls #398",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/398.png",
    "dna": "e62e065d33cc0f69db852fb98262bae91879458a",
    "edition": 398,
    "date": 1672532576631,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Angry",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "398",
    "rarity_score": 482.71830163465944,
    "rarity_level": "RARE"
  },
  "571": {
    "name": "Arkade 90s Bulls #571",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/571.png",
    "dna": "a5bf2b9a0009034d807701df4880b5862940535b",
    "edition": 571,
    "date": 1672532577462,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "571",
    "rarity_score": 336.5569845177082,
    "rarity_level": "COMMON"
  },
  "1025": {
    "name": "Arkade 90s Bulls #1025",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1025.png",
    "dna": "88829f80b1cace80a9d73f6bafe6f59654b3cafe",
    "edition": 1025,
    "date": 1672532578894,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1025",
    "rarity_score": 304.5326808342558,
    "rarity_level": "COMMON"
  },
  "1173": {
    "name": "Arkade 90s Bulls #1173",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1173.png",
    "dna": "8194a0831faaf784bd22a5d5397686425576c8e5",
    "edition": 1173,
    "date": 1672532579512,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1173",
    "rarity_score": 306.81593138642944,
    "rarity_level": "COMMON"
  },
  "633": {
    "name": "Arkade 90s Bulls #633",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/633.png",
    "dna": "984cbf2a5f5f1fc151284d445154ba011eee2d40",
    "edition": 633,
    "date": 1672532580133,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "633",
    "rarity_score": 285.6914487605437,
    "rarity_level": "COMMON"
  },
  "68": {
    "name": "Arkade 90s Bulls #68",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/68.png",
    "dna": "0d5936579ac26d19f3abd6446fa85539e521c952",
    "edition": 68,
    "date": 1672532580764,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "68",
    "rarity_score": 413.423813520179,
    "rarity_level": "COMMON"
  },
  "100": {
    "name": "Arkade 90s Bulls #100",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/100.png",
    "dna": "97fca08db0ff7cf563266cbe805ee9856e7404ac",
    "edition": 100,
    "date": 1672532581973,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Diamond Sad", "score": 124.375 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "100",
    "rarity_score": 352.94616960997973,
    "rarity_level": "COMMON"
  },
  "1912": {
    "name": "Arkade 90s Bulls #1912",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1912.png",
    "dna": "e8e3d7f46895e2a85ccd0fd9611b2dc755785a0d",
    "edition": 1912,
    "date": 1672532582867,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1912",
    "rarity_score": 295.58784092705184,
    "rarity_level": "COMMON"
  },
  "1291": {
    "name": "Arkade 90s Bulls #1291",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1291.png",
    "dna": "60c520194dc013efb2dde8c1a4ea00602381c3c3",
    "edition": 1291,
    "date": 1672532583515,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      { "trait_type": "Head", "value": "Okko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1291",
    "rarity_score": 590.1437197183305,
    "rarity_level": "RARE"
  },
  "1081": {
    "name": "Arkade 90s Bulls #1081",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1081.png",
    "dna": "1df7dd7c982fee934085c3dba2ba62dc07d527ce",
    "edition": 1081,
    "date": 1672532584821,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1081",
    "rarity_score": 415.7340547729062,
    "rarity_level": "COMMON"
  },
  "171": {
    "name": "Arkade 90s Bulls #171",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/171.png",
    "dna": "a33dc6400fe8d0486bdb12a52784e538884d02ab",
    "edition": 171,
    "date": 1672532585435,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "171",
    "rarity_score": 476.4861685720215,
    "rarity_level": "RARE"
  },
  "84": {
    "name": "Arkade 90s Bulls #84",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/84.png",
    "dna": "98e8c0ecf494d54ceb8edaa265c3eafb545bb51e",
    "edition": 84,
    "date": 1672532586264,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "84",
    "rarity_score": 171.54089324923123,
    "rarity_level": "COMMON"
  },
  "1211": {
    "name": "Arkade 90s Bulls #1211",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1211.png",
    "dna": "bd224ed8325453eceeb69d4dd728cd01ad4b5271",
    "edition": 1211,
    "date": 1672532586961,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      { "trait_type": "Outfit", "value": "Spinner", "score": 398.0 },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1211",
    "rarity_score": 725.1095985817246,
    "rarity_level": "RARE"
  },
  "283": {
    "name": "Arkade 90s Bulls #283",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/283.png",
    "dna": "690dc72b15116b18a8e7d398ab294462c6ddcdc0",
    "edition": 283,
    "date": 1672532587940,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Diamond Happy",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "283",
    "rarity_score": 272.09726217508626,
    "rarity_level": "COMMON"
  },
  "619": {
    "name": "Arkade 90s Bulls #619",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/619.png",
    "dna": "fa3df57c6ec55ab1ca3c134e8bc5f52b545951cf",
    "edition": 619,
    "date": 1672532588654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Diamond Smile",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "619",
    "rarity_score": 255.21444718463044,
    "rarity_level": "COMMON"
  },
  "1775": {
    "name": "Arkade 90s Bulls #1775",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1775.png",
    "dna": "2e303fd5cb8031a8095d383374db2f45144ade4b",
    "edition": 1775,
    "date": 1672532589279,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      { "trait_type": "Base", "value": "Superstar Evil", "score": 497.5 },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1775",
    "rarity_score": 788.2959322226133,
    "rarity_level": "RARE"
  },
  "1933": {
    "name": "Arkade 90s Bulls #1933",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1933.png",
    "dna": "8d9baa05df8dd8df174e50b58bac6b69fb381d48",
    "edition": 1933,
    "date": 1672532590012,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1933",
    "rarity_score": 319.750168100983,
    "rarity_level": "COMMON"
  },
  "216": {
    "name": "Arkade 90s Bulls #216",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/216.png",
    "dna": "e7425873a6390a90e7f3c4a14c65be54572d0066",
    "edition": 216,
    "date": 1672532590698,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "216",
    "rarity_score": 195.5866995051144,
    "rarity_level": "COMMON"
  },
  "1706": {
    "name": "Arkade 90s Bulls #1706",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1706.png",
    "dna": "d9872d33e2a6f2b5903e078649e420f810976a42",
    "edition": 1706,
    "date": 1672532591431,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1706",
    "rarity_score": 220.67458432293716,
    "rarity_level": "COMMON"
  },
  "683": {
    "name": "Arkade 90s Bulls #683",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/683.png",
    "dna": "39f487878bb2aa1293e5633e0fb54be5fc3f4301",
    "edition": 683,
    "date": 1672532592642,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Evil", "score": 497.5 },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "683",
    "rarity_score": 767.9535579597758,
    "rarity_level": "RARE"
  },
  "250": {
    "name": "Arkade 90s Bulls #250",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/250.png",
    "dna": "ef19fa9e195606b141151b198e0722913e8c5e0d",
    "edition": 250,
    "date": 1672532593306,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "250",
    "rarity_score": 337.02243543073405,
    "rarity_level": "COMMON"
  },
  "1968": {
    "name": "Arkade 90s Bulls #1968",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1968.png",
    "dna": "299a3f2d934f158e5e91b37a96c333e9fa5ed9bb",
    "edition": 1968,
    "date": 1672532594206,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1968",
    "rarity_score": 582.1685092319317,
    "rarity_level": "RARE"
  },
  "813": {
    "name": "Arkade 90s Bulls #813",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/813.png",
    "dna": "5fc807ffe4d4b483b0361f2da0af1c796ac022a5",
    "edition": 813,
    "date": 1672532595012,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Sad", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Green Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "813",
    "rarity_score": 1028.709284726959,
    "rarity_level": "RARE"
  },
  "287": {
    "name": "Arkade 90s Bulls #287",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/287.png",
    "dna": "9450510c0712ddf0769f98cff7cb076ba5ba01fa",
    "edition": 287,
    "date": 1672532596306,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "287",
    "rarity_score": 386.03688842421536,
    "rarity_level": "COMMON"
  },
  "1147": {
    "name": "Arkade 90s Bulls #1147",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1147.png",
    "dna": "8dfa2359e0749d20af3810e0212f2bbf5df98df0",
    "edition": 1147,
    "date": 1672532596970,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1147",
    "rarity_score": 468.03335079793555,
    "rarity_level": "RARE"
  },
  "817": {
    "name": "Arkade 90s Bulls #817",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/817.png",
    "dna": "78059a913ba24a11563cf79881cf5a12456f00b3",
    "edition": 817,
    "date": 1672532597667,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "817",
    "rarity_score": 249.13527188429907,
    "rarity_level": "COMMON"
  },
  "1762": {
    "name": "Arkade 90s Bulls #1762",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1762.png",
    "dna": "6062fdd2aef2427314eee6f6764a2f8efc87af73",
    "edition": 1762,
    "date": 1672532598361,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Sad", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1762",
    "rarity_score": 480.8761752049426,
    "rarity_level": "RARE"
  },
  "14": {
    "name": "Arkade 90s Bulls #14",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/14.png",
    "dna": "4c3a7dca460e0c53722cff256c5f456813fbbb8c",
    "edition": 14,
    "date": 1672532599086,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "14",
    "rarity_score": 190.60133274343394,
    "rarity_level": "COMMON"
  },
  "1875": {
    "name": "Arkade 90s Bulls #1875",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1875.png",
    "dna": "0c9774b5c0aedde5c03d71fa76632a7bfdddf34d",
    "edition": 1875,
    "date": 1672532600454,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Sad", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1875",
    "rarity_score": 502.69575313769093,
    "rarity_level": "RARE"
  },
  "1317": {
    "name": "Arkade 90s Bulls #1317",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1317.png",
    "dna": "f9d07c836bb6c6c492eebcfe2c7f9b83b87b2f11",
    "edition": 1317,
    "date": 1672532601433,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1317",
    "rarity_score": 315.90799957437076,
    "rarity_level": "COMMON"
  },
  "1643": {
    "name": "Arkade 90s Bulls #1643",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1643.png",
    "dna": "f130561c0e87df052891b73220d3071521cbcfdc",
    "edition": 1643,
    "date": 1672532602097,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1643",
    "rarity_score": 322.6959754022493,
    "rarity_level": "COMMON"
  },
  "1287": {
    "name": "Arkade 90s Bulls #1287",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1287.png",
    "dna": "d0430154c6439084cf93b6298a661e8699276f11",
    "edition": 1287,
    "date": 1672532603505,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1287",
    "rarity_score": 352.0118344098651,
    "rarity_level": "COMMON"
  },
  "751": {
    "name": "Arkade 90s Bulls #751",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/751.png",
    "dna": "75d3cf6ead997c27fef608021ed540b7c9f21e96",
    "edition": 751,
    "date": 1672532604421,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "751",
    "rarity_score": 336.1557795583879,
    "rarity_level": "COMMON"
  },
  "1507": {
    "name": "Arkade 90s Bulls #1507",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1507.png",
    "dna": "8149539b29c3d5fa7a573c4fabe99498b41ce7d4",
    "edition": 1507,
    "date": 1672532605129,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1507",
    "rarity_score": 211.14247216925634,
    "rarity_level": "COMMON"
  },
  "490": {
    "name": "Arkade 90s Bulls #490",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/490.png",
    "dna": "17c97af5777e373993bd0d9f90a5738f87d91f35",
    "edition": 490,
    "date": 1672532605810,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "490",
    "rarity_score": 217.83470920084324,
    "rarity_level": "COMMON"
  },
  "538": {
    "name": "Arkade 90s Bulls #538",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/538.png",
    "dna": "900967d310cccc00317b5d4a2dbb01cc40c3169e",
    "edition": 538,
    "date": 1672532606544,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "538",
    "rarity_score": 251.74019816455382,
    "rarity_level": "COMMON"
  },
  "303": {
    "name": "Arkade 90s Bulls #303",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/303.png",
    "dna": "62a709102757662f8e2315d74b2f8614fe41a2c2",
    "edition": 303,
    "date": 1672532607274,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "303",
    "rarity_score": 527.5153391126598,
    "rarity_level": "RARE"
  },
  "1738": {
    "name": "Arkade 90s Bulls #1738",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1738.png",
    "dna": "836aa1ec7b27852520899cdbfc51a4348f21b2e8",
    "edition": 1738,
    "date": 1672532607872,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1738",
    "rarity_score": 337.0257194688007,
    "rarity_level": "COMMON"
  },
  "790": {
    "name": "Arkade 90s Bulls #790",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/790.png",
    "dna": "a07437ab55a9812769cf21ab18c67c7ddf52b6f9",
    "edition": 790,
    "date": 1672532609306,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "790",
    "rarity_score": 247.8422937828783,
    "rarity_level": "COMMON"
  },
  "197": {
    "name": "Arkade 90s Bulls #197",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/197.png",
    "dna": "50c661bdad9c69c65d685c4970ee1fff79192aef",
    "edition": 197,
    "date": 1672532610021,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Tongue Out",
        "score": 995.0
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "197",
    "rarity_score": 1147.6246250083204,
    "rarity_level": "EPIC"
  },
  "1042": {
    "name": "Arkade 90s Bulls #1042",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1042.png",
    "dna": "01be8d76aee52402589b9672df20518e66f883d1",
    "edition": 1042,
    "date": 1672532610721,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Sad", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1042",
    "rarity_score": 619.3177066535858,
    "rarity_level": "RARE"
  },
  "1087": {
    "name": "Arkade 90s Bulls #1087",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1087.png",
    "dna": "62d60eb8349ce5b3625e9e0d4a365d78a1df673b",
    "edition": 1087,
    "date": 1672532612201,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1087",
    "rarity_score": 514.5708004213097,
    "rarity_level": "RARE"
  },
  "792": {
    "name": "Arkade 90s Bulls #792",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/792.png",
    "dna": "78dc560ed26066bb9798403d5419cdc4c5b5e688",
    "edition": 792,
    "date": 1672532612916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "792",
    "rarity_score": 263.9653485427247,
    "rarity_level": "COMMON"
  },
  "668": {
    "name": "Arkade 90s Bulls #668",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/668.png",
    "dna": "3aebbc163c745b200550d346d92199be4bd60ea2",
    "edition": 668,
    "date": 1672532613616,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "668",
    "rarity_score": 341.5824259359887,
    "rarity_level": "COMMON"
  },
  "73": {
    "name": "Arkade 90s Bulls #73",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/73.png",
    "dna": "103008ce2a905c59ee112b51daa1e8dc0c05a788",
    "edition": 73,
    "date": 1672532614328,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "73",
    "rarity_score": 277.5256526990056,
    "rarity_level": "COMMON"
  },
  "1229": {
    "name": "Arkade 90s Bulls #1229",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1229.png",
    "dna": "bbd9fd7572d9188ae181df917a0c6bf24acb952e",
    "edition": 1229,
    "date": 1672532615292,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1229",
    "rarity_score": 507.0673052071322,
    "rarity_level": "RARE"
  },
  "1870": {
    "name": "Arkade 90s Bulls #1870",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1870.png",
    "dna": "de673c736a2ec26dd33b4f9402272f67f7b382a6",
    "edition": 1870,
    "date": 1672532616147,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      { "trait_type": "Base", "value": "Superstar Evil", "score": 497.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1870",
    "rarity_score": 796.1347885868685,
    "rarity_level": "RARE"
  },
  "1124": {
    "name": "Arkade 90s Bulls #1124",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1124.png",
    "dna": "4981c1e01ef56b6d4651c0c6dfbe847d665691d5",
    "edition": 1124,
    "date": 1672532616832,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      { "trait_type": "Head", "value": "Luiki Hat", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1124",
    "rarity_score": 590.7055106839301,
    "rarity_level": "RARE"
  },
  "1394": {
    "name": "Arkade 90s Bulls #1394",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1394.png",
    "dna": "f1d58f003d42dda993a31055eb9e3cc8cc4bdc18",
    "edition": 1394,
    "date": 1672532617550,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1394",
    "rarity_score": 209.16110125349115,
    "rarity_level": "COMMON"
  },
  "717": {
    "name": "Arkade 90s Bulls #717",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/717.png",
    "dna": "6e81cca3be0908f3f40e73ada8b665140b7a3a74",
    "edition": 717,
    "date": 1672532618305,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Tongue Out",
        "score": 995.0
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "717",
    "rarity_score": 1294.6101029062338,
    "rarity_level": "EPIC"
  },
  "1793": {
    "name": "Arkade 90s Bulls #1793",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1793.png",
    "dna": "6d1837bc1d27f4285a414f5fb71519e6c36da051",
    "edition": 1793,
    "date": 1672532619069,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1793",
    "rarity_score": 275.5065280750676,
    "rarity_level": "COMMON"
  },
  "630": {
    "name": "Arkade 90s Bulls #630",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/630.png",
    "dna": "31331554d34c316f3fc3516b8528116cf4baaa5e",
    "edition": 630,
    "date": 1672532619823,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "630",
    "rarity_score": 700.5094543816946,
    "rarity_level": "RARE"
  },
  "114": {
    "name": "Arkade 90s Bulls #114",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/114.png",
    "dna": "a54c77110c5753aa554a4cccb8619cf6457971c9",
    "edition": 114,
    "date": 1672532620498,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "114",
    "rarity_score": 298.2990544346998,
    "rarity_level": "COMMON"
  },
  "487": {
    "name": "Arkade 90s Bulls #487",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/487.png",
    "dna": "aff2a6a8454a7480ab57c231ebd0e3d1db1f3724",
    "edition": 487,
    "date": 1672532621216,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Luiki Hat", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "487",
    "rarity_score": 838.8070711702095,
    "rarity_level": "RARE"
  },
  "1360": {
    "name": "Arkade 90s Bulls #1360",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1360.png",
    "dna": "3c4666281fe1663675f4180c1de9826ccc94152a",
    "edition": 1360,
    "date": 1672532622189,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Sad", "score": 398.0 },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1360",
    "rarity_score": 629.8653668529678,
    "rarity_level": "RARE"
  },
  "1746": {
    "name": "Arkade 90s Bulls #1746",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1746.png",
    "dna": "543b5accc9ae0096560a3e8a782d85cc6497c94e",
    "edition": 1746,
    "date": 1672532622839,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Superstar Evil", "score": 497.5 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1746",
    "rarity_score": 968.0928653564538,
    "rarity_level": "RARE"
  },
  "1391": {
    "name": "Arkade 90s Bulls #1391",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1391.png",
    "dna": "6e9014ccc2f503665946a47a79ab11a2e7df00dc",
    "edition": 1391,
    "date": 1672532623542,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1391",
    "rarity_score": 365.03412132007037,
    "rarity_level": "COMMON"
  },
  "558": {
    "name": "Arkade 90s Bulls #558",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/558.png",
    "dna": "2af5657c57c18f51dcd53d3029009e75461be2c1",
    "edition": 558,
    "date": 1672532624955,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "558",
    "rarity_score": 425.30973965374034,
    "rarity_level": "COMMON"
  },
  "1412": {
    "name": "Arkade 90s Bulls #1412",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1412.png",
    "dna": "0c4dc7f89dc25021cc64dbea32fda0f781b0e10c",
    "edition": 1412,
    "date": 1672532625614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Angry",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1412",
    "rarity_score": 431.5326453499233,
    "rarity_level": "COMMON"
  },
  "424": {
    "name": "Arkade 90s Bulls #424",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/424.png",
    "dna": "664e3154bdc0b481dc88ad6824d9d2c52f13528e",
    "edition": 424,
    "date": 1672532626990,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "424",
    "rarity_score": 451.2251788917409,
    "rarity_level": "COMMON"
  },
  "211": {
    "name": "Arkade 90s Bulls #211",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/211.png",
    "dna": "2c5608fb5302bee04f66830b5aae62cd5d618f14",
    "edition": 211,
    "date": 1672532627698,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "211",
    "rarity_score": 603.0591241935764,
    "rarity_level": "RARE"
  },
  "1090": {
    "name": "Arkade 90s Bulls #1090",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1090.png",
    "dna": "5ac649460ac762f54acffe91047b737754df4632",
    "edition": 1090,
    "date": 1672532628421,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1090",
    "rarity_score": 321.7337950196368,
    "rarity_level": "COMMON"
  },
  "1969": {
    "name": "Arkade 90s Bulls #1969",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1969.png",
    "dna": "c6daa3fc3b646f4c7e6a316c2ee7379dedd582bf",
    "edition": 1969,
    "date": 1672532629115,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1969",
    "rarity_score": 264.8376454597511,
    "rarity_level": "COMMON"
  },
  "774": {
    "name": "Arkade 90s Bulls #774",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/774.png",
    "dna": "d40f696e3e55c871345d2a9f887ddc2dd4f6347c",
    "edition": 774,
    "date": 1672532629799,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Smile",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "774",
    "rarity_score": 453.17848322610075,
    "rarity_level": "COMMON"
  },
  "1616": {
    "name": "Arkade 90s Bulls #1616",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1616.png",
    "dna": "1aaf1a2ffce549af1fa05e422ea3125bc58b6f0b",
    "edition": 1616,
    "date": 1672532630446,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Superstar Happy",
        "score": 124.375
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1616",
    "rarity_score": 336.3495551915766,
    "rarity_level": "COMMON"
  },
  "982": {
    "name": "Arkade 90s Bulls #982",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/982.png",
    "dna": "48b40a010207730e0d1e935ed250d97a7020cb8a",
    "edition": 982,
    "date": 1672532630907,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "982",
    "rarity_score": 167.72564542781436,
    "rarity_level": "COMMON"
  },
  "620": {
    "name": "Arkade 90s Bulls #620",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/620.png",
    "dna": "cb27f5d1131d223e9d6e84bff655239784827755",
    "edition": 620,
    "date": 1672532631363,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "620",
    "rarity_score": 368.87108622761184,
    "rarity_level": "COMMON"
  },
  "1390": {
    "name": "Arkade 90s Bulls #1390",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1390.png",
    "dna": "e32f82af81a5dfdb4112b78b7499c37419503ba4",
    "edition": 1390,
    "date": 1672532632369,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1390",
    "rarity_score": 298.31112914805016,
    "rarity_level": "COMMON"
  },
  "1668": {
    "name": "Arkade 90s Bulls #1668",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1668.png",
    "dna": "f6b357bc50024b41ce379d298771ac0a6d3eb9fe",
    "edition": 1668,
    "date": 1672532633172,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1668",
    "rarity_score": 313.27108628082846,
    "rarity_level": "COMMON"
  },
  "765": {
    "name": "Arkade 90s Bulls #765",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/765.png",
    "dna": "0ad6eee2c8b48528408f132848065f5ea358f1a5",
    "edition": 765,
    "date": 1672532633625,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "765",
    "rarity_score": 161.57514807456695,
    "rarity_level": "COMMON"
  },
  "1077": {
    "name": "Arkade 90s Bulls #1077",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1077.png",
    "dna": "d7fa56a6fdcecc1086bbad7feaf1f1fefb7c61fa",
    "edition": 1077,
    "date": 1672532634087,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1077",
    "rarity_score": 159.7561561363704,
    "rarity_level": "COMMON"
  },
  "1052": {
    "name": "Arkade 90s Bulls #1052",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1052.png",
    "dna": "af76486124209f956a49bd70d6443234ab2b2933",
    "edition": 1052,
    "date": 1672532634598,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1052",
    "rarity_score": 239.26160006516997,
    "rarity_level": "COMMON"
  },
  "1767": {
    "name": "Arkade 90s Bulls #1767",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1767.png",
    "dna": "9e14cd682f1ca851eb8a874545b06f73ce02862d",
    "edition": 1767,
    "date": 1672532635036,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1767",
    "rarity_score": 112.97754469418928,
    "rarity_level": "COMMON"
  },
  "746": {
    "name": "Arkade 90s Bulls #746",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/746.png",
    "dna": "052c9efab6fcb64b7e15dd68cff5aa0757854d77",
    "edition": 746,
    "date": 1672532635537,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "746",
    "rarity_score": 223.3389813431955,
    "rarity_level": "COMMON"
  },
  "200": {
    "name": "Arkade 90s Bulls #200",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/200.png",
    "dna": "b6c9971bcc67222adf85f4bdb274dec183089ab8",
    "edition": 200,
    "date": 1672532636191,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "200",
    "rarity_score": 215.64337511270122,
    "rarity_level": "COMMON"
  },
  "1543": {
    "name": "Arkade 90s Bulls #1543",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1543.png",
    "dna": "caad670175d6df39d7fb67c664b5c00ed33a5125",
    "edition": 1543,
    "date": 1672532636657,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1543",
    "rarity_score": 130.94685738002732,
    "rarity_level": "COMMON"
  },
  "1145": {
    "name": "Arkade 90s Bulls #1145",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1145.png",
    "dna": "42233923b98ca24e1240ce9fc6d7f9fd1e30c562",
    "edition": 1145,
    "date": 1672532637096,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1145",
    "rarity_score": 290.4752427221964,
    "rarity_level": "COMMON"
  },
  "787": {
    "name": "Arkade 90s Bulls #787",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/787.png",
    "dna": "48598c90866c1a7aee126bd7c5251c2d108e2cd1",
    "edition": 787,
    "date": 1672532637554,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "787",
    "rarity_score": 245.25850150582224,
    "rarity_level": "COMMON"
  },
  "1158": {
    "name": "Arkade 90s Bulls #1158",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1158.png",
    "dna": "b3eab276993174c2603db0a64a7d08b7a9a3821a",
    "edition": 1158,
    "date": 1672532638644,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1158",
    "rarity_score": 384.63231473703297,
    "rarity_level": "COMMON"
  },
  "1983": {
    "name": "Arkade 90s Bulls #1983",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1983.png",
    "dna": "b206669188146bd1d691b06110187287b1ee437a",
    "edition": 1983,
    "date": 1672532639114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1983",
    "rarity_score": 119.7872908271979,
    "rarity_level": "COMMON"
  },
  "260": {
    "name": "Arkade 90s Bulls #260",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/260.png",
    "dna": "a2d03278f6c76529c5fd035cf802b114c643c0e2",
    "edition": 260,
    "date": 1672532639553,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "260",
    "rarity_score": 385.6460890101056,
    "rarity_level": "COMMON"
  },
  "371": {
    "name": "Arkade 90s Bulls #371",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/371.png",
    "dna": "b99bcc690cc4fd39b998fd4838b8ac8e2fb6f8fc",
    "edition": 371,
    "date": 1672532640216,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "371",
    "rarity_score": 359.39947680004167,
    "rarity_level": "COMMON"
  },
  "1900": {
    "name": "Arkade 90s Bulls #1900",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1900.png",
    "dna": "68bb0d31c800277baab0e18315913611f006521f",
    "edition": 1900,
    "date": 1672532640987,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1900",
    "rarity_score": 208.3161012828034,
    "rarity_level": "COMMON"
  },
  "1516": {
    "name": "Arkade 90s Bulls #1516",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1516.png",
    "dna": "b04bda89e8674dcefd3b8393b817922f73d40bb9",
    "edition": 1516,
    "date": 1672532642044,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1516",
    "rarity_score": 213.50532170361484,
    "rarity_level": "COMMON"
  },
  "1188": {
    "name": "Arkade 90s Bulls #1188",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1188.png",
    "dna": "301c9e7a79c071b93c3e2a4cd87746ef5c1f756c",
    "edition": 1188,
    "date": 1672532642539,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1188",
    "rarity_score": 186.75804162608677,
    "rarity_level": "COMMON"
  },
  "1681": {
    "name": "Arkade 90s Bulls #1681",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1681.png",
    "dna": "bbd17cc127636e90d0f9098e672dc6e6d1050862",
    "edition": 1681,
    "date": 1672532642991,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1681",
    "rarity_score": 272.46334892696103,
    "rarity_level": "COMMON"
  },
  "1118": {
    "name": "Arkade 90s Bulls #1118",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1118.png",
    "dna": "6bc5c847597c838e15b7608b664406db2c3816fb",
    "edition": 1118,
    "date": 1672532643451,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1118",
    "rarity_score": 168.69977028772018,
    "rarity_level": "COMMON"
  },
  "1304": {
    "name": "Arkade 90s Bulls #1304",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1304.png",
    "dna": "f9e1f155eb3172bb043315c675360617f435412e",
    "edition": 1304,
    "date": 1672532643968,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1304",
    "rarity_score": 351.64948193962664,
    "rarity_level": "COMMON"
  },
  "251": {
    "name": "Arkade 90s Bulls #251",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/251.png",
    "dna": "551d979921c98eb231f2ff821950f335bce762e3",
    "edition": 251,
    "date": 1672532644456,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "251",
    "rarity_score": 218.0281585408613,
    "rarity_level": "COMMON"
  },
  "724": {
    "name": "Arkade 90s Bulls #724",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/724.png",
    "dna": "04ec5316ba2a05e1c5373974e29d11777b966977",
    "edition": 724,
    "date": 1672532644929,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "724",
    "rarity_score": 482.33476332490795,
    "rarity_level": "RARE"
  },
  "384": {
    "name": "Arkade 90s Bulls #384",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/384.png",
    "dna": "031b3e41b9af60dc899058afb59e526893938404",
    "edition": 384,
    "date": 1672532646359,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "384",
    "rarity_score": 289.02730843884314,
    "rarity_level": "COMMON"
  },
  "225": {
    "name": "Arkade 90s Bulls #225",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/225.png",
    "dna": "8156eea6bd1a59a448659d27340d138628d09d52",
    "edition": 225,
    "date": 1672532646814,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "225",
    "rarity_score": 365.20215950572947,
    "rarity_level": "COMMON"
  },
  "1748": {
    "name": "Arkade 90s Bulls #1748",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1748.png",
    "dna": "0e225c65990509413c3c9771fca5a686d69a4560",
    "edition": 1748,
    "date": 1672532647317,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1748",
    "rarity_score": 286.47461803459703,
    "rarity_level": "COMMON"
  },
  "420": {
    "name": "Arkade 90s Bulls #420",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/420.png",
    "dna": "fdc30d3081e94f239d442cbc373b6596cbfe20c5",
    "edition": 420,
    "date": 1672532647780,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "420",
    "rarity_score": 221.62741012604545,
    "rarity_level": "COMMON"
  },
  "769": {
    "name": "Arkade 90s Bulls #769",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/769.png",
    "dna": "8746a81b8e32f35ac8f4a485c8ff3e036b7e424c",
    "edition": 769,
    "date": 1672532648255,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "769",
    "rarity_score": 269.9729040016332,
    "rarity_level": "COMMON"
  },
  "1841": {
    "name": "Arkade 90s Bulls #1841",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1841.png",
    "dna": "61ece978f756dd6b27bd25ceb1fad34eb8ce319f",
    "edition": 1841,
    "date": 1672532648715,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1841",
    "rarity_score": 263.49908720266836,
    "rarity_level": "COMMON"
  },
  "1725": {
    "name": "Arkade 90s Bulls #1725",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1725.png",
    "dna": "3c69472a13ca3b555018a4a60f9639b7b11264e4",
    "edition": 1725,
    "date": 1672532649222,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1725",
    "rarity_score": 356.89212728404675,
    "rarity_level": "COMMON"
  },
  "883": {
    "name": "Arkade 90s Bulls #883",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/883.png",
    "dna": "4cfd5d0bc01347fb8072981a20a0cc7fa5b70f31",
    "edition": 883,
    "date": 1672532649685,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "883",
    "rarity_score": 143.16202458912116,
    "rarity_level": "COMMON"
  },
  "108": {
    "name": "Arkade 90s Bulls #108",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/108.png",
    "dna": "da619777b724593a4cd5218b4342bead91520a9d",
    "edition": 108,
    "date": 1672532650673,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "108",
    "rarity_score": 275.7738151879278,
    "rarity_level": "COMMON"
  },
  "258": {
    "name": "Arkade 90s Bulls #258",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/258.png",
    "dna": "5fc6823328a70c1c85aa69b9da1eec02baa86c42",
    "edition": 258,
    "date": 1672532651119,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "258",
    "rarity_score": 119.16446692341299,
    "rarity_level": "COMMON"
  },
  "895": {
    "name": "Arkade 90s Bulls #895",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/895.png",
    "dna": "6d7465b49c15e8e668040bb50a0e54c11f2217e6",
    "edition": 895,
    "date": 1672532651564,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "895",
    "rarity_score": 453.3519574656461,
    "rarity_level": "COMMON"
  },
  "1633": {
    "name": "Arkade 90s Bulls #1633",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1633.png",
    "dna": "531869a0dec8115b15c7026832b262b0e9e882d1",
    "edition": 1633,
    "date": 1672532652065,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1633",
    "rarity_score": 164.78241470947683,
    "rarity_level": "COMMON"
  },
  "485": {
    "name": "Arkade 90s Bulls #485",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/485.png",
    "dna": "666de3ff85c816c35adbc552817320c5e646ca0b",
    "edition": 485,
    "date": 1672532652587,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "485",
    "rarity_score": 344.34878823059177,
    "rarity_level": "COMMON"
  },
  "1661": {
    "name": "Arkade 90s Bulls #1661",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1661.png",
    "dna": "e8aeb6431f56cea78291845d669242805c51493a",
    "edition": 1661,
    "date": 1672532653765,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Blue Guardian Mask",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1661",
    "rarity_score": 819.5670804544073,
    "rarity_level": "RARE"
  },
  "1099": {
    "name": "Arkade 90s Bulls #1099",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1099.png",
    "dna": "acbb23a2bff4703eb7163d5f09a464c4b6ccc118",
    "edition": 1099,
    "date": 1672532654285,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1099",
    "rarity_score": 196.34099353958888,
    "rarity_level": "COMMON"
  },
  "279": {
    "name": "Arkade 90s Bulls #279",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/279.png",
    "dna": "6769d3e53f42e3a2632f728d139794ec8145bfbf",
    "edition": 279,
    "date": 1672532655416,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      { "trait_type": "Mask", "value": "Robot Helmet", "score": 995.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "279",
    "rarity_score": 1146.118272033544,
    "rarity_level": "EPIC"
  },
  "723": {
    "name": "Arkade 90s Bulls #723",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/723.png",
    "dna": "32d7a3aa24d784f9e7da65715cba5d3e13363e60",
    "edition": 723,
    "date": 1672532655890,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "723",
    "rarity_score": 334.444967644704,
    "rarity_level": "COMMON"
  },
  "86": {
    "name": "Arkade 90s Bulls #86",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/86.png",
    "dna": "bc90caa2d415a9a0916f7f1af9e586f37f2a8189",
    "edition": 86,
    "date": 1672532657162,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "86",
    "rarity_score": 642.1112853320011,
    "rarity_level": "RARE"
  },
  "412": {
    "name": "Arkade 90s Bulls #412",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/412.png",
    "dna": "60d9b1e20b6c93ff362a928bbc86bd4dd5272af6",
    "edition": 412,
    "date": 1672532657616,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "412",
    "rarity_score": 86.32363334746965,
    "rarity_level": "COMMON"
  },
  "1152": {
    "name": "Arkade 90s Bulls #1152",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1152.png",
    "dna": "f8b32cc2aea52b8e6221edca9d8a8945559af41d",
    "edition": 1152,
    "date": 1672532658110,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1152",
    "rarity_score": 406.5643106315721,
    "rarity_level": "COMMON"
  },
  "1438": {
    "name": "Arkade 90s Bulls #1438",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1438.png",
    "dna": "8557f7e9b39df511ad719a789a3e152402e45ad1",
    "edition": 1438,
    "date": 1672532658570,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1438",
    "rarity_score": 287.38723314389,
    "rarity_level": "COMMON"
  },
  "359": {
    "name": "Arkade 90s Bulls #359",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/359.png",
    "dna": "f8bf48b6abe363fad507414a023933bc2e552e86",
    "edition": 359,
    "date": 1672532659128,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "359",
    "rarity_score": 227.27289058990698,
    "rarity_level": "COMMON"
  },
  "543": {
    "name": "Arkade 90s Bulls #543",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/543.png",
    "dna": "8d17f61281bd3c5cd529c0d0239e212116b02b0b",
    "edition": 543,
    "date": 1672532659575,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "543",
    "rarity_score": 237.54937636777728,
    "rarity_level": "COMMON"
  },
  "380": {
    "name": "Arkade 90s Bulls #380",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/380.png",
    "dna": "afdb98056f812ad86259c87b39eda45a526bb0df",
    "edition": 380,
    "date": 1672532660092,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "380",
    "rarity_score": 310.00989516350876,
    "rarity_level": "COMMON"
  },
  "222": {
    "name": "Arkade 90s Bulls #222",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/222.png",
    "dna": "30f214b0dddef630b181bc3169866343b6d97fca",
    "edition": 222,
    "date": 1672532660793,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "222",
    "rarity_score": 386.52315701529466,
    "rarity_level": "COMMON"
  },
  "756": {
    "name": "Arkade 90s Bulls #756",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/756.png",
    "dna": "a8522f615cb6b1d8dec43fdba5e4e20c3408a715",
    "edition": 756,
    "date": 1672532661866,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "756",
    "rarity_score": 144.21230785742273,
    "rarity_level": "COMMON"
  },
  "1714": {
    "name": "Arkade 90s Bulls #1714",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1714.png",
    "dna": "b1f5d231fa21001ef9ccf7f6beabe24e22d98544",
    "edition": 1714,
    "date": 1672532662417,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1714",
    "rarity_score": 151.33553716312665,
    "rarity_level": "COMMON"
  },
  "1852": {
    "name": "Arkade 90s Bulls #1852",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1852.png",
    "dna": "92cb838f90df888876c15b866e7be26df5e5cb6b",
    "edition": 1852,
    "date": 1672532663530,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1852",
    "rarity_score": 215.4868991977555,
    "rarity_level": "COMMON"
  },
  "697": {
    "name": "Arkade 90s Bulls #697",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/697.png",
    "dna": "bfb6929e51e4ece200c08d6cd0979be0fcd878f4",
    "edition": 697,
    "date": 1672532664042,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "697",
    "rarity_score": 196.89815085452182,
    "rarity_level": "COMMON"
  },
  "705": {
    "name": "Arkade 90s Bulls #705",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/705.png",
    "dna": "89f64c2e88c4e29ddb9dd06ee529ad6ebe06077b",
    "edition": 705,
    "date": 1672532665163,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "705",
    "rarity_score": 244.24128106801086,
    "rarity_level": "COMMON"
  },
  "138": {
    "name": "Arkade 90s Bulls #138",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/138.png",
    "dna": "de5ea0521f84a51d03b48360d98abf0e40534749",
    "edition": 138,
    "date": 1672532666332,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "138",
    "rarity_score": 390.77412823014464,
    "rarity_level": "COMMON"
  },
  "729": {
    "name": "Arkade 90s Bulls #729",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/729.png",
    "dna": "38870396d5c277808e00247f284337021906cb0a",
    "edition": 729,
    "date": 1672532666801,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "729",
    "rarity_score": 252.43212787216638,
    "rarity_level": "COMMON"
  },
  "1817": {
    "name": "Arkade 90s Bulls #1817",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1817.png",
    "dna": "9442f15f7f10fb4d0e07d4f696f5e75f3059765c",
    "edition": 1817,
    "date": 1672532668002,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1817",
    "rarity_score": 173.96709016691705,
    "rarity_level": "COMMON"
  },
  "795": {
    "name": "Arkade 90s Bulls #795",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/795.png",
    "dna": "33ba84ae688cae4b23d748ca9b6f3f506d8c7c34",
    "edition": 795,
    "date": 1672532668703,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Red Stripes", "score": 995.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "795",
    "rarity_score": 1120.4287272280828,
    "rarity_level": "EPIC"
  },
  "772": {
    "name": "Arkade 90s Bulls #772",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/772.png",
    "dna": "545108a16c70e9a3117ff3de2a8fd77edf4e4ed1",
    "edition": 772,
    "date": 1672532670174,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "772",
    "rarity_score": 296.7455604119291,
    "rarity_level": "COMMON"
  },
  "78": {
    "name": "Arkade 90s Bulls #78",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/78.png",
    "dna": "b7a5d2ea61cdd375cb88aebb028c79ad895c1013",
    "edition": 78,
    "date": 1672532670702,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "78",
    "rarity_score": 310.17614234825385,
    "rarity_level": "COMMON"
  },
  "1517": {
    "name": "Arkade 90s Bulls #1517",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1517.png",
    "dna": "85b2688f93d7c2e440ba8f401f835e7778a1fbd4",
    "edition": 1517,
    "date": 1672532671332,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1517",
    "rarity_score": 225.7647435645186,
    "rarity_level": "COMMON"
  },
  "976": {
    "name": "Arkade 90s Bulls #976",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/976.png",
    "dna": "0eb0cbf388e36372aa1d62e66b6e030947936a32",
    "edition": 976,
    "date": 1672532671785,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "976",
    "rarity_score": 123.72586637421924,
    "rarity_level": "COMMON"
  },
  "1404": {
    "name": "Arkade 90s Bulls #1404",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1404.png",
    "dna": "64fae44a00777172d41322f2431e08f9a5a59768",
    "edition": 1404,
    "date": 1672532672244,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1404",
    "rarity_score": 201.56699469027507,
    "rarity_level": "COMMON"
  },
  "343": {
    "name": "Arkade 90s Bulls #343",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/343.png",
    "dna": "1909a46bbd165a352442cbe8c496d5fe91e571a8",
    "edition": 343,
    "date": 1672532673437,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "343",
    "rarity_score": 585.0049884074184,
    "rarity_level": "RARE"
  },
  "285": {
    "name": "Arkade 90s Bulls #285",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/285.png",
    "dna": "7504b3c10cdb924382189590752213511d58c6ff",
    "edition": 285,
    "date": 1672532673933,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "285",
    "rarity_score": 201.13483895475866,
    "rarity_level": "COMMON"
  },
  "118": {
    "name": "Arkade 90s Bulls #118",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/118.png",
    "dna": "3e31b1c2d3ce4039546d347553b32c71d66c7297",
    "edition": 118,
    "date": 1672532674607,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "118",
    "rarity_score": 215.7073620394377,
    "rarity_level": "COMMON"
  },
  "35": {
    "name": "Arkade 90s Bulls #35",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/35.png",
    "dna": "50c07890290af64f4eb6de29d046b7288c65b2b3",
    "edition": 35,
    "date": 1672532675072,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "35",
    "rarity_score": 249.53189894171965,
    "rarity_level": "COMMON"
  },
  "832": {
    "name": "Arkade 90s Bulls #832",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/832.png",
    "dna": "ee80102f04fb7e2b96afa394c1ebcfe32a951e2b",
    "edition": 832,
    "date": 1672532675562,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "832",
    "rarity_score": 255.5668099713782,
    "rarity_level": "COMMON"
  },
  "1944": {
    "name": "Arkade 90s Bulls #1944",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1944.png",
    "dna": "06bef0f7a07bce8b9550ca8da76df5545645f87f",
    "edition": 1944,
    "date": 1672532676746,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1944",
    "rarity_score": 145.63182986306424,
    "rarity_level": "COMMON"
  },
  "1123": {
    "name": "Arkade 90s Bulls #1123",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1123.png",
    "dna": "2de0459f3613b4375231651056fd7d233fca46c5",
    "edition": 1123,
    "date": 1672532677227,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1123",
    "rarity_score": 198.7238966069024,
    "rarity_level": "COMMON"
  },
  "1135": {
    "name": "Arkade 90s Bulls #1135",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1135.png",
    "dna": "cecd0243968a4307cb37738894564f00b0b6c17f",
    "edition": 1135,
    "date": 1672532678412,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1135",
    "rarity_score": 187.47854443435028,
    "rarity_level": "COMMON"
  },
  "1584": {
    "name": "Arkade 90s Bulls #1584",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1584.png",
    "dna": "8901391e60595dbe67ff22878b43193fcb3dafe1",
    "edition": 1584,
    "date": 1672532679179,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1584",
    "rarity_score": 384.803868454916,
    "rarity_level": "COMMON"
  },
  "737": {
    "name": "Arkade 90s Bulls #737",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/737.png",
    "dna": "d717348e895b972c2eec95fc59edac71725e6b5a",
    "edition": 737,
    "date": 1672532680145,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "737",
    "rarity_score": 513.0323647523984,
    "rarity_level": "RARE"
  },
  "906": {
    "name": "Arkade 90s Bulls #906",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/906.png",
    "dna": "bd88e34ae977953e94d25de2cc568e7cfe901416",
    "edition": 906,
    "date": 1672532680598,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "906",
    "rarity_score": 373.5348425430511,
    "rarity_level": "COMMON"
  },
  "1836": {
    "name": "Arkade 90s Bulls #1836",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1836.png",
    "dna": "96149c32e0ed2af4d082952481c458a1fc73ec68",
    "edition": 1836,
    "date": 1672532681058,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1836",
    "rarity_score": 115.3634630869783,
    "rarity_level": "COMMON"
  },
  "1790": {
    "name": "Arkade 90s Bulls #1790",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1790.png",
    "dna": "056fc92bbd2b8f46c5bc838ebb8e2ee0e284856a",
    "edition": 1790,
    "date": 1672532682127,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1790",
    "rarity_score": 283.19184478062357,
    "rarity_level": "COMMON"
  },
  "1901": {
    "name": "Arkade 90s Bulls #1901",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1901.png",
    "dna": "196da2bfcd72ec109429ada237abb6065b63aeb7",
    "edition": 1901,
    "date": 1672532683297,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1901",
    "rarity_score": 175.86578567430817,
    "rarity_level": "COMMON"
  },
  "582": {
    "name": "Arkade 90s Bulls #582",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/582.png",
    "dna": "17e2ee8d6de843725fc979604ae59ce705bee941",
    "edition": 582,
    "date": 1672532683771,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "582",
    "rarity_score": 273.00743053960423,
    "rarity_level": "COMMON"
  },
  "1664": {
    "name": "Arkade 90s Bulls #1664",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1664.png",
    "dna": "4e392fcd5d299776881adff67fefc77553e980bb",
    "edition": 1664,
    "date": 1672532684239,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1664",
    "rarity_score": 121.47762340682124,
    "rarity_level": "COMMON"
  },
  "1603": {
    "name": "Arkade 90s Bulls #1603",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1603.png",
    "dna": "f4145849d87f6895814a6602d83831c8ca3de87f",
    "edition": 1603,
    "date": 1672532684683,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1603",
    "rarity_score": 281.3178967677237,
    "rarity_level": "COMMON"
  },
  "624": {
    "name": "Arkade 90s Bulls #624",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/624.png",
    "dna": "3640d6214c6f85636641ffe8cf83210e99d28539",
    "edition": 624,
    "date": 1672532685172,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "624",
    "rarity_score": 236.95776862723852,
    "rarity_level": "COMMON"
  },
  "1110": {
    "name": "Arkade 90s Bulls #1110",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1110.png",
    "dna": "be1ab86c12c0e32898f1ee1934ee6000cc291555",
    "edition": 1110,
    "date": 1672532686124,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1110",
    "rarity_score": 443.2315650961272,
    "rarity_level": "COMMON"
  },
  "259": {
    "name": "Arkade 90s Bulls #259",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/259.png",
    "dna": "479bf12ed8d41947e1fcc9edc259b54c345c9fb1",
    "edition": 259,
    "date": 1672532686586,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "259",
    "rarity_score": 219.5967978811475,
    "rarity_level": "COMMON"
  },
  "47": {
    "name": "Arkade 90s Bulls #47",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/47.png",
    "dna": "56d9b7ae6d80c3c8228b033bd8e7ecc85dd0fc65",
    "edition": 47,
    "date": 1672532687728,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "47",
    "rarity_score": 281.33587583527174,
    "rarity_level": "COMMON"
  },
  "186": {
    "name": "Arkade 90s Bulls #186",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/186.png",
    "dna": "0b2d9d25cee3330989afa063c8f6ef7338330950",
    "edition": 186,
    "date": 1672532688243,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "186",
    "rarity_score": 144.17277487654897,
    "rarity_level": "COMMON"
  },
  "1692": {
    "name": "Arkade 90s Bulls #1692",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1692.png",
    "dna": "2ef727c6ea834316e6cb74bd8187552ee6e372e4",
    "edition": 1692,
    "date": 1672532688709,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1692",
    "rarity_score": 210.2960553288093,
    "rarity_level": "COMMON"
  },
  "1337": {
    "name": "Arkade 90s Bulls #1337",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1337.png",
    "dna": "05372637e6078d575dbccad04eec766a4ee95e47",
    "edition": 1337,
    "date": 1672532689196,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1337",
    "rarity_score": 307.2791209132839,
    "rarity_level": "COMMON"
  },
  "617": {
    "name": "Arkade 90s Bulls #617",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/617.png",
    "dna": "8aa88f00c36076b66869478987d5ca2e448d21dc",
    "edition": 617,
    "date": 1672532689636,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "617",
    "rarity_score": 419.53857192589885,
    "rarity_level": "COMMON"
  },
  "1542": {
    "name": "Arkade 90s Bulls #1542",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1542.png",
    "dna": "c6dd063b7421db801b3d05ec345c794fd284b0f9",
    "edition": 1542,
    "date": 1672532690379,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1542",
    "rarity_score": 278.0971159801218,
    "rarity_level": "COMMON"
  },
  "1893": {
    "name": "Arkade 90s Bulls #1893",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1893.png",
    "dna": "01dd465cd3949e9776b12a8c130dca31b84249f4",
    "edition": 1893,
    "date": 1672532690984,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1893",
    "rarity_score": 171.95665355012682,
    "rarity_level": "COMMON"
  },
  "1222": {
    "name": "Arkade 90s Bulls #1222",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1222.png",
    "dna": "698ecd45f715a51236d92be65e939ad9b57d63e9",
    "edition": 1222,
    "date": 1672532691491,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1222",
    "rarity_score": 271.9935863328705,
    "rarity_level": "COMMON"
  },
  "446": {
    "name": "Arkade 90s Bulls #446",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/446.png",
    "dna": "19e3dd332cd56ffcbaeb15d4ec7b5eae895470f6",
    "edition": 446,
    "date": 1672532691946,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "446",
    "rarity_score": 113.12310739022762,
    "rarity_level": "COMMON"
  },
  "478": {
    "name": "Arkade 90s Bulls #478",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/478.png",
    "dna": "421bec264633c6d1f5b242e1077222d1c28d6680",
    "edition": 478,
    "date": 1672532693036,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "478",
    "rarity_score": 464.0622845144718,
    "rarity_level": "RARE"
  },
  "1290": {
    "name": "Arkade 90s Bulls #1290",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1290.png",
    "dna": "6faebcb8ac8999a1d443d863d800672aa625eb08",
    "edition": 1290,
    "date": 1672532694220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1290",
    "rarity_score": 136.0412161352474,
    "rarity_level": "COMMON"
  },
  "1548": {
    "name": "Arkade 90s Bulls #1548",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1548.png",
    "dna": "589ca8e45133a1787c671faa813910f161d44854",
    "edition": 1548,
    "date": 1672532694925,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1548",
    "rarity_score": 240.8191421908157,
    "rarity_level": "COMMON"
  },
  "472": {
    "name": "Arkade 90s Bulls #472",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/472.png",
    "dna": "31a515a42699a3612b6b79fb01680ad1c7f214be",
    "edition": 472,
    "date": 1672532695376,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "472",
    "rarity_score": 106.93347317357139,
    "rarity_level": "COMMON"
  },
  "28": {
    "name": "Arkade 90s Bulls #28",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/28.png",
    "dna": "7017fe0f57a84d98c404629125c03aafdac2c06d",
    "edition": 28,
    "date": 1672532696386,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "28",
    "rarity_score": 307.1018018593823,
    "rarity_level": "COMMON"
  },
  "25": {
    "name": "Arkade 90s Bulls #25",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/25.png",
    "dna": "68d4b43e71aaa29729d6d038684f292708b4d270",
    "edition": 25,
    "date": 1672532696841,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "25",
    "rarity_score": 155.72554502670457,
    "rarity_level": "COMMON"
  },
  "1684": {
    "name": "Arkade 90s Bulls #1684",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1684.png",
    "dna": "0f0c4c8dbcff037c21058e177b36012664fca763",
    "edition": 1684,
    "date": 1672532697301,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1684",
    "rarity_score": 193.4272101494534,
    "rarity_level": "COMMON"
  },
  "1348": {
    "name": "Arkade 90s Bulls #1348",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1348.png",
    "dna": "c76f2b72f8c8f25b77185ac515d0a54a3e5d097c",
    "edition": 1348,
    "date": 1672532697821,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1348",
    "rarity_score": 335.2645716236594,
    "rarity_level": "COMMON"
  },
  "1611": {
    "name": "Arkade 90s Bulls #1611",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1611.png",
    "dna": "d0a3a1984dde79e9d56a86f70eb41b6731c24e86",
    "edition": 1611,
    "date": 1672532698365,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1611",
    "rarity_score": 391.5375391153944,
    "rarity_level": "COMMON"
  },
  "1861": {
    "name": "Arkade 90s Bulls #1861",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1861.png",
    "dna": "78a9e1bc3cdcd052acbc09e88edab9939eaf0dfc",
    "edition": 1861,
    "date": 1672532698868,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1861",
    "rarity_score": 216.8998552312326,
    "rarity_level": "COMMON"
  },
  "937": {
    "name": "Arkade 90s Bulls #937",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/937.png",
    "dna": "b3d68d92089c23ba085d9153ef210f69e55a6756",
    "edition": 937,
    "date": 1672532699574,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "937",
    "rarity_score": 179.3400739641127,
    "rarity_level": "COMMON"
  },
  "160": {
    "name": "Arkade 90s Bulls #160",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/160.png",
    "dna": "f66a531127f2c47012be4b04b7884c2a9c9d4f5f",
    "edition": 160,
    "date": 1672532700688,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "160",
    "rarity_score": 548.3234239856769,
    "rarity_level": "RARE"
  },
  "702": {
    "name": "Arkade 90s Bulls #702",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/702.png",
    "dna": "5c23f30e43ad2d3b2f145c0aa8edf6fe440feb22",
    "edition": 702,
    "date": 1672532701209,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "702",
    "rarity_score": 449.95691304326243,
    "rarity_level": "COMMON"
  },
  "943": {
    "name": "Arkade 90s Bulls #943",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/943.png",
    "dna": "c651657a2d8b1cf1d593d411f0cf6922da8dbcdc",
    "edition": 943,
    "date": 1672532701732,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "943",
    "rarity_score": 298.1497335424968,
    "rarity_level": "COMMON"
  },
  "841": {
    "name": "Arkade 90s Bulls #841",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/841.png",
    "dna": "80e40f211f901ffe16ff3da5b08b4a7c15290f18",
    "edition": 841,
    "date": 1672532702355,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "841",
    "rarity_score": 309.8760979149493,
    "rarity_level": "COMMON"
  },
  "122": {
    "name": "Arkade 90s Bulls #122",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/122.png",
    "dna": "7cf394e6294223d45d2b3a310e6f2ecc1931e3ba",
    "edition": 122,
    "date": 1672532703536,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "122",
    "rarity_score": 391.23645516570537,
    "rarity_level": "COMMON"
  },
  "1013": {
    "name": "Arkade 90s Bulls #1013",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1013.png",
    "dna": "5b86426036efc98dcd3229fea14d0f81a520e821",
    "edition": 1013,
    "date": 1672532704118,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1013",
    "rarity_score": 274.16567564828006,
    "rarity_level": "COMMON"
  },
  "1658": {
    "name": "Arkade 90s Bulls #1658",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1658.png",
    "dna": "b9c810625130773a20b657748e2e13c7672a1e77",
    "edition": 1658,
    "date": 1672532704713,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1658",
    "rarity_score": 233.34690359085704,
    "rarity_level": "COMMON"
  },
  "990": {
    "name": "Arkade 90s Bulls #990",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/990.png",
    "dna": "baefe34481d8c762cb67c1257215484f272adb7e",
    "edition": 990,
    "date": 1672532705198,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "990",
    "rarity_score": 255.88249491532744,
    "rarity_level": "COMMON"
  },
  "1922": {
    "name": "Arkade 90s Bulls #1922",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1922.png",
    "dna": "9554521710ba0e520f44659395483ab2abd72a57",
    "edition": 1922,
    "date": 1672532706366,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1922",
    "rarity_score": 135.06261819963555,
    "rarity_level": "COMMON"
  },
  "1510": {
    "name": "Arkade 90s Bulls #1510",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1510.png",
    "dna": "36904c29b8f18739383d45f588db92a4aad7a40e",
    "edition": 1510,
    "date": 1672532706882,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1510",
    "rarity_score": 281.7477832677866,
    "rarity_level": "COMMON"
  },
  "961": {
    "name": "Arkade 90s Bulls #961",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/961.png",
    "dna": "525f85aaf44fa743ca1c5d0bad45f78019aa275b",
    "edition": 961,
    "date": 1672532707401,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "961",
    "rarity_score": 491.23776063217656,
    "rarity_level": "RARE"
  },
  "946": {
    "name": "Arkade 90s Bulls #946",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/946.png",
    "dna": "8506ba18ba049cdc52fb1927e05c87d6e227f42b",
    "edition": 946,
    "date": 1672532707876,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "946",
    "rarity_score": 249.75751276209635,
    "rarity_level": "COMMON"
  },
  "607": {
    "name": "Arkade 90s Bulls #607",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/607.png",
    "dna": "3d624b4d77b8a66f5b96550292bdf966fa977826",
    "edition": 607,
    "date": 1672532708609,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "607",
    "rarity_score": 197.64368048238944,
    "rarity_level": "COMMON"
  },
  "793": {
    "name": "Arkade 90s Bulls #793",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/793.png",
    "dna": "112bc0f044b71cc0d94deb7579403905744ffd0f",
    "edition": 793,
    "date": 1672532709090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "793",
    "rarity_score": 192.55354884354614,
    "rarity_level": "COMMON"
  },
  "392": {
    "name": "Arkade 90s Bulls #392",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/392.png",
    "dna": "27ab51172dd862da8c40f8f44ad4e26d33e1de56",
    "edition": 392,
    "date": 1672532710229,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "392",
    "rarity_score": 362.61972907795933,
    "rarity_level": "COMMON"
  },
  "1033": {
    "name": "Arkade 90s Bulls #1033",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1033.png",
    "dna": "2837389aa8ba7886f47097b536efbea5231270f2",
    "edition": 1033,
    "date": 1672532710829,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1033",
    "rarity_score": 280.07302412534983,
    "rarity_level": "COMMON"
  },
  "471": {
    "name": "Arkade 90s Bulls #471",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/471.png",
    "dna": "4d71114cb1bcb9b92eb16643cfeef2f611c325c9",
    "edition": 471,
    "date": 1672532711330,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "471",
    "rarity_score": 289.3851952743313,
    "rarity_level": "COMMON"
  },
  "246": {
    "name": "Arkade 90s Bulls #246",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/246.png",
    "dna": "48677580704b3b113caef6e62e363572492af87c",
    "edition": 246,
    "date": 1672532711808,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "246",
    "rarity_score": 174.70138169368676,
    "rarity_level": "COMMON"
  },
  "1594": {
    "name": "Arkade 90s Bulls #1594",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1594.png",
    "dna": "e9379a663526ddee116a4d3d6040b61c5ce99ec3",
    "edition": 1594,
    "date": 1672532712266,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1594",
    "rarity_score": 226.46576147846392,
    "rarity_level": "COMMON"
  },
  "1205": {
    "name": "Arkade 90s Bulls #1205",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1205.png",
    "dna": "d82a4d7e98795ec93d18edac066906b5b0d79d1f",
    "edition": 1205,
    "date": 1672532712784,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1205",
    "rarity_score": 133.88486057291112,
    "rarity_level": "COMMON"
  },
  "881": {
    "name": "Arkade 90s Bulls #881",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/881.png",
    "dna": "e6fef182f7a430ac2702f9636f047fee84a90f2f",
    "edition": 881,
    "date": 1672532713267,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "881",
    "rarity_score": 187.1282256304858,
    "rarity_level": "COMMON"
  },
  "739": {
    "name": "Arkade 90s Bulls #739",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/739.png",
    "dna": "7da9f789283b4b79f9acc17c0970380862293fb5",
    "edition": 739,
    "date": 1672532713816,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "739",
    "rarity_score": 179.263219616501,
    "rarity_level": "COMMON"
  },
  "1569": {
    "name": "Arkade 90s Bulls #1569",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1569.png",
    "dna": "9edc5eeb4821020e02507f92b29cb4779ef2eea7",
    "edition": 1569,
    "date": 1672532714312,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1569",
    "rarity_score": 101.87778261452176,
    "rarity_level": "COMMON"
  },
  "1990": {
    "name": "Arkade 90s Bulls #1990",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1990.png",
    "dna": "b0318dab0936c3938462a5032193465454832077",
    "edition": 1990,
    "date": 1672532714807,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1990",
    "rarity_score": 285.7933663596545,
    "rarity_level": "COMMON"
  },
  "1727": {
    "name": "Arkade 90s Bulls #1727",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1727.png",
    "dna": "193bcbb28d218beb0acf781edb48bf1a67b3043a",
    "edition": 1727,
    "date": 1672532715291,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1727",
    "rarity_score": 205.82952153183052,
    "rarity_level": "COMMON"
  },
  "1243": {
    "name": "Arkade 90s Bulls #1243",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1243.png",
    "dna": "bc362f2320c925c0a8523dc624a9fdd9eb1100ef",
    "edition": 1243,
    "date": 1672532715738,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1243",
    "rarity_score": 160.04867949808275,
    "rarity_level": "COMMON"
  },
  "1162": {
    "name": "Arkade 90s Bulls #1162",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1162.png",
    "dna": "4a463310559deba548f84e9b0dbb1fce2501c0f0",
    "edition": 1162,
    "date": 1672532716220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1162",
    "rarity_score": 346.05115037535444,
    "rarity_level": "COMMON"
  },
  "758": {
    "name": "Arkade 90s Bulls #758",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/758.png",
    "dna": "6d236ebdeb37e9c2693c10c02fa0909d9de4beff",
    "edition": 758,
    "date": 1672532716790,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "758",
    "rarity_score": 146.91993276899873,
    "rarity_level": "COMMON"
  },
  "964": {
    "name": "Arkade 90s Bulls #964",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/964.png",
    "dna": "58c603cdfa16d9c23a67eb2aba88521dc87bb2a0",
    "edition": 964,
    "date": 1672532717286,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "964",
    "rarity_score": 219.23472476268648,
    "rarity_level": "COMMON"
  },
  "1315": {
    "name": "Arkade 90s Bulls #1315",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1315.png",
    "dna": "2431b98a47e7ef6fffc20f6e98934d8ea3c25fbe",
    "edition": 1315,
    "date": 1672532717816,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1315",
    "rarity_score": 286.8794273509066,
    "rarity_level": "COMMON"
  },
  "1240": {
    "name": "Arkade 90s Bulls #1240",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1240.png",
    "dna": "353147c2fbcc218716c440ed91030edbcffadfee",
    "edition": 1240,
    "date": 1672532718440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1240",
    "rarity_score": 465.65673687146165,
    "rarity_level": "RARE"
  },
  "1863": {
    "name": "Arkade 90s Bulls #1863",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1863.png",
    "dna": "c59aa1ad5c1e1c91b25f8d9b46ab074769193f5c",
    "edition": 1863,
    "date": 1672532718932,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1863",
    "rarity_score": 215.112740923888,
    "rarity_level": "COMMON"
  },
  "342": {
    "name": "Arkade 90s Bulls #342",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/342.png",
    "dna": "6d842b06ff27321f7d26f488c11acc45d1ffc66a",
    "edition": 342,
    "date": 1672532720248,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "342",
    "rarity_score": 523.556292771535,
    "rarity_level": "RARE"
  },
  "1948": {
    "name": "Arkade 90s Bulls #1948",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1948.png",
    "dna": "24362be8fb0af16bfc76be29b235a4479b45ee23",
    "edition": 1948,
    "date": 1672532720727,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1948",
    "rarity_score": 331.156934671091,
    "rarity_level": "COMMON"
  },
  "1877": {
    "name": "Arkade 90s Bulls #1877",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1877.png",
    "dna": "30bc9b5e37c74a63e82087839d97a15ac7f26b52",
    "edition": 1877,
    "date": 1672532721255,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1877",
    "rarity_score": 181.5604349936048,
    "rarity_level": "COMMON"
  },
  "931": {
    "name": "Arkade 90s Bulls #931",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/931.png",
    "dna": "9a5cb6ad83f41fa16a0179879ee904426237ef1e",
    "edition": 931,
    "date": 1672532722247,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "931",
    "rarity_score": 460.57493482608476,
    "rarity_level": "RARE"
  },
  "17": {
    "name": "Arkade 90s Bulls #17",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/17.png",
    "dna": "05cbb9982b369b7dcd14cc646d9df359ae19cb32",
    "edition": 17,
    "date": 1672532722729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "17",
    "rarity_score": 327.6137714796131,
    "rarity_level": "COMMON"
  },
  "1868": {
    "name": "Arkade 90s Bulls #1868",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1868.png",
    "dna": "8e05898f0c1607ae5244063f2f817477d31b51cc",
    "edition": 1868,
    "date": 1672532723922,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1868",
    "rarity_score": 216.5619061089724,
    "rarity_level": "COMMON"
  },
  "1573": {
    "name": "Arkade 90s Bulls #1573",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1573.png",
    "dna": "9e54aa9b0a757cc57eff81bc023da5f7ea7ab96e",
    "edition": 1573,
    "date": 1672532725182,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1573",
    "rarity_score": 449.5631191856938,
    "rarity_level": "COMMON"
  },
  "247": {
    "name": "Arkade 90s Bulls #247",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/247.png",
    "dna": "9059f4df72602323869253344cff8d353117b5b7",
    "edition": 247,
    "date": 1672532725686,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "247",
    "rarity_score": 384.81608451159036,
    "rarity_level": "COMMON"
  },
  "462": {
    "name": "Arkade 90s Bulls #462",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/462.png",
    "dna": "547290feaa64d93cc901bbc7ee8d54c4a6900373",
    "edition": 462,
    "date": 1672532726173,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "462",
    "rarity_score": 428.9260529136734,
    "rarity_level": "COMMON"
  },
  "198": {
    "name": "Arkade 90s Bulls #198",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/198.png",
    "dna": "f03424ee196263bf67a271bf271d952b369500bc",
    "edition": 198,
    "date": 1672532726654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "198",
    "rarity_score": 368.1817926305428,
    "rarity_level": "COMMON"
  },
  "693": {
    "name": "Arkade 90s Bulls #693",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/693.png",
    "dna": "0d3ac992488a12bfb34ac71bb41dccbafe4a9bb5",
    "edition": 693,
    "date": 1672532727189,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "693",
    "rarity_score": 344.20286094604853,
    "rarity_level": "COMMON"
  },
  "810": {
    "name": "Arkade 90s Bulls #810",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/810.png",
    "dna": "39965722f951bec1846748b5caf9ed893f79b05c",
    "edition": 810,
    "date": 1672532728375,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "810",
    "rarity_score": 477.31017379860504,
    "rarity_level": "RARE"
  },
  "1934": {
    "name": "Arkade 90s Bulls #1934",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1934.png",
    "dna": "282e179fa72dc4c11cf45e589bdabf249af9061b",
    "edition": 1934,
    "date": 1672532728948,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1934",
    "rarity_score": 199.12437844649335,
    "rarity_level": "COMMON"
  },
  "1788": {
    "name": "Arkade 90s Bulls #1788",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1788.png",
    "dna": "1d9f12a473820700d48e9745be1f0f5f3426793e",
    "edition": 1788,
    "date": 1672532729417,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1788",
    "rarity_score": 317.144485975947,
    "rarity_level": "COMMON"
  },
  "1821": {
    "name": "Arkade 90s Bulls #1821",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1821.png",
    "dna": "9b7d7d9acdbed1320119403748527cec325c154e",
    "edition": 1821,
    "date": 1672532730127,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1821",
    "rarity_score": 311.64126717341094,
    "rarity_level": "COMMON"
  },
  "1628": {
    "name": "Arkade 90s Bulls #1628",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1628.png",
    "dna": "7b984c1a14304295f3c6b4d1974cd8e57f639c29",
    "edition": 1628,
    "date": 1672532730569,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1628",
    "rarity_score": 112.0639619419496,
    "rarity_level": "COMMON"
  },
  "576": {
    "name": "Arkade 90s Bulls #576",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/576.png",
    "dna": "2b78a9fda177d34a95966e0710bd47c95d30f9b7",
    "edition": 576,
    "date": 1672532731746,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "576",
    "rarity_score": 439.60793594307626,
    "rarity_level": "COMMON"
  },
  "800": {
    "name": "Arkade 90s Bulls #800",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/800.png",
    "dna": "0089a3cee2b0455fa58d85ab676dfb858d380e34",
    "edition": 800,
    "date": 1672532732387,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "800",
    "rarity_score": 382.80581940778336,
    "rarity_level": "COMMON"
  },
  "270": {
    "name": "Arkade 90s Bulls #270",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/270.png",
    "dna": "e1cb5263f8b4679c40390d6f0f88709a3395494c",
    "edition": 270,
    "date": 1672532732906,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "270",
    "rarity_score": 273.21826990072645,
    "rarity_level": "COMMON"
  },
  "1669": {
    "name": "Arkade 90s Bulls #1669",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1669.png",
    "dna": "73faf43dda09c94cb0ed0da99ca7c43f1dcf860a",
    "edition": 1669,
    "date": 1672532733503,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1669",
    "rarity_score": 176.6471407348057,
    "rarity_level": "COMMON"
  },
  "1624": {
    "name": "Arkade 90s Bulls #1624",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1624.png",
    "dna": "32312b512bbb5291ac308b7699ebb8308bce9e8e",
    "edition": 1624,
    "date": 1672532734695,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1624",
    "rarity_score": 414.5677889513953,
    "rarity_level": "COMMON"
  },
  "1622": {
    "name": "Arkade 90s Bulls #1622",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1622.png",
    "dna": "f461376a4c826352614f96c82120a3681934c5ca",
    "edition": 1622,
    "date": 1672532735155,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1622",
    "rarity_score": 401.57816085533244,
    "rarity_level": "COMMON"
  },
  "413": {
    "name": "Arkade 90s Bulls #413",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/413.png",
    "dna": "dc56324e380eb5e499e422e54d3040d7fc288631",
    "edition": 413,
    "date": 1672532735607,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "413",
    "rarity_score": 228.96802548289037,
    "rarity_level": "COMMON"
  },
  "675": {
    "name": "Arkade 90s Bulls #675",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/675.png",
    "dna": "b4eea954420c0e958f29a56307cc440cd3223fc4",
    "edition": 675,
    "date": 1672532736806,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "675",
    "rarity_score": 326.9552287046246,
    "rarity_level": "COMMON"
  },
  "1831": {
    "name": "Arkade 90s Bulls #1831",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1831.png",
    "dna": "20d0cb6cdf0f4e8f4ecba1567343f64e2a9e0947",
    "edition": 1831,
    "date": 1672532737294,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1831",
    "rarity_score": 365.3787536334501,
    "rarity_level": "COMMON"
  },
  "1980": {
    "name": "Arkade 90s Bulls #1980",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1980.png",
    "dna": "4437d64dd56e5b9ceb7bd7a646f5099883404a34",
    "edition": 1980,
    "date": 1672532737769,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1980",
    "rarity_score": 240.76495047419826,
    "rarity_level": "COMMON"
  },
  "577": {
    "name": "Arkade 90s Bulls #577",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/577.png",
    "dna": "e2139efbd0fb32233072571fb22f3d982f6f585e",
    "edition": 577,
    "date": 1672532738254,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "577",
    "rarity_score": 406.82781257672525,
    "rarity_level": "COMMON"
  },
  "1285": {
    "name": "Arkade 90s Bulls #1285",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1285.png",
    "dna": "ce951fedde53913261882a9fdbdcc1071d03eb29",
    "edition": 1285,
    "date": 1672532738843,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1285",
    "rarity_score": 422.1433761755737,
    "rarity_level": "COMMON"
  },
  "1524": {
    "name": "Arkade 90s Bulls #1524",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1524.png",
    "dna": "36c5e58878839694a215c168e34669c79725946d",
    "edition": 1524,
    "date": 1672532739364,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1524",
    "rarity_score": 215.63606829734093,
    "rarity_level": "COMMON"
  },
  "564": {
    "name": "Arkade 90s Bulls #564",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/564.png",
    "dna": "f4a8d7eb85e77c9463a9f1f5c83ad48b83fede68",
    "edition": 564,
    "date": 1672532740492,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "564",
    "rarity_score": 397.0183784054032,
    "rarity_level": "COMMON"
  },
  "451": {
    "name": "Arkade 90s Bulls #451",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/451.png",
    "dna": "f895775ed68af6629c2f772ed5f92664126530dd",
    "edition": 451,
    "date": 1672532741174,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "451",
    "rarity_score": 219.29700122197391,
    "rarity_level": "COMMON"
  },
  "1659": {
    "name": "Arkade 90s Bulls #1659",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1659.png",
    "dna": "d9366e84863aafc5fd748e30057caff950bf79db",
    "edition": 1659,
    "date": 1672532742387,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1659",
    "rarity_score": 296.4371487012214,
    "rarity_level": "COMMON"
  },
  "591": {
    "name": "Arkade 90s Bulls #591",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/591.png",
    "dna": "aa0b41be33d5d16c2438d13987e8a5bec06cd39b",
    "edition": 591,
    "date": 1672532742876,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "591",
    "rarity_score": 299.72857609126675,
    "rarity_level": "COMMON"
  },
  "1397": {
    "name": "Arkade 90s Bulls #1397",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1397.png",
    "dna": "d164e442e95b8f071296a2dabb3abd0c81a9bc84",
    "edition": 1397,
    "date": 1672532743440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1397",
    "rarity_score": 501.5102202275627,
    "rarity_level": "RARE"
  },
  "1479": {
    "name": "Arkade 90s Bulls #1479",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1479.png",
    "dna": "bc262e831d5651dffa6c4d0a1ebca5e796c2089f",
    "edition": 1479,
    "date": 1672532743924,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1479",
    "rarity_score": 297.1417765463268,
    "rarity_level": "COMMON"
  },
  "1570": {
    "name": "Arkade 90s Bulls #1570",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1570.png",
    "dna": "79a727dbed93e3f1c75c85659c0c3fcb06e97782",
    "edition": 1570,
    "date": 1672532744382,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1570",
    "rarity_score": 254.87236824054034,
    "rarity_level": "COMMON"
  },
  "236": {
    "name": "Arkade 90s Bulls #236",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/236.png",
    "dna": "336a4911a81b3f23f9c13f6e43dac3225790eefd",
    "edition": 236,
    "date": 1672532744839,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "236",
    "rarity_score": 296.22648892039075,
    "rarity_level": "COMMON"
  },
  "1473": {
    "name": "Arkade 90s Bulls #1473",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1473.png",
    "dna": "f04feb5118d74136b588acd5a5dac58349e48cd6",
    "edition": 1473,
    "date": 1672532745315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1473",
    "rarity_score": 206.3643444976431,
    "rarity_level": "COMMON"
  },
  "631": {
    "name": "Arkade 90s Bulls #631",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/631.png",
    "dna": "b096aa45c85dce6ed2baa5bea7ae03544af9ea42",
    "edition": 631,
    "date": 1672532745779,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "631",
    "rarity_score": 413.8844916917675,
    "rarity_level": "COMMON"
  },
  "156": {
    "name": "Arkade 90s Bulls #156",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/156.png",
    "dna": "419af051d6840ac42f411ef0fb04d974e4d43286",
    "edition": 156,
    "date": 1672532746237,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "156",
    "rarity_score": 185.02186851830538,
    "rarity_level": "COMMON"
  },
  "1806": {
    "name": "Arkade 90s Bulls #1806",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1806.png",
    "dna": "d1d8c7c63b2aed81db05dafa046f64f9fa930676",
    "edition": 1806,
    "date": 1672532746721,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1806",
    "rarity_score": 261.06531490174115,
    "rarity_level": "COMMON"
  },
  "1140": {
    "name": "Arkade 90s Bulls #1140",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1140.png",
    "dna": "d6a0b4510aa9becbd6bdc5186229ab368c82ee66",
    "edition": 1140,
    "date": 1672532747497,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      { "trait_type": "Head", "value": "Football Head", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1140",
    "rarity_score": 655.4142001685584,
    "rarity_level": "RARE"
  },
  "20": {
    "name": "Arkade 90s Bulls #20",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/20.png",
    "dna": "187d92dce9714fd33318f51bf7e3bf80e55afbea",
    "edition": 20,
    "date": 1672532747985,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "20",
    "rarity_score": 255.1637884343743,
    "rarity_level": "COMMON"
  },
  "1843": {
    "name": "Arkade 90s Bulls #1843",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1843.png",
    "dna": "269291c8ba2d59efb58a890d6ec4b734e480ebc8",
    "edition": 1843,
    "date": 1672532749279,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1843",
    "rarity_score": 309.199290234913,
    "rarity_level": "COMMON"
  },
  "1741": {
    "name": "Arkade 90s Bulls #1741",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1741.png",
    "dna": "858c9ea89d20f721a7b896bdf23cc5d90790f981",
    "edition": 1741,
    "date": 1672532749805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1741",
    "rarity_score": 342.14826109791426,
    "rarity_level": "COMMON"
  },
  "1328": {
    "name": "Arkade 90s Bulls #1328",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1328.png",
    "dna": "cd876495c0782b0a814e11cc6d7a4dd8b509fb78",
    "edition": 1328,
    "date": 1672532750506,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1328",
    "rarity_score": 168.62899668817022,
    "rarity_level": "COMMON"
  },
  "561": {
    "name": "Arkade 90s Bulls #561",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/561.png",
    "dna": "c749e2ec629c4aed2609b2cc00c3e7024538f51a",
    "edition": 561,
    "date": 1672532750992,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "561",
    "rarity_score": 194.49883549825438,
    "rarity_level": "COMMON"
  },
  "1930": {
    "name": "Arkade 90s Bulls #1930",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1930.png",
    "dna": "39ddb11841281c0c4c7a9bf577fce51166658712",
    "edition": 1930,
    "date": 1672532751549,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1930",
    "rarity_score": 376.10004764438867,
    "rarity_level": "COMMON"
  },
  "502": {
    "name": "Arkade 90s Bulls #502",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/502.png",
    "dna": "c18c78157796a1dd195d0c5fe443a143dc4f0434",
    "edition": 502,
    "date": 1672532752778,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "502",
    "rarity_score": 284.16493919162014,
    "rarity_level": "COMMON"
  },
  "648": {
    "name": "Arkade 90s Bulls #648",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/648.png",
    "dna": "4ddc67a2ded6b9f9a06d180ea643664c2020aa0a",
    "edition": 648,
    "date": 1672532753261,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Sad",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      { "trait_type": "Head", "value": "Okko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "648",
    "rarity_score": 594.3652181187366,
    "rarity_level": "RARE"
  },
  "119": {
    "name": "Arkade 90s Bulls #119",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/119.png",
    "dna": "669439b2fee6f970aadc668196dcacf97e93a4d3",
    "edition": 119,
    "date": 1672532753730,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "119",
    "rarity_score": 323.76972328993844,
    "rarity_level": "COMMON"
  },
  "1008": {
    "name": "Arkade 90s Bulls #1008",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1008.png",
    "dna": "aadf4d3e504047dd6527c3e99514b9782aefd9ea",
    "edition": 1008,
    "date": 1672532754214,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1008",
    "rarity_score": 319.02512688933706,
    "rarity_level": "COMMON"
  },
  "628": {
    "name": "Arkade 90s Bulls #628",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/628.png",
    "dna": "c7d0054b7b4205b88ad149d232f537850f2a9a65",
    "edition": 628,
    "date": 1672532755428,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "628",
    "rarity_score": 255.63278989007924,
    "rarity_level": "COMMON"
  },
  "1898": {
    "name": "Arkade 90s Bulls #1898",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1898.png",
    "dna": "bb5e0a7637512b916c163da6127cae136661bbaf",
    "edition": 1898,
    "date": 1672532756006,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1898",
    "rarity_score": 506.8915280872317,
    "rarity_level": "RARE"
  },
  "1961": {
    "name": "Arkade 90s Bulls #1961",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1961.png",
    "dna": "238dd2de8f7a8752e07e8c733955f5af6268b07e",
    "edition": 1961,
    "date": 1672532756553,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1961",
    "rarity_score": 379.6528579413342,
    "rarity_level": "COMMON"
  },
  "368": {
    "name": "Arkade 90s Bulls #368",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/368.png",
    "dna": "926b1297331e7976edcdc86987b50e0795cb837d",
    "edition": 368,
    "date": 1672532757043,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "368",
    "rarity_score": 447.9150084230593,
    "rarity_level": "COMMON"
  },
  "1558": {
    "name": "Arkade 90s Bulls #1558",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1558.png",
    "dna": "7af5e36228b958ca958c1f2c5a2bd49af43d8a35",
    "edition": 1558,
    "date": 1672532757531,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1558",
    "rarity_score": 153.73499096384876,
    "rarity_level": "COMMON"
  },
  "954": {
    "name": "Arkade 90s Bulls #954",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/954.png",
    "dna": "385505c33e62c5b02cd72c7ae91e566a66b5da86",
    "edition": 954,
    "date": 1672532758004,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "954",
    "rarity_score": 200.58453570636644,
    "rarity_level": "COMMON"
  },
  "812": {
    "name": "Arkade 90s Bulls #812",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/812.png",
    "dna": "07ecae39bbc9f94c8c3092c82f9ffbd185c74b24",
    "edition": 812,
    "date": 1672532759379,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "812",
    "rarity_score": 354.3981968080562,
    "rarity_level": "COMMON"
  },
  "936": {
    "name": "Arkade 90s Bulls #936",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/936.png",
    "dna": "061e676b4fe2a541921edf01dec406edfad98b02",
    "edition": 936,
    "date": 1672532759999,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Evil",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "936",
    "rarity_score": 365.1362831521089,
    "rarity_level": "COMMON"
  },
  "323": {
    "name": "Arkade 90s Bulls #323",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/323.png",
    "dna": "9bd4420138684a08b6d379ce0487aeb92099e60c",
    "edition": 323,
    "date": 1672532760756,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Smile",
        "score": 34.310344827586206
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "323",
    "rarity_score": 230.37974434980802,
    "rarity_level": "COMMON"
  },
  "503": {
    "name": "Arkade 90s Bulls #503",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/503.png",
    "dna": "8bf500734e8bcbcc0aaea42e988141679b3b1a43",
    "edition": 503,
    "date": 1672532761959,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Angry",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "503",
    "rarity_score": 329.4523157414716,
    "rarity_level": "COMMON"
  },
  "1219": {
    "name": "Arkade 90s Bulls #1219",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1219.png",
    "dna": "f13bff58e495dfbd6d00ca04631a23eba3509a49",
    "edition": 1219,
    "date": 1672532763389,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Black Mustache",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1219",
    "rarity_score": 422.9289414467858,
    "rarity_level": "COMMON"
  },
  "540": {
    "name": "Arkade 90s Bulls #540",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/540.png",
    "dna": "a7da274893f04df4cd1cf3d4b23dbcfef5f476b2",
    "edition": 540,
    "date": 1672532764154,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Black Happy",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "540",
    "rarity_score": 405.2778494833187,
    "rarity_level": "COMMON"
  },
  "1296": {
    "name": "Arkade 90s Bulls #1296",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1296.png",
    "dna": "7d77c345d322f30cccb338e94f4318544ea85208",
    "edition": 1296,
    "date": 1672532764628,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1296",
    "rarity_score": 141.11003656270972,
    "rarity_level": "COMMON"
  },
  "699": {
    "name": "Arkade 90s Bulls #699",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/699.png",
    "dna": "636d3fe3edd87133cbdc883449c925e7f3325dd7",
    "edition": 699,
    "date": 1672532765122,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "699",
    "rarity_score": 370.4430231081092,
    "rarity_level": "COMMON"
  },
  "1869": {
    "name": "Arkade 90s Bulls #1869",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1869.png",
    "dna": "de278c34e18e20baf3d876588b7dac9f6476beb2",
    "edition": 1869,
    "date": 1672532765584,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1869",
    "rarity_score": 141.18270709863566,
    "rarity_level": "COMMON"
  },
  "1758": {
    "name": "Arkade 90s Bulls #1758",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1758.png",
    "dna": "bb8c7b178e5b8f59bf0ebc49cb506c1ba6dbc41f",
    "edition": 1758,
    "date": 1672532766149,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1758",
    "rarity_score": 280.7594321966312,
    "rarity_level": "COMMON"
  },
  "1207": {
    "name": "Arkade 90s Bulls #1207",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1207.png",
    "dna": "5adc8a87b55a7c521a79df66d60450ea00acb9a4",
    "edition": 1207,
    "date": 1672532766615,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1207",
    "rarity_score": 145.6836083138226,
    "rarity_level": "COMMON"
  },
  "1943": {
    "name": "Arkade 90s Bulls #1943",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1943.png",
    "dna": "237fafc844044fbbd01a68aa11a895353f3b3a41",
    "edition": 1943,
    "date": 1672532767099,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1943",
    "rarity_score": 165.62302152963872,
    "rarity_level": "COMMON"
  },
  "1217": {
    "name": "Arkade 90s Bulls #1217",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1217.png",
    "dna": "2de87c7679aaa438d635c1b882978e7351f32c8e",
    "edition": 1217,
    "date": 1672532767591,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1217",
    "rarity_score": 426.65972655029145,
    "rarity_level": "COMMON"
  },
  "1981": {
    "name": "Arkade 90s Bulls #1981",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1981.png",
    "dna": "a69e5940cf1c784241eb998e73c7b29d08737753",
    "edition": 1981,
    "date": 1672532768780,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1981",
    "rarity_score": 232.32604849785812,
    "rarity_level": "COMMON"
  },
  "1213": {
    "name": "Arkade 90s Bulls #1213",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1213.png",
    "dna": "c61cd155720a6ba1ab4fc3b9831e72f6ad9bd6f0",
    "edition": 1213,
    "date": 1672532769404,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1213",
    "rarity_score": 128.28172718008003,
    "rarity_level": "COMMON"
  },
  "1085": {
    "name": "Arkade 90s Bulls #1085",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1085.png",
    "dna": "7a3cb42bafecd004df018bb0863688edee8059f4",
    "edition": 1085,
    "date": 1672532769848,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1085",
    "rarity_score": 120.03441598381919,
    "rarity_level": "COMMON"
  },
  "1130": {
    "name": "Arkade 90s Bulls #1130",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1130.png",
    "dna": "2e096abed60c3e242d67ecdc0a9eca5cd74e020f",
    "edition": 1130,
    "date": 1672532770294,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1130",
    "rarity_score": 156.41692854331498,
    "rarity_level": "COMMON"
  },
  "1813": {
    "name": "Arkade 90s Bulls #1813",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1813.png",
    "dna": "594299150d053bd46d5cb9d0443a689bcb4c293a",
    "edition": 1813,
    "date": 1672532770808,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Spinner", "score": 398.0 },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1813",
    "rarity_score": 577.0025898018679,
    "rarity_level": "RARE"
  },
  "106": {
    "name": "Arkade 90s Bulls #106",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/106.png",
    "dna": "e18e68a151dd0dfa3addf65cce0b24d05d83bda2",
    "edition": 106,
    "date": 1672532771293,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "106",
    "rarity_score": 184.26695963266272,
    "rarity_level": "COMMON"
  },
  "625": {
    "name": "Arkade 90s Bulls #625",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/625.png",
    "dna": "2c016560e512a39242621b247ba984e4c00e9a97",
    "edition": 625,
    "date": 1672532771798,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "625",
    "rarity_score": 244.94690349625913,
    "rarity_level": "COMMON"
  },
  "1280": {
    "name": "Arkade 90s Bulls #1280",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1280.png",
    "dna": "6c3c62a63a1898d3406ef1d844e5979042f86aad",
    "edition": 1280,
    "date": 1672532773056,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1280",
    "rarity_score": 332.40303315104484,
    "rarity_level": "COMMON"
  },
  "845": {
    "name": "Arkade 90s Bulls #845",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/845.png",
    "dna": "92cc4ce7fcc0cb731b0d3a90ab36d8c63d30f45b",
    "edition": 845,
    "date": 1672532773607,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "845",
    "rarity_score": 409.37367196674745,
    "rarity_level": "COMMON"
  },
  "687": {
    "name": "Arkade 90s Bulls #687",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/687.png",
    "dna": "c4a72c62a487b032653ea66d14c7ab8010579f78",
    "edition": 687,
    "date": 1672532774064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "687",
    "rarity_score": 119.17646865701984,
    "rarity_level": "COMMON"
  },
  "1112": {
    "name": "Arkade 90s Bulls #1112",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1112.png",
    "dna": "469d8759200c5933542c69c17d1e78b3d1ad87e0",
    "edition": 1112,
    "date": 1672532774514,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1112",
    "rarity_score": 400.95341247886233,
    "rarity_level": "COMMON"
  },
  "295": {
    "name": "Arkade 90s Bulls #295",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/295.png",
    "dna": "a14553496234625efe9574fb2bd73ad93e264cf0",
    "edition": 295,
    "date": 1672532774993,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "295",
    "rarity_score": 303.6980358418097,
    "rarity_level": "COMMON"
  },
  "1423": {
    "name": "Arkade 90s Bulls #1423",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1423.png",
    "dna": "1acb2facb086727a40165d9c02574e8aeac45e59",
    "edition": 1423,
    "date": 1672532775437,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1423",
    "rarity_score": 172.16492147268048,
    "rarity_level": "COMMON"
  },
  "388": {
    "name": "Arkade 90s Bulls #388",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/388.png",
    "dna": "116302ac4ef8853a20f12ab283f05a2cc5c3cd03",
    "edition": 388,
    "date": 1672532776068,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "388",
    "rarity_score": 265.30019116873973,
    "rarity_level": "COMMON"
  },
  "578": {
    "name": "Arkade 90s Bulls #578",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/578.png",
    "dna": "70ebb4e087f5d3b2d31a156e2f6bdce47256acee",
    "edition": 578,
    "date": 1672532776523,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "578",
    "rarity_score": 177.65740625447842,
    "rarity_level": "COMMON"
  },
  "1515": {
    "name": "Arkade 90s Bulls #1515",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1515.png",
    "dna": "ef8f7587eabb4c34e2c6c9644d1d7ca3e9dfcd1d",
    "edition": 1515,
    "date": 1672532777060,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1515",
    "rarity_score": 220.40083414784965,
    "rarity_level": "COMMON"
  },
  "409": {
    "name": "Arkade 90s Bulls #409",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/409.png",
    "dna": "34b770c955d14e2bfe57071a04b9706f3b80d726",
    "edition": 409,
    "date": 1672532778268,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "409",
    "rarity_score": 301.25011487456624,
    "rarity_level": "COMMON"
  },
  "1518": {
    "name": "Arkade 90s Bulls #1518",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1518.png",
    "dna": "32afa90c9d26a3403643e8260f1287dd5c0ccff1",
    "edition": 1518,
    "date": 1672532778758,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1518",
    "rarity_score": 311.29747115193004,
    "rarity_level": "COMMON"
  },
  "1672": {
    "name": "Arkade 90s Bulls #1672",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1672.png",
    "dna": "392ebb6173bc1a3b1fd3a44cd69a4a613cb905ae",
    "edition": 1672,
    "date": 1672532779288,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1672",
    "rarity_score": 106.18934980112783,
    "rarity_level": "COMMON"
  },
  "886": {
    "name": "Arkade 90s Bulls #886",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/886.png",
    "dna": "a8786569a57528ebf2f79ebf2f8601674cfc50a1",
    "edition": 886,
    "date": 1672532779804,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "886",
    "rarity_score": 327.1099737517469,
    "rarity_level": "COMMON"
  },
  "1470": {
    "name": "Arkade 90s Bulls #1470",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1470.png",
    "dna": "157095460c91591f797337453332128b75bd8bbc",
    "edition": 1470,
    "date": 1672532780349,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1470",
    "rarity_score": 487.6329326998972,
    "rarity_level": "RARE"
  },
  "1614": {
    "name": "Arkade 90s Bulls #1614",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1614.png",
    "dna": "51e8cf074af8982a56ea20d57083dbad589677ce",
    "edition": 1614,
    "date": 1672532780820,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1614",
    "rarity_score": 249.92655347527003,
    "rarity_level": "COMMON"
  },
  "139": {
    "name": "Arkade 90s Bulls #139",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/139.png",
    "dna": "e769fe5ca90aa0f85c9d933a01541a58bc5a7d00",
    "edition": 139,
    "date": 1672532781283,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "139",
    "rarity_score": 139.0859776843008,
    "rarity_level": "COMMON"
  },
  "1333": {
    "name": "Arkade 90s Bulls #1333",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1333.png",
    "dna": "57678c334df2ff7b8af62adb22792e8b1c044a63",
    "edition": 1333,
    "date": 1672532781763,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1333",
    "rarity_score": 270.9938754587346,
    "rarity_level": "COMMON"
  },
  "975": {
    "name": "Arkade 90s Bulls #975",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/975.png",
    "dna": "111257c1d2f11c8e6cab353cee0092006966a895",
    "edition": 975,
    "date": 1672532782221,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "975",
    "rarity_score": 309.2153290426564,
    "rarity_level": "COMMON"
  },
  "1887": {
    "name": "Arkade 90s Bulls #1887",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1887.png",
    "dna": "f7de2fc91451e297cc49968e2b5efe91658b6bfe",
    "edition": 1887,
    "date": 1672532782702,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1887",
    "rarity_score": 212.05107281657342,
    "rarity_level": "COMMON"
  },
  "1345": {
    "name": "Arkade 90s Bulls #1345",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1345.png",
    "dna": "1affc3060ecce14095a28ca03b876e3ddc33e120",
    "edition": 1345,
    "date": 1672532783178,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1345",
    "rarity_score": 108.33827417426886,
    "rarity_level": "COMMON"
  },
  "1157": {
    "name": "Arkade 90s Bulls #1157",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1157.png",
    "dna": "2e19e456edb269c6a834613257af3bc4284480b8",
    "edition": 1157,
    "date": 1672532783650,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1157",
    "rarity_score": 160.4483752997393,
    "rarity_level": "COMMON"
  },
  "507": {
    "name": "Arkade 90s Bulls #507",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/507.png",
    "dna": "d7c3c09f1cc20dad5954b5255196be1d8a3f183f",
    "edition": 507,
    "date": 1672532784361,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "507",
    "rarity_score": 357.77125601523846,
    "rarity_level": "COMMON"
  },
  "1225": {
    "name": "Arkade 90s Bulls #1225",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1225.png",
    "dna": "f78db970c82d805955ff24c7613f5b85d70fa8cb",
    "edition": 1225,
    "date": 1672532784806,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1225",
    "rarity_score": 291.5353599074782,
    "rarity_level": "COMMON"
  },
  "1163": {
    "name": "Arkade 90s Bulls #1163",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1163.png",
    "dna": "d53d59a5e6b970a781f2ed9503777b086265c536",
    "edition": 1163,
    "date": 1672532785982,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1163",
    "rarity_score": 214.6827468644559,
    "rarity_level": "COMMON"
  },
  "1417": {
    "name": "Arkade 90s Bulls #1417",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1417.png",
    "dna": "33f811623cafc2b730fdf9efba410f47ebb1d583",
    "edition": 1417,
    "date": 1672532787170,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1417",
    "rarity_score": 313.1457063807824,
    "rarity_level": "COMMON"
  },
  "1083": {
    "name": "Arkade 90s Bulls #1083",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1083.png",
    "dna": "9f23859a841b796726ea50b8b7fc38b7e679c3e5",
    "edition": 1083,
    "date": 1672532787662,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1083",
    "rarity_score": 137.1369730849971,
    "rarity_level": "COMMON"
  },
  "98": {
    "name": "Arkade 90s Bulls #98",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/98.png",
    "dna": "72380e81c2cc21e48d1c5730ed196f45f9292585",
    "edition": 98,
    "date": 1672532788845,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "98",
    "rarity_score": 368.9172353467638,
    "rarity_level": "COMMON"
  },
  "637": {
    "name": "Arkade 90s Bulls #637",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/637.png",
    "dna": "3e1935a519c302c43fa15bbea866d6111c456e59",
    "edition": 637,
    "date": 1672532789308,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "637",
    "rarity_score": 257.0581404340629,
    "rarity_level": "COMMON"
  },
  "909": {
    "name": "Arkade 90s Bulls #909",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/909.png",
    "dna": "f9f1d48fd11f56122d63c44552ed5515b1c636d2",
    "edition": 909,
    "date": 1672532790491,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "909",
    "rarity_score": 200.66594404027103,
    "rarity_level": "COMMON"
  },
  "1702": {
    "name": "Arkade 90s Bulls #1702",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1702.png",
    "dna": "188d46a5540cdde81951343a3ce3fd77b3f4ee67",
    "edition": 1702,
    "date": 1672532791913,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1702",
    "rarity_score": 259.79122798646455,
    "rarity_level": "COMMON"
  },
  "1281": {
    "name": "Arkade 90s Bulls #1281",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1281.png",
    "dna": "261285d6d62473f161d12f9c305274e863352fa4",
    "edition": 1281,
    "date": 1672532792393,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1281",
    "rarity_score": 426.5722507922845,
    "rarity_level": "COMMON"
  },
  "1878": {
    "name": "Arkade 90s Bulls #1878",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1878.png",
    "dna": "679faebe3d5affc1ac23c755ed44093be5781d98",
    "edition": 1878,
    "date": 1672532792853,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1878",
    "rarity_score": 142.7456103715328,
    "rarity_level": "COMMON"
  },
  "1946": {
    "name": "Arkade 90s Bulls #1946",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1946.png",
    "dna": "8bd736765ab55ec0560f6c0a65f7d6abaa49e401",
    "edition": 1946,
    "date": 1672532793863,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      { "trait_type": "Mask", "value": "Robot Helmet", "score": 995.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1946",
    "rarity_score": 1165.6056111464802,
    "rarity_level": "EPIC"
  },
  "516": {
    "name": "Arkade 90s Bulls #516",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/516.png",
    "dna": "0f2d65334b4423576ade78cce72cc405832f665a",
    "edition": 516,
    "date": 1672532794323,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "516",
    "rarity_score": 285.2127093826051,
    "rarity_level": "COMMON"
  },
  "1273": {
    "name": "Arkade 90s Bulls #1273",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1273.png",
    "dna": "5242d094e0efa18f0b7a1f0e0d2e2cd9f139b260",
    "edition": 1273,
    "date": 1672532794785,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1273",
    "rarity_score": 114.62441176475798,
    "rarity_level": "COMMON"
  },
  "390": {
    "name": "Arkade 90s Bulls #390",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/390.png",
    "dna": "a2140db30e8e376c469e1fccf7b04187ba27ccda",
    "edition": 390,
    "date": 1672532795248,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "390",
    "rarity_score": 515.4293292794963,
    "rarity_level": "RARE"
  },
  "570": {
    "name": "Arkade 90s Bulls #570",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/570.png",
    "dna": "10e2d204b3ced584ef0186393263084a0399d837",
    "edition": 570,
    "date": 1672532795713,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "570",
    "rarity_score": 147.68505533148274,
    "rarity_level": "COMMON"
  },
  "180": {
    "name": "Arkade 90s Bulls #180",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/180.png",
    "dna": "7295a10c15eb5db50944d987b6668caa57ba6c89",
    "edition": 180,
    "date": 1672532796909,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "180",
    "rarity_score": 178.36125356444816,
    "rarity_level": "COMMON"
  },
  "1302": {
    "name": "Arkade 90s Bulls #1302",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1302.png",
    "dna": "fc3c52210901ab49679ab467e0e476700b3235d7",
    "edition": 1302,
    "date": 1672532797469,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1302",
    "rarity_score": 165.86501245296236,
    "rarity_level": "COMMON"
  },
  "12": {
    "name": "Arkade 90s Bulls #12",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/12.png",
    "dna": "a3aa028862d49190f3386a14de8c02ed86234827",
    "edition": 12,
    "date": 1672532798162,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "12",
    "rarity_score": 95.56361276612269,
    "rarity_level": "COMMON"
  },
  "481": {
    "name": "Arkade 90s Bulls #481",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/481.png",
    "dna": "9434a5edd959a29b781ad4b24f111a84e0c3632b",
    "edition": 481,
    "date": 1672532798867,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "481",
    "rarity_score": 270.91879174559784,
    "rarity_level": "COMMON"
  },
  "1082": {
    "name": "Arkade 90s Bulls #1082",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1082.png",
    "dna": "1bad92fe03453bf90ce8dc5c9dc15001f6307322",
    "edition": 1082,
    "date": 1672532800013,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1082",
    "rarity_score": 123.85675006922733,
    "rarity_level": "COMMON"
  },
  "1984": {
    "name": "Arkade 90s Bulls #1984",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1984.png",
    "dna": "4249dba3d32ad15007bd143d4fa4c755bbefc57a",
    "edition": 1984,
    "date": 1672532800776,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1984",
    "rarity_score": 299.68206697227146,
    "rarity_level": "COMMON"
  },
  "277": {
    "name": "Arkade 90s Bulls #277",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/277.png",
    "dna": "5d8f72c9c29354112ed0d6390cec3a989eb6902c",
    "edition": 277,
    "date": 1672532801272,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "277",
    "rarity_score": 186.0345130216276,
    "rarity_level": "COMMON"
  },
  "1372": {
    "name": "Arkade 90s Bulls #1372",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1372.png",
    "dna": "7dbec206b080f2de4c7fae072cb0ac412c7b1af8",
    "edition": 1372,
    "date": 1672532801729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1372",
    "rarity_score": 87.2604829690352,
    "rarity_level": "COMMON"
  },
  "836": {
    "name": "Arkade 90s Bulls #836",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/836.png",
    "dna": "6dc7fb6724837c87ba4a804874796074859ecb8a",
    "edition": 836,
    "date": 1672532802211,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "836",
    "rarity_score": 155.23235069529036,
    "rarity_level": "COMMON"
  },
  "725": {
    "name": "Arkade 90s Bulls #725",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/725.png",
    "dna": "51e0e99004c344e5c2b390b43c374ed13f444f03",
    "edition": 725,
    "date": 1672532802720,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "725",
    "rarity_score": 367.00696524793244,
    "rarity_level": "COMMON"
  },
  "1985": {
    "name": "Arkade 90s Bulls #1985",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1985.png",
    "dna": "38dfdb9ff4977f3c705e95142906d3924a8d3b94",
    "edition": 1985,
    "date": 1672532803178,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1985",
    "rarity_score": 149.5507632844025,
    "rarity_level": "COMMON"
  },
  "157": {
    "name": "Arkade 90s Bulls #157",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/157.png",
    "dna": "faf68a5b5429a5ccb45e8ccf290116e0e267829b",
    "edition": 157,
    "date": 1672532803645,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "157",
    "rarity_score": 224.07362880486318,
    "rarity_level": "COMMON"
  },
  "391": {
    "name": "Arkade 90s Bulls #391",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/391.png",
    "dna": "148971672612e3b6ec3b0cff78d935fc593f03b7",
    "edition": 391,
    "date": 1672532804866,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "391",
    "rarity_score": 200.32119401138598,
    "rarity_level": "COMMON"
  },
  "782": {
    "name": "Arkade 90s Bulls #782",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/782.png",
    "dna": "2dc18653d0682807b47108383bd152720cc1884a",
    "edition": 782,
    "date": 1672532805381,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "782",
    "rarity_score": 219.1901556848636,
    "rarity_level": "COMMON"
  },
  "496": {
    "name": "Arkade 90s Bulls #496",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/496.png",
    "dna": "407bcb47631f04839e9e24820f8324160824e113",
    "edition": 496,
    "date": 1672532806582,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "496",
    "rarity_score": 300.39790419153144,
    "rarity_level": "COMMON"
  },
  "214": {
    "name": "Arkade 90s Bulls #214",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/214.png",
    "dna": "98d9d93c6724a2a2a3de213b00ee612e11bb3d18",
    "edition": 214,
    "date": 1672532807023,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "214",
    "rarity_score": 314.3881764967036,
    "rarity_level": "COMMON"
  },
  "1331": {
    "name": "Arkade 90s Bulls #1331",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1331.png",
    "dna": "134c29e082cb3023eb1fb12973ccc62c5ab4bfdb",
    "edition": 1331,
    "date": 1672532808226,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1331",
    "rarity_score": 438.90717731800044,
    "rarity_level": "COMMON"
  },
  "1638": {
    "name": "Arkade 90s Bulls #1638",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1638.png",
    "dna": "4570f175077f3248bb8b6feefe34753aa9303764",
    "edition": 1638,
    "date": 1672532808682,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1638",
    "rarity_score": 240.88339358359636,
    "rarity_level": "COMMON"
  },
  "400": {
    "name": "Arkade 90s Bulls #400",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/400.png",
    "dna": "05cf74db24218f4cfc010ad70deb3d430058dd0d",
    "edition": 400,
    "date": 1672532809904,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "400",
    "rarity_score": 477.2128708792395,
    "rarity_level": "RARE"
  },
  "520": {
    "name": "Arkade 90s Bulls #520",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/520.png",
    "dna": "2251df832f22b4a277ad263b099247fa7435791a",
    "edition": 520,
    "date": 1672532810379,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "520",
    "rarity_score": 199.91902537562618,
    "rarity_level": "COMMON"
  },
  "1232": {
    "name": "Arkade 90s Bulls #1232",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1232.png",
    "dna": "2dc0299d26f533db3f83a5e2dd4f59ff16a09a1f",
    "edition": 1232,
    "date": 1672532810910,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1232",
    "rarity_score": 209.11920221710741,
    "rarity_level": "COMMON"
  },
  "524": {
    "name": "Arkade 90s Bulls #524",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/524.png",
    "dna": "76103377fba15264348b3e2ca22685c26ae29208",
    "edition": 524,
    "date": 1672532811421,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "524",
    "rarity_score": 253.59488497198427,
    "rarity_level": "COMMON"
  },
  "1768": {
    "name": "Arkade 90s Bulls #1768",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1768.png",
    "dna": "ee532dc49b19cd8e64862e763e48fd2d49da3281",
    "edition": 1768,
    "date": 1672532811924,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1768",
    "rarity_score": 272.627383337184,
    "rarity_level": "COMMON"
  },
  "1413": {
    "name": "Arkade 90s Bulls #1413",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1413.png",
    "dna": "6be4c25759fc93ed74c6866120c786b38d0d3350",
    "edition": 1413,
    "date": 1672532812389,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1413",
    "rarity_score": 133.31726763492802,
    "rarity_level": "COMMON"
  },
  "1949": {
    "name": "Arkade 90s Bulls #1949",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1949.png",
    "dna": "7d9afc16515115fc6141d0e857085e33a940db40",
    "edition": 1949,
    "date": 1672532812863,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1949",
    "rarity_score": 276.16114191890097,
    "rarity_level": "COMMON"
  },
  "1497": {
    "name": "Arkade 90s Bulls #1497",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1497.png",
    "dna": "313b50bd08d83c280e3c8c98fa1bcdfb648948d1",
    "edition": 1497,
    "date": 1672532813330,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Dark Invader Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1497",
    "rarity_score": 349.7270923514176,
    "rarity_level": "COMMON"
  },
  "776": {
    "name": "Arkade 90s Bulls #776",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/776.png",
    "dna": "6098b21c38961a1ce5397c669b8e53e7051402b7",
    "edition": 776,
    "date": 1672532814103,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "776",
    "rarity_score": 349.82870027240125,
    "rarity_level": "COMMON"
  },
  "146": {
    "name": "Arkade 90s Bulls #146",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/146.png",
    "dna": "ff053f0bd7b146c290a845566294236815a5eff6",
    "edition": 146,
    "date": 1672532814567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "146",
    "rarity_score": 224.16896710738953,
    "rarity_level": "COMMON"
  },
  "815": {
    "name": "Arkade 90s Bulls #815",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/815.png",
    "dna": "d2484ba96d53194b6c6f5d64990dfa5e7e44dda9",
    "edition": 815,
    "date": 1672532815016,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "815",
    "rarity_score": 227.34770388233994,
    "rarity_level": "COMMON"
  },
  "1004": {
    "name": "Arkade 90s Bulls #1004",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1004.png",
    "dna": "2803d1fd555bd44de9f72fdeee1fe484b8add26c",
    "edition": 1004,
    "date": 1672532815464,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1004",
    "rarity_score": 198.1844867541,
    "rarity_level": "COMMON"
  },
  "1250": {
    "name": "Arkade 90s Bulls #1250",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1250.png",
    "dna": "2a4c7f56a6fe5eabae1a1467373ed8e0c41ba3d0",
    "edition": 1250,
    "date": 1672532815926,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1250",
    "rarity_score": 173.2078308529457,
    "rarity_level": "COMMON"
  },
  "253": {
    "name": "Arkade 90s Bulls #253",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/253.png",
    "dna": "7ae4188f25361a2d275c05c882ab0d5f70d62f51",
    "edition": 253,
    "date": 1672532816642,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "253",
    "rarity_score": 230.66660051246987,
    "rarity_level": "COMMON"
  },
  "433": {
    "name": "Arkade 90s Bulls #433",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/433.png",
    "dna": "5f6330f15d53831e5ee4040509f418ecb1b84c48",
    "edition": 433,
    "date": 1672532817862,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "433",
    "rarity_score": 175.8854165495291,
    "rarity_level": "COMMON"
  },
  "331": {
    "name": "Arkade 90s Bulls #331",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/331.png",
    "dna": "982c665a5a1a3fa474981333a444bf4fefe3cbcb",
    "edition": 331,
    "date": 1672532819045,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "331",
    "rarity_score": 132.05612590949502,
    "rarity_level": "COMMON"
  },
  "1204": {
    "name": "Arkade 90s Bulls #1204",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1204.png",
    "dna": "6acbf6f0493672c1e0d7d529664aa5e75138b891",
    "edition": 1204,
    "date": 1672532820200,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1204",
    "rarity_score": 266.88494242959695,
    "rarity_level": "COMMON"
  },
  "299": {
    "name": "Arkade 90s Bulls #299",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/299.png",
    "dna": "701b14eacd95b9151babb0184bece01b3468a0b4",
    "edition": 299,
    "date": 1672532820646,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "299",
    "rarity_score": 159.06571619163861,
    "rarity_level": "COMMON"
  },
  "1255": {
    "name": "Arkade 90s Bulls #1255",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1255.png",
    "dna": "e86cefccd89d3c878c74f9bf6ac2eebc248d7d45",
    "edition": 1255,
    "date": 1672532821165,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1255",
    "rarity_score": 396.1972048501069,
    "rarity_level": "COMMON"
  },
  "1344": {
    "name": "Arkade 90s Bulls #1344",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1344.png",
    "dna": "e62dbe138811223d672811916d77a70805841484",
    "edition": 1344,
    "date": 1672532821663,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1344",
    "rarity_score": 171.08379197315972,
    "rarity_level": "COMMON"
  },
  "450": {
    "name": "Arkade 90s Bulls #450",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/450.png",
    "dna": "49fc45a0e8c775ed399a89085b2f3245c1242a2f",
    "edition": 450,
    "date": 1672532822168,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "450",
    "rarity_score": 357.83397129365284,
    "rarity_level": "COMMON"
  },
  "458": {
    "name": "Arkade 90s Bulls #458",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/458.png",
    "dna": "44476a362064606ce671cdd52f0d570431fcbdbb",
    "edition": 458,
    "date": 1672532822648,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "458",
    "rarity_score": 365.5015370557096,
    "rarity_level": "COMMON"
  },
  "997": {
    "name": "Arkade 90s Bulls #997",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/997.png",
    "dna": "77ad68d39ca3615bc9a6a66ff925e65ee23374d6",
    "edition": 997,
    "date": 1672532823228,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "997",
    "rarity_score": 556.3373120228424,
    "rarity_level": "RARE"
  },
  "857": {
    "name": "Arkade 90s Bulls #857",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/857.png",
    "dna": "e0c4f24a5cc47384db69d4abd6356d01acf93273",
    "edition": 857,
    "date": 1672532824490,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "857",
    "rarity_score": 276.9193899537885,
    "rarity_level": "COMMON"
  },
  "1856": {
    "name": "Arkade 90s Bulls #1856",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1856.png",
    "dna": "698d97bf9401487c353530af1d41730c74eb1962",
    "edition": 1856,
    "date": 1672532824962,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      { "trait_type": "Mask", "value": "Turbobull", "score": 398.0 },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1856",
    "rarity_score": 590.2851495768516,
    "rarity_level": "RARE"
  },
  "956": {
    "name": "Arkade 90s Bulls #956",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/956.png",
    "dna": "33a7023e1db595a76dd976b0705649406bf58621",
    "edition": 956,
    "date": 1672532826103,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "956",
    "rarity_score": 312.3980273954734,
    "rarity_level": "COMMON"
  },
  "1536": {
    "name": "Arkade 90s Bulls #1536",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1536.png",
    "dna": "e044658222f26153b79730d2a50144586fb73020",
    "edition": 1536,
    "date": 1672532826610,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1536",
    "rarity_score": 350.29072406382886,
    "rarity_level": "COMMON"
  },
  "127": {
    "name": "Arkade 90s Bulls #127",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/127.png",
    "dna": "f9b413b16258694842b21ca90ee99ca45ba80d64",
    "edition": 127,
    "date": 1672532827085,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Yellow Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "127",
    "rarity_score": 590.812287376784,
    "rarity_level": "RARE"
  },
  "597": {
    "name": "Arkade 90s Bulls #597",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/597.png",
    "dna": "f25ea74f984585d6f2359698c2989f0f21b42478",
    "edition": 597,
    "date": 1672532827802,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "597",
    "rarity_score": 282.4183921753696,
    "rarity_level": "COMMON"
  },
  "1720": {
    "name": "Arkade 90s Bulls #1720",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1720.png",
    "dna": "e83a035e9ebfc5ad548c3623143a2b1567660f7c",
    "edition": 1720,
    "date": 1672532828301,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1720",
    "rarity_score": 142.77727312667633,
    "rarity_level": "COMMON"
  },
  "74": {
    "name": "Arkade 90s Bulls #74",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/74.png",
    "dna": "1363397226776e5d0bdb648d7203ea04c4ff5e3a",
    "edition": 74,
    "date": 1672532828761,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "74",
    "rarity_score": 208.71068837661693,
    "rarity_level": "COMMON"
  },
  "1484": {
    "name": "Arkade 90s Bulls #1484",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1484.png",
    "dna": "d903498609f3e5abf83c52971e0d8e8229e6008b",
    "edition": 1484,
    "date": 1672532829912,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1484",
    "rarity_score": 204.42537701245752,
    "rarity_level": "COMMON"
  },
  "309": {
    "name": "Arkade 90s Bulls #309",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/309.png",
    "dna": "8ee1e31a46207113be2efc9fa642718e7fffb812",
    "edition": 309,
    "date": 1672532831064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "309",
    "rarity_score": 313.22246954247845,
    "rarity_level": "COMMON"
  },
  "1420": {
    "name": "Arkade 90s Bulls #1420",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1420.png",
    "dna": "1405ecda15093932cddef88c79d3e1949471f170",
    "edition": 1420,
    "date": 1672532831563,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1420",
    "rarity_score": 167.54168824767717,
    "rarity_level": "COMMON"
  },
  "639": {
    "name": "Arkade 90s Bulls #639",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/639.png",
    "dna": "1afc5c08cdda3ea21b81766e37ba46e01ba3cffb",
    "edition": 639,
    "date": 1672532832757,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "639",
    "rarity_score": 346.7770271004065,
    "rarity_level": "COMMON"
  },
  "312": {
    "name": "Arkade 90s Bulls #312",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/312.png",
    "dna": "c4064f962348bf93a6e4e3f127d5b4292b885da8",
    "edition": 312,
    "date": 1672532833270,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "312",
    "rarity_score": 196.73442137074764,
    "rarity_level": "COMMON"
  },
  "1300": {
    "name": "Arkade 90s Bulls #1300",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1300.png",
    "dna": "99b4e9929b372fd30cf9905fb9bc4a1435fc3aa7",
    "edition": 1300,
    "date": 1672532834502,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1300",
    "rarity_score": 355.8659372235983,
    "rarity_level": "COMMON"
  },
  "347": {
    "name": "Arkade 90s Bulls #347",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/347.png",
    "dna": "b1a63013fdbc605153dc97c3fe5f0123d0d4fe3a",
    "edition": 347,
    "date": 1672532835004,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "347",
    "rarity_score": 179.78477235493762,
    "rarity_level": "COMMON"
  },
  "519": {
    "name": "Arkade 90s Bulls #519",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/519.png",
    "dna": "048cacc749ea31eda0a747108c489736d87d2c59",
    "edition": 519,
    "date": 1672532835455,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "519",
    "rarity_score": 391.5018078970416,
    "rarity_level": "COMMON"
  },
  "1456": {
    "name": "Arkade 90s Bulls #1456",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1456.png",
    "dna": "8acd0306cd86ee5cdd9df14245f190d0af08fff3",
    "edition": 1456,
    "date": 1672532836605,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1456",
    "rarity_score": 322.289309523588,
    "rarity_level": "COMMON"
  },
  "1807": {
    "name": "Arkade 90s Bulls #1807",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1807.png",
    "dna": "6ba76fd7a52e033a09b948029966d05dcf11ad7e",
    "edition": 1807,
    "date": 1672532837782,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1807",
    "rarity_score": 206.73357156277896,
    "rarity_level": "COMMON"
  },
  "1241": {
    "name": "Arkade 90s Bulls #1241",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1241.png",
    "dna": "c46a7f7d54dfb495a385692794ccb26641e4beb8",
    "edition": 1241,
    "date": 1672532838287,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1241",
    "rarity_score": 225.3821391213498,
    "rarity_level": "COMMON"
  },
  "1336": {
    "name": "Arkade 90s Bulls #1336",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1336.png",
    "dna": "df561ba9716b57718a1d23518ab2f9befd310be4",
    "edition": 1336,
    "date": 1672532838774,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1336",
    "rarity_score": 168.32925316506845,
    "rarity_level": "COMMON"
  },
  "1538": {
    "name": "Arkade 90s Bulls #1538",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1538.png",
    "dna": "8fcd8652fb8cf06cba6dc277bac385a6072863a5",
    "edition": 1538,
    "date": 1672532839262,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1538",
    "rarity_score": 287.2744786950557,
    "rarity_level": "COMMON"
  },
  "1028": {
    "name": "Arkade 90s Bulls #1028",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1028.png",
    "dna": "91ed7fd34e95fa4fe52275d7e793ba73e4d1cf77",
    "edition": 1028,
    "date": 1672532839769,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1028",
    "rarity_score": 387.0058558764998,
    "rarity_level": "COMMON"
  },
  "328": {
    "name": "Arkade 90s Bulls #328",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/328.png",
    "dna": "3ffd28280d85047c2f3b5abdc3dac171f5e7d0c2",
    "edition": 328,
    "date": 1672532840304,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "328",
    "rarity_score": 171.19598090405086,
    "rarity_level": "COMMON"
  },
  "1680": {
    "name": "Arkade 90s Bulls #1680",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1680.png",
    "dna": "7231b645c5abc7d7f69fffdfc454d8f168acacd4",
    "edition": 1680,
    "date": 1672532840782,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1680",
    "rarity_score": 251.51079876129702,
    "rarity_level": "COMMON"
  },
  "265": {
    "name": "Arkade 90s Bulls #265",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/265.png",
    "dna": "ff70409771eb92d38bc6f53a6801857b62e0818d",
    "edition": 265,
    "date": 1672532841261,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "265",
    "rarity_score": 260.9730263616933,
    "rarity_level": "COMMON"
  },
  "1210": {
    "name": "Arkade 90s Bulls #1210",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1210.png",
    "dna": "ff0477720311ad2114538729f263a163cda6438c",
    "edition": 1210,
    "date": 1672532841758,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1210",
    "rarity_score": 146.2800054483746,
    "rarity_level": "COMMON"
  },
  "492": {
    "name": "Arkade 90s Bulls #492",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/492.png",
    "dna": "13a6b7e79111fed650ded525074a26ba1bcc029b",
    "edition": 492,
    "date": 1672532842787,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "492",
    "rarity_score": 245.7336289547158,
    "rarity_level": "COMMON"
  },
  "1942": {
    "name": "Arkade 90s Bulls #1942",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1942.png",
    "dna": "ac8ddf59fe46386c482dbc9f747c23b2943518b3",
    "edition": 1942,
    "date": 1672532843359,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1942",
    "rarity_score": 296.93159249596516,
    "rarity_level": "COMMON"
  },
  "599": {
    "name": "Arkade 90s Bulls #599",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/599.png",
    "dna": "411db73701c559a92c62a3313058d9d58e4ca679",
    "edition": 599,
    "date": 1672532844532,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "599",
    "rarity_score": 269.7903719090908,
    "rarity_level": "COMMON"
  },
  "1864": {
    "name": "Arkade 90s Bulls #1864",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1864.png",
    "dna": "149e74504d439f5bd287f03fab381e401378e027",
    "edition": 1864,
    "date": 1672532846099,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1864",
    "rarity_score": 416.7582488400152,
    "rarity_level": "COMMON"
  },
  "627": {
    "name": "Arkade 90s Bulls #627",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/627.png",
    "dna": "5d5316ba7dc4b368555f55c7a171306af36f2a4a",
    "edition": 627,
    "date": 1672532846699,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "627",
    "rarity_score": 235.83666475302257,
    "rarity_level": "COMMON"
  },
  "488": {
    "name": "Arkade 90s Bulls #488",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/488.png",
    "dna": "98c0ec6f6c04f5f1727c7bb2067cf6b31e90ddd2",
    "edition": 488,
    "date": 1672532848258,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "488",
    "rarity_score": 314.8610352694854,
    "rarity_level": "COMMON"
  },
  "439": {
    "name": "Arkade 90s Bulls #439",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/439.png",
    "dna": "958ce559a61bb7bfb0b266c7ef9c847d2f9c1977",
    "edition": 439,
    "date": 1672532848818,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "439",
    "rarity_score": 331.79559162568603,
    "rarity_level": "COMMON"
  },
  "788": {
    "name": "Arkade 90s Bulls #788",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/788.png",
    "dna": "389ee170d1c69ab28a89e0ba346cb8c3d8bf1551",
    "edition": 788,
    "date": 1672532849332,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "788",
    "rarity_score": 288.27545103566945,
    "rarity_level": "COMMON"
  },
  "1342": {
    "name": "Arkade 90s Bulls #1342",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1342.png",
    "dna": "61a1222359654f9eb50eac3e63952d310b2a5f4e",
    "edition": 1342,
    "date": 1672532849875,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1342",
    "rarity_score": 206.59160700762536,
    "rarity_level": "COMMON"
  },
  "1763": {
    "name": "Arkade 90s Bulls #1763",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1763.png",
    "dna": "e4f869e94bdbcc5257b04333de49364e8222b864",
    "edition": 1763,
    "date": 1672532850349,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1763",
    "rarity_score": 211.0571845977402,
    "rarity_level": "COMMON"
  },
  "684": {
    "name": "Arkade 90s Bulls #684",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/684.png",
    "dna": "64f560ed01f3f22af452be0d97e3f777c47d7a79",
    "edition": 684,
    "date": 1672532851558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "684",
    "rarity_score": 337.4127274982303,
    "rarity_level": "COMMON"
  },
  "1395": {
    "name": "Arkade 90s Bulls #1395",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1395.png",
    "dna": "f6cd39ab88f90eea697efc1f8ddee7a67b8ae7c2",
    "edition": 1395,
    "date": 1672533263355,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1395",
    "rarity_score": 350.21108425925723,
    "rarity_level": "COMMON"
  },
  "1209": {
    "name": "Arkade 90s Bulls #1209",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1209.png",
    "dna": "19b408bb83a48ad6fba444699938bf334ab9d36c",
    "edition": 1209,
    "date": 1672533263907,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1209",
    "rarity_score": 312.52316232396424,
    "rarity_level": "COMMON"
  },
  "1619": {
    "name": "Arkade 90s Bulls #1619",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1619.png",
    "dna": "11c90dc012cfc9edb47c99c79bdbee98ce776186",
    "edition": 1619,
    "date": 1672533264409,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1619",
    "rarity_score": 304.8064593586958,
    "rarity_level": "COMMON"
  },
  "762": {
    "name": "Arkade 90s Bulls #762",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/762.png",
    "dna": "a6d41265a6283fa273926c67ccc793e7f85c2f5f",
    "edition": 762,
    "date": 1672533264920,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "762",
    "rarity_score": 170.5650440792158,
    "rarity_level": "COMMON"
  },
  "262": {
    "name": "Arkade 90s Bulls #262",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/262.png",
    "dna": "5b05f3ba11db0aeb64a86e7795b13ff26bda6119",
    "edition": 262,
    "date": 1672533266178,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "262",
    "rarity_score": 281.6984194223106,
    "rarity_level": "COMMON"
  },
  "44": {
    "name": "Arkade 90s Bulls #44",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/44.png",
    "dna": "724fcce299d59e12bb0888938689ce42add4fe35",
    "edition": 44,
    "date": 1672533266750,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "44",
    "rarity_score": 206.40263412053383,
    "rarity_level": "COMMON"
  },
  "905": {
    "name": "Arkade 90s Bulls #905",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/905.png",
    "dna": "afcbd6752af084015f31240fdea69afeae4cb0af",
    "edition": 905,
    "date": 1672533267563,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "905",
    "rarity_score": 310.98993103439267,
    "rarity_level": "COMMON"
  },
  "551": {
    "name": "Arkade 90s Bulls #551",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/551.png",
    "dna": "b386dfb0d5c82586dd72661063e2b9c825d7dc8e",
    "edition": 551,
    "date": 1672533268093,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "551",
    "rarity_score": 177.9553469742788,
    "rarity_level": "COMMON"
  },
  "688": {
    "name": "Arkade 90s Bulls #688",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/688.png",
    "dna": "4e7fa041c1f62771d477634c1e57e97a9ce340de",
    "edition": 688,
    "date": 1672533269286,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "688",
    "rarity_score": 206.27219222775796,
    "rarity_level": "COMMON"
  },
  "62": {
    "name": "Arkade 90s Bulls #62",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/62.png",
    "dna": "d6da1f705c3df470fa3477db0ce2d04684e4b42e",
    "edition": 62,
    "date": 1672533269736,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "62",
    "rarity_score": 281.8849284395441,
    "rarity_level": "COMMON"
  },
  "1076": {
    "name": "Arkade 90s Bulls #1076",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1076.png",
    "dna": "09a9924bbc8f18220c1bf6943ae2609ced2309dc",
    "edition": 1076,
    "date": 1672533270229,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1076",
    "rarity_score": 175.46917749718588,
    "rarity_level": "COMMON"
  },
  "1972": {
    "name": "Arkade 90s Bulls #1972",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1972.png",
    "dna": "e815c3f9b947a7e3d242482f2f2082c4cd8c5429",
    "edition": 1972,
    "date": 1672533271257,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1972",
    "rarity_score": 244.4399876170111,
    "rarity_level": "COMMON"
  },
  "1220": {
    "name": "Arkade 90s Bulls #1220",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1220.png",
    "dna": "876a2a911e52a2bb54f008a25049398aa185abb8",
    "edition": 1220,
    "date": 1672533271821,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1220",
    "rarity_score": 523.7170328022961,
    "rarity_level": "RARE"
  },
  "1779": {
    "name": "Arkade 90s Bulls #1779",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1779.png",
    "dna": "1ca2631dcb43dd31e9f38411caa206708f1ce468",
    "edition": 1779,
    "date": 1672533272386,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1779",
    "rarity_score": 437.9261902573965,
    "rarity_level": "COMMON"
  },
  "781": {
    "name": "Arkade 90s Bulls #781",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/781.png",
    "dna": "3debd3c808342bb3777a65bbed2709499b5938d4",
    "edition": 781,
    "date": 1672533272942,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "781",
    "rarity_score": 133.95139710838407,
    "rarity_level": "COMMON"
  },
  "1683": {
    "name": "Arkade 90s Bulls #1683",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1683.png",
    "dna": "93a9f9d923a24f67e119892a9836b9c12b0ce840",
    "edition": 1683,
    "date": 1672533273462,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1683",
    "rarity_score": 297.591250056655,
    "rarity_level": "COMMON"
  },
  "362": {
    "name": "Arkade 90s Bulls #362",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/362.png",
    "dna": "e47fb11bb27c0717b5dbe86db2a69f7ab4c76272",
    "edition": 362,
    "date": 1672533274031,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "362",
    "rarity_score": 158.69660660545284,
    "rarity_level": "COMMON"
  },
  "1330": {
    "name": "Arkade 90s Bulls #1330",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1330.png",
    "dna": "1d74d5c94db033d2eeb406aa9e2f1a1d4786fe26",
    "edition": 1330,
    "date": 1672533274530,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1330",
    "rarity_score": 278.1946440326967,
    "rarity_level": "COMMON"
  },
  "759": {
    "name": "Arkade 90s Bulls #759",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/759.png",
    "dna": "6f658b45bbb5429bbc1c3f4c91a5b02cb59d0071",
    "edition": 759,
    "date": 1672533275805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "759",
    "rarity_score": 206.03494170051005,
    "rarity_level": "COMMON"
  },
  "45": {
    "name": "Arkade 90s Bulls #45",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/45.png",
    "dna": "8b170db30728b4c3e193535c8c9f9aeba3847738",
    "edition": 45,
    "date": 1672533276963,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "45",
    "rarity_score": 518.1683955047869,
    "rarity_level": "RARE"
  },
  "356": {
    "name": "Arkade 90s Bulls #356",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/356.png",
    "dna": "1e3401c111c8c16620be2bcb02fd37b5652c995b",
    "edition": 356,
    "date": 1672533277955,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "356",
    "rarity_score": 295.9612367640758,
    "rarity_level": "COMMON"
  },
  "1645": {
    "name": "Arkade 90s Bulls #1645",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1645.png",
    "dna": "e55ec83258fca1371e8a7494f92b9c58c5c60d57",
    "edition": 1645,
    "date": 1672533278437,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1645",
    "rarity_score": 310.3644646262888,
    "rarity_level": "COMMON"
  },
  "901": {
    "name": "Arkade 90s Bulls #901",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/901.png",
    "dna": "537a60e43cd731b9ec60c2a77a6841edee1c1796",
    "edition": 901,
    "date": 1672533279644,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "901",
    "rarity_score": 245.24389646545654,
    "rarity_level": "COMMON"
  },
  "506": {
    "name": "Arkade 90s Bulls #506",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/506.png",
    "dna": "ea6d7b4402affa5357b1c904352e499de79669fe",
    "edition": 506,
    "date": 1672533280113,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "506",
    "rarity_score": 164.31944818345085,
    "rarity_level": "COMMON"
  },
  "609": {
    "name": "Arkade 90s Bulls #609",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/609.png",
    "dna": "035d2172c46340bdefe13dd227d133399cd60d99",
    "edition": 609,
    "date": 1672533281352,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "609",
    "rarity_score": 332.197025031897,
    "rarity_level": "COMMON"
  },
  "1430": {
    "name": "Arkade 90s Bulls #1430",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1430.png",
    "dna": "7c99f90e0ca46a55659ad100d451042c165a6fa3",
    "edition": 1430,
    "date": 1672533281908,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1430",
    "rarity_score": 225.6557926281215,
    "rarity_level": "COMMON"
  },
  "1647": {
    "name": "Arkade 90s Bulls #1647",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1647.png",
    "dna": "5d9c00be52f083ccb771e1bdd70718369082e250",
    "edition": 1647,
    "date": 1672533282450,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1647",
    "rarity_score": 254.51893445932294,
    "rarity_level": "COMMON"
  },
  "178": {
    "name": "Arkade 90s Bulls #178",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/178.png",
    "dna": "a7dd8b394e33d3b687412c57c729eb1703f1cbe1",
    "edition": 178,
    "date": 1672533282928,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "178",
    "rarity_score": 400.606769780103,
    "rarity_level": "COMMON"
  },
  "482": {
    "name": "Arkade 90s Bulls #482",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/482.png",
    "dna": "a702068d38ded27ea7c0b48a7d27f3cfefd48150",
    "edition": 482,
    "date": 1672533283406,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "482",
    "rarity_score": 229.67056826164085,
    "rarity_level": "COMMON"
  },
  "510": {
    "name": "Arkade 90s Bulls #510",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/510.png",
    "dna": "f5a501f2aeee71c3c52e3646705aef12d28efdbf",
    "edition": 510,
    "date": 1672533283927,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "510",
    "rarity_score": 494.84620145194003,
    "rarity_level": "RARE"
  },
  "517": {
    "name": "Arkade 90s Bulls #517",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/517.png",
    "dna": "fff4b2680f571deb65e4990323d72d3e57194520",
    "edition": 517,
    "date": 1672533284419,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "517",
    "rarity_score": 277.57018940061243,
    "rarity_level": "COMMON"
  },
  "1018": {
    "name": "Arkade 90s Bulls #1018",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1018.png",
    "dna": "24aff8a1022b2e5acc35383dd5a642971c4538b4",
    "edition": 1018,
    "date": 1672533284940,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1018",
    "rarity_score": 365.04291605327256,
    "rarity_level": "COMMON"
  },
  "740": {
    "name": "Arkade 90s Bulls #740",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/740.png",
    "dna": "dfc0d5a06bd2daee6f2ca1095a90cd2828cf0ee7",
    "edition": 740,
    "date": 1672533286179,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "740",
    "rarity_score": 165.4466037033047,
    "rarity_level": "COMMON"
  },
  "1399": {
    "name": "Arkade 90s Bulls #1399",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1399.png",
    "dna": "bf4f50058f8d18b844988838a055c56de41b27cf",
    "edition": 1399,
    "date": 1672533286707,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Mouse Ears", "score": 995.0 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1399",
    "rarity_score": 1198.012075433885,
    "rarity_level": "EPIC"
  },
  "1418": {
    "name": "Arkade 90s Bulls #1418",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1418.png",
    "dna": "c2f2a934bc809e449f4994205b0c002f97bc6cfb",
    "edition": 1418,
    "date": 1672533287732,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1418",
    "rarity_score": 169.92652979869462,
    "rarity_level": "COMMON"
  },
  "663": {
    "name": "Arkade 90s Bulls #663",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/663.png",
    "dna": "aa50d3701f4b4dca4d3f2708f97fe2b06a474936",
    "edition": 663,
    "date": 1672533289084,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "663",
    "rarity_score": 238.29908187513297,
    "rarity_level": "COMMON"
  },
  "70": {
    "name": "Arkade 90s Bulls #70",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/70.png",
    "dna": "af742d4255166abe58ebf0a188b22464c6d2d39d",
    "edition": 70,
    "date": 1672533290181,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "70",
    "rarity_score": 296.10358932302285,
    "rarity_level": "COMMON"
  },
  "1599": {
    "name": "Arkade 90s Bulls #1599",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1599.png",
    "dna": "b75abf911d1d6195ef05823581e00083c32ae0c2",
    "edition": 1599,
    "date": 1672533290732,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1599",
    "rarity_score": 423.81891497071257,
    "rarity_level": "COMMON"
  },
  "83": {
    "name": "Arkade 90s Bulls #83",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/83.png",
    "dna": "06676bf494b2f35aabede0515da30dd5e4d4a429",
    "edition": 83,
    "date": 1672533291590,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "83",
    "rarity_score": 292.2028476046293,
    "rarity_level": "COMMON"
  },
  "1247": {
    "name": "Arkade 90s Bulls #1247",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1247.png",
    "dna": "9a273e3f43130aec96d7034a8f279bf4a196e673",
    "edition": 1247,
    "date": 1672533292083,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1247",
    "rarity_score": 300.81509089967466,
    "rarity_level": "COMMON"
  },
  "1358": {
    "name": "Arkade 90s Bulls #1358",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1358.png",
    "dna": "33f1d5e0b2ecda2745357a01f4d169732085c619",
    "edition": 1358,
    "date": 1672533292550,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1358",
    "rarity_score": 408.90997784939754,
    "rarity_level": "COMMON"
  },
  "843": {
    "name": "Arkade 90s Bulls #843",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/843.png",
    "dna": "a658ae6a442a39b52e84f2a8d2357835e36a63df",
    "edition": 843,
    "date": 1672533293024,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "843",
    "rarity_score": 165.84543039275084,
    "rarity_level": "COMMON"
  },
  "752": {
    "name": "Arkade 90s Bulls #752",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/752.png",
    "dna": "467a8428363766cd1851b9e3396b5a9a928079ab",
    "edition": 752,
    "date": 1672533293508,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "752",
    "rarity_score": 252.41949860340858,
    "rarity_level": "COMMON"
  },
  "129": {
    "name": "Arkade 90s Bulls #129",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/129.png",
    "dna": "197d29d89484bfa647e7eca098c3e4f932adec6a",
    "edition": 129,
    "date": 1672533294053,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "129",
    "rarity_score": 283.36427635122214,
    "rarity_level": "COMMON"
  },
  "88": {
    "name": "Arkade 90s Bulls #88",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/88.png",
    "dna": "150d7f501eb480c45754a840799ba4318c498614",
    "edition": 88,
    "date": 1672533294529,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "88",
    "rarity_score": 112.79505977142311,
    "rarity_level": "COMMON"
  },
  "1989": {
    "name": "Arkade 90s Bulls #1989",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1989.png",
    "dna": "5ad86b056cca39817c8791c2eba81afba0c6696a",
    "edition": 1989,
    "date": 1672533295074,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1989",
    "rarity_score": 268.3601031280599,
    "rarity_level": "COMMON"
  },
  "1277": {
    "name": "Arkade 90s Bulls #1277",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1277.png",
    "dna": "eead6ba473108200c243666b7d5a2818770f01da",
    "edition": 1277,
    "date": 1672533295648,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1277",
    "rarity_score": 130.18956054205785,
    "rarity_level": "COMMON"
  },
  "508": {
    "name": "Arkade 90s Bulls #508",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/508.png",
    "dna": "3f947d0ce53540b069027e8444423a8f4e030618",
    "edition": 508,
    "date": 1672533296142,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "508",
    "rarity_score": 381.0817429049362,
    "rarity_level": "COMMON"
  },
  "837": {
    "name": "Arkade 90s Bulls #837",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/837.png",
    "dna": "cc7fff33ea2197ca2239e7cca9ad7289acd222cf",
    "edition": 837,
    "date": 1672533296611,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "837",
    "rarity_score": 553.7038715617894,
    "rarity_level": "RARE"
  },
  "1208": {
    "name": "Arkade 90s Bulls #1208",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1208.png",
    "dna": "038c67e27811bccc5eb76f6ba10114a011c3539c",
    "edition": 1208,
    "date": 1672533297121,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1208",
    "rarity_score": 201.06368505600798,
    "rarity_level": "COMMON"
  },
  "261": {
    "name": "Arkade 90s Bulls #261",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/261.png",
    "dna": "543ef90a3e0039c908cf841bb91ae4e4bddc94ca",
    "edition": 261,
    "date": 1672533297564,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "261",
    "rarity_score": 236.811747215754,
    "rarity_level": "COMMON"
  },
  "1201": {
    "name": "Arkade 90s Bulls #1201",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1201.png",
    "dna": "2241ac4d4ecf312ffbec526abe1de73b25033f01",
    "edition": 1201,
    "date": 1672533298040,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Tongue Out",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1201",
    "rarity_score": 296.43668041892295,
    "rarity_level": "COMMON"
  },
  "33": {
    "name": "Arkade 90s Bulls #33",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/33.png",
    "dna": "ed37010154fd6a2bceae44deb14100e63189ea55",
    "edition": 33,
    "date": 1672533298558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "33",
    "rarity_score": 169.4375799736981,
    "rarity_level": "COMMON"
  },
  "1120": {
    "name": "Arkade 90s Bulls #1120",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1120.png",
    "dna": "1a1797731ece54944405e393670b5d82ecc30d2d",
    "edition": 1120,
    "date": 1672533299034,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Sad",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1120",
    "rarity_score": 291.734457841719,
    "rarity_level": "COMMON"
  },
  "1177": {
    "name": "Arkade 90s Bulls #1177",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1177.png",
    "dna": "76a76b41e53e33cb9744566874c78511173d2a62",
    "edition": 1177,
    "date": 1672533299595,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1177",
    "rarity_score": 168.32912381906664,
    "rarity_level": "COMMON"
  },
  "179": {
    "name": "Arkade 90s Bulls #179",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/179.png",
    "dna": "4e8469bade15a83d09b3d90e707fd2dfbf9be479",
    "edition": 179,
    "date": 1672533300622,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "179",
    "rarity_score": 256.38880212416825,
    "rarity_level": "COMMON"
  },
  "911": {
    "name": "Arkade 90s Bulls #911",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/911.png",
    "dna": "a638140abfee7f41517689ab38b282e6c9115616",
    "edition": 911,
    "date": 1672533301112,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "911",
    "rarity_score": 415.85586709936933,
    "rarity_level": "COMMON"
  },
  "1409": {
    "name": "Arkade 90s Bulls #1409",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1409.png",
    "dna": "41782497fc33f81f3fabcc6460c10670fe9fa578",
    "edition": 1409,
    "date": 1672533301590,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1409",
    "rarity_score": 396.7387588668694,
    "rarity_level": "COMMON"
  },
  "22": {
    "name": "Arkade 90s Bulls #22",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/22.png",
    "dna": "c553f39408591a73f990831fd6540bba7ce51949",
    "edition": 22,
    "date": 1672533302166,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "22",
    "rarity_score": 362.3005791975465,
    "rarity_level": "COMMON"
  },
  "385": {
    "name": "Arkade 90s Bulls #385",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/385.png",
    "dna": "ed8f4e230c6106ff9190ebb342139b6765400433",
    "edition": 385,
    "date": 1672533303343,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "385",
    "rarity_score": 343.9655407141345,
    "rarity_level": "COMMON"
  },
  "133": {
    "name": "Arkade 90s Bulls #133",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/133.png",
    "dna": "e75a142f6580f4f84368167148fb51a8d8c0f68a",
    "edition": 133,
    "date": 1672533303883,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "133",
    "rarity_score": 381.2414170368741,
    "rarity_level": "COMMON"
  },
  "493": {
    "name": "Arkade 90s Bulls #493",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/493.png",
    "dna": "82c86f4352e07160a68dd62633581038fc6432a8",
    "edition": 493,
    "date": 1672533304609,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "493",
    "rarity_score": 320.25802340554975,
    "rarity_level": "COMMON"
  },
  "1320": {
    "name": "Arkade 90s Bulls #1320",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1320.png",
    "dna": "780a7d32b904681077622dd016c246ec82182612",
    "edition": 1320,
    "date": 1672533305799,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Brown Mustache",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1320",
    "rarity_score": 282.0982483746608,
    "rarity_level": "COMMON"
  },
  "1121": {
    "name": "Arkade 90s Bulls #1121",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1121.png",
    "dna": "b468bfd8f39b165da865fb582e46eaeb3cdaac92",
    "edition": 1121,
    "date": 1672533306360,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1121",
    "rarity_score": 345.8873059951532,
    "rarity_level": "COMMON"
  },
  "652": {
    "name": "Arkade 90s Bulls #652",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/652.png",
    "dna": "1d3d39b09e525ba6657b4341af0cdaf18d72a524",
    "edition": 652,
    "date": 1672533307009,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Happy",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "652",
    "rarity_score": 220.9620385676307,
    "rarity_level": "COMMON"
  },
  "695": {
    "name": "Arkade 90s Bulls #695",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/695.png",
    "dna": "d1d633cdd205657c0f6b61d636b663ee2d82c307",
    "edition": 695,
    "date": 1672533307538,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Brown Smile",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "695",
    "rarity_score": 252.4105594831607,
    "rarity_level": "COMMON"
  },
  "300": {
    "name": "Arkade 90s Bulls #300",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/300.png",
    "dna": "795a648c7dfaef0c512a89bfebf52f3b80e8a9ea",
    "edition": 300,
    "date": 1672533308082,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Evil",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "300",
    "rarity_score": 497.2168222867596,
    "rarity_level": "RARE"
  },
  "435": {
    "name": "Arkade 90s Bulls #435",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/435.png",
    "dna": "c0a2bebc4ed56174929807ed10975ccdcd9090d3",
    "edition": 435,
    "date": 1672533308843,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "435",
    "rarity_score": 435.9693977966266,
    "rarity_level": "COMMON"
  },
  "1278": {
    "name": "Arkade 90s Bulls #1278",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1278.png",
    "dna": "131575472cfe71d6bf067e1fbc47ad089e620ab7",
    "edition": 1278,
    "date": 1672533309916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1278",
    "rarity_score": 186.50988370458663,
    "rarity_level": "COMMON"
  },
  "399": {
    "name": "Arkade 90s Bulls #399",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/399.png",
    "dna": "41f8d9fb6ceeeb7e6c3652f5d7200f7551399dd1",
    "edition": 399,
    "date": 1672533310401,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "399",
    "rarity_score": 355.34570640018165,
    "rarity_level": "COMMON"
  },
  "827": {
    "name": "Arkade 90s Bulls #827",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/827.png",
    "dna": "c83435dda5904c68a24c30af2aea259b2abb3680",
    "edition": 827,
    "date": 1672533310981,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Brown Angry",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "827",
    "rarity_score": 247.71576897742324,
    "rarity_level": "COMMON"
  },
  "1057": {
    "name": "Arkade 90s Bulls #1057",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1057.png",
    "dna": "e11a4d2d232a75146c2ffb769ee012df02526c81",
    "edition": 1057,
    "date": 1672533311486,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1057",
    "rarity_score": 228.93590267197249,
    "rarity_level": "COMMON"
  },
  "1078": {
    "name": "Arkade 90s Bulls #1078",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1078.png",
    "dna": "056136af47c6f2cd44eefff1bddc31514628414e",
    "edition": 1078,
    "date": 1672533311965,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1078",
    "rarity_score": 283.73052964645825,
    "rarity_level": "COMMON"
  },
  "701": {
    "name": "Arkade 90s Bulls #701",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/701.png",
    "dna": "87321fba368f82ef677796ee1760b6b44cb720bc",
    "edition": 701,
    "date": 1672533312415,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "701",
    "rarity_score": 290.23272676729897,
    "rarity_level": "COMMON"
  },
  "638": {
    "name": "Arkade 90s Bulls #638",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/638.png",
    "dna": "40206db6f83faf3b87369bf4ca176a559b550f78",
    "edition": 638,
    "date": 1672533312871,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "638",
    "rarity_score": 346.1997884682983,
    "rarity_level": "COMMON"
  },
  "317": {
    "name": "Arkade 90s Bulls #317",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/317.png",
    "dna": "d0fa8b1720071a365d4f01e9eece0a47e30fede1",
    "edition": 317,
    "date": 1672533314105,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "317",
    "rarity_score": 237.84932934471468,
    "rarity_level": "COMMON"
  },
  "742": {
    "name": "Arkade 90s Bulls #742",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/742.png",
    "dna": "a119611cc19be9e5c93808462909ae3534ec6cee",
    "edition": 742,
    "date": 1672533314709,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "742",
    "rarity_score": 640.8883929156891,
    "rarity_level": "RARE"
  },
  "840": {
    "name": "Arkade 90s Bulls #840",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/840.png",
    "dna": "e81ddd1a2708e011a6f1b32bfdfef6dbe3961766",
    "edition": 840,
    "date": 1672533315159,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "840",
    "rarity_score": 300.882124848354,
    "rarity_level": "COMMON"
  },
  "1151": {
    "name": "Arkade 90s Bulls #1151",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1151.png",
    "dna": "b459cf05222aed1926997d464f85ed666fc851af",
    "edition": 1151,
    "date": 1672533315687,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1151",
    "rarity_score": 288.3659770459595,
    "rarity_level": "COMMON"
  },
  "824": {
    "name": "Arkade 90s Bulls #824",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/824.png",
    "dna": "d447da5e9f02b7fa6edc790269780fb51314437b",
    "edition": 824,
    "date": 1672533316195,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "824",
    "rarity_score": 312.7805470496989,
    "rarity_level": "COMMON"
  },
  "1401": {
    "name": "Arkade 90s Bulls #1401",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1401.png",
    "dna": "2996d178c27344935278405a3fc0bb6a069eab98",
    "edition": 1401,
    "date": 1672533316946,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Blue Guardian Mask",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1401",
    "rarity_score": 871.5037130065235,
    "rarity_level": "RARE"
  },
  "148": {
    "name": "Arkade 90s Bulls #148",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/148.png",
    "dna": "a777bc5651fe86e93146e741d078118bd6c6b0b9",
    "edition": 148,
    "date": 1672533317396,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "148",
    "rarity_score": 385.5728992863218,
    "rarity_level": "COMMON"
  },
  "10": {
    "name": "Arkade 90s Bulls #10",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/10.png",
    "dna": "2a560f5eddf424d11885cec7013c65cfcd8568a5",
    "edition": 10,
    "date": 1672533317850,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "10",
    "rarity_score": 233.84838025630503,
    "rarity_level": "COMMON"
  },
  "1254": {
    "name": "Arkade 90s Bulls #1254",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1254.png",
    "dna": "2371dab451f4804cd3d8209fdbdf315fb5e41263",
    "edition": 1254,
    "date": 1672533318343,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1254",
    "rarity_score": 266.051098334254,
    "rarity_level": "COMMON"
  },
  "1537": {
    "name": "Arkade 90s Bulls #1537",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1537.png",
    "dna": "f40eab720a565e54eae082935dc6c027e03aaa3b",
    "edition": 1537,
    "date": 1672533319552,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1537",
    "rarity_score": 351.47309111041807,
    "rarity_level": "COMMON"
  },
  "239": {
    "name": "Arkade 90s Bulls #239",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/239.png",
    "dna": "6ff5965a45c9aed397c8494915614f61fbb88dcb",
    "edition": 239,
    "date": 1672533320013,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "239",
    "rarity_score": 166.99758924561962,
    "rarity_level": "COMMON"
  },
  "1920": {
    "name": "Arkade 90s Bulls #1920",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1920.png",
    "dna": "bbdefbe5168cea63614a4d93240b7d2d31954cb9",
    "edition": 1920,
    "date": 1672533320473,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1920",
    "rarity_score": 137.24522082530032,
    "rarity_level": "COMMON"
  },
  "131": {
    "name": "Arkade 90s Bulls #131",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/131.png",
    "dna": "b7691b474ed0b9caecd41d621a329c26128cd169",
    "edition": 131,
    "date": 1672533321677,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Pink Guardian Helmet",
        "score": 995.0
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "131",
    "rarity_score": 1145.1077232795328,
    "rarity_level": "EPIC"
  },
  "856": {
    "name": "Arkade 90s Bulls #856",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/856.png",
    "dna": "90f897de3c2273d59077a1500110b8357873de93",
    "edition": 856,
    "date": 1672533322127,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "856",
    "rarity_score": 179.06827957186394,
    "rarity_level": "COMMON"
  },
  "1143": {
    "name": "Arkade 90s Bulls #1143",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1143.png",
    "dna": "205a37f924787c9da9c58c6184616f8b2fe8f53a",
    "edition": 1143,
    "date": 1672533322565,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1143",
    "rarity_score": 166.51829459528307,
    "rarity_level": "COMMON"
  },
  "645": {
    "name": "Arkade 90s Bulls #645",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/645.png",
    "dna": "f842204424e6bc2fe91a79611bd289332c3723db",
    "edition": 645,
    "date": 1672533323251,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "645",
    "rarity_score": 318.251238874642,
    "rarity_level": "COMMON"
  },
  "1596": {
    "name": "Arkade 90s Bulls #1596",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1596.png",
    "dna": "c0e7611adfe9f1c0eab0adcf6afb59033e94ee95",
    "edition": 1596,
    "date": 1672533323705,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1596",
    "rarity_score": 126.73973010099124,
    "rarity_level": "COMMON"
  },
  "370": {
    "name": "Arkade 90s Bulls #370",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/370.png",
    "dna": "16acc23d4667a18a6445f81f48e73b6aa5f87f0c",
    "edition": 370,
    "date": 1672533324157,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "370",
    "rarity_score": 297.46786465364505,
    "rarity_level": "COMMON"
  },
  "944": {
    "name": "Arkade 90s Bulls #944",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/944.png",
    "dna": "37e8236e1b38f77b24b33200dd20811e2ec290bc",
    "edition": 944,
    "date": 1672533325159,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "944",
    "rarity_score": 197.41362523450107,
    "rarity_level": "COMMON"
  },
  "227": {
    "name": "Arkade 90s Bulls #227",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/227.png",
    "dna": "31d1de6f161c86221a87caeb669d98af6627722e",
    "edition": 227,
    "date": 1672533325614,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "227",
    "rarity_score": 335.5420763510871,
    "rarity_level": "COMMON"
  },
  "1814": {
    "name": "Arkade 90s Bulls #1814",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1814.png",
    "dna": "0495e90aca3ee22fbd61ccb6e660928c07080889",
    "edition": 1814,
    "date": 1672533326787,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1814",
    "rarity_score": 160.648869322841,
    "rarity_level": "COMMON"
  },
  "268": {
    "name": "Arkade 90s Bulls #268",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/268.png",
    "dna": "dea3542d722e94e9bb063ac7d50c64ec0d2d81b8",
    "edition": 268,
    "date": 1672533327484,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "268",
    "rarity_score": 364.3495423765936,
    "rarity_level": "COMMON"
  },
  "950": {
    "name": "Arkade 90s Bulls #950",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/950.png",
    "dna": "deb63fde934e3a34953a0dc403d8d8e3a74d7cc3",
    "edition": 950,
    "date": 1672533327919,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "950",
    "rarity_score": 224.5459480052671,
    "rarity_level": "COMMON"
  },
  "1549": {
    "name": "Arkade 90s Bulls #1549",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1549.png",
    "dna": "e569172eaf466f88e0da036223c5e8439e3abb89",
    "edition": 1549,
    "date": 1672533328374,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1549",
    "rarity_score": 127.17469055061298,
    "rarity_level": "COMMON"
  },
  "541": {
    "name": "Arkade 90s Bulls #541",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/541.png",
    "dna": "5686dac72332ff30637d7605078cc550d4609d68",
    "edition": 541,
    "date": 1672533328869,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "541",
    "rarity_score": 353.6658677407926,
    "rarity_level": "COMMON"
  },
  "1882": {
    "name": "Arkade 90s Bulls #1882",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1882.png",
    "dna": "c56ac2a2a8862462fb81590877392bf163e529a4",
    "edition": 1882,
    "date": 1672533329328,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1882",
    "rarity_score": 143.3563639503952,
    "rarity_level": "COMMON"
  },
  "1784": {
    "name": "Arkade 90s Bulls #1784",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1784.png",
    "dna": "4569c2088c386d94411605fe2edcf7f9aa72ec88",
    "edition": 1784,
    "date": 1672533329843,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1784",
    "rarity_score": 297.48875596632934,
    "rarity_level": "COMMON"
  },
  "1888": {
    "name": "Arkade 90s Bulls #1888",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1888.png",
    "dna": "49bdab98af77ce03220b675298761dc424b7cbfa",
    "edition": 1888,
    "date": 1672533330323,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1888",
    "rarity_score": 197.5045899777878,
    "rarity_level": "COMMON"
  },
  "469": {
    "name": "Arkade 90s Bulls #469",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/469.png",
    "dna": "64a832a1a5ed8fcc2c5d1ef333767dca243c1778",
    "edition": 469,
    "date": 1672533330839,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "469",
    "rarity_score": 238.70262485410265,
    "rarity_level": "COMMON"
  },
  "169": {
    "name": "Arkade 90s Bulls #169",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/169.png",
    "dna": "cb1256cf93c20ba0b0442cb351175d6245ec1f26",
    "edition": 169,
    "date": 1672533331351,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "169",
    "rarity_score": 122.7717487577263,
    "rarity_level": "COMMON"
  },
  "1144": {
    "name": "Arkade 90s Bulls #1144",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1144.png",
    "dna": "f258f761f9ab864300e986cf35bf6bfca4f07a21",
    "edition": 1144,
    "date": 1672533331803,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1144",
    "rarity_score": 242.05564847726487,
    "rarity_level": "COMMON"
  },
  "802": {
    "name": "Arkade 90s Bulls #802",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/802.png",
    "dna": "8bb5f301e62abb6f442121576868a6b264614589",
    "edition": 802,
    "date": 1672533332255,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "802",
    "rarity_score": 113.52605029940659,
    "rarity_level": "COMMON"
  },
  "1586": {
    "name": "Arkade 90s Bulls #1586",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1586.png",
    "dna": "b75bb4711f09409c2fe0b44b5828b62f8168845e",
    "edition": 1586,
    "date": 1672533333271,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1586",
    "rarity_score": 132.6364213838673,
    "rarity_level": "COMMON"
  },
  "208": {
    "name": "Arkade 90s Bulls #208",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/208.png",
    "dna": "6093b8b44ab1afb539218fe7f51af1f39feeccf6",
    "edition": 208,
    "date": 1672533333753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "208",
    "rarity_score": 206.95938506725238,
    "rarity_level": "COMMON"
  },
  "1248": {
    "name": "Arkade 90s Bulls #1248",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1248.png",
    "dna": "00ae4f5c0215e207aca8f847312b3fc08f42915f",
    "edition": 1248,
    "date": 1672533334224,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1248",
    "rarity_score": 169.34570346177333,
    "rarity_level": "COMMON"
  },
  "1388": {
    "name": "Arkade 90s Bulls #1388",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1388.png",
    "dna": "3ca360576e64d43832d05c46a121f25cefd2eb99",
    "edition": 1388,
    "date": 1672533334711,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1388",
    "rarity_score": 334.69847319860065,
    "rarity_level": "COMMON"
  },
  "940": {
    "name": "Arkade 90s Bulls #940",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/940.png",
    "dna": "edc5f220dd8c22512ebe2b6a97940b58634a8c80",
    "edition": 940,
    "date": 1672533335515,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "940",
    "rarity_score": 168.35647341703907,
    "rarity_level": "COMMON"
  },
  "477": {
    "name": "Arkade 90s Bulls #477",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/477.png",
    "dna": "8af77b5cc6d486c0a3679897aecda968c5192eb2",
    "edition": 477,
    "date": 1672533336261,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "477",
    "rarity_score": 145.53791562685885,
    "rarity_level": "COMMON"
  },
  "996": {
    "name": "Arkade 90s Bulls #996",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/996.png",
    "dna": "160e9c2495fb03587ad20745769e8040060db1a3",
    "edition": 996,
    "date": 1672533336759,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "996",
    "rarity_score": 127.70441239218769,
    "rarity_level": "COMMON"
  },
  "1274": {
    "name": "Arkade 90s Bulls #1274",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1274.png",
    "dna": "1228901ae7c236835b2b955861c4f0714b2c4157",
    "edition": 1274,
    "date": 1672533337277,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1274",
    "rarity_score": 154.78980324231316,
    "rarity_level": "COMMON"
  },
  "757": {
    "name": "Arkade 90s Bulls #757",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/757.png",
    "dna": "50c858babfec27e196f3b15056a3ab48c0ff6971",
    "edition": 757,
    "date": 1672533337792,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "757",
    "rarity_score": 268.5598426275031,
    "rarity_level": "COMMON"
  },
  "1426": {
    "name": "Arkade 90s Bulls #1426",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1426.png",
    "dna": "0bdf5e5a0d3581f282e9204e29f9a526eebe394e",
    "edition": 1426,
    "date": 1672533338248,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Pink Guardian Helmet",
        "score": 995.0
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1426",
    "rarity_score": 1193.2167711491566,
    "rarity_level": "EPIC"
  },
  "314": {
    "name": "Arkade 90s Bulls #314",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/314.png",
    "dna": "a9f17a2ed7ab55468455064d72cf1771c0592624",
    "edition": 314,
    "date": 1672533338742,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "314",
    "rarity_score": 167.13459904389072,
    "rarity_level": "COMMON"
  },
  "1117": {
    "name": "Arkade 90s Bulls #1117",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1117.png",
    "dna": "19baa773c3e85550409240c4d4305ffcb95934a3",
    "edition": 1117,
    "date": 1672533339242,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1117",
    "rarity_score": 299.64263844620837,
    "rarity_level": "COMMON"
  },
  "104": {
    "name": "Arkade 90s Bulls #104",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/104.png",
    "dna": "82829b9b7f935aff36e7385c2f11bb4e0131ceb2",
    "edition": 104,
    "date": 1672533339761,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "104",
    "rarity_score": 226.82135156566503,
    "rarity_level": "COMMON"
  },
  "636": {
    "name": "Arkade 90s Bulls #636",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/636.png",
    "dna": "506f2a1f6b1005c0820583e20af098e221120f19",
    "edition": 636,
    "date": 1672533340237,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "636",
    "rarity_score": 164.5014983164678,
    "rarity_level": "COMMON"
  },
  "1037": {
    "name": "Arkade 90s Bulls #1037",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1037.png",
    "dna": "69c04548b2153c94293b47b2eb6c4d9a1f636454",
    "edition": 1037,
    "date": 1672533340697,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1037",
    "rarity_score": 255.40698468668865,
    "rarity_level": "COMMON"
  },
  "1075": {
    "name": "Arkade 90s Bulls #1075",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1075.png",
    "dna": "7c0335ba6ee6442b5ebca2eba808f9e37aa463f0",
    "edition": 1075,
    "date": 1672533341379,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1075",
    "rarity_score": 205.21300477227996,
    "rarity_level": "COMMON"
  },
  "1105": {
    "name": "Arkade 90s Bulls #1105",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1105.png",
    "dna": "12395caf172191b482ee6f0635a36817c78b1131",
    "edition": 1105,
    "date": 1672533341819,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1105",
    "rarity_score": 231.4080351215827,
    "rarity_level": "COMMON"
  },
  "235": {
    "name": "Arkade 90s Bulls #235",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/235.png",
    "dna": "2ce7653314a39a5c2ac09ffa0fc58c3d9a390954",
    "edition": 235,
    "date": 1672533342835,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "235",
    "rarity_score": 295.6905064253731,
    "rarity_level": "COMMON"
  },
  "404": {
    "name": "Arkade 90s Bulls #404",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/404.png",
    "dna": "7264a932c5209f25ddd7254925a9763f9f40a034",
    "edition": 404,
    "date": 1672533343293,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "404",
    "rarity_score": 133.21381863197286,
    "rarity_level": "COMMON"
  },
  "81": {
    "name": "Arkade 90s Bulls #81",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/81.png",
    "dna": "2c53f2ef70131a5d26918044338d3abf7bd1c626",
    "edition": 81,
    "date": 1672533343776,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "81",
    "rarity_score": 133.15083672201726,
    "rarity_level": "COMMON"
  },
  "1644": {
    "name": "Arkade 90s Bulls #1644",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1644.png",
    "dna": "1a6b673bd5d9b63f2b6c163a6fe13fad7e8dd561",
    "edition": 1644,
    "date": 1672533344232,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1644",
    "rarity_score": 93.18456309895538,
    "rarity_level": "COMMON"
  },
  "77": {
    "name": "Arkade 90s Bulls #77",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/77.png",
    "dna": "3ff98b74953e84f906ad795bf20086600494595b",
    "edition": 77,
    "date": 1672533344708,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "77",
    "rarity_score": 219.0616514729918,
    "rarity_level": "COMMON"
  },
  "1072": {
    "name": "Arkade 90s Bulls #1072",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1072.png",
    "dna": "d2fd255ac9f78a009660291e4cf8fdf3558bf373",
    "edition": 1072,
    "date": 1672533345181,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1072",
    "rarity_score": 135.74179473444363,
    "rarity_level": "COMMON"
  },
  "483": {
    "name": "Arkade 90s Bulls #483",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/483.png",
    "dna": "daf756f24de44c6be93fce346f6f3ce17236906b",
    "edition": 483,
    "date": 1672533346017,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "483",
    "rarity_score": 533.0887596713575,
    "rarity_level": "RARE"
  },
  "1266": {
    "name": "Arkade 90s Bulls #1266",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1266.png",
    "dna": "879deb9ea655fc1d2c9b7aae596fbc980d1c4ec3",
    "edition": 1266,
    "date": 1672533346493,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1266",
    "rarity_score": 232.49597718031512,
    "rarity_level": "COMMON"
  },
  "1879": {
    "name": "Arkade 90s Bulls #1879",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1879.png",
    "dna": "e807a9d2f3559b3bdb2223873ff741e02e626f3a",
    "edition": 1879,
    "date": 1672533346974,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1879",
    "rarity_score": 348.9379324767426,
    "rarity_level": "COMMON"
  },
  "1107": {
    "name": "Arkade 90s Bulls #1107",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1107.png",
    "dna": "e7c25c8310268f2e44bdf54c3cac14353c13ce26",
    "edition": 1107,
    "date": 1672533347474,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1107",
    "rarity_score": 304.0435005513593,
    "rarity_level": "COMMON"
  },
  "768": {
    "name": "Arkade 90s Bulls #768",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/768.png",
    "dna": "0b1d0f97d2c91e935cfd3413c7fb257cefaf8217",
    "edition": 768,
    "date": 1672533347988,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "768",
    "rarity_score": 243.52529970500365,
    "rarity_level": "COMMON"
  },
  "89": {
    "name": "Arkade 90s Bulls #89",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/89.png",
    "dna": "bde4ac7ad9b8675fa3a6c4aaadb67e683aac94a5",
    "edition": 89,
    "date": 1672533348440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "89",
    "rarity_score": 232.5362842246432,
    "rarity_level": "COMMON"
  },
  "965": {
    "name": "Arkade 90s Bulls #965",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/965.png",
    "dna": "2b5b0cc217b30c4d1f7635bbc4b0e75cf277903f",
    "edition": 965,
    "date": 1672533348941,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "965",
    "rarity_score": 149.4188493525843,
    "rarity_level": "COMMON"
  },
  "1785": {
    "name": "Arkade 90s Bulls #1785",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1785.png",
    "dna": "92563802f1f0bcc2f3f1b2b9b31622ab99d71796",
    "edition": 1785,
    "date": 1672533350396,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1785",
    "rarity_score": 272.194779538015,
    "rarity_level": "COMMON"
  },
  "1410": {
    "name": "Arkade 90s Bulls #1410",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1410.png",
    "dna": "d389ab269c0eb14b138bf9af4ac1b6d21ac3d72d",
    "edition": 1410,
    "date": 1672533351195,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1410",
    "rarity_score": 182.52390286937725,
    "rarity_level": "COMMON"
  },
  "1978": {
    "name": "Arkade 90s Bulls #1978",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1978.png",
    "dna": "72555ac09fb392fc15a5c285b4da8f1d8a79f82d",
    "edition": 1978,
    "date": 1672533351695,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1978",
    "rarity_score": 283.6393755030556,
    "rarity_level": "COMMON"
  },
  "468": {
    "name": "Arkade 90s Bulls #468",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/468.png",
    "dna": "697c1940e1568c7dbc793d13e497d5a48fc60331",
    "edition": 468,
    "date": 1672533352155,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "468",
    "rarity_score": 385.81675725281565,
    "rarity_level": "COMMON"
  },
  "1355": {
    "name": "Arkade 90s Bulls #1355",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1355.png",
    "dna": "4ae75e78b79fc12c0fe9548ec5f371c1a1c4ecd5",
    "edition": 1355,
    "date": 1672533352602,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1355",
    "rarity_score": 187.9168930991835,
    "rarity_level": "COMMON"
  },
  "923": {
    "name": "Arkade 90s Bulls #923",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/923.png",
    "dna": "36e144756715f264a567272cca0475e2b5a0687b",
    "edition": 923,
    "date": 1672533353056,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "923",
    "rarity_score": 260.087788905407,
    "rarity_level": "COMMON"
  },
  "1007": {
    "name": "Arkade 90s Bulls #1007",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1007.png",
    "dna": "7aabc8b1d6fde0ca81795e657ff7205b2692ada0",
    "edition": 1007,
    "date": 1672533354158,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1007",
    "rarity_score": 341.66501674093274,
    "rarity_level": "COMMON"
  },
  "1321": {
    "name": "Arkade 90s Bulls #1321",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1321.png",
    "dna": "316266f90cf39d8c50427922f1165560c80389cb",
    "edition": 1321,
    "date": 1672533354724,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1321",
    "rarity_score": 316.0728024530168,
    "rarity_level": "COMMON"
  },
  "704": {
    "name": "Arkade 90s Bulls #704",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/704.png",
    "dna": "0bcfb75100e677fb6493750695cc89691a8cd439",
    "edition": 704,
    "date": 1672533355416,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "704",
    "rarity_score": 95.01085394897657,
    "rarity_level": "COMMON"
  },
  "978": {
    "name": "Arkade 90s Bulls #978",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/978.png",
    "dna": "44feef3c1cc8763b133df3c34b02e0ad405c7422",
    "edition": 978,
    "date": 1672533355921,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "978",
    "rarity_score": 140.5841630716548,
    "rarity_level": "COMMON"
  },
  "847": {
    "name": "Arkade 90s Bulls #847",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/847.png",
    "dna": "f65524fdd6a628c553c5b1c25ccaaaca5ff04c98",
    "edition": 847,
    "date": 1672533356399,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "847",
    "rarity_score": 233.55815005109648,
    "rarity_level": "COMMON"
  },
  "41": {
    "name": "Arkade 90s Bulls #41",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/41.png",
    "dna": "b90cc67b56fb1a784fb0c24149b26110b8c68087",
    "edition": 41,
    "date": 1672533357430,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "41",
    "rarity_score": 292.3087194338469,
    "rarity_level": "COMMON"
  },
  "1172": {
    "name": "Arkade 90s Bulls #1172",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1172.png",
    "dna": "9dbd4b6d8faf1dbe1f7686a00f579f2a3df90a3c",
    "edition": 1172,
    "date": 1672533357962,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1172",
    "rarity_score": 332.7837281491364,
    "rarity_level": "COMMON"
  },
  "1309": {
    "name": "Arkade 90s Bulls #1309",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1309.png",
    "dna": "3d75e6d6e2c20e9a28d6e44b890c94ecd83681af",
    "edition": 1309,
    "date": 1672533358660,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1309",
    "rarity_score": 168.0499883428684,
    "rarity_level": "COMMON"
  },
  "121": {
    "name": "Arkade 90s Bulls #121",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/121.png",
    "dna": "430ff39175cf903ce74ecbfc11d1b67c7db0c790",
    "edition": 121,
    "date": 1672533359117,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "121",
    "rarity_score": 129.7084785605794,
    "rarity_level": "COMMON"
  },
  "1375": {
    "name": "Arkade 90s Bulls #1375",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1375.png",
    "dna": "7b6c2991509290192822bfa155411220e59f0b86",
    "edition": 1375,
    "date": 1672533359590,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1375",
    "rarity_score": 170.28770302880463,
    "rarity_level": "COMMON"
  },
  "568": {
    "name": "Arkade 90s Bulls #568",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/568.png",
    "dna": "86053baae6b60054d54dd312e3d011b5eca1033b",
    "edition": 568,
    "date": 1672533360072,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "568",
    "rarity_score": 238.1462223820667,
    "rarity_level": "COMMON"
  },
  "1850": {
    "name": "Arkade 90s Bulls #1850",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1850.png",
    "dna": "d38f207f20f11b22c250824def922cdf0094df53",
    "edition": 1850,
    "date": 1672533360544,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1850",
    "rarity_score": 248.5751681724889,
    "rarity_level": "COMMON"
  },
  "1941": {
    "name": "Arkade 90s Bulls #1941",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1941.png",
    "dna": "992884745b81129ec8e894d4c7c8507e86518365",
    "edition": 1941,
    "date": 1672533361015,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1941",
    "rarity_score": 284.87788040137514,
    "rarity_level": "COMMON"
  },
  "1846": {
    "name": "Arkade 90s Bulls #1846",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1846.png",
    "dna": "2e8cb2981f260998c4c5a4b59f7af8dfa1b25a76",
    "edition": 1846,
    "date": 1672533361533,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1846",
    "rarity_score": 180.96001739320909,
    "rarity_level": "COMMON"
  },
  "1560": {
    "name": "Arkade 90s Bulls #1560",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1560.png",
    "dna": "6b9523e886f5b9991dd24de37a36051c59fc149d",
    "edition": 1560,
    "date": 1672533361980,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1560",
    "rarity_score": 282.5598668794518,
    "rarity_level": "COMMON"
  },
  "1960": {
    "name": "Arkade 90s Bulls #1960",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1960.png",
    "dna": "935e587b2af9b86649cd02c16087b35b24b30b31",
    "edition": 1960,
    "date": 1672533363033,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1960",
    "rarity_score": 378.225434112761,
    "rarity_level": "COMMON"
  },
  "444": {
    "name": "Arkade 90s Bulls #444",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/444.png",
    "dna": "eda9b055f63a8a360a2d82a4935263c4f7264cc8",
    "edition": 444,
    "date": 1672533363511,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "444",
    "rarity_score": 231.8438026479898,
    "rarity_level": "COMMON"
  },
  "1089": {
    "name": "Arkade 90s Bulls #1089",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1089.png",
    "dna": "3051bf28381980c597b1201e57b4614cf44b5eff",
    "edition": 1089,
    "date": 1672533364659,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1089",
    "rarity_score": 179.10615976233555,
    "rarity_level": "COMMON"
  },
  "1419": {
    "name": "Arkade 90s Bulls #1419",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1419.png",
    "dna": "60927401416fb5748b6726411bad54d3e9cf8439",
    "edition": 1419,
    "date": 1672533365195,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1419",
    "rarity_score": 320.7843820230416,
    "rarity_level": "COMMON"
  },
  "1883": {
    "name": "Arkade 90s Bulls #1883",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1883.png",
    "dna": "919a651c3be5895c3c2b05e16adac8750b0235fc",
    "edition": 1883,
    "date": 1672533365760,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1883",
    "rarity_score": 197.337265984312,
    "rarity_level": "COMMON"
  },
  "234": {
    "name": "Arkade 90s Bulls #234",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/234.png",
    "dna": "d848b68e0675561e3fe5c03e6eb3da742f8655a9",
    "edition": 234,
    "date": 1672533366246,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "234",
    "rarity_score": 274.5250418258484,
    "rarity_level": "COMMON"
  },
  "588": {
    "name": "Arkade 90s Bulls #588",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/588.png",
    "dna": "7e5d0c0b050a10104c2c06934384827fe1839e88",
    "edition": 588,
    "date": 1672533366740,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "588",
    "rarity_score": 358.0807598826633,
    "rarity_level": "COMMON"
  },
  "710": {
    "name": "Arkade 90s Bulls #710",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/710.png",
    "dna": "7a6b9df786a16b1240250a78cd854b9b021f9554",
    "edition": 710,
    "date": 1672533367271,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "710",
    "rarity_score": 304.79454520030777,
    "rarity_level": "COMMON"
  },
  "1179": {
    "name": "Arkade 90s Bulls #1179",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1179.png",
    "dna": "0a91139a50bf0532515fabb95dbc5bb2b8605d29",
    "edition": 1179,
    "date": 1672533368333,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1179",
    "rarity_score": 363.6608709516993,
    "rarity_level": "COMMON"
  },
  "67": {
    "name": "Arkade 90s Bulls #67",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/67.png",
    "dna": "f5e21e79c9534274470a910306db260b6d531b2d",
    "edition": 67,
    "date": 1672533369366,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "67",
    "rarity_score": 197.28801912655385,
    "rarity_level": "COMMON"
  },
  "105": {
    "name": "Arkade 90s Bulls #105",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/105.png",
    "dna": "518be3bbf1771aaa9c1552927d7a8d3e87924c7d",
    "edition": 105,
    "date": 1672533370561,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "105",
    "rarity_score": 294.597647832028,
    "rarity_level": "COMMON"
  },
  "1138": {
    "name": "Arkade 90s Bulls #1138",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1138.png",
    "dna": "19617f125050caf5b47d097a02d98b92343510c2",
    "edition": 1138,
    "date": 1672533371082,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Red Guardian Mask",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1138",
    "rarity_score": 426.789949961336,
    "rarity_level": "COMMON"
  },
  "888": {
    "name": "Arkade 90s Bulls #888",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/888.png",
    "dna": "0533a31983c0ebd768175b14170f45e63db92aaf",
    "edition": 888,
    "date": 1672533371576,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "888",
    "rarity_score": 244.66810095998167,
    "rarity_level": "COMMON"
  },
  "1203": {
    "name": "Arkade 90s Bulls #1203",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1203.png",
    "dna": "490d848dfd1c1c2e77f18aae54efee63281ea1da",
    "edition": 1203,
    "date": 1672533372258,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1203",
    "rarity_score": 285.3964984302784,
    "rarity_level": "COMMON"
  },
  "791": {
    "name": "Arkade 90s Bulls #791",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/791.png",
    "dna": "f2bb1744b8edd0eb72fcfb7312bd11ab881f45b2",
    "edition": 791,
    "date": 1672533372947,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "791",
    "rarity_score": 240.85587208348426,
    "rarity_level": "COMMON"
  },
  "1354": {
    "name": "Arkade 90s Bulls #1354",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1354.png",
    "dna": "bbf898b44b770f4a8697c1a505f9c8deb0a7dcff",
    "edition": 1354,
    "date": 1672533373448,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1354",
    "rarity_score": 330.4836950074168,
    "rarity_level": "COMMON"
  },
  "1058": {
    "name": "Arkade 90s Bulls #1058",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1058.png",
    "dna": "73a374660f27727639ff5a704cae39f71fd56808",
    "edition": 1058,
    "date": 1672533373908,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1058",
    "rarity_score": 397.170100379115,
    "rarity_level": "COMMON"
  },
  "1955": {
    "name": "Arkade 90s Bulls #1955",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1955.png",
    "dna": "1a78ae68d1d31f17a721e8aedba5e0a301b51745",
    "edition": 1955,
    "date": 1672533374453,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1955",
    "rarity_score": 297.43091180883647,
    "rarity_level": "COMMON"
  },
  "1198": {
    "name": "Arkade 90s Bulls #1198",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1198.png",
    "dna": "98dfb29bb93daf5d3df6f1f53b37456b3784db69",
    "edition": 1198,
    "date": 1672533375674,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1198",
    "rarity_score": 171.48581094761383,
    "rarity_level": "COMMON"
  },
  "734": {
    "name": "Arkade 90s Bulls #734",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/734.png",
    "dna": "fe5f8be996c76e464ba36dae76398acce8eac888",
    "edition": 734,
    "date": 1672533376139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "734",
    "rarity_score": 197.0661906826078,
    "rarity_level": "COMMON"
  },
  "1800": {
    "name": "Arkade 90s Bulls #1800",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1800.png",
    "dna": "58bd5b88c867d0359b7a0365f5969b871b29fd9c",
    "edition": 1800,
    "date": 1672533376637,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1800",
    "rarity_score": 379.3457909179946,
    "rarity_level": "COMMON"
  },
  "21": {
    "name": "Arkade 90s Bulls #21",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/21.png",
    "dna": "af7a62b17f002095ea7172c923dad31233029092",
    "edition": 21,
    "date": 1672533377154,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "21",
    "rarity_score": 183.03901609290398,
    "rarity_level": "COMMON"
  },
  "992": {
    "name": "Arkade 90s Bulls #992",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/992.png",
    "dna": "175fbca81885e16c9ade8b9a609e0ed516ae5ea9",
    "edition": 992,
    "date": 1672533377613,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "992",
    "rarity_score": 224.15318933101653,
    "rarity_level": "COMMON"
  },
  "1713": {
    "name": "Arkade 90s Bulls #1713",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1713.png",
    "dna": "55118020c939870e8d8e0994337bb7ae967c0894",
    "edition": 1713,
    "date": 1672533378090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1713",
    "rarity_score": 284.59474928928046,
    "rarity_level": "COMMON"
  },
  "31": {
    "name": "Arkade 90s Bulls #31",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/31.png",
    "dna": "b72d872010914905dbcb8f401cebfa254400f9a4",
    "edition": 31,
    "date": 1672533378585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "31",
    "rarity_score": 244.92042290971668,
    "rarity_level": "COMMON"
  },
  "960": {
    "name": "Arkade 90s Bulls #960",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/960.png",
    "dna": "7b5374def5eaa5deab2ab709853b3d66515c3f5a",
    "edition": 960,
    "date": 1672533379831,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "960",
    "rarity_score": 331.14843707774014,
    "rarity_level": "COMMON"
  },
  "1688": {
    "name": "Arkade 90s Bulls #1688",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1688.png",
    "dna": "050c99846f6d22de6214c87a7024ba9998efe13e",
    "edition": 1688,
    "date": 1672533380405,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1688",
    "rarity_score": 379.22614494385925,
    "rarity_level": "COMMON"
  },
  "1621": {
    "name": "Arkade 90s Bulls #1621",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1621.png",
    "dna": "3b5a001353151587b8aacff2b7a2a423f1379e11",
    "edition": 1621,
    "date": 1672533381161,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1621",
    "rarity_score": 213.57597479704032,
    "rarity_level": "COMMON"
  },
  "1424": {
    "name": "Arkade 90s Bulls #1424",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1424.png",
    "dna": "378d95db65ac54fc0c318e14e5cfa96c8c5dfc64",
    "edition": 1424,
    "date": 1672533382223,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1424",
    "rarity_score": 240.85452506037637,
    "rarity_level": "COMMON"
  },
  "142": {
    "name": "Arkade 90s Bulls #142",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/142.png",
    "dna": "5d7c32def8da3a39bd6023943157ee1fb2569ab3",
    "edition": 142,
    "date": 1672533382723,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Superbull Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "142",
    "rarity_score": 623.0181760535712,
    "rarity_level": "RARE"
  },
  "292": {
    "name": "Arkade 90s Bulls #292",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/292.png",
    "dna": "1fd0cdb79967da4c25c0d56395260f1bf4f314e3",
    "edition": 292,
    "date": 1672533383213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "292",
    "rarity_score": 212.66727149288414,
    "rarity_level": "COMMON"
  },
  "206": {
    "name": "Arkade 90s Bulls #206",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/206.png",
    "dna": "ea748443bee6f66d6a80051eecb351e26a0a393c",
    "edition": 206,
    "date": 1672533384342,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "206",
    "rarity_score": 422.98355219659436,
    "rarity_level": "COMMON"
  },
  "1618": {
    "name": "Arkade 90s Bulls #1618",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1618.png",
    "dna": "c4d02114ee6b69a824828d9148bb7608b9e3177c",
    "edition": 1618,
    "date": 1672533384828,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1618",
    "rarity_score": 294.0427211977597,
    "rarity_level": "COMMON"
  },
  "339": {
    "name": "Arkade 90s Bulls #339",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/339.png",
    "dna": "289de7e05f75ee2fe1b207c746f97846b57e70f1",
    "edition": 339,
    "date": 1672533385342,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "339",
    "rarity_score": 311.784390073507,
    "rarity_level": "COMMON"
  },
  "1171": {
    "name": "Arkade 90s Bulls #1171",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1171.png",
    "dna": "4ac982dfe2a8a65b7406f42c2ea21811a3906a97",
    "edition": 1171,
    "date": 1672533385824,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1171",
    "rarity_score": 298.5526932538015,
    "rarity_level": "COMMON"
  },
  "1319": {
    "name": "Arkade 90s Bulls #1319",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1319.png",
    "dna": "ffef342383f375f1cf38ee54f0368c2c11270b0a",
    "edition": 1319,
    "date": 1672533386339,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Party Tee",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1319",
    "rarity_score": 632.6497693098423,
    "rarity_level": "RARE"
  },
  "1554": {
    "name": "Arkade 90s Bulls #1554",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1554.png",
    "dna": "8d050e494e6c357bda525f3450203d475b8bb920",
    "edition": 1554,
    "date": 1672533386813,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1554",
    "rarity_score": 219.28816822547998,
    "rarity_level": "COMMON"
  },
  "1371": {
    "name": "Arkade 90s Bulls #1371",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1371.png",
    "dna": "356764c685a2aad2697e631927afa9df70f34f7b",
    "edition": 1371,
    "date": 1672533387804,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1371",
    "rarity_score": 200.32282245942162,
    "rarity_level": "COMMON"
  },
  "1070": {
    "name": "Arkade 90s Bulls #1070",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1070.png",
    "dna": "4134610be082d66f6e107590f68fb76c002efce5",
    "edition": 1070,
    "date": 1672533388350,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1070",
    "rarity_score": 302.4103901800491,
    "rarity_level": "COMMON"
  },
  "1937": {
    "name": "Arkade 90s Bulls #1937",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1937.png",
    "dna": "bc84972413bd8a8618331e26c9490b38ba4dc2df",
    "edition": 1937,
    "date": 1672533388823,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "Football Head", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1937",
    "rarity_score": 584.7901856781456,
    "rarity_level": "RARE"
  },
  "1495": {
    "name": "Arkade 90s Bulls #1495",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1495.png",
    "dna": "0df3f7ec95c1055fd3daa285acf79ac1b2f1e314",
    "edition": 1495,
    "date": 1672533389951,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1495",
    "rarity_score": 570.7889343846646,
    "rarity_level": "RARE"
  },
  "379": {
    "name": "Arkade 90s Bulls #379",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/379.png",
    "dna": "94f2dd6b2b0cd3fd8e3677ffafb129610b93afc6",
    "edition": 379,
    "date": 1672533390430,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "379",
    "rarity_score": 337.44726620713675,
    "rarity_level": "COMMON"
  },
  "1086": {
    "name": "Arkade 90s Bulls #1086",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1086.png",
    "dna": "e1cd8023981d2c15c20bf10924ec91c13857629e",
    "edition": 1086,
    "date": 1672533391599,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1086",
    "rarity_score": 317.4804107409634,
    "rarity_level": "COMMON"
  },
  "1310": {
    "name": "Arkade 90s Bulls #1310",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1310.png",
    "dna": "28430b87fea24286a47c085f5c044ff5d32a49de",
    "edition": 1310,
    "date": 1672533392078,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1310",
    "rarity_score": 205.39191079404964,
    "rarity_level": "COMMON"
  },
  "1670": {
    "name": "Arkade 90s Bulls #1670",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1670.png",
    "dna": "1125f427c32ce059c4a85fa4b464063c171b4257",
    "edition": 1670,
    "date": 1672533392560,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1670",
    "rarity_score": 221.86330205316125,
    "rarity_level": "COMMON"
  },
  "1880": {
    "name": "Arkade 90s Bulls #1880",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1880.png",
    "dna": "718938265d58eef6d41e478e1ac47f561b62f7fa",
    "edition": 1880,
    "date": 1672533393018,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1880",
    "rarity_score": 239.0075816896296,
    "rarity_level": "COMMON"
  },
  "973": {
    "name": "Arkade 90s Bulls #973",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/973.png",
    "dna": "c39cf79f72112821aa729da15629295cb89670e7",
    "edition": 973,
    "date": 1672533393488,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "973",
    "rarity_score": 113.15416006551749,
    "rarity_level": "COMMON"
  },
  "1286": {
    "name": "Arkade 90s Bulls #1286",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1286.png",
    "dna": "b50108bcaa8b0d1c58918cf9c0459482ed05741c",
    "edition": 1286,
    "date": 1672533393947,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1286",
    "rarity_score": 297.4152195760413,
    "rarity_level": "COMMON"
  },
  "947": {
    "name": "Arkade 90s Bulls #947",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/947.png",
    "dna": "4ce6c6f02e198268f6615c4389900fc69a1d868d",
    "edition": 947,
    "date": 1672533394496,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "947",
    "rarity_score": 125.43046164054695,
    "rarity_level": "COMMON"
  },
  "1142": {
    "name": "Arkade 90s Bulls #1142",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1142.png",
    "dna": "3832a0d6482e725f42ae7eb7a133f5dae39fe80e",
    "edition": 1142,
    "date": 1672533395252,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1142",
    "rarity_score": 209.99210367999595,
    "rarity_level": "COMMON"
  },
  "818": {
    "name": "Arkade 90s Bulls #818",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/818.png",
    "dna": "a532af8cede233e51e256a9487224ccde0911a85",
    "edition": 818,
    "date": 1672533395806,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "818",
    "rarity_score": 438.1333153960969,
    "rarity_level": "COMMON"
  },
  "174": {
    "name": "Arkade 90s Bulls #174",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/174.png",
    "dna": "86e46352b17ceca9e2d9e9c231bef6b5f487f5c0",
    "edition": 174,
    "date": 1672533396296,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "174",
    "rarity_score": 259.7138225182978,
    "rarity_level": "COMMON"
  },
  "1970": {
    "name": "Arkade 90s Bulls #1970",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1970.png",
    "dna": "1de5892c8abe0d7042d0a4f86a3aa1abb3c8b3d1",
    "edition": 1970,
    "date": 1672533396772,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1970",
    "rarity_score": 208.79079736757575,
    "rarity_level": "COMMON"
  },
  "696": {
    "name": "Arkade 90s Bulls #696",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/696.png",
    "dna": "cf841c1cc83b318153ec0faa76ab88e1a00bad13",
    "edition": 696,
    "date": 1672533397890,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "696",
    "rarity_score": 473.9580201641356,
    "rarity_level": "RARE"
  },
  "608": {
    "name": "Arkade 90s Bulls #608",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/608.png",
    "dna": "255d0f36c631d3eb3c98bd0c65c8ed153781e2d8",
    "edition": 608,
    "date": 1672533398358,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "608",
    "rarity_score": 234.011233434859,
    "rarity_level": "COMMON"
  },
  "58": {
    "name": "Arkade 90s Bulls #58",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/58.png",
    "dna": "a10d2272d1142dc7bc3a3780da38dd1429b7ed9a",
    "edition": 58,
    "date": 1672533398851,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "58",
    "rarity_score": 279.1058481496048,
    "rarity_level": "COMMON"
  },
  "149": {
    "name": "Arkade 90s Bulls #149",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/149.png",
    "dna": "de41a76a0047e945c694fdaeeb2de015335b0193",
    "edition": 149,
    "date": 1672533399319,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "149",
    "rarity_score": 120.65267540945084,
    "rarity_level": "COMMON"
  },
  "877": {
    "name": "Arkade 90s Bulls #877",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/877.png",
    "dna": "60160880bec79010cdec9175c1a1830a53977f00",
    "edition": 877,
    "date": 1672533399782,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "877",
    "rarity_score": 229.4344811980323,
    "rarity_level": "COMMON"
  },
  "1475": {
    "name": "Arkade 90s Bulls #1475",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1475.png",
    "dna": "79f2fc393a384c9173c581870e78dd10e8aae009",
    "edition": 1475,
    "date": 1672533400242,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1475",
    "rarity_score": 289.7690686820236,
    "rarity_level": "COMMON"
  },
  "1385": {
    "name": "Arkade 90s Bulls #1385",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1385.png",
    "dna": "30c286efe8c68e2ba9669cea3ef8a160000d3bca",
    "edition": 1385,
    "date": 1672533400791,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1385",
    "rarity_score": 277.6190234412146,
    "rarity_level": "COMMON"
  },
  "1276": {
    "name": "Arkade 90s Bulls #1276",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1276.png",
    "dna": "062b78cae68f26d69273650646ed1f1eccce8fa3",
    "edition": 1276,
    "date": 1672533401411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1276",
    "rarity_score": 230.6596315332009,
    "rarity_level": "COMMON"
  },
  "1613": {
    "name": "Arkade 90s Bulls #1613",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1613.png",
    "dna": "f400e01b367013542853782c8cb38584b83bab66",
    "edition": 1613,
    "date": 1672533402046,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1613",
    "rarity_score": 290.55311107652943,
    "rarity_level": "COMMON"
  },
  "1839": {
    "name": "Arkade 90s Bulls #1839",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1839.png",
    "dna": "55b91211168d78be624f6268dd3ec417307f72ff",
    "edition": 1839,
    "date": 1672533402489,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1839",
    "rarity_score": 298.2237023055676,
    "rarity_level": "COMMON"
  },
  "1129": {
    "name": "Arkade 90s Bulls #1129",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1129.png",
    "dna": "29e451ee63813ec838a235cdbe359f4565531d0a",
    "edition": 1129,
    "date": 1672533402983,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1129",
    "rarity_score": 191.37787238965043,
    "rarity_level": "COMMON"
  },
  "1480": {
    "name": "Arkade 90s Bulls #1480",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1480.png",
    "dna": "a9a49b645833e83e7e6f57fc6fd54bc0f1e0d393",
    "edition": 1480,
    "date": 1672533403503,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1480",
    "rarity_score": 209.1084148674794,
    "rarity_level": "COMMON"
  },
  "660": {
    "name": "Arkade 90s Bulls #660",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/660.png",
    "dna": "217642d896325cd688a104c5442348a949884b45",
    "edition": 660,
    "date": 1672533404209,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Yuki Muko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "660",
    "rarity_score": 627.6467853858928,
    "rarity_level": "RARE"
  },
  "589": {
    "name": "Arkade 90s Bulls #589",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/589.png",
    "dna": "7f2fbc4d0f8d16a0ded45e69c2df86d6727f4131",
    "edition": 589,
    "date": 1672533404690,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "589",
    "rarity_score": 254.84750442112028,
    "rarity_level": "COMMON"
  },
  "760": {
    "name": "Arkade 90s Bulls #760",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/760.png",
    "dna": "4e6c7ec57f884acb96b94ce00873e915ec7bc7a5",
    "edition": 760,
    "date": 1672533405878,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "760",
    "rarity_score": 267.0861399367083,
    "rarity_level": "COMMON"
  },
  "635": {
    "name": "Arkade 90s Bulls #635",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/635.png",
    "dna": "5a17573e37671c3637b135fb97eafea1fa1d46be",
    "edition": 635,
    "date": 1672533407020,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "635",
    "rarity_score": 226.5666819649005,
    "rarity_level": "COMMON"
  },
  "1185": {
    "name": "Arkade 90s Bulls #1185",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1185.png",
    "dna": "1ad42691961a5b1ae85c4a5ca1b791d1b8e351c3",
    "edition": 1185,
    "date": 1672533407672,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1185",
    "rarity_score": 316.03234800197293,
    "rarity_level": "COMMON"
  },
  "557": {
    "name": "Arkade 90s Bulls #557",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/557.png",
    "dna": "7beab79ced1b819b75a3358b3bda6295d486697d",
    "edition": 557,
    "date": 1672533408900,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "557",
    "rarity_score": 204.29188694197776,
    "rarity_level": "COMMON"
  },
  "7": {
    "name": "Arkade 90s Bulls #7",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/7.png",
    "dna": "e06a055ec1cffe9935b0fe848a51d2dcca3929e0",
    "edition": 7,
    "date": 1672533409408,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "7",
    "rarity_score": 238.4934187830721,
    "rarity_level": "COMMON"
  },
  "948": {
    "name": "Arkade 90s Bulls #948",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/948.png",
    "dna": "4ecae27a613c776b00bf4ede3a468b117e6e41a0",
    "edition": 948,
    "date": 1672533409909,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "948",
    "rarity_score": 260.37756418711814,
    "rarity_level": "COMMON"
  },
  "93": {
    "name": "Arkade 90s Bulls #93",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/93.png",
    "dna": "14c82858f06ac0d63725a52bd5acacb06216cf92",
    "edition": 93,
    "date": 1672533410410,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "93",
    "rarity_score": 319.9906100834531,
    "rarity_level": "COMMON"
  },
  "434": {
    "name": "Arkade 90s Bulls #434",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/434.png",
    "dna": "de3af5a0fa7b15e1e63938f2e8b49ec8b0921cba",
    "edition": 434,
    "date": 1672533411505,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "434",
    "rarity_score": 260.15041448549005,
    "rarity_level": "COMMON"
  },
  "744": {
    "name": "Arkade 90s Bulls #744",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/744.png",
    "dna": "b06551a4a2c51c9db88da396e4ae3e10295739dc",
    "edition": 744,
    "date": 1672533412028,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "744",
    "rarity_score": 262.50549584640106,
    "rarity_level": "COMMON"
  },
  "240": {
    "name": "Arkade 90s Bulls #240",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/240.png",
    "dna": "632311ffbac68d2851bf6e6588674667638d1a1d",
    "edition": 240,
    "date": 1672533412486,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "240",
    "rarity_score": 157.88662213047564,
    "rarity_level": "COMMON"
  },
  "732": {
    "name": "Arkade 90s Bulls #732",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/732.png",
    "dna": "b6e603044e26471a15da2e72573b1503d62d7500",
    "edition": 732,
    "date": 1672533413558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "732",
    "rarity_score": 279.9652949013242,
    "rarity_level": "COMMON"
  },
  "263": {
    "name": "Arkade 90s Bulls #263",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/263.png",
    "dna": "c9b15c6e5f40610335880efe4e191860690208b1",
    "edition": 263,
    "date": 1672533414044,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "263",
    "rarity_score": 207.55326372576212,
    "rarity_level": "COMMON"
  },
  "1890": {
    "name": "Arkade 90s Bulls #1890",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1890.png",
    "dna": "a1b0d09b7356169278a99054349eeaff842eab27",
    "edition": 1890,
    "date": 1672533414567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1890",
    "rarity_score": 292.9624652758878,
    "rarity_level": "COMMON"
  },
  "1384": {
    "name": "Arkade 90s Bulls #1384",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1384.png",
    "dna": "d7beb4516d4503548ba028748e78ec181150dec5",
    "edition": 1384,
    "date": 1672533415327,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1384",
    "rarity_score": 179.3471083400255,
    "rarity_level": "COMMON"
  },
  "967": {
    "name": "Arkade 90s Bulls #967",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/967.png",
    "dna": "ef37b9aa00eeeac0ded733ef012d1e30f9a04157",
    "edition": 967,
    "date": 1672533416119,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "967",
    "rarity_score": 272.8465931923892,
    "rarity_level": "COMMON"
  },
  "1437": {
    "name": "Arkade 90s Bulls #1437",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1437.png",
    "dna": "c5440c8f84f3607f258a20ea0b1c429fe8eed87d",
    "edition": 1437,
    "date": 1672533416779,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1437",
    "rarity_score": 216.71252581428655,
    "rarity_level": "COMMON"
  },
  "1809": {
    "name": "Arkade 90s Bulls #1809",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1809.png",
    "dna": "dad1b6e4c3409aba6dd802722a9b8f977d0a2800",
    "edition": 1809,
    "date": 1672533417231,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Black Tee", "score": 1990.0 },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1809",
    "rarity_score": 2394.626671922481,
    "rarity_level": "LEGENDARY"
  },
  "465": {
    "name": "Arkade 90s Bulls #465",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/465.png",
    "dna": "2f53c26403a66b66912c50aa858fe1f65f24b452",
    "edition": 465,
    "date": 1672533417753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "465",
    "rarity_score": 219.51169640105394,
    "rarity_level": "COMMON"
  },
  "1693": {
    "name": "Arkade 90s Bulls #1693",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1693.png",
    "dna": "5886a8beea0271c63b198459a319939211282cf5",
    "edition": 1693,
    "date": 1672533418269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1693",
    "rarity_score": 344.35092374833744,
    "rarity_level": "COMMON"
  },
  "1491": {
    "name": "Arkade 90s Bulls #1491",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1491.png",
    "dna": "ee607b378234561a9e2d06d4580931b2d17d3332",
    "edition": 1491,
    "date": 1672533418911,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1491",
    "rarity_score": 196.96200451277238,
    "rarity_level": "COMMON"
  },
  "1465": {
    "name": "Arkade 90s Bulls #1465",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1465.png",
    "dna": "a23e0ff08afaab07b54c3532cb3f58aa0dbdf952",
    "edition": 1465,
    "date": 1672533419419,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1465",
    "rarity_score": 464.6070130721271,
    "rarity_level": "RARE"
  },
  "271": {
    "name": "Arkade 90s Bulls #271",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/271.png",
    "dna": "35f3ed1b9c7b826d6bf916dd7934c0258e9f80d8",
    "edition": 271,
    "date": 1672533419949,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "271",
    "rarity_score": 155.91546704260838,
    "rarity_level": "COMMON"
  },
  "1786": {
    "name": "Arkade 90s Bulls #1786",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1786.png",
    "dna": "ddd68eeb47528b294cad0813b4cb1b28f744ccad",
    "edition": 1786,
    "date": 1672533420671,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1786",
    "rarity_score": 183.26492835838496,
    "rarity_level": "COMMON"
  },
  "1202": {
    "name": "Arkade 90s Bulls #1202",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1202.png",
    "dna": "ba0b8a9a52103346dc87e2b74926d08f29b35d19",
    "edition": 1202,
    "date": 1672533421175,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1202",
    "rarity_score": 351.6271265190693,
    "rarity_level": "COMMON"
  },
  "1924": {
    "name": "Arkade 90s Bulls #1924",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1924.png",
    "dna": "4452c3fbe6f8afa9881e2ece1573ee02f3d9853e",
    "edition": 1924,
    "date": 1672533421666,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1924",
    "rarity_score": 307.3957335488262,
    "rarity_level": "COMMON"
  },
  "1953": {
    "name": "Arkade 90s Bulls #1953",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1953.png",
    "dna": "76f05c518b540116008c823890719f6b48bba1bb",
    "edition": 1953,
    "date": 1672533422151,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Guardian Suit",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1953",
    "rarity_score": 380.066872360202,
    "rarity_level": "COMMON"
  },
  "1824": {
    "name": "Arkade 90s Bulls #1824",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1824.png",
    "dna": "6c7e2005b2e4193630cf4d78e795e7bca33cc74d",
    "edition": 1824,
    "date": 1672533422620,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1824",
    "rarity_score": 335.04193172440574,
    "rarity_level": "COMMON"
  },
  "1918": {
    "name": "Arkade 90s Bulls #1918",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1918.png",
    "dna": "d6e487e2d34bc316bbf87368e5024df90f27b770",
    "edition": 1918,
    "date": 1672533423116,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1918",
    "rarity_score": 302.4683713503657,
    "rarity_level": "COMMON"
  },
  "1461": {
    "name": "Arkade 90s Bulls #1461",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1461.png",
    "dna": "3ee5c5d9e5ab249c2184f2760a6c1574cf5a9d41",
    "edition": 1461,
    "date": 1672533423629,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1461",
    "rarity_score": 291.16805134733073,
    "rarity_level": "COMMON"
  },
  "1053": {
    "name": "Arkade 90s Bulls #1053",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1053.png",
    "dna": "5d0a319dcf4c4400a1bd89b746c00a27565a0ec5",
    "edition": 1053,
    "date": 1672533424108,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1053",
    "rarity_score": 254.19214811295728,
    "rarity_level": "COMMON"
  },
  "1174": {
    "name": "Arkade 90s Bulls #1174",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1174.png",
    "dna": "6c1e82baf65a98668633057ec58f122b1e4bcfd7",
    "edition": 1174,
    "date": 1672533424654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1174",
    "rarity_score": 350.9851200955073,
    "rarity_level": "COMMON"
  },
  "1244": {
    "name": "Arkade 90s Bulls #1244",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1244.png",
    "dna": "c532ce7a4d632abdde6391b9c25a9f88e8e2f59b",
    "edition": 1244,
    "date": 1672533426007,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1244",
    "rarity_score": 228.15739314586565,
    "rarity_level": "COMMON"
  },
  "1663": {
    "name": "Arkade 90s Bulls #1663",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1663.png",
    "dna": "d7303b4de707472f4ef74dc24087aa629d9f1b44",
    "edition": 1663,
    "date": 1672533426483,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1663",
    "rarity_score": 616.8978101709705,
    "rarity_level": "RARE"
  },
  "1808": {
    "name": "Arkade 90s Bulls #1808",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1808.png",
    "dna": "0d6881ad2c1a99dcd5c33f5a9c1c4ca4184c3634",
    "edition": 1808,
    "date": 1672533427562,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1808",
    "rarity_score": 201.2229446760309,
    "rarity_level": "COMMON"
  },
  "1840": {
    "name": "Arkade 90s Bulls #1840",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1840.png",
    "dna": "af79d2949b243cc026730314ad16f539fe521fe2",
    "edition": 1840,
    "date": 1672533428755,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1840",
    "rarity_score": 281.8654938539934,
    "rarity_level": "COMMON"
  },
  "407": {
    "name": "Arkade 90s Bulls #407",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/407.png",
    "dna": "6023a344174fe9640a3612ea516f18e6263556a2",
    "edition": 407,
    "date": 1672533429268,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "407",
    "rarity_score": 260.0745170187338,
    "rarity_level": "COMMON"
  },
  "1655": {
    "name": "Arkade 90s Bulls #1655",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1655.png",
    "dna": "d5d8ae3744f4f70ad1b1970b1612960be4573ca0",
    "edition": 1655,
    "date": 1672533430844,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1655",
    "rarity_score": 446.12101146027254,
    "rarity_level": "COMMON"
  },
  "43": {
    "name": "Arkade 90s Bulls #43",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/43.png",
    "dna": "367b6ef61d12cbddec5e6bd2d5209b4efc3adc7f",
    "edition": 43,
    "date": 1672533431434,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "43",
    "rarity_score": 174.36148926327346,
    "rarity_level": "COMMON"
  },
  "1759": {
    "name": "Arkade 90s Bulls #1759",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1759.png",
    "dna": "252c9e04b44334cf18640c003bd4697435d9e9b5",
    "edition": 1759,
    "date": 1672533431895,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Tongue Out",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1759",
    "rarity_score": 373.34201721321966,
    "rarity_level": "COMMON"
  },
  "1728": {
    "name": "Arkade 90s Bulls #1728",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1728.png",
    "dna": "67f39928d71cf38a99e42f768e98db8639b9da7b",
    "edition": 1728,
    "date": 1672533432366,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Gray Evil",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1728",
    "rarity_score": 336.10785272499777,
    "rarity_level": "COMMON"
  },
  "534": {
    "name": "Arkade 90s Bulls #534",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/534.png",
    "dna": "178bc309115d75de953ec6c6118e593644438a1b",
    "edition": 534,
    "date": 1672533432879,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Smile",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "534",
    "rarity_score": 173.7538947000041,
    "rarity_level": "COMMON"
  },
  "1830": {
    "name": "Arkade 90s Bulls #1830",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1830.png",
    "dna": "0fce64abeed4512d59dd99985154019d4e723270",
    "edition": 1830,
    "date": 1672533433367,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1830",
    "rarity_score": 277.5568207350774,
    "rarity_level": "COMMON"
  },
  "453": {
    "name": "Arkade 90s Bulls #453",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/453.png",
    "dna": "fbc06f1fd0c419e9e4f4e5c2fd8cb1d8157e1f24",
    "edition": 453,
    "date": 1672533433862,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Sad",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "453",
    "rarity_score": 143.38365494004157,
    "rarity_level": "COMMON"
  },
  "1926": {
    "name": "Arkade 90s Bulls #1926",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1926.png",
    "dna": "86bd2ef0055922be2528a4655db0810351dcdbd1",
    "edition": 1926,
    "date": 1672533434339,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1926",
    "rarity_score": 184.89706877922768,
    "rarity_level": "COMMON"
  },
  "1903": {
    "name": "Arkade 90s Bulls #1903",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1903.png",
    "dna": "1115779816f2667cb1e2eccf6f3a4997c6637262",
    "edition": 1903,
    "date": 1672533434799,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Gray Happy",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1903",
    "rarity_score": 97.35130265926546,
    "rarity_level": "COMMON"
  },
  "1224": {
    "name": "Arkade 90s Bulls #1224",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1224.png",
    "dna": "e429b1e1513b881bc0850bb90b92bcd1f9504520",
    "edition": 1224,
    "date": 1672533435996,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Gray Angry", "score": 79.6 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1224",
    "rarity_score": 149.4103078655876,
    "rarity_level": "COMMON"
  },
  "1884": {
    "name": "Arkade 90s Bulls #1884",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1884.png",
    "dna": "6a6f4bb7c9206b3b2b3e264396198b80d80ed626",
    "edition": 1884,
    "date": 1672533436489,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1884",
    "rarity_score": 497.0375611273141,
    "rarity_level": "RARE"
  },
  "1306": {
    "name": "Arkade 90s Bulls #1306",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1306.png",
    "dna": "dd3a4b55fbfdfe6495fc65be0d19280de9ecabee",
    "edition": 1306,
    "date": 1672533436996,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1306",
    "rarity_score": 397.9858723629872,
    "rarity_level": "COMMON"
  },
  "767": {
    "name": "Arkade 90s Bulls #767",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/767.png",
    "dna": "e3e192c5db75da8e20a9b90d2b1a13b149b4c42c",
    "edition": 767,
    "date": 1672533437446,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "767",
    "rarity_score": 365.9887723021948,
    "rarity_level": "COMMON"
  },
  "97": {
    "name": "Arkade 90s Bulls #97",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/97.png",
    "dna": "9def9ae14c2d2f16113deb172a3a1c04ee0b94f1",
    "edition": 97,
    "date": 1672533438624,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "97",
    "rarity_score": 283.8813582296814,
    "rarity_level": "COMMON"
  },
  "862": {
    "name": "Arkade 90s Bulls #862",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/862.png",
    "dna": "ae1c5e8bd5b82376dac2e7857b964155ada71e62",
    "edition": 862,
    "date": 1672533439086,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "862",
    "rarity_score": 228.5991569778375,
    "rarity_level": "COMMON"
  },
  "1299": {
    "name": "Arkade 90s Bulls #1299",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1299.png",
    "dna": "c842e3e589c8d2876895473d30b7d0575438f561",
    "edition": 1299,
    "date": 1672533439838,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1299",
    "rarity_score": 209.98344940303002,
    "rarity_level": "COMMON"
  },
  "1547": {
    "name": "Arkade 90s Bulls #1547",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1547.png",
    "dna": "2ea98650a5651854d4416f6f65d95a7165a5f092",
    "edition": 1547,
    "date": 1672533440309,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1547",
    "rarity_score": 319.96320907533493,
    "rarity_level": "COMMON"
  },
  "1414": {
    "name": "Arkade 90s Bulls #1414",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1414.png",
    "dna": "0e6036caf1ca79708fdca9a050c32dd5cd0ecb0f",
    "edition": 1414,
    "date": 1672533440769,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1414",
    "rarity_score": 216.1690872439141,
    "rarity_level": "COMMON"
  },
  "1519": {
    "name": "Arkade 90s Bulls #1519",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1519.png",
    "dna": "7123136e64aa8fd5d615f11546fed070859eadfb",
    "edition": 1519,
    "date": 1672533441199,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1519",
    "rarity_score": 363.27152040173473,
    "rarity_level": "COMMON"
  },
  "903": {
    "name": "Arkade 90s Bulls #903",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/903.png",
    "dna": "f00da43d48392f6a9a5dfb0a824ff91045ed36de",
    "edition": 903,
    "date": 1672533442540,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "903",
    "rarity_score": 189.30744267155524,
    "rarity_level": "COMMON"
  },
  "76": {
    "name": "Arkade 90s Bulls #76",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/76.png",
    "dna": "b6d03ad8a1421a2ec4f49c20e0d0590c6c9c0891",
    "edition": 76,
    "date": 1672533442990,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "76",
    "rarity_score": 227.0915018254943,
    "rarity_level": "COMMON"
  },
  "162": {
    "name": "Arkade 90s Bulls #162",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/162.png",
    "dna": "6a3b65f7d275fdb782d92d1edb9cbabd2710559b",
    "edition": 162,
    "date": 1672533443474,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "162",
    "rarity_score": 229.99148764966728,
    "rarity_level": "COMMON"
  },
  "529": {
    "name": "Arkade 90s Bulls #529",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/529.png",
    "dna": "0a65f0c887030b7063c2491af5af0f5449054614",
    "edition": 529,
    "date": 1672533444639,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "529",
    "rarity_score": 318.07970539233287,
    "rarity_level": "COMMON"
  },
  "1265": {
    "name": "Arkade 90s Bulls #1265",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1265.png",
    "dna": "d18afe756185e37319fff678c2a952789e688170",
    "edition": 1265,
    "date": 1672533445133,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "Jokester Mouth",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1265",
    "rarity_score": 392.50820283536444,
    "rarity_level": "COMMON"
  },
  "803": {
    "name": "Arkade 90s Bulls #803",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/803.png",
    "dna": "eebcd5f43b657dd10c07bb3bbae0e23d26c64384",
    "edition": 803,
    "date": 1672533445661,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "803",
    "rarity_score": 319.4416949933076,
    "rarity_level": "COMMON"
  },
  "95": {
    "name": "Arkade 90s Bulls #95",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/95.png",
    "dna": "5cc0ff5fb43046284a6a6cf8da5dea569d2b3579",
    "edition": 95,
    "date": 1672533446859,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "95",
    "rarity_score": 402.4040201563814,
    "rarity_level": "COMMON"
  },
  "1771": {
    "name": "Arkade 90s Bulls #1771",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1771.png",
    "dna": "84682b1d0ec988dc51a744622433052061986411",
    "edition": 1771,
    "date": 1672533447309,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1771",
    "rarity_score": 215.39205383362176,
    "rarity_level": "COMMON"
  },
  "152": {
    "name": "Arkade 90s Bulls #152",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/152.png",
    "dna": "1d87d96f16687e5081e3248271e948f434a15fa6",
    "edition": 152,
    "date": 1672533447744,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "152",
    "rarity_score": 468.7036277937725,
    "rarity_level": "RARE"
  },
  "1195": {
    "name": "Arkade 90s Bulls #1195",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1195.png",
    "dna": "eb5602f245233a60ff3ae3da7092261e5c1f00a5",
    "edition": 1195,
    "date": 1672533448872,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1195",
    "rarity_score": 241.12921769838104,
    "rarity_level": "COMMON"
  },
  "233": {
    "name": "Arkade 90s Bulls #233",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/233.png",
    "dna": "6de189216ef91a809ba3cc1207ec4077165d1379",
    "edition": 233,
    "date": 1672533449359,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "233",
    "rarity_score": 294.16725456149595,
    "rarity_level": "COMMON"
  },
  "1016": {
    "name": "Arkade 90s Bulls #1016",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1016.png",
    "dna": "216299ee9543c5e4c15a54b3b82221891163c5e0",
    "edition": 1016,
    "date": 1672533449830,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Hedgehog Mask",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1016",
    "rarity_score": 343.5374127424528,
    "rarity_level": "COMMON"
  },
  "766": {
    "name": "Arkade 90s Bulls #766",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/766.png",
    "dna": "c58df46b2819f95c800c8c6759ff5c5651e394d7",
    "edition": 766,
    "date": 1672533450291,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "766",
    "rarity_score": 228.9103293121135,
    "rarity_level": "COMMON"
  },
  "1909": {
    "name": "Arkade 90s Bulls #1909",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1909.png",
    "dna": "f36d5db3a4ff1908d87ff3063e5f3028ebdd1a94",
    "edition": 1909,
    "date": 1672533451164,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1909",
    "rarity_score": 272.70317815300507,
    "rarity_level": "COMMON"
  },
  "748": {
    "name": "Arkade 90s Bulls #748",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/748.png",
    "dna": "7f33cc0176dffc502769bfc53c5afc35ebbf3f8d",
    "edition": 748,
    "date": 1672533451641,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "748",
    "rarity_score": 201.55865444166022,
    "rarity_level": "COMMON"
  },
  "1589": {
    "name": "Arkade 90s Bulls #1589",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1589.png",
    "dna": "828d5d879f5d16d24d191381a40058ea3cda325a",
    "edition": 1589,
    "date": 1672533452103,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1589",
    "rarity_score": 223.92844680866108,
    "rarity_level": "COMMON"
  },
  "604": {
    "name": "Arkade 90s Bulls #604",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/604.png",
    "dna": "589cca6c01b76e093c51279dc70dd563acb08b6e",
    "edition": 604,
    "date": 1672533452572,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pickels Overalls",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "604",
    "rarity_score": 860.1239265901557,
    "rarity_level": "RARE"
  },
  "326": {
    "name": "Arkade 90s Bulls #326",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/326.png",
    "dna": "0ff2e78ee35191668592d9c7f27bbb804a19f6f2",
    "edition": 326,
    "date": 1672533453090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet 2",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "326",
    "rarity_score": 438.31868173463727,
    "rarity_level": "COMMON"
  },
  "281": {
    "name": "Arkade 90s Bulls #281",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/281.png",
    "dna": "9c5bafaac90eaa95c303b6c8ae0c60d1a9cb40ab",
    "edition": 281,
    "date": 1672533453801,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "281",
    "rarity_score": 384.9230204208737,
    "rarity_level": "COMMON"
  },
  "1431": {
    "name": "Arkade 90s Bulls #1431",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1431.png",
    "dna": "68eb64bcf050874acbfd08b6448baebdccf51d0e",
    "edition": 1431,
    "date": 1672533454285,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1431",
    "rarity_score": 290.3462948005555,
    "rarity_level": "COMMON"
  },
  "111": {
    "name": "Arkade 90s Bulls #111",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/111.png",
    "dna": "b380b827e01d89f950b180952d90bf12ad26f146",
    "edition": 111,
    "date": 1672533454753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "111",
    "rarity_score": 214.32528494582613,
    "rarity_level": "COMMON"
  },
  "1544": {
    "name": "Arkade 90s Bulls #1544",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1544.png",
    "dna": "8d37c243409a613593f042ae2ae487dea3435fec",
    "edition": 1544,
    "date": 1672533455505,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1544",
    "rarity_score": 309.7945489340358,
    "rarity_level": "COMMON"
  },
  "1380": {
    "name": "Arkade 90s Bulls #1380",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1380.png",
    "dna": "8a5ca9e990ff9d15db9fd51045002d853edaff11",
    "edition": 1380,
    "date": 1672533455982,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1380",
    "rarity_score": 200.9212744780545,
    "rarity_level": "COMMON"
  },
  "1246": {
    "name": "Arkade 90s Bulls #1246",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1246.png",
    "dna": "d957537526f76adaf2e48988941803657c911372",
    "edition": 1246,
    "date": 1672533457106,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1246",
    "rarity_score": 215.2619795648186,
    "rarity_level": "COMMON"
  },
  "1167": {
    "name": "Arkade 90s Bulls #1167",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1167.png",
    "dna": "7d83daf39a87afcc838127a026efe6d73c8f8362",
    "edition": 1167,
    "date": 1672533457825,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1167",
    "rarity_score": 196.32352218414383,
    "rarity_level": "COMMON"
  },
  "470": {
    "name": "Arkade 90s Bulls #470",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/470.png",
    "dna": "1f4ebd434f2c7cac71233bc22751b7a52d36847b",
    "edition": 470,
    "date": 1672533458292,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "470",
    "rarity_score": 294.22614305169407,
    "rarity_level": "COMMON"
  },
  "900": {
    "name": "Arkade 90s Bulls #900",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/900.png",
    "dna": "d609f85a2205d19887600880f34f4f217c7c44bf",
    "edition": 900,
    "date": 1672533458768,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "900",
    "rarity_score": 354.9934837711285,
    "rarity_level": "COMMON"
  },
  "1382": {
    "name": "Arkade 90s Bulls #1382",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1382.png",
    "dna": "e6e8996dddf9b5bb541ad77cacfb00bf308a7bc0",
    "edition": 1382,
    "date": 1672533459259,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1382",
    "rarity_score": 246.01839774477523,
    "rarity_level": "COMMON"
  },
  "1279": {
    "name": "Arkade 90s Bulls #1279",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1279.png",
    "dna": "18d2d0928474ed9c8a19bfaf0331ea4cc97170f2",
    "edition": 1279,
    "date": 1672533459718,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1279",
    "rarity_score": 158.4350890992017,
    "rarity_level": "COMMON"
  },
  "1488": {
    "name": "Arkade 90s Bulls #1488",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1488.png",
    "dna": "aa91788ab1b29b2cf4f34972ccf73c0e59f8890d",
    "edition": 1488,
    "date": 1672533460756,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1488",
    "rarity_score": 331.13800002270085,
    "rarity_level": "COMMON"
  },
  "1010": {
    "name": "Arkade 90s Bulls #1010",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1010.png",
    "dna": "689b873e31be6a326f95dacdb62b5ff6fdcf1ce4",
    "edition": 1010,
    "date": 1672533461236,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1010",
    "rarity_score": 276.5510478660174,
    "rarity_level": "COMMON"
  },
  "215": {
    "name": "Arkade 90s Bulls #215",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/215.png",
    "dna": "a5c6992638bda27a47f10b47d0c0c8ece1221f41",
    "edition": 215,
    "date": 1672533462808,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Mask",
        "value": "Scar",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "215",
    "rarity_score": 543.8671672121222,
    "rarity_level": "RARE"
  },
  "1460": {
    "name": "Arkade 90s Bulls #1460",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1460.png",
    "dna": "4a0dc1209eda696fa9ee084697ef258200b960d7",
    "edition": 1460,
    "date": 1672533463264,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1460",
    "rarity_score": 295.9759688477798,
    "rarity_level": "COMMON"
  },
  "1322": {
    "name": "Arkade 90s Bulls #1322",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1322.png",
    "dna": "902783ef642552d336d0569a388d2fba21bdf3e2",
    "edition": 1322,
    "date": 1672533463743,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1322",
    "rarity_score": 331.38009984763687,
    "rarity_level": "COMMON"
  },
  "773": {
    "name": "Arkade 90s Bulls #773",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/773.png",
    "dna": "5f5b9eb0c45b11b9dd4b78c8fa0810a65834cfc5",
    "edition": 773,
    "date": 1672533464940,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "773",
    "rarity_score": 343.9462478185601,
    "rarity_level": "COMMON"
  },
  "1032": {
    "name": "Arkade 90s Bulls #1032",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1032.png",
    "dna": "dfd7f9cf2f20130a0f9554dc6c58445d80e9436a",
    "edition": 1032,
    "date": 1672533465397,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1032",
    "rarity_score": 267.0580146073703,
    "rarity_level": "COMMON"
  },
  "1193": {
    "name": "Arkade 90s Bulls #1193",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1193.png",
    "dna": "8d175d2587bf154cb66419191073149a50c95214",
    "edition": 1193,
    "date": 1672533465948,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "MF Doom Mask",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1193",
    "rarity_score": 397.2240139089598,
    "rarity_level": "COMMON"
  },
  "1534": {
    "name": "Arkade 90s Bulls #1534",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1534.png",
    "dna": "2be200b928231272d6b9044276c31386833d8c79",
    "edition": 1534,
    "date": 1672533466451,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Mask",
        "value": "Racer Helmet",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1534",
    "rarity_score": 433.7430628875347,
    "rarity_level": "COMMON"
  },
  "736": {
    "name": "Arkade 90s Bulls #736",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/736.png",
    "dna": "493e2c773cbb899f3daccc7669f4f73c39ef154d",
    "edition": 736,
    "date": 1672533466892,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "736",
    "rarity_score": 135.59649628708092,
    "rarity_level": "COMMON"
  },
  "1366": {
    "name": "Arkade 90s Bulls #1366",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1366.png",
    "dna": "be93b2bd8d317f852da1d4a67405fe11411b90c4",
    "edition": 1366,
    "date": 1672533467415,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1366",
    "rarity_score": 318.4165143263351,
    "rarity_level": "COMMON"
  },
  "266": {
    "name": "Arkade 90s Bulls #266",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/266.png",
    "dna": "35f071d780950d5db3133dd1dbd0bd378d344d3f",
    "edition": 266,
    "date": 1672533467862,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "266",
    "rarity_score": 319.4157556220295,
    "rarity_level": "COMMON"
  },
  "172": {
    "name": "Arkade 90s Bulls #172",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/172.png",
    "dna": "9251779f6d5d93b34d39db62e217662619aed2a9",
    "edition": 172,
    "date": 1672533468328,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "172",
    "rarity_score": 314.6012332103221,
    "rarity_level": "COMMON"
  },
  "1697": {
    "name": "Arkade 90s Bulls #1697",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1697.png",
    "dna": "304e7490675e29ec5affd7c78114d92a5016bce4",
    "edition": 1697,
    "date": 1672533468832,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1697",
    "rarity_score": 496.46279640973427,
    "rarity_level": "RARE"
  },
  "887": {
    "name": "Arkade 90s Bulls #887",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/887.png",
    "dna": "4b0b927ffe9ddfc323aac9399eeed1e298e51cb2",
    "edition": 887,
    "date": 1672533469402,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "887",
    "rarity_score": 628.3646989393669,
    "rarity_level": "RARE"
  },
  "504": {
    "name": "Arkade 90s Bulls #504",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/504.png",
    "dna": "3d0f869b09ab6c8792d01e0697b40b19fbfe0094",
    "edition": 504,
    "date": 1672533469916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "504",
    "rarity_score": 356.4090220214659,
    "rarity_level": "COMMON"
  },
  "866": {
    "name": "Arkade 90s Bulls #866",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/866.png",
    "dna": "fca835a9f2394dcab8504f0134d79e563030bf72",
    "edition": 866,
    "date": 1672533470483,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "866",
    "rarity_score": 261.4199819069322,
    "rarity_level": "COMMON"
  },
  "1003": {
    "name": "Arkade 90s Bulls #1003",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1003.png",
    "dna": "414422da83a438e001f4b4eeb0b240099d866f3c",
    "edition": 1003,
    "date": 1672533471062,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "1003",
    "rarity_score": 577.248243771726,
    "rarity_level": "RARE"
  },
  "1798": {
    "name": "Arkade 90s Bulls #1798",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1798.png",
    "dna": "f5e1a7b688043854b32931ec7e7668df9a8eec15",
    "edition": 1798,
    "date": 1672533471592,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1798",
    "rarity_score": 379.13185714113115,
    "rarity_level": "COMMON"
  },
  "430": {
    "name": "Arkade 90s Bulls #430",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/430.png",
    "dna": "e7bd4e2cbf07b1d49b8cd9d29a56baa41cdeff2a",
    "edition": 430,
    "date": 1672533472047,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "430",
    "rarity_score": 321.82887650590004,
    "rarity_level": "COMMON"
  },
  "1406": {
    "name": "Arkade 90s Bulls #1406",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1406.png",
    "dna": "32125089d170f7453a52e5929c7eb14fb2a3094f",
    "edition": 1406,
    "date": 1672533472606,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1406",
    "rarity_score": 427.3649702868189,
    "rarity_level": "COMMON"
  },
  "521": {
    "name": "Arkade 90s Bulls #521",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/521.png",
    "dna": "ddaa55e390e990bc15230f1b3fe597ef04342ff2",
    "edition": 521,
    "date": 1672533473089,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "521",
    "rarity_score": 476.13928449311567,
    "rarity_level": "RARE"
  },
  "993": {
    "name": "Arkade 90s Bulls #993",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/993.png",
    "dna": "ebccbb84edd86f99495e3e5df08391b8cf74ea6a",
    "edition": 993,
    "date": 1672533473915,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "993",
    "rarity_score": 398.0770585951279,
    "rarity_level": "COMMON"
  },
  "859": {
    "name": "Arkade 90s Bulls #859",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/859.png",
    "dna": "ffbb2c01c4f5ef0b275c602cb5026ca488cdcd95",
    "edition": 859,
    "date": 1672533474438,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "859",
    "rarity_score": 167.31738995594105,
    "rarity_level": "COMMON"
  },
  "1551": {
    "name": "Arkade 90s Bulls #1551",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1551.png",
    "dna": "21629a73bed25a310d54c59a0288a781a05eec80",
    "edition": 1551,
    "date": 1672533475630,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1551",
    "rarity_score": 312.7648977584463,
    "rarity_level": "COMMON"
  },
  "1899": {
    "name": "Arkade 90s Bulls #1899",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1899.png",
    "dna": "9cbe593c1803ffe7d6659295ffa4defc8c447108",
    "edition": 1899,
    "date": 1672533476137,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1899",
    "rarity_score": 312.67764805613996,
    "rarity_level": "COMMON"
  },
  "125": {
    "name": "Arkade 90s Bulls #125",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/125.png",
    "dna": "86895ab768bd84e96fe27c22a6a767f9a8b1180a",
    "edition": 125,
    "date": 1672533476639,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "125",
    "rarity_score": 210.9174562824819,
    "rarity_level": "COMMON"
  },
  "1164": {
    "name": "Arkade 90s Bulls #1164",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1164.png",
    "dna": "e7e40f1029baadd15a25eaae5d073a0bbe7b56a2",
    "edition": 1164,
    "date": 1672533477769,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1164",
    "rarity_score": 471.6446111263848,
    "rarity_level": "RARE"
  },
  "1849": {
    "name": "Arkade 90s Bulls #1849",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1849.png",
    "dna": "989e0ac0c530f414137c24e399acfd8e09eb9faa",
    "edition": 1849,
    "date": 1672533478245,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1849",
    "rarity_score": 372.4863747344099,
    "rarity_level": "COMMON"
  },
  "919": {
    "name": "Arkade 90s Bulls #919",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/919.png",
    "dna": "ff864a8bb32a69e5bd2e06e3db8578ec24831849",
    "edition": 919,
    "date": 1672533478724,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "919",
    "rarity_score": 332.09515764829126,
    "rarity_level": "COMMON"
  },
  "59": {
    "name": "Arkade 90s Bulls #59",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/59.png",
    "dna": "68f6b3e174479e8ced62f5cb8f4056fc78cfe7e6",
    "edition": 59,
    "date": 1672533479861,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "59",
    "rarity_score": 477.88734301231386,
    "rarity_level": "RARE"
  },
  "720": {
    "name": "Arkade 90s Bulls #720",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/720.png",
    "dna": "5281064625e87768f1763781b68a1c9060d86814",
    "edition": 720,
    "date": 1672533481114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "720",
    "rarity_score": 302.5647264487639,
    "rarity_level": "COMMON"
  },
  "970": {
    "name": "Arkade 90s Bulls #970",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/970.png",
    "dna": "a7d9ce283d5e49e7b43f213ef973daad4123a283",
    "edition": 970,
    "date": 1672533481625,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "970",
    "rarity_score": 278.2254752424505,
    "rarity_level": "COMMON"
  },
  "566": {
    "name": "Arkade 90s Bulls #566",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/566.png",
    "dna": "588c90fd966329668b59231558cd92bf4b34b1b8",
    "edition": 566,
    "date": 1672533482132,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "566",
    "rarity_score": 349.64786887752166,
    "rarity_level": "COMMON"
  },
  "822": {
    "name": "Arkade 90s Bulls #822",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/822.png",
    "dna": "40f1d8d153f101ffbbbe2f6fbc2cc059baaabfad",
    "edition": 822,
    "date": 1672533482877,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "822",
    "rarity_score": 380.62996228791934,
    "rarity_level": "COMMON"
  },
  "1040": {
    "name": "Arkade 90s Bulls #1040",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1040.png",
    "dna": "15f749a937dfb71849766ae9bc03c6ba25253e23",
    "edition": 1040,
    "date": 1672533483417,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1040",
    "rarity_score": 357.66090284079274,
    "rarity_level": "COMMON"
  },
  "1531": {
    "name": "Arkade 90s Bulls #1531",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1531.png",
    "dna": "2266b71c468098c2f96a183fd4a46614c7c4196f",
    "edition": 1531,
    "date": 1672533483915,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1531",
    "rarity_score": 202.28964154156475,
    "rarity_level": "COMMON"
  },
  "533": {
    "name": "Arkade 90s Bulls #533",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/533.png",
    "dna": "c30d3ba71f25b8f434ed37e995133045c4eb87bf",
    "edition": 533,
    "date": 1672533484516,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Tongue Out",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "533",
    "rarity_score": 320.81948006897915,
    "rarity_level": "COMMON"
  },
  "1973": {
    "name": "Arkade 90s Bulls #1973",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1973.png",
    "dna": "178422509059691ee596df9381fa30092e7b6f00",
    "edition": 1973,
    "date": 1672533485035,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1973",
    "rarity_score": 290.7777325394838,
    "rarity_level": "COMMON"
  },
  "1609": {
    "name": "Arkade 90s Bulls #1609",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1609.png",
    "dna": "dbdaaa93258a733b87c30cc54b7375235ff2ba3f",
    "edition": 1609,
    "date": 1672533485775,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1609",
    "rarity_score": 231.0542048310502,
    "rarity_level": "COMMON"
  },
  "1324": {
    "name": "Arkade 90s Bulls #1324",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1324.png",
    "dna": "2fb8389d0cae381947bfd08d282150bf615b260a",
    "edition": 1324,
    "date": 1672533486286,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1324",
    "rarity_score": 237.52126637177236,
    "rarity_level": "COMMON"
  },
  "1747": {
    "name": "Arkade 90s Bulls #1747",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1747.png",
    "dna": "8e1463300e05d006bc37df44d8279696155ec4f7",
    "edition": 1747,
    "date": 1672533486859,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1747",
    "rarity_score": 280.66198498918186,
    "rarity_level": "COMMON"
  },
  "297": {
    "name": "Arkade 90s Bulls #297",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/297.png",
    "dna": "8e1acca25707172d8810ac0ab2f264eb4b94a2ae",
    "edition": 297,
    "date": 1672533487359,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "297",
    "rarity_score": 546.6142817084158,
    "rarity_level": "RARE"
  },
  "373": {
    "name": "Arkade 90s Bulls #373",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/373.png",
    "dna": "024a60ac76bbbf4048e7525c6af0649293a826c7",
    "edition": 373,
    "date": 1672533487923,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "373",
    "rarity_score": 293.22652137579763,
    "rarity_level": "COMMON"
  },
  "11": {
    "name": "Arkade 90s Bulls #11",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/11.png",
    "dna": "7c0e6e06534ab8529613b28ab79fbb165b884227",
    "edition": 11,
    "date": 1672533488389,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "11",
    "rarity_score": 444.77166193357647,
    "rarity_level": "COMMON"
  },
  "1648": {
    "name": "Arkade 90s Bulls #1648",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1648.png",
    "dna": "670b57dae356a54a70388ae14ac2d5e9eb6560d2",
    "edition": 1648,
    "date": 1672533488871,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1648",
    "rarity_score": 180.16046491597942,
    "rarity_level": "COMMON"
  },
  "1133": {
    "name": "Arkade 90s Bulls #1133",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1133.png",
    "dna": "ada3cf56d44a8890c4652cc25ae321db79c540a5",
    "edition": 1133,
    "date": 1672533489367,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1133",
    "rarity_score": 590.9263249478768,
    "rarity_level": "RARE"
  },
  "1332": {
    "name": "Arkade 90s Bulls #1332",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1332.png",
    "dna": "b5f407fb96869b05d323edb788ce001f43f1ed64",
    "edition": 1332,
    "date": 1672533490615,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Gold Chain",
        "score": 497.5
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1332",
    "rarity_score": 782.9847145472013,
    "rarity_level": "RARE"
  },
  "48": {
    "name": "Arkade 90s Bulls #48",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/48.png",
    "dna": "2835432d4331e3b21e7469cdaec7eef474a4af31",
    "edition": 48,
    "date": 1672533491138,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "48",
    "rarity_score": 388.606012878272,
    "rarity_level": "COMMON"
  },
  "823": {
    "name": "Arkade 90s Bulls #823",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/823.png",
    "dna": "01f27e984fa38ae00ce8f0b88b1414ac37cc1a79",
    "edition": 823,
    "date": 1672533491611,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "823",
    "rarity_score": 328.1829347396368,
    "rarity_level": "COMMON"
  },
  "228": {
    "name": "Arkade 90s Bulls #228",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/228.png",
    "dna": "9448f29c74682e30b3ce966fe16b9d86f1c027bf",
    "edition": 228,
    "date": 1672533492090,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Angry",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "228",
    "rarity_score": 379.7623292986189,
    "rarity_level": "COMMON"
  },
  "536": {
    "name": "Arkade 90s Bulls #536",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/536.png",
    "dna": "f20cf8b6b2ec286d37901fada06c7faa03711ae7",
    "edition": 536,
    "date": 1672533493143,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "536",
    "rarity_score": 279.82457311299066,
    "rarity_level": "COMMON"
  },
  "454": {
    "name": "Arkade 90s Bulls #454",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/454.png",
    "dna": "18a464a49c4dd0713b8ad3d6ba9962ee45eaa2b1",
    "edition": 454,
    "date": 1672533494367,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "454",
    "rarity_score": 346.854593894896,
    "rarity_level": "COMMON"
  },
  "128": {
    "name": "Arkade 90s Bulls #128",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/128.png",
    "dna": "7264c5b74eec0ef9bb0c6dc88d44a7e49dd553f4",
    "edition": 128,
    "date": 1672533494870,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "128",
    "rarity_score": 272.3742322291357,
    "rarity_level": "COMMON"
  },
  "1455": {
    "name": "Arkade 90s Bulls #1455",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1455.png",
    "dna": "cbf8efe4f79e99d32ce5486286a69b00797ee66c",
    "edition": 1455,
    "date": 1672533495476,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1455",
    "rarity_score": 245.39771583520903,
    "rarity_level": "COMMON"
  },
  "1838": {
    "name": "Arkade 90s Bulls #1838",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1838.png",
    "dna": "48db4d84e0fb38ed03bf241cf916fb549d60c067",
    "edition": 1838,
    "date": 1672533495971,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Sad",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1838",
    "rarity_score": 270.683955864771,
    "rarity_level": "COMMON"
  },
  "181": {
    "name": "Arkade 90s Bulls #181",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/181.png",
    "dna": "e1ef469a105dca34d9173a359003800d1e0af6fc",
    "edition": 181,
    "date": 1672533496467,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "181",
    "rarity_score": 325.5017217247873,
    "rarity_level": "COMMON"
  },
  "820": {
    "name": "Arkade 90s Bulls #820",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/820.png",
    "dna": "e6724bb89a84d46d075f491404fc414215dfb811",
    "edition": 820,
    "date": 1672533496974,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "820",
    "rarity_score": 271.56592060466744,
    "rarity_level": "COMMON"
  },
  "1550": {
    "name": "Arkade 90s Bulls #1550",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1550.png",
    "dna": "f4f7c43e91389ffee64b0fd16be2e7acc08bb4a9",
    "edition": 1550,
    "date": 1672533497434,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1550",
    "rarity_score": 206.50231589874537,
    "rarity_level": "COMMON"
  },
  "1641": {
    "name": "Arkade 90s Bulls #1641",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1641.png",
    "dna": "c990c4f417786b2e81b177e49809970218801dd8",
    "edition": 1641,
    "date": 1672533497892,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Smile",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1641",
    "rarity_score": 334.3875098905955,
    "rarity_level": "COMMON"
  },
  "989": {
    "name": "Arkade 90s Bulls #989",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/989.png",
    "dna": "d41d5aab335787723b892d91a3211e48f6d6292f",
    "edition": 989,
    "date": 1672533499111,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "989",
    "rarity_score": 436.89123483538094,
    "rarity_level": "COMMON"
  },
  "1449": {
    "name": "Arkade 90s Bulls #1449",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1449.png",
    "dna": "a97fe36143d3b543d2e07f037add698789e7a234",
    "edition": 1449,
    "date": 1672533500393,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Green Happy",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1449",
    "rarity_score": 289.7297092151056,
    "rarity_level": "COMMON"
  },
  "1192": {
    "name": "Arkade 90s Bulls #1192",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1192.png",
    "dna": "d47294c9ad954f30f78ebe37b044cb7a9e81f935",
    "edition": 1192,
    "date": 1672533500897,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1192",
    "rarity_score": 508.9940977788305,
    "rarity_level": "RARE"
  },
  "1666": {
    "name": "Arkade 90s Bulls #1666",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1666.png",
    "dna": "a8162689e197d8f7277db9e4156ac38fc81b54a2",
    "edition": 1666,
    "date": 1672533501353,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1666",
    "rarity_score": 337.46871665672,
    "rarity_level": "COMMON"
  },
  "858": {
    "name": "Arkade 90s Bulls #858",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/858.png",
    "dna": "7d4cb8fd0a5d40fb9c0f0f8edc789f6f313ee38e",
    "edition": 858,
    "date": 1672533501805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "858",
    "rarity_score": 167.7458271476113,
    "rarity_level": "COMMON"
  },
  "1116": {
    "name": "Arkade 90s Bulls #1116",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1116.png",
    "dna": "0d47fc1ee0d212be461b727928d8cf89243a986e",
    "edition": 1116,
    "date": 1672533502275,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1116",
    "rarity_score": 309.9147177807877,
    "rarity_level": "COMMON"
  },
  "1445": {
    "name": "Arkade 90s Bulls #1445",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1445.png",
    "dna": "69d1a05a367aad51466c855922dacf680b5de36c",
    "edition": 1445,
    "date": 1672533502746,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1445",
    "rarity_score": 553.7338372515516,
    "rarity_level": "RARE"
  },
  "64": {
    "name": "Arkade 90s Bulls #64",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/64.png",
    "dna": "050da058d461941efb17f6ea3b1c983482a1f787",
    "edition": 64,
    "date": 1672533503274,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "64",
    "rarity_score": 411.4664722107857,
    "rarity_level": "COMMON"
  },
  "1555": {
    "name": "Arkade 90s Bulls #1555",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1555.png",
    "dna": "f9d18294f692602443af1491c49edd629077ae51",
    "edition": 1555,
    "date": 1672533503757,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1555",
    "rarity_score": 241.64487570808757,
    "rarity_level": "COMMON"
  },
  "1636": {
    "name": "Arkade 90s Bulls #1636",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1636.png",
    "dna": "ddad1fe01fd689f2317363dc340035aa826057f1",
    "edition": 1636,
    "date": 1672533504213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1636",
    "rarity_score": 225.36185845588972,
    "rarity_level": "COMMON"
  },
  "1929": {
    "name": "Arkade 90s Bulls #1929",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1929.png",
    "dna": "0912427097146df7937f4d8eafbdb79b4a778cb5",
    "edition": 1929,
    "date": 1672533505668,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1929",
    "rarity_score": 267.6743490841699,
    "rarity_level": "COMMON"
  },
  "150": {
    "name": "Arkade 90s Bulls #150",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/150.png",
    "dna": "ed50f6a667907da5b5b52a8771a6316a45f5a9e7",
    "edition": 150,
    "date": 1672533506154,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "150",
    "rarity_score": 330.2026852957289,
    "rarity_level": "COMMON"
  },
  "938": {
    "name": "Arkade 90s Bulls #938",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/938.png",
    "dna": "7a8960ee104190c9f662d405b081f57948e16fe0",
    "edition": 938,
    "date": 1672533507175,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "938",
    "rarity_score": 501.069415421028,
    "rarity_level": "RARE"
  },
  "1187": {
    "name": "Arkade 90s Bulls #1187",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1187.png",
    "dna": "ca7d2587c09c98ad35f768f334bc5e8ac89915a1",
    "edition": 1187,
    "date": 1672533508157,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "Krooper Helmet",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1187",
    "rarity_score": 729.2250815193064,
    "rarity_level": "RARE"
  },
  "1101": {
    "name": "Arkade 90s Bulls #1101",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1101.png",
    "dna": "61f37e2e78cec98832b5df9f1bf240804608a4f7",
    "edition": 1101,
    "date": 1672533508617,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1101",
    "rarity_score": 398.936235142509,
    "rarity_level": "COMMON"
  },
  "1341": {
    "name": "Arkade 90s Bulls #1341",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1341.png",
    "dna": "2366846e4f489c8b19ca9dbdac2dff4aeb1bc034",
    "edition": 1341,
    "date": 1672533509834,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      { "trait_type": "Outfit", "value": "Yellow Tee", "score": 99.5 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1341",
    "rarity_score": 442.86809722337506,
    "rarity_level": "COMMON"
  },
  "1478": {
    "name": "Arkade 90s Bulls #1478",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1478.png",
    "dna": "7ff067c2c4f69c7fd03a1dab66ab9ad45ac86d1c",
    "edition": 1478,
    "date": 1672533510383,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1478",
    "rarity_score": 221.3198629275239,
    "rarity_level": "COMMON"
  },
  "796": {
    "name": "Arkade 90s Bulls #796",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/796.png",
    "dna": "c0f7517358193cd9d3dd53240ed724baf009fcbd",
    "edition": 796,
    "date": 1672533510927,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Tongue Out",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "796",
    "rarity_score": 837.0121425979956,
    "rarity_level": "RARE"
  },
  "598": {
    "name": "Arkade 90s Bulls #598",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/598.png",
    "dna": "2a0b94d19c0c5b9088233ad95c87a23bde480b53",
    "edition": 598,
    "date": 1672533512135,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "598",
    "rarity_score": 347.35300829468304,
    "rarity_level": "COMMON"
  },
  "1196": {
    "name": "Arkade 90s Bulls #1196",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1196.png",
    "dna": "edc99f036236d2f20e6ef11af2c097bf21d0b45a",
    "edition": 1196,
    "date": 1672533512601,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1196",
    "rarity_score": 196.9097701302372,
    "rarity_level": "COMMON"
  },
  "807": {
    "name": "Arkade 90s Bulls #807",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/807.png",
    "dna": "61ad076b16e8474a2191cd1f2353ed151c835713",
    "edition": 807,
    "date": 1672533513057,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "807",
    "rarity_score": 197.00548003692325,
    "rarity_level": "COMMON"
  },
  "334": {
    "name": "Arkade 90s Bulls #334",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/334.png",
    "dna": "3a39345877ca9fa0a6135acd761f03dc91eaa34c",
    "edition": 334,
    "date": 1672533513532,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "334",
    "rarity_score": 187.6988682523354,
    "rarity_level": "COMMON"
  },
  "783": {
    "name": "Arkade 90s Bulls #783",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/783.png",
    "dna": "de377a2e5350c2c64fbeb9d0682a169be98542e3",
    "edition": 783,
    "date": 1672533514080,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "Yellow Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "783",
    "rarity_score": 766.5459009020472,
    "rarity_level": "RARE"
  },
  "1189": {
    "name": "Arkade 90s Bulls #1189",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1189.png",
    "dna": "c25144adba62c7b0c37fdb3b85d39ec739a0b0df",
    "edition": 1189,
    "date": 1672533514536,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1189",
    "rarity_score": 206.18815527609408,
    "rarity_level": "COMMON"
  },
  "16": {
    "name": "Arkade 90s Bulls #16",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/16.png",
    "dna": "69ff5973680ebf76ec47f6766956a53217b416ac",
    "edition": 16,
    "date": 1672533515162,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "16",
    "rarity_score": 248.0487766436621,
    "rarity_level": "COMMON"
  },
  "1023": {
    "name": "Arkade 90s Bulls #1023",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1023.png",
    "dna": "d6d58acaabe691697aee5b1a14b660db3819f9fd",
    "edition": 1023,
    "date": 1672533516230,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1023",
    "rarity_score": 482.8045552811679,
    "rarity_level": "RARE"
  },
  "916": {
    "name": "Arkade 90s Bulls #916",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/916.png",
    "dna": "53208efdd1927c45cc9cad209397a699465186de",
    "edition": 916,
    "date": 1672533516683,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "916",
    "rarity_score": 194.46059536324006,
    "rarity_level": "COMMON"
  },
  "731": {
    "name": "Arkade 90s Bulls #731",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/731.png",
    "dna": "881c0e81add2500062cd9b9fdf0880e01999c9e6",
    "edition": 731,
    "date": 1672533517860,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "731",
    "rarity_score": 396.9015524536977,
    "rarity_level": "COMMON"
  },
  "293": {
    "name": "Arkade 90s Bulls #293",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/293.png",
    "dna": "b8da30809395e3b83b16b2af43daf6096abb3b07",
    "edition": 293,
    "date": 1672533518343,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Tongue Out",
        "score": 663.3333333333334
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "293",
    "rarity_score": 838.6130983290445,
    "rarity_level": "RARE"
  },
  "1459": {
    "name": "Arkade 90s Bulls #1459",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1459.png",
    "dna": "624ddf9eaf7e5998456fe6013081d740eb3314fd",
    "edition": 1459,
    "date": 1672533519073,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1459",
    "rarity_score": 263.3778288280764,
    "rarity_level": "COMMON"
  },
  "835": {
    "name": "Arkade 90s Bulls #835",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/835.png",
    "dna": "45833232cfe3d2ace7c47ef351e7d0d6dfa8ed92",
    "edition": 835,
    "date": 1672533519567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "835",
    "rarity_score": 244.91746472684702,
    "rarity_level": "COMMON"
  },
  "1228": {
    "name": "Arkade 90s Bulls #1228",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1228.png",
    "dna": "6726dc2f50762d9e81f4271808d6a5eabf81cb12",
    "edition": 1228,
    "date": 1672533520785,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1228",
    "rarity_score": 337.1873790445659,
    "rarity_level": "COMMON"
  },
  "363": {
    "name": "Arkade 90s Bulls #363",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/363.png",
    "dna": "161cd51ef453d95155c60f94c8683631c3fa1464",
    "edition": 363,
    "date": 1672533521298,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "363",
    "rarity_score": 379.9716860491273,
    "rarity_level": "COMMON"
  },
  "1098": {
    "name": "Arkade 90s Bulls #1098",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1098.png",
    "dna": "8118c6c2f79f374e1ecd58cec0dde5bdac1383ca",
    "edition": 1098,
    "date": 1672533522263,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1098",
    "rarity_score": 354.7098287586635,
    "rarity_level": "COMMON"
  },
  "1084": {
    "name": "Arkade 90s Bulls #1084",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1084.png",
    "dna": "150db5d764b7aef03d6efd80706b567111686591",
    "edition": 1084,
    "date": 1672533522729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1084",
    "rarity_score": 560.8348468926059,
    "rarity_level": "RARE"
  },
  "103": {
    "name": "Arkade 90s Bulls #103",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/103.png",
    "dna": "8c03d8b6b379dd6d9e584a8e7e135f94ac0b5219",
    "edition": 103,
    "date": 1672533523267,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "103",
    "rarity_score": 418.17303660320863,
    "rarity_level": "COMMON"
  },
  "340": {
    "name": "Arkade 90s Bulls #340",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/340.png",
    "dna": "8fdde12422767de1a303e030c2837e8bc0386a7f",
    "edition": 340,
    "date": 1672533523753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Tongue Out",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "340",
    "rarity_score": 766.1934713095286,
    "rarity_level": "RARE"
  },
  "255": {
    "name": "Arkade 90s Bulls #255",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/255.png",
    "dna": "dcb7c0d0bfaf736377db3afcb607c3c3429b2fa5",
    "edition": 255,
    "date": 1672533524386,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "255",
    "rarity_score": 320.7066173193888,
    "rarity_level": "COMMON"
  },
  "1593": {
    "name": "Arkade 90s Bulls #1593",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1593.png",
    "dna": "6b82a9f8929b7b8b3266f0acb259262b5c024886",
    "edition": 1593,
    "date": 1672533524963,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1593",
    "rarity_score": 393.0312541561561,
    "rarity_level": "COMMON"
  },
  "518": {
    "name": "Arkade 90s Bulls #518",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/518.png",
    "dna": "a0b6dd67730318df2281d49464550fc1ad3820aa",
    "edition": 518,
    "date": 1672533525966,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "518",
    "rarity_score": 338.0612836934866,
    "rarity_level": "COMMON"
  },
  "120": {
    "name": "Arkade 90s Bulls #120",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/120.png",
    "dna": "9d13247f397e9987acad6d7ff67b4ba2a3a2011b",
    "edition": 120,
    "date": 1672533526433,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "120",
    "rarity_score": 256.19263201716103,
    "rarity_level": "COMMON"
  },
  "1141": {
    "name": "Arkade 90s Bulls #1141",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1141.png",
    "dna": "47720faf64cec7c1cf8d32656c97fc3dc34b0eb7",
    "edition": 1141,
    "date": 1672533526884,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1141",
    "rarity_score": 440.0907358148726,
    "rarity_level": "COMMON"
  },
  "1436": {
    "name": "Arkade 90s Bulls #1436",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1436.png",
    "dna": "90bcb82ce432251a2bbf193e3617c9af26c86ee9",
    "edition": 1436,
    "date": 1672533528063,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1436",
    "rarity_score": 290.4509408356316,
    "rarity_level": "COMMON"
  },
  "1854": {
    "name": "Arkade 90s Bulls #1854",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1854.png",
    "dna": "6b4c57de3c4e81b588286a9de76a3272b755e4f5",
    "edition": 1854,
    "date": 1672533528856,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Gold Chain",
        "score": 497.5
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1854",
    "rarity_score": 896.6721299043883,
    "rarity_level": "RARE"
  },
  "1606": {
    "name": "Arkade 90s Bulls #1606",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1606.png",
    "dna": "e357067892ed4027db5bb4b101f1def8d2e4e522",
    "edition": 1606,
    "date": 1672533529336,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1606",
    "rarity_score": 419.92696888871154,
    "rarity_level": "COMMON"
  },
  "1623": {
    "name": "Arkade 90s Bulls #1623",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1623.png",
    "dna": "706e2ed0719f371fdac1f94b4ff3c51560f2311f",
    "edition": 1623,
    "date": 1672533529899,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1623",
    "rarity_score": 410.10223610521973,
    "rarity_level": "COMMON"
  },
  "825": {
    "name": "Arkade 90s Bulls #825",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/825.png",
    "dna": "6551038b5b72a0e5ca91ee8164038a0c5fcb4ee2",
    "edition": 825,
    "date": 1672533530350,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "825",
    "rarity_score": 642.1219812383678,
    "rarity_level": "RARE"
  },
  "163": {
    "name": "Arkade 90s Bulls #163",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/163.png",
    "dna": "b87c989ca951dacfac86448f789b0b9c501102e1",
    "edition": 163,
    "date": 1672533531621,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "163",
    "rarity_score": 440.3902130565709,
    "rarity_level": "COMMON"
  },
  "547": {
    "name": "Arkade 90s Bulls #547",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/547.png",
    "dna": "b2f75a0e2aca1fb483d2683292f360c94ad80654",
    "edition": 547,
    "date": 1672533532142,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Evil",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "547",
    "rarity_score": 423.90925677479237,
    "rarity_level": "COMMON"
  },
  "278": {
    "name": "Arkade 90s Bulls #278",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/278.png",
    "dna": "29b40c444d9b1a3e67d7b8a562fddb48bcc734bc",
    "edition": 278,
    "date": 1672533532805,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      { "trait_type": "Eyes", "value": "Up Red Eyes", "score": 124.375 },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "278",
    "rarity_score": 498.5302761878246,
    "rarity_level": "RARE"
  },
  "1591": {
    "name": "Arkade 90s Bulls #1591",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1591.png",
    "dna": "f8b640fd52b7f91e17aee6397d3e65398a6ef497",
    "edition": 1591,
    "date": 1672533533330,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Sad",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1591",
    "rarity_score": 476.7178717006188,
    "rarity_level": "RARE"
  },
  "369": {
    "name": "Arkade 90s Bulls #369",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/369.png",
    "dna": "008ed6233ad54c7bca7f7a1e15ff4ea149137aa3",
    "edition": 369,
    "date": 1672533534488,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Red Angry",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "369",
    "rarity_score": 328.5264322765597,
    "rarity_level": "COMMON"
  },
  "500": {
    "name": "Arkade 90s Bulls #500",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/500.png",
    "dna": "35c020414552f99074a5a175bbe33ee3683b7d96",
    "edition": 500,
    "date": 1672533535060,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "500",
    "rarity_score": 261.2623810647068,
    "rarity_level": "COMMON"
  },
  "689": {
    "name": "Arkade 90s Bulls #689",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/689.png",
    "dna": "ee6b7af9ed63d0aca1674d54566d350eede0888a",
    "edition": 689,
    "date": 1672533535530,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "689",
    "rarity_score": 171.03589122631072,
    "rarity_level": "COMMON"
  },
  "565": {
    "name": "Arkade 90s Bulls #565",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/565.png",
    "dna": "c52d9ede2645c5d1f4bbdc5782a0467c0555ec77",
    "edition": 565,
    "date": 1672533536066,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "565",
    "rarity_score": 577.9612048480534,
    "rarity_level": "RARE"
  },
  "1827": {
    "name": "Arkade 90s Bulls #1827",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1827.png",
    "dna": "6f2f347d1cd54a1b8338c283d1547fdbb291ccd2",
    "edition": 1827,
    "date": 1672533536575,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1827",
    "rarity_score": 307.0152379416997,
    "rarity_level": "COMMON"
  },
  "182": {
    "name": "Arkade 90s Bulls #182",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/182.png",
    "dna": "7ce5adb94104fa32c3b4cba20efd881c8203613d",
    "edition": 182,
    "date": 1672533537071,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "182",
    "rarity_score": 319.6076738705875,
    "rarity_level": "COMMON"
  },
  "405": {
    "name": "Arkade 90s Bulls #405",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/405.png",
    "dna": "8b4dade88684d41975c373affb1154697e0bb104",
    "edition": 405,
    "date": 1672533537522,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "405",
    "rarity_score": 224.47296995505488,
    "rarity_level": "COMMON"
  },
  "1568": {
    "name": "Arkade 90s Bulls #1568",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1568.png",
    "dna": "db0ecc0f5c0a07d97d93d4dee32857190af25cd9",
    "edition": 1568,
    "date": 1672533538708,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Mustache",
        "score": 1990.0
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1568",
    "rarity_score": 2205.9320873370134,
    "rarity_level": "LEGENDARY"
  },
  "143": {
    "name": "Arkade 90s Bulls #143",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/143.png",
    "dna": "aaca94fc0b8d3138af048b5d8f2da11fbbc7b48a",
    "edition": 143,
    "date": 1672533539245,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "UFO",
        "score": 663.3333333333334
      }
    ],
    "id": "143",
    "rarity_score": 994.1893310392227,
    "rarity_level": "RARE"
  },
  "176": {
    "name": "Arkade 90s Bulls #176",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/176.png",
    "dna": "3f11795cc13689c33fff07d2b9ca7ce61a008be8",
    "edition": 176,
    "date": 1672533539722,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "176",
    "rarity_score": 215.50948569417645,
    "rarity_level": "COMMON"
  },
  "243": {
    "name": "Arkade 90s Bulls #243",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/243.png",
    "dna": "307144cb0c484e324ea646001a06d9e8a082a8da",
    "edition": 243,
    "date": 1672533540916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "243",
    "rarity_score": 382.97977065832794,
    "rarity_level": "COMMON"
  },
  "24": {
    "name": "Arkade 90s Bulls #24",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/24.png",
    "dna": "bec5940ef8fabe2f376a98b5571a19690e1c1dbb",
    "edition": 24,
    "date": 1672533541408,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "24",
    "rarity_score": 233.73221768056376,
    "rarity_level": "COMMON"
  },
  "53": {
    "name": "Arkade 90s Bulls #53",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/53.png",
    "dna": "25b7c96c4ce1238f85a8abed4e7e69b25120bc9d",
    "edition": 53,
    "date": 1672533541884,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "53",
    "rarity_score": 169.189857950762,
    "rarity_level": "COMMON"
  },
  "187": {
    "name": "Arkade 90s Bulls #187",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/187.png",
    "dna": "eeb56ab38a899596f3b35ece4e0bb6fb4879c4b5",
    "edition": 187,
    "date": 1672533542387,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "187",
    "rarity_score": 379.15853648001604,
    "rarity_level": "COMMON"
  },
  "649": {
    "name": "Arkade 90s Bulls #649",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/649.png",
    "dna": "18ce2aea4ed781c95c796cb92ebf8160fd291963",
    "edition": 649,
    "date": 1672533542892,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "649",
    "rarity_score": 322.0723902039998,
    "rarity_level": "COMMON"
  },
  "1012": {
    "name": "Arkade 90s Bulls #1012",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1012.png",
    "dna": "677e63a54279e00f5023ecf4bfd162a6a8d708b5",
    "edition": 1012,
    "date": 1672533544097,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1012",
    "rarity_score": 265.44305498225674,
    "rarity_level": "COMMON"
  },
  "56": {
    "name": "Arkade 90s Bulls #56",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/56.png",
    "dna": "653a0ebba3120342f4bf4c85407bcd61e4c086b0",
    "edition": 56,
    "date": 1672533544604,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "56",
    "rarity_score": 320.08121898329375,
    "rarity_level": "COMMON"
  },
  "1283": {
    "name": "Arkade 90s Bulls #1283",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1283.png",
    "dna": "857902d528ca54d37d6eae21b675e4e129e36e6f",
    "edition": 1283,
    "date": 1672533545072,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1283",
    "rarity_score": 318.9162907204049,
    "rarity_level": "COMMON"
  },
  "925": {
    "name": "Arkade 90s Bulls #925",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/925.png",
    "dna": "bcf59b92ce354ef2427de9436c03adb4051eafb8",
    "edition": 925,
    "date": 1672533545579,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "925",
    "rarity_score": 376.00405994955787,
    "rarity_level": "COMMON"
  },
  "290": {
    "name": "Arkade 90s Bulls #290",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/290.png",
    "dna": "5ec32625e524e0ee13c1b4ad0016c0115226cc82",
    "edition": 290,
    "date": 1672533546104,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "290",
    "rarity_score": 609.2163846504964,
    "rarity_level": "RARE"
  },
  "1149": {
    "name": "Arkade 90s Bulls #1149",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1149.png",
    "dna": "43cc3d3a5b970c0dda723c0b29c3dbb5b83fee3c",
    "edition": 1149,
    "date": 1672533547385,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1149",
    "rarity_score": 475.0941060082186,
    "rarity_level": "RARE"
  },
  "1828": {
    "name": "Arkade 90s Bulls #1828",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1828.png",
    "dna": "84d65b2f2ebfdb8d3055f7b7901aa762bbe27e66",
    "edition": 1828,
    "date": 1672533547845,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1828",
    "rarity_score": 252.7524071118212,
    "rarity_level": "COMMON"
  },
  "115": {
    "name": "Arkade 90s Bulls #115",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/115.png",
    "dna": "61e5296ef27e2cfa760f438cf4b815b0ef868195",
    "edition": 115,
    "date": 1672533548364,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Purple Angry", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "115",
    "rarity_score": 632.4400818159407,
    "rarity_level": "RARE"
  },
  "1066": {
    "name": "Arkade 90s Bulls #1066",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1066.png",
    "dna": "d6a0adb842277f379f8140b8d2bb3285f26af3ea",
    "edition": 1066,
    "date": 1672533548855,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1066",
    "rarity_score": 436.76449948671967,
    "rarity_level": "COMMON"
  },
  "664": {
    "name": "Arkade 90s Bulls #664",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/664.png",
    "dna": "62bc5c69d86977685924642de524b819a226b957",
    "edition": 664,
    "date": 1672533549333,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "664",
    "rarity_score": 265.2160249231244,
    "rarity_level": "COMMON"
  },
  "686": {
    "name": "Arkade 90s Bulls #686",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/686.png",
    "dna": "bcb78f1860eda3af05083b06c7a31b03c94660ce",
    "edition": 686,
    "date": 1672533550046,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "686",
    "rarity_score": 156.5166462407063,
    "rarity_level": "COMMON"
  },
  "1533": {
    "name": "Arkade 90s Bulls #1533",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1533.png",
    "dna": "78c861cfae124c00be9e50b79fe94d7db5082360",
    "edition": 1533,
    "date": 1672533550523,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1533",
    "rarity_score": 267.47756268111993,
    "rarity_level": "COMMON"
  },
  "778": {
    "name": "Arkade 90s Bulls #778",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/778.png",
    "dna": "b74031473b443efc1f9621ea04cf8f3750dfac2e",
    "edition": 778,
    "date": 1672533551005,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Purple Angry", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Bullverine",
        "score": 284.2857142857143
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "778",
    "rarity_score": 856.2904328341672,
    "rarity_level": "RARE"
  },
  "690": {
    "name": "Arkade 90s Bulls #690",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/690.png",
    "dna": "7487053c222f227c45d16b6c6cf84784d98769a7",
    "edition": 690,
    "date": 1672533551520,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "690",
    "rarity_score": 166.00985782959486,
    "rarity_level": "COMMON"
  },
  "513": {
    "name": "Arkade 90s Bulls #513",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/513.png",
    "dna": "1349d0a5dedfaa27d17bddbdb64ad413ee4d531c",
    "edition": 513,
    "date": 1672533552013,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "513",
    "rarity_score": 196.14077459911917,
    "rarity_level": "COMMON"
  },
  "1805": {
    "name": "Arkade 90s Bulls #1805",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1805.png",
    "dna": "911e1794cc9b2ee00385b5e3baf9b1187a40cb39",
    "edition": 1805,
    "date": 1672533552589,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1805",
    "rarity_score": 462.87174849247316,
    "rarity_level": "RARE"
  },
  "351": {
    "name": "Arkade 90s Bulls #351",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/351.png",
    "dna": "db51357ac5ee5f36f7d948c14339ce638c9d57d3",
    "edition": 351,
    "date": 1672533553739,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      { "trait_type": "Head", "value": "Football Head", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "351",
    "rarity_score": 562.5865987782735,
    "rarity_level": "RARE"
  },
  "1131": {
    "name": "Arkade 90s Bulls #1131",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1131.png",
    "dna": "6adf8f719d0d7395e52d5d3c32c36c06a6ddc84c",
    "edition": 1131,
    "date": 1672533554784,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1131",
    "rarity_score": 258.6602082012039,
    "rarity_level": "COMMON"
  },
  "753": {
    "name": "Arkade 90s Bulls #753",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/753.png",
    "dna": "e9aa0ac8c14874a06725cb058bc68990700015f8",
    "edition": 753,
    "date": 1672533555371,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "753",
    "rarity_score": 380.14423005396276,
    "rarity_level": "COMMON"
  },
  "1312": {
    "name": "Arkade 90s Bulls #1312",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1312.png",
    "dna": "f543ab0b19afb0eeac8c712f0d364428b4947c53",
    "edition": 1312,
    "date": 1672533555887,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1312",
    "rarity_score": 285.8157062378244,
    "rarity_level": "COMMON"
  },
  "1206": {
    "name": "Arkade 90s Bulls #1206",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1206.png",
    "dna": "c8062d9f2d3269d84001669a19942ef01783eaf5",
    "edition": 1206,
    "date": 1672533556430,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1206",
    "rarity_score": 223.9123883932302,
    "rarity_level": "COMMON"
  },
  "51": {
    "name": "Arkade 90s Bulls #51",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/51.png",
    "dna": "d6f37867fc6b85db2a9eb36cc3bfba37c4cdb1e2",
    "edition": 51,
    "date": 1672533556979,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "51",
    "rarity_score": 282.9255285930018,
    "rarity_level": "COMMON"
  },
  "698": {
    "name": "Arkade 90s Bulls #698",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/698.png",
    "dna": "616aaf3af907984a53fdf0cb81abfc16c797ebf0",
    "edition": 698,
    "date": 1672533557435,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "698",
    "rarity_score": 247.5793332477761,
    "rarity_level": "COMMON"
  },
  "1575": {
    "name": "Arkade 90s Bulls #1575",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1575.png",
    "dna": "f59c07f99594e1bbbabed6bb74ab1eef145e6555",
    "edition": 1575,
    "date": 1672533557951,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1575",
    "rarity_score": 243.98538095241946,
    "rarity_level": "COMMON"
  },
  "71": {
    "name": "Arkade 90s Bulls #71",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/71.png",
    "dna": "df38a6e6c902e490b8df73a4b98f085fab8d3ebb",
    "edition": 71,
    "date": 1672533558494,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "71",
    "rarity_score": 341.8640292883074,
    "rarity_level": "COMMON"
  },
  "1261": {
    "name": "Arkade 90s Bulls #1261",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1261.png",
    "dna": "1fdfcc1104163175af6ddb1122a988de03c28fb6",
    "edition": 1261,
    "date": 1672533558988,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1261",
    "rarity_score": 331.8913321681097,
    "rarity_level": "COMMON"
  },
  "394": {
    "name": "Arkade 90s Bulls #394",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/394.png",
    "dna": "7b900cf237b2b7aecfd15d6b3efad29315ed5023",
    "edition": 394,
    "date": 1672533559440,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Up Green Eyes",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "394",
    "rarity_score": 333.97363390819606,
    "rarity_level": "COMMON"
  },
  "367": {
    "name": "Arkade 90s Bulls #367",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/367.png",
    "dna": "a9c45a503787f8849d748ee6a723073bd035c3b7",
    "edition": 367,
    "date": 1672533559908,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "367",
    "rarity_score": 398.58192303896044,
    "rarity_level": "COMMON"
  },
  "1270": {
    "name": "Arkade 90s Bulls #1270",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1270.png",
    "dna": "1b49765d24acac5d0fd0e83484dbb0481d1c8427",
    "edition": 1270,
    "date": 1672533561082,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1270",
    "rarity_score": 204.02007404272706,
    "rarity_level": "COMMON"
  },
  "1146": {
    "name": "Arkade 90s Bulls #1146",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1146.png",
    "dna": "56c40178d955548d4e506107dbac7d57f431375e",
    "edition": 1146,
    "date": 1672533561595,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Yuki Muko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1146",
    "rarity_score": 618.3483295227487,
    "rarity_level": "RARE"
  },
  "884": {
    "name": "Arkade 90s Bulls #884",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/884.png",
    "dna": "6f7d525c78354966d9ee1785489e7201458f1b6a",
    "edition": 884,
    "date": 1672533562088,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "884",
    "rarity_score": 313.21548484506366,
    "rarity_level": "COMMON"
  },
  "1812": {
    "name": "Arkade 90s Bulls #1812",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1812.png",
    "dna": "142d7563239cd0251528527e9971b00742b31cd2",
    "edition": 1812,
    "date": 1672533562544,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1812",
    "rarity_score": 229.12816427799117,
    "rarity_level": "COMMON"
  },
  "1761": {
    "name": "Arkade 90s Bulls #1761",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1761.png",
    "dna": "6cc55e659c2583558ed7f9beef7f052d186a3f2d",
    "edition": 1761,
    "date": 1672533563008,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1761",
    "rarity_score": 331.65443178587896,
    "rarity_level": "COMMON"
  },
  "159": {
    "name": "Arkade 90s Bulls #159",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/159.png",
    "dna": "e4064ff98d2ef957f0255b77714ee78a9aaa75e9",
    "edition": 159,
    "date": 1672533564210,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "159",
    "rarity_score": 352.852951684854,
    "rarity_level": "COMMON"
  },
  "1079": {
    "name": "Arkade 90s Bulls #1079",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1079.png",
    "dna": "3e98c6e3fc41bb904be8f013c39281645d69c4d7",
    "edition": 1079,
    "date": 1672533565213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1079",
    "rarity_score": 371.61812125115625,
    "rarity_level": "COMMON"
  },
  "284": {
    "name": "Arkade 90s Bulls #284",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/284.png",
    "dna": "d0dd6ee18367192cc4b9fca115d470fc1b911a89",
    "edition": 284,
    "date": 1672533565992,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "284",
    "rarity_score": 344.4310376383543,
    "rarity_level": "COMMON"
  },
  "1848": {
    "name": "Arkade 90s Bulls #1848",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1848.png",
    "dna": "4cdde74fdb375efe27ba03985efecea4733cc760",
    "edition": 1848,
    "date": 1672533566585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1848",
    "rarity_score": 208.87148301279302,
    "rarity_level": "COMMON"
  },
  "1292": {
    "name": "Arkade 90s Bulls #1292",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1292.png",
    "dna": "1c73d9fcff1b897c3479e96929711c23d2d93e26",
    "edition": 1292,
    "date": 1672533567528,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1292",
    "rarity_score": 386.7467595020159,
    "rarity_level": "COMMON"
  },
  "670": {
    "name": "Arkade 90s Bulls #670",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/670.png",
    "dna": "82f354d002e942d8c665992f0c511a9f6dc2734d",
    "edition": 670,
    "date": 1672533567986,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "670",
    "rarity_score": 487.2149718132764,
    "rarity_level": "RARE"
  },
  "754": {
    "name": "Arkade 90s Bulls #754",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/754.png",
    "dna": "37707d8d389e600a7537a5de6564d237fd8085a0",
    "edition": 754,
    "date": 1672533568610,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Purple Angry", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "754",
    "rarity_score": 561.1276945149187,
    "rarity_level": "RARE"
  },
  "1764": {
    "name": "Arkade 90s Bulls #1764",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1764.png",
    "dna": "1aef80cceb6581ff2cb51019e4b8ec98e6a9f56a",
    "edition": 1764,
    "date": 1672533569147,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1764",
    "rarity_score": 469.11009785514614,
    "rarity_level": "RARE"
  },
  "1913": {
    "name": "Arkade 90s Bulls #1913",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1913.png",
    "dna": "d9c7ed6f62c71001a963d12b0fbdd549fa3020e7",
    "edition": 1913,
    "date": 1672533570325,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1913",
    "rarity_score": 423.2554882782218,
    "rarity_level": "COMMON"
  },
  "1690": {
    "name": "Arkade 90s Bulls #1690",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1690.png",
    "dna": "50979443c35ed0083c281508fa12f2df3b57dd70",
    "edition": 1690,
    "date": 1672533570836,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1690",
    "rarity_score": 311.54393494437466,
    "rarity_level": "COMMON"
  },
  "1778": {
    "name": "Arkade 90s Bulls #1778",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1778.png",
    "dna": "47148bb3be8344ce424f6590f8797466620d5949",
    "edition": 1778,
    "date": 1672533571357,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Tuxedo",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1778",
    "rarity_score": 371.62559955733764,
    "rarity_level": "COMMON"
  },
  "921": {
    "name": "Arkade 90s Bulls #921",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/921.png",
    "dna": "ce5685087f31a60bd263aa9c6de77fa190277d27",
    "edition": 921,
    "date": 1672533571918,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "921",
    "rarity_score": 344.3933581379262,
    "rarity_level": "COMMON"
  },
  "1125": {
    "name": "Arkade 90s Bulls #1125",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1125.png",
    "dna": "af927831e56cea24b6069ae4556036a590b03d57",
    "edition": 1125,
    "date": 1672533572411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1125",
    "rarity_score": 247.66120547762168,
    "rarity_level": "COMMON"
  },
  "344": {
    "name": "Arkade 90s Bulls #344",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/344.png",
    "dna": "9be71181e374d4fd55d6514678ce696357c77d59",
    "edition": 344,
    "date": 1672533572902,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "344",
    "rarity_score": 332.2852347974097,
    "rarity_level": "COMMON"
  },
  "102": {
    "name": "Arkade 90s Bulls #102",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/102.png",
    "dna": "48215f0081fe9a96aaaf5bd59130b506e5f528e7",
    "edition": 102,
    "date": 1672533573380,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "102",
    "rarity_score": 365.1461372322726,
    "rarity_level": "COMMON"
  },
  "1791": {
    "name": "Arkade 90s Bulls #1791",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1791.png",
    "dna": "3fa00d33bd4cb6b53506be944d6557725324ced6",
    "edition": 1791,
    "date": 1672533573883,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1791",
    "rarity_score": 418.5996232634866,
    "rarity_level": "COMMON"
  },
  "489": {
    "name": "Arkade 90s Bulls #489",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/489.png",
    "dna": "dccb125f959e9755de1593e6600655422b0cd509",
    "edition": 489,
    "date": 1672533574388,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Purple Angry", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "489",
    "rarity_score": 542.398484011987,
    "rarity_level": "RARE"
  },
  "646": {
    "name": "Arkade 90s Bulls #646",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/646.png",
    "dna": "eb3ade99becf811df67028bd9f574d1588626d52",
    "edition": 646,
    "date": 1672533575594,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "646",
    "rarity_score": 319.2868960018691,
    "rarity_level": "COMMON"
  },
  "352": {
    "name": "Arkade 90s Bulls #352",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/352.png",
    "dna": "e9235b50b2e38ad4346d9d1eec36e3d671deff06",
    "edition": 352,
    "date": 1672533576771,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "352",
    "rarity_score": 418.6027082455697,
    "rarity_level": "COMMON"
  },
  "1218": {
    "name": "Arkade 90s Bulls #1218",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1218.png",
    "dna": "f0f53867f5d6915fcce7b0cc22d3a6552fe45200",
    "edition": 1218,
    "date": 1672533577795,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1218",
    "rarity_score": 267.0368996129507,
    "rarity_level": "COMMON"
  },
  "6": {
    "name": "Arkade 90s Bulls #6",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/6.png",
    "dna": "06c5a5dde49d14c2e366dbe6b6a8e607224c9b3c",
    "edition": 6,
    "date": 1672533578916,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "6",
    "rarity_score": 389.1074886371655,
    "rarity_level": "COMMON"
  },
  "52": {
    "name": "Arkade 90s Bulls #52",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/52.png",
    "dna": "d9bd875aadfbe663011957637c0c3d13c9e917b5",
    "edition": 52,
    "date": 1672533579411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "52",
    "rarity_score": 406.27079671623085,
    "rarity_level": "COMMON"
  },
  "1111": {
    "name": "Arkade 90s Bulls #1111",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1111.png",
    "dna": "991d804ac5e4141d421839a940683fb84f76a3a5",
    "edition": 1111,
    "date": 1672533580583,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1111",
    "rarity_score": 308.09817375265146,
    "rarity_level": "COMMON"
  },
  "66": {
    "name": "Arkade 90s Bulls #66",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/66.png",
    "dna": "386de3679b5e1eac54c43f606316dada539f25a1",
    "edition": 66,
    "date": 1672533581100,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "66",
    "rarity_score": 640.4156767337579,
    "rarity_level": "RARE"
  },
  "682": {
    "name": "Arkade 90s Bulls #682",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/682.png",
    "dna": "7f939436beb59872b2f8ebb2e88dd2cb84d13e15",
    "edition": 682,
    "date": 1672533582139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "682",
    "rarity_score": 357.24621939870406,
    "rarity_level": "COMMON"
  },
  "929": {
    "name": "Arkade 90s Bulls #929",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/929.png",
    "dna": "7aa34a29976a631a1cd8cdd10ec2553f64f70265",
    "edition": 929,
    "date": 1672533582711,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "929",
    "rarity_score": 302.75228597616683,
    "rarity_level": "COMMON"
  },
  "1521": {
    "name": "Arkade 90s Bulls #1521",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1521.png",
    "dna": "738107c51c2389a1fbbadf44b03f7c677f6c3e77",
    "edition": 1521,
    "date": 1672533583210,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1521",
    "rarity_score": 292.8974684409043,
    "rarity_level": "COMMON"
  },
  "927": {
    "name": "Arkade 90s Bulls #927",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/927.png",
    "dna": "236d81aafc0d2eac3a36054dc0a8636f33091f84",
    "edition": 927,
    "date": 1672533583687,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "927",
    "rarity_score": 202.95401747564034,
    "rarity_level": "COMMON"
  },
  "1605": {
    "name": "Arkade 90s Bulls #1605",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1605.png",
    "dna": "95981256155de835b666bf00eb1e51bd96765b2b",
    "edition": 1605,
    "date": 1672533584315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1605",
    "rarity_score": 324.97526259473153,
    "rarity_level": "COMMON"
  },
  "1471": {
    "name": "Arkade 90s Bulls #1471",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1471.png",
    "dna": "3ce8e3fa6ba3ceb9c182d29d038f867f2033dd00",
    "edition": 1471,
    "date": 1672533584814,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1471",
    "rarity_score": 247.92659352574447,
    "rarity_level": "COMMON"
  },
  "1545": {
    "name": "Arkade 90s Bulls #1545",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1545.png",
    "dna": "e7f71eed8cd04dab5ac9e4104f0e218357e4ebd7",
    "edition": 1545,
    "date": 1672533585269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1545",
    "rarity_score": 192.79674135133882,
    "rarity_level": "COMMON"
  },
  "1122": {
    "name": "Arkade 90s Bulls #1122",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1122.png",
    "dna": "caa259382cf116aa39af2fe4a3d15f9319a37829",
    "edition": 1122,
    "date": 1672533586415,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1122",
    "rarity_score": 207.59239919098206,
    "rarity_level": "COMMON"
  },
  "974": {
    "name": "Arkade 90s Bulls #974",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/974.png",
    "dna": "79f6355ca23d86697316f216ce9e83df10b78b50",
    "edition": 974,
    "date": 1672533587492,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "974",
    "rarity_score": 265.5735018732497,
    "rarity_level": "COMMON"
  },
  "29": {
    "name": "Arkade 90s Bulls #29",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/29.png",
    "dna": "16c348a7dc98f381901c2c9f21a09884649eb1a2",
    "edition": 29,
    "date": 1672533587965,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "29",
    "rarity_score": 310.35845800836756,
    "rarity_level": "COMMON"
  },
  "1476": {
    "name": "Arkade 90s Bulls #1476",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1476.png",
    "dna": "af8e3f7f9a4082575e50ea249cc94cc9e2284d47",
    "edition": 1476,
    "date": 1672533588446,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1476",
    "rarity_score": 479.0474580317696,
    "rarity_level": "RARE"
  },
  "605": {
    "name": "Arkade 90s Bulls #605",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/605.png",
    "dna": "ae62fb2dc7863e2efca82f7fa1d75feab9661186",
    "edition": 605,
    "date": 1672533589215,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "605",
    "rarity_score": 275.2829464274232,
    "rarity_level": "COMMON"
  },
  "580": {
    "name": "Arkade 90s Bulls #580",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/580.png",
    "dna": "6315603e2d63b77ec4eda1b64352b93da608378e",
    "edition": 580,
    "date": 1672533589939,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "580",
    "rarity_score": 327.70860539803147,
    "rarity_level": "COMMON"
  },
  "1126": {
    "name": "Arkade 90s Bulls #1126",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1126.png",
    "dna": "eb4189abff9698c1dbc88b6e7fcc4dfa80f6b69a",
    "edition": 1126,
    "date": 1672533591105,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1126",
    "rarity_score": 358.5346921985023,
    "rarity_level": "COMMON"
  },
  "1398": {
    "name": "Arkade 90s Bulls #1398",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1398.png",
    "dna": "3e071800823623e5227dc2d992374a9bcdec8190",
    "edition": 1398,
    "date": 1672533591629,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1398",
    "rarity_score": 217.87187787194662,
    "rarity_level": "COMMON"
  },
  "808": {
    "name": "Arkade 90s Bulls #808",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/808.png",
    "dna": "a0c8808d8aedde6c84031da1df2aa7c0519a79a8",
    "edition": 808,
    "date": 1672533592092,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Tongue Out",
        "score": 248.75
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "808",
    "rarity_score": 323.72701225502067,
    "rarity_level": "COMMON"
  },
  "509": {
    "name": "Arkade 90s Bulls #509",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/509.png",
    "dna": "aad81c74eb4472c41734552444fc48ec484a7ce2",
    "edition": 509,
    "date": 1672533592584,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "509",
    "rarity_score": 394.9666956458276,
    "rarity_level": "COMMON"
  },
  "986": {
    "name": "Arkade 90s Bulls #986",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/986.png",
    "dna": "cc6d6508c3bd506c2e25ab123063d47cd8d33dc2",
    "edition": 986,
    "date": 1672533593171,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "Game Screen",
        "score": 331.6666666666667
      }
    ],
    "id": "986",
    "rarity_score": 607.7312738940411,
    "rarity_level": "RARE"
  },
  "1682": {
    "name": "Arkade 90s Bulls #1682",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1682.png",
    "dna": "17fe2c9400fbe06e0306af62007a21ed3917152f",
    "edition": 1682,
    "date": 1672533594390,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1682",
    "rarity_score": 388.12589715304136,
    "rarity_level": "COMMON"
  },
  "771": {
    "name": "Arkade 90s Bulls #771",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/771.png",
    "dna": "9701ba538db1766387bd2b019462553f606733ff",
    "edition": 771,
    "date": 1672533594925,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Purple Angry", "score": 398.0 },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "771",
    "rarity_score": 532.7958673895506,
    "rarity_level": "RARE"
  },
  "874": {
    "name": "Arkade 90s Bulls #874",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/874.png",
    "dna": "52e774b75c09320327e39d26473fb1785fb6ff06",
    "edition": 874,
    "date": 1672533595412,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "874",
    "rarity_score": 279.2195736874167,
    "rarity_level": "COMMON"
  },
  "364": {
    "name": "Arkade 90s Bulls #364",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/364.png",
    "dna": "b5f43fb672b9c759e1db113b5dd37a3f97d3207d",
    "edition": 364,
    "date": 1672533596654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Sad",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "364",
    "rarity_score": 259.41990828227756,
    "rarity_level": "COMMON"
  },
  "1935": {
    "name": "Arkade 90s Bulls #1935",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1935.png",
    "dna": "0c7572142b83f49a920f7b5f97daa4dcb0b92cf3",
    "edition": 1935,
    "date": 1672533597355,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1935",
    "rarity_score": 206.57974736165073,
    "rarity_level": "COMMON"
  },
  "632": {
    "name": "Arkade 90s Bulls #632",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/632.png",
    "dna": "6afb47eff4ad955b3f5528bd2796358393155b19",
    "edition": 632,
    "date": 1672533597808,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "632",
    "rarity_score": 279.67642014434847,
    "rarity_level": "COMMON"
  },
  "891": {
    "name": "Arkade 90s Bulls #891",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/891.png",
    "dna": "8311416193deb6bfbc23b4ed381f375672b1234f",
    "edition": 891,
    "date": 1672533598269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "891",
    "rarity_score": 495.1508357308747,
    "rarity_level": "RARE"
  },
  "805": {
    "name": "Arkade 90s Bulls #805",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/805.png",
    "dna": "5c4268f0b47a71abcceb67af96d5607a3e8487fb",
    "edition": 805,
    "date": 1672533598750,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "805",
    "rarity_score": 187.59016608455798,
    "rarity_level": "COMMON"
  },
  "354": {
    "name": "Arkade 90s Bulls #354",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/354.png",
    "dna": "f4dec6ddaa36f4e0bb2c55faa47495a46cc01dda",
    "edition": 354,
    "date": 1672533599288,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Purple Smile",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "354",
    "rarity_score": 323.1688335308737,
    "rarity_level": "COMMON"
  },
  "1485": {
    "name": "Arkade 90s Bulls #1485",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1485.png",
    "dna": "68e40184be91ccfd71b064e81b466912b066efe6",
    "edition": 1485,
    "date": 1672533599776,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Happy",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Habibi Hat",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1485",
    "rarity_score": 440.763717143296,
    "rarity_level": "COMMON"
  },
  "229": {
    "name": "Arkade 90s Bulls #229",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/229.png",
    "dna": "3ffc57969ac2e7cfc808790b9d3a4d26dee2ab3b",
    "edition": 229,
    "date": 1672533600577,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Purple Evil",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Eyes",
        "value": "Down Brown Eyes",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "229",
    "rarity_score": 523.8854361120364,
    "rarity_level": "RARE"
  },
  "1627": {
    "name": "Arkade 90s Bulls #1627",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1627.png",
    "dna": "7f654d02f85d507235d11126b78a564ab6906482",
    "edition": 1627,
    "date": 1672533601953,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Base",
        "value": "Ghost Angry",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Deadbull Mask",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1627",
    "rarity_score": 1074.1496032070982,
    "rarity_level": "EPIC"
  },
  "1825": {
    "name": "Arkade 90s Bulls #1825",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1825.png",
    "dna": "d7671f7b47be60ed24eb84a1d72b9c3126ada894",
    "edition": 1825,
    "date": 1672533602525,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      { "trait_type": "Base", "value": "Ghost Sad", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Purple",
        "score": 99.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1825",
    "rarity_score": 593.2950399131878,
    "rarity_level": "RARE"
  },
  "984": {
    "name": "Arkade 90s Bulls #984",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/984.png",
    "dna": "b2b6decfe554c38acd7f4dc759a9770068e4a7ff",
    "edition": 984,
    "date": 1672533603079,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "984",
    "rarity_score": 506.3439274014223,
    "rarity_level": "RARE"
  },
  "912": {
    "name": "Arkade 90s Bulls #912",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/912.png",
    "dna": "2b032b319ab630af5df8f3162515932683abe46e",
    "edition": 912,
    "date": 1672533604462,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      { "trait_type": "Base", "value": "Ghost Happy", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "912",
    "rarity_score": 596.0694866861921,
    "rarity_level": "RARE"
  },
  "457": {
    "name": "Arkade 90s Bulls #457",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/457.png",
    "dna": "a9c5604c8c2c27065d1f2f3b9f45f88330ffb631",
    "edition": 457,
    "date": 1672533605096,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      { "trait_type": "Base", "value": "Ghost Sad", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "457",
    "rarity_score": 684.7252238596761,
    "rarity_level": "RARE"
  },
  "658": {
    "name": "Arkade 90s Bulls #658",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/658.png",
    "dna": "f61ff9597af791b01f8cc910d4697d97406581d8",
    "edition": 658,
    "date": 1672533605710,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Base",
        "value": "Ghost Tongue Out",
        "score": 995.0
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "658",
    "rarity_score": 1093.7353286339921,
    "rarity_level": "EPIC"
  },
  "798": {
    "name": "Arkade 90s Bulls #798",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/798.png",
    "dna": "f8745f54003b90e4136ed84f1db52e347d89fe11",
    "edition": 798,
    "date": 1672533607220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "798",
    "rarity_score": 300.5556463598519,
    "rarity_level": "COMMON"
  },
  "1687": {
    "name": "Arkade 90s Bulls #1687",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1687.png",
    "dna": "aa2ad75da5fe560db621cf097d7254c1ad7f406d",
    "edition": 1687,
    "date": 1672533607835,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1687",
    "rarity_score": 278.092847483292,
    "rarity_level": "COMMON"
  },
  "983": {
    "name": "Arkade 90s Bulls #983",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/983.png",
    "dna": "a8aa1ddc5ebc7226e816fe13b7446158fbfeea36",
    "edition": 983,
    "date": 1672533608411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      { "trait_type": "Base", "value": "Ghost Sad", "score": 398.0 },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "983",
    "rarity_score": 683.6153886442971,
    "rarity_level": "RARE"
  },
  "1652": {
    "name": "Arkade 90s Bulls #1652",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1652.png",
    "dna": "ce4294efb2d9a40e378183e4636d986ee2769f40",
    "edition": 1652,
    "date": 1672533609729,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1652",
    "rarity_score": 318.8180159724937,
    "rarity_level": "COMMON"
  },
  "1588": {
    "name": "Arkade 90s Bulls #1588",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1588.png",
    "dna": "be64ab8687954c8f79f1402d9f6cf4c8a3fad8a2",
    "edition": 1588,
    "date": 1672533610360,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Base",
        "value": "Ghost Tongue Out",
        "score": 995.0
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1588",
    "rarity_score": 1150.4402981582093,
    "rarity_level": "EPIC"
  },
  "1035": {
    "name": "Arkade 90s Bulls #1035",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1035.png",
    "dna": "16137f31e15f7fcdcc3bd33ed2bea17a491b826c",
    "edition": 1035,
    "date": 1672533612168,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      { "trait_type": "Base", "value": "Ghost Sad", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1035",
    "rarity_score": 484.52375017852836,
    "rarity_level": "RARE"
  },
  "291": {
    "name": "Arkade 90s Bulls #291",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/291.png",
    "dna": "f29101663ce70a3131be7e247451ed9f458a6057",
    "edition": 291,
    "date": 1672533612899,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "291",
    "rarity_score": 364.29104789040207,
    "rarity_level": "COMMON"
  },
  "743": {
    "name": "Arkade 90s Bulls #743",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/743.png",
    "dna": "8fc545770c6e366d54e348632b87526ddd26bfb7",
    "edition": 743,
    "date": 1672533613563,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      { "trait_type": "Base", "value": "Ghost Happy", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "743",
    "rarity_score": 627.1898505078397,
    "rarity_level": "RARE"
  },
  "1631": {
    "name": "Arkade 90s Bulls #1631",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1631.png",
    "dna": "60433bb15eab48fc7ff2971f61e6b636e52dbd8f",
    "edition": 1631,
    "date": 1672533614396,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      { "trait_type": "Base", "value": "Ghost Sad", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1631",
    "rarity_score": 470.3464859467336,
    "rarity_level": "RARE"
  },
  "1520": {
    "name": "Arkade 90s Bulls #1520",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1520.png",
    "dna": "c6854b71bc63b65dba049460230cf9e1fd361747",
    "edition": 1520,
    "date": 1672533615077,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Base",
        "value": "Ghost Angry",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "Dennis Hair",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1520",
    "rarity_score": 966.5764366751621,
    "rarity_level": "RARE"
  },
  "1316": {
    "name": "Arkade 90s Bulls #1316",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1316.png",
    "dna": "ebb4f44293dc5ed1ccf9f551561161e3d39248a2",
    "edition": 1316,
    "date": 1672533615689,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1316",
    "rarity_score": 374.2722909967147,
    "rarity_level": "COMMON"
  },
  "581": {
    "name": "Arkade 90s Bulls #581",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/581.png",
    "dna": "f7252a95f7b4d49dbae80d4c98a4135c21449d26",
    "edition": 581,
    "date": 1672533616258,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "581",
    "rarity_score": 512.2494241467027,
    "rarity_level": "RARE"
  },
  "1227": {
    "name": "Arkade 90s Bulls #1227",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1227.png",
    "dna": "f3c58ff7ebfa958da2a2ba2406d73af27f86af47",
    "edition": 1227,
    "date": 1672533616886,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1227",
    "rarity_score": 335.2826370974641,
    "rarity_level": "COMMON"
  },
  "1374": {
    "name": "Arkade 90s Bulls #1374",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1374.png",
    "dna": "df1855ededc5d6166855984ca64e08ba95d24e1e",
    "edition": 1374,
    "date": 1672533617482,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      { "trait_type": "Base", "value": "Ghost Happy", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1374",
    "rarity_score": 513.6233185802233,
    "rarity_level": "RARE"
  },
  "1132": {
    "name": "Arkade 90s Bulls #1132",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1132.png",
    "dna": "75e518a7141f75d654fbe813b9c763b5f29ed505",
    "edition": 1132,
    "date": 1672533618465,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      { "trait_type": "Base", "value": "Ghost Happy", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Wayne Head",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1132",
    "rarity_score": 669.297597329273,
    "rarity_level": "RARE"
  },
  "248": {
    "name": "Arkade 90s Bulls #248",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/248.png",
    "dna": "c9484b9558d5465f44033045d96a7a5c7931a2bb",
    "edition": 248,
    "date": 1672533619064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Base",
        "value": "Ghost Angry",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "248",
    "rarity_score": 769.5110448579231,
    "rarity_level": "RARE"
  },
  "860": {
    "name": "Arkade 90s Bulls #860",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/860.png",
    "dna": "16693076246312320179a9216ef54dc99397ff6d",
    "edition": 860,
    "date": 1672533620390,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      { "trait_type": "Base", "value": "Ghost Evil", "score": 1990.0 },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      { "trait_type": "Head", "value": "Pickles Hair", "score": 995.0 },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "860",
    "rarity_score": 3065.5735523667668,
    "rarity_level": "LEGENDARY"
  },
  "1770": {
    "name": "Arkade 90s Bulls #1770",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1770.png",
    "dna": "8016ca92800fc537e667d1427c92f33480881e6f",
    "edition": 1770,
    "date": 1672533621026,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      { "trait_type": "Base", "value": "Ghost Happy", "score": 398.0 },
      {
        "trait_type": "Outfit",
        "value": "Tough Turtle",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1770",
    "rarity_score": 770.635630277158,
    "rarity_level": "RARE"
  },
  "1862": {
    "name": "Arkade 90s Bulls #1862",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1862.png",
    "dna": "2228f33487c8a1e62f6942732363c0f0a096b078",
    "edition": 1862,
    "date": 1672533621692,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Base",
        "value": "Ghost Smile",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1862",
    "rarity_score": 312.06319575148905,
    "rarity_level": "COMMON"
  },
  "1137": {
    "name": "Arkade 90s Bulls #1137",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1137.png",
    "dna": "624189ed7d456000d89661786d5f08afdf39e679",
    "edition": 1137,
    "date": 1672533622168,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Orange",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1137",
    "rarity_score": 336.03561536735674,
    "rarity_level": "COMMON"
  },
  "1001": {
    "name": "Arkade 90s Bulls #1001",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1001.png",
    "dna": "7288073d036218c9a13c345789560218a5db320c",
    "edition": 1001,
    "date": 1672533623295,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1001",
    "rarity_score": 131.25845176310008,
    "rarity_level": "COMMON"
  },
  "113": {
    "name": "Arkade 90s Bulls #113",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/113.png",
    "dna": "9dc78c10b5e4282a27d15c9c20ff55d66407d098",
    "edition": 113,
    "date": 1672533623764,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "Green Guardian Mask",
        "score": 497.5
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "113",
    "rarity_score": 580.3523949363224,
    "rarity_level": "RARE"
  },
  "134": {
    "name": "Arkade 90s Bulls #134",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/134.png",
    "dna": "7960f417fbc6906c290f22f4609d3402103fa25b",
    "edition": 134,
    "date": 1672533624262,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "134",
    "rarity_score": 221.75488455569857,
    "rarity_level": "COMMON"
  },
  "613": {
    "name": "Arkade 90s Bulls #613",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/613.png",
    "dna": "8246a0f831ee0afac55a749b61562590137869db",
    "edition": 613,
    "date": 1672533624815,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "613",
    "rarity_score": 197.4411818224406,
    "rarity_level": "COMMON"
  },
  "1563": {
    "name": "Arkade 90s Bulls #1563",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1563.png",
    "dna": "88c867f2dcf71309f757cf6a873bdd6047b6bc08",
    "edition": 1563,
    "date": 1672533625286,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Mask",
        "value": "Quickster Mask",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1563",
    "rarity_score": 233.6819421499857,
    "rarity_level": "COMMON"
  },
  "1305": {
    "name": "Arkade 90s Bulls #1305",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1305.png",
    "dna": "035886635d3212333e9d2ab8b5394271b5373e9e",
    "edition": 1305,
    "date": 1672533626028,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1305",
    "rarity_score": 302.51360992621323,
    "rarity_level": "COMMON"
  },
  "545": {
    "name": "Arkade 90s Bulls #545",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/545.png",
    "dna": "6b889eb388da6baa49d929df332e06517748b683",
    "edition": 545,
    "date": 1672533626492,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "545",
    "rarity_score": 116.54292934199682,
    "rarity_level": "COMMON"
  },
  "977": {
    "name": "Arkade 90s Bulls #977",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/977.png",
    "dna": "3e957e8b39e233f37caeaad0026f874971d8f5a6",
    "edition": 977,
    "date": 1672533626945,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Blue",
        "score": 124.375
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "977",
    "rarity_score": 261.58520549259106,
    "rarity_level": "COMMON"
  },
  "1801": {
    "name": "Arkade 90s Bulls #1801",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1801.png",
    "dna": "af1dd01e682e4c3f0c8c504656add6063690f9bb",
    "edition": 1801,
    "date": 1672533627420,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1801",
    "rarity_score": 332.4762935732622,
    "rarity_level": "COMMON"
  },
  "562": {
    "name": "Arkade 90s Bulls #562",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/562.png",
    "dna": "bbaee1161926b1c051987b701d80ff531f5a2275",
    "edition": 562,
    "date": 1672533627861,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Bullverine Mask",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "562",
    "rarity_score": 193.14912363280104,
    "rarity_level": "COMMON"
  },
  "1886": {
    "name": "Arkade 90s Bulls #1886",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1886.png",
    "dna": "6c3cb30005b9e924c9ec873d53775292803716a3",
    "edition": 1886,
    "date": 1672533628402,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Mask",
        "value": "Punk Helmet",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1886",
    "rarity_score": 220.0796150870871,
    "rarity_level": "COMMON"
  },
  "1046": {
    "name": "Arkade 90s Bulls #1046",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1046.png",
    "dna": "cc0697f6706dd648bfdfb8c598c4632266f9173d",
    "edition": 1046,
    "date": 1672533628860,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1046",
    "rarity_score": 175.5545079829751,
    "rarity_level": "COMMON"
  },
  "480": {
    "name": "Arkade 90s Bulls #480",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/480.png",
    "dna": "ee51d9ee3a8b6a5438f0505fc9abcf28c2ca1995",
    "edition": 480,
    "date": 1672533630134,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "480",
    "rarity_score": 138.80015278062075,
    "rarity_level": "COMMON"
  },
  "402": {
    "name": "Arkade 90s Bulls #402",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/402.png",
    "dna": "b0129c85385c029d202bb5479f3320595d0abee0",
    "edition": 402,
    "date": 1672533630599,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "402",
    "rarity_score": 88.44725823902041,
    "rarity_level": "COMMON"
  },
  "1313": {
    "name": "Arkade 90s Bulls #1313",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1313.png",
    "dna": "11b83e56dc6396110aae66d56744c18726321e79",
    "edition": 1313,
    "date": 1672533631074,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 2",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Mask",
        "value": "Ninja Mask Red",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1313",
    "rarity_score": 324.9118527236566,
    "rarity_level": "COMMON"
  },
  "495": {
    "name": "Arkade 90s Bulls #495",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/495.png",
    "dna": "28ccc6a35e2893ee68ae368e70bfc84a0beac9ab",
    "edition": 495,
    "date": 1672533632253,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "495",
    "rarity_score": 137.34017330017244,
    "rarity_level": "COMMON"
  },
  "726": {
    "name": "Arkade 90s Bulls #726",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/726.png",
    "dna": "f1c53625ee96ef7b558a6e373c6ccc45f9f49ee5",
    "edition": 726,
    "date": 1672533632686,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "726",
    "rarity_score": 142.34805662678576,
    "rarity_level": "COMMON"
  },
  "1464": {
    "name": "Arkade 90s Bulls #1464",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1464.png",
    "dna": "18cbeca5f8c5c6ddac384bbe8a39b144c668d5cb",
    "edition": 1464,
    "date": 1672533633213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1464",
    "rarity_score": 193.48733425584402,
    "rarity_level": "COMMON"
  },
  "421": {
    "name": "Arkade 90s Bulls #421",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/421.png",
    "dna": "09a76202b300528e57ce75b121965a04b943bd69",
    "edition": 421,
    "date": 1672533633728,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Mask",
        "value": "Spiderbull",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "421",
    "rarity_score": 316.1082295325076,
    "rarity_level": "COMMON"
  },
  "429": {
    "name": "Arkade 90s Bulls #429",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/429.png",
    "dna": "1b8d9e8ac5edc9d2d545f0d5e54322d547b5aad3",
    "edition": 429,
    "date": 1672533634269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Mask",
        "value": "The Closer Face",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "429",
    "rarity_score": 323.52884920943893,
    "rarity_level": "COMMON"
  },
  "1097": {
    "name": "Arkade 90s Bulls #1097",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1097.png",
    "dna": "2b78a4a1f511c3e3545a83b8bcbe2b4efa84fe42",
    "edition": 1097,
    "date": 1672533634968,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1097",
    "rarity_score": 391.7874601771815,
    "rarity_level": "COMMON"
  },
  "91": {
    "name": "Arkade 90s Bulls #91",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/91.png",
    "dna": "e521fd7b46e81e10898682899cb53e1b9f38522e",
    "edition": 91,
    "date": 1672533635470,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Mask",
        "value": "Kajoras Mask",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "91",
    "rarity_score": 200.36311492318444,
    "rarity_level": "COMMON"
  },
  "85": {
    "name": "Arkade 90s Bulls #85",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/85.png",
    "dna": "280b2610083441523fb35c657a4273776edb0d7a",
    "edition": 85,
    "date": 1672533635969,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "85",
    "rarity_score": 177.3164711690726,
    "rarity_level": "COMMON"
  },
  "1428": {
    "name": "Arkade 90s Bulls #1428",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1428.png",
    "dna": "226976a34f07e5e86ebcd36bf2fa589dd118e421",
    "edition": 1428,
    "date": 1672533636477,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Mask",
        "value": "NONE",
        "score": 4.783653846153846
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1428",
    "rarity_score": 149.68475230741768,
    "rarity_level": "COMMON"
  },
  "1338": {
    "name": "Arkade 90s Bulls #1338",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1338.png",
    "dna": "a617d1130a3b28ba6ec9534bd58db4166347bdba",
    "edition": 1338,
    "date": 1672533637111,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1338",
    "rarity_score": 461.5548793449219,
    "rarity_level": "RARE"
  },
  "1074": {
    "name": "Arkade 90s Bulls #1074",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1074.png",
    "dna": "ac8784a147b20401fe65ab7442da9650037a79d5",
    "edition": 1074,
    "date": 1672533638349,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1074",
    "rarity_score": 210.75416260878478,
    "rarity_level": "COMMON"
  },
  "1239": {
    "name": "Arkade 90s Bulls #1239",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1239.png",
    "dna": "bb2c03790d6e542655ea3b3b582a52a133ce0aec",
    "edition": 1239,
    "date": 1672533639240,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1239",
    "rarity_score": 237.39206625037542,
    "rarity_level": "COMMON"
  },
  "1506": {
    "name": "Arkade 90s Bulls #1506",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1506.png",
    "dna": "09ebe2d2592ff212ad81e79a5bf7026bee6e1b6c",
    "edition": 1506,
    "date": 1672533639750,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Pickels Overalls",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1506",
    "rarity_score": 797.8459382337983,
    "rarity_level": "RARE"
  },
  "928": {
    "name": "Arkade 90s Bulls #928",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/928.png",
    "dna": "b4fb78e3ec57ef7ca7ecd1c66667fb7a7e97d633",
    "edition": 928,
    "date": 1672533640269,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "928",
    "rarity_score": 388.6028420475335,
    "rarity_level": "COMMON"
  },
  "254": {
    "name": "Arkade 90s Bulls #254",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/254.png",
    "dna": "e5d587a165f310ed5bf81e50e563bd0dcce7183a",
    "edition": 254,
    "date": 1672533640794,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "254",
    "rarity_score": 212.31032533138625,
    "rarity_level": "COMMON"
  },
  "110": {
    "name": "Arkade 90s Bulls #110",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/110.png",
    "dna": "76f818e0ef8cf5633b1e63232de53bd7149bb9bb",
    "edition": 110,
    "date": 1672533641268,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      { "trait_type": "Head", "value": "Okko Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "110",
    "rarity_score": 501.92227280473236,
    "rarity_level": "RARE"
  },
  "672": {
    "name": "Arkade 90s Bulls #672",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/672.png",
    "dna": "9407aeaf07a4eab1679f113cf9fd40672bcbade6",
    "edition": 672,
    "date": 1672533642718,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "672",
    "rarity_score": 266.7273378475793,
    "rarity_level": "COMMON"
  },
  "267": {
    "name": "Arkade 90s Bulls #267",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/267.png",
    "dna": "61e41809d97b442268833b375a9b35b36a97d143",
    "edition": 267,
    "date": 1672533643302,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "267",
    "rarity_score": 251.16075235259686,
    "rarity_level": "COMMON"
  },
  "640": {
    "name": "Arkade 90s Bulls #640",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/640.png",
    "dna": "c091ae4b54b66ef2e23149820ab1ef6fd994fc85",
    "edition": 640,
    "date": 1672533643796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "640",
    "rarity_score": 243.74106828358703,
    "rarity_level": "COMMON"
  },
  "1858": {
    "name": "Arkade 90s Bulls #1858",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1858.png",
    "dna": "455fc6a98aedff4457c95955566b5aa921f59364",
    "edition": 1858,
    "date": 1672533644299,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1858",
    "rarity_score": 432.03785647415026,
    "rarity_level": "COMMON"
  },
  "486": {
    "name": "Arkade 90s Bulls #486",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/486.png",
    "dna": "64bed6c2ad1c8c47d2e0c8514a6890bdd59617d7",
    "edition": 486,
    "date": 1672533645518,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "486",
    "rarity_score": 156.23303924934845,
    "rarity_level": "COMMON"
  },
  "442": {
    "name": "Arkade 90s Bulls #442",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/442.png",
    "dna": "1632c1581a74861a42cf8528e4f56e8c61d633ed",
    "edition": 442,
    "date": 1672533646016,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "442",
    "rarity_score": 246.6839172059435,
    "rarity_level": "COMMON"
  },
  "232": {
    "name": "Arkade 90s Bulls #232",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/232.png",
    "dna": "59c086b437a7cb4d03c1733e09f2e6decb5efbba",
    "edition": 232,
    "date": 1672533646493,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "232",
    "rarity_score": 252.86394848675593,
    "rarity_level": "COMMON"
  },
  "1907": {
    "name": "Arkade 90s Bulls #1907",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1907.png",
    "dna": "cc9b653f12bb20d81decf87b28ab116d42bf7857",
    "edition": 1907,
    "date": 1672533646988,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1907",
    "rarity_score": 288.1522026900391,
    "rarity_level": "COMMON"
  },
  "1027": {
    "name": "Arkade 90s Bulls #1027",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1027.png",
    "dna": "43731bf6094743eb2c4b9c3acd111c04e0808744",
    "edition": 1027,
    "date": 1672533647502,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1027",
    "rarity_score": 278.1695101807729,
    "rarity_level": "COMMON"
  },
  "1723": {
    "name": "Arkade 90s Bulls #1723",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1723.png",
    "dna": "96119b6c618430a1f645cc5b9f99334c4e2a8613",
    "edition": 1723,
    "date": 1672533648118,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1723",
    "rarity_score": 378.3083882265964,
    "rarity_level": "COMMON"
  },
  "55": {
    "name": "Arkade 90s Bulls #55",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/55.png",
    "dna": "a835fc2125583a64eb37844cf8931f3a7416d005",
    "edition": 55,
    "date": 1672533648588,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "55",
    "rarity_score": 165.38159428945661,
    "rarity_level": "COMMON"
  },
  "1580": {
    "name": "Arkade 90s Bulls #1580",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1580.png",
    "dna": "be26c8c026efc996c5531778e4a8f6cfd3db6bfd",
    "edition": 1580,
    "date": 1672533649652,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1580",
    "rarity_score": 168.97712550905894,
    "rarity_level": "COMMON"
  },
  "1199": {
    "name": "Arkade 90s Bulls #1199",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1199.png",
    "dna": "604f01ae7c47107b4244be145906c3bac27f2b5f",
    "edition": 1199,
    "date": 1672533650180,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1199",
    "rarity_score": 272.0303876745142,
    "rarity_level": "COMMON"
  },
  "1369": {
    "name": "Arkade 90s Bulls #1369",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1369.png",
    "dna": "1d38ae7b67e4fe95ec437e999b923bd46a34464d",
    "edition": 1369,
    "date": 1672533650694,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1369",
    "rarity_score": 248.10522616762572,
    "rarity_level": "COMMON"
  },
  "417": {
    "name": "Arkade 90s Bulls #417",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/417.png",
    "dna": "a4f063cc2b8a39170c83c2438d15cd2f351b3557",
    "edition": 417,
    "date": 1672533651215,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "90s Vest",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "417",
    "rarity_score": 550.3284916364175,
    "rarity_level": "RARE"
  },
  "1676": {
    "name": "Arkade 90s Bulls #1676",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1676.png",
    "dna": "7887e754ec6484b3bc18730670ce9fe9edbcc787",
    "edition": 1676,
    "date": 1672533652480,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1676",
    "rarity_score": 285.08823657192465,
    "rarity_level": "COMMON"
  },
  "1091": {
    "name": "Arkade 90s Bulls #1091",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1091.png",
    "dna": "e8ffcb35c177030160e8b9117d15994c73a0d438",
    "edition": 1091,
    "date": 1672533652994,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1091",
    "rarity_score": 478.0208062728895,
    "rarity_level": "RARE"
  },
  "1897": {
    "name": "Arkade 90s Bulls #1897",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1897.png",
    "dna": "8999c3a30e645c35af7fe8c42bda46a352822484",
    "edition": 1897,
    "date": 1672533653521,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1897",
    "rarity_score": 235.46687066354184,
    "rarity_level": "COMMON"
  },
  "194": {
    "name": "Arkade 90s Bulls #194",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/194.png",
    "dna": "ce8c0a90f6edf36fdcccb873ce5889b663ce8c8b",
    "edition": 194,
    "date": 1672533654027,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "194",
    "rarity_score": 338.15668236255516,
    "rarity_level": "COMMON"
  },
  "383": {
    "name": "Arkade 90s Bulls #383",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/383.png",
    "dna": "e28d65b24cbaf92912d6d2cca4af048b61140e04",
    "edition": 383,
    "date": 1672533654545,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "383",
    "rarity_score": 198.3834377118789,
    "rarity_level": "COMMON"
  },
  "1822": {
    "name": "Arkade 90s Bulls #1822",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1822.png",
    "dna": "b8709d06d5a832f66455b41863e8d1085cd406cd",
    "edition": 1822,
    "date": 1672533655033,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Down Green Eyes",
        "score": 124.375
      },
      { "trait_type": "Outfit", "value": "The Stone", "score": 79.6 },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1822",
    "rarity_score": 352.72270430502095,
    "rarity_level": "COMMON"
  },
  "515": {
    "name": "Arkade 90s Bulls #515",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/515.png",
    "dna": "2980602125c80ae7f8da916fbccfed152c820a38",
    "edition": 515,
    "date": 1672533655515,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "515",
    "rarity_score": 201.35115048383946,
    "rarity_level": "COMMON"
  },
  "1546": {
    "name": "Arkade 90s Bulls #1546",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1546.png",
    "dna": "97025f39b7aa39fdd7c863285826400d266084c2",
    "edition": 1546,
    "date": 1672533656033,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1546",
    "rarity_score": 322.3655898952663,
    "rarity_level": "COMMON"
  },
  "1026": {
    "name": "Arkade 90s Bulls #1026",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1026.png",
    "dna": "ba89acac5425fd3c6e8969adf516a3df3378292e",
    "edition": 1026,
    "date": 1672533656527,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1026",
    "rarity_score": 301.45341359122904,
    "rarity_level": "COMMON"
  },
  "65": {
    "name": "Arkade 90s Bulls #65",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/65.png",
    "dna": "1ec349562f5657494a381d78087c046c89f6262e",
    "edition": 65,
    "date": 1672533657019,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "65",
    "rarity_score": 272.7093518158774,
    "rarity_level": "COMMON"
  },
  "1604": {
    "name": "Arkade 90s Bulls #1604",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1604.png",
    "dna": "2c231d5ac1616e86a7906b7565b9b0132260df35",
    "edition": 1604,
    "date": 1672533658172,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1604",
    "rarity_score": 180.3888695985229,
    "rarity_level": "COMMON"
  },
  "361": {
    "name": "Arkade 90s Bulls #361",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/361.png",
    "dna": "9af9c24933d9bcf135546c05664cbedf70b10b1b",
    "edition": 361,
    "date": 1672533658647,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "361",
    "rarity_score": 72.6364017503928,
    "rarity_level": "COMMON"
  },
  "1743": {
    "name": "Arkade 90s Bulls #1743",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1743.png",
    "dna": "5c973bb90b467615cbd1ea1e3065af79ec6d3892",
    "edition": 1743,
    "date": 1672533659239,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1743",
    "rarity_score": 264.6371256971764,
    "rarity_level": "COMMON"
  },
  "626": {
    "name": "Arkade 90s Bulls #626",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/626.png",
    "dna": "f3310ad9f3c0bdee9ff46d3f64e78a4000a877dd",
    "edition": 626,
    "date": 1672533659747,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "626",
    "rarity_score": 179.70741443880402,
    "rarity_level": "COMMON"
  },
  "1671": {
    "name": "Arkade 90s Bulls #1671",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1671.png",
    "dna": "dc291a2853219764527453896a3b9758bec55fdc",
    "edition": 1671,
    "date": 1672533660232,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Koku Hair",
        "score": 284.2857142857143
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1671",
    "rarity_score": 452.8447396422679,
    "rarity_level": "COMMON"
  },
  "728": {
    "name": "Arkade 90s Bulls #728",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/728.png",
    "dna": "b4b50473c0f702c3bb8c7989c5422d704bde576f",
    "edition": 728,
    "date": 1672533660753,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "728",
    "rarity_score": 302.74638117298406,
    "rarity_level": "COMMON"
  },
  "1699": {
    "name": "Arkade 90s Bulls #1699",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1699.png",
    "dna": "f18b0f655fcff34ab40a8df213c204db4a7997f8",
    "edition": 1699,
    "date": 1672533661291,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1699",
    "rarity_score": 263.65603637745886,
    "rarity_level": "COMMON"
  },
  "1006": {
    "name": "Arkade 90s Bulls #1006",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1006.png",
    "dna": "1fbeae6405ca26f8d946c6d2b5ab0912a9f7c732",
    "edition": 1006,
    "date": 1672533662339,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1006",
    "rarity_score": 145.23976760582482,
    "rarity_level": "COMMON"
  },
  "313": {
    "name": "Arkade 90s Bulls #313",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/313.png",
    "dna": "f78d83ce25ae4e90faa62eb63b30d027ae8b4ae2",
    "edition": 313,
    "date": 1672533663085,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "313",
    "rarity_score": 190.90873212918916,
    "rarity_level": "COMMON"
  },
  "876": {
    "name": "Arkade 90s Bulls #876",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/876.png",
    "dna": "c897c7449ef7e2f773814e096b68d488cfc843ec",
    "edition": 876,
    "date": 1672533663740,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "876",
    "rarity_score": 229.1798591726128,
    "rarity_level": "COMMON"
  },
  "1501": {
    "name": "Arkade 90s Bulls #1501",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1501.png",
    "dna": "1856c5a88b8e1261ca5429904a6180d2c5c8d325",
    "edition": 1501,
    "date": 1672533664486,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1501",
    "rarity_score": 195.83464591201107,
    "rarity_level": "COMMON"
  },
  "445": {
    "name": "Arkade 90s Bulls #445",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/445.png",
    "dna": "48ca3fedbcdcba49cd25b4a5ba64356c07af22be",
    "edition": 445,
    "date": 1672533665213,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "445",
    "rarity_score": 208.45899765924523,
    "rarity_level": "COMMON"
  },
  "1457": {
    "name": "Arkade 90s Bulls #1457",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1457.png",
    "dna": "86de9060e1cb48e4b52c13f40a797e4d3abc5763",
    "edition": 1457,
    "date": 1672533665687,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1457",
    "rarity_score": 202.66911438657638,
    "rarity_level": "COMMON"
  },
  "1148": {
    "name": "Arkade 90s Bulls #1148",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1148.png",
    "dna": "47863e5eb701e1852dea392328d09622ff71953b",
    "edition": 1148,
    "date": 1672533666402,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1148",
    "rarity_score": 177.08114662270015,
    "rarity_level": "COMMON"
  },
  "1576": {
    "name": "Arkade 90s Bulls #1576",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1576.png",
    "dna": "466eb5c87635c67ac14fcacdcf2177ac87203ee9",
    "edition": 1576,
    "date": 1672533666882,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1576",
    "rarity_score": 339.775944411118,
    "rarity_level": "COMMON"
  },
  "616": {
    "name": "Arkade 90s Bulls #616",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/616.png",
    "dna": "b4fbfdb074373cb6128a9fe37b1f46199893510d",
    "edition": 616,
    "date": 1672533667345,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Dragon Fighter",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "616",
    "rarity_score": 194.67131923392023,
    "rarity_level": "COMMON"
  },
  "1153": {
    "name": "Arkade 90s Bulls #1153",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1153.png",
    "dna": "82093908ac843e45a019e1fb3ff908d9075846bb",
    "edition": 1153,
    "date": 1672533668402,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Tall Fro",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1153",
    "rarity_score": 425.451643075902,
    "rarity_level": "COMMON"
  },
  "416": {
    "name": "Arkade 90s Bulls #416",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/416.png",
    "dna": "b11cb6b48d77812608be2827cf5343f51fbd1415",
    "edition": 416,
    "date": 1672533669105,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "416",
    "rarity_score": 120.47801226411352,
    "rarity_level": "COMMON"
  },
  "1434": {
    "name": "Arkade 90s Bulls #1434",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1434.png",
    "dna": "3b4a99df04cc9ee0a85691221022d86c862390d0",
    "edition": 1434,
    "date": 1672533669580,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1434",
    "rarity_score": 133.3022540999073,
    "rarity_level": "COMMON"
  },
  "1695": {
    "name": "Arkade 90s Bulls #1695",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1695.png",
    "dna": "d955ee4c6123ae3a7d10ed646ad84955ddde85af",
    "edition": 1695,
    "date": 1672533670114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1695",
    "rarity_score": 262.4065620586487,
    "rarity_level": "COMMON"
  },
  "1339": {
    "name": "Arkade 90s Bulls #1339",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1339.png",
    "dna": "f1ee321d7bf38e01f50c76b0304f6e75286a3b7f",
    "edition": 1339,
    "date": 1672533670659,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Plaid",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1339",
    "rarity_score": 233.293283085503,
    "rarity_level": "COMMON"
  },
  "603": {
    "name": "Arkade 90s Bulls #603",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/603.png",
    "dna": "6f827a0e406c2f5fd84f8dd5afdbf282f53faa3d",
    "edition": 603,
    "date": 1672533671141,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "603",
    "rarity_score": 252.8922039545828,
    "rarity_level": "COMMON"
  },
  "117": {
    "name": "Arkade 90s Bulls #117",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/117.png",
    "dna": "12956de111765cda605aa719f77df73de05f18a6",
    "edition": 117,
    "date": 1672533672567,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "117",
    "rarity_score": 422.5673234459355,
    "rarity_level": "COMMON"
  },
  "414": {
    "name": "Arkade 90s Bulls #414",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/414.png",
    "dna": "16e951c6a0355e59327a4cd2148251ca23cdd3cc",
    "edition": 414,
    "date": 1672533673068,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Down Red Eyes",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "414",
    "rarity_score": 289.0305493983894,
    "rarity_level": "COMMON"
  },
  "69": {
    "name": "Arkade 90s Bulls #69",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/69.png",
    "dna": "f801bed44fe94fb54933c284c471e545590d561a",
    "edition": 69,
    "date": 1672533673577,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "69",
    "rarity_score": 313.01062366616793,
    "rarity_level": "COMMON"
  },
  "264": {
    "name": "Arkade 90s Bulls #264",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/264.png",
    "dna": "e5bd66663981605c88cf289676b4c0479d72d5e7",
    "edition": 264,
    "date": 1672533674077,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "264",
    "rarity_score": 476.63814469170194,
    "rarity_level": "RARE"
  },
  "794": {
    "name": "Arkade 90s Bulls #794",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/794.png",
    "dna": "4385721a27d9f9b5fc87b091526e86125d22c3bc",
    "edition": 794,
    "date": 1672533674585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "794",
    "rarity_score": 243.31741746378813,
    "rarity_level": "COMMON"
  },
  "1733": {
    "name": "Arkade 90s Bulls #1733",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1733.png",
    "dna": "c9b86f3191badc61de504b7c762b8389a56688a2",
    "edition": 1733,
    "date": 1672533675624,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Up Blue Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Superbull Hair", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1733",
    "rarity_score": 676.9584361130583,
    "rarity_level": "RARE"
  },
  "484": {
    "name": "Arkade 90s Bulls #484",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/484.png",
    "dna": "77d753a6858953e8ed3aad6e75ae91d221c42c49",
    "edition": 484,
    "date": 1672533676200,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Brown Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "484",
    "rarity_score": 459.2893947680357,
    "rarity_level": "COMMON"
  },
  "99": {
    "name": "Arkade 90s Bulls #99",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/99.png",
    "dna": "b5f38d3ac6920b997a4563d21eb59303e9af8353",
    "edition": 99,
    "date": 1672533676951,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "99",
    "rarity_score": 196.4839715053254,
    "rarity_level": "COMMON"
  },
  "355": {
    "name": "Arkade 90s Bulls #355",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/355.png",
    "dna": "b2c3631d1a5998cda2bd7fe9584cf97025ec07b2",
    "edition": 355,
    "date": 1672533678078,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Wooden Cowboy Hat",
        "score": 248.75
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "355",
    "rarity_score": 351.7873762408849,
    "rarity_level": "COMMON"
  },
  "1678": {
    "name": "Arkade 90s Bulls #1678",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1678.png",
    "dna": "e83f9d989114ce10eb017d86105c095a1a43dc3f",
    "edition": 1678,
    "date": 1672533678585,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1678",
    "rarity_score": 419.95949436773117,
    "rarity_level": "COMMON"
  },
  "1327": {
    "name": "Arkade 90s Bulls #1327",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1327.png",
    "dna": "ab1fb1880a845434313f55fbf4f5d97825c3b7ec",
    "edition": 1327,
    "date": 1672533679109,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1327",
    "rarity_score": 247.61193040914122,
    "rarity_level": "COMMON"
  },
  "969": {
    "name": "Arkade 90s Bulls #969",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/969.png",
    "dna": "051334fe489556c8851aeef9ffa2aec6712d7280",
    "edition": 969,
    "date": 1672533680337,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "North Park Beanie",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "969",
    "rarity_score": 232.38491361553432,
    "rarity_level": "COMMON"
  },
  "1646": {
    "name": "Arkade 90s Bulls #1646",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1646.png",
    "dna": "0fad21afab0fae0acf3e6606c44a6bb5f4a06edb",
    "edition": 1646,
    "date": 1672533680861,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1646",
    "rarity_score": 145.60783619778312,
    "rarity_level": "COMMON"
  },
  "963": {
    "name": "Arkade 90s Bulls #963",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/963.png",
    "dna": "3659ee198b8487ad4bdf7e28f434b4e05ad16907",
    "edition": 963,
    "date": 1672533682047,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "963",
    "rarity_score": 123.44807775942299,
    "rarity_level": "COMMON"
  },
  "306": {
    "name": "Arkade 90s Bulls #306",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/306.png",
    "dna": "7805f7a365cae47986c11a4939e9b8b5045dcf61",
    "edition": 306,
    "date": 1672533682747,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "306",
    "rarity_score": 308.9612741694367,
    "rarity_level": "COMMON"
  },
  "1253": {
    "name": "Arkade 90s Bulls #1253",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1253.png",
    "dna": "e423deafe9101d19b61ceab8dda330cb6084f7bd",
    "edition": 1253,
    "date": 1672533683253,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1253",
    "rarity_score": 200.13903498345422,
    "rarity_level": "COMMON"
  },
  "819": {
    "name": "Arkade 90s Bulls #819",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/819.png",
    "dna": "90c6d7e4f45bc195e15cc532f46673f0caf8c14c",
    "edition": 819,
    "date": 1672533683747,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "819",
    "rarity_score": 241.13747354801737,
    "rarity_level": "COMMON"
  },
  "345": {
    "name": "Arkade 90s Bulls #345",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/345.png",
    "dna": "d64710b620d7300ae864d95e687843ad51cf4f63",
    "edition": 345,
    "date": 1672533684289,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Kaddex Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "345",
    "rarity_score": 335.57964168861184,
    "rarity_level": "COMMON"
  },
  "662": {
    "name": "Arkade 90s Bulls #662",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/662.png",
    "dna": "13982611776be8c520e6719716be8b5696c28bd0",
    "edition": 662,
    "date": 1672533684779,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "662",
    "rarity_score": 206.21634675601646,
    "rarity_level": "COMMON"
  },
  "1011": {
    "name": "Arkade 90s Bulls #1011",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1011.png",
    "dna": "4a286c1c9786c22ccde1367edcd39ffb83efd08b",
    "edition": 1011,
    "date": 1672533686031,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1011",
    "rarity_score": 320.2187844223417,
    "rarity_level": "COMMON"
  },
  "745": {
    "name": "Arkade 90s Bulls #745",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/745.png",
    "dna": "20541f4cb65b6f4388fd3ca7f2f3fd829c7c295f",
    "edition": 745,
    "date": 1672533686606,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jokester",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "745",
    "rarity_score": 215.79117497591514,
    "rarity_level": "COMMON"
  },
  "145": {
    "name": "Arkade 90s Bulls #145",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/145.png",
    "dna": "c3f29bc1b65a46c7508f86e13d094545a0475dff",
    "edition": 145,
    "date": 1672533687064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "145",
    "rarity_score": 135.44647998863277,
    "rarity_level": "COMMON"
  },
  "968": {
    "name": "Arkade 90s Bulls #968",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/968.png",
    "dna": "59caad29afaa6c3ba47484c58162941df9ea5e3e",
    "edition": 968,
    "date": 1672533687529,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Orange Not Smart Suit",
        "score": 248.75
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "968",
    "rarity_score": 406.5307740206613,
    "rarity_level": "COMMON"
  },
  "546": {
    "name": "Arkade 90s Bulls #546",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/546.png",
    "dna": "40ef18a57eeb973619c66b9cd15c32764c084aa7",
    "edition": 546,
    "date": 1672533688139,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "546",
    "rarity_score": 211.67479749697745,
    "rarity_level": "COMMON"
  },
  "1055": {
    "name": "Arkade 90s Bulls #1055",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1055.png",
    "dna": "00782d39e7ee06a4f2e1bccc6ab3c0792cebc812",
    "edition": 1055,
    "date": 1672533689194,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1055",
    "rarity_score": 297.2927652992783,
    "rarity_level": "COMMON"
  },
  "1466": {
    "name": "Arkade 90s Bulls #1466",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1466.png",
    "dna": "38d846cfee4ea6935fec15ba8490d8122fa43dc2",
    "edition": 1466,
    "date": 1672533689692,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1466",
    "rarity_score": 214.9599367516344,
    "rarity_level": "COMMON"
  },
  "1803": {
    "name": "Arkade 90s Bulls #1803",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1803.png",
    "dna": "81bc5f2c911769e303a224648c588ab5451c978f",
    "edition": 1803,
    "date": 1672533690751,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      { "trait_type": "Head", "value": "Thriller Hair", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1803",
    "rarity_score": 234.71559822914932,
    "rarity_level": "COMMON"
  },
  "988": {
    "name": "Arkade 90s Bulls #988",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/988.png",
    "dna": "3b04a31fd7955c4378dc933176417336b2bf9261",
    "edition": 988,
    "date": 1672533691315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "988",
    "rarity_score": 494.23580234646363,
    "rarity_level": "RARE"
  },
  "1958": {
    "name": "Arkade 90s Bulls #1958",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1958.png",
    "dna": "b66ddbbcc94b50a993b05dce7889b1a09bfeff6b",
    "edition": 1958,
    "date": 1672533692047,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1958",
    "rarity_score": 282.175858590765,
    "rarity_level": "COMMON"
  },
  "168": {
    "name": "Arkade 90s Bulls #168",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/168.png",
    "dna": "527bb03b1d66e95e5f56f2cf31056e55303aac58",
    "edition": 168,
    "date": 1672533692522,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "168",
    "rarity_score": 212.17093591978113,
    "rarity_level": "COMMON"
  },
  "460": {
    "name": "Arkade 90s Bulls #460",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/460.png",
    "dna": "b81c98a29f589662e58399f12134bb2cc5ebcef3",
    "edition": 460,
    "date": 1672533693015,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Moon Walker",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "460",
    "rarity_score": 280.7220043547516,
    "rarity_level": "COMMON"
  },
  "853": {
    "name": "Arkade 90s Bulls #853",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/853.png",
    "dna": "b2f8d2604500ebbf636f5e4eb8a29ff145ea1bf2",
    "edition": 853,
    "date": 1672533693598,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Plaid Cube",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "853",
    "rarity_score": 252.99199837848215,
    "rarity_level": "COMMON"
  },
  "539": {
    "name": "Arkade 90s Bulls #539",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/539.png",
    "dna": "512d7d01bac31b633f3dbcf32dc884f154fb7542",
    "edition": 539,
    "date": 1672533694165,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "539",
    "rarity_score": 227.05582531641062,
    "rarity_level": "COMMON"
  },
  "1393": {
    "name": "Arkade 90s Bulls #1393",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1393.png",
    "dna": "597e31fc1b01f33060fa8668c4762f59614a99e6",
    "edition": 1393,
    "date": 1672533694708,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1393",
    "rarity_score": 203.30939983804382,
    "rarity_level": "COMMON"
  },
  "861": {
    "name": "Arkade 90s Bulls #861",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/861.png",
    "dna": "392ebdb76cd5a231bb5874f9c247a342a530a7ba",
    "edition": 861,
    "date": 1672533695190,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "861",
    "rarity_score": 180.71975513457917,
    "rarity_level": "COMMON"
  },
  "1962": {
    "name": "Arkade 90s Bulls #1962",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1962.png",
    "dna": "9a96596b855079a93879ce0f8aea17cc37688b0b",
    "edition": 1962,
    "date": 1672533695677,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1962",
    "rarity_score": 122.35499702983655,
    "rarity_level": "COMMON"
  },
  "1719": {
    "name": "Arkade 90s Bulls #1719",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1719.png",
    "dna": "4c6d018519599b1eb9d3468ebfe9c119a26bf743",
    "edition": 1719,
    "date": 1672533696196,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1719",
    "rarity_score": 279.514199854392,
    "rarity_level": "COMMON"
  },
  "275": {
    "name": "Arkade 90s Bulls #275",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/275.png",
    "dna": "84273a9d9cfe85de9898ecf15c69353cd46a7ac0",
    "edition": 275,
    "date": 1672533697264,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "275",
    "rarity_score": 194.30746247382027,
    "rarity_level": "COMMON"
  },
  "811": {
    "name": "Arkade 90s Bulls #811",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/811.png",
    "dna": "21c24a1350c07002da39017c1440178bb018251e",
    "edition": 811,
    "date": 1672533698422,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "811",
    "rarity_score": 200.61076689784005,
    "rarity_level": "COMMON"
  },
  "1781": {
    "name": "Arkade 90s Bulls #1781",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1781.png",
    "dna": "ee46d0c62ae217c9042cfc2748fe389750d1ca1b",
    "edition": 1781,
    "date": 1672533698917,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1781",
    "rarity_score": 403.20633323863416,
    "rarity_level": "COMMON"
  },
  "1467": {
    "name": "Arkade 90s Bulls #1467",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1467.png",
    "dna": "10e87f253b0e4d7c1c4fc1f0bb63090f19d16ee7",
    "edition": 1467,
    "date": 1672533699635,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1467",
    "rarity_score": 254.8320378567262,
    "rarity_level": "COMMON"
  },
  "600": {
    "name": "Arkade 90s Bulls #600",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/600.png",
    "dna": "1095bf6e357de56fc8830d3362d4bd9f4e721e81",
    "edition": 600,
    "date": 1672533700114,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Luiki Hat", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "600",
    "rarity_score": 522.449515745325,
    "rarity_level": "RARE"
  },
  "1829": {
    "name": "Arkade 90s Bulls #1829",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1829.png",
    "dna": "f21dc2202cdff9bfea6c9296c1bd4a8433748c33",
    "edition": 1829,
    "date": 1672533700632,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1829",
    "rarity_score": 215.5112027661558,
    "rarity_level": "COMMON"
  },
  "112": {
    "name": "Arkade 90s Bulls #112",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/112.png",
    "dna": "262034324660a94e3d970ef7bcc0c2be1795ed93",
    "edition": 112,
    "date": 1672533701154,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "112",
    "rarity_score": 178.42849850594413,
    "rarity_level": "COMMON"
  },
  "1288": {
    "name": "Arkade 90s Bulls #1288",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1288.png",
    "dna": "25263d1e40411f5003d60f1c4a16191c86f1cff3",
    "edition": 1288,
    "date": 1672533701635,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1288",
    "rarity_score": 214.0931311327226,
    "rarity_level": "COMMON"
  },
  "1810": {
    "name": "Arkade 90s Bulls #1810",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1810.png",
    "dna": "21994809b8938b0e8a8a5216414fdb3ba51fce52",
    "edition": 1810,
    "date": 1672533702669,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1810",
    "rarity_score": 244.43818227508896,
    "rarity_level": "COMMON"
  },
  "443": {
    "name": "Arkade 90s Bulls #443",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/443.png",
    "dna": "25b4c8a88866aa79413fe1cc4662a35c5dc8ea65",
    "edition": 443,
    "date": 1672533703148,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "443",
    "rarity_score": 463.4717900933572,
    "rarity_level": "RARE"
  },
  "184": {
    "name": "Arkade 90s Bulls #184",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/184.png",
    "dna": "cc344d3b605c4c24073158662e564c0604bdd97f",
    "edition": 184,
    "date": 1672533703621,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "184",
    "rarity_score": 134.4354820466986,
    "rarity_level": "COMMON"
  },
  "1874": {
    "name": "Arkade 90s Bulls #1874",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1874.png",
    "dna": "f4b31a54de10f86cdb5881a84f5b2b53198bd6e5",
    "edition": 1874,
    "date": 1672533704135,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1874",
    "rarity_score": 110.37266078821641,
    "rarity_level": "COMMON"
  },
  "1295": {
    "name": "Arkade 90s Bulls #1295",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1295.png",
    "dna": "7608ac82948026708f986cefeb8f23a03612d4a9",
    "edition": 1295,
    "date": 1672533704634,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1295",
    "rarity_score": 138.15868384201542,
    "rarity_level": "COMMON"
  },
  "298": {
    "name": "Arkade 90s Bulls #298",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/298.png",
    "dna": "af5b38b600a89ccc50197fc67a1e01b548849c03",
    "edition": 298,
    "date": 1672533705182,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Gold Chain",
        "score": 497.5
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "298",
    "rarity_score": 795.1589411583868,
    "rarity_level": "RARE"
  },
  "679": {
    "name": "Arkade 90s Bulls #679",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/679.png",
    "dna": "1495903c1a114da06419abbba0e7459ab577fdb1",
    "edition": 679,
    "date": 1672533705669,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "679",
    "rarity_score": 121.17080819282141,
    "rarity_level": "COMMON"
  },
  "358": {
    "name": "Arkade 90s Bulls #358",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/358.png",
    "dna": "3f3ffc25d611a6baa05dfd3c52d92abb7ccc9ff2",
    "edition": 358,
    "date": 1672533706204,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "358",
    "rarity_score": 330.77802934490484,
    "rarity_level": "COMMON"
  },
  "1565": {
    "name": "Arkade 90s Bulls #1565",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1565.png",
    "dna": "3cd6d8736f968d9204f502543779f60951b91104",
    "edition": 1565,
    "date": 1672533707433,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1565",
    "rarity_score": 220.5174669226437,
    "rarity_level": "COMMON"
  },
  "1552": {
    "name": "Arkade 90s Bulls #1552",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1552.png",
    "dna": "b007d8cb2e737ec809ea50ab4c4a74267e92ecc0",
    "edition": 1552,
    "date": 1672533707921,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1552",
    "rarity_score": 152.63755137601825,
    "rarity_level": "COMMON"
  },
  "155": {
    "name": "Arkade 90s Bulls #155",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/155.png",
    "dna": "1d89dd3694758223db53b8cef345eff99f4687ec",
    "edition": 155,
    "date": 1672533708419,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "155",
    "rarity_score": 96.30099085853747,
    "rarity_level": "COMMON"
  },
  "694": {
    "name": "Arkade 90s Bulls #694",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/694.png",
    "dna": "42a55104934c2c9f6e49e1b7d3418b0131072cc4",
    "edition": 694,
    "date": 1672533708903,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Hoodie",
        "score": 58.529411764705884
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "694",
    "rarity_score": 215.8363661648486,
    "rarity_level": "COMMON"
  },
  "1906": {
    "name": "Arkade 90s Bulls #1906",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1906.png",
    "dna": "7445c16cf17169208b68b5fafafd08bd94b109a6",
    "edition": 1906,
    "date": 1672533709371,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1906",
    "rarity_score": 274.0005284667333,
    "rarity_level": "COMMON"
  },
  "622": {
    "name": "Arkade 90s Bulls #622",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/622.png",
    "dna": "1beb492613e73b6bf04aad4c63c7baa004423059",
    "edition": 622,
    "date": 1672533709894,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "622",
    "rarity_score": 349.96740926017077,
    "rarity_level": "COMMON"
  },
  "1977": {
    "name": "Arkade 90s Bulls #1977",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1977.png",
    "dna": "8fc74f3363207e3f7bba0c7562f3402cfc7b4d91",
    "edition": 1977,
    "date": 1672533710348,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Turbo Bull",
        "score": 117.05882352941177
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1977",
    "rarity_score": 307.3451077822029,
    "rarity_level": "COMMON"
  },
  "1769": {
    "name": "Arkade 90s Bulls #1769",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1769.png",
    "dna": "63eafe28e8daeb7f0f5d9b0b6eaafac5182f78b8",
    "edition": 1769,
    "date": 1672533710842,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1769",
    "rarity_score": 235.5831903333178,
    "rarity_level": "COMMON"
  },
  "425": {
    "name": "Arkade 90s Bulls #425",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/425.png",
    "dna": "3c3ca5d1dabe99a60bd31a0ef6a15f0f9de5b30f",
    "edition": 425,
    "date": 1672533711318,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "425",
    "rarity_score": 327.27300963944276,
    "rarity_level": "COMMON"
  },
  "1685": {
    "name": "Arkade 90s Bulls #1685",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1685.png",
    "dna": "9ef80648d28d686a9e90b9a56998f8493450eae7",
    "edition": 1685,
    "date": 1672533711840,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1685",
    "rarity_score": 266.92582215239634,
    "rarity_level": "COMMON"
  },
  "1667": {
    "name": "Arkade 90s Bulls #1667",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1667.png",
    "dna": "acbd167c8f8bcf76e20a5812646f04d4327a1999",
    "edition": 1667,
    "date": 1672533712365,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1667",
    "rarity_score": 173.32402052297826,
    "rarity_level": "COMMON"
  },
  "1945": {
    "name": "Arkade 90s Bulls #1945",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1945.png",
    "dna": "7176501870e1755bf7360e8c09c576d0d461b7ab",
    "edition": 1945,
    "date": 1672533713109,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1945",
    "rarity_score": 166.33961931535785,
    "rarity_level": "COMMON"
  },
  "204": {
    "name": "Arkade 90s Bulls #204",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/204.png",
    "dna": "40da1f9b8b48dbee7f4f57a8a4143487db93768a",
    "edition": 204,
    "date": 1672533713619,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "204",
    "rarity_score": 201.73878022628776,
    "rarity_level": "COMMON"
  },
  "221": {
    "name": "Arkade 90s Bulls #221",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/221.png",
    "dna": "d8b9e7e88eb130ee3d0164ec9a33434b5bb38f8f",
    "edition": 221,
    "date": 1672533714097,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "221",
    "rarity_score": 310.24439149149714,
    "rarity_level": "COMMON"
  },
  "50": {
    "name": "Arkade 90s Bulls #50",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/50.png",
    "dna": "9c19ff948b35a2a3b7a340d9b423305f478a115c",
    "edition": 50,
    "date": 1672533714571,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "50",
    "rarity_score": 190.75703583633359,
    "rarity_level": "COMMON"
  },
  "958": {
    "name": "Arkade 90s Bulls #958",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/958.png",
    "dna": "637c97d411260462a9789627216d35772883e76c",
    "edition": 958,
    "date": 1672533715912,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "958",
    "rarity_score": 272.79608023658494,
    "rarity_level": "COMMON"
  },
  "1986": {
    "name": "Arkade 90s Bulls #1986",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1986.png",
    "dna": "ce4204ec0023c3defa57b202143707fb3b79b1ab",
    "edition": 1986,
    "date": 1672533716420,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1986",
    "rarity_score": 349.77856271442346,
    "rarity_level": "COMMON"
  },
  "1675": {
    "name": "Arkade 90s Bulls #1675",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1675.png",
    "dna": "50c9b16441034de336ed7c3880662baf3eaadf3c",
    "edition": 1675,
    "date": 1672533716906,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1675",
    "rarity_score": 119.94749461360384,
    "rarity_level": "COMMON"
  },
  "1557": {
    "name": "Arkade 90s Bulls #1557",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1557.png",
    "dna": "1b389dc5ee2dac884f8fe9b0e163e1ba3c24c740",
    "edition": 1557,
    "date": 1672533717366,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Luiki Hat", "score": 398.0 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1557",
    "rarity_score": 496.6438684605385,
    "rarity_level": "RARE"
  },
  "1443": {
    "name": "Arkade 90s Bulls #1443",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1443.png",
    "dna": "2a74787f4722155f82ec0cca4c1cdf87f8d7ef38",
    "edition": 1443,
    "date": 1672533717999,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1443",
    "rarity_score": 271.26434157903066,
    "rarity_level": "COMMON"
  },
  "1865": {
    "name": "Arkade 90s Bulls #1865",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1865.png",
    "dna": "e72eda0515ef2bf429e95698052c9b2b2f567e66",
    "edition": 1865,
    "date": 1672533718529,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1865",
    "rarity_score": 488.19532601752826,
    "rarity_level": "RARE"
  },
  "1335": {
    "name": "Arkade 90s Bulls #1335",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1335.png",
    "dna": "4c6149a3eb531a8b329c787115e23e4e09ba589d",
    "edition": 1335,
    "date": 1672533719006,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Spinner", "score": 398.0 },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1335",
    "rarity_score": 506.58066424702207,
    "rarity_level": "RARE"
  },
  "1739": {
    "name": "Arkade 90s Bulls #1739",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1739.png",
    "dna": "4cfdf09d9528c9ab9a5b778f3b4fb2da9fe3f980",
    "edition": 1739,
    "date": 1672533719506,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1739",
    "rarity_score": 169.39920414840955,
    "rarity_level": "COMMON"
  },
  "475": {
    "name": "Arkade 90s Bulls #475",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/475.png",
    "dna": "3e5e0a02b461bcb85b1589ad0ff9483686a045e4",
    "edition": 475,
    "date": 1672533720214,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "475",
    "rarity_score": 490.73215545179914,
    "rarity_level": "RARE"
  },
  "1954": {
    "name": "Arkade 90s Bulls #1954",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1954.png",
    "dna": "bc202e040e148e0111332e5fa053c127a6cf0ef1",
    "edition": 1954,
    "date": 1672533720771,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1954",
    "rarity_score": 184.84994256314988,
    "rarity_level": "COMMON"
  },
  "926": {
    "name": "Arkade 90s Bulls #926",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/926.png",
    "dna": "3c4f1401e2c79ba24b0223ab098517850641c8f8",
    "edition": 926,
    "date": 1672533721246,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "926",
    "rarity_score": 339.4309840580834,
    "rarity_level": "COMMON"
  },
  "530": {
    "name": "Arkade 90s Bulls #530",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/530.png",
    "dna": "8da2a31eed20d9d3b662389ada8b6afe55de5490",
    "edition": 530,
    "date": 1672533721778,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Yellow Guardian Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "530",
    "rarity_score": 271.6113884484521,
    "rarity_level": "COMMON"
  },
  "1494": {
    "name": "Arkade 90s Bulls #1494",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1494.png",
    "dna": "50144ebe2f0a2a6559f337bbe1969b2c5c7f7b14",
    "edition": 1494,
    "date": 1672533722247,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Rocket Hair", "score": 248.75 },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1494",
    "rarity_score": 441.9420284219453,
    "rarity_level": "COMMON"
  },
  "422": {
    "name": "Arkade 90s Bulls #422",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/422.png",
    "dna": "065c3d8201aba0e581b4ee48769b3c5067a9c6a4",
    "edition": 422,
    "date": 1672533723009,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "422",
    "rarity_score": 323.072014176667,
    "rarity_level": "COMMON"
  },
  "514": {
    "name": "Arkade 90s Bulls #514",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/514.png",
    "dna": "319141c63e63ebac7cfed82c7fc2b196ca168431",
    "edition": 514,
    "date": 1672533723483,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "514",
    "rarity_score": 175.50989312594223,
    "rarity_level": "COMMON"
  },
  "972": {
    "name": "Arkade 90s Bulls #972",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/972.png",
    "dna": "63a61f76d16ed0a11bd58afea0dac51b7687acfc",
    "edition": 972,
    "date": 1672533723978,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "972",
    "rarity_score": 255.97558595791688,
    "rarity_level": "COMMON"
  },
  "1069": {
    "name": "Arkade 90s Bulls #1069",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1069.png",
    "dna": "9e0b7f6e53f71ad7fd1c091e4acb2037d982a651",
    "edition": 1069,
    "date": 1672533724517,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1069",
    "rarity_score": 217.7938091486372,
    "rarity_level": "COMMON"
  },
  "294": {
    "name": "Arkade 90s Bulls #294",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/294.png",
    "dna": "c1fb49f18616092717952cc70584e78afe3f3f48",
    "edition": 294,
    "date": 1672533725673,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "294",
    "rarity_score": 144.88828179228858,
    "rarity_level": "COMMON"
  },
  "579": {
    "name": "Arkade 90s Bulls #579",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/579.png",
    "dna": "0e3b3cccb7b9007a7d6a6c8f2c606f3c40234aaa",
    "edition": 579,
    "date": 1672533726121,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Up Brown Eyes",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "579",
    "rarity_score": 212.80126817413475,
    "rarity_level": "COMMON"
  },
  "325": {
    "name": "Arkade 90s Bulls #325",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/325.png",
    "dna": "1d255c8bea7908aa779bb0f18fe92043764aea8f",
    "edition": 325,
    "date": 1672533726601,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "325",
    "rarity_score": 244.53138094754615,
    "rarity_level": "COMMON"
  },
  "1796": {
    "name": "Arkade 90s Bulls #1796",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1796.png",
    "dna": "abc0104f7960ec43a8d4e14c18c96ba57b7f3816",
    "edition": 1796,
    "date": 1672533727746,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1796",
    "rarity_score": 247.06642371419278,
    "rarity_level": "COMMON"
  },
  "1383": {
    "name": "Arkade 90s Bulls #1383",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1383.png",
    "dna": "a5e3aa7fa7fc4a3b4905a4d09b9b1a46a7ee91ae",
    "edition": 1383,
    "date": 1672533728949,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1383",
    "rarity_score": 139.47986872216106,
    "rarity_level": "COMMON"
  },
  "998": {
    "name": "Arkade 90s Bulls #998",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/998.png",
    "dna": "f958ef0f5cab0f82aaebf55c8fe244dce95e9e7f",
    "edition": 998,
    "date": 1672533730164,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "998",
    "rarity_score": 241.60536299541207,
    "rarity_level": "COMMON"
  },
  "1592": {
    "name": "Arkade 90s Bulls #1592",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1592.png",
    "dna": "590a117b94f707b52cb9774c4f02f8e654d2b66b",
    "edition": 1592,
    "date": 1672533730648,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1592",
    "rarity_score": 165.94469792355574,
    "rarity_level": "COMMON"
  },
  "719": {
    "name": "Arkade 90s Bulls #719",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/719.png",
    "dna": "a6262a73ab2a57550646bd1b80dee0654fe012b6",
    "edition": 719,
    "date": 1672533731174,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "719",
    "rarity_score": 187.151504289756,
    "rarity_level": "COMMON"
  },
  "307": {
    "name": "Arkade 90s Bulls #307",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/307.png",
    "dna": "5879dadefe1d11e03951de78af9c6802644aefcb",
    "edition": 307,
    "date": 1672533731922,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "307",
    "rarity_score": 136.47558166054176,
    "rarity_level": "COMMON"
  },
  "37": {
    "name": "Arkade 90s Bulls #37",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/37.png",
    "dna": "5441a332b275c855ed3c8e371fcb07614c1bcb0e",
    "edition": 37,
    "date": 1672533732629,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "37",
    "rarity_score": 426.5745293382908,
    "rarity_level": "COMMON"
  },
  "199": {
    "name": "Arkade 90s Bulls #199",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/199.png",
    "dna": "a823f7b2654973346e30d0997ac7aa1cb4d71475",
    "edition": 199,
    "date": 1672533733103,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Party Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "199",
    "rarity_score": 436.1770587121335,
    "rarity_level": "COMMON"
  },
  "873": {
    "name": "Arkade 90s Bulls #873",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/873.png",
    "dna": "9364bbab9547a4735c7c991d42450bc99fe8c86e",
    "edition": 873,
    "date": 1672533733631,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Habibi Vest",
        "score": 124.375
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "873",
    "rarity_score": 226.02296538858417,
    "rarity_level": "COMMON"
  },
  "1113": {
    "name": "Arkade 90s Bulls #1113",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1113.png",
    "dna": "07d4bcfd6fc5a1920155438fa979242f4f4b1fc1",
    "edition": 1113,
    "date": 1672533734127,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1113",
    "rarity_score": 260.69369197172165,
    "rarity_level": "COMMON"
  },
  "828": {
    "name": "Arkade 90s Bulls #828",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/828.png",
    "dna": "ffadc0cb97e5de9cc836195585aa193a386ef00b",
    "edition": 828,
    "date": 1672533734610,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "828",
    "rarity_score": 475.41290357324544,
    "rarity_level": "RARE"
  },
  "153": {
    "name": "Arkade 90s Bulls #153",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/153.png",
    "dna": "8765a2b7fd0b8663df11b34b87cb0ee6b6042e49",
    "edition": 153,
    "date": 1672533735096,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "153",
    "rarity_score": 205.1451805872594,
    "rarity_level": "COMMON"
  },
  "476": {
    "name": "Arkade 90s Bulls #476",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/476.png",
    "dna": "e91af982ae04395a2a37073eece5269a12c6fd7c",
    "edition": 476,
    "date": 1672533735552,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "476",
    "rarity_score": 136.74814154579474,
    "rarity_level": "COMMON"
  },
  "1665": {
    "name": "Arkade 90s Bulls #1665",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1665.png",
    "dna": "9a2f1729e7e90abd156101ca71bb9d005ee048a3",
    "edition": 1665,
    "date": 1672533736043,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1665",
    "rarity_score": 213.4740230552177,
    "rarity_level": "COMMON"
  },
  "389": {
    "name": "Arkade 90s Bulls #389",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/389.png",
    "dna": "fdcbbc8fa5f737b0c85374d4b580471d1bcb5ab7",
    "edition": 389,
    "date": 1672533736551,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Abducted Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "389",
    "rarity_score": 270.8899967699673,
    "rarity_level": "COMMON"
  },
  "1425": {
    "name": "Arkade 90s Bulls #1425",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1425.png",
    "dna": "7e0505c6cab437f499935b16ee9cb7430e22356b",
    "edition": 1425,
    "date": 1672533737064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1425",
    "rarity_score": 481.71660841526534,
    "rarity_level": "RARE"
  },
  "1872": {
    "name": "Arkade 90s Bulls #1872",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1872.png",
    "dna": "357b93315d7e732f5e7bfae9ba5a04661b8ef4c7",
    "edition": 1872,
    "date": 1672533737795,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Down Blue Eyes",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1872",
    "rarity_score": 387.6226706140783,
    "rarity_level": "COMMON"
  },
  "282": {
    "name": "Arkade 90s Bulls #282",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/282.png",
    "dna": "b272c2ad4f935bdb53130c4fe52f3d4211df97e3",
    "edition": 282,
    "date": 1672533738558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "282",
    "rarity_score": 209.34093241935855,
    "rarity_level": "COMMON"
  },
  "1216": {
    "name": "Arkade 90s Bulls #1216",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1216.png",
    "dna": "fb05465fdfce3e421449f518e73a261fde1718e9",
    "edition": 1216,
    "date": 1672533739654,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1216",
    "rarity_score": 255.4545561783498,
    "rarity_level": "COMMON"
  },
  "305": {
    "name": "Arkade 90s Bulls #305",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/305.png",
    "dna": "b589db84c414aa7965e32c01c7b43d99aa0543f7",
    "edition": 305,
    "date": 1672533740155,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "305",
    "rarity_score": 275.37159756002876,
    "rarity_level": "COMMON"
  },
  "801": {
    "name": "Arkade 90s Bulls #801",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/801.png",
    "dna": "381c8ce2e51460b73e88abbbf717c3bf8b0f560a",
    "edition": 801,
    "date": 1672533740644,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "801",
    "rarity_score": 406.0414410270849,
    "rarity_level": "COMMON"
  },
  "1427": {
    "name": "Arkade 90s Bulls #1427",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1427.png",
    "dna": "5362ae712eaeb39bb57b6abe48aa31de20188c4e",
    "edition": 1427,
    "date": 1672533741163,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1427",
    "rarity_score": 138.81676818169447,
    "rarity_level": "COMMON"
  },
  "1067": {
    "name": "Arkade 90s Bulls #1067",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1067.png",
    "dna": "34e3050475f230779102cfcf9be4b2a616ebc0ef",
    "edition": 1067,
    "date": 1672533742060,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Superbull",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1067",
    "rarity_score": 395.56278901468306,
    "rarity_level": "COMMON"
  },
  "1577": {
    "name": "Arkade 90s Bulls #1577",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1577.png",
    "dna": "ab8f013e28a2514916307d2478b9fc13fd5f061b",
    "edition": 1577,
    "date": 1672533742616,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1577",
    "rarity_score": 278.11636954551125,
    "rarity_level": "COMMON"
  },
  "575": {
    "name": "Arkade 90s Bulls #575",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/575.png",
    "dna": "4dda9b119952b80a526febd35c36036584c8ebd4",
    "edition": 575,
    "date": 1672533743923,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "575",
    "rarity_score": 262.42477178268695,
    "rarity_level": "COMMON"
  },
  "79": {
    "name": "Arkade 90s Bulls #79",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/79.png",
    "dna": "fd6b17107a4cb6a9204677d6b8da83f0af3fd0e0",
    "edition": 79,
    "date": 1672533744411,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "79",
    "rarity_score": 238.1598928652883,
    "rarity_level": "COMMON"
  },
  "1514": {
    "name": "Arkade 90s Bulls #1514",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1514.png",
    "dna": "6f8151773badf46d7f99a9c25911ae5522ff6be1",
    "edition": 1514,
    "date": 1672533745253,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1514",
    "rarity_score": 356.2445966409358,
    "rarity_level": "COMMON"
  },
  "1710": {
    "name": "Arkade 90s Bulls #1710",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1710.png",
    "dna": "6590b5b581987a4c103ccd7787e2171018c28a46",
    "edition": 1710,
    "date": 1672533745734,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1710",
    "rarity_score": 172.66678803929224,
    "rarity_level": "COMMON"
  },
  "219": {
    "name": "Arkade 90s Bulls #219",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/219.png",
    "dna": "8b8f48cd543e813cacd8c421628cec7a7a0ca3bd",
    "edition": 219,
    "date": 1672533746932,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "219",
    "rarity_score": 209.06532888012745,
    "rarity_level": "COMMON"
  },
  "23": {
    "name": "Arkade 90s Bulls #23",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/23.png",
    "dna": "d497bc24a71db0e4c1b575d6644f524d2811fcc6",
    "edition": 23,
    "date": 1672533747992,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "23",
    "rarity_score": 212.35608332468516,
    "rarity_level": "COMMON"
  },
  "378": {
    "name": "Arkade 90s Bulls #378",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/378.png",
    "dna": "66c2db404efdccc44def09c3426ced9f8acdc106",
    "edition": 378,
    "date": 1672533748676,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "378",
    "rarity_score": 557.4941752013851,
    "rarity_level": "RARE"
  },
  "1389": {
    "name": "Arkade 90s Bulls #1389",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1389.png",
    "dna": "f2d72cf89f22ca9c9c405611684370d13c9602c8",
    "edition": 1389,
    "date": 1672533749903,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Adventurer",
        "score": 110.55555555555556
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1389",
    "rarity_score": 326.0351832824235,
    "rarity_level": "COMMON"
  },
  "703": {
    "name": "Arkade 90s Bulls #703",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/703.png",
    "dna": "c8f286ba6e01864d57ba0ce9e6892620850bb6ad",
    "edition": 703,
    "date": 1672533750664,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "703",
    "rarity_score": 220.19607835788656,
    "rarity_level": "COMMON"
  },
  "1766": {
    "name": "Arkade 90s Bulls #1766",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1766.png",
    "dna": "cf3ce31492fda5e6027d7cbb97b5de80466b1f51",
    "edition": 1766,
    "date": 1672533751212,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Toy Cowboy", "score": 124.375 },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1766",
    "rarity_score": 297.59544497977726,
    "rarity_level": "COMMON"
  },
  "1441": {
    "name": "Arkade 90s Bulls #1441",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1441.png",
    "dna": "5162fae983e152143bd875c2dff47fd9c6349615",
    "edition": 1441,
    "date": 1672533751707,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1441",
    "rarity_score": 147.63687443671253,
    "rarity_level": "COMMON"
  },
  "1654": {
    "name": "Arkade 90s Bulls #1654",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1654.png",
    "dna": "a895328788b1318f130c69639a706239ad7a1c84",
    "edition": 1654,
    "date": 1672533752423,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1654",
    "rarity_score": 262.71764787729995,
    "rarity_level": "COMMON"
  },
  "1160": {
    "name": "Arkade 90s Bulls #1160",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1160.png",
    "dna": "db5425a441eef3736539f3ab6988d2ec9bf26e39",
    "edition": 1160,
    "date": 1672533752915,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Red Guardian Suit",
        "score": 165.83333333333334
      },
      { "trait_type": "Head", "value": "Pickles Hair", "score": 995.0 },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1160",
    "rarity_score": 1257.4664383988948,
    "rarity_level": "EPIC"
  },
  "1314": {
    "name": "Arkade 90s Bulls #1314",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1314.png",
    "dna": "d079a2538ee11d31dad4556c21bea368010c2ba3",
    "edition": 1314,
    "date": 1672533753381,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1314",
    "rarity_score": 144.97102814959874,
    "rarity_level": "COMMON"
  },
  "1486": {
    "name": "Arkade 90s Bulls #1486",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1486.png",
    "dna": "efcea490df44dfaa13b601c94d92bbae63aae040",
    "edition": 1486,
    "date": 1672533754558,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1486",
    "rarity_score": 167.91189566042468,
    "rarity_level": "COMMON"
  },
  "1626": {
    "name": "Arkade 90s Bulls #1626",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1626.png",
    "dna": "a6c00668d5837c2cd8588a7cc7f73aaf4c737e86",
    "edition": 1626,
    "date": 1672533755053,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      {
        "trait_type": "Head",
        "value": "Skottie Hair",
        "score": 221.11111111111111
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1626",
    "rarity_score": 310.9925565516892,
    "rarity_level": "COMMON"
  },
  "158": {
    "name": "Arkade 90s Bulls #158",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/158.png",
    "dna": "f3efe93ec5acdf2dfaae0d12e649b8ded35275d3",
    "edition": 158,
    "date": 1672533755560,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      { "trait_type": "Head", "value": "Girl Mouse Ears", "score": 99.5 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "158",
    "rarity_score": 269.3947341769212,
    "rarity_level": "COMMON"
  },
  "1835": {
    "name": "Arkade 90s Bulls #1835",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1835.png",
    "dna": "8a93ee13150ccddbd5f45b705fe4861ce7cac95b",
    "edition": 1835,
    "date": 1672533756089,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1835",
    "rarity_score": 248.96871644079425,
    "rarity_level": "COMMON"
  },
  "528": {
    "name": "Arkade 90s Bulls #528",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/528.png",
    "dna": "16122426279b246e70c1396fbba92f2ba7be112a",
    "edition": 528,
    "date": 1672533756591,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kold",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "528",
    "rarity_score": 465.63909108625097,
    "rarity_level": "RARE"
  },
  "13": {
    "name": "Arkade 90s Bulls #13",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/13.png",
    "dna": "e5e033406ceba504ed8c625ff7f6becdaedd8b8a",
    "edition": 13,
    "date": 1672533757050,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "13",
    "rarity_score": 96.00490437318727,
    "rarity_level": "COMMON"
  },
  "1694": {
    "name": "Arkade 90s Bulls #1694",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1694.png",
    "dna": "d1d4c7da25495ab61a7fbf13493a2f5a1f769d12",
    "edition": 1694,
    "date": 1672533757510,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor Mouse",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1694",
    "rarity_score": 173.06981974885653,
    "rarity_level": "COMMON"
  },
  "1021": {
    "name": "Arkade 90s Bulls #1021",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1021.png",
    "dna": "b343d2f131f8f5a1f509b24e699446b9a910bc25",
    "edition": 1021,
    "date": 1672533757983,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Friday Hat",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1021",
    "rarity_score": 180.16952353449093,
    "rarity_level": "COMMON"
  },
  "945": {
    "name": "Arkade 90s Bulls #945",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/945.png",
    "dna": "c363177de7168ca8199156725291e8a35b8e1f67",
    "edition": 945,
    "date": 1672533758439,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Blonde Hair", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "945",
    "rarity_score": 221.99160023283676,
    "rarity_level": "COMMON"
  },
  "1966": {
    "name": "Arkade 90s Bulls #1966",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1966.png",
    "dna": "5bc84fac131b2bca8c0fb4eae7e7e4990fa7e83b",
    "edition": 1966,
    "date": 1672533758956,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Ten Piece",
        "score": 165.83333333333334
      },
      { "trait_type": "Head", "value": "KDSwap", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1966",
    "rarity_score": 363.2924300888209,
    "rarity_level": "COMMON"
  },
  "667": {
    "name": "Arkade 90s Bulls #667",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/667.png",
    "dna": "26cf78e1b3a2022f234cb800f66c911e219859d1",
    "edition": 667,
    "date": 1672533760131,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "667",
    "rarity_score": 160.5738273260563,
    "rarity_level": "COMMON"
  },
  "4": {
    "name": "Arkade 90s Bulls #4",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/4.png",
    "dna": "2840d0a70466aaec77b1a88d1cc35e6ccab04087",
    "edition": 4,
    "date": 1672533760671,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Letterman Jacket",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Head",
        "value": "Karth Hair",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "4",
    "rarity_score": 283.1424721643297,
    "rarity_level": "COMMON"
  },
  "337": {
    "name": "Arkade 90s Bulls #337",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/337.png",
    "dna": "f80984db908d5319bef4e22889ffceb3c17bafb4",
    "edition": 337,
    "date": 1672533761215,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Nerd",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "337",
    "rarity_score": 213.8261521152137,
    "rarity_level": "COMMON"
  },
  "941": {
    "name": "Arkade 90s Bulls #941",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/941.png",
    "dna": "8973200080ba8a26488d2d47801ae2d29a47f566",
    "edition": 941,
    "date": 1672533761856,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "941",
    "rarity_score": 174.4314911437809,
    "rarity_level": "COMMON"
  },
  "1826": {
    "name": "Arkade 90s Bulls #1826",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1826.png",
    "dna": "da8ef18c90f92d5fb077b7e34f299bdd100c2b31",
    "edition": 1826,
    "date": 1672533762339,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1826",
    "rarity_score": 237.71097804663836,
    "rarity_level": "COMMON"
  },
  "1982": {
    "name": "Arkade 90s Bulls #1982",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1982.png",
    "dna": "55721136989574ea8cd98715eaf9ddafe22af5fb",
    "edition": 1982,
    "date": 1672533762838,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1982",
    "rarity_score": 139.18360465329494,
    "rarity_level": "COMMON"
  },
  "1964": {
    "name": "Arkade 90s Bulls #1964",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1964.png",
    "dna": "4bc180eebc5b1f1b8c0adeb175ea924ded53de91",
    "edition": 1964,
    "date": 1672533763342,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Wallaby Tee",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Kyle Hat",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1964",
    "rarity_score": 468.05720932310254,
    "rarity_level": "RARE"
  },
  "676": {
    "name": "Arkade 90s Bulls #676",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/676.png",
    "dna": "4dcc53c7e6f18e0f5e575b64031b8a4086ff8f09",
    "edition": 676,
    "date": 1672533764376,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Moon Astronaut",
        "score": 79.6
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "676",
    "rarity_score": 202.963590771821,
    "rarity_level": "COMMON"
  },
  "419": {
    "name": "Arkade 90s Bulls #419",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/419.png",
    "dna": "0e8133be939afe644e2a28fa81492358bb0e975b",
    "edition": 419,
    "date": 1672533764885,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "419",
    "rarity_score": 257.4030148892527,
    "rarity_level": "COMMON"
  },
  "1294": {
    "name": "Arkade 90s Bulls #1294",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1294.png",
    "dna": "1fd24640971aa3e9f45c402f5ea6ae6c39f2ba18",
    "edition": 1294,
    "date": 1672533765415,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1294",
    "rarity_score": 204.6709437861127,
    "rarity_level": "COMMON"
  },
  "124": {
    "name": "Arkade 90s Bulls #124",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/124.png",
    "dna": "2b9cd4be8e966909892e6d1fa67ed14cbc2d7de9",
    "edition": 124,
    "date": 1672533765890,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Army",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "124",
    "rarity_score": 149.26260945252398,
    "rarity_level": "COMMON"
  },
  "296": {
    "name": "Arkade 90s Bulls #296",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/296.png",
    "dna": "128e85b8f29773b11e4af3327c09bd48b32d238b",
    "edition": 296,
    "date": 1672533767106,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "296",
    "rarity_score": 197.17299062228506,
    "rarity_level": "COMMON"
  },
  "1429": {
    "name": "Arkade 90s Bulls #1429",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1429.png",
    "dna": "6929d8ec0e0a49ee04a8c1bb3c986480faf53c5c",
    "edition": 1429,
    "date": 1672533767618,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Sword And Shield",
        "score": 24.26829268292683
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Orange Not Smart Hat",
        "score": 99.5
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1429",
    "rarity_score": 282.42455235863383,
    "rarity_level": "COMMON"
  },
  "1774": {
    "name": "Arkade 90s Bulls #1774",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1774.png",
    "dna": "509ae604ab317b56422d3c731ec70102bdd89cb0",
    "edition": 1774,
    "date": 1672533768113,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1774",
    "rarity_score": 155.38338200130755,
    "rarity_level": "COMMON"
  },
  "1472": {
    "name": "Arkade 90s Bulls #1472",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1472.png",
    "dna": "edeff22f67183cc20da00490da4881d76a1b13ab",
    "edition": 1472,
    "date": 1672533768606,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Sweater Vest",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Forrest Bulls Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1472",
    "rarity_score": 266.9760515237616,
    "rarity_level": "COMMON"
  },
  "1318": {
    "name": "Arkade 90s Bulls #1318",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1318.png",
    "dna": "277f3ffc31253ca811dfbd5b1325fa73dc2ebea1",
    "edition": 1318,
    "date": 1672533769124,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1318",
    "rarity_score": 185.78386397032227,
    "rarity_level": "COMMON"
  },
  "1902": {
    "name": "Arkade 90s Bulls #1902",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1902.png",
    "dna": "1107259f7c1dfc5c25982e3abf671cd29a4172de",
    "edition": 1902,
    "date": 1672533769600,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Beige Bull Tee",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1902",
    "rarity_score": 121.89229990341578,
    "rarity_level": "COMMON"
  },
  "741": {
    "name": "Arkade 90s Bulls #741",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/741.png",
    "dna": "18450c39d10c0bb91a99761cdef23f3cf654be24",
    "edition": 741,
    "date": 1672533770075,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      { "trait_type": "Outfit", "value": "Koala Wallet", "score": 79.6 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "741",
    "rarity_score": 234.1628458592905,
    "rarity_level": "COMMON"
  },
  "1447": {
    "name": "Arkade 90s Bulls #1447",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1447.png",
    "dna": "413f1af35fe14f773838a4163db1618aca1630c1",
    "edition": 1447,
    "date": 1672533770850,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1447",
    "rarity_score": 186.19414533629254,
    "rarity_level": "COMMON"
  },
  "1364": {
    "name": "Arkade 90s Bulls #1364",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1364.png",
    "dna": "4c2708f055ebad99a6d74912e762b8d830d49ec2",
    "edition": 1364,
    "date": 1672533771311,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Brown Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Space Ranger Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1364",
    "rarity_score": 276.6909427921754,
    "rarity_level": "COMMON"
  },
  "3": {
    "name": "Arkade 90s Bulls #3",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/3.png",
    "dna": "f43020632c1cf7760e841523a85fdc07b03c94d4",
    "edition": 3,
    "date": 1672533771781,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "3",
    "rarity_score": 103.61320993345014,
    "rarity_level": "COMMON"
  },
  "1938": {
    "name": "Arkade 90s Bulls #1938",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1938.png",
    "dna": "98e8c59135bc04a10dd725daccbaae8864fd86bf",
    "edition": 1938,
    "date": 1672533772732,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1938",
    "rarity_score": 163.45832536428728,
    "rarity_level": "COMMON"
  },
  "324": {
    "name": "Arkade 90s Bulls #324",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/324.png",
    "dna": "e80e7d5903afdbb958c226bd010f30fcbaa17ea8",
    "edition": 324,
    "date": 1672533773242,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Staff",
        "score": 30.615384615384617
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Block Bulls",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "324",
    "rarity_score": 315.4437676404587,
    "rarity_level": "COMMON"
  },
  "893": {
    "name": "Arkade 90s Bulls #893",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/893.png",
    "dna": "28ef127c4554b11e2a07db5a55df1a13d4103572",
    "edition": 893,
    "date": 1672533773806,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "893",
    "rarity_score": 263.33677394759474,
    "rarity_level": "COMMON"
  },
  "274": {
    "name": "Arkade 90s Bulls #274",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/274.png",
    "dna": "7eefca52f693c26d2c4fa81784db53ccc7f72a87",
    "edition": 274,
    "date": 1672533775315,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Tunic",
        "score": 663.3333333333334
      },
      {
        "trait_type": "Head",
        "value": "Green Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "274",
    "rarity_score": 946.3618030111058,
    "rarity_level": "RARE"
  },
  "871": {
    "name": "Arkade 90s Bulls #871",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/871.png",
    "dna": "7cbb9b0d6bb49f4f47aef0231370dd81e71369b2",
    "edition": 871,
    "date": 1672533775796,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Luiki",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Head",
        "value": "Kace Adventure Hair",
        "score": 331.6666666666667
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "871",
    "rarity_score": 733.7963084841999,
    "rarity_level": "RARE"
  },
  "601": {
    "name": "Arkade 90s Bulls #601",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/601.png",
    "dna": "2b904bf1b8cc2af5139b89a8f96754af28dc33c3",
    "edition": 601,
    "date": 1672533776270,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Quickster Suit",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Spinner Hat",
        "score": 104.73684210526316
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "601",
    "rarity_score": 246.22528204090676,
    "rarity_level": "COMMON"
  },
  "816": {
    "name": "Arkade 90s Bulls #816",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/816.png",
    "dna": "296ecca4706003c7551fe5186fdbb4ff55d438e9",
    "edition": 816,
    "date": 1672533776759,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Nunchucks",
        "score": 25.844155844155843
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "Captain Sustainable Hair",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "816",
    "rarity_score": 303.1902766133204,
    "rarity_level": "COMMON"
  },
  "449": {
    "name": "Arkade 90s Bulls #449",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/449.png",
    "dna": "76704558c4c9276775dac01b80636735aef07322",
    "edition": 449,
    "date": 1672533777252,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "449",
    "rarity_score": 323.0255061954829,
    "rarity_level": "COMMON"
  },
  "231": {
    "name": "Arkade 90s Bulls #231",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/231.png",
    "dna": "b38deb7e36ae8bb29b8210f8751ad6fb6a496cfb",
    "edition": 231,
    "date": 1672533777735,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "231",
    "rarity_score": 185.84777671616453,
    "rarity_level": "COMMON"
  },
  "455": {
    "name": "Arkade 90s Bulls #455",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/455.png",
    "dna": "be4c38a1ee245a32163fe9822e2db6d586c3947b",
    "edition": 455,
    "date": 1672533778220,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Green Eyes",
        "score": 38.26923076923077
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "455",
    "rarity_score": 188.15381809440368,
    "rarity_level": "COMMON"
  },
  "196": {
    "name": "Arkade 90s Bulls #196",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/196.png",
    "dna": "8068a067d3bfa76e4cf8b773d383a4e3b22c3958",
    "edition": 196,
    "date": 1672533779424,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "X Wallet",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Kaldo Hat",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "196",
    "rarity_score": 364.1904426847562,
    "rarity_level": "COMMON"
  },
  "1724": {
    "name": "Arkade 90s Bulls #1724",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1724.png",
    "dna": "0ce8dbee99fd549806c257895e2ed4605176af00",
    "edition": 1724,
    "date": 1672533779962,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Dad Bod",
        "score": 52.36842105263158
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Peace Glasses",
        "score": 42.340425531914896
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1724",
    "rarity_score": 221.4080141847617,
    "rarity_level": "COMMON"
  },
  "1064": {
    "name": "Arkade 90s Bulls #1064",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1064.png",
    "dna": "de618bab397080946ad38ef8af8a1bb01e1c778d",
    "edition": 1064,
    "date": 1672533780820,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Vanta Space Suit",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Mohawk",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Light Saber",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1064",
    "rarity_score": 468.75318106753076,
    "rarity_level": "RARE"
  },
  "1178": {
    "name": "Arkade 90s Bulls #1178",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1178.png",
    "dna": "e4463f5bae76f0654ee0dc246cbb6fcf8ae76ea8",
    "edition": 1178,
    "date": 1672533781381,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1178",
    "rarity_score": 138.2116621815198,
    "rarity_level": "COMMON"
  },
  "1651": {
    "name": "Arkade 90s Bulls #1651",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1651.png",
    "dna": "c941e2eadcc054d5b07be22084fd795f6b651509",
    "edition": 1651,
    "date": 1672533782533,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1651",
    "rarity_score": 209.12036865538823,
    "rarity_level": "COMMON"
  },
  "892": {
    "name": "Arkade 90s Bulls #892",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/892.png",
    "dna": "963aff8be4a25338d759588531c69acf49c06304",
    "edition": 892,
    "date": 1672533783015,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      { "trait_type": "Head", "value": "Ten Piece Hat", "score": 199.0 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "892",
    "rarity_score": 340.43463305040365,
    "rarity_level": "COMMON"
  },
  "1765": {
    "name": "Arkade 90s Bulls #1765",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1765.png",
    "dna": "0f2f38bf7e01ea2528ea3768bc5a414ebc5d9010",
    "edition": 1765,
    "date": 1672533783492,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Lucky Jacket", "score": 99.5 },
      {
        "trait_type": "Head",
        "value": "Employee Hat Red",
        "score": 132.66666666666666
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1765",
    "rarity_score": 519.4920405023872,
    "rarity_level": "RARE"
  },
  "1742": {
    "name": "Arkade 90s Bulls #1742",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1742.png",
    "dna": "0597522e7aca78d76abc45882b9ddf82a59adcee",
    "edition": 1742,
    "date": 1672533783975,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kadena Buddies",
        "score": 36.18181818181818
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1742",
    "rarity_score": 184.31202443285162,
    "rarity_level": "COMMON"
  },
  "1362": {
    "name": "Arkade 90s Bulls #1362",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1362.png",
    "dna": "b528b77db1dbda682e6e759a10fd004281a41e98",
    "edition": 1362,
    "date": 1672533784519,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      { "trait_type": "Head", "value": "Halo", "score": 124.375 },
      {
        "trait_type": "Glasses",
        "value": "Baby Glasses",
        "score": 49.75
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1362",
    "rarity_score": 298.6788151572438,
    "rarity_level": "COMMON"
  },
  "1708": {
    "name": "Arkade 90s Bulls #1708",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1708.png",
    "dna": "2b2cf651be2c78a3c91348ab30612a230528c751",
    "edition": 1708,
    "date": 1672533785014,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Sailor",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "Blue Not Smart Hat",
        "score": 124.375
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1708",
    "rarity_score": 365.4137251798115,
    "rarity_level": "COMMON"
  },
  "643": {
    "name": "Arkade 90s Bulls #643",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/643.png",
    "dna": "096cccbb015c5aa2b2657ddaac206f0ff65cf03b",
    "edition": 643,
    "date": 1672533785524,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "643",
    "rarity_score": 151.95905561414804,
    "rarity_level": "COMMON"
  },
  "572": {
    "name": "Arkade 90s Bulls #572",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/572.png",
    "dna": "6d2bc25a531ff18f534dd1961378a97d37ff4436",
    "edition": 572,
    "date": 1672533786187,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Detective Suit",
        "score": 71.07142857142857
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "572",
    "rarity_score": 212.06998343472677,
    "rarity_level": "COMMON"
  },
  "1911": {
    "name": "Arkade 90s Bulls #1911",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1911.png",
    "dna": "67d6049497faa85d35ae7b2c3bb025040bd43527",
    "edition": 1911,
    "date": 1672533787343,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Slayer Sword",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Captain Sustainable",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Scavenger Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1911",
    "rarity_score": 237.74698520151887,
    "rarity_level": "COMMON"
  },
  "249": {
    "name": "Arkade 90s Bulls #249",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/249.png",
    "dna": "81fcc36faa2e8258c1abb1a74efbded3903e8755",
    "edition": 249,
    "date": 1672533788501,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple Gradient",
        "score": 19.134615384615383
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Termination Glasses",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "249",
    "rarity_score": 196.75799077366224,
    "rarity_level": "COMMON"
  },
  "289": {
    "name": "Arkade 90s Bulls #289",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/289.png",
    "dna": "938d3668e256fe83e8fa9bd547b8950065b4b06b",
    "edition": 289,
    "date": 1672533788986,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Red Tunic Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "289",
    "rarity_score": 220.57898079997872,
    "rarity_level": "COMMON"
  },
  "256": {
    "name": "Arkade 90s Bulls #256",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/256.png",
    "dna": "2e86c0ea921745564478e3376db6efb7e576e596",
    "edition": 256,
    "date": 1672533789626,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      { "trait_type": "Outfit", "value": "Robe", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "256",
    "rarity_score": 331.48255588482857,
    "rarity_level": "COMMON"
  },
  "747": {
    "name": "Arkade 90s Bulls #747",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/747.png",
    "dna": "25146064249391dc4d425ca55c2df49a1cd83d7a",
    "edition": 747,
    "date": 1672533790839,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "747",
    "rarity_score": 140.10418877464835,
    "rarity_level": "COMMON"
  },
  "914": {
    "name": "Arkade 90s Bulls #914",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/914.png",
    "dna": "0f7e39e6d1c20a85dd6a8d4c7ba20d957e7f99ef",
    "edition": 914,
    "date": 1672533791317,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      { "trait_type": "Eyes", "value": "Left Green Eyes", "score": 39.8 },
      {
        "trait_type": "Outfit",
        "value": "Pink Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "914",
    "rarity_score": 287.6840347143777,
    "rarity_level": "COMMON"
  },
  "718": {
    "name": "Arkade 90s Bulls #718",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/718.png",
    "dna": "be8fba970840bfb331f59216d4891cf80e0d3534",
    "edition": 718,
    "date": 1672533792509,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Fictional Long Hair",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "718",
    "rarity_score": 149.70412407967697,
    "rarity_level": "COMMON"
  },
  "151": {
    "name": "Arkade 90s Bulls #151",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/151.png",
    "dna": "06eb93f34e89a0fc1d1e5979f4fb5157625b58f5",
    "edition": 151,
    "date": 1672533793669,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Red Eyes",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Outfit",
        "value": "Rocket Squad",
        "score": 180.9090909090909
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "151",
    "rarity_score": 335.6908265716727,
    "rarity_level": "COMMON"
  },
  "491": {
    "name": "Arkade 90s Bulls #491",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/491.png",
    "dna": "2c60c7b44d0b0cf03b85257d86c16154de8dbc7a",
    "edition": 491,
    "date": 1672533794871,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Hedgehog",
        "score": 86.52173913043478
      },
      {
        "trait_type": "Head",
        "value": "Blue Tunic Hat",
        "score": 142.14285714285714
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "491",
    "rarity_score": 287.63993476218155,
    "rarity_level": "COMMON"
  },
  "1214": {
    "name": "Arkade 90s Bulls #1214",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1214.png",
    "dna": "a2487d7e457e51fe6c654ce2cf96285175904e59",
    "edition": 1214,
    "date": 1672533795455,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Red Pool",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1214",
    "rarity_score": 210.09107820098438,
    "rarity_level": "COMMON"
  },
  "1051": {
    "name": "Arkade 90s Bulls #1051",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1051.png",
    "dna": "d2d84dcf9358b18db66ae52a0d1791fbd9f5836a",
    "edition": 1051,
    "date": 1672533796030,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Fresh King Shirt",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1051",
    "rarity_score": 161.56723641216792,
    "rarity_level": "COMMON"
  },
  "1715": {
    "name": "Arkade 90s Bulls #1715",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1715.png",
    "dna": "6a33a7e6b9ce59f1772674f2d86ba0e364e86c11",
    "edition": 1715,
    "date": 1672533797192,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Black Suit",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Head",
        "value": "Walkman",
        "score": 60.303030303030305
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1715",
    "rarity_score": 204.92797954167705,
    "rarity_level": "COMMON"
  },
  "1334": {
    "name": "Arkade 90s Bulls #1334",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1334.png",
    "dna": "9c28da1334c27c1f4c0c46f16e63627d13bb9087",
    "edition": 1334,
    "date": 1672533797727,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      { "trait_type": "Base", "value": "Smile", "score": 248.75 },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Great Burger Uniform",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Head",
        "value": "Short Hair",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Glasses",
        "value": "Circle Glasses",
        "score": 23.41176470588235
      },
      {
        "trait_type": "Accessory",
        "value": "Wizard Staff",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1334",
    "rarity_score": 547.9882572805892,
    "rarity_level": "RARE"
  },
  "1950": {
    "name": "Arkade 90s Bulls #1950",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1950.png",
    "dna": "c530f77ec48ccd253012868ddf9a49303055b6d8",
    "edition": 1950,
    "date": 1672533798811,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue Gradient",
        "score": 22.11111111111111
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1950",
    "rarity_score": 149.0881099240837,
    "rarity_level": "COMMON"
  },
  "1361": {
    "name": "Arkade 90s Bulls #1361",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1361.png",
    "dna": "234cf48156642de5a43b9cac7ce462469fc21abb",
    "edition": 1361,
    "date": 1672533799289,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Nose Ring",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1361",
    "rarity_score": 162.49825696432526,
    "rarity_level": "COMMON"
  },
  "1792": {
    "name": "Arkade 90s Bulls #1792",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1792.png",
    "dna": "3e1c9aff05093743008e303f2712eb550637172c",
    "edition": 1792,
    "date": 1672533799787,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Kaid Muscle Tee",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "Kittygotchi",
        "score": 16.722689075630253
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1792",
    "rarity_score": 173.50985708111648,
    "rarity_level": "COMMON"
  },
  "387": {
    "name": "Arkade 90s Bulls #387",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/387.png",
    "dna": "b8f1a56667f53877e73f05b1e744cd48a5a6a16a",
    "edition": 387,
    "date": 1672533800274,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Red",
        "score": 23.13953488372093
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Not Smart Suit",
        "score": 76.53846153846153
      },
      {
        "trait_type": "Head",
        "value": "Snapback Backwards Hat",
        "score": 53.78378378378378
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "387",
    "rarity_score": 277.7858077978368,
    "rarity_level": "COMMON"
  },
  "190": {
    "name": "Arkade 90s Bulls #190",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/190.png",
    "dna": "5ad510618ea0cbdf5d3c33347946289520ba6f65",
    "edition": 190,
    "date": 1672533800767,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "190",
    "rarity_score": 135.4311848747039,
    "rarity_level": "COMMON"
  },
  "144": {
    "name": "Arkade 90s Bulls #144",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/144.png",
    "dna": "a8694d1e874c8e708210cebf2e21eaf557993719",
    "edition": 144,
    "date": 1672533801424,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Spiderbull",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "144",
    "rarity_score": 220.13955976094923,
    "rarity_level": "COMMON"
  },
  "376": {
    "name": "Arkade 90s Bulls #376",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/376.png",
    "dna": "c21fcbee7aeb6db1994f034674f48a3ffe206636",
    "edition": 376,
    "date": 1672533802531,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Termination Leather Jacket",
        "score": 94.76190476190476
      },
      {
        "trait_type": "Head",
        "value": "Employee Hat Black",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "376",
    "rarity_score": 322.75309022332965,
    "rarity_level": "COMMON"
  },
  "498": {
    "name": "Arkade 90s Bulls #498",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/498.png",
    "dna": "c009fbf088a5eea5c24443586106944f41f9c07b",
    "edition": 498,
    "date": 1672533803045,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      {
        "trait_type": "Outfit",
        "value": "K Planet",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 1",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "Koala",
        "score": 17.304347826086957
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "498",
    "rarity_score": 203.41287260327664,
    "rarity_level": "COMMON"
  },
  "301": {
    "name": "Arkade 90s Bulls #301",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/301.png",
    "dna": "9b3d4744304f548ba071f933f27ea72445de5b46",
    "edition": 301,
    "date": 1672533803547,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "301",
    "rarity_score": 114.94890840912522,
    "rarity_level": "COMMON"
  },
  "319": {
    "name": "Arkade 90s Bulls #319",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/319.png",
    "dna": "eae4afc7f4d518be3d755154982341ace841c57a",
    "edition": 319,
    "date": 1672533804040,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "319",
    "rarity_score": 184.71009869340563,
    "rarity_level": "COMMON"
  },
  "806": {
    "name": "Arkade 90s Bulls #806",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/806.png",
    "dna": "d19eceff52d47b952ac6b073b422c0d33e9a49da",
    "edition": 806,
    "date": 1672533804517,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Gold Miner Tee",
        "score": 51.02564102564103
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "806",
    "rarity_score": 279.5575405476853,
    "rarity_level": "COMMON"
  },
  "461": {
    "name": "Arkade 90s Bulls #461",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/461.png",
    "dna": "ff5bd26eb5ed35c29c9ffac712a74b4baefe5ba4",
    "edition": 461,
    "date": 1672533805038,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      { "trait_type": "Outfit", "value": "KDSwap", "score": 49.75 },
      {
        "trait_type": "Head",
        "value": "PACt Bandana",
        "score": 68.62068965517241
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "461",
    "rarity_score": 239.0447576828679,
    "rarity_level": "COMMON"
  },
  "1639": {
    "name": "Arkade 90s Bulls #1639",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1639.png",
    "dna": "81b1fb5f827c3146176294e3fe541bf569b37627",
    "edition": 1639,
    "date": 1672533805525,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kirvana Tee",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1639",
    "rarity_score": 143.5230304273311,
    "rarity_level": "COMMON"
  },
  "1251": {
    "name": "Arkade 90s Bulls #1251",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1251.png",
    "dna": "dd85df6383a4b123cdc5b87ada6b28086c8dee09",
    "edition": 1251,
    "date": 1672533806965,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Left Blue Eyes",
        "score": 36.851851851851855
      },
      {
        "trait_type": "Outfit",
        "value": "Jean Jacket",
        "score": 37.54716981132076
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1251",
    "rarity_score": 188.8657614166983,
    "rarity_level": "COMMON"
  },
  "844": {
    "name": "Arkade 90s Bulls #844",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/844.png",
    "dna": "453fdba0b0cb6221f53151d01e73a6ac70437c93",
    "edition": 844,
    "date": 1672533807428,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Yellow",
        "score": 9.128440366972477
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Arkade",
        "score": 32.096774193548384
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "844",
    "rarity_score": 235.85281092225657,
    "rarity_level": "COMMON"
  },
  "1047": {
    "name": "Arkade 90s Bulls #1047",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1047.png",
    "dna": "877755d8703d8d65063b6feed09baa26bac3c7b9",
    "edition": 1047,
    "date": 1672533808181,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Black Gradient",
        "score": 26.18421052631579
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "90s Hat 2",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1047",
    "rarity_score": 382.7433723703984,
    "rarity_level": "COMMON"
  },
  "651": {
    "name": "Arkade 90s Bulls #651",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/651.png",
    "dna": "60b91eea1e6bcd139b17020b94ea45ac3252dc11",
    "edition": 651,
    "date": 1672533808705,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Purple",
        "score": 10.698924731182796
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "Kool Tee 1",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Head",
        "value": "Diggers Hat",
        "score": 110.55555555555556
      },
      {
        "trait_type": "Glasses",
        "value": "Auto Glasses",
        "score": 18.952380952380953
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "651",
    "rarity_score": 277.82044409120175,
    "rarity_level": "COMMON"
  },
  "75": {
    "name": "Arkade 90s Bulls #75",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/75.png",
    "dna": "6b0645072b601afba6e18dcd21a6771099767fbe",
    "edition": 75,
    "date": 1672533809835,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green Gradient",
        "score": 20.1010101010101
      },
      {
        "trait_type": "Back",
        "value": "Ninja Dual Swords",
        "score": 22.359550561797754
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Right Blue Eyes",
        "score": 47.38095238095238
      },
      {
        "trait_type": "Outfit",
        "value": "Black Guardian Suit",
        "score": 199.0
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "Karth Glasses",
        "score": 18.77358490566038
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "75",
    "rarity_score": 406.9063289001202,
    "rarity_level": "COMMON"
  },
  "1190": {
    "name": "Arkade 90s Bulls #1190",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1190.png",
    "dna": "58274102a6b6925359e359652f03cb88020457ac",
    "edition": 1190,
    "date": 1672533810367,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Mustache",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Eyes",
        "value": "Right Brown Eyes",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Outfit",
        "value": "Pact Rats",
        "score": 56.857142857142854
      },
      {
        "trait_type": "Head",
        "value": "90s Hat 3",
        "score": 41.458333333333336
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1190",
    "rarity_score": 258.23119857992396,
    "rarity_level": "COMMON"
  },
  "188": {
    "name": "Arkade 90s Bulls #188",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/188.png",
    "dna": "4bbd385e8b661920e9e3c336db2fc6cd3e42ba36",
    "edition": 188,
    "date": 1672533811556,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "Arkade Hat",
        "score": 64.19354838709677
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "188",
    "rarity_score": 146.0787770714508,
    "rarity_level": "COMMON"
  },
  "678": {
    "name": "Arkade 90s Bulls #678",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/678.png",
    "dna": "c6eedfcb62f67347b9e65bc30821ad2420693ad7",
    "edition": 678,
    "date": 1672533812061,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Blue",
        "score": 7.37037037037037
      },
      {
        "trait_type": "Back",
        "value": "Ninja Knife",
        "score": 28.428571428571427
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Angry",
        "score": 34.91228070175438
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Kosmos",
        "score": 52.36842105263158
      },
      { "trait_type": "Head", "value": "Frosted Tips", "score": 79.6 },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "678",
    "rarity_score": 213.42057380369758,
    "rarity_level": "COMMON"
  },
  "1857": {
    "name": "Arkade 90s Bulls #1857",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1857.png",
    "dna": "9074711395782a4fac2023d0d0411fb57df76d7e",
    "edition": 1857,
    "date": 1672533813231,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Blue Eyes",
        "score": 4.925742574257426
      },
      {
        "trait_type": "Outfit",
        "value": "NONE",
        "score": 12.208588957055214
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "White 3D Glasses",
        "score": 46.27906976744186
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1857",
    "rarity_score": 132.22450486879825,
    "rarity_level": "COMMON"
  },
  "647": {
    "name": "Arkade 90s Bulls #647",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/647.png",
    "dna": "55c4c416953e6591acec5e526569949d9aab1d51",
    "edition": 647,
    "date": 1672533814698,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink Gradient",
        "score": 22.873563218390803
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Tongue Out",
        "score": 66.33333333333333
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Green Tunic",
        "score": 153.07692307692307
      },
      {
        "trait_type": "Head",
        "value": "Billy the Bull Hat",
        "score": 62.1875
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "647",
    "rarity_score": 316.5129034737883,
    "rarity_level": "COMMON"
  },
  "173": {
    "name": "Arkade 90s Bulls #173",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/173.png",
    "dna": "16da59cb3abe3f47bf2af4277db3dde9d9ba804c",
    "edition": 173,
    "date": 1672533815192,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Green",
        "score": 7.081850533807829
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Green Eyes",
        "score": 5.076530612244898
      },
      {
        "trait_type": "Outfit",
        "value": "Space Robe",
        "score": 73.70370370370371
      },
      {
        "trait_type": "Head",
        "value": "Good Burger Hat",
        "score": 117.05882352941177
      },
      {
        "trait_type": "Glasses",
        "value": "Shoobie Glasses",
        "score": 43.26086956521739
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "173",
    "rarity_score": 277.3127706769909,
    "rarity_level": "COMMON"
  },
  "669": {
    "name": "Arkade 90s Bulls #669",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/669.png",
    "dna": "4f293028abf8f7f9cafa4f0cbe67346da2d13b8b",
    "edition": 669,
    "date": 1672533815680,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Happy",
        "score": 27.63888888888889
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Red Eyes",
        "score": 12.283950617283951
      },
      { "trait_type": "Outfit", "value": "Hey", "score": 199.0 },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "Vintage Steve Glasses",
        "score": 40.61224489795919
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "669",
    "rarity_score": 296.6826731819351,
    "rarity_level": "COMMON"
  },
  "321": {
    "name": "Arkade 90s Bulls #321",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/321.png",
    "dna": "89555fdc0039fa096ad853fb9e6ed1e3aa3ad89b",
    "edition": 321,
    "date": 1672533816150,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Pink",
        "score": 10.994475138121548
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Sad",
        "score": 35.535714285714285
      },
      {
        "trait_type": "Eyes",
        "value": "Left Red Eyes",
        "score": 51.02564102564103
      },
      {
        "trait_type": "Outfit",
        "value": "Red 33",
        "score": 90.45454545454545
      },
      {
        "trait_type": "Head",
        "value": "NONE",
        "score": 6.909722222222222
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "321",
    "rarity_score": 201.88515135914074,
    "rarity_level": "COMMON"
  },
  "1797": {
    "name": "Arkade 90s Bulls #1797",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1797.png",
    "dna": "f7fa8c4668d67d0dee17c957c3d932c3bd877e51",
    "edition": 1797,
    "date": 1672533817064,
    "attributes": [
      {
        "trait_type": "Background",
        "value": "Orange",
        "score": 6.745762711864407
      },
      {
        "trait_type": "Back",
        "value": "NONE",
        "score": 1.3006535947712419
      },
      {
        "trait_type": "Base",
        "value": "Light Brown Evil",
        "score": 39.01960784313726
      },
      {
        "trait_type": "Eyes",
        "value": "Forward Brown Eyes",
        "score": 4.2703862660944205
      },
      {
        "trait_type": "Outfit",
        "value": "Blue Tunic",
        "score": 82.91666666666667
      },
      {
        "trait_type": "Head",
        "value": "Fictional Fro",
        "score": 58.529411764705884
      },
      {
        "trait_type": "Glasses",
        "value": "NONE",
        "score": 3.472949389179756
      },
      {
        "trait_type": "Accessory",
        "value": "NONE",
        "score": 1.1782119597394909
      },
      {
        "trait_type": "Overlay",
        "value": "NONE",
        "score": 1.0132382892057026
      }
    ],
    "id": "1797",
    "rarity_score": 198.4468884853648,
    "rarity_level": "COMMON"
  },
  "1513": {
    "name": "Arkade 90s Bulls #1513",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1513.png",
    "dna": "0c8b36939bec29789cf83f54d031cb40616629b6",
    "edition": 1513,
    "date": 1672533817545,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Notorious KDA",
        "score": 1990.0
      }
    ],
    "id": "1513",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1435": {
    "name": "Arkade 90s Bulls #1435",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1435.png",
    "dna": "8e1d2f07fc521a46f5d8ee464aa4a802c606f460",
    "edition": 1435,
    "date": 1672533818468,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Firebreather",
        "score": 1990.0
      }
    ],
    "id": "1435",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "712": {
    "name": "Arkade 90s Bulls #712",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/712.png",
    "dna": "d8594c339d06cb1feae21c69e89e14daa2296da6",
    "edition": 712,
    "date": 1672533819019,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Spiderbull",
        "score": 1990.0
      }
    ],
    "id": "712",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1368": {
    "name": "Arkade 90s Bulls #1368",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1368.png",
    "dna": "aab875cd1aa3bdea4e45d4a99504e7cffa7a764c",
    "edition": 1368,
    "date": 1672533819802,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Founder Bull Kadi",
        "score": 1990.0
      }
    ],
    "id": "1368",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1092": {
    "name": "Arkade 90s Bulls #1092",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1092.png",
    "dna": "ad74de602d54e869e52a586968eb3c9e2906579c",
    "edition": 1092,
    "date": 1672533820459,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Sphynxxx",
        "score": 1990.0
      }
    ],
    "id": "1092",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1442": {
    "name": "Arkade 90s Bulls #1442",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1442.png",
    "dna": "96803bbe9b1b2293714e100ca0f3e0f0aff94db4",
    "edition": 1442,
    "date": 1672533820810,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Wizard",
        "score": 1990.0
      }
    ],
    "id": "1442",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1": {
    "name": "Arkade 90s Bulls #1",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1.png",
    "dna": "d7f88b9c2c472369713404cef27f2069aa076ccd",
    "edition": 1,
    "date": 1672533821191,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Santa",
        "score": 1990.0
      }
    ],
    "id": "1",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "959": {
    "name": "Arkade 90s Bulls #959",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/959.png",
    "dna": "f231cfcb6367d999313c3baed5dcbc58fdacbb97",
    "edition": 959,
    "date": 1672533821513,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Kong",
        "score": 1990.0
      }
    ],
    "id": "959",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "375": {
    "name": "Arkade 90s Bulls #375",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/375.png",
    "dna": "f4adc27bc999a2061e68262eb4b38e2c301e30db",
    "edition": 375,
    "date": 1672533821821,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Founder Bull Norrizzy",
        "score": 1990.0
      }
    ],
    "id": "375",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1307": {
    "name": "Arkade 90s Bulls #1307",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1307.png",
    "dna": "960e13fe6450a145f315a765d169db44b0bd46be",
    "edition": 1307,
    "date": 1672533822590,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Arkade",
        "score": 1990.0
      }
    ],
    "id": "1307",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1833": {
    "name": "Arkade 90s Bulls #1833",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1833.png",
    "dna": "9789da376c1b7906c1fbf4cf7d39c1d27a08af8d",
    "edition": 1833,
    "date": 1672533823084,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Super Kario",
        "score": 1990.0
      }
    ],
    "id": "1833",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "869": {
    "name": "Arkade 90s Bulls #869",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/869.png",
    "dna": "85c2ac0a43a7522fb2303b19aea5a10f77dc5c12",
    "edition": 869,
    "date": 1672533823821,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Red Pill",
        "score": 1990.0
      }
    ],
    "id": "869",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1956": {
    "name": "Arkade 90s Bulls #1956",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1956.png",
    "dna": "e2408add662099972026b48f39dc4c31e27a12ce",
    "edition": 1956,
    "date": 1672533824129,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Krazy",
        "score": 1990.0
      }
    ],
    "id": "1956",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1303": {
    "name": "Arkade 90s Bulls #1303",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1303.png",
    "dna": "953ca5f2626b5d7d57d5d3bd551dd6e1d5e78c08",
    "edition": 1303,
    "date": 1672533824457,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Fry Kook",
        "score": 1990.0
      }
    ],
    "id": "1303",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "1325": {
    "name": "Arkade 90s Bulls #1325",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/1325.png",
    "dna": "ade770d4d79f6cc83ef26c7969a79d3d37b5c65f",
    "edition": 1325,
    "date": 1672533825041,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Founder Bull Sn0",
        "score": 1990.0
      }
    ],
    "id": "1325",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "2": {
    "name": "Arkade 90s Bulls #2",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/2.png",
    "dna": "e81a30031d0add1602cdc0d0006b1971336e36a3",
    "edition": 2,
    "date": 1672533825365,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Slayer",
        "score": 1990.0
      }
    ],
    "id": "2",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  },
  "563": {
    "name": "Arkade 90s Bulls #563",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "https://arkade-prod.s3.us-east-1.amazonaws.com/arkade-90s-bulls/563.png",
    "dna": "3d4099a46a2167d133d66a6cdf39e52d77ffbde6",
    "edition": 563,
    "date": 1672533825811,
    "attributes": [
      {
        "trait_type": "Special Edition",
        "value": "Purple Dragon",
        "score": 1990.0
      }
    ],
    "id": "563",
    "rarity_score": 1990.0,
    "rarity_level": "ONE OF ONE"
  }
}






const testArkade90sBullsMetadata = {
  "1": {
    "name": "Arkade 90s Bulls #1",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///1.png",
    "dna": "d7f88b9c2c472369713404cef27f2069aa076ccd",
    "edition": 1,
    "date": 1672533821191,
    "attributes": [{ "trait_type": "Special Edition", "value": "Santa" }]
  },
  "2": {
    "name": "Arkade 90s Bulls #2",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///2.png",
    "dna": "e81a30031d0add1602cdc0d0006b1971336e36a3",
    "edition": 2,
    "date": 1672533825365,
    "attributes": [{ "trait_type": "Special Edition", "value": "Slayer" }]
  },
  "3": {
    "name": "Arkade 90s Bulls #3",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///3.png",
    "dna": "f43020632c1cf7760e841523a85fdc07b03c94d4",
    "edition": 3,
    "date": 1672533771781,
    "attributes": [
      { "trait_type": "Background", "value": "Green" },
      { "trait_type": "Back", "value": "NONE" },
      { "trait_type": "Base", "value": "Light Brown Angry" },
      { "trait_type": "Eyes", "value": "Right Brown Eyes" },
      { "trait_type": "Outfit", "value": "NONE" },
      { "trait_type": "Head", "value": "NONE" },
      { "trait_type": "Glasses", "value": "NONE" },
      { "trait_type": "Accessory", "value": "NONE" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
  "4": {
    "name": "Arkade 90s Bulls #4",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///4.png",
    "dna": "2840d0a70466aaec77b1a88d1cc35e6ccab04087",
    "edition": 4,
    "date": 1672533760671,
    "attributes": [
      { "trait_type": "Background", "value": "Orange" },
      { "trait_type": "Back", "value": "Sword And Shield" },
      { "trait_type": "Base", "value": "Light Brown Angry" },
      { "trait_type": "Eyes", "value": "Left Blue Eyes" },
      { "trait_type": "Outfit", "value": "Letterman Jacket" },
      { "trait_type": "Head", "value": "Karth Hair" },
      { "trait_type": "Glasses", "value": "Karth Glasses" },
      { "trait_type": "Accessory", "value": "NONE" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
  "5": {
    "name": "Arkade 90s Bulls #5",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///5.png",
    "dna": "f4860dff5a933229ebfb158c14032162dfed7226",
    "edition": 5,
    "date": 1672532379480,
    "attributes": [
      { "trait_type": "Background", "value": "Yellow" },
      { "trait_type": "Back", "value": "NONE" },
      { "trait_type": "Base", "value": "Gold Happy" },
      { "trait_type": "Eyes", "value": "Forward Brown Eyes" },
      { "trait_type": "Outfit", "value": "NONE" },
      { "trait_type": "Mask", "value": "NONE" },
      { "trait_type": "Accessory", "value": "NONE" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
  "6": {
    "name": "Arkade 90s Bulls #6",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///6.png",
    "dna": "06c5a5dde49d14c2e366dbe6b6a8e607224c9b3c",
    "edition": 6,
    "date": 1672533578916,
    "attributes": [
      { "trait_type": "Background", "value": "Pink Gradient" },
      { "trait_type": "Back", "value": "Ninja Dual Swords" },
      { "trait_type": "Base", "value": "Purple Evil" },
      { "trait_type": "Eyes", "value": "Down Red Eyes" },
      { "trait_type": "Outfit", "value": "Beige Bull Tee" },
      { "trait_type": "Head", "value": "Billy the Bull Hat" },
      { "trait_type": "Glasses", "value": "Nose Ring" },
      { "trait_type": "Accessory", "value": "NONE" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
  "7": {
    "name": "Arkade 90s Bulls #7",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///7.png",
    "dna": "e06a055ec1cffe9935b0fe848a51d2dcca3929e0",
    "edition": 7,
    "date": 1672533409408,
    "attributes": [
      { "trait_type": "Background", "value": "Yellow" },
      { "trait_type": "Back", "value": "NONE" },
      { "trait_type": "Base", "value": "Gray Happy" },
      { "trait_type": "Eyes", "value": "Forward Brown Eyes" },
      { "trait_type": "Outfit", "value": "Arkade" },
      { "trait_type": "Head", "value": "Karth Hair" },
      { "trait_type": "Glasses", "value": "Karth Glasses" },
      { "trait_type": "Accessory", "value": "Koala" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
  "8": {
    "name": "Arkade 90s Bulls #8",
    "description": "Groovy bulls rocking all the styles and themes of the 1990s. Owning a bull will take you on a trip down memory lane, back to a time when fast food was actually cheap and cartoons peaked.",
    "image": "ipfs:///8.png",
    "dna": "5a1da5a3915101697b83dd3c7639b801f5a072d5",
    "edition": 8,
    "date": 1672532026221,
    "attributes": [
      { "trait_type": "Background", "value": "Green" },
      { "trait_type": "Back", "value": "Ninja Dual Swords" },
      { "trait_type": "Base", "value": "Bronze Sad" },
      { "trait_type": "Eyes", "value": "Left Brown Eyes" },
      { "trait_type": "Outfit", "value": "Tuxedo" },
      { "trait_type": "Mask", "value": "NONE" },
      { "trait_type": "Accessory", "value": "NONE" },
      { "trait_type": "Overlay", "value": "NONE" }
    ]
  },
}

export default arkade_90s_bulls_metadata
import { buildExecCommand } from "../pact/PactUtils";
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE, NETWORK_ID } from "./Constants";

function transformButterNftDataToStandard(nftData) {
    let transformedData = {};


    return transformedData;
}

function transformNftDataToIsokoStandard(policy, nft, collectionProvider) {
    let transformedData = {};

    transformedData["chain-id"] = policy["chain-id"];
    transformedData["collection-name"] = policy["project-name"];
    transformedData["formatted_attributes"] = collectionProvider?.getNftTraitStats(nft["attributes"]);
    transformedData["nft-id"] = nft["id"];
    transformedData["nft-uri"] = nft["image"];
    // transformedData["nft-id"] = nft["id"]
    transformedData["current-owner"] = nft["owner"];
    transformedData["platform"] = "";
    transformedData["policy-name"] = policy["policy-name"];
    if (!nft["price"] || nft["price"] < 0) {
        transformedData["status"] = "unlocked";
    } else {
        transformedData["status"] = "listed";
    }
    transformedData["listing-details"] = {
        timeout: undefined,
        price: nft["price"]
    };

    return transformedData;
}

function createEzBuyCommandFromExec(
    pactCode,
    chainId,
    sender,
    pubKey,
    envData,
    caps,
    cmdType,
    gasPrice,
    gasLimit,
    txMessage = null,
    previewContent = null,
    transactionCompleteCallback = null
) {
    const cmd = {
        pactCode,
        caps,
        sender: sender,
        gasLimit: gasLimit ? gasLimit : DEFAULT_GAS_LIMIT,
        gasPrice: gasPrice ? gasPrice : DEFAULT_GAS_PRICE,
        chainId: chainId,
        ttl: 600,
        envData,
        signingPubKey: pubKey,
        networkId: NETWORK_ID,
    };

    let bzCommand = buildExecCommand(
        pactCode,
        chainId,
        sender,
        pubKey,
        envData,
        caps
    );

    let cmdPubKeyObj = {
        cmd: bzCommand,
        pubKey: pubKey
    }

    let txData = {
        cmdType: cmdType,
        cmd: cmd,
        bzCommand: cmdPubKeyObj,
        previewContent: previewContent,
        transactionMessage: txMessage,
        callback: transactionCompleteCallback,
        errorHandler: transactionCompleteCallback,
        errorMessage: "",
        shouldSign: true,
    };

    return txData;
}

export {
    transformButterNftDataToStandard,
    createEzBuyCommandFromExec,
    transformNftDataToIsokoStandard
}
import { Box, Text } from "@chakra-ui/react";
import React from "react";
import FilterToggle from "./FilterToggle";
import Traits from "./Traits";

const FilterTab = () => {
    return (
        <Box px="8px">
            <Box mb="32px" pt="16px">
                <Text fontSize="20px" fontWeight="900">
                    Status
                </Text>
                <FilterToggle label={"Show only Listed NFTs"} />
                <FilterToggle label={"Show only My NFTs"} />
            </Box>

            <Box mt="24px">
                <Traits />
            </Box>
        </Box>
    );
};

export default FilterTab;

import { Box, Image, Text } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";
import { firestoreQueryCollection } from "../Firestore/FirestoreService";
import { checkIfNullOrUndefined } from "../utils/utils";

const SubCollectionCard = memo(
    ({
        collectionConfig,
        collectionUtility,
        collectionProvider,
        mintProvider,
        subCollection,
        subCollectionId,
        cardCallback,
        type,
        clickable,
        burnNfts,
        ...props
    }) => {
        const [collectionFloor, setCollectionFloor] = useState(null);
        const [price, setPrice] = useState(null);

        useEffect(() => {
            async function getCollectionFloor() {
                // console.log(subCollectionId)
                let floor = await collectionProvider.getCollectionFloor(collectionConfig["policy-info"], subCollectionId);
                setCollectionFloor(floor);
            }
            if (
                !checkIfNullOrUndefined(collectionConfig) &&
                !checkIfNullOrUndefined(collectionProvider) &&
                checkIfNullOrUndefined(collectionFloor) &&
                type === "live"
            ) {
                getCollectionFloor();
            }
        }, [subCollectionId, setCollectionFloor]);

        useEffect(() => {
            async function getNextPrice() {
                if (!checkIfNullOrUndefined(mintProvider)) {
                    let localPrice = await mintProvider.getNextPrice();
                    setPrice(localPrice);
                }
            }

            if (checkIfNullOrUndefined(price) && !checkIfNullOrUndefined(collectionProvider)) {
                getNextPrice();
            }
        }, [collectionProvider]);

        function getSupply() {
            if (!checkIfNullOrUndefined(burnNfts)) {
                return subCollection["nft_supply"] - burnNfts.length;
            }
            return subCollection["nft_supply"];
        }

        return (
            <>
                {collectionConfig && (
                    <Box
                        className="kadCard latestDesign"
                        style={clickable ? { cursor: "pointer" } : null}
                        onClick={() => {
                            if (clickable) {
                                clickable &&
                                    cardCallback &&
                                    cardCallback(subCollectionId);
                            }
                        }}
                        {...props}
                    >
                        <Box className="">
                            <Image
                                src={
                                    mintProvider?.getNftImage(`${subCollectionId}${collectionConfig["nft-id-separator-token"]}1`)
                                }
                                alt="nftCard"
                                width={"100%"}
                                height="18.5rem"
                                objectFit={"cover"}
                                className="cardImage"
                            />
                        </Box>
                        <Box
                            style={{ padding: "16px 16px 16px 21px" }}
                        // bgColor="#160d19"
                        >
                            <Box>
                                <Box className="flex sBtw alignCenter">
                                    {" "}
                                    <Text
                                        className="kadcarsTitle"
                                        fontSize="1rem"
                                        fontWeight="700"
                                        noOfLines={1}
                                    >
                                        {" "}
                                        {
                                            mintProvider?.getSubCollectionName(collectionConfig, subCollectionId)
                                        }
                                    </Text>
                                    {/* Card type badge variants */}
                                    {/* live badge */}
                                    {type === "marketplace" && (
                                        <Box
                                            className=""
                                            fontSize="12px"
                                            fontWeight={"700"}
                                            px="8px"
                                            py="2px"
                                            border="1px"
                                            borderColor="#E9BEFA"
                                            bgColor="#BC3CF0"
                                            borderRadius={"32px"}
                                            flexShrink={0}
                                        >
                                            {" "}TRADE NOW{" "}
                                        </Box>
                                    )}
                                    {type === "live" && (
                                        <Box
                                            className=""
                                            fontSize="12px"
                                            fontWeight={"700"}
                                            px="8px"
                                            py="2px"
                                            border="1px"
                                            borderColor="#E9BEFA"
                                            bgColor="green"
                                            borderRadius={"32px"}
                                            flexShrink={0}
                                        >
                                            {" "}LIVE{" "}
                                        </Box>
                                    )}
                                    {type === "startingSoon" && (
                                        <Box
                                            className="startingSoonBadge"
                                            flexShrink={0}
                                        >
                                            {" "}COMING SOON{" "}
                                        </Box>
                                    )}
                                    {type === "ended" && (
                                        <Box
                                            className="endedBadge"
                                            flexShrink={0}
                                        >
                                            {" "}ENDED{" "}
                                        </Box>
                                    )}
                                    {/* Card type badge variants */}
                                </Box>
                                <Box mt="0.5rem" className="totalItems">
                                    <Box className="cardDetails">
                                        <p
                                            style={{
                                                marginBottom: "0px",
                                                fontSize: "12px",
                                            }}
                                        >
                                            TOTAL ITEMS
                                        </p>
                                        <p
                                            className=""
                                            style={{
                                                marginBottom: "0px",
                                                fontSize: "12px",
                                                textAlign: "right",
                                            }}
                                        >
                                            {" "}
                                            {type === "live" && "FLOOR"}
                                        </p>
                                    </Box>
                                    <Box className="cardDetails cardValue">
                                        <Text>
                                            {getSupply()}
                                        </Text>
                                        <Text className="" textAlign={"right"}>
                                            {
                                                type === "live" && collectionFloor ?
                                                    `${collectionFloor.toFixed(1)} KDA`
                                                    : "N/A"
                                            }
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
        );
    }
);

export default SubCollectionCard;

import { useContext } from "react";
import { toast } from "react-toastify";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { PactContext } from "../pact/PactContextProvider";
import { projectConfigs } from "../project_config_files/project_configs";
import {
    CHAIN_ID_KEY,
    KADCARS_PROJECT_NAME_KEY,
    MAINNET_NETWORK_ID,
    NETWORK_ID,
    MINT_PHASES_KEY,
    NFT_SUPPLY_KEY,
    TESTNET_NETWORK_ID,
    KADCARS_NFT,
    LOCAL_ACCOUNT_KEY,
    MINT_AMOUNT_LIMIT_KEY,
    NEW_ADMIN_ADDRESS,
    COLLECTION_NAME_KEY,
    ISOKO_ORCHESTRATOR_V2,
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,
    ISOKO_COLLECTION_POLICY,
} from "../utils/Constants";
import { checkIfNullOrUndefined, tryLoadLocal } from "../utils/utils";
import { createUniqueTransferCapabilities, getPactDecimal } from "../pact/PactUtils";
import Pact from "pact-lang-api";
import { useQuickBuy } from "../CrossChainOperations/EzBuy";
import uris from "../project_config_files/kadcars_uris";
import { IsokoCollectionsContext } from "../IsokoContextProvider/IsokoCollectionsContextProvider";

const KADCARS_CREATOR_KEY = "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5";
export const KADCARS_COLLECTION_HASH = "collection:4feG01EViLC9bR5gA-mBEFF-TEAKs12h-9tPnDVJ5qI";
// export const KADCARS_COLLECTION_HASH = "collection:9OBlJaWG812Ahdd6dg7sCNYPB_A1PmP-StvuuwjFC6w";

function useKadcarsProvider() {
    const quickBuy = useQuickBuy();
    const pactContext = useContext(PactContext);
    const queryBlockChainForData = useQueryBlockchainForData();
    const { getNetworkSpecificParameterFromProjectConfig, getParameterFromProjectConfig } = useContext(IsokoCollectionsContext);

    async function mint(amount, priceToPay, transactionCompleteCallback = null) {
        let priceToPayContract = 0.97 * priceToPay;
        let chainId = getChain();
        let accountDetails = tryLoadLocal(LOCAL_ACCOUNT_KEY);
        let priceToPayLPFee = getTransactionFee(priceToPay);
        let totalPrice = priceToPay;
        let cantMint = await checkIfUserCantMint(pactContext.account.account);

        let pactCode = `(${ISOKO_ORCHESTRATOR_V2}.mint-bulk 
            ${amount} 
            "kadcars-nft-collection" 
            "${pactContext.account.account}" 
            (read-keyset "user-ks"))`;

        // let pactCodeToTransferFunds = `(coin.transfer 
        //         "${pactContext.account.account}" 
        //         "${ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS}" 
        //         ${parseFloat(priceToPayLPFee).toFixed(8)})`;

        // pactCode = pactCode + pactCodeToTransferFunds;

        const envData = {
            "user-ks": accountDetails.accountGuard,
            account: accountDetails.account,
        };

        const capDataList = [
            {
                role: "Project mint fee",
                description: "Pay to mint",
                sender: pactContext.account.account,
                receiver: ISOKO_LAUNCHPAD_FEE_COLLECTOR_ADDRESS,//TODO: change this to f1
                amount: getPactDecimal(priceToPay),
            },
        ];
        let caps = createUniqueTransferCapabilities(capDataList);
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));

        const cmd = {
            pactCode,
            caps: caps,
            sender: pactContext.account.account,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId,
            ttl: 600,
            envData: envData,
            signingPubKey: accountDetails.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        //For quickbuy
        let signers = [
            {
                publicKey: accountDetails.accountGuard.keys[0],
                clist: caps.map((cap) => {
                    return cap.cap;
                }),
            },
        ];

        let meta = Pact.lang.mkMeta(
            pactContext.account.account,
            chainId,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            parseFloat((Date.now() / 1000).toFixed(2)),
            1200
        );
        let bzCommand = JSON.parse(
            Pact.simple.exec.createCommand(
                signers,
                (Date.now() / 1000).toString(),
                pactCode,
                envData,
                meta,
                NETWORK_ID
            ).cmds[0]["cmd"]
        );
        let cmdPubKeyObj = {
            cmd: bzCommand,
            pubKey: accountDetails.accountGuard.keys[0],
        };

        let txData = {
            cmdType: "exec",
            cmd: cmd,
            bzCommand: cmdPubKeyObj,
            transactionMessage: `Purchasing ${amount} Kadcar${amount > 1 ? "s" : ""} for 
                ${parseFloat(totalPrice.toFixed(4))} KDA`,
            callback: transactionCompleteCallback,
            errorHandler: transactionCompleteCallback,
            previewComponent: "",
            errorMessage: "",
            shouldSign: true,
        };

        quickBuy.shouldExecuteQuickBuy(txData, totalPrice, chainId);
    }

    async function getNftsForUser() {
        let pactCode = null;
        const chainId = getChain();

        pactCode = `(${ISOKO_COLLECTION_POLICY}.get-nfts-by-owner "${KADCARS_COLLECTION_HASH}" "${pactContext.account.account}")`;
        // pactCode = `(${ISOKO_COLLECTION_POLICY}.get-nfts-by-owner "${KADCARS_COLLECTION_HASH}" "k:f157854c15e9bb8fb55aafdecc1ce27a3d60973cbe6870045f4415dc06be06f5")`;

        let userNfts = await queryBlockChainForData(pactCode, chainId);
        let tokenIds = userNfts.map((nft) => {
            return nft["token-id"];
        })

        return !checkIfNullOrUndefined(tokenIds) ? tokenIds : [];
    }

    async function getMintedTotal() {
        let pactCode = null;

        pactCode = `(${ISOKO_COLLECTION_POLICY}.get-minted-supply "${KADCARS_COLLECTION_HASH}")`;

        let chainId = getChain();

        let blockchainResponse = await queryBlockChainForData(
            pactCode,
            chainId
        );

        if (blockchainResponse) {
            return blockchainResponse;
        }
        return 0;
    }

    function getMintPriceForNextPhase() {
        // let phaseData = collectionProvider.getMintPriceForNextPhase(
        //     KADCARS_NFT,
        //     KADCARS_PROJECT_NAME_KEY
        // );

        // return phaseData;
    }

    function getNftAmountLimit() {
    }

    function getNextPrice(mintAmt) {
        return 25;
    }

    function getTransactionFee(price) {
        let txFee = parseFloat(
            (price * 0.03).toFixed(8)
        );
        return txFee;
    }

    function getChain() {
        let chainId = getNetworkSpecificParameterFromProjectConfig(
            KADCARS_NFT,
            KADCARS_PROJECT_NAME_KEY,
            CHAIN_ID_KEY
        );

        return chainId;
    }

    const getTotalSupply = () => {
    };

    function getNextPrice() {
        let price = 25;

        return price;
    }

    async function fetchData(nftId, callback) {
        // let url = `https://kadcars-manifests.nyc3.digitaloceanspaces.com/${nftId}.json`;
        let url = `https://kadcars-nft-metadata.nyc3.digitaloceanspaces.com/${nftId}.json`;

        await fetch(url)
            .then((response) => response.json())
            .then((res) => {
                console.log(res)
                callback(res);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    async function getNftMetadata(nftId) {
        let metadata = null;

        await fetchData(nftId, (res) => {
            metadata = {
                ...res["data"][3],
                "nft-uri": `https://${res["uri"]["data"].split("//")[1]}.ipfs.nftstorage.link`
            };
        })

        return metadata;
    }

    async function getTokenData(tokenId) {
        let data = null;
        let metadata = await queryBlockChainForData(`(${ISOKO_COLLECTION_POLICY}.get-token "${tokenId}")`, "8");

        return metadata;
    }

    async function getNftManifest(tokenId) {
        let data = null;
        let metadata = (await queryBlockChainForData(`(marmalade-v2.ledger.get-token-info "${tokenId}")`, "8"))["uri"];
        // metadata = metadata.split("test-")[0] + metadata.split("test-")[1];

        await fetch(metadata)
            .then((response) => response.json())
            .then((res) => {
                data = {
                    ...res["data"][3],
                    "nft-uri": `https://${res["uri"]["data"].split("//")[1]}.ipfs.nftstorage.link`
                }
            })
            .catch((e) => {
                console.log(e);
            });

        return data;
    }

    async function getNftId(nftId) {
        let data = null;
        let metadata = (await queryBlockChainForData(`(marmalade-v2.ledger.get-token-info "${nftId}")`, "8"))["uri"];
        // metadata = metadata.split("test-")[0] + metadata.split("test-")[1];

        await fetch(metadata)
            .then((response) => response.json())
            .then((res) => data = res["data"][1]["datum"]["vehicle-information"]["vin"])
            .catch((e) => {
                console.log(e);
            });

        return data;
    }

    async function getNftImage(nftId) {
        let data = null;
        let metadata = (await queryBlockChainForData(`(marmalade-v2.ledger.get-token-info "${nftId}")`, "8"))["uri"];

        // metadata = metadata.split("test-")[0] + metadata.split("test-")[1];

        await fetch(metadata)
            .then((response) => response.json())
            .then((res) => {
                data = res["uri"]["data"].split("//")[1];
            })
            .catch((e) => {
                console.log(e);
            });

        return `https://${data}.ipfs.nftstorage.link`;
    }

    function getAccountWhitelistData() {
        return null;
    }

    function getPhaseParticipationRequirement() {
        return null;
    }

    async function checkIfUserCantMint(account) {
        let isInPublicWindow = getIsInPublicWindow();

        if (isInPublicWindow) {
            return false;
        }

        return true;
    }

    function getIsInPublicWindow() {
        let currentTime = Date.now();
        let mintPhases = getMintPhases();
        console.log(mintPhases);
        if (currentTime > Date.parse(mintPhases["public_sale"]["start_time"])) {
            return true;
        }
        return false;
    }

    function getMintPhases() {
        let mintPhases = getNetworkSpecificParameterFromProjectConfig(
            KADCARS_NFT,
            KADCARS_PROJECT_NAME_KEY,
            MINT_PHASES_KEY
        );

        return mintPhases;
    }

    async function getNftListingData(nftId, payload) {
        let nftData = {};
        let tokenData = await getTokenData(nftId);
        let manifest = await getNftManifest(nftId);
        let nftDataSnapshot = {
            ...tokenData,
            ...manifest
        }

        nftData["chain-id"] = "8";
        nftData["nft-uri"] = nftDataSnapshot["nft-uri"];
        nftData["token-id"] = nftDataSnapshot["token-id"];
        nftData["current-owner"] = nftDataSnapshot["owner"];
        nftData["nft-id-on-chain"] = nftDataSnapshot["token-id"];
        nftData["name"] = nftDataSnapshot["datum"]["datum"]["name"];
        nftData["rarity_level"] = nftDataSnapshot["datum"]["datum"]["rarity_level"];
        nftData["formatted_attributes"] = payload["getNftTraitStats"](nftDataSnapshot["datum"]["datum"]["attributes"]);

        return nftData;
    }

    return {
        mint,
        getNftId,
        getNextPrice,
        getChain,
        getTotalSupply,
        getMintPriceForNextPhase,
        getMintedTotal,
        getNftsForUser,
        getNftAmountLimit,
        getTokenData,
        getNftImage,
        getNftListingData,
        getNftMetadata,
        getAccountWhitelistData,
        getNftManifest,
        getPhaseParticipationRequirement
    };
}

export { useKadcarsProvider };

import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE, ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS, ISOKO_MARKETPLACE_FEE_PERCENTAGE, KADENA_SKELLIES, NETWORK_ID } from "../utils/Constants";
import { createUniqueTransferCapabilities, fetchAccountDetailsWithChainId, getPactDecimal } from "../pact/PactUtils";
import Pact from "pact-lang-api";
import { getAmountByPercentage, reduceAccountString } from "../utils/utils";
import { createEzBuyCommandFromExec } from "../utils/transformers";
import { useQueryBlockchainForData } from "../pact/ContractHooks";
import { toast } from "react-toastify";

const GAS_LIMIT_FOR_TRANSFER = 5000;

function useButterMarketplaceProvider(collectionConfig, ezBuy, pactContext) {
    const queryBlockChainForData = useQueryBlockchainForData();

    async function buy(nftData, buyer, transactionCompleteCallback = null) {
        let txData = await buildBuyTransaction(nftData, buyer, transactionCompleteCallback);
        ezBuy.shouldExecuteQuickBuy(txData, nftData["listing-details"]["price"], nftData["chain-id"], transactionCompleteCallback);
    }

    async function list(nftData, lister, transactionCompleteCallback = null) {
        let pactCode = `(${collectionConfig["policy-info"]["policy-name"]}.put-id-for-sale "${nftData["nft-id"]}" ${parseFloat(nftData["price"]).toFixed(8)})`;
        let envData = {};
        let caps = [
            Pact.lang.mkCap(
                "Verify your account",
                "Verify your account",
                `${collectionConfig["policy-info"]["policy-name"]}.ACCOUNT_GUARD`,
                [lister]
            ),
        ];

        const cmd = {
            pactCode,
            caps: caps,
            sender: lister,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId: nftData["chain-id"],
            ttl: 600,
            envData: envData ? envData : {},
            signingPubKey: pactContext.account.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = (
            <p>
                Listing {nftData["nft-id"]} for {nftData["price"]} KDA
            </p>
        );

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Listing 1 NFT with ID: ${nftData["nft-id"]}`,
        //     callback ?? (() => alert("Listing NFT completed successfully!")),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(
        //     pactCode,
        //     nftData["chain-id"],
        //     pactContext.account.account,
        //     pactContext.account.accountGuard.keys[0],
        //     envData,
        //     caps
        // );

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: `Listing ${nftData["nft-id"]} for ${nftData["price"]}`,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Listing #${nftData["nft-id"]} for ${nftData["price"]} KDA`,
            previewContent,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, null, nftData["chain-id"], transactionCompleteCallback);
    }

    async function withdraw(nftData, seller, transactionCompleteCallback = null) {
        let pactCode = `(${collectionConfig["policy-info"]["policy-name"]}.remove-id-from-sale "${nftData["nft-id"]}")`;

        let caps = [
            Pact.lang.mkCap(
                "Verify your account",
                "Verify your account",
                `${collectionConfig["policy-info"]["policy-name"]}.ACCOUNT_GUARD`,
                [seller]
            ),
        ];
        const sellerAccountDetails = await fetchAccountDetailsWithChainId({
            account: seller,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!sellerAccountDetails) {
            //TODO: throw error here
            toast.error("Failed to withdraw your NFT, please check your account");
            return;
        }

        const envData = {
            seller: seller,
            "seller-guard": sellerAccountDetails["guard"],
            "Marketplace Facilitator": "Isoko LLC",
        };

        const cmd = {
            pactCode,
            caps,
            sender: seller,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId: nftData["chain-id"],
            ttl: 600,
            envData,
            signingPubKey: pactContext.account.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = <p>Withdrawing token {nftData["nft-id"]}</p>;

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Transfering ${nftData["nft-id"]}`,
        //     callback ?? (() => alert(`transfer succeeded`)),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(
        //     pactCode,
        //     nftData["chain-id"],
        //     pactContext.account.account,
        //     pactContext.account.accountGuard.keys[0],
        //     envData,
        //     caps
        // );

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: `Withdrawing ${nftData["nft-id"]} from Isoko Marketplace`,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Withdrawing ${nftData["nft-id"]} from Isoko Marketplace`,
            previewContent,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, null, nftData["chain-id"], transactionCompleteCallback);
    }

    async function transfer(nftData, receiver, transactionCompleteCallback = null) {
        let pactCode = `(${collectionConfig["policy-info"]["policy-name"]}.transfer "${receiver}" "${nftData["nft-id"]}")`;
        console.log(nftData)
        const receiverAccountDetails = await fetchAccountDetailsWithChainId({
            account: receiver,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });
        console.log(nftData)
        console.log(receiverAccountDetails)
        if (!receiverAccountDetails) {
            toast.error(`Failed to transfer NFT, make sure destination account is correct & some balance exists on chain ${nftData["chain-id"]}`);
            return;
        }

        let envData = {
            account: receiver,
            "user-ks": receiverAccountDetails["guard"],
        };

        let caps = [
            Pact.lang.mkCap(
                "Transfer Capability",
                "transfer",
                `coin.TRANSFER`,
                [nftData["nft-id"], nftData["current-owner"], receiver, 1.0]
            ),
            Pact.lang.mkCap(
                "Verify your account",
                "Verify your account",
                `${collectionConfig["policy-info"]["policy-name"]}.ACCOUNT_GUARD`,
                [nftData["current-owner"]]
            ),
            Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []),
        ];

        const cmd = {
            pactCode,
            caps,
            sender: pactContext.account.account,
            gasLimit: GAS_LIMIT_FOR_TRANSFER,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId: nftData["chain-id"],
            ttl: 600,
            envData,
            signingPubKey: pactContext.account.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        const previewContent = <p>Transferring token {nftData["nft-id"]}</p>;

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Transferring ${nftData["nft-id"]}`,
        //     callback ?? (() => alert(`transfer succeeded`)),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(
        //     pactCode,
        //     nftData["chain-id"],
        //     pactContext.account.account,
        //     pactContext.account.accountGuard.keys[0],
        //     envData,
        //     caps
        // );

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: ,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Transferring ${nftData["nft-id"]} to ${reduceAccountString(receiver)}`,
            previewContent,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, null, nftData["chain-id"], transactionCompleteCallback);
    }

    async function getNftFieldsForIds(fieldsList, nftIds) {
        let pactCode = "";
        if (nftIds instanceof Array) {
            pactCode = `(${collectionConfig["policy-info"]["policy-name"]}.get-nft-fields-for-ids [${fieldsList}] [${nftIds}])`;
        } else {
            pactCode = `(${collectionConfig["policy-info"]["policy-name"]}.get-nft-fields-for-id [${fieldsList}] "${nftIds}")`;
        }

        let blockchainResponse = await queryBlockChainForData(pactCode, collectionConfig["policy-info"]["chain-id"]);
        let nftFields = {};

        if (blockchainResponse !== null) {
            Object.entries(blockchainResponse).map(([field, value]) => {
                nftFields[field] = value;

                if (field === "price") {
                    if (value.decimal) {
                        nftFields[field] = parseFloat(value.decimal);
                    }
                }
            })
        } else {
            return null;
        }

        return nftFields;
    }

    async function bulkBuy(nftDataList, buyer, chainId, transactionCompleteCallback = null) {
        let txData = [];
        let amountToPay = 0;

        for (const nftData of nftDataList) {
            let temp = await buildBuyTransaction(nftData, buyer, transactionCompleteCallback);
            let txFee = 0;

            if (amountToPay === 0) {
                txFee = nftData["transaction-fee"] + nftData["listing-details"]["price"] + nftData["royalty-fee"];
            } else {
                txFee = nftData["listing-details"]["price"] + nftData["royalty-fee"];
            }
            amountToPay += txFee;

            txData.push(temp);
        }

        ezBuy.shouldExecuteQuickBuy(txData, amountToPay, chainId, transactionCompleteCallback);
    }

    async function buildBuyTransaction(nftData, buyer, transactionCompleteCallback = null) {
        let pactCodeToBuy = `(${collectionConfig["policy-info"]["policy-name"]}.buy-id-on-sale "${nftData["nft-id"]}" "${buyer}")`;
        let pactCodeToTransferFunds = `(coin.transfer "${buyer}" "${ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS}" ${parseFloat(
            nftData["transaction-fee"]
        ).toFixed(8)})`;
        let pactCode = pactCodeToBuy + pactCodeToTransferFunds;
        let totalPrice = nftData["transaction-fee"] + nftData["listing-details"]["price"] + nftData["royalty-fee"];
        let envData = {
            "user-ks": pactContext.account.accountGuard,
            account: pactContext.account.account,
        };

        //TODO: ADD CAPABILITY
        if (ISOKO_MARKETPLACE_FEE_PERCENTAGE > 0) {
            let priceToPayLPFee = getAmountByPercentage(nftData["listing-details"]["price"], ISOKO_MARKETPLACE_FEE_PERCENTAGE);
            let pactCodeToTransferFunds = `(coin.transfer "${buyer}" "${ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS}" ${parseFloat(priceToPayLPFee).toFixed(8)})`;
            pactCode = pactCode + pactCodeToTransferFunds;
        }
        console.log(nftData)
        let capList = [
            {
                role: "Pay transaction fee",
                description: "Pay transaction fee",
                sender: buyer,
                receiver: ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS,
                amount: { decimal: parseFloat(nftData["transaction-fee"].toFixed(12)) },
            },
            {
                role: "Pay royalty fee",
                description: "Pay royalty fee",
                sender: buyer,
                receiver: collectionConfig["policy-info"]["creator-wallet"],
                amount: { decimal: parseFloat(nftData["royalty-fee"].toFixed(12)) },
            },
            {
                role: "Pay to buy",
                description: "Pay to buy",
                sender: buyer,
                receiver: nftData["current-owner"],
                amount: { decimal: parseFloat((nftData["listing-details"]["price"]).toFixed(12)) },
            },
        ];

        if (collectionConfig["policy-info"]["collection-name"] === KADENA_SKELLIES) {
            let mintedBy = await getNftFieldsForIds(
                [`"minted-by"`],
                nftData["nft-id"]
            );
            console.log(mintedBy)
            if (mintedBy["minted-by"]) {
                nftData["minted-by"] = mintedBy["minted-by"]
                console.log(nftData)
                capList.push({
                    role: "Pay to original minter",
                    description: "Pay royalties to original minter",
                    sender: buyer,
                    receiver: nftData["minted-by"],
                    amount: { decimal: parseFloat((0.04 * nftData["listing-details"]["price"]).toFixed(12)) },
                });
            }
        }
        let caps = createUniqueTransferCapabilities(capList);
        caps.push(
            Pact.lang.mkCap(
                "Verify your account",
                "Verify your account",
                `${collectionConfig["policy-info"]["policy-name"]}.ACCOUNT_GUARD`,
                [buyer]
            )
        );
        caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));

        const cmd = {
            pactCode,
            caps: caps,
            sender: buyer,
            gasLimit: DEFAULT_GAS_LIMIT,
            gasPrice: DEFAULT_GAS_PRICE,
            chainId: nftData["chain-id"],
            ttl: 600,
            envData: envData,
            signingPubKey: pactContext.account.accountGuard.keys[0],
            networkId: NETWORK_ID,
        };

        console.log(cmd);

        const previewContent = (
            <p>
                Purchasing {nftData["name"]} for {totalPrice} KDA
            </p>
        );

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Purchasing ${nftData["name"]}`,
        //     callback ?? (() => alert("Purchase completed successfully!")),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(
        //     pactCode,
        //     nftData["chain-id"],
        //     pactContext.account.account,
        //     pactContext.account.accountGuard.keys[0],
        //     envData,
        //     caps
        // );

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: `Purchasing ${nftData["nft-id"]} for ${nftData["listing-details"]["price"]}`,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT,
            `Purchasing ${nftData["nft-id"]} for ${nftData["listing-details"]["price"]}`,
            previewContent,
            transactionCompleteCallback
        );
        console.log(nftData);
        return txData;
    }

    return {
        buy,
        list,
        withdraw,
        transfer,
    };
}

export {
    useButterMarketplaceProvider
}
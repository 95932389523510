import {
    DEFAULT_GAS_LIMIT,
    DEFAULT_GAS_PRICE,
    FEE_PERCENTAGE,
    ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS,
    ROYALTY_INFO_KEY,
} from "../utils/Constants";
import Pact from "pact-lang-api";
import {
    buildContCmd,
    buildExecCommand,
    createUniqueTransferCapabilities,
    fetchAccountDetailsWithChainId,
    getPactDecimal
} from "../pact/PactUtils";
import { checkIfNullOrUndefined } from "../utils/utils";
import { createEzBuyCommandFromExec } from "../utils/transformers";
import { toast } from "react-toastify";

const GAS_LIMIT_FOR_WITHDRAW = 5000;
const GAS_LIMIT_FOR_TRANSFER = 5000;
const GAS_LIMIT_FOR_OFFER = 5000;
const GAS_LIMIT_FOR_SALE = 5000;

function useMarmaladeProvider(collectionConfig, ezBuy, pactContext) {

    async function transfer(nftData, receiver, transactionCompleteCallback = null) {
        let chainId = nftData["chain-id"];
        let pactCode = `(marmalade.ledger.transfer-create "${nftData["nft-id-on-chain"]}" "${pactContext.account.account}" "${nftData["receiver"]}" (read-keyset "user-ks") 1.0)`;

        const receiverAccountDetails = await fetchAccountDetailsWithChainId({
            account: receiver,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!receiverAccountDetails) {
            toast.error(`Transfer failed, please ensure some KDA exists on chain ${nftData["chain-id"]} of the receiver wallet`);
            return;
        }

        const envData = {
            "user-ks": receiverAccountDetails["guard"],
            account: nftData["receiver"],
            "Marketplace Facilitator": "Isoko LLC",
            classification: "transfer"
        };

        let caps = [];
        // caps.push(Pact.lang.mkCap("Gas capability", "Pay gas", "coin.GAS", []));
        caps.push(
            Pact.lang.mkCap(
                "Transfer",
                "transfer",
                "marmalade.ledger.TRANSFER",
                [
                    nftData["nft-id-on-chain"],
                    pactContext.account.account,
                    nftData["receiver"],
                    1.0,
                ]
            )
        );

        const previewContent = <p>Training Badger to box for KDA</p>;

        // const cmd = {
        //     pactCode,
        //     caps: caps,
        //     sender: pactContext.account.account,
        //     gasLimit: DEFAULT_GAS_LIMIT,
        //     gasPrice: DEFAULT_GAS_PRICE,
        //     chainId,
        //     ttl: 600,
        //     envData: envData,
        //     signingPubKey: pactContext.account.accountGuard.keys[0],
        //     networkId: NETWORK_ID,
        // };

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Listing 1 NFT with ID: ${nftData["nft-id-on-chain"]}`,
        //     callback ?? (() => alert("Listing NFT completed successfully!")),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(pactCode, chainId, pactContext.account.account, pactContext.account.accountGuard.keys[0], envData, caps);

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: `Transferring ${nftData["nft-id"]} to ${receiver}`,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            GAS_LIMIT_FOR_TRANSFER,
            `Transferring ${nftData["nft-id"]} to ${receiver}`,
            previewContent,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, 0, chainId, transactionCompleteCallback);
    }

    async function list(nftData, lister, transactionCompleteCallback = null) {
        let chainId = nftData["chain-id"];
        let pactCode = `(marmalade.ledger.sale "${nftData["nft-id-on-chain"]}" "${nftData["current-owner"]}" 1.0 ${nftData["timeout"]})`;

        const listingAccountDetails = await fetchAccountDetailsWithChainId({
            account: lister,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!listingAccountDetails) {
            toast.error(`Failed to list your NFT, ensure some funds exist on chain ${nftData["chain-id"]}`);
            return;
        }

        let caps = [];
        let envData = {
            quote: {
                "price": parseFloat(nftData["price"]),
                "recipient": lister,
                "recipient-guard": listingAccountDetails["guard"]
            },
            "Marketplace Facilitator": "Isoko LLC",
        };

        console.log(envData);
        caps.push(
            Pact.lang.mkCap(
                "DEBIT",
                "Marmalade Debit",
                `marmalade.ledger.DEBIT`,
                [nftData["nft-id-on-chain"], nftData["current-owner"]]
            )
        );
        caps.push(
            Pact.lang.mkCap(
                "OFFER",
                "Marmalade Offer",
                `marmalade.ledger.OFFER`,
                [
                    nftData["nft-id-on-chain"],
                    nftData["current-owner"],
                    1.0,
                    { int: nftData["timeout"] },
                ]
            )
        );

        // const cmd = {
        //     pactCode,
        //     caps: caps,
        //     sender: pactContext.account.account,
        //     gasLimit: DEFAULT_GAS_LIMIT,
        //     gasPrice: DEFAULT_GAS_PRICE,
        //     chainId,
        //     ttl: 600,
        //     envData: envData ? envData : {},
        //     signingPubKey: pactContext.account.accountGuard.keys[0],
        //     networkId: NETWORK_ID,
        // };

        const previewContent = (
            <p>
                Listing {nftData["nft-id"]} for {nftData["price"]} KDA
            </p>
        );

        // pactContext.sendTransaction(
        //     cmd,
        //     previewContent,
        //     `Listing 1 NFT with ID: ${nftData["nft-id-on-chain"]}`,
        //     callback ?? (() => alert("Listing NFT completed successfully!")),
        //     errorHandler ?? null,
        //     "",
        //     true
        // );

        // let bzCommand = buildExecCommand(pactCode, chainId, lister, pactContext.account.accountGuard.keys[0], envData, caps);

        // let cmdPubKeyObj = {
        //     cmd: bzCommand,
        //     pubKey: pactContext.account.accountGuard.keys[0]
        // }

        // let txData = {
        //     cmdType: "exec",
        //     cmd: cmd,
        //     bzCommand: cmdPubKeyObj,
        //     previewContent: previewContent,
        //     transactionMessage: `Listing ${nftData["nft-id"]} for ${nftData["price"]}`,
        //     callback: transactionCompleteCallback,
        //     errorHandler: transactionCompleteCallback,
        //     errorMessage: "",
        //     shouldSign: true,
        // };

        const txData = createEzBuyCommandFromExec(
            pactCode,
            nftData["chain-id"],
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            envData,
            caps,
            "exec",
            DEFAULT_GAS_PRICE,
            GAS_LIMIT_FOR_OFFER,
            `Listing ${nftData["nft-id"]} for ${nftData["price"]}`,
            previewContent,
            transactionCompleteCallback
        );

        ezBuy.shouldExecuteQuickBuy(txData, 0, chainId, transactionCompleteCallback);
    }

    async function withdraw(nftData, seller, transactionCompleteCallback = null) {
        let pubKey = seller.split(":")[1];
        const pactId = nftData["listing-details"]["pactId"];

        const sellerAccountDetails = await fetchAccountDetailsWithChainId({
            account: seller,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!sellerAccountDetails) {
            //TODO: throw error here
            toast.error(`Failed to withdraw your NFT, ensure some funds exist on chain ${nftData["chain-id"]}`);
            return;
        }

        const envData = {
            seller: seller,
            "seller-guard": sellerAccountDetails["guard"],
            "Marketplace Facilitator": "Isoko LLC",
        };

        const clist = [
            {
                publicKey: pubKey,
                clist: [{ name: "coin.GAS", args: [] }],
            },
        ];

        // nftData["listing-details"]["pactId"],
        let contCmd = buildContCmd(
            pactId,
            seller,
            nftData["chain-id"],
            0,
            true,
            null,
            envData,
            clist,
            null,
            GAS_LIMIT_FOR_WITHDRAW
        );
        console.log(contCmd)
        contCmd["cmds"][0]["pubKey"] = pubKey;

        let txData = {
            cmdType: "cont",
            cmd: contCmd,
            bzCommand: contCmd
        }

        ezBuy.shouldExecuteQuickBuy(txData, 0, nftData["chain-id"], transactionCompleteCallback);
    }

    async function buy(nftData, buyer, transactionCompleteCallback = null) {
        let txData = await buildBuyTransaction(nftData, buyer, transactionCompleteCallback);
        let txFee = parseFloat((nftData["listing-details"]["price"] * FEE_PERCENTAGE).toFixed(8))

        console.log(nftData)
        console.log(txData)

        ezBuy.shouldExecuteQuickBuy(
            txData,
            nftData["listing-details"]["price"] + txFee,
            nftData["chain-id"],
            transactionCompleteCallback
        );

        // let sendSignedResponse = await quickSignUtil.quickSignAndSend(contCmd);
        // transactionCompleteCallback && transactionCompleteCallback(sendSignedResponse);
    }

    async function bulkBuy(nftDataList, buyer, chainId, transactionCompleteCallback = null) {
        let txData = [];
        let amountToPay = 0;

        for (const nftData of nftDataList) {
            let temp = await buildBuyTransaction(nftData, buyer, transactionCompleteCallback);
            let txFee = parseFloat((nftData["listing-details"]["price"] * FEE_PERCENTAGE).toFixed(8));
            amountToPay += txFee;

            txData.push(temp);
        }

        ezBuy.shouldExecuteQuickBuy(txData, amountToPay, chainId, transactionCompleteCallback);
    }

    async function buildBuyTransaction(nftData, buyer, transactionCompleteCallback = null) {
        let pubKey = buyer.split(":")[1];
        let royaltyInfo = collectionConfig[ROYALTY_INFO_KEY];

        const buyerAccountDetails = await fetchAccountDetailsWithChainId({
            account: buyer,
            chainId: nftData["chain-id"],
            gasPrice: DEFAULT_GAS_PRICE,
            gasLimit: DEFAULT_GAS_LIMIT,
        });

        if (!buyerAccountDetails) {
            toast.error("Failed to purchase this NFT, please check your account");
            return;
        }

        const envData = {
            buyer: buyer,
            "buyer-guard": buyerAccountDetails["guard"],
            "Marketplace Facilitator": "Isoko LLC",
        };

        let clist = [
            {
                publicKey: buyer.split(":")[1],
                clist: [
                    {
                        name: "coin.GAS",
                        args: [],
                    },
                    {
                        name: "marmalade.ledger.BUY",
                        args: [
                            nftData["nft-id-on-chain"],
                            nftData["current-owner"],
                            buyer,
                            1.0,
                            { int: nftData["listing-details"]["timeout"] },
                            nftData["listing-details"]["pactId"],
                        ],
                    }
                ],
            },
        ];

        let capList = [
            {
                role: "Purchase the NFT",
                description: "Pay to buy",
                name: "coin.TRANSFER",
                sender: buyer,
                receiver: nftData["current-owner"],
                amount: { decimal: parseFloat((nftData["listing-details"]["price"]).toFixed(8)) } //TODO: change to pactdecimal
            }
        ];

        if (!checkIfNullOrUndefined(royaltyInfo)) {
            royaltyInfo.forEach((info) => {
                let newCap = {
                    role: "Royalty",
                    description: "Royalty",
                    name: "coin.TRANSFER",
                    sender: buyer,
                    receiver: info["address"],
                    amount: { decimal: parseFloat((info["percentage"] * nftData["listing-details"]["price"]).toFixed(8)) } //TODO: change to pactdecimal
                }
                capList.push(newCap);
            })

        }
        console.log(capList)
        let dedupedList = createUniqueTransferCapabilities(capList);
        dedupedList.forEach((cap) => {
            clist[0]["clist"].push(cap.cap)
        });
        console.log(dedupedList)
        let contCmd = buildContCmd(
            nftData["listing-details"]["pactId"],
            buyer,
            nftData["chain-id"],
            1,
            false,
            null,
            envData,
            clist,
            null,
            GAS_LIMIT_FOR_SALE
        );
        console.log(contCmd)
        contCmd["cmds"][0]["pubKey"] = pubKey;

        let marketFeeCmd = buildExecTxForMarmaladeFee(nftData["listing-details"]["price"], nftData["chain-id"]);

        let txData = {
            cmdType: "cont",
            cmd: contCmd,
            bzCommand: contCmd,
            marketFeeCommand: marketFeeCmd
        }

        return txData;
    }

    function buildExecTxForMarmaladeFee(price, chainId) {
        let txFee = parseFloat((price * FEE_PERCENTAGE).toFixed(8))
        let pactCode = `(coin.transfer "${pactContext.account.account}" "${ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS}" ${txFee})`;

        let clist = [
            Pact.lang.mkCap(
                "Marketplace Fee Capability",
                "Marketplace Fee Capability",
                "coin.TRANSFER",
                [
                    pactContext.account.account,
                    ISOKO_MARKETPLACE_FEE_COLLECTOR_ADDRESS,
                    { decimal: parseFloat(price.toFixed(8)) }, //TODO fix precision and limit precision
                    chainId
                ]
            )
        ];

        let execCommand = buildExecCommand(
            pactCode,
            chainId,
            pactContext.account.account,
            pactContext.account.accountGuard.keys[0],
            {},
            clist,
            DEFAULT_GAS_PRICE,
            DEFAULT_GAS_LIMIT
        )
        execCommand["payload"]["exec"]["code"] = pactCode;

        return execCommand;
    }

    return {
        bulkBuy,
        transfer,
        withdraw,
        list,
        buy,
    };
}

export { useMarmaladeProvider };

import { BUTTER_TYPE, LOCAL_ACCOUNT_KEY, MARMALADE_TYPE, SGK_WEAPONS_NAME_KEY, STACKED_TYPE } from "../utils/Constants";
import { Outlet, useParams } from "react-router";
import { buildDocumentQuery, firestoreQueryCollection } from "../Firestore/FirestoreService";
import { checkIfNullOrUndefined, useInterval } from "../utils/utils";
import { createContext, useCallback, useContext, useEffect, useState } from "react"

import { PactContext } from "../pact/PactContextProvider";
import { getBlockHeightForChain } from "../pact/PactUtils";
import { useCollectionProvider } from "../CollectionProviders/CollectionProvider";
import { useMarketplaceProviderFactory } from "../MarketplaceProviders/MarketplaceProviderFactory";
import useNftMap from "./useNftMap";
import { useLocalStorage } from "../hooks/useLocalStorage";

const MarketplaceContext = createContext();

function MarketplaceContextProvider() {
    const { projectId, nftId, subCollectionId } = useParams();
    const pactContext = useContext(PactContext);
    const projectName = projectId.split("-")[0];
    const collectionName = projectId.split("-")[1];
    const collectionProvider = useCollectionProvider(projectName, collectionName);
    const collectionConfig = collectionProvider.getConfig();
    const mintProvider = collectionProvider.getMintProvider();
    const marketplaceProvider = useMarketplaceProviderFactory(collectionConfig);
    const nftsInCollectionSnapshot = useNftMap(collectionProvider, subCollectionId, subCollectionId);

    const [loading, setLoading] = useState(true);
    const [collectionMetadata, setCollectionMetadata] = useState(null);
    const [collectionNfts, setCollectionNfts] = useState([]);
    const [userNfts, setUserNfts] = useState([]);
    const [filteredNfts, setFilteredNfts] = useState(null);
    const [nftsToDisplay, setNftsToDisplay] = useState(null);
    const [illusion, setIllusion] = useState(null);
    const [pageNumArray, setPageNumArray] = useState([]);
    const [currentHeight, setCurrentHeight] = useState(null);

    useEffect(() => {
        getCurrentHeight();
    }, [])

    useInterval(
        async () => {
            await getCurrentHeight();
        },
        15000,
        false
    );

    const refreshCollectionMetadata = useCallback(async () => {
        await getCollectionOverview();
    }, [projectId, subCollectionId])

    useEffect(() => {
        refreshCollectionMetadata();
    }, [refreshCollectionMetadata]);

    useEffect(() => {
        if (!checkIfNullOrUndefined(pactContext?.userWallet) && !checkIfNullOrUndefined(nftsInCollectionSnapshot)) {
            let userNfts = [];
            for (const [owner, nftMap] of Object.entries(nftsInCollectionSnapshot)) {
                for (const nftData of Object.values(nftMap)) {
                    if (owner === pactContext.account?.account) {
                        userNfts.push(nftData);
                    }
                }
            }
            setUserNfts(userNfts);
        }
    }, [pactContext?.userWallet, nftsInCollectionSnapshot]);

    useEffect(() => {
        async function getCollectionNftsAndMetadata() {
            const mintProvider = collectionProvider.getMintProvider();
            let illusionIds = illusion.map((nft) => {
                return nft["nft-id"];
            })
            let userNfts = [];
            let listed = [];
            let unlisted = [];
            let allNfts = [];
            let owners = [];
            let numListed = 0;
            let floor = 500;
            let collectionMetadataCopy = { ...collectionMetadata };

            for (const [owner, nftMap] of Object.entries(nftsInCollectionSnapshot)) {
                owners.push(owner);

                for (const nftData of Object.values(nftMap)) {
                    if (collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                        nftData["nft-uri"] = mintProvider.getNftImage(nftData["nft-id-on-chain"]);
                    }
                    if (nftData["status"] === "listed") {
                        numListed += 1;
                        listed.push(nftData);
                    } else {
                        unlisted.push(nftData);
                    }

                    if (pactContext.userWallet && owner === pactContext.userWallet) {
                        userNfts.push(nftData);
                    }
                }
            }
            collectionMetadataCopy["owners"] = owners;
            collectionMetadataCopy["listed"] = numListed;

            listed = sortNfts("LOW", listed);
            allNfts = listed.concat(unlisted);

            setLoading(false);
            setUserNfts(userNfts);
            setFilteredNfts(allNfts);
            setNftsToDisplay(allNfts);
            setCollectionNfts(allNfts);
            calculatePaginationArray(allNfts);

            collectionProvider.getCollectionFloor(collectionMetadataCopy["policy-info"], subCollectionId && subCollectionId).then((result) => {
                collectionMetadataCopy["floor"] = result ? result : "N/A";
                collectionMetadataCopy["best_offer"] = result ? result : "N/A";
                setCollectionMetadata(collectionMetadataCopy);
            });
        }

        illusion && nftsInCollectionSnapshot && getCollectionNftsAndMetadata();
    }, [nftsInCollectionSnapshot, illusion]);

    async function getCollectionOverview() {
        let standard = collectionProvider.getCollectionStandard();
        let collectionOverview = null;
        if (standard === STACKED_TYPE && checkIfNullOrUndefined(subCollectionId)) {
            setCollectionMetadata(null);
            return;
        }

        collectionOverview = await collectionProvider.getMarketplaceCollectionDetails(nftId, subCollectionId);
        setCollectionMetadata(collectionOverview);

        if (standard === MARMALADE_TYPE || standard === STACKED_TYPE) {
            await getIllusion();
        } else {
            setIllusion([]);
        }
    }

    async function getIllusion() {
        const mintProviderLocal = collectionProvider.getMintProvider();
        let formattedIllusion = [];
        let illusion = null;

        if (collectionProvider.getCollectionStandard() === STACKED_TYPE) {
            illusion = await firestoreQueryCollection(
                `/projects/${projectName}/nft-collections/${collectionName}/NFTs-${subCollectionId}`,
                "listing-details.price",
                ["listing-details.price", "!=", null],
                16
            );
        } else {
            illusion = await firestoreQueryCollection(
                `/projects/${projectName}/nft-collections/${collectionName}/NFTs`,
                "listing-details.price",
                ["listing-details.price", "!=", null],
                16
            );
        }

        let blockHeight = await getBlockHeightForChain(collectionConfig["policy-info"]["chain-id"]);
        illusion = illusion.filter((nft) => {
            return nft["listing-details"]["timeout"] > blockHeight
        });

        if (checkIfNullOrUndefined(illusion) || illusion?.length === 0 || illusion?.length < 16) {
            let temp = null;

            if (collectionProvider.getCollectionStandard() === STACKED_TYPE) {
                temp = await firestoreQueryCollection(
                    `/projects/${projectName}/nft-collections/${collectionName}/NFTs-${subCollectionId}`,
                    "nft-id",
                    ["nft-id", "!=", null],
                    16 - illusion.length
                );
            } else {
                temp = await firestoreQueryCollection(
                    `/projects/${projectName}/nft-collections/${collectionName}/NFTs`,
                    "nft-id",
                    ["nft-id", "!=", null],
                    16 - illusion.length
                );
            }

            if (checkIfNullOrUndefined(illusion)) {
                illusion = temp;
            } else {
                temp.forEach((nft) => {
                    let match = illusion.some((nftLocal) => nftLocal["nft-id"] === nft["nft-id"]);
                    if (!match) {
                        illusion.push(nft);
                    }
                })
            }
        }

        if (collectionProvider.getCollectionStandard() === STACKED_TYPE) {
            illusion = illusion.map((nft) => {
                nft["nft-uri"] = mintProviderLocal.getNftImage(nft["nft-id-on-chain"]);
                return nft;
            });
        }

        setIllusion(illusion);
        setLoading(false);
    }

    async function sweepTheFloor(amountToPay) {
        let nftDataList = [];

        if (collectionConfig["policy-info"]["standard"] === BUTTER_TYPE) {

        } else {
            if (collectionConfig["policy-info"]["standard"] === STACKED_TYPE) {
                nftDataList = await firestoreQueryCollection(
                    `/projects/${projectName}/nft-collections/${collectionName}/NFTs-${subCollectionId}`,
                    "listing-details",
                    ["listing-details", "!=", null],
                );
            } else {
                nftDataList = await firestoreQueryCollection(
                    `/projects/${projectName}/nft-collections/${collectionName}/NFTs`,
                    "listing-details",
                    ["listing-details", "!=", null]
                );
            }
        }

        let currentHeight = await getCurrentHeight();
        let totalPrice = 0;
        nftDataList.filter((nftData) => {
            return nftData["listing-details"]["timeout"] > currentHeight; //TODO: add buffer in case nft expires a few seconds after
        }).forEach((nftData) => {
            totalPrice += nftData["listing-details"]["price"];
            if (totalPrice < amountToPay) {
                nftDataList.push(nftData);
            }
        });

        console.log(nftDataList)
        await marketplaceProvider.bulkBuy(nftDataList, pactContext.account.account, collectionConfig["policy-info"]["chain-id"]);
    }

    async function getCurrentHeight() {
        let config = collectionProvider.getConfig();
        let newHeight = await getBlockHeightForChain(config["policy-info"]["chain-id"]);
        setCurrentHeight(newHeight);
        return newHeight;
    }

    function calculatePaginationArray(nftsArray) {
        let pageArray = [];
        for (let i = 0; i < nftsArray.length / 16; i++) {
            pageArray.push(i);
        }
        setPageNumArray(pageArray);
    }

    async function processUserNftMapForMarm(nftMap, fullMap) {
        nftMap.forEach((entry) => {
            processUserNftMap(entry, fullMap)
        })
    }

    async function processUserNftMap(map, fullMap) {
        if (collectionName === SGK_WEAPONS_NAME_KEY) {
            let burnWallet = collectionConfig["policy-info"]["burn-wallet"]

            if (!checkIfNullOrUndefined(map[burnWallet])) {
                delete map[burnWallet];
            }
        }
        let nftCount = 0;
        for (const [user, nfts] of Object.entries(map)) {
            if (Object.values(nfts).length === 0) {
                continue;
            }
            let nftsNew = {};
            if (!fullMap[user]) {
                fullMap[user] = {};
            }
            fullMap[user] = {
                ...fullMap[user],
                ...nfts
            }
        }
        return fullMap;
    }

    function sortNfts(orientation, nftsList, callback = null) {
        let results = [];
        let sortedNfts = [];
        let nfts = collectionProvider.sortNfts(nftsList, currentHeight);

        if (orientation === "LOW") {
            sortedNfts = nfts["listed"].sort((a, b) => {
                return a["listing-details"]["price"] - b["listing-details"]["price"];
            });
        } else {
            sortedNfts = nfts["listed"].sort((a, b) => {
                return b["listing-details"]["price"] - a["listing-details"]["price"];
            });
        }

        results = results.concat(sortedNfts).concat(nfts["unlisted"]);
        callback && callback(results);
        return results;
    }

    function filterListed(nftList) {
        let listedNfts = [];

        nftList.forEach((nft) => {
            if (nft["status"] === "listed" && nft["listing-details"]) {
                listedNfts.push(nft);
            }
        });
        calculatePaginationArray(listedNfts);

        return listedNfts;
    }

    function getNftState(nftData) {
        let displayText = "Not Listed";
        let totalPrice = 0.0;

        if (nftData["listing-details"]) {
            totalPrice = nftData["listing-details"]["price"];
            if (nftData["standard"] === BUTTER_TYPE) {
                totalPrice = nftData["listing-details"]["price"] + nftData["transaction-fee"];
                return `${(totalPrice)?.toFixed(3)} KDA`;
            }
            if (nftData["listing-details"]["timeout"] < currentHeight) {
                displayText = "Expired";
                if (pactContext.account?.account === nftData["current-owner"]) {
                    displayText = "Relist";
                }
                return displayText;
            } else {
                return `${totalPrice?.toFixed(3)} KDA`;
            }
        }

        return displayText;
    }

    function clearContext() {
        setLoading(true);
        setCollectionMetadata(null);
        setCollectionNfts([]);
        setUserNfts([]);
        setFilteredNfts(null);
        setNftsToDisplay(null);
        setIllusion(null);
        setPageNumArray([]);
    }

    return (
        <MarketplaceContext.Provider
            value={{
                nftsInCollectionSnapshot,
                collectionMetadata,
                collectionNfts,
                userNfts,
                filteredNfts,
                nftsToDisplay,
                collectionProvider,
                marketplaceProvider,
                currentHeight,
                projectId,
                nftId,
                subCollectionId,
                projectName,
                collectionName,
                loading,
                illusion,
                mintProvider,
                pageNumArray,
                collectionConfig,
                sortNfts,
                setUserNfts,
                getNftState,
                setLoading,
                setFilteredNfts,
                setNftsToDisplay,
                setIllusion,
                sweepTheFloor,
                filterListed,
                setPageNumArray,
                clearContext,
                setCollectionMetadata,
                calculatePaginationArray
            }}
        >
            <Outlet />
        </MarketplaceContext.Provider>
    )
}

export {
    MarketplaceContext,
    MarketplaceContextProvider
}